import * as React from 'react'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'

import { Localized } from '../../../../../localization/react'
import { PublicUser } from '../../../ActivityDetails.types'
import FullscreenDialog from '../../../../../components/FullscreenDialog'

import AllParticipatingFriendsContent from './AllParticipatingFriendsContent'

interface Props {
  isOpen: boolean
  toggleDialog: () => void
  friends: Array<PublicUser>
}

const AllParticipatingFriends = ({ isOpen, toggleDialog, friends }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return isMobile ? (
    <FullscreenDialog title={<Localized id="friends-attending-section-title" />} isOpen={isOpen} onClose={toggleDialog}>
      <AllParticipatingFriendsContent friends={friends} />
    </FullscreenDialog>
  ) : (
    <Dialog open={isOpen} onClose={toggleDialog}>
      <DialogContent sx={{ height: '672px', padding: 0 }}>
        <AllParticipatingFriendsContent friends={friends} />
      </DialogContent>
    </Dialog>
  )
}

export default AllParticipatingFriends
