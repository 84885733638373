import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import Link from '@eversports/klimt-primitives/Link'
import Stack from '@eversports/klimt-primitives/Stack'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../../localization/react'
import { From } from '../../../../App.types'

import FriendsIcon from './assets/friends.svg'

interface Props {
  onBackClick: () => void
  userHandle: string
  hasFriends: boolean
}

const NotificationPromotionActions = ({ onBackClick, userHandle, hasFriends }: Props) => {
  const { amplitude } = useAmplitude()
  const to = hasFriends ? `/u` : `/user/${userHandle}/friends?from=${From.PROMOTION_NOTIFICATION_CENTER}`

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Notification Center Promotion CTA Button')
    }
  }

  return (
    <Stack gap={4} width="100%">
      <Button variant="tertiary" onClick={onBackClick}>
        <Text variant="small" sx={{ textTransform: 'none' }}>
          <Localized id="notification-promotion-dialog-back" />
        </Text>
      </Button>
      <Link to={to} external={hasFriends ? true : false}>
        <Button variant="primary" sx={{ padding: 2 }} onClick={handleClick}>
          <Stack direction="row" gap={2} alignItems="center">
            <img src={FriendsIcon} width="12px" height="12px" />
            <Text sx={{ color: 'white', fontWeight: 'bold', textTransform: 'none' }}>
              {hasFriends ? (
                <Localized id="notification-promotion-dialog-invite-friends" />
              ) : (
                <Localized id="notification-promotion-dialog-add-friends" />
              )}
            </Text>
          </Stack>
        </Button>
      </Link>
    </Stack>
  )
}

export default NotificationPromotionActions
