import * as React from 'react'
import RadioButton, { RadioButtonProps } from '@eversports/klimt-forms/RadioButton'

type Props = Pick<RadioButtonProps, 'value' | 'label'>
const ModalRadioButton = ({ value, label }: Props) => {
  return (
    <RadioButton
      value={value}
      label={label}
      sx={{
        '& .MuiSvgIcon-root': {
          fontSize: 12,
        },
        '& .MuiRadio-root': {
          flexShrink: 0,
        },
      }}
    />
  )
}

export default ModalRadioButton
