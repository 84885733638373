import { ListItem } from '@eversports/klimt-primitives/List'
import Text from '@eversports/klimt-primitives/Text'
import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box from '@eversports/klimt-primitives/Box'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import { formatTimeAsURLParam } from '../../../../utils/format-date-time'
import { Slot } from '../../../../App.types'
import { useDiscoverState } from '../../DiscoverContext'

interface Props {
  slot: Slot
  isActive: boolean
  to: string
}

const CourtSlot = ({ slot, isActive, to: initialLinkTo }: Props) => {
  const { language } = useSettingsContext()
  const { venueCourtAreasActiveFilters, venueCourtSurfaceActiveFilters } = useDiscoverState()

  const isAvailable = slot.isAvailable
  const slotStartDate = new Date(slot.start)
  const slotTimeAsUrlParam = formatTimeAsURLParam(slotStartDate)

  const startHourTime = slotStartDate.toLocaleTimeString(language, {
    hour: '2-digit',
    minute: '2-digit',
  })

  let to = initialLinkTo

  // If the court is available, pass time and any extra filters as url params
  if (isAvailable) {
    to = `${to}&time=${slotTimeAsUrlParam}`

    // Add url param for indoor/outdoor. If both are selected, then omit the param
    if (venueCourtAreasActiveFilters && venueCourtAreasActiveFilters.length === 1) {
      const [area] = venueCourtAreasActiveFilters
      to = `${to}&area=${area}`
    }
    // Add url param for surface type.
    // Only useful if ONE is selected. If there are 2+, then omit.
    if (venueCourtSurfaceActiveFilters && venueCourtSurfaceActiveFilters.length === 1) {
      const [surface] = venueCourtSurfaceActiveFilters
      to = `${to}&surface=${surface}`
    }
  }

  if (isAvailable) {
    return (
      <ListItem
        sx={{
          p: 0,
          width: 'auto',
        }}
      >
        <Link to={to} external blank>
          <Box
            sx={{
              padding: { xs: '4px 18px', sm: 2 },
              border: '1px solid',
              borderColor: isActive ? 'primary.main' : 'lightgray',
              boxShadow: boxShadows.default,
              borderRadius: 0.5,
              '&:hover': {
                boxShadow: '0px 4px 4px rgb(20 20 20 / 20%)',
              },
              zIndex: 1,
            }}
          >
            <Text variant="small" sx={{ fontWeight: 'bold', color: isActive ? 'primary.main' : 'text' }}>
              {startHourTime}
            </Text>
          </Box>
        </Link>
      </ListItem>
    )
  }

  // When the user clicks on an unavailable slot, nothing should open. Not even the venue overview page.
  return (
    <ListItem
      onClick={(e: React.MouseEvent) => e.preventDefault()}
      sx={{
        width: 'auto',
        border: '1px solid',
        borderColor: 'transparent',
        backgroundColor: 'lightgray',
        borderRadius: 0.5,
        '&.MuiListItem-root': { padding: { xs: '4px 18px', sm: 2 } },
        '&:hover': {
          cursor: 'default',
        },
      }}
    >
      <Text variant="small" sx={{ fontWeight: 'bold', color: 'gray' }}>
        {startHourTime}
      </Text>
    </ListItem>
  )
}

export default CourtSlot
