import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'

import { Localized } from '../../../../../../localization/react'

import ProfileVisibility from './ProfileVisibility'

interface Props {
  isOpen: boolean
  onClick: () => void
}

const ProfileVisibilitySettingActionItem = ({ isOpen, onClick }: Props) => {
  return (
    <>
      <Box paddingY={4} paddingX={6} sx={{ cursor: 'pointer' }} onClick={onClick}>
        <Text sx={{ color: 'midnight' }}>
          <Localized id="edit-profile-privacy-call-to-action" />
        </Text>
      </Box>
      <ProfileVisibility isOpen={isOpen} toggleDialog={onClick} />
    </>
  )
}

export default ProfileVisibilitySettingActionItem
