import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import EversportsLogoIcon from '@eversports/design-tokens/assets/logo/icon.svg'
import Box from '@eversports/klimt-primitives/Box'

interface Props {
  profilePicture?: string
}

const UserProfilePicture = ({ profilePicture }: Props) => {
  return (
    <Box
      width="140px"
      height="140px"
      sx={{
        flexShrink: 0,
        border: '4px solid',
        borderColor: 'white',
        borderRadius: 10,
        filter: `drop-shadow(${boxShadows.default})`,
        backgroundImage: `url(${(profilePicture as string) ?? EversportsLogoIcon})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  )
}

export default UserProfilePicture
