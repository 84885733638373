import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

import SearchUsers from '../SearchUsers'
import ShareYourProfile from '../ShareYourProfile'
import { Localized } from '../../../../../../localization/react'

import SyncContacts from './SyncContacts'
import SuggestedFriends from './SuggestedFriends'

interface Props {
  profileImage?: string
}

const FindFriends = ({ profileImage }: Props) => {
  return (
    <Stack gap={4} padding={{ xs: 4, md: 0 }}>
      <Stack>
        <Heading is="h3" sx={{ color: 'midnight' }}>
          <Localized id="find-friends-section-title" />
        </Heading>
        <Text variant="small" sx={{ color: 'carbon' }}>
          <Localized id="find-friends-section-sub-title" />
        </Text>
      </Stack>
      <SuggestedFriends />
      <SearchUsers />
      <ShareYourProfile profileImage={profileImage} />
      <SyncContacts />
    </Stack>
  )
}

export default FindFriends
