import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Icon from '@eversports/klimt-primitives/Icon'
import FilterIcon from '@eversports/design-tokens/assets/icons/filter.svg'

interface Props {
  isActive: boolean
  openFilters: () => void
}

const MobileMoreFilters = ({ isActive, openFilters }: Props) => (
  <Box
    onClick={openFilters}
    sx={{
      border: '1px solid',
      borderColor: isActive ? 'primary.main' : 'mediumgray',
      borderRadius: 10,
      p: 2.5,
      ml: 'auto',
      cursor: 'pointer',
      height: 'min-content',
    }}
  >
    <Icon src={FilterIcon} size="small" color={isActive ? 'primary' : 'black'} />
  </Box>
)

export default MobileMoreFilters
