import * as React from 'react'
import { useState, useEffect } from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import Link from '@eversports/klimt-primitives/Link'
import Stack from '@eversports/klimt-primitives/Stack'
import File from '@eversports/design-tokens/assets/icons/file.svg'
import Icon from '@eversports/klimt-primitives/Icon'
import Box from '@eversports/klimt-primitives/Box'
import Tooltip from '@eversports/klimt-primitives/Tooltip'

import Timer from '../assets/timer.svg'
import { Localized } from '../../../localization/react'
import {
  useDownloadInvoiceButtonGetInvoiceQuery,
  useDownloadInvoiceButtonStartGeneratingInvoiceDownloadLinkMutation,
} from '../../../graphql'

const POLL_INTERVAL = 5000

interface InvoiceButtonProps {
  isDownloading?: boolean
  handleClick?: () => Promise<void>
}

const InvoiceButton = ({ isDownloading = false, handleClick }: InvoiceButtonProps) => (
  <Button
    onClick={handleClick}
    variant="secondary"
    disabled={isDownloading}
    sx={{
      borderRadius: 1,
      p: 2,
      width: 'fit-content',
      borderColor: isDownloading ? 'tertiary' : 'primary',
      '&:hover': { borderColor: isDownloading ? 'tertiary' : 'primary' },
    }}
  >
    <Stack alignItems="center" flexDirection={{ sm: 'row' }}>
      <Box mr={1}>
        {isDownloading ? (
          <Icon src={Timer} size="small" color="tertiary" />
        ) : (
          <Icon src={File} size="small" color="primary" />
        )}
      </Box>
      <Text variant="badge" color={isDownloading ? 'tertiary' : 'primary'} sx={{ textTransform: 'none' }}>
        <Localized id={isDownloading ? 'download-invoice-is-generating-label' : 'download-invoice-label'} />
      </Text>
    </Stack>
  </Button>
)

interface Props {
  invoiceId: string
  downloadLink?: string | null
}

const DownloadInvoiceButton = ({ invoiceId, downloadLink: initialDownloadLink }: Props) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const [generateInvoiceDownload] = useDownloadInvoiceButtonStartGeneratingInvoiceDownloadLinkMutation({
    variables: { invoiceId },
  })

  const { data, startPolling, stopPolling } = useDownloadInvoiceButtonGetInvoiceQuery({
    variables: {
      invoiceId,
    },
    skip: Boolean(initialDownloadLink),
  })

  const handleClick = async () => {
    setIsDownloading(true)
    await generateInvoiceDownload()
    startPolling(POLL_INTERVAL)
  }

  const downloadLink = initialDownloadLink ?? data?.invoice?.downloadLink

  useEffect(() => {
    if (isDownloading && data?.invoice.downloadLink) {
      stopPolling()
      setIsDownloading(false)
    }
  }, [data, isDownloading])

  if (downloadLink) {
    return (
      <Link to={downloadLink} external blank>
        <InvoiceButton />
      </Link>
    )
  }

  return isDownloading ? (
    <Tooltip title={<Localized id="download-progress-please-check-back-soon" />} arrow placement="right">
      <div style={{ width: 'fit-content' }}>
        <InvoiceButton isDownloading={isDownloading} handleClick={handleClick} />
      </div>
    </Tooltip>
  ) : (
    <InvoiceButton handleClick={handleClick} />
  )
}

export default DownloadInvoiceButton
