import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Icon from '@eversports/klimt-primitives/Icon'
import Stack from '@eversports/klimt-primitives/Stack'
import DotsMenuHorizontalIcon from '@eversports/design-tokens/assets/icons/dots-menu-horizontal.svg'
import { useRef, useState } from 'react'

import { Localized } from '../../../../../../localization/react'
import ActionWrapper from '../components/ActionWrapper'

import SettingsActions from './SettingsActions'

const SettingsButton = () => {
  const containerRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleDialog = () => {
    setAnchorEl(containerRef.current)
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      <ActionWrapper onClick={toggleDialog} forwardedRef={containerRef}>
        <Stack direction="row" alignItems="center" gap={2.5}>
          <Text variant="small" sx={{ color: 'black', display: { xs: 'none', md: 'initial' } }}>
            <Localized id="settings-button" />
          </Text>
          <Icon src={DotsMenuHorizontalIcon} size="small" color="midnight" />
        </Stack>
      </ActionWrapper>
      <SettingsActions anchorEl={anchorEl} isOpen={isModalOpen} toggleDialog={toggleDialog} />
    </>
  )
}

export default SettingsButton
