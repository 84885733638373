import * as React from 'react'
import { useRef, useEffect } from 'react'
import Dialog, { DialogContent, DialogTitle } from '@eversports/klimt-primitives/Dialog'
import Text from '@eversports/klimt-primitives/Text'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import Divider from '@eversports/klimt-primitives/Divider'

import { Review } from '../../ActivityDetails.types'
import { Localized } from '../../../../localization/react'
import Drawer from '../../components/Drawer'

import ReviewContent from './ReviewContent'
import ShowMore from './ShowMore'

interface ReviewWrapperProps {
  reviewId: string
  scrollToId?: string
}

const ReviewWrapper = ({ children, reviewId, scrollToId }: React.PropsWithChildren<ReviewWrapperProps>) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (scrollToId && reviewId === scrollToId) {
      wrapperRef.current?.scrollIntoView()
    }
  }, [scrollToId])

  return (
    <Stack forwardedRef={wrapperRef} gap={4} sx={{ width: '100%', paddingX: 3 }}>
      {children}
      <Divider />
    </Stack>
  )
}

interface Props {
  reviews: Array<Review>
  isOpen: boolean
  togglePopup: () => void
  scrollToId?: string
  handleShowMore: () => void
  hasMoreReviews: boolean
}

const ReviewsPopup = ({ reviews, isOpen, togglePopup, scrollToId, handleShowMore, hasMoreReviews }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <>
      {isMobile ? (
        <Drawer isOpen={isOpen} handleClose={togglePopup}>
          <Box sx={{ paddingY: 5, background: 'white' }}>
            <Text variant="large" color="midnight" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              <Localized id="activity-details-venue-reviews-header" />
            </Text>
          </Box>
          <Stack gap={4} alignItems="center" sx={{ overflow: 'scroll' }}>
            {reviews.map((review) => (
              <ReviewWrapper key={review.id} reviewId={review.id} scrollToId={scrollToId}>
                <ReviewContent review={review} isExpandable />
              </ReviewWrapper>
            ))}
            {hasMoreReviews && <ShowMore handleClick={handleShowMore} />}
          </Stack>
        </Drawer>
      ) : (
        <Dialog
          open={isOpen}
          onClose={togglePopup}
          sx={{
            '& .MuiDialog-paper': {
              background: 'white',
              border: '1px solid',
              borderColor: 'lightgray',
              boxSizing: 'border-box',
              boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.12)',
              borderRadius: '16px',
              width: { xs: '90vw', sm: '500px' },
              height: '60vh',
            },
          }}
        >
          <DialogTitle>
            <Text color="midnight" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              <Localized id="activity-details-venue-reviews-header" />
            </Text>
          </DialogTitle>
          <DialogContent>
            <Stack gap={4} alignItems="center">
              {reviews.map((review) => (
                <ReviewWrapper key={review.id} reviewId={review.id} scrollToId={scrollToId}>
                  <ReviewContent review={review} isExpandable />
                </ReviewWrapper>
              ))}
              {hasMoreReviews && <ShowMore handleClick={handleShowMore} />}
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default ReviewsPopup
