import { assertNever } from '@eversports/klimt-utilities/assert-never'

import { MatchSettingsState } from '../DetailsSection.types'
import { MatchMissingField } from '../../Match.types'

interface Args {
  state: MatchSettingsState
  requiredFields: Array<MatchMissingField>
}

const isMatchStateValid = ({ state, requiredFields }: Args) => {
  const missingFieldsValidity = requiredFields.map((requiredField) => {
    switch (requiredField) {
      case MatchMissingField.LEVEL:
        return Boolean(state.level)
      case MatchMissingField.GENDER:
        return Boolean(state.gender)
      case MatchMissingField.COMPETITION_TYPE:
        return Boolean(state.competitionType)
      case MatchMissingField.MAX_PARTICIPANTS:
        return state.maxParticipants !== undefined && state.maxParticipants !== null
      default:
        return assertNever(requiredField)
    }
  })

  return !missingFieldsValidity.includes(false)
}

export default isMatchStateValid
