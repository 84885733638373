import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import { useRouteMatch } from 'react-router-dom'
import Box from '@eversports/klimt-primitives/Box'

import SectionWrapper from '../../components/SectionWrapper'
import { Trainer, Venue } from '../../VenueProfile.types'
import { Localized } from '../../../../localization/react'
import Routes from '../../VenueProfile.routes'

import TrainersMoreCard from './TrainersMoreCard'
import TrainersViewAllButton from './TrainersViewAllButton'
import TrainerCard from './TrainerCard'

const MOBILE_NUMBER_OF_CARDS_TO_SHOW = 3
const DESKTOP_NUMBER_OF_CARDS_TO_SHOW = 6
export const NUMBER_OF_MORE_AVATARS_TO_SHOW = 3

interface Props {
  trainers: Array<Trainer>
  venue: Venue
}

const Trainers = ({ trainers, venue }: Props) => {
  const { url } = useRouteMatch()
  const showTrainersSection = trainers.length > 0

  if (!showTrainersSection) return null

  const linkToTeamPage = `${url}${Routes.TEAM}`

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const showCardForMoreTrainers = !isMobile && trainers.length > DESKTOP_NUMBER_OF_CARDS_TO_SHOW

  const numberOfTrainersToShowDesktop = showCardForMoreTrainers
    ? DESKTOP_NUMBER_OF_CARDS_TO_SHOW - 1
    : DESKTOP_NUMBER_OF_CARDS_TO_SHOW

  const numberOfTrainersToShow = isMobile ? MOBILE_NUMBER_OF_CARDS_TO_SHOW : numberOfTrainersToShowDesktop

  const showViewAll = trainers.length > numberOfTrainersToShow

  const shownTrainers = trainers.slice(0, numberOfTrainersToShow)

  const shownTrainersOnMoreCard = trainers.slice(
    numberOfTrainersToShow,
    numberOfTrainersToShow + NUMBER_OF_MORE_AVATARS_TO_SHOW + 1,
  )

  return (
    <SectionWrapper>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Heading is="h2" color="midnight">
          <Localized id="venue-profile-team-title" />
        </Heading>
        {!isMobile && showViewAll && <TrainersViewAllButton linkToTeamPage={linkToTeamPage} />}
      </Stack>
      <Box display="grid" gap={4} gridTemplateColumns={{ xs: '1fr', md: 'repeat(3, 1fr)' }}>
        {shownTrainers.map((trainer) => (
          <TrainerCard key={trainer.id} trainer={trainer} venue={venue} />
        ))}
        {showCardForMoreTrainers && (
          <TrainersMoreCard trainers={shownTrainersOnMoreCard} linkToTeamPage={linkToTeamPage} />
        )}
      </Box>
      {isMobile && showViewAll && <TrainersViewAllButton linkToTeamPage={linkToTeamPage} />}
    </SectionWrapper>
  )
}

export default Trainers
