import { MatchCompetitionType, MatchGender, MatchLevel } from '../../Match.types'

export const levelTranslation = {
  [MatchLevel.BEGINNER]: 'match-rule-level-beginner',
  [MatchLevel.INTERMEDIATE]: 'match-rule-level-intermediate',
  [MatchLevel.ADVANCED]: 'match-rule-level-advanced',
  [MatchLevel.ANY]: 'match-rule-level-any',
} as const

export const levelDescriptionTranslation = {
  [MatchLevel.BEGINNER]: 'match-rule-level-beginner-description',
  [MatchLevel.INTERMEDIATE]: 'match-rule-level-intermediate-description',
  [MatchLevel.ADVANCED]: 'match-rule-level-advanced-description',
  [MatchLevel.ANY]: 'match-rule-level-any-description',
} as const

export const competitionTypeTranslation = {
  [MatchCompetitionType.FRIENDLY]: 'match-rule-competition-type-friendly',
  [MatchCompetitionType.COMPETITIVE]: 'match-rule-competition-type-competitive',
} as const

export const competitionTypeDescriptionTranslation = {
  [MatchCompetitionType.FRIENDLY]: 'match-rule-competition-type-friendly-description',
  [MatchCompetitionType.COMPETITIVE]: 'match-rule-competition-type-competitive-description',
} as const

export const genderTranslation = {
  [MatchGender.MALE_ONLY]: 'match-rule-gender-male-only',
  [MatchGender.FEMALE_ONLY]: 'match-rule-gender-female-only',
  [MatchGender.UNISEX]: 'match-rule-gender-unisex',
} as const

export const MIN_NUMBER_OF_PLAYERS = 2
