import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'

const SportBadge = ({ children }: React.PropsWithChildren<unknown>) => (
  <Box
    sx={{
      width: 'fit-content',
      paddingX: 3,
      paddingY: 2,
      border: '1px solid',
      borderColor: 'darkgray',
      borderRadius: 1,
      background: 'rgba(20, 20, 20, 0.70)',
      flexShrink: 0,
      '&:hover': {
        background: 'rgba(20, 20, 20, 0.30)',
      },
    }}
  >
    {children}
  </Box>
)

export default SportBadge
