import * as React from 'react'
import { default as MuiMenu, MenuProps } from '@mui/material/Menu'
import { borderRadius } from '@eversports/design-tokens/border-radius'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

/**
 * @deprecated Use the Popover component instead
 */
const Menu: React.FC<React.PropsWithChildren<MenuProps>> = ({ sx, ...props }) => {
  return (
    <MuiMenu
      sx={{
        '& .MuiMenu-paper': {
          border: '1px solid #F2F2F2',
          boxShadow: boxShadows.hover,
          borderRadius: `${borderRadius.default}px`,
          py: 2,
          px: 4,
        },
        '& .MuiMenu-list': {
          padding: 0,
        },
        ...sx,
      }}
      {...props}
    />
  )
}

export default Menu
