import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'

interface Props {
  headerText: string
}

const WrappedHeader = ({ headerText }: Props) => (
  <Heading
    is="h1"
    variant="large"
    color="midnight"
    sx={{
      minHeight: '40px',
      maxHeight: '72px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    }}
  >
    {headerText}
  </Heading>
)

export default WrappedHeader
