import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import AddFriendIcon from '@eversports/design-tokens/assets/icons/add-friends.svg'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import { useState } from 'react'

import LoginOrRegisterDisclaimer from '../../../../../../components/LoginOrRegisterDisclaimer'
import Drawer from '../../../../../../components/Drawer'
import { AuthRoutes } from '../../../../../../components/Header/UserMenu/UserMenu.constants'
import { Localized } from '../../../../../../localization/react'

const LoggedOutFriendsButton = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen)
  }

  const handleClick = () => {
    const urlParams = `?redirectPath=${encodeURIComponent(window.location.pathname)}`
    window.location.href = `${window.location.origin}${AuthRoutes.REGISTER}${urlParams}`
  }

  return (
    <>
      <Button
        variant="primary"
        sx={{ textTransform: 'none', paddingY: { xs: 3, md: 2 }, paddingX: 6, width: 'fit-content' }}
        onClick={toggleDialog}
      >
        <Stack alignItems="center" direction="row" ap={2}>
          <Icon size="small" color="white" src={AddFriendIcon} />
          <Text sx={{ color: 'white', fontWeight: 'bold' }}>
            <Localized id="add-to-friends-button-text" />
          </Text>
        </Stack>
      </Button>
      {isMobile ? (
        <Drawer open={isDialogOpen} onClose={toggleDialog}>
          <LoginOrRegisterDisclaimer handleClick={handleClick} descriptionType="adding-a-friend" />
        </Drawer>
      ) : (
        <Dialog open={isDialogOpen} onClose={toggleDialog} maxWidth="xs">
          <DialogContent>
            <LoginOrRegisterDisclaimer handleClick={handleClick} descriptionType="adding-a-friend" />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default LoggedOutFriendsButton
