import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Link from '@eversports/klimt-primitives/Link'
import Text from '@eversports/klimt-primitives/Text'
import Divider from '@eversports/klimt-primitives/Divider'

import NewBadge from '../../NewBadge'
import { Localized } from '../../../localization/react'

import { UserItem } from './UserMenu.types'

interface Props {
  userItem: UserItem
  onClose?: () => void
}

const UserMenuLink = ({ userItem, onClose }: Props) => {
  const { to, icon, label, hasDivider, isBold, isNew, isExternal, notification } = userItem
  return (
    <>
      <Link to={to} external={isExternal}>
        <Stack
          onClick={onClose}
          direction="row"
          alignItems="center"
          gap={2}
          sx={{
            px: 0,
            py: { xs: 4, lg: 2.5 },
            '&:hover': { backgroundColor: 'lightgray' },
            width: '-webkit-fill-available',
          }}
        >
          <Stack alignItems="center" width={{ xs: '20px', lg: 'auto' }}>
            <img src={icon} loading="lazy" width="16px" height="16px" />
          </Stack>
          <Text variant="small" style={{ fontWeight: isBold ? 'bold' : 'normal' }}>
            {label}
          </Text>
          {notification && <>{notification}</>}
          {isNew && <NewBadge label={<Localized id="user-menu-new-tag" />} />}
        </Stack>
      </Link>
      {hasDivider && <Divider />}
    </>
  )
}

export default UserMenuLink
