import * as React from 'react'
import { useEffect } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

import SearchInventory from './SearchInventory'
import SearchLocation from './SearchLocation'
import useLastCitySearch from './hooks/useLastCitySearch'
import { CitySearchResult, SearchPosition } from './Search.types'
import InventorySearchPlaceholder from './SearchInventory/InventorySearchPlaceholder'

interface Props {
  updateParentLocation?: (value: CitySearchResult) => void
  variant?: 'default' | 'stacked'
  hasInputLabels?: boolean
  hasSearchButton?: boolean
  isDefaultOpen?: boolean
}

const Search = ({
  updateParentLocation,
  variant = 'default',
  hasInputLabels = false,
  hasSearchButton = false,
  isDefaultOpen = false,
}: Props) => {
  const { location, setLocation } = useLastCitySearch()

  const isStacked = variant === 'stacked'

  const locationPosition = isStacked ? SearchPosition.TOP : SearchPosition.LEFT
  const inventoryPosition = isStacked ? SearchPosition.BOTTOM : SearchPosition.RIGHT

  useEffect(() => {
    if (location && updateParentLocation) {
      updateParentLocation(location)
    }
  }, [location])

  return (
    <Stack direction={{ xs: isStacked ? 'column' : 'row', sm: 'row' }} width="100%">
      <SearchLocation
        location={location}
        handleLocationUpdate={setLocation}
        searchPosition={locationPosition}
        hasInputLabels={hasInputLabels}
      />
      {location ? (
        <SearchInventory
          location={location}
          searchPosition={inventoryPosition}
          hasInputLabels={hasInputLabels}
          hasSearchButton={hasSearchButton}
          isDefaultOpen={isDefaultOpen}
        />
      ) : (
        <InventorySearchPlaceholder searchPosition={inventoryPosition} hasInputLabels={hasInputLabels} />
      )}
    </Stack>
  )
}

export default Search
