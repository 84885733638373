import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { VenueAmenityType } from '../../../../../App.types'
import { Localized } from '../../../../../localization/react'

import AmenityIconsMap from './AmenityIconsMap'

interface Props {
  amenities: Array<VenueAmenityType>
}

const Amenities = ({ amenities }: Props) => {
  return (
    <Stack gap={2}>
      <Text color="midnight" sx={{ fontWeight: 'bold' }}>
        <Localized id="venue-profile-amenities-title" />
      </Text>
      <Stack component="ul" paddingLeft={0} direction="row" flexWrap="wrap" gap={3}>
        {AmenityIconsMap.map((amenityIcon) => {
          const isAmenityActive = amenities.includes(amenityIcon.amenity)
          return (
            <Stack
              component="li"
              gap={2}
              direction="row"
              alignItems="center"
              sx={{
                py: 1,
                px: 2,
                backgroundColor: 'lightgray',
                borderRadius: 0.5,
                opacity: isAmenityActive ? 1 : 0.35,
              }}
              key={amenityIcon.amenity}
            >
              <img src={amenityIcon.icon} width="20px" height="20px" alt={amenityIcon.amenity} />
              <Text>{amenityIcon.label}</Text>
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default Amenities
