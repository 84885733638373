import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import Alert from '@eversports/klimt-primitives/Alert'

import { Localized } from '../../../../localization/react'
import {
  EventRegistrationValidationProblem,
  EventRegistrationWindowState,
  OpenTimeRange,
  RegistrationState,
} from '../../ActivityDetails.types'

const WarningAlert = ({ children }: React.PropsWithChildren<unknown>) => (
  <Alert
    severity="error"
    icon={false}
    sx={{
      justifyContent: 'center',
      borderRadius: 0.5,
      width: '100%',
      boxShadow: 'none',
      border: '1px solid rgba(242, 74, 74, 0.2)',
      padding: 0,
      '& .MuiAlert-message': { paddingY: 0.5 },
    }}
  >
    {children}
  </Alert>
)

interface Props {
  registrationState: RegistrationState
  registrationWindowState: EventRegistrationWindowState
  registrationWindow: OpenTimeRange
}

const NotBookableAlert = ({ registrationState, registrationWindowState, registrationWindow }: Props) => {
  const { language } = useSettingsContext()
  const { validationProblems } = registrationState

  const isCancelled = validationProblems.includes(EventRegistrationValidationProblem.CANCELLED)

  if (isCancelled) {
    return (
      <WarningAlert>
        <Text>
          <Localized id="activity-details-event-is-cancelled-label" />
        </Text>
      </WarningAlert>
    )
  }

  const isFullyBooked = validationProblems.includes(EventRegistrationValidationProblem.FULLY_BOOKED)

  if (isFullyBooked) {
    return (
      <WarningAlert>
        <Text>
          <Localized id="activity-details-event-fully-booked-label" />
        </Text>
      </WarningAlert>
    )
  }

  const isInPast = validationProblems.includes(EventRegistrationValidationProblem.IN_PAST)

  if (isInPast) {
    return (
      <WarningAlert>
        <Text>
          <Localized id="activity-details-event-already-occured-label" />
        </Text>
      </WarningAlert>
    )
  }

  if (registrationWindowState === EventRegistrationWindowState.NOT_YET_OPEN && registrationWindow.start) {
    const registrationStartDate = new Date(registrationWindow.start).toLocaleDateString(language, {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    return (
      <WarningAlert>
        <Text>
          <Localized id="activity-details-event-bookable-from-label" params={{ registrationStartDate }} />
        </Text>
      </WarningAlert>
    )
  }

  return null
}
export default NotBookableAlert
