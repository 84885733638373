import { borderRadius } from '@eversports/design-tokens/border-radius'

import { CurrentLocation, AllSportsInCity, SearchPosition } from './Search.types'

export const NUMBER_OF_SHOWN_RECENT_SEARCHES = 2
export const NUMBER_OF_QUERY_RESULTS = 20
export const MOBILE_NUMBER_OF_SHOWN_RESULTS = 9
export const DESKTOP_NUMBER_OF_SHOWN_RESULTS = 15

export const CURRENT_LOCATION_RESULT: CurrentLocation = {
  id: 'current-location',
  __typename: 'currentLocation',
}

export const ALL_SPORTS_CITY_RESULT: AllSportsInCity = {
  id: 'all-sports-city',
  __typename: 'AllSportsInCity',
  cityId: '',
  cityName: '',
  citySlug: '',
}

export const STACKED_POSITIONS = [SearchPosition.TOP, SearchPosition.BOTTOM]

export const borderRadiusMapping: { [key in SearchPosition]: string } = {
  [SearchPosition.LEFT]: `${borderRadius.default}px 0px 0px ${borderRadius.default}px`,
  [SearchPosition.RIGHT]: `0px ${borderRadius.default}px ${borderRadius.default}px 0px`,
  [SearchPosition.TOP]: `${borderRadius.default}px ${borderRadius.default}px 0px 0px`,
  [SearchPosition.BOTTOM]: `0px 0px ${borderRadius.default}px ${borderRadius.default}px`,
}

export const borderWidthMapping: { [key in SearchPosition]: string } = {
  [SearchPosition.LEFT]: '1px 0px 1px 1px',
  [SearchPosition.RIGHT]: '1px',
  [SearchPosition.TOP]: '1px 1px 0px 1px',
  [SearchPosition.BOTTOM]: '1px',
}

export const openDropdownBorderRadiusMapping: { [key in SearchPosition]: string } = {
  [SearchPosition.LEFT]: `${borderRadius.default}px 0px 0px 0px`,
  [SearchPosition.RIGHT]: `0px ${borderRadius.default}px 0px 0px`,
  [SearchPosition.TOP]: `${borderRadius.default}px ${borderRadius.default}px 0px 0px`,
  [SearchPosition.BOTTOM]: `0px 0px ${borderRadius.default}px ${borderRadius.default}px`,
}
