import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Svg from '@eversports/klimt-primitives/Svg'
import Text from '@eversports/klimt-primitives/Text'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import { Localized } from '../../../../localization/react'
import { formatTime } from '../../../../helpers/format-time'
import { getDurationInMinutes } from '../../../../helpers/get-duration-in-minutes'
import checkHasOneSession from '../../helpers/check-has-one-session'
import { EventSession, SpotLimit, RegistrationState } from '../../ActivityDetails.types'
import Calendar from '../../assets/calendar.svg'
import Clock from '../../assets/clock.svg'
import { isEventInFuture } from '../../Overview/Schedule/helpers/is-event-in-future'

import { formatDate } from './helpers/format-date'
import Availability from './Availability'

const MAX_DURATION_TO_SHOW = 180

interface Props {
  eventSessions: Array<EventSession>
  availableSpots: SpotLimit | null
  registrationState: RegistrationState
}

const DateTimeAvailability = ({ eventSessions, availableSpots, registrationState }: Props) => {
  const { language } = useSettingsContext()

  const hasOneSession = checkHasOneSession(eventSessions)
  const firstSession = eventSessions[0]
  const { start, end } = firstSession
  const date = formatDate({ dateString: start, language })
  const hasStarted = !isEventInFuture(start)

  if (hasOneSession) {
    const startTime = formatTime(language, start)
    const endTime = formatTime(language, end)
    const duration = getDurationInMinutes(start, end)
    const showDuration = duration <= MAX_DURATION_TO_SHOW
    return (
      <Stack gap={4}>
        <Stack direction="row" alignItems="center" flexWrap="wrap" columnGap={2} rowGap={1}>
          <Svg src={Calendar} color="midnight" width={12} height={12} />
          <Text color="midnight" sx={{ fontWeight: 'bold' }}>
            <Localized id="activity-detail-date-label" />
          </Text>
          <Text color="midnight" sx={{ flexBasis: '100%' }}>
            {date}
          </Text>
        </Stack>
        <Stack direction="row" alignItems="center" flexWrap="wrap" columnGap={2} rowGap={1}>
          <Svg src={Clock} color="midnight" width={12} height={12} />
          <Text color="midnight" sx={{ fontWeight: 'bold' }}>
            <Localized id="activity-detail-time-label" />
          </Text>
          <Stack direction="row" sx={{ flexBasis: '100%' }} gap={1}>
            <Text color="midnight">
              {startTime} - {endTime}
            </Text>
            {showDuration && (
              <Text>
                <Localized id="activity-duration-in-minutes-short" params={{ duration }} />
              </Text>
            )}
          </Stack>
        </Stack>
        <Availability availableSpots={availableSpots} registrationState={registrationState} />
      </Stack>
    )
  }

  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" columnGap={2} rowGap={1}>
      <Svg color="midnight" src={Calendar} width={12} height={12} />
      <Text color="midnight" sx={{ fontWeight: 'bold' }}>
        <Localized id="activity-detail-date-label" />
      </Text>
      <Text color="midnight" sx={{ flexBasis: '100%' }}>
        {hasStarted ? (
          <Localized id="activity-detail-multi-session-started-on-date" params={{ startDate: date }} />
        ) : (
          <Localized id="activity-detail-multi-session-start-date" params={{ startDate: date }} />
        )}
      </Text>
      <Text color="midnight" sx={{ flexBasis: '100%' }}>
        <Localized id="activity-detail-multi-sessions-label" />
      </Text>
    </Stack>
  )
}

export default DateTimeAvailability
