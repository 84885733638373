import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import { REVIEW_CARD_GAP, REVIEW_CARD_WIDTH } from './Reviews.constants'

const REVIEW_CARD_HEIGHT = 126

interface Props {
  handleClick: () => void
}

const ReviewCard = ({ children, handleClick }: React.PropsWithChildren<Props>) => {
  return (
    <Stack
      gap={`${REVIEW_CARD_GAP}px`}
      onClick={handleClick}
      sx={{
        width: { xs: '100%', sm: `${REVIEW_CARD_WIDTH}px` },
        height: { sm: '100%' },
        maxHeight: `${REVIEW_CARD_HEIGHT}px`,
        paddingX: { sm: 3 },
        paddingY: 3,
        border: { sm: '1px solid' },
        borderBottom: '1px solid',
        borderColor: { xs: 'mediumgray', sm: 'mediumgray' }, // This may seem unnecessary, but without, the sm border was black - must be the specificity
        borderRadius: { sm: 1 },
        flexShrink: 0,
        '&:hover': {
          boxShadow: { sm: boxShadows.default },
          cursor: 'pointer',
        },
      }}
    >
      {children}
    </Stack>
  )
}

export default ReviewCard
