import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Avatar from '@eversports/klimt-primitives/Avatar'

import NotificationTimeStamp from '../NotificationTimeStamp'
import { Localized } from '../../../../localization/react'

import { FakeNotification } from './NotificationPromotion.types'

interface Props {
  notification: FakeNotification
}

const FakeNotification = ({ notification }: Props) => {
  return (
    <Box
      sx={{
        padding: 4,
        border: '1px solid',
        borderColor: 'mediumgray',
        borderRadius: 1,
        backgroundColor: 'white',
        boxShadow: boxShadows.hover,
        position: 'relative',
      }}
    >
      <Stack direction="row" alignItems="flex-start" gap={4}>
        <Avatar
          src={notification.icon}
          sx={{
            height: '42px',
            width: '42px',
            backgroundColor: 'white',
            borderRadius: 10,
          }}
        />
        <Stack gap={1} width="100%">
          <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>
            <Localized id={notification.headerTextKey} />
          </Text>
          <NotificationTimeStamp createdAt={notification.created} isNew={true} />
        </Stack>
      </Stack>
    </Box>
  )
}

export default FakeNotification
