import * as React from 'react'
import Animation from '@eversports/klimt-animations/Animation'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'

import { Localized } from '../../../../../localization/react'

import NoRequestsAnimation from './assets/no-friends-requests.lottie'

const NoFriendsOrRequests = () => {
  return (
    <Box
      padding={4}
      sx={{ border: '1px dashed', borderColor: 'mediumgray', textAlign: 'center', marginX: { xs: 4, md: 0 } }}
    >
      <Animation src={NoRequestsAnimation} style={{ width: '30%', margin: 'auto' }} loop autoplay />
      <Text sx={{ fontWeight: 'bold', color: 'midnight' }}>
        <Localized id="friends-requests-section-no-requests-title" />
      </Text>
      <Text variant="small">
        <Localized id="friends-requests-section-no-requests-description" />
      </Text>
    </Box>
  )
}

export default NoFriendsOrRequests
