import getParsedSearchParams from '../../../../../utils/get-parsed-search-params'
import { discoverMatchesFiltersInitialState } from '../DiscoverSportMatches.constants'
import { DiscoverMatchesFiltersState } from '../DiscoverSportMatches.types'

const getDiscoverySportInitialFilters = (): DiscoverMatchesFiltersState => {
  const parsedSearchParams = getParsedSearchParams()

  if (!parsedSearchParams.size) return discoverMatchesFiltersInitialState

  const venues = Array.isArray(parsedSearchParams.get('venues')) ? parsedSearchParams.get('venues') : undefined
  const parsedDate = parsedSearchParams.get('date')
  const date = typeof parsedDate === 'string' ? new Date(parsedDate) : null
  const sports = Array.isArray(parsedSearchParams.get('sports')) ? parsedSearchParams.get('sports') : undefined
  const levels = Array.isArray(parsedSearchParams.get('levels')) ? parsedSearchParams.get('levels') : undefined
  const competitionTypes = Array.isArray(parsedSearchParams.get('competitionTypes'))
    ? parsedSearchParams.get('competitionTypes')
    : undefined

  return { venues, date, sports, levels, competitionTypes }
}

export default getDiscoverySportInitialFilters
