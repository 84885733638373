import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import Processing from '@eversports/klimt-primitives/Processing'
import Text from '@eversports/klimt-primitives/Text'

import useGetTopContentHeight from '../../hooks/useGetTopContentHeight'
import { Localized } from '../../localization/react'

import { NotificationConnection } from './Notifications.types'
import NoNotifications from './NoNotifications'
import Navigation from './Navigation'
import getNotificationBlocks from './helpers/get-notification-blocks'
import NotificationBlock from './NotificationBlock'
import useNotifications from './hooks/useNotifications'

interface Props {
  initiallyFetchedNotifications: NotificationConnection
  unreadNotificationsCount: number | null
}

const NotificationsComponent = ({ initiallyFetchedNotifications, unreadNotificationsCount }: Props) => {
  const { notifications, loading, fetchMore, hasMoreResults } = useNotifications({ initiallyFetchedNotifications })
  const { DESKTOP_TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])

  const notificationBlocks = getNotificationBlocks(notifications)

  return (
    <Box
      sx={{
        backgroundColor: 'limelite',
        minHeight: `calc(100vh - ${DESKTOP_TOP_CONTENT_HEIGHT}px)`,
        height: '100%',
      }}
      component="main"
    >
      <Stack margin="auto" maxWidth={{ xs: '100%', sm: '440px' }} gap={{ xs: 3, sm: 0 }} paddingBottom={4}>
        <Navigation unreadNotificationsCount={unreadNotificationsCount} />
        {notificationBlocks.length > 0 ? (
          <Stack gap={{ xs: 6, sm: 8 }}>
            {notificationBlocks.map((block) => (
              <NotificationBlock key={block.date} date={block.date} notifications={block.notifications} />
            ))}
            {hasMoreResults && (
              <Processing isLoading={loading}>
                <Box
                  sx={{ backgroundColor: 'limelite', borderRadius: 1, padding: 2, width: '100%', cursor: 'pointer' }}
                  onClick={fetchMore}
                >
                  <Text variant="small" sx={{ color: 'primary.main', fontWeight: 'bold', textAlign: 'center' }}>
                    <Localized id="load-more-notifications-button" />
                  </Text>
                </Box>
              </Processing>
            )}
          </Stack>
        ) : (
          <NoNotifications />
        )}
      </Stack>
    </Box>
  )
}

export default NotificationsComponent
