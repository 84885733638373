import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import * as React from 'react'

interface Props {
  index: number
  name: string
  count: number
  image?: React.ReactNode
}

const RecapSectionListItem = ({ index, name, count, image }: Props) => {
  return (
    <Stack
      component="li"
      direction="row"
      alignItems="center"
      sx={{
        paddingX: 4,
        paddingY: 2,
        borderBottom: '1px solid',
        borderColor: 'mediumgray',
        textAlign: 'left',
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} width="100%">
        <Stack direction="row" gap={2}>
          <Text variant="large" sx={{ fontWeight: 'bold', color: 'darkgray' }}>
            {index}.
          </Text>
          {image && image}
          <Text variant="large" sx={{ fontWeight: 'bold', color: 'midnight' }}>
            {name}
          </Text>
        </Stack>
        <Text variant="large" sx={{ fontWeight: 'bold', color: '#0BD6C2' }}>
          {count}
        </Text>
      </Stack>
    </Stack>
  )
}

export default RecapSectionListItem
