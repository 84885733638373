import * as React from 'react'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import CalendarIcon from '@eversports/design-tokens/assets/icons/calendar.svg'
import Text from '@eversports/klimt-primitives/Text'

import { formatDate } from '../../../../../helpers/format-date'

interface Props {
  date: string
}

const ParticipationDate = ({ date }: Props) => {
  const { language } = useSettingsContext()
  const formattedDate = formatDate(language, date)
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Icon src={CalendarIcon} color="midnight" size="small" />
      <Text sx={{ color: 'midnight' }}>{formattedDate}</Text>
    </Stack>
  )
}

export default ParticipationDate
