import { GlobalConfig } from './set-config'
import { getWindow } from './window'

declare let global: GlobalConfig

export function getSharedConfig<T>(): T {
  return (typeof window === 'undefined' ? global.sharedConfig : getWindow().sharedConfig) as T
}

export function getServerConfig<T>(): T {
  if (typeof window === 'undefined') return global.serverConfig as T

  throw new Error('Trying to access server config on client')
}
