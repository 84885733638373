import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { useParams } from 'react-router-dom'
import Box from '@eversports/klimt-primitives/Box'
import CheckmarkIcon from '@eversports/design-tokens/assets/icons/checkmark.svg'
import Icon from '@eversports/klimt-primitives/Icon'

import { Localized } from '../../../localization/react'
import { useVenueNewsletterMatchQuery, useVenueNewsletterSubscribeMutation } from '../../../graphql'

const VenueNewsletter = () => {
  const { id } = useParams<{ id: string }>()
  const { data: matchData } = useVenueNewsletterMatchQuery({ variables: { matchId: id } })
  const [subscribeToNewsletter, { data, loading }] = useVenueNewsletterSubscribeMutation()

  const handleButtonClick = () => {
    if (
      loading ||
      data ||
      matchData?.match.organizerV2.__typename !== 'PrivateMatchOrganizer' ||
      !matchData?.match.organizerV2.customer
    ) {
      return
    }
    void subscribeToNewsletter({ variables: { customerId: matchData?.match.organizerV2.customer.id } })
  }

  if (
    !matchData ||
    matchData.match.organizerV2.__typename !== 'PrivateMatchOrganizer' ||
    matchData.match.organizerV2.customer?.isSubscribedToNewsletter
  ) {
    return null
  }

  return (
    <Stack gap={4}>
      <Text variant="large" sx={{ color: 'white' }}>
        <Localized id="venue-newsletter-subscription-title" />
      </Text>
      <Text variant="small" sx={{ color: 'gray' }}>
        <Localized id="venue-newsletter-subscription-sub-title" />
      </Text>
      <Box
        px={3}
        py={2}
        sx={{ backgroundColor: 'text.primary', width: 'fit-content', borderRadius: 1, cursor: 'pointer' }}
        onClick={handleButtonClick}
      >
        {data?.subscribeToNewsletter.id ? (
          <Stack direction="row" alignItems="center" gap={2}>
            <Icon src={CheckmarkIcon} color="white" size="default" />
            <Text sx={{ color: 'white' }}>
              <Localized id="venue-newsletter-subscription-success" />
            </Text>
          </Stack>
        ) : (
          <Text sx={{ color: 'white' }}>
            <Localized id="venue-newsletter-subscription-call-to-action" />
          </Text>
        )}
      </Box>
    </Stack>
  )
}

export default VenueNewsletter
