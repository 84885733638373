import ClientLogger from '@eversports/klimt-utilities/client-logger'
import { SupportedTLD, isSupportedTLD } from '@eversports/react-app-base/tld-configs'

interface Props {
  path: string
  origin: string
  tld: SupportedTLD
  venueCountry: string
}

const getCanonicalHref = ({ path, origin, tld, venueCountry }: Props) => {
  const countryLowerCase = venueCountry.toLowerCase()

  const isVenueSameTLD = countryLowerCase === tld

  if (isVenueSameTLD) {
    return new URL(path, origin).href
  }

  const isCountryValidTLD = isSupportedTLD(countryLowerCase)

  if (isCountryValidTLD) {
    const newOrigin = origin.replace(/(-dev-|-test-|)\b(de|at|ch|fr|nl|be|it|es)\b/, `$1${countryLowerCase}`)
    return new URL(path, newOrigin).href
  }

  ClientLogger.info(`Venue country is not a supported TLD: ${venueCountry}`)
  return new URL(path, origin).href
}

export default getCanonicalHref
