import * as React from 'react'
import { useState, useEffect, MouseEvent } from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Icon from '@eversports/klimt-primitives/Icon'
import Stack from '@eversports/klimt-primitives/Stack'
import Time from '@eversports/klimt-localization/Time'
import ClockIcon from '@eversports/design-tokens/assets/icons/clock.svg'
import { getHours, setHours, setMinutes, setSeconds } from 'date-fns'

import { Localized } from '../localization/react'
import PickerBox from '../components/PickerBox'
import PickerMenu from '../components/PickerMenu'
import PickerDrawer from '../components/PickerDrawer'

import TimePickerContent from './TimePickerContent'

interface Props {
  value?: Date | null
  onChange: (value: Date | null) => void
  hoursToShow: Array<number>
  isMobile?: boolean
}

const TimePicker = ({ value, onChange, hoursToShow, isMobile }: Props) => {
  const [hour, setHour] = useState<number | null>(value ? getHours(value) : null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const openTimePicker = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clearTime = () => {
    setHour(null)
    onChange(null)
    handleClose()
  }

  const handleHourClick = (event: MouseEvent<HTMLLIElement>) => {
    setHour(event.currentTarget.value)
  }

  useEffect(() => {
    if (value === null) setHour(value)
  }, [value])

  useEffect(() => {
    if (hour === null) {
      onChange(null)
    } else {
      const date = setSeconds(setMinutes(setHours(new Date(), hour), 0), 0)
      setAnchorEl(null)
      onChange(date)
    }
  }, [hour])

  return (
    <>
      <PickerBox onClick={openTimePicker} isActive={Boolean(value)}>
        <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
          <Text sx={{ display: { xs: value ? 'none' : 'initial', sm: 'initial' } }}>
            <Localized id="time" />
          </Text>
          {value && (
            <Text sx={{ color: 'primary.main', fontWeight: 'bold' }}>
              <Time variant="short" value={value} />
            </Text>
          )}
          <Icon src={ClockIcon} size="small" color={value ? 'primary' : 'gray'} />
        </Stack>
      </PickerBox>
      {!isMobile ? (
        <PickerMenu anchorEl={anchorEl} isOpen={open} onClose={handleClose}>
          <TimePickerContent
            hour={hour}
            hoursToShow={hoursToShow}
            handleHourClick={handleHourClick}
            handleClearTime={clearTime}
          />
        </PickerMenu>
      ) : (
        <PickerDrawer isOpen={Boolean(anchorEl)} onClose={handleClose}>
          <TimePickerContent
            hour={hour}
            hoursToShow={hoursToShow}
            handleHourClick={handleHourClick}
            handleClearTime={clearTime}
            isMobile={true}
          />
        </PickerDrawer>
      )}
    </>
  )
}

export default TimePicker
