import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import RadioButton, { RadioButtonProps } from '@eversports/klimt-primitives/RadioButton'

import { MatchVisibility } from '../../../Match.types'

import MatchVisibilityToggleRadioButtonLabel from './MatchVisibilityToggleRadioButtonLabel'

type RadioProps = Pick<RadioButtonProps, 'onClick'>

interface Props extends RadioProps {
  currentVisibility?: MatchVisibility
  value: MatchVisibility
  isDisabled: boolean
}

const MatchVisibilityToggleRadioButton = ({ value, currentVisibility, onClick, isDisabled }: Props) => {
  const isSelected = value === currentVisibility
  return (
    <RadioButton
      value={value}
      label={<MatchVisibilityToggleRadioButtonLabel value={value} isSelected={isSelected} isDisabled={isDisabled} />}
      onClick={onClick}
      disabled={isDisabled}
      sx={{
        paddingX: 3,
        paddingY: 1,
        margin: 0,
        flex: 1,
        width: '100%',
        border: '1px solid',
        borderColor: isSelected && !isDisabled ? 'primary.main' : 'mediumgray',
        borderRadius: 10,
        boxShadow: boxShadows.default,
        cursor: isDisabled ? 'cursor' : 'pointer',
        '> label': {
          width: '100%',
        },
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
        '& .MuiRadio-root': {
          flexShrink: 0,
        },
        '&:hover': {
          borderColor: isDisabled ? 'mediumgray' : 'primary.main',
        },
      }}
    />
  )
}

export default MatchVisibilityToggleRadioButton
