import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Link from '@eversports/klimt-primitives/Link'
import Box from '@eversports/klimt-primitives/Box'
import Svg from '@eversports/klimt-primitives/Svg/Svg'

import { Localized } from '../../../localization/react'
import ContentWrapper from '../components/ContentWrapper'
import { Venue } from '../ActivityDetails.types'
import { VenueProfileTabsPrefix } from '../../../App.types'

import Reviews from './Reviews'
import ExternalLinkIcon from './assets/external-link.svg'

interface Props {
  venue: Venue
}

const VenueInformation = ({ venue }: Props) => {
  const { name, slug, logo } = venue
  return (
    <ContentWrapper>
      <Stack gap={4} sx={{ overflow: 'hidden' }}>
        <Heading is="h2" variant="small" color="midnight">
          <Localized id="activity-details-venue-information-header" />
        </Heading>
        <Stack gap={6}>
          <Stack direction="row" gap={5}>
            <Link to={`${VenueProfileTabsPrefix.OVERVIEW}${slug}`} external blank ariaLabel={name}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Box sx={{ border: '1px solid', borderColor: 'mediumgray', borderRadius: 1, overflow: 'hidden' }}>
                  <img
                    src={logo?.xSmall}
                    width={40}
                    height={40}
                    loading="lazy"
                    alt={name}
                    style={{ objectFit: 'contain' }}
                  />
                </Box>
                <Text
                  color="midnight"
                  sx={{ fontWeight: 'bold', textDecoration: 'underline', '&:hover': { cursor: 'pointer' } }}
                >
                  {name}
                </Text>
                <Box sx={{ marginTop: 1 }}>
                  <Svg src={ExternalLinkIcon} width={12} height={12} />
                </Box>
              </Stack>
            </Link>
          </Stack>
          <Reviews venue={venue} />
        </Stack>
      </Stack>
    </ContentWrapper>
  )
}
export default VenueInformation
