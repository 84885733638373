import { Types, setTransport, flush, createInstance } from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

export type AmplitudeClient = Types.BrowserClient

let client: AmplitudeClient | null = null

interface Args {
  amplitudeProjectApiKey?: string
  sessionReplaySampleRate?: number
}

const init = ({ amplitudeProjectApiKey, sessionReplaySampleRate }: Args) => {
  if (!amplitudeProjectApiKey) {
    throw new Error('No amplitude project api key was set')
  }

  client = createInstance()
  client.init(amplitudeProjectApiKey, { defaultTracking: false })

  if (sessionReplaySampleRate) {
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: sessionReplaySampleRate,
    })
    client.add(sessionReplayTracking)
  }

  window.addEventListener('pagehide', () => {
    setTransport('beacon')
    flush()
  })

  return client
}

export default init
