import { BaseNotification, NotificationBlock } from '../Notifications.types'

function groupNotificationsByDate(
  notifications: Array<BaseNotification>,
): Array<{ date: string; notifications: Array<BaseNotification> }> {
  const groupedNotifications: Array<{ date: string; notifications: Array<BaseNotification> }> = []

  for (const notification of notifications) {
    // Extract the date (without time) as a string from the timestamp
    const date = new Date(notification.created)
    const dateKey = date.toISOString().split('T')[0]

    // Check if the dateKey already exists in the groupedObjects array
    const existingNotifications = groupedNotifications.find((group) => group.date === dateKey)

    if (existingNotifications) {
      // If it exists, push the object into the existing group's objects array
      existingNotifications.notifications.push(notification)
    } else {
      // If it doesn't exist, create a new group with a "date" property and an "objects" array
      groupedNotifications.push({ date: dateKey, notifications: [notification] })
    }
  }

  return groupedNotifications
}

const getNotificationBlocks = (notifications: Array<BaseNotification>): Array<NotificationBlock> => {
  return groupNotificationsByDate(notifications)
}

export default getNotificationBlocks
