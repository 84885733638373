import * as React from 'react'

import CollapsibleFilter from '../../../../Filters/components/CollapsibleFilter'
import { Localized } from '../../../../../../localization/react'
import { Action } from '../../helpers/discover-sport-matches-reducer'
import { MatchVenue } from '../../../../../../App.types'

import VenuesFilterForm from './VenuesFilterForm'

interface Props {
  dispatch: React.Dispatch<Action>
  venues: Array<MatchVenue>
  selectedVenues?: Array<string>
}

export const CollapsibleVenuesFilter = ({ dispatch, venues, selectedVenues }: Props) => {
  return (
    <CollapsibleFilter header={<Localized id="filters-matches-venues-heading" />}>
      <VenuesFilterForm dispatch={dispatch} venues={venues} selectedVenues={selectedVenues} />
    </CollapsibleFilter>
  )
}

export default CollapsibleVenuesFilter
