import * as React from 'react'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import { Localized } from '../../../../../../localization/react'
import FullscreenDialog from '../../../../../../components/FullscreenDialog'

import SearchContent from './SearchContent'

interface Props {
  isOpen: boolean
  toggleDialog: () => void
}

const Search = ({ isOpen, toggleDialog }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return isMobile ? (
    <FullscreenDialog title={<Localized id="find-new-friends-title" />} isOpen={isOpen} onClose={toggleDialog}>
      <SearchContent />
    </FullscreenDialog>
  ) : (
    <Dialog open={isOpen} onClose={toggleDialog}>
      <DialogContent sx={{ height: '672px', padding: 0 }}>
        <SearchContent />
      </DialogContent>
    </Dialog>
  )
}

export default Search
