import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import { Localized } from '../localization/react'
import { ManagerLinksMapping } from '../App.constants'

const EversportsFlywheelLinks = () => {
  const { tld } = useSettingsContext()
  const { business, corporate } = ManagerLinksMapping[tld]
  return (
    <>
      <Link to={corporate} external>
        <Box
          sx={{
            borderRadius: 1,
            paddingX: 4,
            paddingY: 2,
            border: '1px solid',
            borderColor: 'primary.main',
            backgroundColor: 'rgb(0 67 73 / 90%)',
            '&:hover': { boxShadow: boxShadows.hoverPrimary },
          }}
        >
          <Text variant="small" color="white" sx={{ fontWeight: 600 }}>
            <Localized id="footer-corporate-title" />
          </Text>
          <Text variant="small" color="white" sx={{ opacity: 0.7 }}>
            <Localized id="footer-corporate-description" />
          </Text>
        </Box>
      </Link>
      <Link to={business} external>
        <Box
          sx={{
            borderRadius: 1,
            paddingX: 4,
            paddingY: 2,
            border: '1px solid',
            borderColor: 'primary.main',
            backgroundColor: '#004349',
            '&:hover': { boxShadow: boxShadows.hoverPrimary },
          }}
        >
          <Text variant="small" color="white" sx={{ fontWeight: 600 }}>
            <Localized id="footer-provider-title" />
          </Text>
          <Text variant="small" color="white" sx={{ opacity: 0.7 }}>
            <Localized id="footer-provider-description" />
          </Text>
        </Box>
      </Link>
    </>
  )
}

export default EversportsFlywheelLinks
