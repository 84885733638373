import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../localization/react'

import InfoIcon from './assets/info.svg'

interface Props {
  showInfoIcon?: boolean
}

const CorporateBadgeComponent = ({ showInfoIcon = false }: Props) => (
  <Stack
    sx={{
      gap: 1,
      px: 2,
      py: 1,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'rgba(242, 153, 74, 0.08)',
      border: '1px solid rgba(242, 153, 74, 0.16)',
      borderRadius: 10,
      width: 'fit-content',
    }}
  >
    <Text variant="small" sx={{ fontWeight: 'bold', color: 'tertiary' }}>
      <Localized id="corporate-deal-label" />
    </Text>
    {showInfoIcon && (
      <img src={InfoIcon} width="14px" height="14px" alt="Information on corporate benefits" loading="lazy" />
    )}
  </Stack>
)

export default CorporateBadgeComponent
