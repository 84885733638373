import * as React from 'react'
import Box, { BoxProps } from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

type SxProp = Pick<BoxProps, 'sx'>

const Card = ({ children, sx }: React.PropsWithChildren<SxProp>) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        transition: 'ease-in-out',
        border: { xs: 0, sm: '0.5px solid' },
        borderRadius: { xs: 0, sm: 2 },
        borderColor: { xs: 'none', sm: 'lightgray' },
        boxShadow: boxShadows.default,
        width: '100%',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default Card
