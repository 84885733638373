import * as React from 'react'
import { assertNever } from '@eversports/klimt-utilities/assert-never'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import { BallsportMatchState, UserType } from '../Match.types'
import useLoggedInUserType from '../hooks/use-logged-in-user-type'

import OrganizerBannerSectionMessage from './OrganizerBannerSectionMessage'
import ParticipantBannerSectionMessage from './ParticipantBannerSectionMessage'

interface Props {
  sportName: string
  bookingState: BallsportMatchState
}

const BannerSectionMessage = ({ bookingState, sportName }: Props) => {
  const { userType } = useLoggedInUserType()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  switch (userType) {
    case UserType.ORGANIZER:
      return <OrganizerBannerSectionMessage bookingState={bookingState} sportName={sportName} isMobile={isMobile} />
    case UserType.PARTICIPANT:
    case UserType.EVERSPORTS_GUEST:
    case UserType.EXTERNAL_GUEST:
      return <ParticipantBannerSectionMessage bookingState={bookingState} sportName={sportName} isMobile={isMobile} />
    default:
      return assertNever(userType)
  }
}

export default BannerSectionMessage
