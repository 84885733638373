export const CUSTOM_BREAKPOINT_1 = 'CUSTOM_BREAKPOINT_1'
export const CUSTOM_BREAKPOINT_2 = 'CUSTOM_BREAKPOINT_2'
export const CUSTOM_BREAKPOINT_3 = 'CUSTOM_BREAKPOINT_3'
export const CUSTOM_BREAKPOINT_4 = 'CUSTOM_BREAKPOINT_4'
export const CUSTOM_BREAKPOINT_5 = 'CUSTOM_BREAKPOINT_5'
export const CUSTOM_BREAKPOINT_6 = 'CUSTOM_BREAKPOINT_6'
export const CUSTOM_BREAKPOINT_7 = 'CUSTOM_BREAKPOINT_7'

export const customBreakpoints = {
  CUSTOM_BREAKPOINT_1: 768, // iPad mini Vertical
  CUSTOM_BREAKPOINT_2: 820, // iPad Air Vertical
  CUSTOM_BREAKPOINT_3: 1180, // iPad Air Horizontal
  CUSTOM_BREAKPOINT_4: 1280,
  CUSTOM_BREAKPOINT_5: 1330,
  CUSTOM_BREAKPOINT_6: 1440, // laptop
  CUSTOM_BREAKPOINT_7: 1720, // large monitor
}

export type CustomBreakpointType = keyof typeof customBreakpoints
