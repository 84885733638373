import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'

interface Props {
  variant: 'medium' | 'large'
  src: string
}

const RecapOverviewImage = ({ src, variant }: Props) => {
  const size = variant === 'medium' ? '56px' : { xs: '240px', md: '280px' }
  return (
    <Box
      component="img"
      src={src}
      width={size}
      height={size}
      marginBottom={variant === 'large' ? '-40px' : 0} // the images have a weird empty bottom spacing
      loading="lazy"
    />
  )
}

export default RecapOverviewImage
