import * as React from 'react'
import { useState } from 'react'

import { BaseNotification } from '../../Notifications.types'
import NotificationContent from '../NotificationContent'
import { useNotificationPromotionMeQuery } from '../../../../graphql'

import NotificationPromotionDialog from './NotificationPromotionDialog'

interface Props {
  notification: BaseNotification
  onClick: () => void
}

const NotificationPromotion = ({ notification, onClick }: Props) => {
  const { data } = useNotificationPromotionMeQuery()
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(!isOpen)
    onClick()
  }

  if (!data || !data?.me) {
    return null
  }

  return (
    <>
      <NotificationPromotionDialog
        isOpen={isOpen}
        toggleDialog={handleClick}
        userHandle={data.me.handle}
        friendsCount={data.me.friendsCount}
      />
      <NotificationContent notification={notification} onClick={handleClick} />
    </>
  )
}

export default NotificationPromotion
