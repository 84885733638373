import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import * as React from 'react'
import { useState } from 'react'

import { Localized } from '../../../../../localization/react'

import AllUpcomingParticipations from './AllUpcomingParticipations'

const ShowAllUpcomingParticipations = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Button
        variant="tertiary"
        sx={{ px: 6, py: 1, width: 'fit-content', border: '1px solid', borderColor: 'mediumgray', margin: '0 auto' }}
        onClick={handleClick}
      >
        <Text variant="small" sx={{ fontWeight: 'bold', textTransform: 'none', color: 'midnight' }}>
          <Localized id="user-profile-upcoming-bookings-show-all-button" />
        </Text>
      </Button>
      <AllUpcomingParticipations isOpen={isOpen} onClose={handleClose} />
    </>
  )
}

export default ShowAllUpcomingParticipations
