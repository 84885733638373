import * as React from 'react'
import { useEffect, useState } from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import { useParams } from 'react-router-dom'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../../localization/react'
import useLoggedInUser from '../../../../hooks/useLoggedInUser'
import {
  useVenueProfileAddVenueToFavouritesMutation,
  useVenueProfileGetIsVenueFavouriteQuery,
  useVenueProfileRemoveVenueFromFavouritesMutation,
  useVenueProfileFavoriteButtonVenueContextQuery,
} from '../../../../graphql'
import HeartFilledIcon from '../../assets/heart-filled-icon.svg'
import HeartOutlineIcon from '../../assets/heart-outline-icon.svg'
import { AuthRoutes } from '../../../../components/Header/UserMenu/UserMenu.constants'
import Drawer from '../../../../components/Drawer'
import LoginOrRegisterDisclaimer from '../../../../components/LoginOrRegisterDisclaimer'

import FavoriteAndShareButtonsBox from './components/FavoriteAndShareButtonsBox'

const VenueProfileFavoriteButton = () => {
  const { venueSlug } = useParams<{ venueSlug: string }>()
  const { getLoggedInUser, user } = useLoggedInUser()
  const { data: venueData } = useVenueProfileFavoriteButtonVenueContextQuery({ variables: { slug: venueSlug } })

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { amplitude } = useAmplitude()

  const [isFavourite, setIsFavourite] = useState<boolean | undefined>(undefined)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const venueId = venueData?.venueContext?.venue?.id

  useEffect(() => {
    getLoggedInUser()
  }, [])

  const { data: isVenueFavouriteResult } = useVenueProfileGetIsVenueFavouriteQuery({
    variables: {
      id: venueId as string,
    },
    skip: !user.data,
  })

  useEffect(() => {
    if (isVenueFavouriteResult) {
      setIsFavourite(isVenueFavouriteResult.venue.isFavourite)
    }
  }, [isVenueFavouriteResult])

  const [addToFavourites, { loading: addToFavouritesLoading }] = useVenueProfileAddVenueToFavouritesMutation({
    variables: { venueId: venueId as string },
    optimisticResponse: {
      addVenueToFavourites: {
        __typename: 'Venue',
        id: venueId as string,
        isFavourite: true,
      },
    },
    onCompleted: (data) => {
      setIsFavourite(data?.addVenueToFavourites.isFavourite)
      if (amplitude && venueData) {
        amplitude.logEvent('Add venue to favorites', {
          venue: {
            name: venueData.venueContext.venue.name,
            slug: venueData.venueContext.venue.slug,
            rating: venueData.venueContext.venue.rating,
            reviewCount: venueData.venueContext.venue.reviewCount,
            numberOfImages: venueData.venueContext.venue.images.length,
          },
        })
      }
    },
  })

  const [removeFromFavourites, { loading: removeFromFavouritesLoading }] =
    useVenueProfileRemoveVenueFromFavouritesMutation({
      variables: { venueId: venueId as string },
      optimisticResponse: {
        removeVenueFromFavourites: {
          __typename: 'Venue',
          id: venueId as string,
          isFavourite: false,
        },
      },
      onCompleted: (data) => {
        setIsFavourite(data?.removeVenueFromFavourites.isFavourite)
        if (amplitude && venueData) {
          amplitude.logEvent('Remove venue from favorites', {
            venue: {
              name: venueData.venueContext.venue.name,
              slug: venueData.venueContext.venue.slug,
              rating: venueData.venueContext.venue.rating,
              reviewCount: venueData.venueContext.venue.reviewCount,
              numberOfImages: venueData.venueContext.venue.images.length,
            },
          })
        }
      },
    })

  if (!venueId) {
    return null
  }

  const isLoading = addToFavouritesLoading || removeFromFavouritesLoading

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen)
  }

  const handleLoginClick = () => {
    const urlParams = `?redirectPath=${encodeURIComponent(window.location.pathname + window.location.search)}`
    window.location.href = `${window.location.origin}${AuthRoutes.REGISTER}${urlParams}`
  }

  const handleClick = () => {
    if (!user.data) {
      toggleDialog()
      return
    }

    if (isFavourite) {
      void removeFromFavourites()
    } else {
      void addToFavourites()
    }
  }

  return (
    <FavoriteAndShareButtonsBox onClick={handleClick}>
      <Stack direction="row" alignItems="center" gap={3}>
        <Text variant="xsmall" sx={{ fontWeight: 'bold', color: 'black', display: { xs: 'none', sm: 'initial' } }}>
          <Localized id={isFavourite ? 'venue-is-in-favorites' : 'add-venue-to-favorites'} />
        </Text>
        <img
          src={isFavourite ? HeartFilledIcon : HeartOutlineIcon}
          width="18px"
          height="18px"
          loading="lazy"
          alt={isFavourite ? 'Favorited heart' : 'Unfavorited heart'}
          style={{ transition: 'all ease-in-out', transform: isLoading ? 'scale(0.9)' : 'none' }}
        />
      </Stack>
      {isMobile ? (
        <Drawer open={isDialogOpen} onClose={toggleDialog}>
          <LoginOrRegisterDisclaimer handleClick={handleLoginClick} descriptionType="favoriting-venue" />
        </Drawer>
      ) : (
        <Dialog open={isDialogOpen} onClose={toggleDialog} maxWidth="xs">
          <DialogContent>
            <LoginOrRegisterDisclaimer handleClick={handleLoginClick} descriptionType="favoriting-venue" />
          </DialogContent>
        </Dialog>
      )}
    </FavoriteAndShareButtonsBox>
  )
}

export default VenueProfileFavoriteButton
