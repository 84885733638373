import BricolageGrotesqueMedium from './BricolageGrotesque-Medium.woff2'
import BricolageGrotesqueSemibold from './BricolageGrotesque-SemiBold.woff2'

const BricolageGrotesque = `
  @font-face {
    font-family: 'Bricolage Grotesque';
    src: url(${BricolageGrotesqueMedium}) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Bricolage Grotesque';
    src: url(${BricolageGrotesqueSemibold}) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
`

export default BricolageGrotesque
