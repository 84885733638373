import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

import { Contact } from '../../VenueProfile.types'
import SectionWrapper from '../../components/SectionWrapper'

import CorporateDisclaimer from './CorporateDisclaimer'
import ContactIcons from './ContactIcons'

interface Props {
  contact: Contact
}

const ContactInformation = ({ contact }: Props) => {
  return (
    <SectionWrapper sx={{ p: 4 }}>
      <Stack gap={4}>
        <ContactIcons contact={contact} />
        <CorporateDisclaimer />
      </Stack>
    </SectionWrapper>
  )
}

export default ContactInformation
