import { PrivacySettingVisibility } from '../../../../App.types'

export const visibilityTypeTranslation = {
  [PrivacySettingVisibility.PUBLIC]: 'visibility-setting-title-public',
  [PrivacySettingVisibility.PRIVATE]: 'visibility-setting-title-private',
  [PrivacySettingVisibility.FRIEND]: 'visibility-setting-title-friend',
} as const

export const visibilityTypeDescriptionTranslation = {
  [PrivacySettingVisibility.PUBLIC]: 'visibility-setting-description-public',
  [PrivacySettingVisibility.PRIVATE]: 'visibility-setting-description-private',
  [PrivacySettingVisibility.FRIEND]: 'visibility-setting-description-friend',
} as const
