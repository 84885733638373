import { useLocation } from 'react-router-dom'

function isJSON(value: string) {
  try {
    JSON.parse(value)
  } catch (e) {
    return false
  }
  return true
}

function getParsedSearchParams() {
  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)
  const searchParamsMap = new Map()

  for (const [key, value] of searchParams.entries()) {
    if (isJSON(value)) {
      searchParamsMap.set(key, JSON.parse(decodeURIComponent(value)))
    } else {
      searchParamsMap.set(key, decodeURIComponent(value))
    }
  }

  return searchParamsMap
}

export default getParsedSearchParams
