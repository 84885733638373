import * as React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { useNotificationsMarkAllAsSeenMutation, useNotificationsMeQuery } from '../../graphql'
import LoadingPage from '../../components/LoadingPage'
import { AuthRoutes } from '../../App.constants'

import NotificationsComponent from './NotificationsComponent'
import { NUMBER_OF_NOTIFICATIONS_TO_LOAD } from './Notifications.constants'

const Notifications = () => {
  const { pathname, search } = useLocation()
  const { amplitude } = useAmplitude()
  const { data, loading } = useNotificationsMeQuery({ variables: { first: NUMBER_OF_NOTIFICATIONS_TO_LOAD } })
  const [markAllNotificationsAsSeen] = useNotificationsMarkAllAsSeenMutation()

  useEffect(() => {
    if (!data) return
    void markAllNotificationsAsSeen()
  }, [data])

  useEffect(() => {
    if (!amplitude || !data?.me) return
    amplitude.logEvent('Viewed Notifications Center', {
      numberOfPendingNotifications: data?.me?.unreadNotificationsCount,
    })
  }, [amplitude])

  if (!data || loading) {
    return <LoadingPage />
  }

  if (!data.me) {
    if (typeof window !== 'undefined') {
      window.location.href = `${AuthRoutes.LOGIN}?redirectPath=${encodeURIComponent(pathname + search)}`
    }
    return <LoadingPage />
  }

  return (
    <NotificationsComponent
      initiallyFetchedNotifications={data.me.notifications}
      unreadNotificationsCount={data.me.unreadNotificationsCount}
    />
  )
}

export default Notifications
