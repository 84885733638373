import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import Button from '@eversports/klimt-primitives/Button'
import Link from '@eversports/klimt-primitives/Link'
import Processing from '@eversports/klimt-primitives/Processing'

import { Localized } from '../../localization/react'

import { PRIVACY_SETTINGS_INTERCOM_LINK } from './PublicProfilePrivacyTermsDisclaimer.constants'

interface Props {
  onClick: () => void
  isLoading: boolean
}

const PublicProfilePrivacyTermsDisclaimerComponentContent = ({ onClick, isLoading }: Props) => {
  return (
    <Processing isLoading={isLoading}>
      <Stack gap={4}>
        <Heading is="h3" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          <Localized id="friends-privacy-update-title" />
        </Heading>
        <Text variant="small" sx={{ color: 'darkgray' }}>
          <Localized id="friends-privacy-update-paragraph-1" />
        </Text>
        <Text variant="small" sx={{ color: 'darkgray' }}>
          <Localized
            id="friends-privacy-update-paragraph-2"
            params={{
              privacyPolicyLink: (
                <Link to="/h/privacy" external blank>
                  <Text variant="label" component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
                    <Localized id="friends-privacy-update-privacy-policy-link" />
                  </Text>
                </Link>
              ),
            }}
          />
        </Text>
        <Text variant="small" sx={{ color: 'darkgray' }}>
          <Localized
            id="friends-privacy-update-paragraph-3"
            params={{
              privacySettingsLink: (
                <Link to={PRIVACY_SETTINGS_INTERCOM_LINK} external blank>
                  <Text variant="label" component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
                    <Localized id="friends-privacy-adjust-privacy-settings-link" />
                  </Text>
                </Link>
              ),
            }}
          />
        </Text>
        <Text variant="small" sx={{ color: 'darkgray' }}>
          <Localized id="friends-privacy-update-paragraph-4" />
        </Text>
        <Button variant="primary" sx={{ p: 3 }} onClick={onClick}>
          <Text variant="small" sx={{ fontWeight: 'bold', color: 'white', textTransform: 'none' }}>
            <Localized id="friends-privacy-update-confirmation-button" />
          </Text>
        </Button>
      </Stack>
    </Processing>
  )
}

export default PublicProfilePrivacyTermsDisclaimerComponentContent
