import * as React from 'react'
import { useState } from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Box from '@eversports/klimt-primitives/Box'

import { useIntl } from '../../../../../localization/react'

import ShareIcon from './assets/share.svg'
import ShareActions from './ShareActions'

interface Props {
  eventId: string
}
const ShareButton = ({ eventId }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const intl = useIntl()

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen)
  }

  return (
    <Box sx={{ width: '40px' }}>
      <Button
        onClick={toggleDialog}
        variant="tertiary"
        sx={{ padding: 3, height: 'fit-content', flexShrink: 0, minWidth: '0px' }}
      >
        <img src={ShareIcon} width="12px" height="12px" alt={intl.shareActivityDetail()} />
      </Button>
      <ShareActions isOpen={isDialogOpen} toggleDialog={toggleDialog} eventId={eventId} />
    </Box>
  )
}

export default ShareButton
