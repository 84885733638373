import { Locales } from '../../../localization'

interface Props {
  dateString: string
  language: Locales
}

export const formatShortDate = ({ dateString, language }: Props) => {
  const date = new Date(dateString)
  return date.toLocaleDateString(language, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })
}
