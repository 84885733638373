import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../../localization/react'

const RequestedFriendship = () => {
  return (
    <Box sx={{ paddingY: { xs: 3, md: 2 }, paddingX: 6, backgroundColor: 'lightgray', borderRadius: 10 }}>
      <Text sx={{ color: 'carbon' }}>
        <Localized id="friendship-requested" />
      </Text>
    </Box>
  )
}

export default RequestedFriendship
