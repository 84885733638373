import * as React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import Routes from '../App.routes'

import UserInterviewRegistration from './UserInterviewRegistration'
import RecommendationsPage from './Recommendations'
import NotFoundPage from './NotFound'
import TechnicalNotesPage from './TechnicalNotes'
import VenueProfilePage from './VenueProfile'
import InvoiceHistoryPage from './InvoiceHistory'
import MatchPage from './Match'
import DiscoverCityPage from './Discover/pages/DiscoverCity'
import DiscoverVenuesPage from './Discover/pages/DiscoverVenues'
import DiscoverSportMatchesPage from './Discover/pages/DiscoverSportMatches'
import HomePage from './Home'
import ActivityDetailsPage from './ActivityDetails'
import PublicUserProfilePage from './PublicUserProfile'
import Notifications from './Notifications'
import YearlyRecap from './YearlyRecap'
import DataSubProcessors from './DataSubProcessors'

const Pages = () => {
  const location = useLocation()

  return (
    <Switch location={location}>
      <Route path={[Routes.SEO_DISCOVER_SPORT_CITY, Routes.APP_DISCOVER_SPORT_CITY]}>
        <DiscoverVenuesPage />
      </Route>
      <Route path={Routes.DISCOVER_CITY}>
        <DiscoverCityPage />
      </Route>
      <Route path={Routes.RECOMMENDATIONS}>
        <RecommendationsPage />
      </Route>
      <Route path={Routes.TECHNICAL_NOTES}>
        <TechnicalNotesPage />
      </Route>
      <Route path={Routes.DATA_SUB_PROCESSORS}>
        <DataSubProcessors />
      </Route>
      <Route path={Routes.VENUE_PROFILE}>
        <VenueProfilePage />
      </Route>
      <Route path={Routes.INVOICE_HISTORY}>
        <InvoiceHistoryPage />
      </Route>
      <Route path={Routes.MATCH_DETAILS}>
        <MatchPage />
      </Route>
      <Route path={Routes.DISCOVER_SPORT_MATCHES_CITY}>
        <DiscoverSportMatchesPage />
      </Route>
      <Route path={Routes.ACTIVITY_DETAILS_DEPRECATED}>
        <ActivityDetailsPage />
      </Route>
      <Route path={Routes.ACTIVITY_DETAILS}>
        <ActivityDetailsPage />
      </Route>
      <Route path={Routes.YEARLY_RECAP}>
        <YearlyRecap />
      </Route>
      <Route path={Routes.PUBLIC_USER_PROFILE}>
        <PublicUserProfilePage />
      </Route>
      <Route path={Routes.HOME} exact>
        <HomePage />
      </Route>
      <Route path={Routes.USER_INTERVIEW_REGISTRATION}>
        <UserInterviewRegistration />
      </Route>
      <Route path={Routes.NOTIFICATIONS}>
        <Notifications />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  )
}

export default Pages
