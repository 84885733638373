import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import { assertNever } from '@eversports/klimt-utilities/assert-never'

import { ActionWrapperVariant } from './AdditionalActions.types'

interface Props {
  onClick?: (value: unknown) => void
  isDisabled?: boolean
  variant?: ActionWrapperVariant
}

const ActionWrapper = ({ children, onClick, isDisabled, variant = 'contained' }: React.PropsWithChildren<Props>) => {
  switch (variant) {
    case 'contained':
      return (
        <Stack
          py={2}
          px={4}
          gap={1}
          direction="row"
          flex="initial"
          width="fit-content"
          alignItems="center"
          justifyContent="center"
          onClick={onClick}
          sx={{
            border: '1px solid',
            borderColor: 'lightgray',
            boxShadow: boxShadows.default,
            cursor: 'pointer',
            borderRadius: 1,
            textAlign: 'center',
            minWidth: '100px',
            '&:hover': {
              boxShadow: isDisabled ? 'none' : '0px 4px 4px rgb(20 20 20 / 20%)',
            },
            pointerEvents: isDisabled ? 'none' : 'auto',
            opacity: isDisabled ? 0.5 : 1,
          }}
        >
          {children}
        </Stack>
      )
    case 'expanded':
      return (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          onClick={onClick}
          gap={3}
          p={5}
          sx={{
            borderBottom: '1px solid',
            borderColor: 'mediumgray',
          }}
        >
          {children}
        </Stack>
      )
    default:
      return assertNever(variant)
  }
}

export default ActionWrapper
