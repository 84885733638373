import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import Link from '@eversports/klimt-primitives/Link'

import { Localized, useIntl } from '../../../../localization/react'
import getLinkToCheckout from '../../helpers/get-link-to-checkout'
import { EventRegistrationValidationProblem, RegistrationState } from '../../ActivityDetails.types'

interface Props {
  bookableItemId: string
  venueId: string
  registrationState: RegistrationState
}

const BookNowButton = ({ bookableItemId, venueId, registrationState }: Props) => {
  const intl = useIntl()

  const isBookable = registrationState.bookable

  if (isBookable) {
    const checkoutUrl = getLinkToCheckout({
      bookableItemId,
      venueId,
      origin: 'eversport',
    })
    return (
      <Link to={checkoutUrl} external ariaLabel={intl.activityDetailsBookNow()}>
        <Button variant="primary" sx={{ width: '100%', height: '44px' }} data-testid="activity-details-book-now-action">
          <Text color="white" variant="large" sx={{ textTransform: 'none', fontWeight: 'bold' }}>
            <Localized id="activity-details-book-now" />
          </Text>
        </Button>
      </Link>
    )
  }

  const isRegistered = registrationState.validationProblems.includes(
    EventRegistrationValidationProblem.ALREADY_REGISTERED,
  )

  if (isRegistered) {
    return (
      <Button variant="primary" disabled sx={{ width: '100%', height: '44px' }}>
        <Text color="white" variant="large" sx={{ textTransform: 'none', fontWeight: 'bold' }}>
          <Localized id="activity-details-already-registered" />
        </Text>
      </Button>
    )
  }

  return (
    <Button variant="primary" disabled sx={{ width: '100%', height: '44px' }}>
      <Text color="white" variant="large" sx={{ textTransform: 'none', fontWeight: 'bold' }}>
        <Localized id="activity-details-not-bookable" />
      </Text>
    </Button>
  )
}

export default BookNowButton
