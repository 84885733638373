import * as React from 'react'
import { useState, useEffect } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../localization/react'

import mapIcon from './assets/Map.svg'
import ListIcon from './assets/List.svg'
import { useDiscoverReducer, useDiscoverState } from './DiscoverContext'

const DiscoverViewToggle = () => {
  const dispatch = useDiscoverReducer()
  const [previousYCoordinate, setPreviousYCoordinate] = useState(0)
  const { showMobileMapView } = useDiscoverState()
  const { amplitude } = useAmplitude()

  const handleClick = () => {
    if (amplitude) {
      const toggleName = showMobileMapView ? 'List' : 'Map'
      amplitude.logEvent(`Clicked Discover Show ${toggleName}`)
    }
    dispatch({ type: 'TOGGLE_MAP_VIEW' })
  }

  useEffect(() => {
    if (showMobileMapView) {
      setPreviousYCoordinate(window.scrollY)
      window.scrollTo(0, 0)
    } else {
      window.scrollTo(0, previousYCoordinate)
    }
  }, [showMobileMapView])

  return (
    <Stack
      onClick={handleClick}
      gap={2}
      alignItems="center"
      justifyContent="center"
      direction="row"
      sx={{
        py: 3,
        px: 8,
        zIndex: 2,
        borderRadius: 10,
        backgroundColor: 'midnight',
        color: 'white',
        position: 'fixed',
        bottom: '32px',
        right: '50%',
        width: 'max-content',
        transform: 'translateX(50%)',
        display: { xs: 'flex', lg: 'none' },
      }}
    >
      <img
        src={showMobileMapView ? ListIcon : mapIcon}
        loading="lazy"
        alt={showMobileMapView ? 'List View' : 'Map View'}
        width="14px"
        height="14px"
      />
      <Text variant="large" color="white" sx={{ fontWeight: 'bold' }}>
        {showMobileMapView ? <Localized id="list-toggle-button" /> : <Localized id="map-toggle-button" />}
      </Text>
    </Stack>
  )
}

export default DiscoverViewToggle
