async function getNavigatorGeoLocation(): Promise<{ latitude: number; longitude: number } | undefined> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (geoPosition: GeolocationPosition) => {
        const position = {
          latitude: geoPosition.coords.latitude,
          longitude: geoPosition.coords.longitude,
        }
        resolve(position)
      },
      () => {
        reject()
      },
    )
  })
}

export default getNavigatorGeoLocation
