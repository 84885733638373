import * as React from 'react'
import { AccordionSummaryProps, default as MuiAccordionSummary } from '@mui/material/AccordionSummary'
import styled from '@eversports/klimt-design-components/styled'
import AccordionChevron from '@eversports/design-tokens/assets/icons/chevron-down.svg'

const ExpandAccordionIcon = () => <img src={AccordionChevron} alt="toggle accordion icon" loading="lazy" />

const StyledAccordionSummary = styled(MuiAccordionSummary)`
  height: auto;
  min-height: 0 !important;
  margin: 0;
  padding: 0px 16px;

  .Mui-expanded {
    margin: 12px 0;
  }
`

const AccordionSummary: React.FC<React.PropsWithChildren<AccordionSummaryProps>> = (props) => {
  return <StyledAccordionSummary {...props} expandIcon={<ExpandAccordionIcon />} />
}

export default AccordionSummary
