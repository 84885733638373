import { UserType } from './PublicUserProfile.types'

export const userTypeMapping: { [key in UserType]: string } = {
  [UserType.LOGGED_OUT_USER]: 'external guest',
  [UserType.FRIENDS]: 'friend',
  [UserType.EVERSPORTS_USER]: 'eversports guest',
  [UserType.PROFILE_OWNER]: 'owner',
  [UserType.REQUESTED_FRIENDSHIP]: 'eversports guest',
  [UserType.FRIENDSHIP_REQUEST_PENDING]: 'eversports guest',
}

export enum TOOLTIP_TYPE {
  SHARE_PROFILE = 'hasDismissedShareProfileTooltip',
  UPCOMING_BOOKINGS = 'hasDismissedUpcomingBookingsTooltip',
  FRIENDS_OF_USER = 'hasDismissedFriendsOfUserTooltip',
}
