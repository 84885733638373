import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../../localization/react'
import useLoggedInUserType from '../../../hooks/use-logged-in-user-type'
import { UserType } from '../../../PublicUserProfile.types'

const NoFavouriteVenues = () => {
  const { userType } = useLoggedInUserType()

  return (
    <Box padding={4} sx={{ border: '1px dashed', borderColor: 'mediumgray', textAlign: 'center' }}>
      {userType === UserType.PROFILE_OWNER ? (
        <Text variant="small">
          <Localized id="user-profile-no-favourite-venues" />
        </Text>
      ) : (
        <Text variant="small">
          <Localized id="user-profile-friends-no-favourite-venues" />
        </Text>
      )}
    </Box>
  )
}

export default NoFavouriteVenues
