import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../localization/react'
import { BallsportMatchState, UserType } from '../../Match.types'
import { VALID_BALLSPORT_MATCH_STATES } from '../../Match.constants'
import LockIcon from '../../assets/lock.svg'

import BannerSectionBadge from './BannerSectionBadge'

interface Props {
  accessCode: string | null
  userType: UserType
  approvalState: BallsportMatchState
}

const AccessCode = ({ accessCode, userType, approvalState }: Props) => {
  const isValidState = VALID_BALLSPORT_MATCH_STATES.includes(approvalState)

  if (userType !== UserType.ORGANIZER || !isValidState) {
    return null
  }

  return (
    <BannerSectionBadge>
      <Stack alignItems="center" direction="row" sx={{ gap: 1.5 }}>
        <img src={LockIcon} width="11px" height="13px" alt="Access code icon" />
        <Text sx={{ color: 'white' }}>
          <Localized id="access-code" />:
        </Text>
        <Text sx={{ color: 'white' }}>{accessCode}</Text>
      </Stack>
    </BannerSectionBadge>
  )
}

export default AccessCode
