import * as React from 'react'
import { useParams } from 'react-router-dom'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { motion, AnimatePresence } from '@eversports/klimt-utilities/framer-motion'
import { useState } from 'react'

import { useOrganizerNotesMatchQuery } from '../../../../graphql'
import useLoggedInUserType from '../../hooks/use-logged-in-user-type'
import { MatchStatus, UserType } from '../../Match.types'
import { Localized } from '../../../../localization/react'
import useMatchStatus from '../../hooks/use-match-status'

import OrganizerNotesContent from './OrganizerNotesContent'
import { savedMessageVariants, SHOW_SAVED_BADGE_TIME } from './OrganizerNotes.constants'

const OrganizerNotes = () => {
  const { id } = useParams<{ id: string }>()
  const { userType } = useLoggedInUserType()
  const matchStatus = useMatchStatus()
  const [showSaveMessage, setShowSaveMessage] = useState(false)

  const handleSaveNote = () => {
    setShowSaveMessage(true)
    setTimeout(() => {
      setShowSaveMessage(false)
    }, SHOW_SAVED_BADGE_TIME)
  }

  const { data } = useOrganizerNotesMatchQuery({ variables: { matchId: id } })

  const isOrganizer = userType === UserType.ORGANIZER
  const organizerNote = data?.match.organizerNote

  if ((!isOrganizer && !organizerNote) || !data) {
    return null
  }

  const isDisabled = matchStatus !== MatchStatus.UPCOMING

  return (
    <Stack gap={2} width="100%">
      <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
        {isOrganizer ? (
          <Stack direction="row" gap={0.5}>
            <Text sx={{ fontWeight: 'bold', color: 'midnight' }}>
              <Localized id="organizer-note-title-admin" />
            </Text>
          </Stack>
        ) : (
          <Text sx={{ fontWeight: 'bold' }}>
            <Localized id="organizer-note-title-non-admin" />
          </Text>
        )}
        <AnimatePresence>
          {showSaveMessage && (
            <motion.div variants={savedMessageVariants} initial="hidden" animate="visible" exit="hidden">
              <Text variant="badge" sx={{ color: 'primary.light' }}>
                <Localized id="saved-organizer-note" />
              </Text>
            </motion.div>
          )}
        </AnimatePresence>
      </Stack>
      {isOrganizer ? (
        <OrganizerNotesContent handleSaveNote={handleSaveNote} initialNote={organizerNote} isDisabled={isDisabled} />
      ) : (
        <Text sx={{ whiteSpace: 'pre-wrap' }}>{organizerNote}</Text>
      )}
    </Stack>
  )
}

export default OrganizerNotes
