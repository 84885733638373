import * as React from 'react'
import { useEffect, useRef, useState } from 'react'

import Box, { BoxProps } from '../Box/Box'

type BoundaryEdge = keyof Pick<ClientRect, 'top' | 'bottom' | 'left' | 'right'>

interface Props {
  boundaryEdge: BoundaryEdge
  offset?: number
  sx?: BoxProps['sx']
}

const DEFAULT_OFFSET = 16

function useSticky({
  boundaryEdge,
  offset = DEFAULT_OFFSET,
}: Props): [React.MutableRefObject<HTMLDivElement | null>, boolean] {
  const elementRef = useRef<HTMLDivElement>(null)
  const [isSticky, setIsSticky] = useState(false)

  const handleScroll = () => {
    // used to throttle the event since scroll events can fire at a high rate
    window.requestAnimationFrame(() => {
      if (!elementRef.current) return

      const elementRefBoundingClientRect = elementRef.current.getBoundingClientRect()
      const isParentOnOriginalPosition =
        elementRef.current.parentElement &&
        elementRef.current.parentElement.getBoundingClientRect()[boundaryEdge] >= offset
      const isElementTooBig = ['top', 'bottom'].includes(boundaryEdge)
        ? elementRefBoundingClientRect.height + offset >= window.innerHeight
        : elementRefBoundingClientRect.width + offset >= window.innerWidth

      if (isParentOnOriginalPosition || isElementTooBig) {
        setIsSticky(false)
      } else if (elementRefBoundingClientRect[boundaryEdge] <= offset) {
        setIsSticky(true)
      }
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [offset])

  return [elementRef, isSticky]
}

const Sticky: React.FC<React.PropsWithChildren<Props>> = ({ boundaryEdge, offset = DEFAULT_OFFSET, sx, children }) => {
  const [containerRef, isSticky] = useSticky({ boundaryEdge, offset })

  return (
    <Box
      sx={{
        position: isSticky ? 'sticky' : 'initial',
        top: isSticky ? offset : 'initial',
        ...sx,
      }}
      forwardedRef={containerRef}
    >
      {children}
    </Box>
  )
}

export default Sticky
