import * as React from 'react'

import FilterMenu from '../../../../../../components/FilterMenu'
import { Localized } from '../../../../../../localization/react'
import { Action } from '../../helpers/discover-sport-matches-reducer'
import { MatchLevel } from '../../../../../../App.types'
import { matchLevelTranslation } from '../../../../../../App.constants'

import LevelsFilterForm from './LevelsFilterForm'

interface Props {
  dispatch: React.Dispatch<Action>
  selectedLevels?: Array<MatchLevel>
}

const DesktopLevelsFilter = ({ dispatch, selectedLevels }: Props) => {
  const activeFilters = selectedLevels?.map((level) => matchLevelTranslation[level])
  return (
    <FilterMenu
      header={<Localized id="filters-matches-level-heading" />}
      activeFilters={activeFilters}
      minWidth="162px"
    >
      <LevelsFilterForm dispatch={dispatch} selectedLevels={selectedLevels} />
    </FilterMenu>
  )
}

export default DesktopLevelsFilter
