import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Slider from '@eversports/klimt-primitives/Slider'
import Button from '@eversports/klimt-primitives/Button'

import { Localized } from '../../../localization/react'

import { marks } from './NPSBanner.constants'

interface Props {
  score: number | null
  onChange: (value: number) => void
  onButtonClick: () => void
}

const NPSBannerScore = ({ score, onChange, onButtonClick }: Props) => {
  const handleChange = (_event: Event, value: number | Array<number>) => {
    if (typeof value !== 'number') return
    onChange(value)
  }

  return (
    <>
      <Text variant="large" sx={{ fontWeight: 'bold' }}>
        <Localized id="nps-score-title" />
      </Text>
      <Stack gap={2} width="100%">
        <Slider
          aria-label="NPS Score"
          valueLabelDisplay="auto"
          value={score ? score : -1}
          step={1}
          min={0}
          max={10}
          size="medium"
          onChange={handleChange}
          sx={{
            color: score === null ? 'gray' : 'primary.main',
            '& .MuiSlider-thumb': {
              display: score === null ? 'none' : 'flex',
            },
          }}
          marks={marks}
        />
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Text variant="small" sx={{ color: 'gray' }}>
            <Localized id="nps-score-not-at-all-likely" />
          </Text>
          <Text variant="small" sx={{ color: 'gray' }}>
            <Localized id="nps-score-extremely-likely" />
          </Text>
        </Stack>
      </Stack>
      <Button
        variant="primary"
        sx={{ width: '100%', maxWidth: '345px', padding: 3 }}
        onClick={onButtonClick}
        disabled={score === null}
      >
        <Text sx={{ color: 'white', fontWeight: 'bold', textTransform: 'capitalize' }}>
          {score === null ? (
            <Localized id="nps-score-button-no-number-selected" />
          ) : (
            <Localized id="nps-score-button" />
          )}
        </Text>
      </Button>
    </>
  )
}

export default NPSBannerScore
