import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import { Helmet } from 'react-helmet-async'

import useGetTopContentHeight from '../../hooks/useGetTopContentHeight'
import { useIntl } from '../../localization/react'

const TechnicalNotes = () => {
  const intl = useIntl()
  const { language } = useSettingsContext()
  const { DESKTOP_TOP_CONTENT_HEIGHT: TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])
  const pdfUrl = `https://files.eversports.com/technical-notes-${language === 'de' ? 'de' : 'en'}-05-24.pdf`

  return (
    <>
      <Helmet>
        <title>{`Eversports - ${intl.technicalNotesHeading()}`}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Stack
        sx={{
          backgroundColor: 'eggshell',
          height: `calc(100vh - ${TOP_CONTENT_HEIGHT}px)`,
        }}
        component="main"
      >
        <object data={pdfUrl} type="application/pdf" width="100%" height="100%">
          <iframe src={`https://docs.google.com/gview?url=${pdfUrl}&embedded=true`} width="100%" height="100%" />
        </object>
      </Stack>
    </>
  )
}

export default TechnicalNotes
