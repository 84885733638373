import * as React from 'react'

import { CarouselDirection } from '../ImagesGallery'
import Carousel from '../Carousel'
import Thumbnails from '../Carousel/Thumbnails'
import { Image } from '../../../VenueProfile.types'

import LightboxContainer from './LightboxContainer'

interface Props {
  isOpen: boolean
  handleClose: () => void
  images: Array<Image>
  activeImageIndex: number
  setActiveImageIndex: React.Dispatch<React.SetStateAction<number>>
  carouselDirection: CarouselDirection
  setCarouselDirection: React.Dispatch<React.SetStateAction<CarouselDirection>>
}
const Lightbox: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  handleClose,
  images,
  activeImageIndex,
  setActiveImageIndex,
  carouselDirection,
  setCarouselDirection,
}) => {
  return (
    <LightboxContainer isOpen={isOpen} handleClose={handleClose}>
      <Carousel
        images={images}
        activeImageIndex={activeImageIndex}
        setActiveImageIndex={setActiveImageIndex}
        carouselDirection={carouselDirection}
        setCarouselDirection={setCarouselDirection}
        isInLightbox={true}
        handleClose={handleClose}
      />
      <Thumbnails
        images={images}
        activeImageIndex={activeImageIndex}
        setActiveImageIndex={setActiveImageIndex}
        setCarouselDirection={setCarouselDirection}
      />
    </LightboxContainer>
  )
}

export default Lightbox
