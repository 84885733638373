import * as React from 'react'
import Box, { BoxProps } from '@eversports/klimt-primitives/Box'

type SxProps = Pick<BoxProps, 'sx'>

const SectionWrapper = ({ children, sx }: React.PropsWithChildren<SxProps>) => (
  <Box sx={{ ...sx, paddingX: 4, width: '100%', maxWidth: { lg: '1162px' } }}>{children}</Box>
)

export default SectionWrapper
