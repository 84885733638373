import * as React from 'react'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import Drawer from '../../../../components/Drawer'

import useSharingMessage from './hooks/use-sharing-message'
import SharingActionsContent from './SharingActiontsContent'

interface Props {
  isOpen: boolean
  toggleDialog: () => void
  matchId: string
}

const SharingActions = ({ isOpen, toggleDialog, matchId }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const sharingMessage = useSharingMessage()

  return isMobile ? (
    <Drawer open={isOpen} onClose={toggleDialog}>
      <SharingActionsContent sharingMessage={sharingMessage} matchId={matchId} />
    </Drawer>
  ) : (
    <Dialog open={isOpen} onClose={toggleDialog}>
      <DialogContent>
        <SharingActionsContent sharingMessage={sharingMessage} matchId={matchId} />
      </DialogContent>
    </Dialog>
  )
}

export default SharingActions
