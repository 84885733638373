import * as React from 'react'
import { useEffect } from 'react'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import Stack from '@eversports/klimt-primitives/Stack'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import FilterCheckbox, { CheckboxVariant } from '../../../../../../components/FilterCheckbox'
import { Action } from '../../helpers/discover-sport-matches-reducer'
import { MatchCompetitionType } from '../../../../../../App.types'
import { AMPLITUDE_DISCOVER_SPORT_CITY_FILTER_EVENT } from '../../../../Discover.constants'
import { DiscoverFilterTypes } from '../../../../Filters/Filters.types'
import { matchCompetitionTypeTranslation } from '../../../../../../App.constants'
import { Localized } from '../../../../../../localization/react'

interface Props {
  dispatch: React.Dispatch<Action>
  variant?: CheckboxVariant
  selectedCompetitionTypes?: Array<MatchCompetitionType>
}

interface InitialValues {
  competitionTypes?: Array<MatchCompetitionType>
}

const CompetitionTypeFilterForm = ({ dispatch, selectedCompetitionTypes, variant = 'default' }: Props) => {
  const { amplitude } = useAmplitude()
  const methods = useForm<InitialValues>({ defaultValues: { competitionTypes: [] } })

  const newlySelectedCompetitionTypes = methods.watch('competitionTypes')
  const { isDirty } = methods.formState

  useEffect(() => {
    methods.reset({ competitionTypes: selectedCompetitionTypes ?? [] })
  }, [selectedCompetitionTypes])

  useEffect(() => {
    if (!isDirty) return
    dispatch({ type: 'UPDATE_DISCOVER_MATCHES_COMPETITION_TYPE_FILTER', payload: newlySelectedCompetitionTypes })
    if (amplitude) {
      amplitude.logEvent(AMPLITUDE_DISCOVER_SPORT_CITY_FILTER_EVENT, {
        type: DiscoverFilterTypes.competitionType,
        tab: 'match',
      })
    }
  }, [newlySelectedCompetitionTypes])

  return (
    <Form methods={methods}>
      <Stack spacing={2}>
        {Object.keys(MatchCompetitionType).map((competitionType) => (
          <FilterCheckbox
            key={competitionType}
            value={competitionType}
            label={<Localized id={matchCompetitionTypeTranslation[competitionType as MatchCompetitionType]} />}
            name="competitionTypes"
            variant={variant}
          />
        ))}
      </Stack>
    </Form>
  )
}

export default CompetitionTypeFilterForm
