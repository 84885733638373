import * as React from 'react'

import useMediaQueryBreakpoint from '../../../../../hooks/useMediaQueryBreakpoint'
import { Image } from '../../../VenueProfile.types'

import ViewGalleryButton from './ViewGalleryButton'
import GalleryImage from './GalleryImage'
import { GalleryWrapper } from './GalleryWrapper'
import { getNumberOfImagesToShow } from './helpers/get-number-of-images-to-show'

interface Props {
  images: Array<Image>
  setActiveImageIndex: React.Dispatch<React.SetStateAction<number>>
  openLightbox: () => void
}

const Gallery = ({ images, setActiveImageIndex, openLightbox }: Props) => {
  const screenBreakpoint = useMediaQueryBreakpoint()

  const numberOfImagesToShow = getNumberOfImagesToShow(screenBreakpoint)

  const galleryTilePlaceholders = Array.from(Array(numberOfImagesToShow))
  const imageGalleryTiles = galleryTilePlaceholders.map((_, idx) => images?.[idx] ?? undefined)

  const isXs = screenBreakpoint === 'xs'
  const hasMoreImages = images.length > numberOfImagesToShow
  const showViewGalleryButton = !isXs && hasMoreImages

  const openLightboxOnImage = (imageIndex: number) => () => {
    setActiveImageIndex(imageIndex)
    openLightbox()
  }

  return (
    <GalleryWrapper>
      {imageGalleryTiles.map((image, idx) => (
        <GalleryImage key={idx} image={image} idx={idx} onClick={openLightboxOnImage(idx)} />
      ))}
      {showViewGalleryButton && <ViewGalleryButton onClick={openLightboxOnImage(0)} />}
    </GalleryWrapper>
  )
}

export default Gallery
