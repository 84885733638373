import * as React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Box from '@eversports/klimt-primitives/Box'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import Stack from '@eversports/klimt-primitives/Stack'

import { useMatchQuery } from '../../graphql'
import MetaHelmet from '../../components/MetaHelmet'
import LoadingPage from '../../components/LoadingPage'

import BannerSection from './BannerSection'
import PageNavigation from './PageNavigation'
import { PageType } from './Match.types'
import usePageType from './hooks/use-page-type'
import LayoutContainer from './components/LayoutContainer'
import NotFoundMatch from './NotFoundMatch'
import DetailsSection from './DetailsSection'
import ViewMyBookings from './ViewMyBookings'
import useLoggedInUserType from './hooks/use-logged-in-user-type'
import { fromMapping, matchStatusMapping, NUMBER_OF_INITIAL_COMMENTS_FETCHED, userTypeMapping } from './Match.constants'
import ParticipationSection from './ParticipationSection'
import CancellationsConditions from './CancellationConditions'
import NonValidBookingStateDisclaimer from './NonValidBookingStateDisclaimer'
import useMatchStatus from './hooks/use-match-status'
import CallToAction from './ParticipationSection/CallToAction'
import CommentsSection from './CommentsSection'

const Match = () => {
  const { id } = useParams<{ id: string }>()
  const { amplitude } = useAmplitude()
  const { userType } = useLoggedInUserType()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const pageType = usePageType()
  const matchStatus = useMatchStatus()

  const { data, loading, error } = useMatchQuery({
    variables: { matchId: id, first: NUMBER_OF_INITIAL_COMMENTS_FETCHED },
  })

  useEffect(() => {
    if (!amplitude || !data || !matchStatus) return

    amplitude.logEvent('Viewed Match Details', {
      from: fromMapping[pageType],
      sport: data?.match.sport.slug,
      userType: userTypeMapping[userType],
      matchTime: matchStatusMapping[matchStatus],
      venue: {
        name: data?.match.venue.name,
      },
      settings: {
        maxParticipants: data?.match.maxParticipants,
        level: data?.match.level,
        competitionType: data?.match.competitionType,
        gender: data?.match.gender,
        visibility: data?.match.visibility,
      },
    })
  }, [amplitude])

  if (error && error.message === 'Match not found') {
    return <NotFoundMatch />
  }

  if (loading || !data) {
    return <LoadingPage />
  }

  return (
    <>
      <MetaHelmet meta={data.match.meta} noIndex />
      <Box sx={{ flex: 1, backgroundColor: 'limelite' }}>
        <BannerSection />
        <LayoutContainer>
          {pageType !== PageType.THANK_YOU && <PageNavigation pageType={pageType} />}
          <DetailsSection />
          <NonValidBookingStateDisclaimer />
          <ParticipationSection />
          <CommentsSection />
          <Stack gap={2} width="100%">
            <CancellationsConditions />
            {pageType === PageType.THANK_YOU && <ViewMyBookings />}
          </Stack>
          {isMobile && <CallToAction isMobile />}
        </LayoutContainer>
      </Box>
    </>
  )
}

export default Match
