import { TRAINER_EVENT_TYPES } from '../ActivityDetails.constants'
import { EventType } from '../../../App.types'

export type TrainerTranslationKey = 'activity-details-trainer-heading' | 'activity-details-teacher-heading'

const TRANSLATION_KEYS: { [key in 'trainer' | 'teacher']: TrainerTranslationKey } = {
  trainer: 'activity-details-trainer-heading',
  teacher: 'activity-details-teacher-heading',
}

function getTrainerTranslationKey(eventType: EventType): TrainerTranslationKey {
  if (TRAINER_EVENT_TYPES.includes(eventType)) {
    return TRANSLATION_KEYS.trainer
  }

  return TRANSLATION_KEYS.teacher
}

export default getTrainerTranslationKey
