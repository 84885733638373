import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import SeoLink from '@eversports/klimt-primitives/SeoLink'

import { Localized } from '../../../../localization/react'

interface Props {
  linkToTeamPage: string
}

const TrainersViewAllButton = ({ linkToTeamPage }: Props) => {
  return (
    <SeoLink encodedTo={btoa(linkToTeamPage)} type="hidden">
      <Button variant="tertiary" sx={{ py: 1.5, px: 16, width: { xs: '100%', md: 'fit-content' } }}>
        <Text sx={{ fontWeight: 'bold', textTransform: 'none' }}>
          <Localized id="view-all" />
        </Text>
      </Button>
    </SeoLink>
  )
}

export default TrainersViewAllButton
