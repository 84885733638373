import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Breadcrumbs from '@eversports/klimt-primitives/Breadcrumbs'
import Box from '@eversports/klimt-primitives/Box'
import Icon from '@eversports/klimt-primitives/Icon'
import ArrowBackwards from '@eversports/design-tokens/assets/icons/arrow-backwards.svg'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import { Localized } from '../../localization/react'

interface Props {
  unreadNotificationsCount: number | null
}

const Navigation = ({ unreadNotificationsCount }: Props) => {
  return (
    <Stack
      padding={{ xs: 4, sm: 6 }}
      paddingX={{ xs: 4, sm: 0 }}
      sx={{ backgroundColor: { xs: 'white', sm: 'transparent' }, boxShadow: { xs: boxShadows.default, sm: 'none' } }}
      gap={3}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">
          <Text sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            <Localized id="breadcrumb-navigation-item-home" />
          </Text>
        </Link>
        <Text>
          <Localized id="breadcrumb-navigation-item-notifications" />
        </Text>
      </Breadcrumbs>
      <Stack direction="row" alignItems="center" gap={2}>
        <Link to="/">
          <Icon src={ArrowBackwards} size="default" color="midnight" />
        </Link>
        <Stack direction="row" gap={3}>
          <Heading is="h1">
            <Localized id="notifications-page-title" />
          </Heading>
          {Boolean(unreadNotificationsCount) && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingX={2}
              paddingY={1}
              borderRadius={1}
              backgroundColor="red"
            >
              <Text variant="xsmall" sx={{ color: 'white' }}>
                {unreadNotificationsCount}
              </Text>
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Navigation
