import { differenceInMinutes } from 'date-fns'
import { useParams } from 'react-router-dom'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import { SharingMessage } from '@eversports/klimt-patterns/SharingActions'

import { useIntl } from '../../../../../localization/react'
import { useUseSharingMessageMatchQuery } from '../../../../../graphql'
import useMatchUrl from '../../../hooks/use-match-url'

const useSharingMessage = (): SharingMessage => {
  const intl = useIntl()
  const matchUrl = useMatchUrl()
  const { language } = useSettingsContext()
  const { id } = useParams<{ id: string }>()
  const { data } = useUseSharingMessageMatchQuery({ variables: { matchId: id } })

  if (!data) return { encodedMessage: '', translatedMessage: '', linkToCopy: '' }

  const start = new Date(data.match.start)
  const end = new Date(data.match.end)

  const formattedDate = new Intl.DateTimeFormat(language).format(start)
  const duration = differenceInMinutes(end, start)

  const startTime = start.toLocaleTimeString(language, {
    hour: '2-digit',
    minute: '2-digit',
  })

  const endTime = end.toLocaleTimeString(language, {
    hour: '2-digit',
    minute: '2-digit',
  })

  const dayOfTheWeek = new Intl.DateTimeFormat(language, {
    weekday: 'long',
  }).format(start)

  const params = {
    sportName: data.match.sport.name,
    dayOfTheWeek,
    formattedDate,
    startTime,
    endTime,
    duration,
    venueName: data.match.venue.name,
    matchUrl,
  }

  //   Message that is shared:
  //
  //   Hey, I've booked ${sportName} for us:
  //   📅 ${dayOfTheWeek}, ${formattedDate}
  //   ⏰ ${startTime} - ${endTime} (${duration}min)
  //   🏠 ${venueName}
  //
  //   ➡️ See court info or save to calendar here: ${matchUrl}
  const translatedMessage = intl.matchSharingMessage(params)

  return {
    encodedMessage: encodeURIComponent(translatedMessage),
    translatedMessage: translatedMessage,
    linkToCopy: matchUrl,
  }
}

export default useSharingMessage
