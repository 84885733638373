import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import { useParams } from 'react-router-dom'
import Box from '@eversports/klimt-primitives/Box'

import { Localized } from '../../../../localization/react'
import useGetTopContentHeight from '../../../../hooks/useGetTopContentHeight'
import { useNotFoundCityQuery, useNotFoundCitySearchQuery } from '../../../../graphql'

import OnlineOfferings from './OnlineOfferings'
import NoResults from './assets/NoResults.svg'
import OtherCities from './OtherCities'
import OtherSports from './OtherSports'

const NotFound = () => {
  const { DESKTOP_TOP_CONTENT_HEIGHT: TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])

  const { sportSlug, citySlug } = useParams<{ citySlug: string; sportSlug?: string }>()

  const { data: cityData, error: cityDataError } = useNotFoundCityQuery({ variables: { slug: citySlug } })
  const { data: citySearchData } = useNotFoundCitySearchQuery({ skip: Boolean(sportSlug) })

  const showOtherSports = sportSlug && cityData && !cityDataError && cityData.city.popularSports.length > 0

  const showCityOtherCities = citySearchData && citySearchData.citySearch.length > 0

  return (
    <Stack
      gap={18}
      backgroundColor="limelite"
      justifyContent={{ md: 'center' }}
      alignItems={{ md: 'center' }}
      paddingX={6}
      paddingY={12}
      height={`calc(100vh - ${TOP_CONTENT_HEIGHT}px)`}
    >
      <Stack justifyContent="center" alignItems="center" gap={4}>
        <Box component="img" src={NoResults} alt="" width={{ xs: '50px', md: '70px' }} />
        <Heading is="h1" color="midnight" textAlign="center" variant="large">
          {sportSlug ? (
            <Localized id="discover-no-results-sport-city" params={{ sportSlug, citySlug }} />
          ) : (
            <Localized id="discover-no-results-city" params={{ citySlug }} />
          )}
        </Heading>
        <Text>
          <Localized id="discover-no-results-description" />
        </Text>
      </Stack>
      {showOtherSports ? (
        <OtherSports cityData={cityData.city} />
      ) : (
        showCityOtherCities && <OtherCities cities={citySearchData.citySearch} />
      )}
      <OnlineOfferings />
    </Stack>
  )
}

export default NotFound
