import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import Heading from '@eversports/klimt-primitives/Heading'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import Text from '@eversports/klimt-primitives/Text'
import Link from '@eversports/klimt-primitives/Link'

import useGetTopContentHeight from '../../../../hooks/useGetTopContentHeight'
import { Match, MatchSport, MatchVenue, Meta } from '../../../../App.types'
import { DiscoverOfferingType, SeoContent } from '../../Discover.types'
import Offerings from '../../Offerings'
import MetaHelmet from '../../../../components/MetaHelmet'
import { Localized } from '../../../../localization/react'

import MatchesFilters from './MatchesFilters'
import Matches from './Matches'
import { DiscoverMatchesFiltersState } from './DiscoverSportMatches.types'
import { Action } from './helpers/discover-sport-matches-reducer'

interface Props {
  sportSlug: string
  citySlug: string
  cityName?: string
  sports?: Array<MatchSport> | null
  discoverSport: MatchSport | null
  venues: Array<MatchVenue> | null
  matches: Array<Match>
  offerings: Array<DiscoverOfferingType>
  appliedFilters: DiscoverMatchesFiltersState
  dispatch: React.Dispatch<Action>
  loadingFilterMatches: boolean
  loadingMoreMatches: boolean
  hasMoreResults?: boolean
  onFetchMoreMatches: () => void
  meta: Meta
  content: Pick<SeoContent, 'heading' | 'description'>
}

const DiscoverSportMatchesComponent = ({
  content,
  meta,
  sportSlug,
  cityName,
  citySlug,
  venues,
  sports,
  matches,
  offerings,
  discoverSport,
  appliedFilters,
  dispatch,
  loadingFilterMatches,
  loadingMoreMatches,
  hasMoreResults,
  onFetchMoreMatches,
}: Props) => {
  const { origin } = useSettingsContext()
  const { DESKTOP_TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])

  const canonicalHref = sportSlug
    ? new URL(`/matches/${citySlug}/${sportSlug}`, origin).href
    : new URL(`/matches/${citySlug}`, origin).href

  return (
    <>
      <MetaHelmet meta={meta} canonicalHref={canonicalHref} />
      <Box
        sx={{
          backgroundColor: 'white',
          height: '100%',
        }}
        component="main"
      >
        <Stack
          sx={{
            position: { xs: 'sticky' },
            top: { xs: '0', lg: DESKTOP_TOP_CONTENT_HEIGHT },
            zIndex: 6,
          }}
        >
          {discoverSport && (
            <Offerings
              sport={discoverSport}
              offerings={offerings}
              activeOffering={DiscoverOfferingType.DISCOVER_OFFERING_PUBLIC_MATCH}
            />
          )}
          <MatchesFilters appliedFilters={appliedFilters} dispatch={dispatch} venues={venues} sports={sports} />
          <Stack
            gap={4}
            paddingY={4}
            paddingX={{ xs: 4, md: 8 }}
            position="relative"
            sx={{ backgroundColor: { xs: 'eggshell', lg: 'white' } }}
          >
            <Stack>
              <Heading is="h1">{content.heading}</Heading>
              {sportSlug && cityName && (
                <Text variant="small" sx={{ color: 'darkgray' }}>
                  <Localized
                    id="all-public-matches-in-city-call-to-action"
                    params={{
                      matchesInCityLink: (
                        <Link to={`/matches/${citySlug}`} external>
                          <Text
                            variant="label"
                            component="span"
                            sx={{ color: 'primary.main', textDecoration: 'underline' }}
                          >
                            <Localized id="all-public-matches-in-city-link" params={{ cityName }} />
                          </Text>
                        </Link>
                      ),
                    }}
                  />
                </Text>
              )}
            </Stack>
            {content.description && (
              <Text sx={{ color: 'darkgray' }} variant="small">
                {content.description}
              </Text>
            )}
            <Matches
              matches={matches}
              loadingFilterMatches={loadingFilterMatches}
              loadingMoreMatches={loadingMoreMatches}
              hasMoreResults={hasMoreResults}
              onFetchMoreMatches={onFetchMoreMatches}
            />
          </Stack>
        </Stack>
      </Box>
    </>
  )
}

export default DiscoverSportMatchesComponent
