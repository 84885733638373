import * as React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'
import { extractStatusCodeFromError } from '@eversports/react-app-base/create-apollo-client'
import { useHttpStatus } from '@eversports/react-app-base/http-status/use-http-status'

import {
  useActivityDetailsGetEventBookableItemQuery,
  useActivityDetailsGetEventBookableItemDeprecatedQuery,
} from '../../graphql'
import { getDurationInMinutes } from '../../helpers/get-duration-in-minutes'
import LoadingPage from '../../components/LoadingPage'

import NotFoundActivity from './NotFoundActivity'
import ActivityDetailsComponent from './ActivityDetailsComponent'
import { paramToEventTypeMapping } from './ActivityDetails.constants'
import { EventRegistrationValidationProblem } from './ActivityDetails.types'
import getDaysUntilEvent from './helpers/get-days-til-event'
import getCheapestProduct from './Overview/Products/helpers/get-cheapest-product'
import checkHasOneSession from './helpers/check-has-one-session'

const ActivityDetails = () => {
  const { amplitude, logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const { eventType, shortId, activityId } = useParams<{ eventType?: string; shortId?: string; activityId?: string }>()

  const { data, loading, error } = activityId
    ? useActivityDetailsGetEventBookableItemQuery({
        variables: { id: activityId },
      })
    : // we keep the old URL structure for some time until we replaced all the URL referencing this page
    shortId && eventType
    ? useActivityDetailsGetEventBookableItemDeprecatedQuery({
        variables: { eventType: paramToEventTypeMapping[eventType], shortId },
      })
    : (undefined as never)

  const { setStatus } = useHttpStatus()

  useEffect(() => {
    if (!data || !amplitude) return

    const activity = data.getEventBookableItem
    const eventSession = activity.eventSessions?.[0]
    const hostingChannel = eventSession?.hasOnlineStream ? 'online-livestream' : 'onsite'

    const isBookable = activity.registrationState.bookable
    const isRegistered = activity.registrationState.validationProblems.includes(
      EventRegistrationValidationProblem.ALREADY_REGISTERED,
    )

    const duration = getDurationInMinutes(eventSession.start, eventSession.end)
    const daysUntilActivity = getDaysUntilEvent(eventSession.start)

    const venue = data.getEventBookableItem.venue

    const hasLimitedSpots =
      activity.availableSpots?.__typename === 'LimitedSpots' &&
      !activity.availableSpots.displayCapped &&
      activity.availableSpots.limit > 0

    const isAvailabilityUrgencyTagShown = isBookable && hasLimitedSpots

    const startingPrice = getCheapestProduct(activity.purchaseOptions)

    const hasOneSession = checkHasOneSession(activity.eventSessions)

    const hasTrainerDescription = Boolean(activity.trainers?.[0]?.description)

    logAmplitudeEventOnce({
      eventName: 'Viewed Activity Details',
      eventProperties: {
        venue: { name: venue.name, city: venue.city.name },
        startingPrice: startingPrice?.value ? startingPrice.value / 100 : undefined,
        currencyCode: startingPrice?.currency,
        activityType: activity.eventType.toLowerCase(),
        duration,
        level: activity.level,
        isOutdoor: !eventSession.location?.indoor,
        hostingChannel,
        numberOfSessions: activity.eventSessions.length,
        daysUntilActivity,
        trainer: hasOneSession ? hasTrainerDescription : null,
        availabilityUrgencyTagShown: isAvailabilityUrgencyTagShown,
        hasFriendsAttending: activity.participatingFriends.nodes.length > 0,
        numberOfFriendsAttending: activity.participatingFriends.nodes.length,
        isBookable,
        isRegistered,
        sport: {
          slug: activity.sport.slug,
        },
        isNew: true,
      },
    })
  }, [data, amplitude])

  if (error) {
    const status = extractStatusCodeFromError(error)
    if (status) {
      setStatus(status)
    }
    if (status === 404) {
      return <NotFoundActivity />
    }
  }

  if (!data || loading) {
    return <LoadingPage />
  }

  const venueReviews = data.getEventBookableItem.venue.reviews.edges.map(({ node, cursor }) => ({ ...node, cursor }))

  const activity = {
    ...data.getEventBookableItem,
    venue: { ...data.getEventBookableItem.venue, reviews: venueReviews },
  }

  const eventSessions = activity.eventSessions

  if (!eventSessions.length) {
    return null
  }

  return <ActivityDetailsComponent activity={activity} eventSessions={eventSessions} />
}

export default ActivityDetails
