import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { BaseNotification } from '../Notifications.types'
import getNotificationLink from '../helpers/get-notification-link'
import { PROMOTION_NOTIFICATION_CONTENT_TYPE } from '../Notifications.constants'

import NotificationContent from './NotificationContent'
import NotificationPromotion from './NotificationPromotion'

interface Props {
  notification: BaseNotification
}

const Notification = ({ notification }: Props) => {
  const { amplitude } = useAmplitude()
  const link = getNotificationLink(notification)

  const handleNotificationClick = () => {
    if (!amplitude) return
    amplitude.logEvent('Clicked Notification Tile', {
      type: notification.contentType,
      isNew: !notification.seen,
    })
  }

  if (notification.contentType === PROMOTION_NOTIFICATION_CONTENT_TYPE) {
    return <NotificationPromotion notification={notification} onClick={handleNotificationClick} />
  }

  if (link) {
    return (
      <Link to={link.to} external={link.isExternal}>
        <NotificationContent notification={notification} onClick={handleNotificationClick} />
      </Link>
    )
  }

  return <NotificationContent notification={notification} onClick={handleNotificationClick} />
}

export default Notification
