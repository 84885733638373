import * as React from 'react'
import Skeleton from '@eversports/klimt-primitives/Skeleton'
import Stack from '@eversports/klimt-primitives/Stack'
import Divider from '@eversports/klimt-primitives/Divider'
import Box from '@eversports/klimt-primitives/Box'

interface Props {
  numberOfCopies: number
}

const TrainerSkeleton = ({ numberOfCopies }: Props) => {
  const trainerCards = [...Array(numberOfCopies)]

  return (
    <>
      {trainerCards.map((_, index) => (
        <Box
          sx={{
            background: 'white',
            border: '0.5px solid',
            borderColor: 'lightgray',
            boxShadow: '0px 1px 1px rgba(20, 20, 20, 0.2)',
            borderRadius: 1,
            padding: 3,
          }}
          key={index}
        >
          <Stack gap={4}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Skeleton variant="circular" width="80px" height="80px" />
              <Stack gap={1} width="50%">
                <Skeleton variant="rectangular" width="100%" />
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" gap={2.5}>
              <Skeleton variant="rectangular" width="25%" />
              <Skeleton variant="rectangular" width="25%" />
            </Stack>
            <Divider sx={{ borderColor: 'ligthgray' }} />
            <Skeleton variant="rectangular" width="50%" height="32px" sx={{ margin: 'auto', borderRadius: 2 }} />
          </Stack>
        </Box>
      ))}
    </>
  )
}

export default TrainerSkeleton
