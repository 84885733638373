import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import useGetTopContentHeight from '../../../../hooks/useGetTopContentHeight'
import { PublicUser, UserType } from '../../PublicUserProfile.types'
import getStaticPageMetadata from '../../../../helpers/get-static-page-metadata'
import { useIntl } from '../../../../localization/react'
import MetaHelmet from '../../../../components/MetaHelmet'
import useLoggedInUserType from '../../hooks/use-logged-in-user-type'

import UserDetailsCard from './UserDetailsCard'
import UpcomingParticipations from './UpcomingParticipations'
import FavouriteVenues from './FavouriteVenues'
import BreadcrumbsNavigation from './BreadcrumbsNavigation'

interface Props {
  user: PublicUser
}

const ProfileComponent = ({ user }: Props) => {
  const intl = useIntl()
  const { userType } = useLoggedInUserType()
  const { origin } = useSettingsContext()
  const { DESKTOP_TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])

  const meta = getStaticPageMetadata({
    title: intl.publicUserProfileMetaTitle({ firstName: user.firstName, lastName: user.lastName }),
    description: intl.publicUserProfileMetaDescription(),
    image: user.profilePicture,
    url: `${origin}/user/${user.handle}`,
  })

  return (
    <>
      <MetaHelmet meta={meta} noIndex />
      <Box
        sx={{ backgroundColor: 'limelite', minHeight: `calc(100vh - ${DESKTOP_TOP_CONTENT_HEIGHT}px)`, height: '100%' }}
        component="main"
      >
        <Stack
          maxWidth="910px"
          margin="auto"
          boxSizing="content-box"
          padding={{ xs: 0, md: 6 }}
          paddingBottom={{ xs: 6, md: 0 }}
          gap={{ xs: 3, md: 6 }}
        >
          {userType === UserType.PROFILE_OWNER && <BreadcrumbsNavigation />}
          <UserDetailsCard
            firstName={user.firstName}
            lastName={user.lastName}
            bio={user.bio}
            handle={user.handle}
            profilePicture={user.profilePicture}
            friendsCount={user.friendsCount}
          />
          <Box display="grid" gridTemplateColumns={{ xs: '1fr', md: 'repeat(2, 1fr)' }} gap={10}>
            <UpcomingParticipations participations={user.upcomingParticipationsV2} />
            <FavouriteVenues venues={user.favouriteVenues} />
          </Box>
        </Stack>
      </Box>
    </>
  )
}

export default ProfileComponent
