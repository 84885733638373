import * as React from 'react'
import Avatar from '@eversports/klimt-primitives/Avatar'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import ExpandableContent from '@eversports/klimt-patterns/ExpandableContent'

import { getRelativeTime } from '../../../../helpers/get-relative-time'
import { Localized } from '../../../../localization/react'
import { Review } from '../../ActivityDetails.types'

const REVIEW_CARD_CONTENT_HEIGHT = 60

interface Props {
  review: Review
  isExpandable?: boolean
}

const ReviewContent = ({ review, isExpandable = false }: Props) => {
  const reviewDate = getRelativeTime(review.createdAt)
  return (
    <Stack direction="row" spacing={3}>
      <Avatar
        src={review.user.profilePicture?.xSmall}
        imgProps={{
          loading: 'lazy',
          alt: 'User headshot',
        }}
        sx={{
          height: '32px',
          width: '32px',
          borderRadius: 10,
        }}
      />
      <Stack>
        <Text variant="small" color="midnight" sx={{ fontWeight: 'bold' }}>
          {review.user.firstName}
        </Text>
        <Text variant="xsmall">
          <Localized
            id="date-time-relative-to-now"
            params={{ timeFrame: reviewDate.timeFrame, timeValue: reviewDate.timeValue }}
          />
        </Text>
        <ExpandableContent clampedTextHeight={REVIEW_CARD_CONTENT_HEIGHT} isDisabled={!isExpandable}>
          <Text variant="small">{review.comment}</Text>
        </ExpandableContent>
      </Stack>
    </Stack>
  )
}

export default ReviewContent
