import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import AcceptFriendIcon from '@eversports/design-tokens/assets/icons/accept-friend.svg'
import Processing from '@eversports/klimt-primitives/Processing'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../../../../localization/react'
import { useApproveFriendRequestButtonAcceptFriendRequestMutation } from '../../../../../../graphql'
import DeclineFriendRequest from '../../../Friends/MyFriends/RequestsList/ReceivedFriendRequest/DeclineFriendRequest'

interface Props {
  publicUserId?: string
}

const ApproveFriendRequestButton = ({ publicUserId }: Props) => {
  const { amplitude } = useAmplitude()
  const [approveFriendRequest, { loading }] = useApproveFriendRequestButtonAcceptFriendRequestMutation({
    variables: { userId: publicUserId! },
  })

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Approve Received Friend Request Button')
    }
    void approveFriendRequest()
  }

  return (
    <Stack direction="row" alignItems="center" flexShrink={0} gap={2}>
      <Processing isLoading={loading}>
        <Button
          variant="secondary"
          sx={{ textTransform: 'none', paddingY: { xs: 3, md: 2 }, paddingX: 6, width: 'fit-content' }}
          onClick={handleClick}
        >
          <Stack alignItems="center" direction="row" gap={2}>
            <Icon size="small" color="primary" src={AcceptFriendIcon} />
            <Text sx={{ color: 'primary.main', fontWeight: 'bold' }}>
              <Localized id="approve-friend-request-button-text" />
            </Text>
          </Stack>
        </Button>
      </Processing>
      <DeclineFriendRequest friendId={publicUserId!} />
    </Stack>
  )
}

export default ApproveFriendRequestButton
