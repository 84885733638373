import Text from '@eversports/klimt-primitives/Text'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import * as React from 'react'

import { Localized } from '../../../localization/react'

const EmptyListingResult = () => {
  return (
    <Stack justifyContent="center" spacing={4}>
      <Heading is="h4" variant="small">
        <Localized id="empty-list-result-title" />
      </Heading>
      <Text variant="small" sx={{ color: 'darkgray' }}>
        <Localized id="empty-list-result-description" />
      </Text>
      {/* <Button variant="secondary" onClick={() => dispatch({ type: 'RESET_FILTERS' })}>
          Reset all filters
      </Button> */}
    </Stack>
  )
}

export default EmptyListingResult
