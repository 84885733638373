import { NavigationTabVariant } from '../NavigationTabs.types'

const getNavigationTabVariantStyles = (variant: NavigationTabVariant) => {
  switch (variant) {
    case 'discoveryPage':
      return {
        py: 3,
        mx: 0,
        borderRadius: { xs: 0, lg: '8px 8px 0px 0px' },
      }
    case 'venueProfile':
    default:
      return {
        py: 3,
        mx: { xs: 0, sm: 0.5 },
        borderRadius: { xs: 0, sm: 1 },
      }
  }
}

export default getNavigationTabVariantStyles
