import * as React from 'react'
import { useMemo } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { useParams } from 'react-router-dom'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import { SportWithParents } from '../Discover.types'

import AllSportsList from './AllSportsList'
import SportPill from './SportPill'

const NUMBER_OF_VISIBLE_PILLS_MOBILE = 3
const NUMBER_OF_PILLS = 9

// We only want to show "main" sports.
// So only include sports that either don't have parents,
// or none of their parents are already included in the list.
const getMainSportsToShow = ({ sports, max }: { sports: Array<SportWithParents>; max: number }) => {
  const sportIds = sports.map((sport) => sport.id)
  return sports
    .filter((sport) => !sport.parents || sport.parents.every((sport) => !sportIds.includes(sport.id)))
    .slice(0, max)
}

interface Props {
  sports: Array<SportWithParents>
}

const SportPills = ({ sports }: Props) => {
  const { citySlug } = useParams<{ citySlug: string }>()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (!citySlug) return null

  const sportsForPills = useMemo(() => getMainSportsToShow({ sports, max: NUMBER_OF_PILLS }), [sports])

  return (
    <Stack
      direction="row"
      sx={{
        gap: 3,
        px: { xs: 3, lg: 8 },
        py: 2,
        background: 'white',
        border: '0.5px solid',
        borderColor: 'mediumgray',
        overflow: 'auto',
        whiteSpace: 'nowrap',
      }}
    >
      {sportsForPills.map((sport, index) => {
        const iconLoadMethod = !isMobile || index < NUMBER_OF_VISIBLE_PILLS_MOBILE ? 'eager' : 'lazy'
        return <SportPill key={sport.id} sport={sport} citySlug={citySlug} iconLoadMethod={iconLoadMethod} />
      })}
      <AllSportsList sports={sports} />
    </Stack>
  )
}

export default SportPills
