import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'

import FooterBg from '../../../static/footer-bg.webp'
import ContainedXLWidthContainer from '../ContainedXLWidthContainer'
import EversportsFlywheelLinks from '../EversportsFlywheelLinks'

import StaticFooterComponent from './StaticFooterComponent'
import CopyrightAndPaymentMethods from './CopyrightAndPaymentMethods'

const Footer = () => (
  <Stack
    component="footer"
    sx={{
      position: 'relative',
      overflow: 'hidden',
      zIndex: 2,
      contentVisibility: 'auto',
    }}
  >
    <Box
      component="img"
      src={FooterBg}
      loading="lazy"
      alt="footer background"
      sx={{
        objectFit: 'cover',
        position: 'absolute',
        minWidth: '100vw',
        minHeight: '100%',
        left: '50%',
        top: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
      }}
    />
    <ContainedXLWidthContainer>
      <Stack sx={{ flexDirection: { sm: 'row' }, gap: 4, paddingX: 4, paddingY: 6 }}>
        <EversportsFlywheelLinks />
      </Stack>
      <StaticFooterComponent />
      <CopyrightAndPaymentMethods />
    </ContainedXLWidthContainer>
  </Stack>
)

export default Footer
