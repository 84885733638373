import * as React from 'react'
import { useParams } from 'react-router-dom'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import Button from '@eversports/klimt-primitives/Button'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Processing from '@eversports/klimt-primitives/Processing'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { useEffect, useState } from 'react'

import {
  useRemoveParticipantsMatchQuery,
  useRemoveParticipantsRemoveParticipantsFromMatchMutation,
} from '../../../../../graphql'
import useLoggedInUserType from '../../../hooks/use-logged-in-user-type'
import { Localized } from '../../../../../localization/react'
import ErrorSnackbar from '../../components/ErrorSnackbar'

import ParticipantToBeRemoved from './ParticipantToBeRemoved'
import { RemoveParticipantsForm, UserWithParticipantId } from './RemoveParticipants.types'
import { REMOVE_PARTICIPANTS_FORM_KEY } from './RemoveParticipants.constants'

interface Props {
  onClose: () => void
}

const RemoveParticipants = ({ onClose }: Props) => {
  const { id } = useParams<{ id: string }>()
  const { amplitude } = useAmplitude()
  const { userId } = useLoggedInUserType()
  const [expectedErrorMessage, setExpectedErrorMessage] = useState<string | null>(null)
  const { data } = useRemoveParticipantsMatchQuery({ variables: { matchId: id } })
  const [removeParticipants, { loading, data: removeParticipantsData }] =
    useRemoveParticipantsRemoveParticipantsFromMatchMutation({
      onCompleted: onClose,
    })

  useEffect(() => {
    const expectedError =
      removeParticipantsData?.removeParticipantsFromMatchV2.__typename === 'ExpectedErrors'
        ? removeParticipantsData?.removeParticipantsFromMatchV2.errors[0]
        : null

    if (expectedError) {
      setExpectedErrorMessage(expectedError.message)
    }
  }, [removeParticipantsData])

  const methods = useForm<RemoveParticipantsForm>({
    defaultValues: { participantIdsToBeRemoved: [] },
  })

  const participantIdsToBeRemoved = methods.watch(REMOVE_PARTICIPANTS_FORM_KEY)
  const participants = data?.match.participants
    .filter(({ user }) => user.id !== userId)
    .map(({ id, user }) => ({ participantId: id, ...user })) as Array<UserWithParticipantId>

  const handleSubmit = () => {
    void removeParticipants({ variables: { matchId: id, matchParticipantIds: participantIdsToBeRemoved } })
    if (amplitude) {
      amplitude.logEvent('Removed Match Participants', {
        currentNumberOfParticipants: data?.match.participants.length,
        numberOfRemovedParticipants: participantIdsToBeRemoved.length,
        settings: {
          maxParticipants: data?.match.maxParticipants,
          level: data?.match.level,
          competitionType: data?.match.competitionType,
          gender: data?.match.gender,
          visibility: data?.match.visibility,
        },
      })
    }
  }

  return (
    <>
      <Processing isLoading={loading}>
        <Stack gap={8} alignItems="center">
          <Text sx={{ fontWeight: 'bold' }}>
            <Localized id="remove-players-title" />
          </Text>
          <Stack gap={4} width="100%">
            <Text variant="small">
              <Localized id="match-participants-count" params={{ count: participants.length }} />
            </Text>
            <Form methods={methods}>
              <Stack direction="row" flexWrap="wrap" alignItems="center" gap={4}>
                {participants.map((participant) => (
                  <ParticipantToBeRemoved
                    key={participant.id}
                    participant={participant}
                    isSelected={participantIdsToBeRemoved.includes(participant.participantId)}
                  />
                ))}
              </Stack>
            </Form>
          </Stack>
          <Stack direction="row" width="100%" gap={4}>
            <Button variant="tertiary" sx={{ p: 3, flex: 1 }} onClick={onClose}>
              <Text sx={{ color: 'text', fontWeight: 'bold', textShadow: boxShadows.default, textTransform: 'none' }}>
                <Localized id="dismiss-players-removal-button" />
              </Text>
            </Button>
            <Button
              variant="danger"
              onClick={handleSubmit}
              disabled={participantIdsToBeRemoved.length === 0}
              sx={{
                p: 3,
                flex: 1.5,
              }}
            >
              <Text sx={{ color: 'white', fontWeight: 'bold', textShadow: boxShadows.default, textTransform: 'none' }}>
                <Localized id="remove-players-button" />
              </Text>
            </Button>
          </Stack>
        </Stack>
      </Processing>
      {expectedErrorMessage && (
        <ErrorSnackbar
          isOpen={Boolean(expectedErrorMessage)}
          errorMessage={expectedErrorMessage}
          onClose={() => setExpectedErrorMessage(null)}
        />
      )}
    </>
  )
}

export default RemoveParticipants
