import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'

import { Localized } from '../../../../../localization/react'
import { FavoriteVenue as FavoriteVenueType } from '../../../PublicUserProfile.types'

import FavouriteVenuesIcon from './assets/favourite-venues-icon.webp'
import FavouriteVenue from './FavouriteVenue'

interface Props {
  favouriteVenues: Array<FavoriteVenueType>
  isMobile?: boolean
}

const AllFavouritesContent = ({ favouriteVenues, isMobile = false }: Props) => {
  return (
    <Stack padding={6} gap={3} minWidth={{ xs: '100%', md: '450px' }}>
      {!isMobile && (
        <Stack direction="row" alignItems="center" gap={2}>
          <img src={FavouriteVenuesIcon} width="17px" height="16px" />
          <Heading is="h3" sx={{ color: 'midnight' }}>
            <Localized id="user-profile-favourite-venues-title" />
          </Heading>
        </Stack>
      )}
      <Stack gap={3}>
        {favouriteVenues.map((favouriteVenue) => (
          <FavouriteVenue key={favouriteVenue.id} venue={favouriteVenue} />
        ))}
      </Stack>
    </Stack>
  )
}

export default AllFavouritesContent
