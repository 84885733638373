import * as React from 'react'
import { assertNever } from '@eversports/klimt-utilities/assert-never'

import useLoggedInUserType from '../../../../hooks/use-logged-in-user-type'
import { UserType } from '../../../../PublicUserProfile.types'

import AddToFriendsButton from './AddToFriendsButton'
import ApproveFriendRequestButton from './ApproveFriendRequestButton'
import RequestedFriendshipButton from './RequestedFriendshipButton'
import LoggedOutFriendsButton from './LoggedOutFriendsButton'
import FriendsButton from './FriendsButton'

const FriendshipButton = () => {
  const { userType, publicUserId } = useLoggedInUserType()

  switch (userType) {
    case UserType.PROFILE_OWNER:
      return null
    case UserType.EVERSPORTS_USER:
      return <AddToFriendsButton publicUserId={publicUserId} />
    case UserType.FRIENDSHIP_REQUEST_PENDING:
      return <ApproveFriendRequestButton publicUserId={publicUserId} />
    case UserType.REQUESTED_FRIENDSHIP:
      return <RequestedFriendshipButton publicUserId={publicUserId} />
    case UserType.FRIENDS:
      return <FriendsButton publicUserId={publicUserId} />
    case UserType.LOGGED_OUT_USER:
      return <LoggedOutFriendsButton />
    default:
      return assertNever(userType)
  }
}

export default FriendshipButton
