import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Link from '@eversports/klimt-primitives/Link'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import { Localized } from '../../../../localization/react'
import { ManagerLinksMapping } from '../../../../App.constants'

const CorporateDisclaimer = () => {
  const { tld } = useSettingsContext()

  return (
    <Text variant="badge" sx={{ color: 'darkgray', fontWeight: 'initial' }}>
      <Localized
        id="corporate-partner-promo-text"
        params={{
          link: (
            <Link
              to={ManagerLinksMapping[tld].corporate}
              external
              blank
              ariaLabel="Learn about Eversports' special corporate deals"
            >
              <Text
                component="span"
                variant="badge"
                sx={{ color: 'primary.main', textDecoration: 'underline', fontWeight: 'initial' }}
              >
                <Localized id="corporate-partner-promo-text-link" />
              </Text>
            </Link>
          ),
        }}
      />
    </Text>
  )
}

export default CorporateDisclaimer
