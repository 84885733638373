import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import { useParams } from 'react-router-dom'
import Icon from '@eversports/klimt-primitives/Icon'
import ArrowLinkIcon from '@eversports/design-tokens/assets/icons/arrow-link.svg'

import { Localized } from '../../localization/react'
import { useViewMyBookingsMatchQuery } from '../../graphql'
import useUrlParams from '../../hooks/use-url-params'
import { Origin } from '../../App.types'

import { UserType } from './Match.types'
import useLoggedInUserType from './hooks/use-logged-in-user-type'

const ViewMyBookings = () => {
  const { origin } = useUrlParams()
  const { userType } = useLoggedInUserType()
  const { id } = useParams<{ id: string }>()
  const { data } = useViewMyBookingsMatchQuery({ variables: { matchId: id } })

  if (!data || userType !== UserType.ORGANIZER) {
    return null
  }

  const to = origin === Origin.WIDGET ? `/widget/w/${data.match.venue.shortId}` : '/u'

  return (
    <Link to={to} external ariaLabel="View my bookings">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        gap={2}
        py={[1, 0]}
        px={2}
        width="fit-content"
      >
        <Text color="midnight" style={{ textDecoration: 'underline' }}>
          <Localized id="continue-to-my-bookings" />
        </Text>
        <Icon src={ArrowLinkIcon} size="x-small" color="darkgray" />
      </Stack>
    </Link>
  )
}

export default ViewMyBookings
