const getInitials = ({
  firstname,
  lastname,
}: {
  firstname: string | null | undefined
  lastname: string | null | undefined
}): string | React.ReactNode => {
  const collectionOfChars = [
    firstname?.charAt(0),
    lastname?.charAt(0),
    firstname?.charAt(1),
    lastname?.charAt(1),
  ].filter(Boolean)

  const initials = collectionOfChars.slice(0, 2)

  return initials.length ? initials.join('') : undefined
}

export default getInitials
