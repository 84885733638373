import { useEffect, useState } from 'react'

import { PublicParticipation, PublicParticipationEdge } from '../FriendsUpcomingParticipations.types'
import {
  useUseFriendsUpcomingParticipationsMeLazyQuery,
  useUseFriendsUpcomingParticipationsMeQuery,
} from '../../../../../../../graphql'
import { NUMBER_OF_UPCOMING_PARTICIPATIONS_TO_LOAD } from '../FriendsUpcomingParticipations.constants'

interface Result {
  friendsUpcomingParticipations: Array<PublicParticipation>
  initialLoading: boolean
  loading: boolean
  fetchMore: () => void
  hasMoreResults?: boolean
}

const useFriendsUpcomingParticipations = (): Result => {
  const [hasMoreResults, setHasMoreResults] = useState<boolean | undefined>()
  const [upcomingFriendsParticipations, setUpcomingFriendsParticipations] = useState<Array<PublicParticipationEdge>>([])

  const { loading: initialLoading } = useUseFriendsUpcomingParticipationsMeQuery({
    variables: { first: NUMBER_OF_UPCOMING_PARTICIPATIONS_TO_LOAD },
    onCompleted: (initialData) => {
      setHasMoreResults(initialData.me?.upcomingFriendsParticipations.pageInfo.hasNextPage)
      setUpcomingFriendsParticipations(initialData.me?.upcomingFriendsParticipations.edges || [])
    },
  })

  const [loadMoreUpcomingFriendsParticipants, { data, loading }] = useUseFriendsUpcomingParticipationsMeLazyQuery({
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!data?.me?.upcomingFriendsParticipations.edges.length) return
    const fetchedUpcomingFriendsParticipations = data.me?.upcomingFriendsParticipations.edges || []
    const newUpcomingFriendsParticipations = upcomingFriendsParticipations
      ? [...upcomingFriendsParticipations, ...fetchedUpcomingFriendsParticipations]
      : fetchedUpcomingFriendsParticipations
    setHasMoreResults(data.me?.upcomingFriendsParticipations.pageInfo.hasNextPage)
    setUpcomingFriendsParticipations(newUpcomingFriendsParticipations)
  }, [data])

  const fetchMore = () => {
    if (hasMoreResults === false) return
    const cursor = upcomingFriendsParticipations[upcomingFriendsParticipations.length - 1].cursor
    void loadMoreUpcomingFriendsParticipants({
      variables: {
        first: NUMBER_OF_UPCOMING_PARTICIPATIONS_TO_LOAD,
        after: cursor,
      },
    })
  }

  const friendsUpcomingParticipations = upcomingFriendsParticipations.map((edge) => edge.node) || []

  return {
    loading,
    initialLoading,
    friendsUpcomingParticipations,
    fetchMore,
    hasMoreResults,
  }
}

export default useFriendsUpcomingParticipations
