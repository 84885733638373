import { useRef } from 'react'

import useAmplitude from './useAmplitude'

import { AmplitudeClient } from '@eversports/amplitude-react/core/amplitude'

interface Props {
  eventName: string
  eventProperties: object
}
interface Result {
  amplitude: AmplitudeClient | null
  logAmplitudeEventOnce: (props: Props) => void
}

const useLogAmplitudeEventOnce = (): Result => {
  const { amplitude } = useAmplitude()

  const hasTriggeredEvent = useRef(false)

  const logAmplitudeEventOnce = ({ eventName, eventProperties }: Props) => {
    if (!amplitude || hasTriggeredEvent.current) return
    amplitude.logEvent(eventName, eventProperties)

    hasTriggeredEvent.current = true
  }

  return { amplitude, logAmplitudeEventOnce }
}

export default useLogAmplitudeEventOnce
