import * as React from 'react'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import ClockIcon from '@eversports/design-tokens/assets/icons/clock.svg'
import Text from '@eversports/klimt-primitives/Text'

import { formatTime } from '../../../../../helpers/format-time'

interface Props {
  start: string
  end: string
}

const ParticipationTime = ({ start, end }: Props) => {
  const { language } = useSettingsContext()
  const startTime = formatTime(language, start)
  const endTime = formatTime(language, end)

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Icon src={ClockIcon} color="midnight" size="small" />
      <Text sx={{ color: 'midnight' }}>
        {startTime} - {endTime}
      </Text>
    </Stack>
  )
}

export default ParticipationTime
