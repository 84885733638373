import HomeIcon from '@eversports/design-tokens/assets/icons/home.svg'
import MyBookingsIcon from '@eversports/design-tokens/assets/icons/my-bookings.svg'
import MyVideosIcon from '@eversports/design-tokens/assets/icons/my-videos.svg'
import MyPassesIcon from '@eversports/design-tokens/assets/icons/my-passes.svg'
import MyFavoritesIcon from '@eversports/design-tokens/assets/icons/my-favorites.svg'
import MyPersonalInformationIcon from '@eversports/design-tokens/assets/icons/my-personal-information.svg'
import MyPaymentMethodsIcon from '@eversports/design-tokens/assets/icons/my-payment-methods.svg'
import SignoutIcon from '@eversports/design-tokens/assets/icons/signout.svg'
import * as React from 'react'
import ForBusinessesIcon from '@eversports/design-tokens/assets/icons/for-businesses.svg'
import ForCorporatesIcon from '@eversports/design-tokens/assets/icons/for-corporates.svg'
import { SupportedTLD } from '@eversports/react-app-base/tld-configs'
import FriendsIcon from '@eversports/design-tokens/assets/icons/friends.svg'
import MyProfileIcon from '@eversports/design-tokens/assets/icons/my-profile.svg'

import { ManagerLinksMapping } from '../../../App.constants'
import { Localized } from '../../../localization/react'
import { From } from '../../../App.types'
import NumberOfPendingFriendRequests from '../../NumberOfPendingFriendRequests'
import RecommendationsIcon from '../../../../static/recommendations.svg'
import NotificationsIcon from '../../../../static/notifications.svg'
import UnreadNotificationCount from '../../UnreadNotificationsCount'

import { UserProfileRoutes } from './UserMenu.constants'
import { UserItem } from './UserMenu.types'

interface NonLoggedInUserMenuItemsArgs {
  tld: SupportedTLD
}

export const LoggedInUserMenuItems: Array<UserItem> = [
  {
    icon: MyBookingsIcon,
    label: <Localized id="user-menu-item-my-bookings" />,
    to: UserProfileRoutes.MY_BOOKINGS,
    isExternal: true,
  },
  {
    icon: MyVideosIcon,
    label: <Localized id="user-menu-item-my-videos" />,
    to: UserProfileRoutes.MY_VIDEOS,
    isExternal: true,
  },
  {
    icon: MyPassesIcon,
    label: <Localized id="user-menu-item-my-passes-and-memberships" />,
    to: UserProfileRoutes.MY_PASSES,
    isExternal: true,
  },
  {
    icon: MyFavoritesIcon,
    label: <Localized id="user-menu-item-my-favorites" />,
    to: UserProfileRoutes.MY_FAVORITES,
    isExternal: true,
  },
  {
    icon: MyPersonalInformationIcon,
    label: <Localized id="user-menu-item-my-personal-information" />,
    to: UserProfileRoutes.MY_INFO,
    isExternal: true,
  },
  {
    icon: MyPaymentMethodsIcon,
    label: <Localized id="user-menu-item-my-payment-methods" />,
    to: UserProfileRoutes.MY_PAYMENT_METHODS,
    hasDivider: true,
    isExternal: true,
  },
  {
    icon: SignoutIcon,
    label: <Localized id="user-menu-item-signout" />,
    to: UserProfileRoutes.SIGNOUT,
    isExternal: true,
  },
]

export const NonLoggedInUserMenuItems = ({ tld }: NonLoggedInUserMenuItemsArgs): Array<UserItem> => [
  {
    icon: HomeIcon,
    label: <Localized id="user-menu-item-home" />,
    to: UserProfileRoutes.HOME,
    isExternal: false,
  },
  {
    icon: ForCorporatesIcon,
    label: <Localized id="user-menu-item-for-corporates" />,
    to: ManagerLinksMapping[tld].corporate,
    isExternal: true,
  },
  {
    icon: ForBusinessesIcon,
    label: <Localized id="user-menu-item-for-businesses" />,
    to: ManagerLinksMapping[tld].business,
    isExternal: true,
  },
]

export const PublicUserFeatureMenuItems = ({ userHandle }: { userHandle: string }): Array<UserItem> => [
  {
    icon: MyProfileIcon,
    label: <Localized id="user-menu-item-my-public-profile" />,
    to: `/user/${userHandle}?from=${From.USER_MENU}`,
    isExternal: false,
  },
  {
    icon: FriendsIcon,
    label: <Localized id="user-menu-item-my-friends" />,
    to: `/user/${userHandle}/friends?from=${From.USER_MENU}`,
    isExternal: false,
    notification: <NumberOfPendingFriendRequests />,
  },
]

export const HomeUserItem = {
  to: UserProfileRoutes.HOME,
  icon: HomeIcon,
  label: <Localized id="user-menu-item-home" />,
  hasDivider: true,
  isExternal: false,
}

export const RecommendationsUserItem = {
  to: UserProfileRoutes.RECOMMENDATIONS,
  icon: RecommendationsIcon,
  label: <Localized id="recommendations" />,
  isExternal: false,
}

export const NotificationsUserItem = {
  to: UserProfileRoutes.NOTIFICATIONS,
  icon: NotificationsIcon,
  label: <Localized id="notifications" />,
  isExternal: false,
  notification: <UnreadNotificationCount />,
}
