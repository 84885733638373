import * as React from 'react'
import { AnimatePresence } from '@eversports/klimt-utilities/framer-motion'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Localized, useIntl } from '../../localization/react'
import ContainedXLWidthContainer from '../../components/ContainedXLWidthContainer'
import AppRoutes from '../../App.routes'
import getStaticPageMetadata from '../../helpers/get-static-page-metadata'
import MetaHelmet from '../../components/MetaHelmet'

import TrialCardsPage from './TrialCards'
import TeachersPage from './Teachers'
import Recommendations from './Recommendations'
import Routes from './Recommendations.routes'

// If it's the landing page, show a special header.
// If it's a subroute, show the standard header.
const RecommendationsHeader = () => {
  const { pathname } = useLocation()
  return pathname === (AppRoutes.RECOMMENDATIONS as string) ? (
    <Header>
      <Stack justifyContent="center" alignItems="center" flex={1} display={{ xs: 'flex', lg: 'none' }}>
        <Text sx={{ fontWeight: 'bold' }}>
          <Localized id="recommendations-page-title" />
        </Text>
      </Stack>
    </Header>
  ) : (
    <Header />
  )
}

const RecommendationsScreen = () => {
  const intl = useIntl()
  const { path, url } = useRouteMatch()
  const { origin } = useSettingsContext()

  const meta = getStaticPageMetadata({
    title: intl.recommendationsMetaTitle(),
    description: intl.recommendationsMetaDescription(),
    url: origin,
  })

  return (
    <>
      <MetaHelmet meta={meta} noIndex />
      <RecommendationsHeader />
      <AnimatePresence exitBeforeEnter>
        <Switch>
          <Route exact path={path}>
            <Recommendations />
          </Route>
          <ContainedXLWidthContainer>
            <Route path={`${url}${Routes.TEACHER_RECOMMENDATIONS}`}>
              <TeachersPage />
            </Route>
            <Route path={`${url}${Routes.TRIAL_OFFER_RECOMMENDATIONS}`}>
              <TrialCardsPage />
            </Route>
          </ContainedXLWidthContainer>
        </Switch>
      </AnimatePresence>
      <Footer />
    </>
  )
}

export default RecommendationsScreen
