import * as React from 'react'
import { useEffect, useState, useRef } from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Box from '@eversports/klimt-primitives/Box/Box'

interface Props {
  activityName: string
}

const RevealedActivityName = ({ activityName }: Props) => {
  const [showActivityName, setShowActivityName] = useState(false)

  const headerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      const hasScrolledDown = window.scrollY > 200
      setShowActivityName(hasScrolledDown)
    }
    window.addEventListener('scroll', handleScroll, {
      passive: true,
    })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Box
      sx={{
        height: showActivityName ? headerRef.current?.clientHeight : 0,
        opacity: showActivityName ? 1 : 0,
        marginBottom: showActivityName ? 4 : 0,
        transition: 'opacity 0.2s ease, height 0.5s ease, marginBottom 0.5s ease',
        overflowY: 'hidden',
      }}
    >
      <Box forwardedRef={headerRef}>
        <Heading is="h3" color="midnight">
          {activityName}
        </Heading>
      </Box>
    </Box>
  )
}

export default RevealedActivityName
