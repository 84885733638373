import { match } from 'css-mediaquery'
import { breakpoints } from '@eversports/design-tokens/breakpoints'

const customMediaQueryMatcher = (deviceType: string) => (query: string) => ({
  matches: match(query, {
    width: deviceType === 'mobile' ? `${breakpoints.xs}px` : `${breakpoints.lg}px`,
  }),
})

export default customMediaQueryMatcher
