/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

// DO NOT MODIFY
// FILE GENERATED BY `intl-codegen@2.2.1`
// https://github.com/eversport/intl-codegen
// DO NOT MODIFY

import { defineLoader } from "intl-codegen/runtime";

export const loadLanguage = defineLoader([
  "fullFormatDate",
  "longFormatDate",
  "mediumFormatDate",
  "monetaryValue",
  "shortFormatDate",
  "shortFormatTime",
], {
  de: () => import("./locales/de.js"),
  en: () => import("./locales/en.js"),
  es: () => import("./locales/es.js"),
  nl: () => import("./locales/nl.js"),
  fr: () => import("./locales/fr.js"),
  it: () => import("./locales/it.js"),
}, "en");
