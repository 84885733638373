import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'

interface Props {
  hasContent?: boolean
  onClick?: () => void
  isDisabled?: boolean
}

const MatchSettingOrganizerBox = ({ children, hasContent, onClick, isDisabled }: React.PropsWithChildren<Props>) => {
  return (
    <Box
      width="fit-content"
      flex={[1, 'initial']}
      onClick={onClick}
      sx={{
        py: 1,
        px: 3,
        border: '1px solid',
        borderColor: 'mediumgray',
        borderRadius: 1,
        boxShadow: boxShadows.default,
        cursor: isDisabled ? 'cursor' : 'pointer',
        textAlign: 'center',
        minWidth: '75px',
        opacity: hasContent && !isDisabled ? 1 : 0.5,
        '&:hover': {
          boxShadow: isDisabled ? boxShadows.default : '0px 4px 4px rgb(20 20 20 / 20%)',
        },
      }}
    >
      <Stack alignItems="center" gap={1}>
        {children}
      </Stack>
    </Box>
  )
}

export default MatchSettingOrganizerBox
