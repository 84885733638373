import * as React from 'react'
import { assertNever } from '@eversports/klimt-utilities/assert-never'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import { useParams } from 'react-router-dom'

import { UserType } from '../../Match.types'
import useLoggedInUserType from '../../hooks/use-logged-in-user-type'
import { Localized } from '../../../../localization/react'
import { useDisclaimerMatchQuery } from '../../../../graphql'

const Disclaimer = () => {
  const { userType } = useLoggedInUserType()
  const { id } = useParams<{ id: string }>()
  const { data } = useDisclaimerMatchQuery({ variables: { matchId: id } })

  if (!data) {
    return null
  }

  switch (userType) {
    case UserType.ORGANIZER:
      return (
        <Stack gap={1}>
          <Stack direction={['column', 'row']} flexWrap="wrap" gap={1}>
            <Text variant="large" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              <Localized id="organizer-match-disclaimer-next-step" />
            </Text>
            <Text variant="large" sx={{ fontWeight: 'bold' }}>
              <Localized id="organizer-match-disclaimer-title" />
            </Text>
          </Stack>
          <Text sx={{ color: 'darkgray' }}>
            <Localized id="organizer-match-disclaimer-subtitle" />
          </Text>
        </Stack>
      )
    case UserType.PARTICIPANT:
      return (
        <Stack gap={1} flexWrap="wrap">
          <Stack direction={['column', 'row']} gap={1}>
            <Text variant="large" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              <Localized id="participant-match-disclaimer-all-set" />
            </Text>
            <Text variant="large" sx={{ fontWeight: 'bold' }}>
              <Localized id="participant-match-disclaimer-title" />
            </Text>
          </Stack>
          <Text sx={{ color: 'darkgray' }}>
            <Localized
              id="participant-match-disclaimer-subtitle"
              params={{ organizerFirstName: data.match.organizerV2.firstName }}
            />
          </Text>
        </Stack>
      )
    case UserType.EVERSPORTS_GUEST:
    case UserType.EXTERNAL_GUEST:
      return (
        <Stack gap={1}>
          <Stack direction={['column', 'row']} gap={1}>
            <Text variant="large" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              <Localized id="logged-in-user-match-disclaimer-dont-forget" />
            </Text>
            <Text variant="large" sx={{ fontWeight: 'bold' }}>
              <Localized
                id="logged-in-user-match-disclaimer-title"
                params={{ organizerFirstName: data.match.organizerV2.firstName }}
              />
            </Text>
          </Stack>
          <Text sx={{ color: 'darkgray' }}>
            <Localized id="logged-in-user-match-disclaimer-subtitle" />
          </Text>
        </Stack>
      )
    default:
      return assertNever(userType)
  }
}

export default Disclaimer
