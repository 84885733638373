import * as React from 'react'
import { ChangeEvent, KeyboardEvent, useState } from 'react'
import TextField from '@eversports/klimt-primitives/TextField'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'
import Icon from '@eversports/klimt-primitives/Icon'
import SendMessageIcon from '@eversports/design-tokens/assets/icons/send-message.svg'

import { Localized, useIntl } from '../../../localization/react'
import useLoggedInUserType from '../hooks/use-logged-in-user-type'
import { UserType } from '../Match.types'

interface Props {
  onSubmit: ({ comment }: { comment: string }) => void
}

const NewCommentInput = ({ onSubmit }: Props) => {
  const intl = useIntl()
  const { userType } = useLoggedInUserType()
  const [comment, setComment] = useState<string>('')
  const isNonLoggedInUser = userType === UserType.EXTERNAL_GUEST

  const submitComment = () => {
    if (comment) {
      onSubmit({ comment })
      setComment('')
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      submitComment()
    }
  }

  return (
    <Box sx={{ p: 4, maxWidth: '520px', margin: 'auto' }}>
      {isNonLoggedInUser ? (
        <Text sx={{ textAlign: 'center' }}>
          <Localized id="non-logged-in-user-comment-disclaimer" />
        </Text>
      ) : (
        <TextField
          placeholder={intl.addNewCommentPlaceholder()}
          value={comment}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <Box sx={{ cursor: 'pointer' }} onClick={submitComment}>
                <Icon src={SendMessageIcon} color={comment !== '' ? 'primary' : 'gray'} size="small" />
              </Box>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 10,
            },
          }}
          fullWidth
        />
      )}
    </Box>
  )
}

export default NewCommentInput
