import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import MonetaryValue from '@eversports/klimt-localization/MonetaryValue'
import Text from '@eversports/klimt-primitives/Text'

import { Ticket, TicketPriceType } from '../../ActivityDetails.types'

interface Props {
  ticket: Ticket
}

const TicketPriceText = ({ ticket }: Props) => {
  if (ticket.currentPriceType === TicketPriceType.TICKET_PRICE_TYPE_EARLY_BIRD && ticket.earlyBirdPrice) {
    return (
      <Stack direction="row" gap={1}>
        <Text color="midnight" sx={{ fontWeight: { xs: 'bold', sm: 'normal' } }}>
          <MonetaryValue value={ticket.earlyBirdPrice.value} currencyCode={ticket.earlyBirdPrice.currency} />
        </Text>
        <Text color="gray" sx={{ textDecoration: 'line-through' }}>
          <MonetaryValue value={ticket.standardPrice.value} currencyCode={ticket.standardPrice.currency} />
        </Text>
      </Stack>
    )
  }

  if (ticket.currentPriceType === TicketPriceType.TICKET_PRICE_TYPE_LAST_MINUTE && ticket.lastMinutePrice) {
    return (
      <Stack direction="row" gap={1}>
        <Text color="midnight" sx={{ fontWeight: { xs: 'bold', sm: 'normal' } }}>
          <MonetaryValue value={ticket.lastMinutePrice.value} currencyCode={ticket.lastMinutePrice.currency} />
        </Text>
        <Text color="gray" sx={{ textDecoration: 'line-through' }}>
          <MonetaryValue value={ticket.standardPrice.value} currencyCode={ticket.standardPrice.currency} />
        </Text>
      </Stack>
    )
  }

  return (
    <Text color="midnight" sx={{ fontWeight: { xs: 'bold', sm: 'normal' } }}>
      <MonetaryValue value={ticket.standardPrice.value} currencyCode={ticket.standardPrice.currency} />
    </Text>
  )
}

export default TicketPriceText
