import * as React from 'react'

import Footer from '../../components/Footer'

import Home from './Home'
import Header from './Header'

const HomePage = () => {
  return (
    <>
      <Header />
      <Home />
      <Footer />
    </>
  )
}

export default HomePage
