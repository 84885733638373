import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import EversportsLogoIcon from '@eversports/design-tokens/assets/logo/icon.svg'

interface Props {
  variant: 'small' | 'medium' | 'large'
  src?: string
}

const VARIANTS = {
  small: {
    width: '33px',
    height: '33px',
    borderRadius: 2,
  },
  medium: {
    width: '56px',
    height: '56px',
    borderRadius: 20,
    border: '2px solid',
    borderColor: 'mediumgray',
    boxShadow: boxShadows.default,
  },
  large: {
    width: { xs: '200px', md: '240px' },
    height: { xs: '200px', md: '240px' },
    borderRadius: 20,
    border: '4px solid',
    borderColor: 'mediumgray',
    boxShadow: boxShadows.hover,
  },
}

const RecapVenueLogo = ({ src, variant }: Props) => {
  return (
    <Box
      sx={{
        ...VARIANTS[variant],
        flexShrink: 0,
        backgroundColor: 'white',
        backgroundImage: `url(${src ?? EversportsLogoIcon})`,
        backgroundSize: src ? 'cover' : 'auto',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    />
  )
}

export default RecapVenueLogo
