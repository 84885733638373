import * as React from 'react'
import { useParams } from 'react-router-dom'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import NavigationTabs from '../../../components/NavigationTabs'
import { DiscoverOfferingType, DiscoverySport } from '../Discover.types'
import useGetTopContentHeight from '../../../hooks/useGetTopContentHeight'

import getOfferingTabs from './helpers/get-offering-tabs'

interface Props {
  offerings: Array<DiscoverOfferingType> | null
  sport: DiscoverySport
  activeOffering?: DiscoverOfferingType
}

const Offerings = ({ offerings, sport, activeOffering }: Props) => {
  const { citySlug } = useParams<{ citySlug: string }>()
  const { DESKTOP_TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])

  if (offerings === null || !offerings.length) {
    return null
  }

  const tabs = getOfferingTabs({ offerings, sport, citySlug })
  const activeTabIndex = activeOffering ? tabs.map((t) => t.offering).indexOf(activeOffering) : 0

  return (
    <Box
      sx={{
        top: { xs: 'initial', lg: DESKTOP_TOP_CONTENT_HEIGHT },
        backgroundColor: 'white',
        position: { xs: 'relative', lg: 'sticky' },
        boxShadow: boxShadows.default,
        px: { xs: 0, lg: 8.5 },
        pt: { xs: 0, lg: 0.5 },
        zIndex: 6,
      }}
    >
      <NavigationTabs tabs={tabs} activeTabIndex={activeTabIndex} tabVariant="discoveryPage" />
    </Box>
  )
}

export default Offerings
