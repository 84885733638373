export const palette = {
  primary: '#21a696',
  secondary: '#7700EE',
  tertiary: '#F2994A',
  brightgreen: '#11D200',
  red: '#FF1A1A',
  midnight: '#141414',
  carbon: '#666666',
  darkgray: '#4D4D4D',
  gray: '#BBBBBB',
  mediumgray: '#E0E0E0',
  lightgray: '#F2F2F2',
  limelite: '#F9FBFB',
  eggshell: '#FAFAFA',
  whitesmoke: '#EEF2F7',
  white: '#FFFFFF',
  black: '#000000',
}

export type Color = keyof typeof palette
