import * as React from 'react'
import { borderRadius } from '@eversports/design-tokens/border-radius'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import Box from '../Box'

const TableContainerCard = ({ children, ...rest }: React.PropsWithChildren<unknown>) => {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'lightgray',
        backgroundColor: 'white',
        borderRadius: `${borderRadius.default}px`,
        boxShadow: boxShadows.default,
        px: 4,
        paddingTop: 1,
        paddingBottom: 5,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default TableContainerCard
