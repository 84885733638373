import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import { useEffect, useState } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Icon from '@eversports/klimt-primitives/Icon'
import CloseIcon from '@eversports/design-tokens/assets/icons/close.svg'

import { PublicParticipationConnection, PublicParticipation, UserType } from '../../../PublicUserProfile.types'
import { Localized } from '../../../../../localization/react'
import PrivacySettingButton from '../../../components/PrivacySettingButton'
import useLoggedInUserType from '../../../hooks/use-logged-in-user-type'
import PrivateInformation from '../../../components/PrivateInformation'
import { PrivacySettingField } from '../../../../../App.types'
import PromotionalTooltip from '../../../components/PromotionalTooltip'
import { TOOLTIP_TYPE } from '../../../PublicUserProfile.constants'

import UpcomingBookingsIcon from './assets/upcoming-bookings-icon.webp'
import UpcomingParticipation from './UpcomingParticipation'
import NoUpcomingParticipations from './NoUpcomingParticipations'
import ShowAllUpcomingParticipations from './ShowAllUpcomingParticipations'

interface Props {
  participations: PublicParticipationConnection | null
}

const NUMBER_OF_UPCOMING_PARTICIPATIONS_TO_SHOW = 2

const UpcomingParticipationsContent = ({ participations }: { participations: Array<PublicParticipation> }) => {
  return participations.length ? (
    <>
      {participations.map((participation) => (
        <UpcomingParticipation key={participation.id} participation={participation} />
      ))}
    </>
  ) : (
    <NoUpcomingParticipations />
  )
}

const UpcomingParticipations = ({ participations }: Props) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const { userType } = useLoggedInUserType()
  const participationsToShow = participations?.edges
    .slice(0, NUMBER_OF_UPCOMING_PARTICIPATIONS_TO_SHOW)
    .map((participation) => participation.node)

  const handleTooltipClose = () => {
    localStorage.setItem(TOOLTIP_TYPE.UPCOMING_BOOKINGS, 'true')
    setIsTooltipOpen(false)
  }

  useEffect(() => {
    // we should show this tooltip only if the share profile tooltip has already been dismissed before
    if (
      window.localStorage.getItem(TOOLTIP_TYPE.SHARE_PROFILE) &&
      !window.localStorage.getItem(TOOLTIP_TYPE.UPCOMING_BOOKINGS)
    ) {
      setIsTooltipOpen(true)
    }
  }, [])

  return (
    <Stack gap={3} paddingX={{ xs: 4, md: 0 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={3}>
        <Stack direction="row" alignItems="center" gap={2}>
          <img src={UpcomingBookingsIcon} width="17px" height="16px" />
          <Heading
            is="h3"
            sx={{ color: 'midnight', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
          >
            <Localized id="user-profile-upcoming-bookings-title" />
          </Heading>
        </Stack>
        {userType === UserType.PROFILE_OWNER && (
          <PromotionalTooltip
            open={isTooltipOpen}
            content={
              <Stack gap={1}>
                <Stack direction="row" justifyContent="space-between">
                  <Text sx={{ color: 'white', textAlign: 'left', fontWeight: 'bold' }}>
                    <Localized id="user-connection-tooltip-upcoming-bookings-title" />
                  </Text>
                  <Box onClick={handleTooltipClose} sx={{ cursor: 'pointer' }}>
                    <Icon size="small" color="white" src={CloseIcon} />
                  </Box>
                </Stack>
                <Text variant="small" sx={{ color: 'white' }}>
                  <Localized id="user-connection-tooltip-upcoming-bookings-description" />
                </Text>
              </Stack>
            }
          >
            <PrivacySettingButton field={PrivacySettingField.UPCOMING_BOOKINGS} onClick={handleTooltipClose} />
          </PromotionalTooltip>
        )}
      </Stack>
      {participationsToShow ? (
        <UpcomingParticipationsContent participations={participationsToShow} />
      ) : (
        <PrivateInformation field={PrivacySettingField.UPCOMING_BOOKINGS} />
      )}
      {participations?.edges && participations.edges.length > NUMBER_OF_UPCOMING_PARTICIPATIONS_TO_SHOW && (
        <ShowAllUpcomingParticipations />
      )}
    </Stack>
  )
}

export default UpcomingParticipations
