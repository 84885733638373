import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface Props {
  to: string
  external?: boolean
  blank?: boolean
  ariaLabel?: string
}

const Link: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  external = false,
  blank = false,
  to,
  ariaLabel,
}) => {
  const blankProps = blank ? { target: `_blank`, rel: 'noopener' } : {}
  const ariaLabelProps = ariaLabel ? { 'aria-label': ariaLabel } : {}

  return external ? (
    <a href={to} {...blankProps} {...ariaLabelProps} style={{ display: 'contents' }}>
      {children}
    </a>
  ) : (
    <RouterLink to={to} {...ariaLabelProps} {...blankProps} style={{ display: 'contents' }}>
      {children}
    </RouterLink>
  )
}

export default Link
