import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import { TableRow, TableCell, TableHead } from '@eversports/klimt-primitives/Table'

import { Localized } from '../../../localization/react'

interface Props {
  headerId: 'date' | 'payment-method' | 'payment-amount' | 'payment-status' | 'download-invoices'
}

const HeaderText = ({ headerId }: Props) => (
  <TableCell>
    <Text color="gray" sx={{ fontWeight: 'bold' }}>
      <Localized id={`invoice-history-table-header-${headerId}` as any} />
    </Text>
  </TableCell>
)

const TableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <HeaderText headerId="date" />
        <HeaderText headerId="payment-method" />
        <HeaderText headerId="payment-amount" />
        <HeaderText headerId="payment-status" />
        <HeaderText headerId="download-invoices" />
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
