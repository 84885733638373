import * as React from 'react'

import { VenueAmenityType, Branch } from '../../../../App.types'
import SectionWrapper from '../../components/SectionWrapper'

import About from './About'
import Branches from './Branches'
import Location from './Location'
import Amenities from './Amenities'

interface Props {
  about: string
  address: string
  latitude?: number | null
  longitude?: number | null
  headerOffset: number
  amenities: Array<VenueAmenityType>
  branches: Array<Branch>
  hideAddress: boolean
}

const Overview = ({ about, address, latitude, longitude, branches, amenities, headerOffset, hideAddress }: Props) => {
  return (
    <SectionWrapper>
      <About content={about} />
      <Amenities amenities={amenities} />
      {!hideAddress && (
        <Location address={address} latitude={latitude} longitude={longitude} headerOffset={headerOffset} />
      )}
      <Branches branches={branches} headerOffset={headerOffset} />
    </SectionWrapper>
  )
}

export default Overview
