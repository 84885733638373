import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../localization/react'
import useLoggedInUserType from '../hooks/use-logged-in-user-type'
import { UserType } from '../PublicUserProfile.types'
import { PrivacySettingField } from '../../../App.types'

import ConnectToSeeContent from './ConnectToSeeContent'

interface Props {
  field: PrivacySettingField
}

const PrivateInformation = ({ field }: Props) => {
  const { userType } = useLoggedInUserType()

  return userType === UserType.FRIENDS ? (
    <Box padding={4} sx={{ border: '1px dashed', borderColor: 'mediumgray', textAlign: 'center' }}>
      <Text variant="small">
        <Localized id="user-profile-private-information" />
      </Text>
    </Box>
  ) : (
    <ConnectToSeeContent field={field} />
  )
}

export default PrivateInformation
