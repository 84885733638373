import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useUseMatchCommentsMatchLazyQuery, useUseMatchCommentsMatchQuery } from '../../../../graphql'
import { NUMBER_OF_COMMENTS_TO_FETCH } from '../CommentsSection.constants'
import { Comment } from '../CommentsSection.types'
import { NUMBER_OF_INITIAL_COMMENTS_FETCHED } from '../../Match.constants'

type CommentWithCursor = Comment & { cursor?: string }

interface Result {
  comments: Array<CommentWithCursor>
  loading: boolean
  fetchLastComment: () => void
  fetchMoreComments: () => void
  hasMoreResults?: boolean
}

const mergeNewComments = (previousComments: Array<CommentWithCursor>, newComments: Array<CommentWithCursor>) => {
  const newUniqueComments = newComments.filter(
    (comment) => !previousComments.some((previousComment) => previousComment.id === comment.id),
  )
  const comments = [...newUniqueComments, ...previousComments]
  return comments
}

const useMatchComments = (): Result => {
  const { id } = useParams<{ id: string }>()
  const [comments, setComments] = useState<Array<CommentWithCursor>>([])
  const [hasMoreResults, setHasMoreResults] = useState<boolean | undefined>()
  const [getMoreComments, { data: newComments, loading }] = useUseMatchCommentsMatchLazyQuery()
  const { data, refetch } = useUseMatchCommentsMatchQuery({
    variables: { matchId: id, first: NUMBER_OF_INITIAL_COMMENTS_FETCHED },
  })

  const initialComments = useMemo(
    () => data?.match.comments.edges.map(({ node, cursor }) => ({ ...node, cursor })) || [],
    [],
  )

  const initialHasMoreResults = useMemo(() => Boolean(data?.match.comments.pageInfo.hasNextPage), [])

  const fetchMoreComments = () => {
    if (hasMoreResults === false) return
    const indexOfLastComment = comments.length > 0 ? comments.length - 1 : initialComments.length - 1
    const cursor =
      comments.length > 0 ? comments[indexOfLastComment]?.cursor : initialComments[indexOfLastComment]?.cursor
    void getMoreComments({
      variables: { matchId: id, first: NUMBER_OF_COMMENTS_TO_FETCH, after: cursor },
    })
  }

  const fetchLastComment = () => {
    void refetch({ matchId: id, first: NUMBER_OF_INITIAL_COMMENTS_FETCHED })
  }

  useEffect(() => {
    if (!newComments?.match.comments.edges.length) return
    const fetchedComments = newComments.match.comments.edges.map(({ node, cursor }) => ({ ...node, cursor }))
    const existingComments = comments.length > 0 ? comments : initialComments
    setHasMoreResults(Boolean(newComments?.match.comments.pageInfo.hasNextPage))
    setComments([...existingComments, ...fetchedComments])
  }, [newComments])

  useEffect(() => {
    if (!data?.match.comments.edges.length) return
    const lastComments = data.match.comments.edges.map(({ node, cursor }) => ({ ...node, cursor }))
    const existingComments = comments.length > 0 ? comments : initialComments
    const newComments = mergeNewComments(existingComments, lastComments)
    setComments(newComments)
  }, [data])

  return {
    loading,
    fetchMoreComments,
    fetchLastComment,
    hasMoreResults: hasMoreResults ?? initialHasMoreResults,
    comments: comments.length ? comments : initialComments,
  }
}

export default useMatchComments
