import { createTheme } from '@mui/material/styles'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import ResetCSS from './reset-css'
import FigtreeFontFace from './figtree-font-face'
import baseTheme from './base-theme'

const theme = createTheme(baseTheme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ${FigtreeFontFace}
        ${ResetCSS}
      `,
    },
  },
  shadows: {
    ...baseTheme.shadows,
    0: boxShadows.default,
    1: boxShadows.hover,
  },
})

export type Theme = typeof theme

export default theme

export { createTheme }
