import Processing from '@eversports/klimt-primitives/Processing'
import * as React from 'react'

import ParticipationButton from '../components/ParticipationButton'
import MobileButtonContainer from '../components/MobileButtonContainer'

interface Props {
  isUserJoiningMatch: boolean
  handleClick: () => void
  isMobile: boolean
  isDisabled: boolean
}

const JoinMatchButton = ({
  children,
  isUserJoiningMatch,
  handleClick,
  isMobile,
  isDisabled,
}: React.PropsWithChildren<Props>) => {
  return isMobile ? (
    <MobileButtonContainer>
      <Processing isLoading={isUserJoiningMatch}>
        <ParticipationButton onClick={handleClick} isDisabled={isDisabled}>
          {children}
        </ParticipationButton>
      </Processing>
    </MobileButtonContainer>
  ) : (
    <Processing isLoading={isUserJoiningMatch}>
      <ParticipationButton onClick={handleClick} isDisabled={isDisabled}>
        {children}
      </ParticipationButton>
    </Processing>
  )
}

export default JoinMatchButton
