import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'

import { Localized } from '../../localization/react'

interface Props {
  openSpots: number
}

const OpenSpots = ({ openSpots }: Props) => {
  const hasOpenSpots = openSpots > 0
  return (
    <Box
      px={3}
      py={1}
      sx={{ backgroundColor: hasOpenSpots ? 'rgba(33, 166, 150, 0.08)' : 'rgba(255, 26, 26, 0.08)', borderRadius: 10 }}
    >
      <Text variant="xsmall" sx={{ color: hasOpenSpots ? 'primary.main' : 'red' }}>
        <Localized id="number-of-open-spots" params={{ openSpots }} />
      </Text>
    </Box>
  )
}

export default OpenSpots
