import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Divider from '@eversports/klimt-primitives/Divider'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import SeoLink from '@eversports/klimt-primitives/SeoLink'
import { useLocation } from 'react-router-dom'

import { Venue } from '../../VenueProfile.types'
import { getLinkToTeacherOffering } from '../../../../helpers/get-link-to-teacher-offering'
import TeacherCardContainer from '../../../../components/TeacherCard/TeacherCardContainer'
import TeacherCardButton from '../../../../components/TeacherCard/TeacherCardButton'
import Routes from '../../VenueProfile.routes'

import TrainerCardInfo from './TrainerCardInfo'
import { Props } from './TrainerCard'

const VENUE_PROFILE_OVERVIEW_PAGE_NAME_FOR_AMPLITUDE = 'venue profile overview'
const VENUE_PROFILE_TEAM_PAGE_NAME_FOR_AMPLITUDE = 'venue profile team'

interface ActiveCardProps extends Props {
  venue: Venue
}

const ActiveTrainerCard = ({ trainer, venue }: ActiveCardProps) => {
  const { amplitude } = useAmplitude()
  const { pathname } = useLocation()

  const handleCardClick = () => {
    if (amplitude) {
      const teamRoute = Routes.TEAM.split('/')[1]
      const isTeamTab = pathname.split('/')[3] === teamRoute

      amplitude.logEvent('Clicked Trainer Tile', {
        from: isTeamTab ? VENUE_PROFILE_TEAM_PAGE_NAME_FOR_AMPLITUDE : VENUE_PROFILE_OVERVIEW_PAGE_NAME_FOR_AMPLITUDE,
        trainer: {
          hasImage: Boolean(trainer.image),
          numberOfSports: trainer.sports.length,
        },
      })
    }
  }

  const path = getLinkToTeacherOffering({
    venueOfferings: venue.offerings,
    venueSlug: venue.slug,
    teacherId: trainer.id,
  })

  return (
    <TeacherCardContainer isClickable handleClick={handleCardClick}>
      <SeoLink encodedTo={btoa(path.to)} external blank type="hidden">
        <Stack gap={{ xs: 2, md: 4 }}>
          <TrainerCardInfo trainer={trainer} />
          <Divider sx={{ borderColor: 'ligthgray' }} />
          <TeacherCardButton isActiveAtVenue={true} isOverviewPage={path.isOverviewPage} />
        </Stack>
      </SeoLink>
    </TeacherCardContainer>
  )
}

export default ActiveTrainerCard
