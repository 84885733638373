import * as React from 'react'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import { useEffect, useState } from 'react'

import FullscreenDialog from '../../../../../../../../components/FullscreenDialog'
import { Localized } from '../../../../../../../../localization/react'
import {
  SuggestedType,
  useAllSuggestedFriendsMeLazyQuery,
  useAllSuggestedFriendsMeQuery,
} from '../../../../../../../../graphql'
import { PublicUserEdge } from '../SuggestedFriends.types'

import AllSuggestedFriendsContent from './AllSuggestedFriendsContent'
import { NUMBER_OF_SUGGESTED_FRIENDS_TO_SHOW } from './AllSuggestedFriends.constants'

interface Props {
  isOpen: boolean
  toggleDialog: () => void
}

const AllSuggestedFriends = ({ isOpen, toggleDialog }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [suggestedFriends, setSuggestedFriends] = useState<Array<PublicUserEdge>>([])

  const [loadMoreFriends, { data: additionalData, loading }] = useAllSuggestedFriendsMeLazyQuery()
  const {
    data,
    loading: initialLoading,
    previousData,
  } = useAllSuggestedFriendsMeQuery({
    variables: {
      first: NUMBER_OF_SUGGESTED_FRIENDS_TO_SHOW,
      suggestedTypes: [SuggestedType.COMMON_FRIENDS, SuggestedType.COMMON_ACTIVITIES],
    },
  })

  useEffect(() => {
    if (!data || !data.me || previousData) return
    setSuggestedFriends(data.me.suggestedFriends.edges)
  }, [data])

  useEffect(() => {
    if (!additionalData || !additionalData.me) return
    setSuggestedFriends([...suggestedFriends, ...additionalData.me.suggestedFriends.edges])
  }, [additionalData])

  const suggestedFriendsWithCursor = suggestedFriends?.map((edge) => edge.node)
  const hasMoreFriends = Boolean(
    additionalData
      ? additionalData.me?.suggestedFriends.pageInfo.hasNextPage
      : data?.me?.suggestedFriends.pageInfo.hasNextPage,
  )

  const fetchMoreFriends = () => {
    const cursor = suggestedFriends[suggestedFriends.length - 1].cursor
    void loadMoreFriends({
      variables: {
        first: NUMBER_OF_SUGGESTED_FRIENDS_TO_SHOW,
        after: cursor,
      },
    })
  }

  return isMobile ? (
    <FullscreenDialog title={<Localized id="suggested-friends-title" />} isOpen={isOpen} onClose={toggleDialog}>
      <AllSuggestedFriendsContent
        friends={suggestedFriendsWithCursor}
        hasMoreFriends={hasMoreFriends}
        onLoadMore={fetchMoreFriends}
        initialLoading={initialLoading}
        loading={loading}
      />
    </FullscreenDialog>
  ) : (
    <Dialog open={isOpen} onClose={toggleDialog}>
      <DialogContent sx={{ height: '672px', padding: 0 }}>
        <AllSuggestedFriendsContent
          friends={suggestedFriendsWithCursor}
          hasMoreFriends={hasMoreFriends}
          onLoadMore={fetchMoreFriends}
          initialLoading={initialLoading}
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  )
}

export default AllSuggestedFriends
