import * as React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import { default as MuiCheckbox, CheckboxProps } from '@mui/material/Checkbox'
import { TextVariants } from '@eversports/design-tokens/typography'

import Text from '../Text'

export interface Props extends CheckboxProps {
  label: React.ReactNode
  labelVariant?: keyof TextVariants
}

const Checkbox: React.FC<React.PropsWithChildren<Props>> = ({
  inputProps,
  label,
  labelVariant = 'regular',
  ...props
}) => {
  return (
    <FormControlLabel
      control={<MuiCheckbox {...props} inputProps={{ ...inputProps, 'aria-label': label as string }} />}
      label={<Text variant={labelVariant}>{label}</Text>}
      sx={{
        margin: 0,
      }}
    />
  )
}

export default Checkbox
