import * as React from 'react'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { formatTime } from '../../../../../../helpers/format-time'
import { getDurationInMinutes } from '../../../../../../helpers/get-duration-in-minutes'
import { formatDate } from '../../../../../../helpers/format-date'

interface Props {
  start: string
  end: string
}

const FriendUpcomingParticipationTimeRange = ({ start, end }: Props) => {
  const { language } = useSettingsContext()
  const date = formatDate(language, start)
  const startTime = formatTime(language, start)
  const endTime = formatTime(language, end)
  const duration = getDurationInMinutes(start, end)

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Text variant="large" sx={{ fontWeight: 'bold', color: 'midnight' }}>
        {date} • {startTime} - {endTime} • {duration} Min
      </Text>
    </Stack>
  )
}

export default FriendUpcomingParticipationTimeRange
