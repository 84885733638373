import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { Localized, useIntl } from '../../../localization/react'
import SectionWrapper from '../components/SectionWrapper'

import FriendsFeed from './assets/friends-feed.webp'
import Rocket from './assets/rocket.svg'
import ClassSchedule from './assets/schedule.webp'
import Schedule from './assets/schedule.svg'
import TwoActivePeople from './assets/two-active-people.webp'
import User from './assets/user.svg'
import FeatureCard from './FeatureCard'

const FeaturePromotion = () => {
  const intl = useIntl()
  return (
    <SectionWrapper sx={{ marginBottom: 15, contentVisibility: 'auto' }}>
      <Heading
        is="h2"
        variant="xlarge"
        color="midnight"
        sx={{ fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'", fontWeight: 500 }}
      >
        <Localized id="home-feature-preview-header" />
      </Heading>
      <Text variant="large" sx={{ paddingBottom: 4 }}>
        <Localized id="home-feature-preview-description" />
      </Text>
      <Stack
        component="ul"
        sx={{
          flexDirection: { sm: 'row' },
          gap: 3,
          padding: 0,
          '& > li:nth-child(3)': {
            display: { xs: 'none', md: 'flex' },
          },
        }}
      >
        <FeatureCard
          imgSrc={FriendsFeed}
          iconSrc={User}
          title={intl.homeFeaturePreviewFriendsFeedTitle()}
          description={intl.homeFeaturePreviewFriendsFeedDescription()}
        />
        <FeatureCard
          imgSrc={ClassSchedule}
          iconSrc={Schedule}
          title={intl.homeFeaturePreviewWaitingListTitle()}
          description={intl.homeFeaturePreviewWaitingListDescription()}
        />
        <FeatureCard
          imgSrc={TwoActivePeople}
          iconSrc={Rocket}
          title={intl.homeFeaturePreviewUpdatesTitle()}
          description={intl.homeFeaturePreviewUpdatesDescription()}
        />
      </Stack>
    </SectionWrapper>
  )
}

export default FeaturePromotion
