import { useEffect, useState } from 'react'

import { Trainer } from '../../../VenueProfile.types'
import { useUseVenueTrainersVenueLazyQuery } from '../../../../../graphql'

export const NUMBER_OF_TRAINERS_TO_FETCH = 12

interface Props {
  venueId: string
  initialTrainers: Array<Trainer>
  initialHasMoreResults: boolean
}

interface Result {
  trainers: Array<Trainer>
  loading: boolean
  fetchMoreTrainers: () => Promise<void>
  hasMoreResults: boolean
}

const useVenueTrainers = ({ venueId, initialTrainers, initialHasMoreResults }: Props): Result => {
  const [trainers, setTrainers] = useState<Array<Trainer>>(initialTrainers)
  const [hasMoreResults, setHasMoreResults] = useState(initialHasMoreResults)

  const [getMoreTrainers, { data, loading }] = useUseVenueTrainersVenueLazyQuery()

  const fetchMoreTrainers = async () => {
    const indexOfLastTrainer = trainers.length - 1
    const cursor = trainers[indexOfLastTrainer]?.cursor
    await getMoreTrainers({
      variables: { id: venueId, first: NUMBER_OF_TRAINERS_TO_FETCH, after: cursor },
    })
  }

  useEffect(() => {
    if (data && data.venue && data.venue.trainers.edges.length > 0) {
      const fetchedTrainers = data.venue.trainers.edges.map(({ node, cursor }) => ({ ...node, cursor }))
      setHasMoreResults(Boolean(data?.venue.trainers.pageInfo.hasNextPage))
      setTrainers([...trainers, ...fetchedTrainers])
    }
  }, [data])

  return { loading, fetchMoreTrainers, trainers, hasMoreResults }
}

export default useVenueTrainers
