import * as React from 'react'
import { useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import Text from '@eversports/klimt-primitives/Text'
import Tooltip from '@eversports/klimt-primitives/Tooltip'
import LinkIcon from '@eversports/design-tokens/assets/icons/link.svg'

import { Localized } from '../localization/react'

import SharingActionBox from './SharingActionBox'

import { ShareType } from '@eversports/klimt-patterns/SharingActions/SharingActions.types'

interface Props {
  trackClick: (type: ShareType.CopyLink) => void
  link: string
}

const SharingActionCopyLink = ({ trackClick, link }: Props) => {
  const [showToolTip, setToolTip] = useState(false)

  const handleCopyLink = () => {
    trackClick(ShareType.CopyLink)
    void navigator.clipboard.writeText(link).then(() => {
      setToolTip(true)
      setTimeout(() => setToolTip(false), 1500)
    })
  }

  return (
    <Stack alignItems="center" justifyContent="center" gap={2} flexGrow={1} flexShrink={0}>
      <Tooltip title={<Localized id="tooltip-copied-link" />} arrow placement="bottom" open={showToolTip}>
        <SharingActionBox onClick={handleCopyLink}>
          <Icon src={LinkIcon} color="midnight" size="default" />
        </SharingActionBox>
      </Tooltip>
      <Text variant="small" textAlign="center" sx={{ color: 'midnight' }}>
        <Localized id="sharing-actions-copy-link" />
      </Text>
    </Stack>
  )
}

export default SharingActionCopyLink
