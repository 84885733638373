import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'

const MobileButtonContainer = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <Box
      px={4}
      py={3}
      sx={{
        position: 'sticky',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'white',
        boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.16)',
      }}
    >
      {children}
    </Box>
  )
}

export default MobileButtonContainer
