import * as React from 'react'
import { TableContainer, Table } from '@eversports/klimt-primitives/Table'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import { Invoice } from '../InvoiceHistory.types'

import TableHeader from './TableHeader'
import TableContent from './TableContent'

interface Props {
  invoices: Array<Invoice>
}

const InvoiceTable = ({ invoices }: Props) => {
  return (
    <TableContainer sx={{ boxShadow: boxShadows.default, display: { xs: 'none', md: 'block' } }}>
      <Table>
        <TableHeader />
        <TableContent invoices={invoices} />
      </Table>
    </TableContainer>
  )
}

export default InvoiceTable
