import * as React from 'react'
import { List, ListItem } from '@eversports/klimt-primitives/List'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import ClockIcon from '@eversports/design-tokens/assets/icons/clock.svg'

import { Localized } from '../../../../localization/react'
import { Slot } from '../../../../App.types'
import { useDiscoverState } from '../../DiscoverContext'
import { formatTimeAsQueryVariable } from '../../../../utils/format-date-time'

import CourtSlot from './CourtSlot'
import CourtSlotsSkeleton from './CourtSlotsSkeleton'

interface Props {
  slots?: Array<Slot>
  venueId: string
  to: string
  numberOfSlotsToShow?: number
}

const CourtSlots = ({ slots, venueId, to, numberOfSlotsToShow = 5 }: Props) => {
  const { venueCourtSlotTimeActiveFilter } = useDiscoverState()

  if (!slots) {
    return <CourtSlotsSkeleton numberOfSlotsToShow={numberOfSlotsToShow} />
  }

  if (slots.length === 0) {
    return (
      <Box p={3} sx={{ backgroundColor: 'lightgray', maxWidth: 385 }}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Icon src={ClockIcon} size="small" color="gray" />
          <Text variant="small">
            <Localized id="court-slots-nothing-available-on-the-day" />
          </Text>
        </Stack>
      </Box>
    )
  }

  const courtSlotTimeAsString = venueCourtSlotTimeActiveFilter
    ? formatTimeAsQueryVariable(venueCourtSlotTimeActiveFilter)
    : null

  const slotsToShow = slots.slice(0, numberOfSlotsToShow)

  const indexOfFirstActiveSlot = slotsToShow.findIndex((slot) => {
    const slotStartHour = formatTimeAsQueryVariable(new Date(slot.start)).split(':')[0]
    const activeTimeFilterHour = courtSlotTimeAsString && courtSlotTimeAsString.split(':')[0]
    return activeTimeFilterHour && slotStartHour === activeTimeFilterHour
  })

  const showMore = slots.length > slotsToShow.length

  return (
    <List sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 2, p: 0, mr: [0, 8] }}>
      {slotsToShow.map((slot: Slot, index: number) => (
        <CourtSlot key={`${venueId}-${slot.start}`} slot={slot} isActive={index === indexOfFirstActiveSlot} to={to} />
      ))}
      {showMore && (
        <ListItem
          sx={{
            p: 0,
            width: 'auto',
          }}
        >
          <Text
            variant="small"
            sx={{ fontWeight: ['normal', 'bold'], padding: { xs: '0px 18px 0px 0px', sm: '8px 8px 8px 0px' } }}
          >
            <Localized id="more-slots-label" />
          </Text>
        </ListItem>
      )}
    </List>
  )
}

export default CourtSlots
