import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Link from '@eversports/klimt-primitives/Link'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import ErrorIcon from '@eversports/design-tokens/assets/icons/error.svg'

import useGetTopContentHeight from '../../hooks/useGetTopContentHeight'
import { Localized } from '../../localization/react'

const Forbidden = () => {
  const { DESKTOP_TOP_CONTENT_HEIGHT: TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])
  return (
    <Stack
      sx={{
        backgroundColor: 'eggshell',
        height: `calc(100vh - ${TOP_CONTENT_HEIGHT}px)`,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      component="main"
    >
      <Stack gap={4} paddingX={4} alignItems="center" justifyContent="center" data-testid="error">
        <Icon src={ErrorIcon} size="x-large" color="red" />
        <Text variant="large" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          <Localized id="forbidden-error-message" />
        </Text>
        <Link to="/">
          <Button variant="primary" sx={{ px: 2, py: 1 }}>
            <Text variant="badge" sx={{ color: 'white' }}>
              <Localized id="back-to-home" />
            </Text>
          </Button>
        </Link>
      </Stack>
    </Stack>
  )
}

export default Forbidden
