import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import FilledStar from '@eversports/design-tokens/assets/icons/filled-star.svg'
import EmptyStar from '@eversports/design-tokens/assets/icons/empty-star.svg'
import RatingComponent from '@eversports/klimt-primitives/Rating'
import styled from '@eversports/klimt-design-components/styled'
import { palette } from '@eversports/design-tokens/palette'

import { Localized } from '../../../../localization/react'
import { Rating, RatingTypeToNameMapping } from '../../VenueProfile.types'

import GrayRectangle from './assets/gray-rectangle.svg'
import TertiaryRectangle from './assets/tertiary-rectangle.svg'

interface Props {
  rating: number | null
  reviewCount: number
  ratings: Array<Rating>
}

const GoldenRectangle = styled('span')`
  width: 12px;
  height: 2px;
  margin-right: 2px;
  padding-right: 8px;
  background-image: url(${TertiaryRectangle as string});
  position: relative;
  border-radius: 0px 20px 20px 0px;

  :after {
    border-radius: 0px 20px 20px 0px;
    position: absolute;
    background: ${palette.tertiary};
    right: 0px;
    content: '';
    width: 2px;
    height: 2px;
  }
`

const Categories = ({ rating, reviewCount, ratings }: Props) => {
  const hasReviews = reviewCount > 0
  const overallRating = rating ?? 0

  return (
    <Stack spacing={7} flex={{ xs: 1, lg: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <img src={reviewCount ? FilledStar : EmptyStar} alt="rating" loading="lazy" width={24} height={24} />
          <Text variant="large" sx={{ fontSize: '26px', fontWeight: 'bold' }}>
            {overallRating.toFixed(1)}
          </Text>
        </Stack>
        {hasReviews ? (
          <Text variant="small" color="darkgray" sx={{ fontWeight: 'bold' }}>
            <Localized id="venue-profile-label-review-count" params={{ reviewCount }} />
          </Text>
        ) : (
          <Text variant="small" color="darkgray" sx={{ fontWeight: 'bold' }}>
            <Localized id="rating-no-reviews" />
          </Text>
        )}
      </Stack>
      <Stack spacing={5}>
        {ratings.map((rating, idx) => {
          const ratingValue = (rating.value ?? 0).toFixed(1)
          return (
            <Stack key={idx} direction="row" justifyContent="space-between" alignItems="center">
              <Text variant="small">
                <Localized id={RatingTypeToNameMapping[rating.type]} />
              </Text>
              <Stack spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                <RatingComponent
                  defaultValue={Number(ratingValue)}
                  readOnly
                  icon={<GoldenRectangle />}
                  emptyIcon={
                    <img
                      width={12}
                      height={2}
                      src={GrayRectangle}
                      alt="unfulfilled rating score"
                      style={{ marginRight: '2px' }}
                    />
                  }
                  precision={0.1}
                />
                <Text variant="small" sx={{ fontWeight: 'bold' }}>
                  {ratingValue}
                </Text>
              </Stack>
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default Categories
