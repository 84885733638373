import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Divider from '@eversports/klimt-primitives/Divider'

import TeacherCardContainer from '../../../../components/TeacherCard/TeacherCardContainer'
import TeacherCardButton from '../../../../components/TeacherCard/TeacherCardButton'
import { Trainer } from '../../VenueProfile.types'

import TrainerCardInfo from './TrainerCardInfo'

interface Props {
  trainer: Trainer
}

const DisabledTrainerCard = ({ trainer }: Props) => (
  <TeacherCardContainer>
    <Stack gap={4}>
      <TrainerCardInfo trainer={trainer} />
      <Divider sx={{ borderColor: 'ligthgray' }} />
      <TeacherCardButton isActiveAtVenue={false} />
    </Stack>
  </TeacherCardContainer>
)

export default DisabledTrainerCard
