import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import { useEffect, useRef, useState } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'

import NoCommentsPlaceholderSection from './NoCommentsPlaceholderSection'
import CommentsAreaDelimiter from './components/CommentsAreaDelimiter'

interface Props {
  variant: 'contained' | 'fullscreen'
  isEmpty: boolean
}

const CommentsArea = ({ variant, isEmpty, children }: React.PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null)
  const [lastChildKey, setLastChildKey] = useState<string | null>(null)

  useEffect(() => {
    if (!ref.current || !children || isEmpty) return
    const childrenObject = Object.values(children)
    const comments = childrenObject[0] as Array<{ key: string }>
    if (lastChildKey === comments[0].key) return
    setLastChildKey(comments[0].key)
    ref.current.scrollTop = ref.current.scrollHeight
  }, [children])

  return (
    <Stack
      sx={{
        backgroundColor: 'lightgray',
        boxShadow: boxShadows.default,
        maxHeight: variant === 'contained' ? '400px' : 'initial',
        minHeight: variant === 'contained' ? '210px' : 'initial',
        overflowY: 'scroll',
        flex: 1,
      }}
      forwardedRef={ref}
    >
      <CommentsAreaDelimiter position="top" />
      <Box sx={{ flex: 1, py: 3, px: 4, display: 'flex' }}>
        <Stack
          direction="column-reverse"
          sx={{ maxWidth: '600px', margin: isEmpty ? 'auto' : '0 auto', height: '100%', flex: 1 }}
          gap={2}
        >
          {isEmpty ? <NoCommentsPlaceholderSection /> : children}
        </Stack>
      </Box>
      <CommentsAreaDelimiter position="bottom" />
    </Stack>
  )
}

export default CommentsArea
