import { useLocation } from 'react-router-dom'

import { Origin, URLParams } from '../App.types'

const useUrlParams = () => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)

  const parsedParams: URLParams = { origin: Origin.MARKETPLACE }

  if (params.get('origin')) {
    parsedParams.origin = params.get('origin') === 'widget' ? Origin.WIDGET : Origin.MARKETPLACE
  }

  if (params.get('returnTo')) {
    parsedParams.returnTo = params.get('returnTo') as string
  }

  if (params.get('from')) {
    parsedParams.from = params.get('from') as string
  }

  if (params.get('show')) {
    parsedParams.show = params.get('show') as string
  }

  if (params.get('view')) {
    parsedParams.view = params.get('view') as string
  }

  return parsedParams
}

export default useUrlParams
