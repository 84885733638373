import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box from '@eversports/klimt-primitives/Box'

import { Localized } from '../../../../localization/react'

interface Props {
  icon: string | null
  localizedId: string
}

const OfferingCard = ({ icon, localizedId }: Props) => (
  <Box
    sx={{
      paddingX: 4,
      paddingY: 6,
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'mediumgray',
      boxShadow: boxShadows.default,
      flexShrink: 0,
      backgroundColor: 'white',
      color: 'midnight',
      minWidth: { xs: '132px', sm: '148px' },
      width: 'fit-content',
      '&:hover': { boxShadow: boxShadows.hover },
    }}
  >
    {icon && (
      <img src={icon} alt="" loading="lazy" width={20} height={20} style={{ flexShrink: 0, marginBottom: '8px' }} />
    )}
    <Localized id={localizedId as any} />
  </Box>
)
export default OfferingCard
