import { default as React } from 'react'
import { default as MuiStack, StackProps, StackTypeMap } from '@mui/material/Stack'

export type { StackProps }

// Why the extra prop: https://stackoverflow.com/questions/58578570/value-of-using-react-forwardref-vs-custom-ref-prop/60237948#60237948
const Stack = <D extends React.ElementType = StackTypeMap['defaultComponent'], P = Record<string, never>>(
  props: StackProps<D, P> & { forwardedRef?: React.Ref<HTMLDivElement> },
) => {
  const { forwardedRef, ...rest } = props
  return <MuiStack ref={forwardedRef} {...rest} />
}

export default Stack
