export interface FooterLinksProps {
  footerLinks: {
    about: string
    career: string
    website: string
    pricing: string
    demo: string
    instagram: string
    facebook: string
    youtube: string
  }
}

export const FooterLinksMapping = {
  at: {
    about: 'https://www.eversportsmanager.com/de/uber-uns/',
    corporate: 'https://www.eversportscorporate.com/de/corporate-sports/',
    career: 'https://www.eversportsmanager.com/de/jobs/',
    website:
      'https://www.eversportsmanager.com/de/partner-werden/?utm_source=Referrals&utm_medium=marketplace-website-footer&utm_campaign=mp-b2b-landingpage',
    pricing:
      'https://www.eversportsmanager.com/de/preise/?utm_source=Referrals&utm_medium=marketplace-pricing-footer&utm_campaign=mp-b2b-pricingpage',
    demo: 'https://www.eversportsmanager.com/de/demo/?utm_source=Referrals&utm_medium=marketplace-demo-footer&utm_campaign=mp-b2b-demopage',
    instagram: 'https://www.instagram.com/eversports_official/',
    facebook: 'https://www.facebook.com/eversports',
    youtube: 'https://www.youtube.com/channel/UCJvG0QxfPt33Ds1bx436-vQ',
  },
  ch: {
    about: 'https://www.eversportsmanager.com/de/uber-uns/',
    corporate: 'https://www.eversportscorporate.com/',
    career: 'https://www.eversportsmanager.com/de/jobs/',
    website:
      'https://www.eversportsmanager.com/de/partner-werden/?utm_source=Referrals&utm_medium=marketplace-website-footer&utm_campaign=mp-b2b-landingpage',
    pricing:
      'https://www.eversportsmanager.com/de/preise/?utm_source=Referrals&utm_medium=marketplace-pricing-footer&utm_campaign=mp-b2b-pricingpage',
    demo: 'https://www.eversportsmanager.com/de/demo/?utm_source=Referrals&utm_medium=marketplace-demo-footer&utm_campaign=mp-b2b-demopage',
    instagram: 'https://www.instagram.com/eversports_official/',
    facebook: 'https://www.facebook.com/eversports',
    youtube: 'https://www.youtube.com/channel/UCJvG0QxfPt33Ds1bx436-vQ',
  },
  de: {
    about: 'https://www.eversportsmanager.com/de/uber-uns/',
    corporate: 'https://www.eversportscorporate.com/de/corporate-sports/',
    career: 'https://www.eversportsmanager.com/de/jobs/',
    website:
      'https://www.eversportsmanager.com/de/partner-werden/?utm_source=Referrals&utm_medium=marketplace-website-footer&utm_campaign=mp-b2b-landingpage',
    pricing:
      'https://www.eversportsmanager.com/de/preise/?utm_source=Referrals&utm_medium=marketplace-pricing-footer&utm_campaign=mp-b2b-pricingpage',
    demo: 'https://www.eversportsmanager.com/de/demo/?utm_source=Referrals&utm_medium=marketplace-demo-footer&utm_campaign=mp-b2b-demopage',
    instagram: 'https://www.instagram.com/eversports_official/',
    facebook: 'https://www.facebook.com/eversports',
    youtube: 'https://www.youtube.com/channel/UCJvG0QxfPt33Ds1bx436-vQ',
  },
  fr: {
    about: 'https://www.eversportsmanager.com/fr/a-propos-de-nous/',
    corporate: 'https://www.eversportscorporate.com/',
    career: 'https://www.eversportsmanager.com/fr/jobs/',
    website:
      'https://www.eversportsmanager.com/fr/devenir-partenaire/?utm_source=Referrals&utm_medium=marketplace-website-footer&utm_campaign=mp-b2b-landingpage',
    pricing:
      'https://www.eversportsmanager.com/fr/prix/?utm_source=Referrals&utm_medium=marketplace-pricing-footer&utm_campaign=mp-b2b-pricingpage',
    demo: 'https://www.eversportsmanager.com/fr/demo/?utm_source=Referrals&utm_medium=marketplace&utm_campaign=mp-b2b-demopage',
    instagram: 'https://www.instagram.com/eversportsfr',
    facebook: 'https://www.facebook.com/eversports',
    youtube: 'https://www.youtube.com/channel/UCJvG0QxfPt33Ds1bx436-vQ',
  },
  nl: {
    about: 'https://www.eversportsmanager.com/nl/over-ons/',
    corporate: 'https://www.eversportscorporate.com/',
    career: 'https://www.eversportsmanager.com/nl/vacature/',
    website:
      'https://www.eversportsmanager.com/nl/partner-worden/?utm_source=Referrals&utm_medium=marketplace-website-footer&utm_campaign=mp-b2b-landingpage',
    pricing:
      'https://www.eversportsmanager.com/nl/prijzen/?utm_source=Referrals&utm_medium=marketplace-pricing-footer&utm_campaign=mp-b2b-pricingpage',
    demo: 'https://www.eversportsmanager.com/nl/demo/?utm_source=Referrals&utm_medium=marketplace-demo-footer&utm_campaign=mp-b2b-demopage',
    instagram: 'https://www.instagram.com/eversportsnl/',
    facebook: 'https://www.facebook.com/eversports',
    youtube: 'https://www.youtube.com/channel/UCJvG0QxfPt33Ds1bx436-vQ',
  },
  it: {
    about: 'https://www.eversportsmanager.com/it/riguardo-a-noi/',
    corporate: 'https://www.eversportscorporate.com/',
    career: 'https://www.eversportsmanager.com/jobs/',
    website:
      'https://www.eversportsmanager.com/it/?utm_source=Referrals&utm_medium=marketplace-website-footer&utm_campaign=mp-b2b-landingpage',
    pricing:
      'https://www.eversportsmanager.com/it/prezzi/?utm_source=Referrals&utm_medium=marketplace-pricing-footer&utm_campaign=mp-b2b-pricingpage',
    demo: 'https://www.eversportsmanager.com/it/demo/?utm_source=Referrals&utm_medium=marketplace-demo-footer&utm_campaign=mp-b2b-demopage',
    instagram: 'https://www.instagram.com/eversports_official/',
    facebook: 'https://www.facebook.com/eversports',
    youtube: 'https://www.youtube.com/channel/UCJvG0QxfPt33Ds1bx436-vQ',
  },
  es: {
    about: 'https://www.eversportsmanager.com/es/sobre-nosotros/',
    corporate: 'https://www.eversportscorporate.com/',
    career: 'https://www.eversportsmanager.com/jobs/',
    website:
      'https://www.eversportsmanager.com/es/?utm_source=Referrals&utm_medium=marketplace-website-footer&utm_campaign=mp-b2b-landingpage',
    pricing:
      'https://www.eversportsmanager.com/es/precios/?utm_source=Referrals&utm_medium=marketplace-pricing-footer&utm_campaign=mp-b2b-pricingpage',
    demo: 'https://www.eversportsmanager.com/es/demo/?utm_source=Referrals&utm_medium=marketplace-demo-footer&utm_campaign=mp-b2b-demopage',
    instagram: 'https://www.instagram.com/eversports_official/',
    facebook: 'https://www.facebook.com/eversports',
    youtube: 'https://www.youtube.com/channel/UCJvG0QxfPt33Ds1bx436-vQ',
  },
  be: {
    about: 'https://www.eversportsmanager.com/nl/over-ons/',
    corporate: 'https://www.eversportscorporate.com/',
    career: 'https://www.eversportsmanager.com/nl/vacature/',
    website:
      'https://www.eversportsmanager.com/nl/partner-worden/?utm_source=Referrals&utm_medium=marketplace-website-footer&utm_campaign=mp-b2b-landingpage',
    pricing:
      'https://www.eversportsmanager.com/nl/prijzen/?utm_source=Referrals&utm_medium=marketplace-pricing-footer&utm_campaign=mp-b2b-pricingpage',
    demo: 'https://www.eversportsmanager.com/nl/demo/?utm_source=Referrals&utm_medium=marketplace-demo-footer&utm_campaign=mp-b2b-demopage',
    instagram: 'https://www.instagram.com/eversportsnl/',
    facebook: 'https://www.facebook.com/eversports',
    youtube: 'https://www.youtube.com/channel/UCJvG0QxfPt33Ds1bx436-vQ',
  },
}

export const BLOG_LINK =
  'https://blog.eversports.com/?utm_source=marketplace&utm_medium=footer_link&utm_campaign=b2c_blog_footer_link'
