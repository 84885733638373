import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Stack from '@eversports/klimt-primitives/Stack'
import SeoLink from '@eversports/klimt-primitives/SeoLink'
import * as React from 'react'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { MonetaryValue, VenueProfileTabsPrefix } from '../../../../App.types'

import DividerIcon from './assets/divider.svg'
import CallToActionItem from './CallToActionItem'

interface Props {
  slug: string
  cheapestPrice?: MonetaryValue | null
  cheapestTrialProductPrice?: MonetaryValue | null
}

const CallToActionPrices = ({ slug, cheapestPrice, cheapestTrialProductPrice }: Props) => {
  const { amplitude } = useAmplitude()

  if (!cheapestPrice && !cheapestTrialProductPrice) {
    return null
  }

  const handleClick = () => {
    if (amplitude) {
      const lowestTrialPrice = cheapestTrialProductPrice ? cheapestTrialProductPrice.value / 100 : null
      const lowestPrice = cheapestPrice ? cheapestPrice.value / 100 : null

      amplitude.logEvent('Clicked Price Summary Link', {
        venue: {
          lowestTrialPrice,
          lowestPrice,
        },
      })
    }
  }

  return (
    <SeoLink encodedTo={btoa(`${VenueProfileTabsPrefix.PRICES}${slug}`)} external type="hidden">
      <Box
        onClick={handleClick}
        sx={{
          boxShadow: boxShadows.default,
          border: '1px solid',
          borderColor: 'lightgray',
          backgroundColor: 'white',
          borderRadius: 1,
          '&:hover': {
            boxShadow: boxShadows.hover,
          },
        }}
      >
        <Stack direction="row" justifyContent="space-around">
          {cheapestTrialProductPrice && <CallToActionItem type="trial-offer" price={cheapestTrialProductPrice} />}
          {cheapestTrialProductPrice && cheapestPrice && (
            <img src={DividerIcon} width="8px" height="53px" alt="Divider Icon" />
          )}
          {cheapestPrice && <CallToActionItem type="prices" price={cheapestPrice} />}
        </Stack>
      </Box>
    </SeoLink>
  )
}

export default CallToActionPrices
