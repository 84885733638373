import * as React from 'react'
import { useContext } from 'react'
import { Language } from '@eversports/language'

import { isSupportedTLD, SupportedTLD } from './tld-configs'
import hostsConfig, { createOrigin } from './hosts-config'

interface Props {
  settings: {
    host: string
    protocol: string
    language: Language
  }
}

export interface SettingsContext {
  origin: string
  host: string
  protocol: string
  language: Language
  tld: SupportedTLD
}

const Context = React.createContext<undefined | SettingsContext>(undefined)

export const SettingsContext: React.FC<React.PropsWithChildren<Props>> = ({ settings, children }) => {
  const tld = hostsConfig.get(settings.host).countryCode.toLowerCase()
  const value = {
    protocol: settings.protocol,
    origin: createOrigin(settings),
    host: settings.host,
    tld: isSupportedTLD(tld) ? tld : 'at',
    language: settings.language,
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useSettingsContext = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('Settings context was not correctly defined')
  }

  return context
}

export default SettingsContext
