import * as React from 'react'
import { useEffect, useState } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Dialog, { DialogContent, DialogTitle } from '@eversports/klimt-primitives/Dialog'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'

import { DiscoverMatchesFiltersState } from '../DiscoverSportMatches.types'
import { Action } from '../helpers/discover-sport-matches-reducer'
import { Sport, MatchVenue } from '../../../../../App.types'
import setSearchParams from '../../../../../utils/set-search-params'
import { Localized } from '../../../../../localization/react'
import MobileMoreFilters from '../../../Filters/MobileMoreFilters'

import { CollapsibleVenuesFilter, DrawerVenuesFilter, DesktopVenuesFilter } from './VenuesFilter'
import { CollapsibleSportsFilter, DesktopSportsFilter, DrawerSportsFilter } from './SportsFilter'
import DateFilter from './DateFilter'
import ResetFilters from './ResetFilters'
import { CollapsibleLevelsFilter, DesktopLevelsFilter } from './LevelsFilter'
import { CollapsibleCompetitionTypeFilter, DesktopCompetitionTypeFilter } from './CompetitionTypeFilter'

interface Props {
  appliedFilters: DiscoverMatchesFiltersState
  dispatch: React.Dispatch<Action>
  venues: Array<MatchVenue> | null
  sports?: Array<Sport> | null
}

const MatchesFilters = ({ appliedFilters, dispatch, venues, sports }: Props) => {
  useEffect(() => {
    setSearchParams(appliedFilters)
  }, [appliedFilters])

  return (
    <Box
      sx={{
        zIndex: 5,
        py: { xs: 3, lg: 2 },
        px: { xs: 3, lg: 8 },
        backgroundColor: { xs: 'white', lg: 'eggshell' },
        height: { xs: '63px', lg: '55px' },
        boxShadow: boxShadows.default,
        whiteSpace: 'nowrap',
        overflow: 'auto',
      }}
    >
      <MobileMatchesFilters appliedFilters={appliedFilters} dispatch={dispatch} venues={venues} sports={sports} />
      <DesktopMatchesFilters appliedFilters={appliedFilters} dispatch={dispatch} venues={venues} sports={sports} />
    </Box>
  )
}

const MobileMatchesFilters = ({ appliedFilters, venues, sports, dispatch }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasCollapsedFiltersSelected =
    Boolean(appliedFilters.sports?.length) ||
    Boolean(appliedFilters.venues?.length) ||
    Boolean(appliedFilters.levels?.length) ||
    Boolean(appliedFilters.competitionTypes?.length)

  const toggleDialog = () => {
    setIsOpen(!isOpen)
  }

  const handleClick = () => {
    dispatch({ type: 'RESET_DISCOVER_MATCHES_FILTERS' })
  }

  return (
    <Stack
      direction="row"
      gap={4}
      justifyContent="space-between"
      alignItems="center"
      display={{ xs: 'flex', lg: 'none' }}
    >
      {sports && <DrawerSportsFilter sports={sports} dispatch={dispatch} selectedSports={appliedFilters.sports} />}
      {venues && !sports && (
        <DrawerVenuesFilter venues={venues} dispatch={dispatch} selectedVenues={appliedFilters.venues} />
      )}
      <DateFilter dispatch={dispatch} selectedDate={appliedFilters.date} isMobile />
      <MobileMoreFilters isActive={hasCollapsedFiltersSelected} openFilters={toggleDialog} />
      <Dialog open={isOpen} onClose={toggleDialog} fullScreen keepMounted>
        <DialogTitle sx={{ border: '0.5px solid lightgray', boxShadow: '0px 1px 2px #DDDDDD', px: 3, py: 4 }}>
          <Stack direction="row" alignItems="center" spacing={6}>
            <Text
              variant="badge"
              sx={{
                color: 'darkgray',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                cursor: 'pointer',
                flex: 1,
              }}
              onClick={toggleDialog}
            >
              <Localized id="mobile-filters-cancel" />
            </Text>
            <Text variant="large" sx={{ fontWeight: 'bold', textAlign: 'center', flex: 1 }}>
              <Localized id="mobile-filters-heading" />
            </Text>
            <Text
              variant="badge"
              sx={{ color: 'primary.main', textAlign: 'right', cursor: 'pointer', textTransform: 'uppercase', flex: 1 }}
              onClick={handleClick}
            >
              <Localized id="mobile-filters-reset" />
            </Text>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ p: 0, backgroundColor: 'eggshell' }}>
          <Stack spacing="1px">
            {venues && (
              <CollapsibleVenuesFilter venues={venues} dispatch={dispatch} selectedVenues={appliedFilters.venues} />
            )}
            {sports && (
              <CollapsibleSportsFilter sports={sports} dispatch={dispatch} selectedSports={appliedFilters.sports} />
            )}
            <CollapsibleLevelsFilter dispatch={dispatch} selectedLevels={appliedFilters.levels} />
            <CollapsibleCompetitionTypeFilter
              dispatch={dispatch}
              selectedCompetitionTypes={appliedFilters.competitionTypes}
            />
          </Stack>
        </DialogContent>
        <Box
          sx={{
            border: '1px solid lightgray',
            boxSizing: 'border-box',
            boxShadow: '0px -1px 2px rgba(0, 0, 0, 0.12)',
            borderRadius: '16px 16px 0px 0px',
            width: '100%',
            px: 2,
            py: 3,
            backgroundColor: 'white',
          }}
        >
          <Button variant="primary" onClick={toggleDialog} fullWidth sx={{ px: 5, py: 3 }}>
            <Text variant="large" sx={{ fontWeight: 'bold', color: 'white' }}>
              <Localized id="mobile-filters-apply-filters" />
            </Text>
          </Button>
        </Box>
      </Dialog>
    </Stack>
  )
}

const DesktopMatchesFilters = ({ appliedFilters, venues, sports, dispatch }: Props) => {
  return (
    <Stack direction="row" gap={4} display={{ xs: 'none', lg: 'flex' }}>
      {sports && <DesktopSportsFilter sports={sports} dispatch={dispatch} selectedSports={appliedFilters.sports} />}
      {venues && <DesktopVenuesFilter venues={venues} dispatch={dispatch} selectedVenues={appliedFilters.venues} />}
      <DateFilter dispatch={dispatch} selectedDate={appliedFilters.date} />
      <DesktopLevelsFilter dispatch={dispatch} selectedLevels={appliedFilters.levels} />
      <DesktopCompetitionTypeFilter dispatch={dispatch} selectedCompetitionTypes={appliedFilters.competitionTypes} />
      <ResetFilters appliedFilters={appliedFilters} dispatch={dispatch} />
    </Stack>
  )
}

export default MatchesFilters
