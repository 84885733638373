import * as React from 'react'
import { SharingMessage } from '@eversports/klimt-patterns/SharingActions'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import DefaultSharingActions from '@eversports/klimt-patterns/SharingActions/SharingActions'

import { Localized } from '../../../../localization/react'
import InviteFriends from '../../../../components/InviteFriends'

interface Props {
  sharingMessage: SharingMessage
  matchId: string
}

const SharingActionsContent = ({ sharingMessage, matchId }: Props) => {
  return (
    <Stack gap={4}>
      <InviteFriends type="match" matchId={matchId} />
      <Heading is="h4" variant="small">
        <Localized id="share-it" />
      </Heading>
      <DefaultSharingActions sharingMessage={sharingMessage} />
    </Stack>
  )
}

export default SharingActionsContent
