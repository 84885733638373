import Stack from '@eversports/klimt-primitives/Stack'
import * as React from 'react'

import { EventBookableItem, EventSession } from '../ActivityDetails.types'
import VenueInformation from '../VenueInformation/VenueInformation'
import checkHasOneSession from '../helpers/check-has-one-session'

import InfoSection from './InfoSection'
import Products from './Products'
import CancellationConditions from './InfoSection/CancellationConditions'
import Schedule from './Schedule/Schedule'
import Trainers from './Trainers/Trainers'

interface Props {
  activity: EventBookableItem
  eventSessions: Array<EventSession>
}

const Overview = ({ activity, eventSessions }: Props) => {
  const { eventType, trainers, cancellationCondition, registrationType } = activity

  const hasMultipleSessions = !checkHasOneSession(eventSessions)

  return (
    <Stack gap={{ xs: 3, md: 4 }} flex={{ md: 2 }} maxWidth={{ md: 700 }}>
      <Stack gap={{ xs: 1, md: 2 }}>
        <InfoSection activity={activity} eventSessions={eventSessions} />
        <Trainers trainers={trainers} eventType={eventType} />
        <Products purchaseOptions={activity.purchaseOptions} registrationType={registrationType} />
        {hasMultipleSessions && <Schedule eventSessions={eventSessions} />}
        <VenueInformation venue={activity.venue} />
      </Stack>
      <CancellationConditions cancellationCondition={cancellationCondition} />
    </Stack>
  )
}

export default Overview
