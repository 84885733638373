import * as React from 'react'
import Menu from '@eversports/klimt-primitives/Menu'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

interface Props {
  anchorEl: HTMLElement | null
  isOpen: boolean
  onClose: () => void
}

const PickerMenu = ({ anchorEl, isOpen, onClose, children }: React.PropsWithChildren<Props>) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiMenu-paper': {
          border: '0.5px solid',
          borderColor: 'lightgray',
          boxShadow: boxShadows.default,
          borderRadius: '0px 8px 8px 8px',
          px: 10,
          py: 4,
        },
        '& .MuiMenu-list': {
          p: 0,
        },
      }}
    >
      {children}
    </Menu>
  )
}

export default PickerMenu
