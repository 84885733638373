import * as React from 'react'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import Button from '@eversports/klimt-primitives/Button'
import Processing from '@eversports/klimt-primitives/Processing'
import Text from '@eversports/klimt-primitives/Text'

import { AddFriendPublicUserDocument, useAddFriendRequestFriendshipMutation } from '../../../../../../graphql'
import { Localized } from '../../../../../../localization/react'
import { PublicUser } from '../../../../PublicUserProfile.types'

interface Props {
  friend: PublicUser
}

const AddFriend = ({ friend }: Props) => {
  const { amplitude } = useAmplitude()
  const [addToFriends, { loading }] = useAddFriendRequestFriendshipMutation({
    variables: { userId: friend.id },
    refetchQueries: [
      {
        query: AddFriendPublicUserDocument,
        variables: { handle: friend.handle },
      },
    ],
  })

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Add Friend Button')
    }
    void addToFriends()
  }

  return (
    <Processing isLoading={loading}>
      <Button
        variant="tertiary"
        sx={{ textTransform: 'none', paddingY: { xs: 3, md: 2 }, paddingX: 6, width: 'fit-content' }}
        onClick={handleClick}
      >
        <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>
          <Localized id="add-friend" />
        </Text>
      </Button>
    </Processing>
  )
}

export default AddFriend
