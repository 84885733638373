import * as React from 'react'
import { useParams } from 'react-router-dom'
import Drawer from '@eversports/klimt-primitives/Drawer'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import { useAllUpcomingBookingsPublicUserQuery } from '../../../../../graphql'
import { Localized } from '../../../../../localization/react'
import FullscreenDialog from '../../../../../components/FullscreenDialog'

import AllUpcomingParticipationsContent from './AllUpcomingParticipationsContent'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const AllUpcomingParticipations = ({ isOpen, onClose }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { userHandle } = useParams<{ userHandle: string }>()
  const { data, loading } = useAllUpcomingBookingsPublicUserQuery({ variables: { handle: userHandle } })

  if (!data || loading || !data.publicUser.upcomingParticipationsV2?.edges) {
    return null
  }

  const participationsToShow = data?.publicUser.upcomingParticipationsV2?.edges.map((edge) => edge.node)

  return isMobile ? (
    <FullscreenDialog title={<Localized id="user-profile-upcoming-bookings-title" />} isOpen={isOpen} onClose={onClose}>
      <AllUpcomingParticipationsContent upcomingParticipations={participationsToShow} isMobile />
    </FullscreenDialog>
  ) : (
    <Drawer anchor="right" open={isOpen} onClose={onClose} sx={{ padding: 6 }}>
      <AllUpcomingParticipationsContent upcomingParticipations={participationsToShow} />
    </Drawer>
  )
}

export default AllUpcomingParticipations
