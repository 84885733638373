import { useEffect, useState } from 'react'

import { BaseNotification, NotificationConnection, NotificationEdge } from '../Notifications.types'
import { NUMBER_OF_NOTIFICATIONS_TO_LOAD } from '../Notifications.constants'
import { useUseNotificationsMeLazyQuery } from '../../../graphql'

interface Result {
  notifications: Array<BaseNotification>
  loading: boolean
  fetchMore: () => void
  hasMoreResults: boolean
}

interface Args {
  initiallyFetchedNotifications: NotificationConnection | null
}

const useNotifications = ({ initiallyFetchedNotifications }: Args): Result => {
  const initialFetchedNotificationsEdges = initiallyFetchedNotifications?.edges || []
  const [notifications, setNotifications] = useState<Array<NotificationEdge>>(initialFetchedNotificationsEdges)
  const [loadMoreNotifications, { data, loading }] = useUseNotificationsMeLazyQuery({ fetchPolicy: 'no-cache' })

  useEffect(() => {
    if (!data) return
    const fetchedNotifications = data.me?.notifications.edges || []
    const newNotifications = notifications ? [...notifications, ...fetchedNotifications] : fetchedNotifications
    setNotifications(newNotifications)
  }, [data])

  const fetchMore = () => {
    const cursor = notifications[notifications.length - 1].cursor
    void loadMoreNotifications({
      variables: {
        first: NUMBER_OF_NOTIFICATIONS_TO_LOAD,
        after: cursor,
      },
    })
  }

  const hasMoreResults = Boolean(
    data?.me?.notifications.pageInfo.hasNextPage ?? initiallyFetchedNotifications?.pageInfo.hasNextPage,
  )
  const notificationsWithoutCursor = notifications
    ? notifications.map((edge) => edge.node)
    : initialFetchedNotificationsEdges.map((edge) => edge.node)

  return {
    loading,
    notifications: notificationsWithoutCursor,
    fetchMore,
    hasMoreResults,
  }
}

export default useNotifications
