import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Icon from '@eversports/klimt-primitives/Icon'
import Text from '@eversports/klimt-primitives/Text'
import Dialog from '@eversports/klimt-primitives/Dialog'
import ChevronLeftIcon from '@eversports/design-tokens/assets/icons/chevron-left.svg'

interface Props {
  title: React.ReactElement
  isOpen: boolean
  onClose: () => void
}

const FullscreenDialog = ({ children, title, isOpen, onClose }: React.PropsWithChildren<Props>) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          p={4}
          sx={{
            position: 'sticky',
            backgroundColor: 'white',
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
            top: 0,
            zIndex: 5,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                padding: 1,
                border: '1px solid',
                borderColor: 'lightgray',
                boxShadow: boxShadows.default,
                width: 'fit-content',
                borderRadius: 10,
              }}
              onClick={onClose}
            >
              <Icon src={ChevronLeftIcon} size="small" color="midnight" />
            </Box>
          </Box>
          <Text sx={{ color: 'midnight', fontWeight: 'bold', flex: 1, textAlign: 'center' }}>{title}</Text>
          <Text
            variant="xsmall"
            sx={{
              color: 'primary.main',
              textDecoration: 'underline',
              cursor: 'pointer',
              textAlign: 'right',
              flex: 1,
            }}
          ></Text>
        </Stack>
        {children}
      </Stack>
    </Dialog>
  )
}

export default FullscreenDialog
