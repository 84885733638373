import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import TextField from '@eversports/klimt-primitives/TextField'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized, useIntl } from '../../../localization/react'

interface Props {
  comment: string
  onChange: (value: string) => void
  onButtonClick: () => void
  onEditClick: () => void
}

const NPSBannerComment = ({ comment, onChange, onButtonClick, onEditClick }: Props) => {
  const intl = useIntl()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }
  return (
    <>
      <Stack gap={2} width="100%">
        <Text
          sx={{
            color: 'primary.main',
            textDecoration: 'underline',
            fontWeight: 'bold',
            cursor: 'pointer',
            alignSelf: 'end',
          }}
          onClick={onEditClick}
        >
          <Localized id="nps-comment-edit-score-button" />
        </Text>
        <Text variant="large" sx={{ fontWeight: 'bold' }}>
          <Localized id="nps-comment-title" />
        </Text>
        <TextField
          rows={2}
          value={comment}
          onChange={handleChange}
          placeholder={intl.npsCommentPlaceholder()}
          multiline
          fullWidth
        />
      </Stack>
      <Button variant="primary" sx={{ width: '100%', maxWidth: '345px', padding: 3 }} onClick={onButtonClick}>
        <Text sx={{ color: 'white', fontWeight: 'bold', textTransform: 'capitalize' }}>
          <Localized id="nps-comment-button" />
        </Text>
      </Button>
    </>
  )
}

export default NPSBannerComment
