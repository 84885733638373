import { useState } from 'react'

import { useSearchCitySearchLazyQuery } from '../../../../graphql'
import getNavigatorGeoLocation from '../../helpers/get-navigator-geo-location'
import { CitySearchResult } from '../../Search.types'

function useGeoLocation() {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState<boolean>()

  const [getCitySearchLazy] = useSearchCitySearchLazyQuery()

  const handleGetGeoLocation = async (): Promise<CitySearchResult | undefined> => {
    setHasError(false)
    try {
      setIsLoading(true)

      const navigatorCoordinates = await getNavigatorGeoLocation()

      if (navigatorCoordinates) {
        const { data } = await getCitySearchLazy({
          variables: { coordinate: { ...navigatorCoordinates }, searchTerm: '' },
        })
        if (data?.citySearch && data.citySearch[0]) {
          const queryResult: CitySearchResult = data.citySearch[0]
          return {
            ...queryResult,
            coordinate: { ...navigatorCoordinates },
            isCurrentLocation: true,
          }
        }
      } else {
        setHasError(true)
      }
    } catch (e) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
    return
  }

  return {
    isLoading,
    hasError,
    handleGetGeoLocation,
  }
}

export default useGeoLocation
