import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import HorizontalLogo from '@eversports/design-tokens/assets/logo/horizontal.svg'
import IconLogo from '@eversports/design-tokens/assets/logo/icon.svg'
import Svg from '@eversports/klimt-primitives/Svg'
import FilledHorizontalLogo from '@eversports/design-tokens/assets/logo/filled-horizontal.svg'
import WhiteHorizontalLogo from '@eversports/design-tokens/assets/logo/filled-inverted-horizontal.svg'
import Box from '@eversports/klimt-primitives/Box'

import { useIntl } from '../../localization/react'
import Routes from '../../App.routes'

import { ColorContrast } from './Header.types'

interface Props {
  to?: string
  external?: boolean
  colorContrast?: ColorContrast
}

const EversportsLogoLink = ({ to = Routes.HOME, colorContrast = ColorContrast.NORMAL, external = false }: Props) => {
  const intl = useIntl()
  const ariaLabel = (to as Routes) === Routes.HOME ? intl.homeLabel() : undefined

  const logoColor = colorContrast === ColorContrast.NORMAL ? 'darkgray' : 'white'
  const loadingLogo = colorContrast === ColorContrast.NORMAL ? FilledHorizontalLogo : WhiteHorizontalLogo

  return (
    <Box flexShrink={0} alignSelf="center">
      <Link to={to} ariaLabel={ariaLabel} external={external}>
        <Box display={{ xs: 'none', lg: 'flex' }}>
          <Svg
            src={HorizontalLogo}
            color={logoColor}
            height={32}
            width={157}
            loadingComponent={<img src={loadingLogo} height={32} width={157} />}
          />
        </Box>
        <Box display={{ xs: 'flex', lg: 'none' }}>
          <Svg src={IconLogo} height={32} width={32} loadingComponent={<img src={IconLogo} height={32} width={32} />} />
        </Box>
      </Link>
    </Box>
  )
}

export default EversportsLogoLink
