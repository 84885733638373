import * as React from 'react'
import { useState } from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box/Box'

import { Localized } from '../../../../localization/react'
import ContentWrapper from '../../components/ContentWrapper'
import { Trainer } from '../../ActivityDetails.types'
import getTrainerTranslationKey from '../../helpers/get-trainer-translation-key'
import { EventType } from '../../../../App.types'
import Pagination from '../../components/Pagination'

import TrainerPopup from './TrainerPopup'
import TrainerCard from './TrainerCard'
import { TRAINER_CARD_WIDTH } from './Trainer.constants'

const CARD_GAP = 16
const ITERATE_BY = 2

interface Props {
  trainers: Array<Trainer>
  eventType: EventType
}

const MultipleTrainers = ({ trainers, eventType }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeTrainerId, setActiveTrainerId] = useState<string>()
  const [activeIndex, setActiveIndex] = useState(0)

  const trainerHeadingKey = getTrainerTranslationKey(eventType)

  const trainerCount = trainers.length
  const activeTrainer = trainers.find((trainer) => trainer.id === activeTrainerId)

  const togglePopup = () => {
    setIsModalOpen(!isModalOpen)
  }

  const showPagination = trainerCount > ITERATE_BY

  const handleBack = () => {
    setActiveIndex((prevIndex) => {
      if (prevIndex - ITERATE_BY < 0) {
        return trainerCount - ITERATE_BY
      }
      return prevIndex - ITERATE_BY
    })
  }

  const handleForward = () => {
    setActiveIndex((prevIndex) => {
      if (prevIndex + ITERATE_BY > trainerCount - ITERATE_BY) {
        return 0
      }
      return prevIndex + ITERATE_BY
    })
  }

  const handleCardClick = (trainerId: string, hasMoreInfo: boolean) => () => {
    if (!hasMoreInfo) return
    setActiveTrainerId(trainerId)
    togglePopup()
  }

  return (
    <>
      <ContentWrapper>
        <Heading is="h2" color="midnight">
          <Localized id={trainerHeadingKey} params={{ trainerCount }} />
        </Heading>
        <Box sx={{ overflow: { xs: 'scroll', md: 'hidden' } }}>
          <Stack
            direction="row"
            gap={`${CARD_GAP}px`}
            sx={{
              width: '100%',
              transform: {
                xs: 'none',
                md: `translateX(${activeIndex * -1 * (TRAINER_CARD_WIDTH + CARD_GAP)}px)`,
              },
              transition: '0.7s ease',
            }}
          >
            {trainers.map((trainer) => (
              <TrainerCard key={trainer.id} trainer={trainer} handleCardClick={handleCardClick} />
            ))}
          </Stack>
        </Box>
        {showPagination && (
          <Box display={{ xs: 'none', md: 'block' }}>
            <Pagination isFirstPage={activeIndex === 0} handleBack={handleBack} handleForward={handleForward} />
          </Box>
        )}
      </ContentWrapper>
      <TrainerPopup
        trainer={activeTrainer}
        isOpen={isModalOpen}
        togglePopup={togglePopup}
        trainerHeadingKey={trainerHeadingKey}
      />
    </>
  )
}

export default MultipleTrainers
