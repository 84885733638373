import * as React from 'react'
import { useEffect } from 'react'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import Stack from '@eversports/klimt-primitives/Stack'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import FilterCheckbox, { CheckboxVariant } from '../../../../../../components/FilterCheckbox'
import { Action } from '../../helpers/discover-sport-matches-reducer'
import { MatchLevel } from '../../../../../../App.types'
import { AMPLITUDE_DISCOVER_SPORT_CITY_FILTER_EVENT } from '../../../../Discover.constants'
import { DiscoverFilterTypes } from '../../../../Filters/Filters.types'
import { Localized } from '../../../../../../localization/react'
import { matchLevelTranslation } from '../../../../../../App.constants'

interface Props {
  dispatch: React.Dispatch<Action>
  variant?: CheckboxVariant
  selectedLevels?: Array<MatchLevel>
}

interface InitialValues {
  levels?: Array<MatchLevel>
}

const LevelsFilterForm = ({ dispatch, selectedLevels, variant = 'default' }: Props) => {
  const { amplitude } = useAmplitude()
  const methods = useForm<InitialValues>({ defaultValues: { levels: [] } })

  const newlySelectedLevels = methods.watch('levels')
  const { isDirty } = methods.formState

  useEffect(() => {
    methods.reset({ levels: selectedLevels ?? [] })
  }, [selectedLevels])

  useEffect(() => {
    if (!isDirty) return
    dispatch({ type: 'UPDATE_DISCOVER_MATCHES_LEVELS_FILTER', payload: newlySelectedLevels })
    if (amplitude) {
      amplitude.logEvent(AMPLITUDE_DISCOVER_SPORT_CITY_FILTER_EVENT, { type: DiscoverFilterTypes.level, tab: 'match' })
    }
  }, [newlySelectedLevels])

  return (
    <Form methods={methods}>
      <Stack spacing={2}>
        {Object.keys(MatchLevel).map((level) => (
          <FilterCheckbox
            key={level}
            value={level}
            label={<Localized id={matchLevelTranslation[level as MatchLevel]} />}
            variant={variant}
            name="levels"
          />
        ))}
      </Stack>
    </Form>
  )
}

export default LevelsFilterForm
