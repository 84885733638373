import * as React from 'react'

interface Props {
  index: number
  value: number
}

const TabPanel = ({ children, value, index }: React.PropsWithChildren<Props>) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
      {value === index ? children : null}
    </div>
  )
}

export default TabPanel
