import { EventType, Image } from '../../App.types'

export interface PublicUser {
  id: string
  firstName: string
  lastName: string
  handle: string
  profilePicture: Image | null
  bio?: string | null
  upcomingParticipationsV2: PublicParticipationConnection | null
  favouriteVenues: FavoriteVenueConnection | null
  friendsCount: number | null
  isYourFriend: boolean | null
  pendingFriendRequest: PendingFriendRequest | null
}

export interface FriendConnection {
  edges: Array<FriendEdge>
  pageInfo: PageInfo
}

export interface FriendEdge {
  cursor: string
  node: Friend
}

export interface Friend {
  id: string
  publicUser: PublicUserFriend
}

export interface PublicUserFriend {
  id: string
  firstName: string
  lastName: string
  handle: string
  profilePicture: Image | null
  isYourFriend: boolean
  pendingFriendRequest: PendingFriendRequest | null
}

export interface PendingFriendRequest {
  id: string
  from: { id: string }
  to: { id: string }
}

export interface PublicParticipationConnection {
  edges: Array<PublicParticipationEdge>
  pageInfo: PageInfo
}

interface PageInfo {
  hasNextPage: boolean
  hasPreviousPage: boolean
}

interface PublicParticipationEdge {
  cursor: string
  node: PublicParticipation
}

export type PublicParticipation = PublicCourtBooking | PublicEventSessionParticipation | BallsportMatch

interface PublicCourtBooking {
  id: string
  timeRange: TimeRange
  bookableItem: CourtBookableItem
  __typename: 'PublicCourtBooking'
}

interface PublicEventSessionParticipation {
  id: string
  timeRange: TimeRange
  bookableItem: EventBookableItem
  __typename: 'PublicEventSessionParticipation'
}

interface BallsportMatch {
  id: string
  start: string
  end: string
  sport: Sport
  venue: Venue
  __typename: 'BallsportMatch'
}

interface TimeRange {
  start: string
  end: string
}

interface CourtBookableItem {
  sport: Sport
  venue: Venue
}

export interface EventBookableItem {
  shortId: string
  eventType: EventType
  activityName: string
  sport: Sport
  venue: Venue
}

export enum EventPagesPrefix {
  CAMP = '/camp/',
  CLASS = '/class/',
  COURSE = '/course/',
  EDUCATION = '/education/',
  EVENT = '/event/',
  OPENTRAINING = '/openTraining/',
  RETREAT = '/retreat/',
  TRAINING = '/training/',
  WORKSHOP = '/workshop/',
}

interface Sport {
  name: string
}

export interface Venue {
  name: string
  location: Location
  logo: Image | null
}

interface Location {
  zip: string
  city: string
}

export interface FavoriteVenueConnection {
  edges: Array<FavoriteVenueEdge>
  pageInfo: PageInfo
}

interface FavoriteVenueEdge {
  cursor: string
  node: { venue: FavoriteVenue }
}

export interface FavoriteVenue {
  id: string
  name: string
  slug: string
  logo: Image | null
}

export const EventTypeToVenueProfileTab = {
  [EventType.CAMP]: EventPagesPrefix.CAMP,
  [EventType.CLASS]: EventPagesPrefix.CLASS,
  [EventType.COURSE]: EventPagesPrefix.COURSE,
  [EventType.EDUCATION]: EventPagesPrefix.EDUCATION,
  [EventType.EVENT]: EventPagesPrefix.EVENT,
  [EventType.OPENTRAINING]: EventPagesPrefix.OPENTRAINING,
  [EventType.RETREAT]: EventPagesPrefix.RETREAT,
  [EventType.TRAINING]: EventPagesPrefix.TRAINING,
  [EventType.WORKSHOP]: EventPagesPrefix.WORKSHOP,
}

export enum UserType {
  PROFILE_OWNER = 'OWNER',
  FRIENDSHIP_REQUEST_PENDING = 'FRIENDSHIP_REQUEST_PENDING',
  REQUESTED_FRIENDSHIP = 'REQUESTED_FRIENDSHIP',
  FRIENDS = 'FRIENDS',
  EVERSPORTS_USER = 'EVERSPORTS_USER',
  LOGGED_OUT_USER = 'LOGGED_OUT_USER',
}
