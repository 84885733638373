import * as React from 'react'
import {
  loadLanguage as loadKlimtLanguage,
  Provider as KlimtLocalizationProvider,
} from '@eversports/klimt-localization/localization/react'
import {
  loadLanguage as loadKlimtPatternsLanguage,
  Provider as KlimtPatternsLocalizationProvider,
} from '@eversports/klimt-patterns/localization/react'
import {
  loadLanguage as loadUserComponentsLanguage,
  Provider as UserComponentsLocalizationProvider,
} from '@eversports/user-components/localization/react'

import { loadLanguage, Locales, Provider } from '../localization/react'

async function getLocalizationProvider(locale: Locales): Promise<React.FC<React.PropsWithChildren<unknown>>> {
  const [intl, klimtIntl, klimtPatternsIntl, userComponentsIntl] = await Promise.all([
    loadLanguage(locale),
    loadKlimtLanguage(locale),
    loadKlimtPatternsLanguage(locale),
    loadUserComponentsLanguage(locale),
  ])

  return (props) => (
    <Provider value={intl}>
      <KlimtLocalizationProvider value={klimtIntl}>
        <KlimtPatternsLocalizationProvider value={klimtPatternsIntl}>
          <UserComponentsLocalizationProvider value={userComponentsIntl}>
            {props.children}
          </UserComponentsLocalizationProvider>
        </KlimtPatternsLocalizationProvider>
      </KlimtLocalizationProvider>
    </Provider>
  )
}

export default getLocalizationProvider
