import { default as React } from 'react'
import { BoxProps, default as MuiBox } from '@mui/material/Box'

export type { BoxProps }

const Box = <D extends React.ElementType, P = Record<string, never>>(
  props: BoxProps<D, P> & { forwardedRef?: React.Ref<HTMLElement> },
) => {
  const { forwardedRef, ...rest } = props
  return <MuiBox ref={forwardedRef} {...rest} />
}

export default Box
