import * as React from 'react'

import FilterMenu from '../../../../../../components/FilterMenu'
import { Localized } from '../../../../../../localization/react'
import { Action } from '../../helpers/discover-sport-matches-reducer'
import { MatchVenue } from '../../../../../../App.types'

import VenuesFilterForm from './VenuesFilterForm'

interface Props {
  dispatch: React.Dispatch<Action>
  venues: Array<MatchVenue>
  selectedVenues?: Array<string>
}

const DesktopVenuesFilter = ({ dispatch, venues, selectedVenues }: Props) => {
  const selectedVenuesNames = venues.filter((venue) => selectedVenues?.includes(venue.id)).map((venue) => venue.name)

  return (
    <FilterMenu
      header={<Localized id="filters-matches-venues-heading" />}
      activeFilters={selectedVenuesNames}
      minWidth="162px"
    >
      <VenuesFilterForm dispatch={dispatch} venues={venues} selectedVenues={selectedVenues} />
    </FilterMenu>
  )
}

export default DesktopVenuesFilter
