import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Link from '@eversports/klimt-primitives/Link'

import { Localized } from '../../../../../localization/react'
import useLastCitySearch from '../../../../../components/Search/hooks/useLastCitySearch'
import useLoggedInUserType from '../../../hooks/use-logged-in-user-type'
import { UserType } from '../../../PublicUserProfile.types'

const NoUpcomingParticipations = () => {
  const { userType } = useLoggedInUserType()
  const { location } = useLastCitySearch()

  return (
    <Box padding={6} sx={{ border: '1px dashed', borderColor: 'mediumgray', textAlign: 'center' }}>
      {userType === UserType.PROFILE_OWNER ? (
        <Stack gap={2}>
          <Text variant="small">
            <Localized id="user-profile-no-upcoming-bookings" />
          </Text>
          {location?.slug && (
            <Link to={`/b/${location.slug}`}>
              <Text sx={{ color: 'primary.main', fontWeight: 'bold', textDecoration: 'underline' }}>
                <Localized id="user-profile-no-upcoming-bookings-link" />
              </Text>
            </Link>
          )}
        </Stack>
      ) : (
        <Text variant="small">
          <Localized id="user-profile-friend-no-upcoming-bookings" />
        </Text>
      )}
    </Box>
  )
}

export default NoUpcomingParticipations
