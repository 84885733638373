import { EventType } from '../../App.types'

export const eventTypeToUrlMapping = {
  [EventType.CAMP]: 'camp',
  [EventType.CLASS]: 'class',
  [EventType.COURSE]: 'course',
  [EventType.EDUCATION]: 'education',
  [EventType.EVENT]: 'event',
  [EventType.OPENTRAINING]: 'openTraining',
  [EventType.RETREAT]: 'retreat',
  [EventType.TRAINING]: 'training',
  [EventType.WORKSHOP]: 'workshop',
}

export const NUMBER_OF_NOTIFICATIONS_TO_LOAD = 10
export const PROMOTION_NOTIFICATION_CONTENT_TYPE = 'promotion-notification-center'
