import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import MonetaryValue from '@eversports/klimt-localization/MonetaryValue'

import { MonetaryValue as MonetaryValueType } from '../../../App.types'

interface Props {
  price: MonetaryValueType
  description: string
}

const TrialCardDetails = ({ price, description }: Props) => (
  <>
    <Text variant="badge" sx={{ fontWeight: 'bold', color: 'primary.main', textTransform: 'uppercase' }}>
      <MonetaryValue value={price.value} currencyCode={price.currency} />
    </Text>
    <Text
      variant="small"
      sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '300px', mr: 3 }}
    >
      {description}
    </Text>
  </>
)

export default TrialCardDetails
