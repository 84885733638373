import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

const InvoiceDate = ({ invoiceDate: invoiceDateString }: { invoiceDate: string | null }) => {
  const { language } = useSettingsContext()

  if (!invoiceDateString) return null

  const invoiceDate = new Date(invoiceDateString)
  const formattedInvoiceDate = invoiceDate.toLocaleDateString(language, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })

  return (
    <Text variant="large" sx={{ fontWeight: 'bold', minWidth: 86 }}>
      {formattedInvoiceDate}
    </Text>
  )
}

export default InvoiceDate
