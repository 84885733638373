import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../../../../localization/react'
import { competitionTypeDescriptionTranslation, competitionTypeTranslation } from '../MatchSettings.constants'
import { MatchCompetitionType } from '../../../Match.types'

interface Props {
  competitionType: MatchCompetitionType
}

const CompetitionTypeContent = ({ competitionType }: Props) => {
  return (
    <Stack gap={0.5} p={3} width="100%">
      <Text variant="badge">
        <Localized id={competitionTypeTranslation[competitionType]} />
      </Text>
      <Text variant="xsmall">
        <Localized id={competitionTypeDescriptionTranslation[competitionType]} />
      </Text>
    </Stack>
  )
}

export default CompetitionTypeContent
