import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../localization/react'

interface Props {
  handleClick: () => void
}

const ShowMore = ({ handleClick }: Props) => {
  return (
    <Box onClick={handleClick} sx={{ width: '100%', cursor: 'pointer' }}>
      <Text variant="label" color="midnight" sx={{ textAlign: 'center' }}>
        <Localized id="activity-detail-reviews-show-more" />
      </Text>
    </Box>
  )
}

export default ShowMore
