import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import SeoLink from '@eversports/klimt-primitives/SeoLink'

import { Localized } from '../../../../localization/react'
import { DiscoverPrefix } from '../../../../App.types'

import { CityData } from './NotFound.types'

interface Props {
  cityData: CityData
}

const OtherSports = ({ cityData }: Props) => (
  <Stack gap={3} width={{ md: '50vw' }}>
    <Heading is="h2" variant="small" color="midnight">
      <Localized id="discover-no-results-popular-sports-label" params={{ city: cityData.name }} />
    </Heading>
    <Stack direction="row" gap={3} flexWrap="wrap">
      {cityData.popularSports.map((sport) => {
        const to = new URL(
          `${DiscoverPrefix.APP_SPORT_CITY}/${sport.slug}/${cityData.slug}`,
          `https://${cityData.canonicalHost}`,
        ).toString()
        return (
          <SeoLink key={sport.id} type="hidden" encodedTo={btoa(to)} external>
            <Button
              variant="tertiary"
              sx={{ px: 3, py: 2, border: '1px solid', borderColor: 'lightgray', textTransform: 'none' }}
            >
              <Text>{sport.name}</Text>
            </Button>
          </SeoLink>
        )
      })}
    </Stack>
  </Stack>
)

export default OtherSports
