export enum FriendsTabsIndex {
  FIND_FRIENDS = 0,
  FRIENDS = 1,
  FRIEND_REQUESTS = 2,
}

export enum FriendsTabsIndexWithActivites {
  FRIENDS_ACTIVITIES = 0,
  FIND_FRIENDS = 1,
  FRIENDS = 2,
  FRIEND_REQUESTS = 3,
}
