import * as React from 'react'
import { TableRow, TableCell } from '@eversports/klimt-primitives/Table'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import Text from '@eversports/klimt-primitives/Text/Text'

import { formatTime } from '../../../../helpers/format-time'
import { Localized } from '../../../../localization/react'
import { formatShortDate } from '../../helpers/format-short-date'
import { ScheduleSession } from '../../ActivityDetails.types'
import { formatLocation } from '../../helpers/format-location'

import { isEventInFuture } from './helpers/is-event-in-future'

interface Props {
  session: ScheduleSession
}

const ScheduleTableRow = ({ session }: Props) => {
  const { language } = useSettingsContext()

  const date = formatShortDate({ language, dateString: session.start })
  const startTime = formatTime(language, session.start)
  const endTime = formatTime(language, session.end)
  const location = session.location ? formatLocation(session.location) : ''

  const isAvailable = isEventInFuture(session.start)
  const contentColor = isAvailable ? 'midnight' : 'gray'

  return (
    <TableRow
      sx={{
        border: 0,
        '&:nth-of-type(odd)': {
          backgroundColor: 'eggshell',
        },
      }}
    >
      <TableCell align="center" sx={{ border: 0 }}>
        <Text color={contentColor} sx={{ textDecoration: isAvailable ? 'none' : 'line-through' }}>
          {date}
        </Text>
      </TableCell>
      <TableCell align="center" sx={{ border: 0 }}>
        <Text color={contentColor}>
          {startTime} - {endTime}
        </Text>
      </TableCell>
      <TableCell align="center" sx={{ border: 0 }}>
        <Text color={contentColor}>
          {session.hasOnlineStream ? <Localized id="activity-detail-online-label" /> : location}
        </Text>
      </TableCell>
      <TableCell align="center" sx={{ border: 0 }}>
        {session?.trainer && <Text color={contentColor}>{session.trainer.nickname}</Text>}
      </TableCell>
    </TableRow>
  )
}

export default ScheduleTableRow
