import * as React from 'react'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import Dialog, { DialogActions, DialogContent } from '@eversports/klimt-primitives/Dialog'

import NotificationPromotionContent from './NotificationPromotionContent'
import NotificationPromotionActions from './NotificationPromotionActions'

interface Props {
  isOpen: boolean
  toggleDialog: () => void
  userHandle: string
  friendsCount: number
}

const NotificationPromotionDialog = ({ isOpen, toggleDialog, userHandle, friendsCount }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const hasFriends = friendsCount > 0

  return (
    <Dialog open={isOpen} onClose={toggleDialog} fullScreen={isMobile} maxWidth="xs">
      <DialogContent sx={{ padding: 4, display: 'flex', alignItems: 'center' }}>
        <NotificationPromotionContent hasFriends={hasFriends} />
      </DialogContent>
      <DialogActions sx={{ padding: 4 }}>
        <NotificationPromotionActions userHandle={userHandle} hasFriends={hasFriends} onBackClick={toggleDialog} />
      </DialogActions>
    </Dialog>
  )
}

export default NotificationPromotionDialog
