import { assertNever } from '@eversports/klimt-utilities/assert-never'

import { PublicParticipation, PublicParticipationBlock } from '../FriendsUpcomingParticipations.types'

const getUpcomingParticipationDate = (upcomingParticipation: PublicParticipation): string => {
  switch (upcomingParticipation.__typename) {
    case 'PublicCourtBooking':
      return upcomingParticipation.timeRange.start
    case 'PublicEventSessionParticipation':
      return upcomingParticipation.timeRange.start
    case 'BallsportMatch':
      return upcomingParticipation.start
    default:
      return assertNever(upcomingParticipation)
  }
}

function groupUpcomingParticipationsByDate(
  upcomingParticipations: Array<PublicParticipation>,
): Array<{ date: string; upcomingParticipations: Array<PublicParticipation> }> {
  const groupedUpcomingParticipations: Array<{ date: string; upcomingParticipations: Array<PublicParticipation> }> = []

  for (const upcomingParticipation of upcomingParticipations) {
    // Extract the date (without time) as a string from the timestamp
    const date = new Date(getUpcomingParticipationDate(upcomingParticipation))
    const dateKey = date.toISOString().split('T')[0]

    // Check if the dateKey already exists in the groupedObjects array
    const existingUpcomingParticipations = groupedUpcomingParticipations.find((group) => group.date === dateKey)

    if (existingUpcomingParticipations) {
      // If it exists, push the object into the existing group's objects array
      existingUpcomingParticipations.upcomingParticipations.push(upcomingParticipation)
    } else {
      // If it doesn't exist, create a new group with a "date" property and an "objects" array
      groupedUpcomingParticipations.push({ date: dateKey, upcomingParticipations: [upcomingParticipation] })
    }
  }

  return groupedUpcomingParticipations
}

const getFriendsUpcomingParticipationsBlocks = (
  upcomingParticipations: Array<PublicParticipation>,
): Array<PublicParticipationBlock> => {
  return groupUpcomingParticipationsByDate(upcomingParticipations)
}

export default getFriendsUpcomingParticipationsBlocks
