import { useRouteMatch } from 'react-router-dom'

import Routes from '../../../App.routes'
import { TOP_CONTENT_DISCOVER_CITY, TOP_CONTENT_DISCOVER_SPORT_CITY } from '../Discover.constants'

type DiscoverPageType = Routes.DISCOVER_CITY | Routes.APP_DISCOVER_SPORT_CITY | Routes.SEO_DISCOVER_SPORT_CITY

export const getTopContentElements = () => {
  const match = useRouteMatch()

  switch (match.path as DiscoverPageType) {
    case Routes.DISCOVER_CITY:
      return TOP_CONTENT_DISCOVER_CITY
    case Routes.SEO_DISCOVER_SPORT_CITY:
    case Routes.APP_DISCOVER_SPORT_CITY:
      return TOP_CONTENT_DISCOVER_SPORT_CITY
    default:
      throw Error(`Not an aphrodite discover page: ${match.path}`)
  }
}
