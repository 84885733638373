import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Svg from '@eversports/klimt-primitives/Svg/Svg'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import { formatShortDate } from '../../helpers/format-short-date'
import { ScheduleSession } from '../../ActivityDetails.types'
import { Localized } from '../../../../localization/react'
import Calendar from '../../assets/calendar.svg'
import Clock from '../../assets/clock.svg'
import Location from '../../assets/locationMarker.svg'
import { formatLocation } from '../../helpers/format-location'
import { formatTime } from '../../../../helpers/format-time'

import { isEventInFuture } from './helpers/is-event-in-future'

interface Props {
  session: ScheduleSession
}

const ScheduleCardContent = ({ session }: Props) => {
  const { language } = useSettingsContext()

  const date = formatShortDate({ language, dateString: session.start })
  const startTime = formatTime(language, session.start)
  const endTime = formatTime(language, session.end)
  const location = session.location ? formatLocation(session.location) : ''

  const isAvailable = isEventInFuture(session.start)
  const contentColor = isAvailable ? 'midnight' : 'gray'

  return (
    <Stack
      gap={2}
      paddingY={3}
      flexWrap="wrap"
      sx={{
        borderBottom: '1px solid',
        borderColor: 'mediumgray',
        width: '100%',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Svg src={Calendar} color={contentColor} width={12} height={12} />
        <Text color={contentColor} sx={{ fontWeight: 'bold', textDecoration: isAvailable ? 'none' : 'line-through' }}>
          {date}
        </Text>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <Svg src={Clock} color={contentColor} width={12} height={12} />
        <Text color={contentColor} sx={{ textDecoration: isAvailable ? 'none' : 'line-through' }}>
          {startTime} - {endTime}
        </Text>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <Svg src={Location} color={contentColor} width={12} height={12} />
        <Text color={contentColor}>
          {session.hasOnlineStream ? <Localized id="activity-detail-online-label" /> : location}
        </Text>
      </Stack>
      {session?.trainer && (
        <Stack direction="row" alignItems="center" gap={2}>
          <Text color={isAvailable ? 'carbon' : 'gray'}>
            <Localized id="event-by-label" />
          </Text>
          <Text color={contentColor}>{session.trainer.nickname}</Text>
        </Stack>
      )}
    </Stack>
  )
}

export default ScheduleCardContent
