import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Skeleton from '@eversports/klimt-primitives/Skeleton'

import FriendUpcomingParticipationCard from './FriendUpcomingParticipationCard'

const ParticipationsPlaceholder = () => {
  return (
    <FriendUpcomingParticipationCard>
      <Stack gap={3}>
        <Stack direction="row" gap={2}>
          <Skeleton width="120px" height="80px" sx={{ flexShrink: 0 }} variant="rectangular" />
          <Stack gap={2} width="100%">
            <Skeleton sx={{ flex: 1 }} variant="rectangular" />
            <Skeleton width="150px" variant="rectangular" />
            <Skeleton width="100px" variant="rectangular" />
          </Stack>
        </Stack>
        <Skeleton width="240px" height="50px" variant="rectangular" />
      </Stack>
      <Stack gap={1}>
        <Skeleton width="100px" height="21px" variant="rectangular" />
        <Stack direction="row" alignItems="center">
          <Skeleton width="44px" height="44px" variant="circular" sx={{ marginLeft: 0 }} />
          <Skeleton width="44px" height="44px" variant="circular" sx={{ marginLeft: -2 }} />
          <Skeleton width="44px" height="44px" variant="circular" sx={{ marginLeft: -2 }} />
        </Stack>
      </Stack>
    </FriendUpcomingParticipationCard>
  )
}

const FriendsUpcomingParticipationsPlaceholder = () => {
  return (
    <Stack gap={{ xs: 6, md: 4 }} paddingTop={{ xs: 4, md: 0 }}>
      <Skeleton width="100%" height="27px" variant="rectangular" />
      <Stack gap={{ xs: 1, md: 3 }}>
        <ParticipationsPlaceholder />
        <ParticipationsPlaceholder />
        <ParticipationsPlaceholder />
      </Stack>
      <ParticipationsPlaceholder />
    </Stack>
  )
}

export default FriendsUpcomingParticipationsPlaceholder
