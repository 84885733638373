import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'

interface Props {
  venueName: string
  venueLogoImgSrc?: string
}

export const MOBILE_LOGO_DIMENSION = 104
const DESKTOP_LOGO_DIMENSION = 130
const MAX_NUMBER_OF_INITIALS = 3

const LogoImage = ({ venueName, venueLogoImgSrc }: Props) => {
  return !venueLogoImgSrc ? (
    <InitialsLogo venueName={venueName} />
  ) : (
    <Box
      component="img"
      sx={{
        objectFit: 'contain',
        width: { xs: `${MOBILE_LOGO_DIMENSION}px`, sm: `${DESKTOP_LOGO_DIMENSION}px` },
        height: { xs: `${MOBILE_LOGO_DIMENSION}px`, sm: `${DESKTOP_LOGO_DIMENSION}px` },
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'lightgray',
        flexShrink: 0,
      }}
      src={venueLogoImgSrc}
      alt={venueName}
    />
  )
}

const InitialsLogo = ({ venueName }: { venueName: string }) => {
  const venueNameInitials = venueName
    .split(' ')
    .slice(0, MAX_NUMBER_OF_INITIALS)
    .map((name) => name && name[0].toUpperCase())
    .join('')

  return (
    <Box
      sx={{
        width: { xs: `${MOBILE_LOGO_DIMENSION}px`, sm: `${DESKTOP_LOGO_DIMENSION}px` },
        height: { xs: `${MOBILE_LOGO_DIMENSION}px`, sm: `${DESKTOP_LOGO_DIMENSION}px` },
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'lightgray',
        fontSize: { xs: '32px', sm: '40px' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'eggshell',
      }}
    >
      {venueNameInitials}
    </Box>
  )
}

export default LogoImage
