import * as React from 'react'
import { useState } from 'react'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'

import MobileButtonContainer from '../components/MobileButtonContainer'
import ParticipationButton from '../components/ParticipationButton'
import LoginOrRegisterDisclaimer from '../../../../components/LoginOrRegisterDisclaimer'
import Drawer from '../../../../components/Drawer'

interface Props {
  handleClick: () => void
  isMobile: boolean
  isDisabled: boolean
}

const GuestJoinMatchButton = ({ children, handleClick, isMobile, isDisabled }: React.PropsWithChildren<Props>) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen)
  }

  return isMobile ? (
    <>
      <MobileButtonContainer>
        <ParticipationButton onClick={toggleDialog} isDisabled={isDisabled}>
          {children}
        </ParticipationButton>
      </MobileButtonContainer>
      <Drawer open={isDialogOpen} onClose={toggleDialog}>
        <LoginOrRegisterDisclaimer handleClick={handleClick} descriptionType="joining-a-match" />
      </Drawer>
    </>
  ) : (
    <>
      <ParticipationButton onClick={toggleDialog} isDisabled={isDisabled}>
        {children}
      </ParticipationButton>
      <Dialog open={isDialogOpen} onClose={toggleDialog} maxWidth="xs">
        <DialogContent>
          <LoginOrRegisterDisclaimer handleClick={handleClick} descriptionType="joining-a-match" />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default GuestJoinMatchButton
