import * as React from 'react'

import getLinkToVenueFirstOffering from '../../../../helpers/get-link-to-venue-first-offering'
import { useDiscoverReducer } from '../../DiscoverContext'
import { useMapListingResultVenueQuery } from '../../../../graphql'

import MapListingResultComponent from './MapListingResultComponent'

interface MapListingResultProps {
  venueId: string
}

const MapListingResult = ({ venueId }: MapListingResultProps) => {
  const { data } = useMapListingResultVenueQuery({
    variables: {
      venueId,
    },
  })

  const dispatch = useDiscoverReducer()

  if (!data) return null

  const handleClick = (e: MouseEvent) => {
    e.preventDefault()
    dispatch({ type: 'SET_CLICKED_VENUE_ID', payload: undefined })
  }

  const venue = data.venue

  const to = getLinkToVenueFirstOffering({
    venueSlug: venue.slug,
    firstVenueOffering: venue.offerings[0],
  })

  return <MapListingResultComponent venue={venue} to={to} handleClick={handleClick} />
}

export default MapListingResult
