import * as React from 'react'
import { useState } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'

import useMediaQueryBreakpoint from '../../../../hooks/useMediaQueryBreakpoint'
import { Image } from '../../VenueProfile.types'
import { MOBILE_IMAGE_HEIGHT } from '../ImagesGallery/ImagesGallery.constants'

import PlaceholderImage from './assets/gallery-placeholder-image.webp'
import Lightbox from './Lightbox'
import Gallery from './Gallery'
import Carousel from './Carousel'

export type CarouselDirection = 'left' | 'right'
interface Props {
  images: Array<Image>
}

const ImagesGallery = ({ images }: Props) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const [carouselDirection, setCarouselDirection] = useState<CarouselDirection>('left')
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const screenBreakpoint = useMediaQueryBreakpoint()
  const isXs = screenBreakpoint === 'xs'

  const closeLightbox = () => setIsLightboxOpen(false)
  const openLightbox = () => setIsLightboxOpen(true)

  return (
    <>
      <Box sx={{ display: { sm: 'none' }, width: '100%', height: `${MOBILE_IMAGE_HEIGHT}px` }}>
        {isXs &&
          (!images.length ? (
            <Box sx={{ minWidth: '343px', height: `${MOBILE_IMAGE_HEIGHT}px`, overflowY: 'hidden' }}>
              <img
                src={PlaceholderImage}
                alt="Venue Profile Image"
                width="100%"
                height={`${MOBILE_IMAGE_HEIGHT}px`}
                style={{ objectFit: 'cover', borderRadius: '8px' }}
              />
            </Box>
          ) : (
            <Stack display={{ xs: 'flex', sm: 'none' }} justifyContent="space-around">
              <Carousel
                images={images}
                activeImageIndex={activeImageIndex}
                setActiveImageIndex={setActiveImageIndex}
                carouselDirection={carouselDirection}
                setCarouselDirection={setCarouselDirection}
                openLightbox={openLightbox}
              />
            </Stack>
          ))}
      </Box>
      {!isXs && <Gallery images={images} setActiveImageIndex={setActiveImageIndex} openLightbox={openLightbox} />}
      {isLightboxOpen && (
        <Lightbox
          isOpen={isLightboxOpen}
          handleClose={closeLightbox}
          images={images}
          activeImageIndex={activeImageIndex}
          setActiveImageIndex={setActiveImageIndex}
          carouselDirection={carouselDirection}
          setCarouselDirection={setCarouselDirection}
        />
      )}
    </>
  )
}

export default ImagesGallery
