import Link from '@eversports/klimt-primitives/Link'
import Text from '@eversports/klimt-primitives/Text'
import Breadcrumbs from '@eversports/klimt-primitives/Breadcrumbs'
import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import ArrowBackwards from '@eversports/design-tokens/assets/icons/arrow-backwards.svg'
import Box from '@eversports/klimt-primitives/Box'
import { useParams } from 'react-router-dom'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import { Localized } from '../../localization/react'
import { usePageNavigationMatchQuery } from '../../graphql'
import { Origin } from '../../App.types'
import useUrlParams from '../../hooks/use-url-params'

import { PageType, UserType } from './Match.types'
import PageNavigationBreadcrumbLink from './PageNavigationBreadcrumbLink'
import useLoggedInUserType from './hooks/use-logged-in-user-type'

interface Props {
  pageType: PageType
}

const PageNavigation = ({ pageType }: Props) => {
  const { language } = useSettingsContext()
  const { id } = useParams<{ id: string }>()
  const { userType } = useLoggedInUserType()

  const { origin } = useUrlParams()
  const { data } = usePageNavigationMatchQuery({ variables: { matchId: id } })

  if (!data || pageType === PageType.THANK_YOU) return null
  if (pageType === PageType.OVERVIEW && userType !== UserType.ORGANIZER) return null

  const startDate = new Date(data.match.start)
  const formattedDate = new Intl.DateTimeFormat(language, { year: 'numeric', month: 'short', day: 'numeric' }).format(
    startDate,
  )
  const venueShortId = data.match.venue.shortId
  const sportName = data.match.sport.name

  const sportSlug = data.match.sport.slug
  const citySlug = data.match.venue.city.slug
  const venueName = data.match.venue.name

  return (
    <Box sx={{ backgroundColor: 'eggshell', p: { xs: 4, sm: 0 }, width: '100%' }}>
      {origin === Origin.WIDGET ? (
        <Link external to={`/widget/w/${venueShortId}`}>
          <Stack flex={1} direction="row" alignItems="center" gap={2}>
            <Icon src={ArrowBackwards} size="small" color="midnight" />
            <Text sx={{ textTransform: 'capitalize' }}>
              <Localized id="back-link" />
            </Text>
          </Stack>
        </Link>
      ) : (
        <Breadcrumbs aria-label="breadcrumb">
          <PageNavigationBreadcrumbLink
            pageType={pageType}
            sportSlug={sportSlug}
            citySlug={citySlug}
            venueName={venueName}
          />
          <Text>
            {sportName} ({formattedDate})
          </Text>
        </Breadcrumbs>
      )}
    </Box>
  )
}

export default PageNavigation
