import * as React from 'react'
import KlimtDrawer from '@eversports/klimt-primitives/Drawer/Drawer'

const MOBILE_DRAWER_HEIGHT = '80vh'

interface Props {
  isOpen: boolean
  handleClose: () => void
  height?: string
}

const Drawer = ({ children, isOpen, handleClose, height = MOBILE_DRAWER_HEIGHT }: React.PropsWithChildren<Props>) => (
  <KlimtDrawer
    open={isOpen}
    onClose={handleClose}
    anchor="bottom"
    sx={{
      '& .MuiDrawer-paper': {
        border: '1px solid',
        borderColor: 'lightgray',
        borderRadius: '16px 16px 0px 0px',
        height,
        paddingX: 6,
        paddingBottom: 4,
      },
    }}
  >
    {children}
  </KlimtDrawer>
)

export default Drawer
