import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { assertNever } from '@eversports/klimt-utilities/assert-never'
import { AnimatePresence } from '@eversports/klimt-utilities/framer-motion'
import NoSsr from '@eversports/klimt-design-components/NoSsr'

import {
  NpsBannerMeDocument,
  useNpsBannerAddNetPromoterScoreMutation,
  useNpsBannerDismissNetPromoterScoreMutation,
  useNpsBannerMeQuery,
} from '../../../graphql'

import NPSBannerComponent from './NPSBannerComponent'
import { NPSBannerStep, TIME_TO_HIDE_BANNER } from './NPSBanner.constants'

const NPSBanner = () => {
  const forwardedRef = useRef<HTMLDivElement | null>(null)
  const [showNPSBanner, setShowNPSBanner] = useState(false)
  const [sliderValue, setSliderValue] = useState<number | null>(null)
  const [comment, setComment] = useState('')
  const [step, setStep] = useState<NPSBannerStep>(NPSBannerStep.SCORE)

  const { data } = useNpsBannerMeQuery()
  const [addNetPromoterScore] = useNpsBannerAddNetPromoterScoreMutation({ refetchQueries: [NpsBannerMeDocument] })
  const [dismissNetPromoterScore] = useNpsBannerDismissNetPromoterScoreMutation({
    refetchQueries: [NpsBannerMeDocument],
  })

  useEffect(() => {
    if (data?.me?.showNetPromoterScoreQuestion) {
      setShowNPSBanner(true)
    }
  }, [data])

  const handleNextStep = () => {
    switch (step) {
      case NPSBannerStep.SCORE:
        setStep(NPSBannerStep.COMMENT)
        return
      case NPSBannerStep.COMMENT:
        void addNetPromoterScore({
          variables: { score: sliderValue!, comment },
          onCompleted: () => {
            setTimeout(() => {
              setShowNPSBanner(false)
            }, TIME_TO_HIDE_BANNER)
          },
        })
        setStep(NPSBannerStep.THANK_YOU)
        return
      case NPSBannerStep.THANK_YOU:
        return
      default:
        return assertNever(step)
    }
  }

  const handlePreviousStep = () => {
    switch (step) {
      case NPSBannerStep.SCORE:
        return
      case NPSBannerStep.COMMENT:
        return setStep(NPSBannerStep.SCORE)
      case NPSBannerStep.THANK_YOU:
        return setStep(NPSBannerStep.COMMENT)
      default:
        return assertNever(step)
    }
  }

  const handleDismiss = () => {
    void dismissNetPromoterScore({ onCompleted: () => setShowNPSBanner(false) })
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (forwardedRef.current && !forwardedRef.current.contains(event.target as Node)) {
        handleDismiss()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [forwardedRef])

  return (
    <NoSsr>
      <AnimatePresence>
        {showNPSBanner && (
          <NPSBannerComponent
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            comment={comment}
            setComment={setComment}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            handleDismiss={handleDismiss}
            forwardedRef={forwardedRef}
            step={step}
          />
        )}
      </AnimatePresence>
    </NoSsr>
  )
}

export default NPSBanner
