import * as React from 'react'
import { useState } from 'react'
import { TableRow, TableCell } from '@eversports/klimt-primitives/Table'
import Text from '@eversports/klimt-primitives/Text'
import Info from '@eversports/design-tokens/assets/icons/info.svg'
import Icon from '@eversports/klimt-primitives/Icon/Icon'
import Stack from '@eversports/klimt-primitives/Stack/Stack'

import { PurchaseOption } from '../../ActivityDetails.types'

import PriceLabel from './PriceLabel'
import PriceText from './PriceText'
import SessionsForTicketPopup from './SessionsForTicketPopup'

interface Props {
  purchaseOption: PurchaseOption
  isCorporateUser: boolean
}

const PriceTableRow = ({ purchaseOption, isCorporateUser }: Props) => {
  const [isScheduleOpen, setIsScheduleOpen] = useState(false)

  const isTicket = purchaseOption.__typename === 'Ticket'

  const toggleSchedule = () => {
    if (isTicket) {
      setIsScheduleOpen(!isScheduleOpen)
    }
  }

  return (
    <>
      <TableRow
        onClick={toggleSchedule}
        sx={{
          border: 0,
          '&:nth-of-type(odd)': {
            backgroundColor: 'eggshell',
          },
          cursor: isTicket ? 'pointer' : 'default',
        }}
      >
        <TableCell sx={{ border: 0, minWidth: '160px' }}>
          <PriceText purchaseOption={purchaseOption} isCorporateUser={isCorporateUser} />
        </TableCell>
        <TableCell sx={{ border: 0 }}>
          <Text color="midnight">{purchaseOption.name}</Text>
        </TableCell>
        <TableCell sx={{ border: 0 }}>
          <Stack alignItems="flex-end">
            <PriceLabel purchaseOption={purchaseOption} isCorporateUser={isCorporateUser} />
          </Stack>
        </TableCell>
        {isTicket && (
          <TableCell align="right" sx={{ border: 0 }}>
            <Icon src={Info} size="small" color="gray" />
          </TableCell>
        )}
      </TableRow>
      {isTicket && (
        <SessionsForTicketPopup
          ticket={purchaseOption}
          sessions={purchaseOption.validForSessions}
          isOpen={isScheduleOpen}
          togglePopup={toggleSchedule}
          isCorporateUser={isCorporateUser}
        />
      )}
    </>
  )
}

export default PriceTableRow
