import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import Stack from '@eversports/klimt-primitives/Stack'
import Link from '@eversports/klimt-primitives/Link'

import { Localized } from '../../localization/react'

import GoogleMapsIcon from './assets/google-maps-icon.svg'
import { getGoogleMapsLink } from './helpers/get-google-maps-link'

export type GoogleMapsUrlVariant = 'search' | 'directions'

type DisplayOnlyProps = {
  type: 'display'
  sizeVariant?: 'default' | 'small'
}

type UrlProps = {
  type: 'url'
  sizeVariant?: 'default' | 'small'
  latitude: number
  longitude: number
  urlVariant: GoogleMapsUrlVariant
}

const OpenInGoogleMapsButton = ({ sizeVariant = 'default' }: Pick<DisplayOnlyProps, 'sizeVariant'>) => {
  if (sizeVariant === 'small') {
    return (
      <Button variant="tertiary" sx={{ paddingX: 2, paddingY: 1 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <img src={GoogleMapsIcon} alt="Google Maps Icon" width="16px" height="16px" />
          <Text variant="xsmall" sx={{ textTransform: 'none' }}>
            <Localized id="open-google-maps-short-text" />
          </Text>
        </Stack>
      </Button>
    )
  }
  return (
    <Button variant="tertiary">
      <Stack direction="row" alignItems="center" gap={2}>
        <img src={GoogleMapsIcon} alt="Google Maps Icon" width="17px" height="21px" />
        <Text sx={{ fontWeight: 'bold', textTransform: 'none' }}>
          <Localized id="open-google-maps" />
        </Text>
      </Stack>
    </Button>
  )
}

const MapShowGoogleMaps = (props: DisplayOnlyProps | UrlProps) => {
  const { type, sizeVariant = 'default' } = props

  if (type === 'display') {
    return <OpenInGoogleMapsButton sizeVariant={sizeVariant} />
  }

  const { latitude, longitude, urlVariant } = props

  const url = getGoogleMapsLink({ variant: urlVariant, latitude, longitude })

  return (
    <Link to={url} external blank>
      <OpenInGoogleMapsButton sizeVariant={sizeVariant} />
    </Link>
  )
}

export default MapShowGoogleMaps
