import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import { Helmet } from 'react-helmet-async'

import MetaHelmet from '../../components/MetaHelmet'
import { useIntl } from '../../localization/react'
import getStaticPageMetadata from '../../helpers/get-static-page-metadata'

import DownloadApps from './DownloadApps'
import CitySportOfferings from './CitySportOfferings'
import Testimonials from './Testimonials'
import Hero from './Hero'
import WhatIsEversports from './WhatIsEversports'
import WhereIsEversports from './WhereIsEversports'
import CitySportSeoLinks from './CitySportSeoLinks'
import FeaturePromotion from './FeaturePromotion'
import BricolageGrotesqueFont from './assets/bricolage-grotesque-font-face'

const Home = () => {
  const intl = useIntl()
  const { origin, tld } = useSettingsContext()

  const countryName = intl.tldCountryName({ tld })

  const meta = getStaticPageMetadata({
    title: intl.homeTldMetaTitle({ countryName }),
    description: intl.homeTldMetaDescription({ countryName }),
    url: origin,
  })

  return (
    <>
      <Helmet>
        <style>{BricolageGrotesqueFont}</style>
      </Helmet>
      <MetaHelmet meta={meta} canonicalHref={origin} />
      <Stack component="main" alignItems="center" sx={{ maxWidth: '100vw' }}>
        <Hero />
        <WhatIsEversports />
        <WhereIsEversports />
        <CitySportSeoLinks />
        <CitySportOfferings />
        <FeaturePromotion />
        <Testimonials />
        <DownloadApps />
      </Stack>
    </>
  )
}

export default Home
