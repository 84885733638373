import { InvoiceState } from './InvoiceHistory.types'

export const invoiceStatusColorMapping = {
  [InvoiceState.INVOICE_STATE_FULLY_PAID]: 'primary',
  [InvoiceState.INVOICE_STATE_OPEN]: 'tertiary',
  [InvoiceState.INVOICE_STATE_CANCELLED]: 'red',
  [InvoiceState.INVOICE_STATE_REFUND]: 'secondary',
} as const

export const invoiceStatusLabelMapping = {
  [InvoiceState.INVOICE_STATE_FULLY_PAID]: 'invoice-status-paid',
  [InvoiceState.INVOICE_STATE_OPEN]: 'invoice-status-unpaid',
  [InvoiceState.INVOICE_STATE_CANCELLED]: 'invoice-status-cancelled',
  [InvoiceState.INVOICE_STATE_REFUND]: 'invoice-status-refund',
} as const
