import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import MonetaryValue from '@eversports/klimt-localization/MonetaryValue'
import Stack from '@eversports/klimt-primitives/Stack'

import { MonetaryValue as MonetaryValueType } from '../../../../App.types'
import { Localized } from '../../../../localization/react'

interface Props {
  type: 'trial-offer' | 'prices'
  price: MonetaryValueType
}

const CallToActionItem = ({ type, price }: Props) => {
  return (
    <Stack justifyContent="center" p={2} flex={1}>
      <Text
        variant="badge"
        sx={{ color: 'darkgray', textTransform: 'uppercase', fontWeight: 'initial', textAlign: 'center' }}
      >
        {type === 'trial-offer' ? (
          <Localized id="venue-profile-trial-offer-price" />
        ) : (
          <Localized id="venue-profile-cheapest-price" />
        )}
      </Text>
      <Text variant="small" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        <MonetaryValue value={price.value} currencyCode={price.currency} />
      </Text>
    </Stack>
  )
}

export default CallToActionItem
