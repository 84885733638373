import { default as MuiTooltip, TooltipProps } from '@mui/material/Tooltip'
import * as React from 'react'

export { TooltipProps }

export const Tooltip = (props: React.PropsWithChildren<TooltipProps>) => {
  return <MuiTooltip {...props} />
}

export default Tooltip
