import * as React from 'react'
import CircularProgress from '@eversports/klimt-primitives/CircularProgress'
import Stack from '@eversports/klimt-primitives/Stack'

const LoadingPage = () => {
  return (
    <Stack justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress />
    </Stack>
  )
}

export default LoadingPage
