import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'

interface Props {
  name: string
}

const FriendUpcomingParticipationSport = ({ name }: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'midnight',
        borderRadius: 1,
        paddingY: 1,
        paddingX: 2,
        width: 'fit-content',
      }}
    >
      <Text variant="badge" sx={{ fontWeight: 'bold' }}>
        {name}
      </Text>
    </Box>
  )
}

export default FriendUpcomingParticipationSport
