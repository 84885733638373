import * as React from 'react'
import { useEffect, useState } from 'react'
import MessagesIcon from '@eversports/design-tokens/assets/icons/messages.svg'
import Link from '@eversports/klimt-primitives/Link'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import Text from '@eversports/klimt-primitives/Text'
import getMobileOperatingSystem from '@eversports/klimt-utilities/get-operating-system'

import { Localized } from '../localization/react'

import SharingActionBox from './SharingActionBox'

import { ShareType } from '@eversports/klimt-patterns/SharingActions/SharingActions.types'

interface Props {
  trackClick: (type: ShareType.Messages) => void
  message: string
}

const SharingActionMessage = ({ trackClick, message }: Props) => {
  const [operatingSystem, setOperatingSystem] = useState<string | null>(null)
  const toPrefix = operatingSystem === 'iOS' ? `sms:&body=` : 'sms:?body='

  useEffect(() => {
    setOperatingSystem(getMobileOperatingSystem())
  }, [])

  return (
    <Stack alignItems="center" justifyContent="center" gap={2} flexGrow={1} flexShrink={0}>
      <Link to={`${toPrefix}${message}`} external blank ariaLabel="sms friends">
        <SharingActionBox onClick={() => trackClick(ShareType.Messages)}>
          <Icon src={MessagesIcon} color="midnight" size="default" />
        </SharingActionBox>
      </Link>
      <Text variant="small" textAlign="center" sx={{ color: 'midnight' }}>
        <Localized id="sharing-actions-message" />
      </Text>
    </Stack>
  )
}

export default SharingActionMessage
