import { SharingMessage } from '@eversports/klimt-patterns/SharingActions'

import { useIntl } from '../../../../localization/react'

interface Args {
  userFirstName: string
  recapUrl: string
}

const useSharingMessage = ({ userFirstName, recapUrl }: Args): SharingMessage => {
  const intl = useIntl()

  const params = {
    userFirstName,
    recapUrl,
  }

  //   Message that is shared:
  //
  //   Hey, checkout ${userFirstName}'s 2023 Eversports Recap 🎉
  //
  //   ➡️ ${recapUrl}
  const translatedMessage = intl.yearlyRecapSharingMessage(params)

  return {
    encodedMessage: encodeURIComponent(translatedMessage),
    translatedMessage: translatedMessage,
    linkToCopy: recapUrl,
  }
}

export default useSharingMessage
