import * as React from 'react'
import { Helmet } from 'react-helmet-async'

import { Meta } from '../App.types'
import { AggregateRatingStructuredData } from '../helpers/get-aggregate-rating-structured-data'

interface Props {
  meta: Meta
  canonicalHref?: string
  noIndex?: boolean
  structuredDataJSONLD?: AggregateRatingStructuredData
}

const MetaHelmet = ({ meta, canonicalHref, noIndex, structuredDataJSONLD }: Props) => {
  const { twitter, openGraph } = meta.social

  return (
    <Helmet>
      <title>{meta.title}</title>
      {meta.description && <meta name="description" content={meta.description} />}
      {canonicalHref && <link rel="canonical" href={canonicalHref} />}
      {noIndex && <meta name="robots" content="noindex" />}
      <meta property="og:type" content={openGraph.type} />
      <meta property="og:site_name" content={openGraph.siteName} />
      <meta property="og:title" content={openGraph.title} />
      {openGraph.description && <meta property="og:description" content={openGraph.description} />}
      <meta property="og:image" content={openGraph.image.large} />
      <meta property="og:image:width" content="256" />
      <meta property="og:image:height" content="256" />
      <meta property="og:image:alt" content={openGraph.title} />
      <meta property="og:url" content={openGraph.url} />
      <meta name="twitter:card" content={twitter.type} />
      <meta name="twitter:title" content={twitter.title} />
      {twitter.description && <meta name="twitter:description" content={twitter.description} />}
      <meta property="twitter:image" content={twitter.image.large} />
      <meta property="twitter:image:alt" content={twitter.title} />
      {structuredDataJSONLD && (
        <script type="application/ld+json">{JSON.stringify({ ...structuredDataJSONLD })}</script>
      )}
    </Helmet>
  )
}
export default React.memo(MetaHelmet)
