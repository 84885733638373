import * as React from 'react'
import Dialog, { DialogContent, DialogTitle } from '@eversports/klimt-primitives/Dialog'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { useState } from 'react'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import Drawer from '@eversports/klimt-primitives/Drawer'

import { Localized } from '../../../localization/react'
import { SportWithParents } from '../Discover.types'

import Ellipse from './assets/Ellipse.svg'
import AllSportsContent from './AllSportsContent'

export const MOBILE_DRAWER_HEIGHT = '67vh'

interface Props {
  sports: Array<SportWithParents>
}

const AllSportsList = ({ sports }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const toggleView = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Stack
        onClick={toggleView}
        direction="row"
        alignItems="center"
        gap={2}
        sx={{
          px: 5,
          py: 2,
          border: '1px solid',
          borderColor: 'mediumgray',
          borderRadius: 10,
          width: 'fit-content',
          ':hover': { cursor: 'pointer', borderColor: 'primary.main' },
          flexShrink: 0,
        }}
      >
        <img src={Ellipse} width="12px" height="4px" loading="lazy" alt="See more" />
        <Text>
          <Localized id="see-more-sports" />
        </Text>
      </Stack>
      {isMobile ? (
        <Drawer
          open={isOpen}
          onClose={toggleView}
          anchor="bottom"
          sx={{
            '& .MuiDrawer-paper': {
              border: '1px solid',
              borderColor: 'lightgray',
              borderRadius: '16px 16px 0px 0px',
              height: MOBILE_DRAWER_HEIGHT,
              p: 5,
            },
          }}
        >
          <Stack gap={4}>
            <Text variant="large" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              <Localized id="all-sports-heading" />
            </Text>
            <AllSportsContent sports={sports} isShown={isOpen} />
          </Stack>
        </Drawer>
      ) : (
        <Dialog
          open={isOpen}
          onClose={toggleView}
          sx={{
            '& .MuiDialog-paper': {
              background: 'white',
              border: '1px solid',
              borderColor: 'lightgray',
              boxSizing: 'border-box',
              boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.12)',
              borderRadius: '16px',
              minWidth: { xs: '90vw', lg: '400px' },
              height: '60vh',
            },
          }}
        >
          <DialogTitle>
            <Text variant="large" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              <Localized id="all-sports-heading" />
            </Text>
          </DialogTitle>
          <DialogContent>
            <AllSportsContent sports={sports} isShown={isOpen} />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default AllSportsList
