import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import NoSsr from '@eversports/klimt-design-components/NoSsr'
import { UserFeaturePreviews } from '@eversports/react-app-base/user-feature-previews'

import { useYearlyRecapBannerMeQuery } from '../../graphql'
import { Localized } from '../../localization/react'
import useEnabledFeaturePreviews from '../../hooks/useEnabledFeaturePreviews'

import FacePartySVG from './assets/face-party.svg'
import BackgroundImage from './assets/recap-background-image.webp'

const YearlyRecapBanner = () => {
  const { data } = useYearlyRecapBannerMeQuery()
  const enabledFeaturePreviews = useEnabledFeaturePreviews()
  const isYearlyRecapEnabled = Boolean(enabledFeaturePreviews?.includes(UserFeaturePreviews.YEARLY_RECAP))

  if (!data || !data.me) {
    return null
  }

  if (!data.me.recapToken || !isYearlyRecapEnabled) {
    return null
  }

  return (
    <Link to={`/user/${data.me.recapToken}/yearly-recap`}>
      <Box
        sx={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'white',
          padding: 3,
          textAlign: 'center',
          width: '100%',
          borderBottom: '1px solid',
          borderColor: 'primary.main',
          position: 'sticky',
          zIndex: 6,
        }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          gap={{ xs: 0, sm: 2 }}
          alignItems="center"
          justifyContent="center"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <Box
              sx={{
                padding: 1,
                borderRadius: 1,
                background:
                  'linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #D3EDEA',
              }}
            >
              <img src={FacePartySVG} height="20px" width="20px" />
            </Box>
            <Text variant="large" sx={{ color: 'midnight', fontWeight: 'bold' }}>
              <Localized id="yearly-recap-banner-title" />
            </Text>
          </Stack>
          <Text>
            <Localized id="yearly-recap-banner-subtitle" />
          </Text>
        </Stack>
      </Box>
    </Link>
  )
}

const YearlyRecapBannerWrapper = () => {
  return (
    <NoSsr>
      <YearlyRecapBanner />
    </NoSsr>
  )
}

export default YearlyRecapBannerWrapper
