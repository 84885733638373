import * as React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import DiscoverScreen from '../../DiscoverScreen'

import DiscoverCity from './DiscoverCity'

const DiscoverCityPage = () => {
  const { citySlug } = useParams<{ citySlug: string }>()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [citySlug])

  return (
    <DiscoverScreen>
      <DiscoverCity />
    </DiscoverScreen>
  )
}

export default DiscoverCityPage
