import { DiscoverPrefix } from '../../../App.types'
import { InventorySearchResult } from '../Search.types'

const getInventoryResultLink = (result: InventorySearchResult) => {
  switch (result.__typename) {
    case 'SportSearchResult':
      return `${DiscoverPrefix.APP_SPORT_CITY}/${result.sportSlug}/${result.citySlug}`
    case 'VenueSearchResult':
      return `/s/${result.slug}`
    case 'AllSportsInCity':
      return `/b/${result.citySlug}`
    default:
      return ''
  }
}

export default getInventoryResultLink
