import * as React from 'react'
import { useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Info from '@eversports/design-tokens/assets/icons/info.svg'
import Icon from '@eversports/klimt-primitives/Icon/Icon'

import { PurchaseOption } from '../../ActivityDetails.types'

import SessionsForTicketPopup from './SessionsForTicketPopup'
import PriceText from './PriceText'
import PriceLabel from './PriceLabel'

interface Props {
  purchaseOption: PurchaseOption
  isCorporateUser: boolean
}

const PriceCardContent = ({ purchaseOption, isCorporateUser }: Props) => {
  const [isScheduleOpen, setIsScheduleOpen] = useState(false)

  const isTicket = purchaseOption.__typename === 'Ticket'

  const toggleSchedule = () => {
    if (isTicket) {
      setIsScheduleOpen(!isScheduleOpen)
    }
  }

  return (
    <>
      <Stack
        onClick={toggleSchedule}
        justifyContent="space-between"
        gap={2}
        paddingY={4}
        sx={{
          borderBottom: '1px solid',
          borderColor: 'mediumgray',
          width: '100%',
          cursor: isTicket ? 'pointer' : 'default',
        }}
      >
        <Stack direction="row" justifyContent="space-between" gap={2} width="100%">
          <PriceText purchaseOption={purchaseOption} isCorporateUser={isCorporateUser} />
          {isTicket && <Icon src={Info} size="small" color="gray" />}
        </Stack>
        <Text color="carbon">{purchaseOption.name}</Text>
        <PriceLabel purchaseOption={purchaseOption} isCorporateUser={isCorporateUser} />
      </Stack>
      {isTicket && (
        <SessionsForTicketPopup
          ticket={purchaseOption}
          sessions={purchaseOption.validForSessions}
          isOpen={isScheduleOpen}
          togglePopup={toggleSchedule}
          isCorporateUser={isCorporateUser}
        />
      )}
    </>
  )
}

export default PriceCardContent
