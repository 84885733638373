import { DiscoverPrefix } from './App.types'

enum Routes {
  HOME = '/',
  SEO_DISCOVER_SPORT_CITY = `${DiscoverPrefix.SEO_SPORT_CITY}/:sportSlug/:citySlug`,
  APP_DISCOVER_SPORT_CITY = `${DiscoverPrefix.APP_SPORT_CITY}/:sportSlug/:citySlug`,
  DISCOVER_CITY = '/b/:citySlug',
  RECOMMENDATIONS = '/u/recommendations',
  TECHNICAL_NOTES = '/h/technical-notes',
  DATA_SUB_PROCESSORS = '/h/sub-processors',
  VENUE_PROFILE = '/s/:venueSlug',
  INVOICE_HISTORY = '/u/credits/:assignedProductId/invoice-history',
  MATCH_DETAILS = '/match/:id',
  DISCOVER_SPORT_MATCHES_CITY = '/matches/:citySlug/:sportSlug?',
  ACTIVITY_DETAILS_DEPRECATED = '/e/:eventType/:shortId',
  ACTIVITY_DETAILS = '/activity/:activityId',
  PUBLIC_USER_PROFILE = '/user/:userHandle',
  USER_INTERVIEW_REGISTRATION = '/user-interview-registration',
  NOTIFICATIONS = '/u/notifications',
  YEARLY_RECAP = '/user/:recapToken/yearly-recap',
}

export default Routes
