import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import StoreBadge from '../../../components/StoreBadge'
import { Localized } from '../../../localization/react'

const StoreBadges = () => {
  return (
    <Stack gap={6}>
      <Text variant="large" sx={{ color: 'white' }}>
        <Localized id="download-app-title" />
      </Text>
      <Stack direction="row" flexWrap="wrap" gap={2}>
        <StoreBadge type="app-store" height="44px" width="140px" urlVariant="ballsports" />
        <StoreBadge type="play-store" height="44px" width="162px" />
      </Stack>
    </Stack>
  )
}

export default StoreBadges
