import * as React from 'react'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import { useEffect, useState } from 'react'
import Stack, { StackProps } from '@eversports/klimt-primitives/Stack'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import NoSsr from '@eversports/klimt-design-components/NoSsr'

import { ManagerLinksMapping } from '../../App.constants'
import useLoggedInUser from '../../hooks/useLoggedInUser'
import YearlyRecapBanner from '../YearlyRecapBanner'

import EversportsLogoLink from './EversportsLogoLink'
import DesktopHeaderItems from './DesktopHeaderItems'
import MobileUserMenuDialogComponent from './UserMenu/MobileUserMenuDialogComponent'
import NPSBanner from './NPSBanner'

type PositionProp = Pick<StackProps, 'position' | 'boxShadow'>

const Header = ({
  children,
  position = 'sticky',
  boxShadow = boxShadows.default,
}: React.PropsWithChildren<PositionProp>) => {
  const { tld } = useSettingsContext()
  const managerLinks = ManagerLinksMapping[tld]
  const { getLoggedInUser, user } = useLoggedInUser()
  const [authUrlParams, setAuthUrlParams] = useState('')

  useEffect(() => {
    getLoggedInUser()
    setAuthUrlParams(
      `?origin=eversport&redirectPath=${encodeURIComponent(window.location.pathname + window.location.search)}`,
    )
  }, [])

  return (
    <>
      <Stack
        sx={{
          top: 0,
          zIndex: 7,
          width: '100%',
          position,
        }}
      >
        <YearlyRecapBanner />
        <Stack
          sx={{
            boxShadow,
          }}
          component="header"
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              px: { xs: 3, lg: 8 },
              pt: { xs: 3, lg: 2 },
              pb: { xs: 3, lg: 2 },
              width: '100%',
              backgroundColor: 'white',
              zIndex: 6,
              gap: { xs: 3, lg: 5 },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{ width: { xs: '100%', lg: 'calc(55vw - 20px)' }, paddingRight: { lg: 3 }, gap: { xs: 3, lg: 5 } }}
            >
              <EversportsLogoLink />
              {children}
            </Stack>
            <NoSsr>
              <MobileUserMenuDialogComponent user={user} authUrlParams={authUrlParams} />
              <DesktopHeaderItems managerLinks={managerLinks} user={user} authUrlParams={authUrlParams} />
            </NoSsr>
          </Stack>
        </Stack>
      </Stack>
      <NPSBanner />
    </>
  )
}

export default Header
