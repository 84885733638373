const CHECKOUT_PATH = '/phoenix'

interface Props {
  bookableItemId: string
  origin: string
  venueId: string
}

const getLinkToCheckout = ({ bookableItemId, origin, venueId }: Props) => {
  const paramsObj = {
    bookableItemId,
    origin,
    venueId,
  }
  const searchParams = new URLSearchParams(paramsObj)

  return `${CHECKOUT_PATH}?${searchParams.toString()}`
}

export default getLinkToCheckout
