import * as React from 'react'
import { useState } from 'react'
import Dialog, { DialogTitle, DialogContent } from '@eversports/klimt-primitives/Dialog'
import Stack from '@eversports/klimt-primitives/Stack'
import Switch from '@eversports/klimt-primitives/Switch'
import Text from '@eversports/klimt-primitives/Text'
import Link from '@eversports/klimt-primitives/Link'
import Button from '@eversports/klimt-primitives/Button'

import { Localized } from '../../../localization/react'
import { TrackingType } from '../../../App.types'

interface CookieBannerComponentProps {
  isOpen: boolean
  isAnalyticsChecked: boolean
  isAdvertisingChecked: boolean
  handleOnChange: (type: TrackingType) => (event: React.ChangeEvent<HTMLInputElement>) => void
  openCookieBanner: () => void
  handleClickAccept: () => void
  handleClickConfirm: () => void
}

const CookieBannerComponent = ({
  isOpen,
  isAnalyticsChecked,
  isAdvertisingChecked,
  openCookieBanner,
  handleOnChange,
  handleClickAccept,
  handleClickConfirm,
}: CookieBannerComponentProps) => {
  const [showCookiePreferences, setShowCookiePreferences] = useState(false)
  const handleClickCookiePreferences = () => {
    setShowCookiePreferences(true)
  }

  return (
    <>
      <Text onClick={openCookieBanner} sx={{ color: 'lightgray', cursor: 'pointer' }}>
        <Localized id="footer-cookie-settings" />
      </Text>
      <Dialog
        open={isOpen}
        sx={{
          '& .MuiDialog-paper': {
            background: '#ffffff',
            border: '1px solid #F2F2F2',
            boxSizing: 'border-box',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.12)',
            borderRadius: '16px',
            minWidth: { lg: '400px' },
            maxWidth: { lg: '680px' },
          },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center', m: 2, fontWeight: 'bold' }}>
          <Localized id="cookie-banner-title" />
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={8}>
            {!showCookiePreferences ? (
              <Text sx={{ textAlign: 'center', color: 'darkgray' }}>
                <Localized id="cookie-banner-description-short" />
              </Text>
            ) : (
              <>
                <Text sx={{ textAlign: 'center', color: 'darkgray' }}>
                  <Localized id="cookie-banner-description-long" />
                </Text>
                <Text sx={{ textAlign: 'center', color: 'darkgray' }}>
                  <Localized
                    id="cookie-banner-further-information"
                    params={{
                      privacyPolicy: (
                        <Link to="/h/privacy" external blank>
                          <Localized id="cookie-banner-privacy-policy" />
                        </Link>
                      ),
                      technicalNotes: (
                        <Link to="https://www.eversportsmanager.com/de/technische-hinweise" external blank>
                          <Localized id="cookie-banner-technical-notes" />
                        </Link>
                      ),
                    }}
                  />
                </Text>
                <Stack spacing={8}>
                  <Stack spacing={3}>
                    <Switch
                      disabled
                      defaultChecked
                      label={
                        <Text variant="large" sx={{ fontWeight: 'bold' }}>
                          <Localized id="cookie-banner-label-necessary" />
                        </Text>
                      }
                    />
                    <Text sx={{ textAlign: 'center', color: 'darkgray' }}>
                      <Localized id="cookie-banner-label-necessary-description" />
                    </Text>
                  </Stack>
                  <Stack spacing={3}>
                    <Switch
                      checked={isAnalyticsChecked}
                      onChange={handleOnChange(TrackingType.ANALYTICS)}
                      label={
                        <Text variant="large" sx={{ fontWeight: 'bold' }}>
                          <Localized id="cookie-banner-label-analytics" />
                        </Text>
                      }
                    />
                    <Text sx={{ textAlign: 'center', color: 'darkgray' }}>
                      <Localized id="cookie-banner-label-analytics-description" />
                    </Text>
                  </Stack>
                  <Stack spacing={3}>
                    <Switch
                      checked={isAdvertisingChecked}
                      onChange={handleOnChange(TrackingType.ADVERTISING)}
                      label={
                        <Text variant="large" sx={{ fontWeight: 'bold' }}>
                          <Localized id="cookie-banner-label-advertising" />
                        </Text>
                      }
                    />
                    <Text sx={{ textAlign: 'center', color: 'darkgray' }}>
                      <Localized id="cookie-banner-label-advertising-description" />
                    </Text>
                  </Stack>
                </Stack>
              </>
            )}
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
              {showCookiePreferences ? (
                <Button variant="secondary" onClick={handleClickConfirm} sx={{ flex: 1, padding: 3 }}>
                  <Text variant="badge" sx={{ color: 'primary.main' }}>
                    <Localized id="cookie-banner-confirm" />
                  </Text>
                </Button>
              ) : (
                <Button variant="tertiary" onClick={handleClickCookiePreferences} sx={{ flex: 1, padding: 3 }}>
                  <Text variant="badge" sx={{ color: 'midnight' }}>
                    <Localized id="cookie-banner-cookie-preferences" />
                  </Text>
                </Button>
              )}
              <Button variant="primary" onClick={handleClickAccept} sx={{ flex: 2, padding: 3 }}>
                <Text sx={{ color: 'white', fontWeight: 'bold' }}>
                  <Localized id="cookie-banner-accept" />
                </Text>
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CookieBannerComponent
