import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import EversportsWhiteLogoIcon from '@eversports/design-tokens/assets/logo/icon-white.svg'

import { Image } from '../../../App.types'

export const TRIAL_CARD_LOGO_LENGTH = 100

const PlaceholderVenueLogo = () => (
  <Box
    sx={{
      width: `${TRIAL_CARD_LOGO_LENGTH}px`,
      height: `${TRIAL_CARD_LOGO_LENGTH}px`,
      borderRadius: '0px 8px 0px 0px',
      position: 'relative',
      right: '-16px',
      top: '-16px',
      backgroundColor: 'lightgray',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box sx={{ width: '50%', height: '50%' }}>
      <img src={EversportsWhiteLogoIcon} alt="Eversports Icon" loading="lazy" />
    </Box>
  </Box>
)

interface Props {
  logo: Image | null
}
const TrialCardVenueLogo = ({ logo }: Props) => {
  return logo && logo.xSmall ? (
    <img
      src={logo.xSmall}
      alt="Venue logo"
      style={{
        width: `${TRIAL_CARD_LOGO_LENGTH}px`,
        height: `${TRIAL_CARD_LOGO_LENGTH}px`,
        borderRadius: '0px 8px 0px 0px',
        position: 'relative',
        right: '-16px',
        top: '-16px',
        backgroundColor: '#f2f2f2',
        objectFit: 'cover',
      }}
    />
  ) : (
    <PlaceholderVenueLogo />
  )
}

export default TrialCardVenueLogo
