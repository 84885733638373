import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Dialog, { DialogTitle, DialogContent } from '@eversports/klimt-primitives/Dialog'
import Button from '@eversports/klimt-primitives/Button'
import Icon from '@eversports/klimt-primitives/Icon'
import FilterIcon from '@eversports/design-tokens/assets/icons/filter.svg'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Divider from '@eversports/klimt-primitives/Divider'
import Sticky from '@eversports/klimt-primitives/Sticky'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import { useDiscoverReducer, useDiscoverState } from '../DiscoverContext'
import { Localized } from '../../../localization/react'
import useGetTopContentHeight, { ContentType } from '../../../hooks/useGetTopContentHeight'
import { Sport } from '../../../App.types'
import { DiscoverySport, SportCategory, AvailableFiltersType } from '../Discover.types'

import {
  DesktopVenueOfferingsFilter,
  MobileVenueOfferingsFilter,
  MobileVenueOfferingsFilterDrawer,
} from './VenueOfferingsFilter'
import {
  DesktopVenueActivityAvailabilityFilter,
  MobileVenueActivityAvailabilityFilter,
  MobileVenueActivityAvailabilityFilterDrawer,
} from './VenueActivityAvailabilityFilter'
import { DesktopVenueAttributesFilter, MobileVenueAttributesFilter } from './VenueAttributesFilter'
import { MobileSubSportsFilterComponent, DesktopSubSportsFilterComponent } from './SubSportsFilter'
import ResetAllFilters from './ResetAllFilters'
import { DesktopVenueCourtAreaFilter, MobileVenueCourtAreaFilter } from './VenueCourtAreaFilter'
import { DesktopVenueCourtSurfaceFilter, MobileVenueCourtSurfaceFilter } from './VenueCourtSurfaceFilter'
import { DesktopVenueAmenitiesFilter, MobileVenueAmenitiesFilter } from './VenueAmenitiesFilter'
import { VenueCourtSlotDateFilter } from './VenueCourtSlotDateFilter'
import VenueCourtSlotTimeFilter from './VenueCourtSlotTimeFilter'
import FilterSkeleton, { FlickerLoading } from './FilterSkeleton'
import MobileMoreFilters from './MobileMoreFilters'

const hasNoFiltersInModalSelected = () => {
  const {
    venueAttributeActiveFilters,
    subSportsActiveFilters,
    venueCourtAreasActiveFilters,
    venueCourtSurfaceActiveFilters,
    venueAmenitiesActiveFilters,
    sport: discoverSport,
  } = useDiscoverState()

  if (discoverSport?.category !== SportCategory.FITNESS) {
    return !venueCourtAreasActiveFilters && !venueCourtSurfaceActiveFilters && !venueAmenitiesActiveFilters
  } else {
    return !venueAttributeActiveFilters && !subSportsActiveFilters
  }
}

interface Props {
  availableFilters: AvailableFiltersType
  availableSubSports?: Array<Sport>
  hasOfferings: boolean
  sport: DiscoverySport
}

const Filters = ({ availableSubSports, hasOfferings, sport, availableFilters }: Props) => {
  const topContentElements: Array<ContentType> = ['NAVIGATION_BAR']

  if (!topContentElements.some((element) => element === 'OFFERINGS') && hasOfferings) {
    topContentElements.push('OFFERINGS')
  }

  const { DESKTOP_TOP_CONTENT_HEIGHT } = useGetTopContentHeight(topContentElements)

  return (
    <Box
      sx={{
        top: { xs: 0, lg: DESKTOP_TOP_CONTENT_HEIGHT },
        position: 'sticky',
        zIndex: 5,
        py: { xs: 3, lg: 2 },
        px: { xs: 3, lg: 8 },
        boxShadow: boxShadows.default,
        backgroundColor: { xs: 'white', lg: 'eggshell' },
        height: { xs: '63px', lg: '55px' }, // prevent layout shift
        overflow: 'auto',
        whiteSpace: 'nowrap',
      }}
    >
      <MobileFilters
        availableSubSports={availableSubSports}
        category={sport.category}
        availableFilters={availableFilters}
      />
      <DesktopFilters
        availableSubSports={availableSubSports}
        category={sport.category}
        availableFilters={availableFilters}
      />
    </Box>
  )
}

interface FilterProps {
  availableFilters: AvailableFiltersType
  availableSubSports?: Array<Sport>
  category?: string
}

const MobileFilters = ({ availableFilters, availableSubSports, category }: FilterProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const dispatch = useDiscoverReducer()
  const { showFilterView } = useDiscoverState()

  const isDialogOpen = isMobile && showFilterView

  const { MOBILE_TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])

  const hasNoModalFiltersSelected = hasNoFiltersInModalSelected()

  const handleClick = () => {
    dispatch({ type: 'RESET_FILTERS' })
  }

  const toggleDialog = () => {
    dispatch({ type: 'TOGGLE_FILTER_VIEW' })
  }

  return (
    <Sticky boundaryEdge="top" offset={MOBILE_TOP_CONTENT_HEIGHT + 12} sx={{ display: { xs: 'block', lg: 'none' } }}>
      <Stack direction="row" gap={3} justifyContent="space-between" alignItems="center">
        {!category ? (
          <>
            <FilterSkeleton />
            <FilterSkeleton />
            <FlickerLoading
              sx={{
                border: '1px solid',
                borderColor: hasNoModalFiltersSelected ? 'mediumgray' : 'primary.main',
                borderRadius: 10,
                p: 2.5,
                ml: 'auto',
                cursor: 'pointer',
                height: 'min-content',
              }}
            >
              <Icon src={FilterIcon} size="small" color={hasNoModalFiltersSelected ? 'black' : 'primary'} />
            </FlickerLoading>
          </>
        ) : (category as SportCategory) !== SportCategory.FITNESS ? (
          <>
            <VenueCourtSlotDateFilter isMobile />
            <VenueCourtSlotTimeFilter isMobile />
            <MobileMoreFilters isActive={!hasNoModalFiltersSelected} openFilters={toggleDialog} />
          </>
        ) : (
          <>
            <MobileVenueOfferingsFilterDrawer />
            <MobileVenueActivityAvailabilityFilterDrawer />
            <MobileMoreFilters isActive={!hasNoModalFiltersSelected} openFilters={toggleDialog} />
          </>
        )}
      </Stack>
      <Dialog
        open={isDialogOpen}
        onClose={toggleDialog}
        fullScreen
        keepMounted
        sx={{ display: { xs: 'block', lg: 'none' } }}
      >
        <DialogTitle sx={{ border: '0.5px solid lightgray', boxShadow: '0px 1px 2px #DDDDDD', px: 3, py: 4 }}>
          <Stack direction="row" alignItems="center" spacing={6}>
            <Text
              variant="badge"
              sx={{
                color: 'darkgray',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                cursor: 'pointer',
                flex: 1,
              }}
              onClick={toggleDialog}
            >
              <Localized id="mobile-filters-cancel" />
            </Text>
            <Text variant="large" sx={{ fontWeight: 'bold', textAlign: 'center', flex: 1 }}>
              <Localized id="mobile-filters-heading" />
            </Text>
            <Text
              variant="badge"
              sx={{ color: 'primary.main', textAlign: 'right', cursor: 'pointer', textTransform: 'uppercase', flex: 1 }}
              onClick={handleClick}
            >
              <Localized id="mobile-filters-reset" />
            </Text>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ p: 0, backgroundColor: 'eggshell' }}>
          <Stack spacing="1px">
            {category !== SportCategory.FITNESS ? (
              <>
                <MobileVenueCourtAreaFilter />
                <MobileVenueCourtSurfaceFilter availableSurfaces={availableFilters.surfaces} />
                <MobileVenueAmenitiesFilter />
                <MobileVenueAttributesFilter />
              </>
            ) : (
              <>
                <MobileVenueOfferingsFilter />
                <MobileVenueActivityAvailabilityFilter />
                <MobileVenueAttributesFilter />
                {availableSubSports && availableSubSports.length > 0 && (
                  <MobileSubSportsFilterComponent availableSubSports={availableSubSports} />
                )}
              </>
            )}
          </Stack>
        </DialogContent>
        <Box
          sx={{
            border: '1px solid lightgray',
            boxSizing: 'border-box',
            boxShadow: '0px -1px 2px rgba(0, 0, 0, 0.12)',
            borderRadius: '16px 16px 0px 0px',
            width: '100%',
            px: 2,
            py: 3,
            backgroundColor: 'white',
          }}
        >
          <Button variant="primary" onClick={toggleDialog} fullWidth sx={{ px: 5, py: 3 }}>
            <Text variant="large" sx={{ fontWeight: 'bold', color: 'white' }}>
              <Localized id="mobile-filters-apply-filters" />
            </Text>
          </Button>
        </Box>
      </Dialog>
    </Sticky>
  )
}

const DesktopFilters = ({ availableFilters, availableSubSports, category }: FilterProps) => {
  const { showFilterView } = useDiscoverState()
  return (
    <Stack direction="row" gap={4} display={{ xs: 'none', lg: 'flex' }}>
      {!category ? (
        <>
          <FilterSkeleton />
          <FilterSkeleton />
          <FilterSkeleton />
          <FilterSkeleton />
        </>
      ) : (category as SportCategory) !== SportCategory.FITNESS ? (
        <>
          <VenueCourtSlotDateFilter />
          <VenueCourtSlotTimeFilter />
          <Divider orientation="vertical" variant="middle" flexItem />
          <DesktopVenueCourtAreaFilter isDefaultOpen={showFilterView} />
          <DesktopVenueCourtSurfaceFilter availableSurfaces={availableFilters.surfaces} />
          <DesktopVenueAmenitiesFilter />
          <DesktopVenueAttributesFilter />
        </>
      ) : (
        <>
          <DesktopVenueOfferingsFilter isDefaultOpen={showFilterView} />
          <DesktopVenueActivityAvailabilityFilter />
          <DesktopVenueAttributesFilter />
          {availableSubSports && availableSubSports.length > 0 && (
            <DesktopSubSportsFilterComponent availableSubSports={availableSubSports} />
          )}
        </>
      )}

      <ResetAllFilters />
    </Stack>
  )
}

export default React.memo(Filters)
