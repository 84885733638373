import { DiscoverMatchesFiltersState } from '../DiscoverSportMatches.types'
import { MatchCompetitionType, MatchLevel } from '../../../../../App.types'

interface UpdateDiscoverMatchesSportsFilter {
  type: 'UPDATE_DISCOVER_MATCHES_SPORTS_FILTER'
  payload?: Array<string>
}

interface UpdateDiscoverMatchesDateFilter {
  type: 'UPDATE_DISCOVER_MATCHES_DATE_FILTER'
  payload: Date
}

interface UpdateDiscoverMatchesVenuesFilter {
  type: 'UPDATE_DISCOVER_MATCHES_VENUES_FILTER'
  payload?: Array<string>
}

interface UpdateDiscoverMatchesLevelsFilter {
  type: 'UPDATE_DISCOVER_MATCHES_LEVELS_FILTER'
  payload?: Array<MatchLevel>
}

interface UpdateDiscoverMatchesCompetitionTypeFilter {
  type: 'UPDATE_DISCOVER_MATCHES_COMPETITION_TYPE_FILTER'
  payload?: Array<MatchCompetitionType>
}

interface ResetMatchesFilters {
  type: 'RESET_DISCOVER_MATCHES_FILTERS'
}

export type Action =
  | UpdateDiscoverMatchesSportsFilter
  | UpdateDiscoverMatchesDateFilter
  | UpdateDiscoverMatchesVenuesFilter
  | UpdateDiscoverMatchesLevelsFilter
  | UpdateDiscoverMatchesCompetitionTypeFilter
  | ResetMatchesFilters

const discoverMatchesFiltersReducer = (
  state: DiscoverMatchesFiltersState,
  action: Action,
): DiscoverMatchesFiltersState => {
  switch (action.type) {
    case 'UPDATE_DISCOVER_MATCHES_SPORTS_FILTER':
      return { ...state, sports: action.payload }
    case 'UPDATE_DISCOVER_MATCHES_DATE_FILTER':
      return { ...state, date: action.payload }
    case 'UPDATE_DISCOVER_MATCHES_VENUES_FILTER':
      return { ...state, venues: action.payload }
    case 'UPDATE_DISCOVER_MATCHES_LEVELS_FILTER':
      return { ...state, levels: action.payload }
    case 'UPDATE_DISCOVER_MATCHES_COMPETITION_TYPE_FILTER':
      return { ...state, competitionTypes: action.payload }
    case 'RESET_DISCOVER_MATCHES_FILTERS':
      return { sports: [], venues: [], levels: [], competitionTypes: [], date: null }
    default:
      return state
  }
}

export default discoverMatchesFiltersReducer
