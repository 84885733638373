import { ScheduleSession } from '../../../ActivityDetails.types'

import { isEventInFuture } from './is-event-in-future'

const sortByDate = (a: ScheduleSession, b: ScheduleSession) => {
  const startA = new Date(a.start)
  const startB = new Date(b.start)
  return startA.getTime() - startB.getTime()
}

export const sortSessions = (sessions: Array<ScheduleSession>) => {
  const futureSessions = sessions.filter((session) => isEventInFuture(session.start))
  const pastSessions = sessions.filter((session) => !isEventInFuture(session.start))

  return [...futureSessions.sort(sortByDate), ...pastSessions.sort(sortByDate)]
}
