import Text from '@eversports/klimt-primitives/Text'
import * as React from 'react'

const OverflowText = ({
  children,
  isUnderlined = false,
  onClick,
}: React.PropsWithChildren<{ isUnderlined?: boolean; onClick?: () => void }>) => (
  <Text
    onClick={onClick}
    sx={{
      textDecoration: isUnderlined ? 'underline' : 'none',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }}
  >
    {children}
  </Text>
)

export default OverflowText
