import { breakpoints, BreakpointType } from '@eversports/design-tokens/breakpoints'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

/**
 * Returns the current breakpoint region for the screen width.
 * Determines this with MUI's useMediaQuery function, and the project's defined breakpoints.
 * Good to use when using the breakpoints as keys in an object, and needing to access said value.
 */
const useMediaQueryBreakpoint = (): BreakpointType | undefined => {
  const theme = useTheme()

  const isXs = useMediaQuery(theme.breakpoints.down('sm'))
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'))
  const isXl = useMediaQuery(theme.breakpoints.up('xl'))

  const breakPointExistence: { [key in BreakpointType]: boolean } = {
    xs: isXs,
    sm: isSm,
    md: isMd,
    lg: isLg,
    xl: isXl,
  }

  const appBreakpoints = Object.keys(breakpoints) as Array<BreakpointType>

  const screenBreakpoint = appBreakpoints.find((bp) => breakPointExistence[bp])

  return screenBreakpoint
}

export default useMediaQueryBreakpoint
