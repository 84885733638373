import * as React from 'react'
import { default as MuiRadioGroup, RadioGroupProps } from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

export interface Props extends RadioGroupProps {
  label?: string
}

const RadioGroup: React.FC<React.PropsWithChildren<Props>> = ({ label, ...props }) => {
  return (
    <FormControl component="fieldset" fullWidth>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <MuiRadioGroup {...props} />
    </FormControl>
  )
}

export default RadioGroup
