import * as React from 'react'
import { useState } from 'react'
import Processing from '@eversports/klimt-primitives/Processing'
import Icon from '@eversports/klimt-primitives/Icon'
import CloseIcon from '@eversports/design-tokens/assets/icons/close.svg'
import Box from '@eversports/klimt-primitives/Box'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { useCancelFriendRequestCancelFriendRequestMutation } from '../../../../../../../graphql'
import { Localized } from '../../../../../../../localization/react'
import ConfirmationDisclaimer from '../../../../../components/ConfirmationDisclaimer'

interface Props {
  friendId: string
}

const CancelFriendRequest = ({ friendId }: Props) => {
  const { amplitude } = useAmplitude()
  const [isOpen, setIsOpen] = useState(false)
  const [cancelFriendRequest, { loading }] = useCancelFriendRequestCancelFriendRequestMutation({
    variables: { userId: friendId },
  })

  const toggleDisclaimer = () => {
    setIsOpen(!isOpen)
  }

  const cancelRequest = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Confirm Remove Sent Friend Request')
    }
    toggleDisclaimer()
    void cancelFriendRequest()
  }

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Cancel Friend Request Button')
    }
    toggleDisclaimer()
  }

  const handleDismiss = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Dismiss Remove Sent Friend Request')
    }
    toggleDisclaimer()
  }

  return (
    <>
      <Processing isLoading={loading}>
        <Box sx={{ cursor: 'pointer', flexShrink: 0 }} onClick={handleClick}>
          <Icon size="default" color="gray" src={CloseIcon} />
        </Box>
      </Processing>
      <ConfirmationDisclaimer
        title={<Localized id="confirmation-disclaimer-requested-friendship-title" />}
        description={<Localized id="confirmation-disclaimer-requested-friendship-description" />}
        confirmationText={<Localized id="confirmation-disclaimer-requested-friendship-cancel-request" />}
        dismissText={<Localized id="confirmation-disclaimer-requested-friendship-dismiss" />}
        onConfirm={cancelRequest}
        onDismiss={handleDismiss}
        onClose={toggleDisclaimer}
        isOpen={isOpen}
      />
    </>
  )
}

export default CancelFriendRequest
