import * as React from 'react'
import { useForm } from '@eversports/klimt-forms/Form'
import InviteFriendsComponent from '@eversports/user-components/InviteFriends'
import NoFriendsPlaceholder from '@eversports/user-components/InviteFriends/NoFriendsPlaceholder'
import FriendsLoadingPlaceholder from '@eversports/user-components/InviteFriends/FriendsLoadingPlaceholder'
import { assertNever } from '@eversports/klimt-utilities/assert-never'
import SuccessfullyInvitedFriends from '@eversports/user-components/InviteFriends/SuccessfullyInvitedFriends'
import { useEffect, useState } from 'react'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { From } from '../../App.types'
import {
  useInviteFriendsMeLazyQuery,
  useInviteFriendsMeQuery,
  useInviteFriendsShareEventMutation,
  useInviteFriendsShareMatchMutation,
  useInviteFriendsShareVenueMutation,
} from '../../graphql'

import { FriendsToInviteForm, InviteFriends as InviteFriendsType } from './InviteFriends.types'
import { NUMBER_OF_INITIAL_FRIENDS_FETCHED } from './InviteFriends.constants'

const InviteFriends = (props: InviteFriendsType) => {
  const { amplitude } = useAmplitude()
  const [hasInvitedFriends, setHasInvitedFriends] = useState(false)
  const [getFriends, { data, called, loading }] = useInviteFriendsMeLazyQuery()
  const { data: initialData, loading: initialLoading } = useInviteFriendsMeQuery({
    variables: { first: NUMBER_OF_INITIAL_FRIENDS_FETCHED },
  })

  useEffect(() => {
    if (!amplitude || !initialData?.me) return
    amplitude.logEvent('Viewed Share With Friends', {
      numberOfFriends: initialData.me.friendsCount,
      type: props.type,
    })
  }, [initialData])

  const handleComplete = () => {
    setHasInvitedFriends(true)
  }

  const [inviteFriendsToEvent, { loading: loadingEventMutation }] = useInviteFriendsShareEventMutation({
    onCompleted: handleComplete,
  })
  const [inviteFriendsToMatch, { loading: loadingMatchMutation }] = useInviteFriendsShareMatchMutation({
    onCompleted: handleComplete,
  })
  const [inviteFriendsToVenue, { loading: loadingVenueMutation }] = useInviteFriendsShareVenueMutation({
    onCompleted: handleComplete,
  })

  const methods = useForm<FriendsToInviteForm>({
    defaultValues: { friendsToInvite: [] },
  })

  if (!initialData || initialLoading) {
    return <FriendsLoadingPlaceholder numberOfSkeletons={NUMBER_OF_INITIAL_FRIENDS_FETCHED} />
  }

  if (initialData && initialData.me && !initialData.me.friends.edges.length) {
    const to = `/user/${initialData.me.handle}/friends?from=${From.SHARE_WITH_FRIENDS_PLACEHOLDER}`
    return <NoFriendsPlaceholder to={to} />
  }

  if (!initialData?.me) {
    return null
  }

  if (hasInvitedFriends) {
    return <SuccessfullyInvitedFriends />
  }

  const handleFetchFriends = (args: { after?: string }) => {
    void getFriends({
      variables: {
        first: NUMBER_OF_INITIAL_FRIENDS_FETCHED,
        after: args.after,
      },
    })
  }

  const handleSubmit = () => {
    const userIds = methods.getValues('friendsToInvite')

    switch (props.type) {
      case 'match':
        void inviteFriendsToMatch({ variables: { matchId: props.matchId, userIds } })
        break
      case 'venue':
        void inviteFriendsToVenue({ variables: { venueId: props.venueId, userIds } })
        break
      case 'event':
        void inviteFriendsToEvent({ variables: { eventId: props.eventId, userIds } })
        break
      default:
        return assertNever(props)
    }

    if (amplitude) {
      amplitude.logEvent('Clicked Share With Friends Button', {
        numberOfSelectedFriends: userIds.length,
        type: props.type,
      })
    }
  }

  const fetchedFriends = !called ? initialData.me.friends : data?.me?.friends

  return (
    <InviteFriendsComponent
      fetchFriends={handleFetchFriends}
      fetchedFriends={fetchedFriends}
      formMethods={methods}
      isLoading={loading}
      isSubmitting={loadingMatchMutation || loadingVenueMutation || loadingEventMutation}
      onSubmit={handleSubmit}
    />
  )
}

export default InviteFriends
