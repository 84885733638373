import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import { useLocation } from 'react-router-dom'
import Box from '@eversports/klimt-primitives/Box'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { useIntl } from '../../../../../localization/react'
import { createIcsUrl } from '../../../../../helpers/create-ics-url'
import { EventBookableItem, EventSession } from '../../../ActivityDetails.types'
import { formatLocation } from '../../../helpers/format-location'

import AddToCalendar from './assets/calendarAdd.svg'

type EventProps = Pick<EventBookableItem, 'activityName' | 'eventType'>

const CalendarButton = ({
  activityName,
  sportName,
  eventType,
  eventSession,
}: EventProps & { sportName: string; eventSession: EventSession }) => {
  const { language, origin } = useSettingsContext()
  const { amplitude } = useAmplitude()
  const location = useLocation()
  const intl = useIntl()

  const activityUrl = new URL(location.pathname, origin).href
  const description = intl.activityDetailsCalendarDescription({ activityUrl })
  const summary = intl.activityDetailsCalendarSummary({ sportName, eventType, activityName })

  const { start, end, location: sessionLocation } = eventSession

  const address = eventSession.hasOnlineStream ? 'online' : sessionLocation ? formatLocation(sessionLocation) : ''

  const icsUrl = createIcsUrl({
    summary,
    start,
    end,
    address,
    description,
  })

  // Format download file name
  const startDate = new Date(start)
  const localShortDate = startDate.toLocaleDateString(language, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Add to Calendar', {
        from: 'activity detail',
      })
    }

    const fileName = `${activityName}_${localShortDate}`
    // Download the file this way instead of window.open() to avoid adblockers
    const link = document.createElement('a')
    link.download = fileName
    link.href = encodeURI(`data:text/calendar;charset=utf8,${icsUrl}`)
    link.click()
    link.remove()
  }

  return (
    <Box sx={{ width: '40px' }}>
      <Button
        onClick={handleClick}
        variant="tertiary"
        sx={{ padding: 3, height: 'fit-content', flexShrink: 0, minWidth: '0px' }}
      >
        <img src={AddToCalendar} width="12px" height="12px" alt={intl.addToCalendarAltText()} />
      </Button>
    </Box>
  )
}

export default CalendarButton
