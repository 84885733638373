import * as React from 'react'
import Dialog from '@eversports/klimt-primitives/Dialog'
import Box from '@eversports/klimt-primitives/Box'
import Close from '@eversports/design-tokens/assets/icons/close.svg'
import Icon from '@eversports/klimt-primitives/Icon'
import Stack from '@eversports/klimt-primitives/Stack'

interface Props {
  handleClose: () => void
}

// Ensure the Close Button is aligned with the Right Arrow
// Close on click anywhere in this "top black background" above the image.
const CloseButton = ({ handleClose }: Props) => (
  <Stack flexDirection="row" onClick={handleClose}>
    <Box sx={{ flexBasis: { xs: '85%', sm: '90%' } }} />
    <Box
      sx={{
        flexBasis: { xs: '15%', sm: '10%' },
        cursor: 'pointer',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Icon src={Close} color="white" size="large" />
    </Box>
  </Stack>
)

interface ContainerProps extends Props {
  isOpen: boolean
}

const LightboxContainer: React.FC<React.PropsWithChildren<ContainerProps>> = ({ isOpen, handleClose, children }) => {
  return (
    <Dialog
      open={isOpen}
      sx={{ backgroundColor: 'rgba(0,0,0,.9)' }}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          margin: '0',
          maxHeight: 'calc(100% - 32px)', // overwrite MUI calc that uses 64px
        },
      }}
    >
      <CloseButton handleClose={handleClose} />
      {children}
    </Dialog>
  )
}

export default LightboxContainer
