import * as React from 'react'
import Avatar from '@eversports/klimt-primitives/Avatar'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import ExpandableContent from '@eversports/klimt-patterns/ExpandableContent'

import { Trainer } from '../../ActivityDetails.types'
import DefaultAvatar from '../../assets/avatar.svg'
import { useIntl } from '../../../../localization/react'

import { TRAINER_CARD_WIDTH } from './Trainer.constants'

const MAX_HEIGHT_TRAINER_INFO = 38

interface Props {
  trainer: Trainer
  handleCardClick: (trainerId: string, hasMoreInfo: boolean) => () => void
}

const TrainerCard = ({ trainer, handleCardClick }: Props) => {
  const intl = useIntl()

  const { id, nickname, description, image, education } = trainer
  const hasMoreInfo = Boolean(description) || Boolean(education)

  return (
    <Stack
      key={id}
      onClick={handleCardClick(id, hasMoreInfo)}
      direction="row"
      alignItems="center"
      gap={3}
      sx={{
        border: '1px solid',
        borderColor: 'mediumgray',
        borderRadius: 1,
        paddingX: 4,
        paddingY: 3,
        cursor: hasMoreInfo ? 'pointer' : 'default',
        width: `${TRAINER_CARD_WIDTH}px`,
        flexShrink: 0,
      }}
    >
      <Avatar
        src={image?.xSmall ?? DefaultAvatar}
        alt={intl.activityDetailsTeacherLabel()}
        sx={{ width: { xs: '50px', lg: '60px' }, height: { xs: '50px', lg: '60px' } }}
      />
      <Stack>
        <Text color="midnight" sx={{ fontWeight: 'bold' }}>
          {nickname}
        </Text>
        {hasMoreInfo && (
          <ExpandableContent clampedTextHeight={MAX_HEIGHT_TRAINER_INFO} isDisabled>
            <Text>{description ?? education}</Text>
          </ExpandableContent>
        )}
      </Stack>
    </Stack>
  )
}

export default TrainerCard
