import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Box from '@eversports/klimt-primitives/Box'
import Icon from '@eversports/klimt-primitives/Icon'
import InstagramIcon from '@eversports/design-tokens/assets/icons/instagram.svg'
import FacebookIcon from '@eversports/design-tokens/assets/icons/facebook.svg'
import EmailIcon from '@eversports/design-tokens/assets/icons/email.svg'
import WebsiteIcon from '@eversports/design-tokens/assets/icons/website.svg'
import TelephoneIcon from '@eversports/design-tokens/assets/icons/telephone.svg'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

interface ContactIconWrapperProps {
  handleClick: () => void
}

const ContactIconWrapper: React.FC<React.PropsWithChildren<ContactIconWrapperProps>> = ({ children, handleClick }) => (
  <Box
    onClick={handleClick}
    sx={{
      py: 3,
      px: 4,
      backgroundColor: 'rgba(33, 166, 150, 0.08)',
      borderRadius: 1,
      cursor: 'pointer',
      '&:hover': { backgroundColor: 'rgba(33, 166, 150, 0.16)' },
    }}
  >
    {children}
  </Box>
)

interface Props {
  type: 'instagram' | 'facebook' | 'email' | 'website' | 'telephone'
  to: string
}

const iconMapping = {
  instagram: InstagramIcon,
  facebook: FacebookIcon,
  website: WebsiteIcon,
}

const ContactIcon = ({ type, to }: Props) => {
  const { amplitude } = useAmplitude()

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Venue Contact Link', {
        contact: {
          type,
        },
      })
    }
  }

  switch (type) {
    case 'instagram':
    case 'facebook':
    case 'website':
      return (
        <Link to={to} external blank ariaLabel={`view venue's ${type} page`}>
          <ContactIconWrapper handleClick={handleClick}>
            <Icon src={iconMapping[type]} color="primary" size="small" />
          </ContactIconWrapper>
        </Link>
      )
    case 'email':
      return (
        <Link to={`mailto: ${to}`} external blank ariaLabel="email venue">
          <ContactIconWrapper handleClick={handleClick}>
            <Icon src={EmailIcon} color="primary" size="small" />
          </ContactIconWrapper>
        </Link>
      )
    case 'telephone':
      return (
        <Link to={`tel:${to}`} external blank ariaLabel="call venue">
          <ContactIconWrapper handleClick={handleClick}>
            <Icon src={TelephoneIcon} color="primary" size="small" />
          </ContactIconWrapper>
        </Link>
      )
    default:
      return null
  }
}

export default ContactIcon
