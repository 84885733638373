import { useState, useEffect } from 'react'

import { useUseReviewsVenueQuery } from '../../../../../graphql'
import { Review } from '../../../ActivityDetails.types'

const REVIEWS_TO_QUERY = 10

interface Props {
  venueId: string
}

const useVenueReviews = ({ venueId }: Props) => {
  const [allReviews, setAllReviews] = useState<Array<Review>>([])

  const { data, refetch } = useUseReviewsVenueQuery({
    variables: { id: venueId, first: REVIEWS_TO_QUERY },
  })

  const hasMoreReviewsToFetch = Boolean(data?.venue.reviews.pageInfo.hasNextPage)

  const fetchMoreReviews = (cursor?: string | null) => {
    if (cursor === undefined) return
    void refetch({ first: REVIEWS_TO_QUERY, after: cursor })
  }

  useEffect(() => {
    if (data && data.venue) {
      const newReviews = data.venue.reviews.edges.map(({ node, cursor }) => ({ ...node, cursor }))
      setAllReviews((prevReviews) => (prevReviews ? [...prevReviews, ...newReviews] : newReviews))
    }
  }, [data])

  return {
    allReviews,
    fetchMoreReviews,
    hasMoreReviewsToFetch,
    newQueryData: data?.venue,
  }
}

export default useVenueReviews
