import { assertNever } from '@eversports/klimt-utilities/assert-never'

import { MatchCompetitionType, MatchGender, MatchLevel } from '../../../Match.types'
import { MatchSettingsState } from '../MatchSettings.types'

interface UpdateMatchLevel {
  type: 'UPDATE_MATCH_LEVEL'
  payload: MatchLevel | null
}

interface UpdateMatchCompetitionType {
  type: 'UPDATE_MATCH_COMPETITION_TYPE'
  payload: MatchCompetitionType | null
}

interface UpdateMatchPlayerGender {
  type: 'UPDATE_MATCH_PLAYER_GENDER'
  payload: MatchGender | null
}

interface UpdateMatchMaxPlayerCount {
  type: 'UPDATE_MATCH_MAX_PLAYER_COUNT'
  payload: number | null
}

interface ResetMatchSettings {
  type: 'RESET_MATCH_SETTINGS'
  payload: MatchSettingsState
}

export type Action =
  | UpdateMatchLevel
  | UpdateMatchCompetitionType
  | UpdateMatchPlayerGender
  | UpdateMatchMaxPlayerCount
  | ResetMatchSettings

const matchSettingsReducer = (state: MatchSettingsState, action: Action): MatchSettingsState => {
  switch (action.type) {
    case 'UPDATE_MATCH_LEVEL':
      return { ...state, level: action.payload }
    case 'UPDATE_MATCH_COMPETITION_TYPE':
      return { ...state, competitionType: action.payload }
    case 'UPDATE_MATCH_PLAYER_GENDER':
      return { ...state, gender: action.payload }
    case 'UPDATE_MATCH_MAX_PLAYER_COUNT':
      return { ...state, maxParticipants: action.payload }
    case 'RESET_MATCH_SETTINGS':
      return { ...action.payload }
    default:
      return assertNever(action)
  }
}

export default matchSettingsReducer
