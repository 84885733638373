import { ApolloQueryResult } from '@apollo/client'
import { useEffect, useState } from 'react'

import { Teacher } from '../../../../App.types'
import { UseRecommendationsTeachersMeQuery, useUseRecommendationsTeachersMeQuery } from '../../../../graphql'

interface Result {
  teachers: Array<Teacher>
  loading: boolean
  fetchMore: () => Promise<ApolloQueryResult<UseRecommendationsTeachersMeQuery>>
  hasMoreResults: boolean
}

export const NUMBER_OF_INITIAL_TEACHER_CARDS_FETCHED = 12

const useRecommendationsTeachers = (): Result => {
  const [teachers, setTeachers] = useState<Array<Teacher> | null>(null)
  const { data, loading, refetch } = useUseRecommendationsTeachersMeQuery({
    variables: {
      first: NUMBER_OF_INITIAL_TEACHER_CARDS_FETCHED,
    },
  })

  useEffect(() => {
    const fetchedTeachers = data?.me?.recommendation?.recentTrainers.edges.map(({ node }) => node) || []
    const newTeachers = teachers ? [...teachers, ...fetchedTeachers] : fetchedTeachers
    setTeachers(newTeachers)
  }, [data])

  const initiallyFetchedTeachers = data?.me?.recommendation?.recentTrainers.edges.map(({ node }) => node) || []
  const hasMoreResults = Boolean(data?.me?.recommendation?.recentTrainers.pageInfo.hasNextPage)
  const indexOfLastTeacher = data?.me?.recommendation?.recentTrainers
    ? data.me.recommendation.recentTrainers.edges.length - 1
    : 0
  const cursor = data?.me?.recommendation?.recentTrainers.edges[indexOfLastTeacher]?.cursor

  const fetchMore = async () =>
    refetch({
      after: cursor,
    })

  return { loading, fetchMore, teachers: !teachers ? initiallyFetchedTeachers : teachers, hasMoreResults }
}

export default useRecommendationsTeachers
