import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

const VenueProfileSectionWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Stack
      gap={8}
      sx={{
        px: 4,
        py: 10,
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'lightgray',
        boxShadow: boxShadows.default,
        borderRadius: 1,
        width: '100%',
      }}
    >
      {children}
    </Stack>
  )
}

export default VenueProfileSectionWrapper
