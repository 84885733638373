// for the filters that have drawers
export enum FilterKey {
  VENUE_OFFERINGS = 'venueOfferingsActiveFilters',
  VENUE_ACTIVITY_AVAILABILITY = 'venueActivityAvailabilityActiveFilters',
  VENUE_ATTRIBUTES = 'venueAttributeActiveFilters',
  VENUE_AMENITIES = 'venueAmenitiesActiveFilters',
}

export const filterHeadingMapping = {
  VENUE_OFFERINGS: 'filters-studio-activities-heading',
  VENUE_ACTIVITY_AVAILABILITY: 'filters-activities-availability-heading',
  VENUE_ATTRIBUTES: 'filters-studio-attributes-heading',
  VENUE_AMENITIES: 'filters-amenities-heading',
} as const
