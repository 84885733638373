import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import Svg from '@eversports/klimt-primitives/Svg'

import { Localized } from '../../../../../../localization/react'

import { ParticipationState } from './FriendsUpcomingParticipations.types'
import { participationStateTranslation } from './FriendsUpcomingParticipations.constants'
import ParticipationStateCheckoutMark from './assets/participation-state-checkmark.svg'

interface Props {
  state: ParticipationState
}

const FriendUpcomingParticipationState = ({ state }: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: 1,
        paddingY: 1,
        paddingX: 2,
        width: 'fit-content',
      }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Svg width={12} height={12} color="primary" src={ParticipationStateCheckoutMark} />
        <Text variant="badge" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          <Localized id={participationStateTranslation[state]} />
        </Text>
      </Stack>
    </Box>
  )
}

export default FriendUpcomingParticipationState
