import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import SeoLink from '@eversports/klimt-primitives/SeoLink'

import { TagBadge } from '../../../Discover/Listing/Badge'
import { Tag, VenueProfileTabsPrefix } from '../../../../App.types'

interface Props {
  tags: Array<Tag>
  slug: string
}

// once we have the new prices tab, this doesn't have to be external
const TrialOfferTags = ({ tags, slug }: Props) => (
  <Stack component="ul" paddingLeft={0} direction="row" spacing={2}>
    {tags.map(({ name }) => (
      <Box key={name} component="li" display="block">
        <SeoLink encodedTo={btoa(`${VenueProfileTabsPrefix.PRICES}${slug}`)} external type="hidden">
          <TagBadge key={name}>{name}</TagBadge>
        </SeoLink>
      </Box>
    ))}
  </Stack>
)

export default TrialOfferTags
