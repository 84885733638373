import { Marker } from '../Map.types'

import createMapMarker from './create-map-marker'

interface Props {
  markers: Array<Marker>
  map: google.maps.Map
  clickable?: boolean
}

const createMapMarkers = ({ map, markers, clickable = true }: Props) => {
  for (const marker of markers) {
    createMapMarker({ map, marker, type: 'default', clickable })
  }
}

export default createMapMarkers
