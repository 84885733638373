import { MatchSetting } from './MatchSetting.types'
import GraphIcon from './assets/graph.svg'
import TrophyIcon from './assets/trophy.svg'
import UsersIcon from './assets/users.svg'

export const matchSettingIconTranslation = {
  [MatchSetting.LEVEL]: 'match-rule-level-placeholder',
  [MatchSetting.COMPETITION_TYPE]: 'match-rule-competition-type-placeholder',
  [MatchSetting.GENDER]: 'match-rule-gender-placeholder',
} as const

export const matchSettingIcon = {
  [MatchSetting.LEVEL]: GraphIcon,
  [MatchSetting.COMPETITION_TYPE]: TrophyIcon,
  [MatchSetting.GENDER]: UsersIcon,
} as const
