import * as React from 'react'
import Avatar from '@eversports/klimt-primitives/Avatar'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import EversportsLogoIcon from '@eversports/design-tokens/assets/logo/icon.svg'

import { Venue } from '../../../PublicUserProfile.types'

interface Props {
  venue: Venue
}

const ParticipationVenueInfo = ({ venue }: Props) => {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Avatar
        src={venue.logo?.medium || EversportsLogoIcon}
        sx={{
          height: '33px',
          width: '33px',
          border: '1px solid',
          borderColor: 'mediumgray',
          backgroundColor: 'white',
          borderRadius: 10,
        }}
      />
      <Stack gap={1}>
        <Text variant="small">{venue.name}</Text>
        <Text variant="xsmall">
          {venue.location.zip}, {venue.location.city}
        </Text>
      </Stack>
    </Stack>
  )
}

export default ParticipationVenueInfo
