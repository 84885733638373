import * as React from 'react'
import Avatar from '@eversports/klimt-primitives/Avatar'

import AddFriendsPlaceholderIcon from './assets/add-friends-placeholder.svg'

interface Props {
  index: number
}

const PlaceholderFriend = ({ index }: Props) => {
  return (
    <Avatar
      src={AddFriendsPlaceholderIcon}
      sx={{
        height: `24px`,
        width: `24px`,
        border: '1px solid',
        borderColor: 'white',
        backgroundColor: 'mediumgray',
        marginLeft: index === 0 ? 0 : -1,
        borderRadius: 10,
        padding: 1,
      }}
    />
  )
}

export default PlaceholderFriend
