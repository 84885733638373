import * as React from 'react'
import Skeleton from '@eversports/klimt-primitives/Skeleton'
import Stack from '@eversports/klimt-primitives/Stack'

import ListingCard from '../ListingCard'

import {
  DESKTOP_VENUE_IMAGE_HEIGHT,
  DESKTOP_VENUE_IMAGE_WIDTH,
  MOBILE_VENUE_IMAGE_HEIGHT,
  MOBILE_VENUE_IMAGE_WIDTH,
} from './ListingResult.constants'

interface Props {
  numberOfCopies: number
}

const DesktopSkeletonListingResult = () => {
  return (
    <Stack direction="row" spacing={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
      <Skeleton
        variant="rectangular"
        sx={{
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
          minHeight: DESKTOP_VENUE_IMAGE_HEIGHT,
          minWidth: DESKTOP_VENUE_IMAGE_WIDTH,
        }}
      />
      <Stack spacing={3} sx={{ padding: '8px' }}>
        <Skeleton variant="text" height="28px" width="156px" />
        <Skeleton variant="text" height="24px" width="128px" />
        <Skeleton variant="text" height="24px" width="128px" />
        <Skeleton variant="text" height="20px" width="128px" />
      </Stack>
    </Stack>
  )
}

const MobileSkeletonListingResult = () => {
  return (
    <Stack sx={{ display: { xs: 'flex', sm: 'none' } }}>
      <Skeleton
        variant="rectangular"
        sx={{
          height: MOBILE_VENUE_IMAGE_HEIGHT,
          width: MOBILE_VENUE_IMAGE_WIDTH,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          position: 'relative',
          overflow: 'hidden',
        }}
      />
      <Stack gap={2} sx={{ overflow: 'auto', width: '100%', p: 3 }}>
        <Stack gap={3}>
          <Skeleton variant="text" height="20px" width="156px" />
          <Skeleton variant="text" height="20px" width="128px" />
        </Stack>
        <Skeleton variant="text" height="28px" width="50%" />
        <Skeleton variant="text" height="28px" width="50%" />
      </Stack>
    </Stack>
  )
}

const SkeletonListingResult = ({ numberOfCopies = 1 }: Props) => {
  const filterCards = [...Array(numberOfCopies)]
  return (
    <>
      {filterCards.map((_, i) => (
        <ListingCard key={i}>
          <DesktopSkeletonListingResult />
          <MobileSkeletonListingResult />
        </ListingCard>
      ))}
    </>
  )
}

export default SkeletonListingResult
