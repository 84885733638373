import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'

import { Localized } from '../../localization/react'
import ContainedXLWidthContainer from '../../components/ContainedXLWidthContainer'

import YogaTennisWomanEmoji from './assets/yoga-tennis-woman-emoji.svg'
import WomanYogaPoseImage from './assets/woman-yoga-pose-image.webp'

const RecommendationsHero = () => {
  return (
    <ContainedXLWidthContainer>
      <Box
        sx={{
          position: 'absolute',
          left: '0px',
          height: '347px',
          width: '100vw',
          background: 'linear-gradient(180deg, rgba(119, 0, 238, 0.04) 0%, rgba(119, 0, 238, 0) 100%)',
          zIndex: 0,
        }}
      />
      <Stack
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'center', md: 'inherit' }}
        width="100%"
        gap={5}
        px={{ xs: 4, md: 8 }}
      >
        <Stack
          spacing={2}
          sx={{ maxWidth: '412px', my: { xs: 0, md: 'auto' }, flexBasis: { md: '50%', lg: '45%', xl: 'inherit' } }}
        >
          <img
            src={YogaTennisWomanEmoji}
            alt="Yoga Tennis Woman Emoji"
            width={68}
            height={68}
            style={{ marginLeft: '-8px', marginBottom: '-20px' }}
          />
          <Text
            sx={{
              fontSize: { xs: '26px', md: '36px', lg: '48px' },
              lineHeight: { xs: '40px', md: '52px', lg: '72px' },
              fontWeight: { xs: 'bold', md: 'bolder' },
              maxWidth: { xs: '220px', md: 'inherit' },
            }}
          >
            <Localized id="recommendations-hero-title" />
          </Text>
          <Text variant="large" color="darkgray">
            <Localized id="recommendations-hero-subtitle" />
          </Text>
        </Stack>
        <Box
          sx={{
            borderRadius: '0px 0px 16px 80px',
            width: { md: '475px', lg: '785px' },
            height: { md: '280px', lg: '450px' },
            ml: { xs: -2, sm: 0 },
            mr: { xs: 0, lg: 2.5 },
          }}
        >
          <img
            src={WomanYogaPoseImage}
            alt="Woman in Yoga Pose"
            style={{
              objectFit: 'cover',
              width: '100%',
              maxHeight: '450px',
              borderRadius: '0px 0px 16px 80px',
              transform: 'matrix(1, 0, -0.07, 1, 0, 0)',
              boxShadow: '0px 5px 20px 0px #776868',
            }}
          />
        </Box>
      </Stack>
    </ContainedXLWidthContainer>
  )
}

export default RecommendationsHero
