import * as React from 'react'
import Svg from '@eversports/klimt-primitives/Svg'
import Box from '@eversports/klimt-primitives/Box'
import PlusIcon from '@eversports/design-tokens/assets/icons/add.svg'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

const ShowMoreButton = () => {
  return (
    <Box
      sx={{ backgroundColor: 'midnight', borderRadius: 10, filter: `drop-shadow(${boxShadows.default})` }}
      marginLeft={-2}
      zIndex={2}
    >
      <Svg src={PlusIcon} width={22} height={22} color="white" />
    </Box>
  )
}

export default ShowMoreButton
