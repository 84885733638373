import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'
import { palette } from '@eversports/design-tokens/palette'

import { Localized } from '../../../localization/react'
import { InvoiceState } from '../InvoiceHistory.types'
import { invoiceStatusColorMapping, invoiceStatusLabelMapping } from '../InvoiceHistory.constants'

interface Props {
  invoiceState: InvoiceState
}

const InvoiceStatusBadge = ({ invoiceState }: Props) => (
  <Box
    px={2}
    py={1}
    sx={{
      background: `${palette[invoiceStatusColorMapping[invoiceState]]}1F`,
      borderRadius: 0.5,
      width: 'fit-content',
    }}
  >
    <Text color={invoiceStatusColorMapping[invoiceState]} variant="label">
      <Localized id={invoiceStatusLabelMapping[invoiceState] as any} />
    </Text>
  </Box>
)

export default InvoiceStatusBadge
