import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import ExpandableContent from '@eversports/klimt-patterns/ExpandableContent'
import FormattedContent from '@eversports/klimt-primitives/FormattedContent'

import { Localized } from '../../../../localization/react'

interface Props {
  content: string
}

const CLAMPED_TEXT_HEIGHT = 63

const About = ({ content }: Props) => {
  return (
    <Stack gap={2}>
      <Heading is="h2" color="midnight">
        <Localized id="venue-profile-about-title" />
      </Heading>
      <ExpandableContent clampedTextHeight={CLAMPED_TEXT_HEIGHT}>
        <FormattedContent htmlContent={content} />
      </ExpandableContent>
    </Stack>
  )
}

export default About
