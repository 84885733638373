import SharingActions, { SharingMessage } from '@eversports/klimt-patterns/SharingActions'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import * as React from 'react'

import { Localized } from '../../../../localization/react'
import InviteFriends from '../../../../components/InviteFriends'

interface Props {
  sharingMessage: SharingMessage
  venueId: string
}

const VenueShareButtonActionsContent = ({ sharingMessage, venueId }: Props) => {
  return (
    <Stack gap={4}>
      <InviteFriends type="venue" venueId={venueId} />
      <Heading is="h4" variant="small">
        <Localized id="share-it" />
      </Heading>
      <SharingActions sharingMessage={sharingMessage} />
    </Stack>
  )
}

export default VenueShareButtonActionsContent
