import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Processing from '@eversports/klimt-primitives/Processing'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../../../../../localization/react'
import { PublicUser as PublicUserType } from '../SuggestedFriends.types'
import SuggestedFriend from '../SuggestedFriend'
import { SuggestedFriendsSkeleton } from '../SuggestedFriendsPlaceholder'

interface Props {
  friends?: Array<PublicUserType>
  initialLoading: boolean
  loading: boolean
  hasMoreFriends: boolean
  onLoadMore: () => void
}

const AllSuggestedFriendsContent = ({ friends, initialLoading, loading, hasMoreFriends, onLoadMore }: Props) => {
  return (
    <Stack padding={6} gap={4}>
      <Heading is="h3" textAlign="center" display={{ xs: 'none', md: 'initial' }}>
        <Localized id="suggested-friends-title" />
      </Heading>
      {initialLoading ? (
        <Stack direction="row" flexWrap="wrap" gap={2}>
          <SuggestedFriendsSkeleton />
          <SuggestedFriendsSkeleton />
          <SuggestedFriendsSkeleton />
          <SuggestedFriendsSkeleton />
        </Stack>
      ) : (
        <>
          <Stack direction="row" flexWrap="wrap" gap={[3, 2]}>
            {friends && friends.map((friend) => <SuggestedFriend key={friend.id} friend={friend} />)}
          </Stack>
          {hasMoreFriends && (
            <Processing isLoading={loading}>
              <Box
                sx={{ backgroundColor: 'limelite', borderRadius: 1, padding: 2, width: '100%', cursor: 'pointer' }}
                onClick={onLoadMore}
              >
                <Text variant="small" sx={{ color: 'primary.main', fontWeight: 'bold', textAlign: 'center' }}>
                  <Localized id="load-more-suggestions" />
                </Text>
              </Box>
            </Processing>
          )}
        </>
      )}
    </Stack>
  )
}

export default AllSuggestedFriendsContent
