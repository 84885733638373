import * as React from 'react'
import { useEffect } from 'react'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'
import { useLocation } from 'react-router-dom'

import { useMyFriendsMeQuery } from '../../../../../graphql'
import LoadingPage from '../../../../../components/LoadingPage'
import { NUMBER_OF_INITIAL_FRIENDS_FETCHED } from '../Friends.constants'
import { AuthRoutes, fromMapping } from '../../../../../App.constants'
import useFromParam from '../../../../../hooks/use-from-param'

import MyFriendsComponent from './MyFriendsComponent'

const MyFriends = () => {
  const from = useFromParam()
  const { pathname, search } = useLocation()
  const { amplitude, logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const { data, loading } = useMyFriendsMeQuery({
    variables: {
      firstFriendsNumber: NUMBER_OF_INITIAL_FRIENDS_FETCHED,
    },
  })

  useEffect(() => {
    if (!amplitude) return
    logAmplitudeEventOnce({
      eventName: 'Viewed My Friends Page',
      eventProperties: { from: from ? fromMapping[from] : null },
    })
  }, [amplitude])

  if (!data || loading) {
    return <LoadingPage />
  }

  if (!data.me) {
    if (typeof window !== 'undefined') {
      window.location.href = `${AuthRoutes.LOGIN}?redirectPath=${encodeURIComponent(pathname + search)}`
    }
    return <LoadingPage />
  }

  return <MyFriendsComponent me={data.me} from={from} />
}

export default MyFriends
