import * as React from 'react'
import { assertNever } from '@eversports/klimt-utilities/assert-never'
import Avatar from '@eversports/klimt-primitives/Avatar'

import getInitials from '../ActivityDetails/FriendAttending/ParticipatingFriends/utils/get-initials'

import { NotificationIcon as NotificationIconType } from './Notifications.types'

interface Props {
  icon: NotificationIconType
}

const NotificationIcon = ({ icon }: Props) => {
  switch (icon.__typename) {
    case 'NotificationIconImage':
      return (
        <Avatar
          src={icon.image.xSmall}
          sx={{
            height: '42px',
            width: '42px',
            border: '1px solid',
            borderColor: 'lightgray',
            backgroundColor: 'white',
            borderRadius: 1,
          }}
        />
      )
    case 'NotificationIconUser':
      return (
        <Avatar
          src={icon.user.profilePicture?.xSmall}
          sx={{
            height: '42px',
            width: '42px',
            border: '1px solid',
            borderColor: 'lightgray',
            backgroundColor: 'white',
            color: 'darkgray',
            borderRadius: 10,
          }}
        >
          {getInitials({ firstname: icon.user.firstName, lastname: icon.user.lastName })}
        </Avatar>
      )
    default:
      return assertNever(icon)
  }
}

export default NotificationIcon
