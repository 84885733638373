import { useEffect, useState } from 'react'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import { breakpoints } from '@eversports/design-tokens/breakpoints'

const MOST_COMMENT_MOBILE_BREAKPOINT_WIDTH = 375

const useDocumentWidth = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const [documentWidth, setDocumentWidth] = useState(isMobile ? MOST_COMMENT_MOBILE_BREAKPOINT_WIDTH : breakpoints.lg)

  useEffect(() => {
    const updateDocumentBodyDimensions = () => {
      const documentBodyWith = window.document.body.getBoundingClientRect().width
      setDocumentWidth(documentBodyWith)
    }

    window.addEventListener('resize', updateDocumentBodyDimensions)

    return () => window.removeEventListener('resize', updateDocumentBodyDimensions)
  }, [])

  return documentWidth
}

export default useDocumentWidth
