import { SupportedTLD } from '@eversports/react-app-base/tld-configs'

export const DEFAULT_CITY_SLUGS: { [key in SupportedTLD]: string } = {
  at: 'wien',
  nl: 'amsterdam',
  de: 'berlin',
  es: 'barcelona',
  ch: 'zuerich',
  it: 'roma',
  be: 'brussels',
  fr: 'paris',
}

export const PROMOTED_CITY_SLUGS: { [key in SupportedTLD]: Array<string> } = {
  at: ['wien', 'graz', 'linz', 'salzburg'],
  de: ['berlin', 'hamburg', 'koeln', 'muenchen'],
  nl: ['amsterdam', 'rotterdam', 'utrecht', 'den-haag'],
  ch: ['zuerich', 'bern', 'basel', 'winterthur'],
  fr: ['paris', 'marseille', 'lyon', 'toulouse'],
  be: ['bruxelles', 'gent', 'antwerpen', 'liege'],
  es: [],
  it: [],
}
