import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Avatar from '@eversports/klimt-primitives/Avatar'
import Text from '@eversports/klimt-primitives/Text'
import EversportsLogoIcon from '@eversports/design-tokens/assets/logo/icon.svg'
import { useState } from 'react'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import UserProfileSharingActions from '../../../components/UserProfileSharingActions'
import { Localized } from '../../../../../localization/react'
import SubSectionCard from '../components/SubSectionCard'

interface Props {
  profileImage?: string
}

const ShareYourProfile = ({ profileImage }: Props) => {
  const { amplitude } = useAmplitude()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen)
    if (amplitude) {
      amplitude.logEvent('Clicked User Profile Share Button')
    }
  }

  return (
    <>
      <SubSectionCard onClick={toggleDialog}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>
              <Localized id="share-your-profile-call-to-action" />
            </Text>
            <Text variant="small">
              <Localized id="share-your-profile-description" />
            </Text>
          </Stack>
          <Avatar
            src={profileImage || EversportsLogoIcon}
            sx={{
              height: '42px',
              width: '42px',
              border: '1px solid',
              borderColor: 'lightgray',
              borderRadius: 10,
              backgroundColor: 'white',
            }}
          />
        </Stack>
      </SubSectionCard>
      <UserProfileSharingActions isOpen={isDialogOpen} toggleDialog={toggleDialog} />
    </>
  )
}

export default ShareYourProfile
