import * as React from 'react'
import { ChangeEvent } from 'react'
import TextField from '@eversports/klimt-primitives/TextField'
import CircularProgress from '@eversports/klimt-primitives/CircularProgress'
import Box from '@eversports/klimt-primitives/Box'
import Icon from '@eversports/klimt-primitives/Icon'
import Close from '@eversports/design-tokens/assets/icons/close.svg'

import { useIntl } from '../../../localization/react'

import SearchSvg from './SearchSvg'

interface Props {
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  isLoading?: boolean
}

const MobileDrawerSearchInput = ({ searchValue = '', setSearchValue, isLoading }: Props) => {
  const intl = useIntl()

  const clearInput = () => setSearchValue('')

  const inputProps = {
    startAdornment: (
      <Box marginRight={4} flexShrink={0}>
        {isLoading ? <CircularProgress size={12} /> : <SearchSvg size={12} />}
      </Box>
    ),
    endAdornment: searchValue ? (
      <Box sx={{ cursor: 'pointer' }}>
        <Icon onClick={clearInput} src={Close} color="gray" size="default" />
      </Box>
    ) : null,
    style: { fontSize: 16 },
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  return (
    <TextField
      id="search-input"
      value={searchValue}
      placeholder={intl.searchInputPlaceholderText()}
      onChange={handleInputChange}
      sx={{
        '& .MuiInputBase-root': {
          borderRadius: 10,
        },
      }}
      InputProps={inputProps}
    />
  )
}

export default MobileDrawerSearchInput
