import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import { borderRadius } from '@eversports/design-tokens/border-radius'

import { Localized, useIntl } from '../../../localization/react'
import MagnifyGlass from '../assets/magnifyGlass.svg'

export const SEARCH_BUTTON_DIAMETER = 48

interface SearchIconProps {
  altText: string
}

const SearchIcon = ({ altText }: SearchIconProps) => (
  <img src={MagnifyGlass} alt={altText} width={16} height={16} color="white" />
)

const SearchButtonComponent = () => {
  const intl = useIntl()

  return (
    <>
      <Button
        variant="primary"
        aria-label={intl.searchSubmitButtonText()}
        sx={{
          display: { xs: 'block', sm: 'none' },
          borderRadius: `0px 0px ${borderRadius.default}px ${borderRadius.default}px`,
          width: '100%',
          height: '50px',
        }}
      >
        <Stack direction="row" gap={2} justifyContent="center" alignItems="center">
          <SearchIcon altText={intl.searchSubmitButtonText()} />
          <Heading is="h3" color="white" sx={{ textTransform: 'none' }}>
            <Localized id="search-submit-button-text" />
          </Heading>
        </Stack>
      </Button>
      <Button
        variant="primary"
        aria-label={intl.searchSubmitButtonText()}
        sx={{
          display: { xs: 'none', sm: 'block' },
          borderRadius: 10,
          boxShadow: '0px 8px 8px rgba(33, 166, 150, 0.4)',
          position: 'absolute',
          right: '16px',
          top: '12px',
          minWidth: '0px',
          width: `${SEARCH_BUTTON_DIAMETER}px`,
          height: `${SEARCH_BUTTON_DIAMETER}px`,
        }}
      >
        <SearchIcon altText={intl.searchSubmitButtonText()} />
      </Button>
    </>
  )
}
export default SearchButtonComponent
