import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { useDiscoverReducer, useDiscoverState } from '../DiscoverContext'
import { Localized } from '../../../localization/react'

const ResetAllFilters = () => {
  const {
    venueOfferingsActiveFilters,
    subSportsActiveFilters,
    venueActivityAvailabilityActiveFilters,
    venueAttributeActiveFilters,
    venueCourtAreasActiveFilters,
    venueCourtSurfaceActiveFilters,
    venueAmenitiesActiveFilters,
  } = useDiscoverState()
  const dispatch = useDiscoverReducer()

  const handleClick = () => {
    dispatch({ type: 'RESET_FILTERS' })
  }

  const isVisible = Boolean(
    venueOfferingsActiveFilters ||
      subSportsActiveFilters ||
      venueActivityAvailabilityActiveFilters ||
      venueAttributeActiveFilters ||
      venueCourtAreasActiveFilters ||
      venueCourtSurfaceActiveFilters ||
      venueAmenitiesActiveFilters,
  )

  return isVisible ? (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      onClick={handleClick}
      gap={1}
      sx={{
        border: '1px solid',
        borderColor: 'mediumgray',
        boxSizing: 'border-box',
        borderRadius: 10,
        py: 2,
        px: 5,
        cursor: 'pointer',
        '&:hover': {
          borderColor: 'primary.main',
        },
      }}
    >
      <Text variant="small" sx={{ color: 'primary.main' }}>
        <Localized id="filters-reset-all" />
      </Text>
    </Stack>
  ) : null
}

export default ResetAllFilters
