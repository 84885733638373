import * as React from 'react'
import { useEffect, useState } from 'react'
import { getLanguageFromLanguageWithLocale, Language, SUPPORTED_LANGUAGES } from '@eversports/language'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Locale } from 'date-fns'
import enLocale from 'date-fns/locale/en-GB/index.js'

const getLanguage = (): Language => {
  const locale = getLanguageFromLanguageWithLocale(navigator.language)
  return SUPPORTED_LANGUAGES.has(locale) ? locale : 'en'
}

const DateLocalizationProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [locale, setLocale] = useState<Locale>(enLocale)

  useEffect(() => {
    const importLocale = async (language: Language) => {
      try {
        const localeToSet: Locale = await import(
          /* webpackExclude: /_lib/ */
          /* webpackChunkName: "df-[index]" */
          /* webpackMode: "lazy" */
          `date-fns/locale/${language === 'en' ? 'en-GB' : language}/index.js`
        )

        if (localeToSet) {
          setLocale(localeToSet)
        }
      } catch (e: any) {
        return
      }
    }

    const language = getLanguage()

    if (language !== 'en') {
      void importLocale(language)
    }
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  )
}

export default DateLocalizationProvider
