import * as React from 'react'
import { useState, useEffect } from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import ShareIcon from '@eversports/design-tokens/assets/icons/share.svg'
import Icon from '@eversports/klimt-primitives/Icon'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import CloseIcon from '@eversports/design-tokens/assets/icons/close.svg'
import Box from '@eversports/klimt-primitives/Box'

import { Localized } from '../../../../../localization/react'
import UserProfileSharingActions from '../../../components/UserProfileSharingActions'
import useLoggedInUserType from '../../../hooks/use-logged-in-user-type'
import { UserType } from '../../../PublicUserProfile.types'
import { TOOLTIP_TYPE } from '../../../PublicUserProfile.constants'
import PromotionalTooltip from '../../../components/PromotionalTooltip'

import ActionWrapper from './components/ActionWrapper'

const ShareUserProfileButton = () => {
  const { amplitude } = useAmplitude()
  const { userType } = useLoggedInUserType()
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const toggleDialog = () => {
    handleTooltipClose()
    setIsDialogOpen(!isDialogOpen)
  }

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked User Profile Share Button', {
        from: userType === UserType.PROFILE_OWNER ? 'my profile' : 'friend profile',
      })
    }
    toggleDialog()
  }

  const handleTooltipClose = () => {
    localStorage.setItem(TOOLTIP_TYPE.SHARE_PROFILE, 'true')
    setIsTooltipOpen(false)
  }

  useEffect(() => {
    if (userType !== UserType.PROFILE_OWNER) return
    if (!window.localStorage.getItem(TOOLTIP_TYPE.SHARE_PROFILE)) {
      setIsTooltipOpen(true)
    }
  }, [])

  return (
    <>
      <ActionWrapper onClick={handleClick}>
        <PromotionalTooltip
          open={isTooltipOpen}
          content={
            <Stack gap={1}>
              <Stack direction="row" justifyContent="space-between">
                <Text sx={{ color: 'white', textAlign: 'left', fontWeight: 'bold' }}>
                  <Localized id="user-connection-tooltip-share-your-profile-title" />
                </Text>
                <Box onClick={handleTooltipClose} sx={{ cursor: 'pointer' }}>
                  <Icon size="small" color="white" src={CloseIcon} />
                </Box>
              </Stack>
              <Text variant="small" sx={{ color: 'white' }}>
                <Localized id="user-connection-tooltip-share-your-profile-description" />
              </Text>
            </Stack>
          }
        >
          <Stack direction="row" alignItems="center" gap={2.5}>
            <Text variant="small" sx={{ color: 'black', display: { xs: 'none', md: 'initial' } }}>
              <Localized id="share-user-profile-button" params={{ userType }} />
            </Text>
            <Icon src={ShareIcon} size="small" color="midnight" />
          </Stack>
        </PromotionalTooltip>
      </ActionWrapper>
      <UserProfileSharingActions isOpen={isDialogOpen} toggleDialog={toggleDialog} />
    </>
  )
}

export default ShareUserProfileButton
