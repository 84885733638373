import * as React from 'react'
import DatePicker from '@eversports/klimt-patterns/DatePicker'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Action } from '../../helpers/discover-sport-matches-reducer'
import { AMPLITUDE_DISCOVER_SPORT_CITY_FILTER_EVENT } from '../../../../Discover.constants'
import { DiscoverFilterTypes } from '../../../../Filters/Filters.types'

interface Props {
  selectedDate: Date | null
  dispatch: React.Dispatch<Action>
  isMobile?: boolean
}

const DateFilter = ({ selectedDate, dispatch, isMobile }: Props) => {
  const { amplitude } = useAmplitude()

  const handleDateChange = (date: Date | null) => {
    if (!date) return
    dispatch({ type: 'UPDATE_DISCOVER_MATCHES_DATE_FILTER', payload: date })
    if (amplitude) {
      amplitude.logEvent(AMPLITUDE_DISCOVER_SPORT_CITY_FILTER_EVENT, {
        type: DiscoverFilterTypes.date,
        tab: 'match',
      })
    }
  }

  return (
    <DatePicker
      value={selectedDate}
      onChange={handleDateChange}
      isMobile={isMobile}
      variant="simple"
      minWidth="162px"
    />
  )
}

export default DateFilter
