import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Divider from '@eversports/klimt-primitives/Divider'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { useLocation } from 'react-router-dom'
import Link from '@eversports/klimt-primitives/Link'

import { getLinkToTeacherOffering } from '../../../helpers/get-link-to-teacher-offering'
import { BaseVenue } from '../../../App.types'
import TeacherCardButton from '../../../components/TeacherCard/TeacherCardButton'
import TeacherCardContainer from '../../../components/TeacherCard/TeacherCardContainer'

import { Props } from './TeacherCard'
import TeacherCardInfo from './TeacherCardInfo'

interface ActiveCardProps extends Props {
  venue: BaseVenue
}

const ActiveTeacherCard = ({ teacher, venue }: ActiveCardProps) => {
  const { amplitude } = useAmplitude()
  const { pathname } = useLocation()

  const handleCardClick = () => {
    if (amplitude) {
      const teacherName = `${teacher?.firstName ?? ''} ${teacher?.lastName ?? ''}`

      amplitude.logEvent('Clicked Trainer Tile', {
        from: pathname,
        venue: {
          name: venue.name,
        },
        trainer: {
          name: teacherName,
          hasImage: Boolean(teacher.image),
          numberOfSports: teacher.sports.length,
        },
      })
    }
  }

  const path = getLinkToTeacherOffering({
    venueOfferings: venue.offerings,
    venueSlug: venue.slug,
    teacherId: teacher.id,
  })

  return (
    <Link to={path.to} external blank>
      <TeacherCardContainer isClickable handleClick={handleCardClick}>
        <Stack gap={4}>
          <TeacherCardInfo teacher={teacher} venue={venue} />
          <Divider sx={{ borderColor: 'ligthgray' }} />
          <TeacherCardButton isActiveAtVenue={true} isOverviewPage={path.isOverviewPage} />
        </Stack>
      </TeacherCardContainer>
    </Link>
  )
}

export default ActiveTeacherCard
