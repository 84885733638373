import * as React from 'react'
import PlayStoreBadge from '@eversports/design-tokens/assets/icons/play-store.svg'
import AppStoreBadge from '@eversports/design-tokens/assets/icons/app-store.svg'
import Stack from '@eversports/klimt-primitives/Stack'
import Link from '@eversports/klimt-primitives/Link'
import Text from '@eversports/klimt-primitives/Text'

import { Localized, useIntl, Locales } from '../../localization/react'

type StoreBadgeType = 'play-store' | 'app-store'
type UrlVariant = 'default' | 'ballsports'
type UrlVariantObj = { [K in UrlVariant]: string }
type ColorVariant = 'default' | 'black' | 'transparent'

interface StoreBadgeProps {
  type: StoreBadgeType
  urlVariant?: UrlVariant
  colorVariant?: ColorVariant
  height: string
  width: string
}

const StoreBadgeReference = (language: Locales): Record<StoreBadgeType, UrlVariantObj> => ({
  'play-store': {
    default: `https://play.google.com/store/apps/details?id=at.eversport&hl=${language}`,
    ballsports: `https://play.google.com/store/apps/details?id=at.eversport&hl=${language}`,
  },
  'app-store': {
    default: `https://itunes.apple.com/app/eversport-now/id946460861`,
    ballsports: `https://apps.apple.com/app/id946460861?ppid=d4542bd1-2f8a-4314-812e-c584c77e7c8d`,
  },
})

const StoreBadgeBackgroundColorVariants: { [key in ColorVariant]: string } = {
  default: 'text.primary',
  black: 'black',
  transparent: 'transparent',
}

const StoreBadgeTextColorVariants: { [key in ColorVariant]: string } = {
  default: 'white',
  black: 'white',
  transparent: 'midnight',
}

const StoreBadgeBorderVariants: { [key in ColorVariant]: string } = {
  default: 'none',
  black: 'none',
  transparent: '1px solid #141414',
}

export const StoreBadge = ({
  type,
  height,
  width,
  urlVariant = 'default',
  colorVariant = 'default',
}: StoreBadgeProps) => {
  const intl = useIntl()

  const backgroundColor = StoreBadgeBackgroundColorVariants[colorVariant]
  const textColor = StoreBadgeTextColorVariants[colorVariant]
  const border = StoreBadgeBorderVariants[colorVariant]

  return (
    <Link
      to={StoreBadgeReference(intl.context.locale.loaded)[type][urlVariant]}
      ariaLabel={`${type}-badge`}
      external
      blank
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={4}
        width={width}
        height={height}
        sx={{
          px: 2,
          py: 1,
          backgroundColor,
          borderRadius: 1,
          border,
        }}
      >
        <img
          src={type === 'play-store' ? PlayStoreBadge : AppStoreBadge}
          alt={`${type}-badge`}
          width="34px"
          height="34px"
          loading="lazy"
        />
        <Stack>
          <Text variant="badge" sx={{ color: textColor }}>
            <Localized id="store-badge-get-it-on" />
          </Text>
          <Text variant="large" sx={{ color: textColor, fontWeight: 'bold' }}>
            {type === 'play-store' ? (
              <Localized id="store-badge-google-play" />
            ) : (
              <Localized id="store-badge-apple-store" />
            )}
          </Text>
        </Stack>
      </Stack>
    </Link>
  )
}

export default StoreBadge
