import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../../localization/react'
import FooterBackground from '../assets/footer-background.webp'

import StoreBadges from './StoreBadges'
import VenueNewsletter from './VenueNewsletter'

const currentYear = new Date().getFullYear().toString()

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${FooterBackground as string})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Stack
        gap={8}
        justifyContent="space-around"
        sx={{ p: 4, flexDirection: { xs: 'column-reverse', sm: 'row' }, flexWrap: { xs: 'revert', sm: 'initial' } }}
      >
        <StoreBadges />
        <VenueNewsletter />
      </Stack>
      <Box sx={{ textAlign: 'center', backgroundColor: 'rgba(20, 20, 20, 0.5)', p: 4 }}>
        <Text variant="label" sx={{ color: 'gray' }}>
          <Localized id="footer-copyright-disclaimer" params={{ currentYear }} />
        </Text>
      </Box>
    </Box>
  )
}

export default Footer
