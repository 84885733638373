import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import SharingActions, { SharingMessage } from '@eversports/klimt-patterns/SharingActions'

import { Localized } from '../../../../../localization/react'
import InviteFriends from '../../../../../components/InviteFriends'

interface Props {
  sharingMessage: SharingMessage
  eventId: string
}

const ShareContent = ({ sharingMessage, eventId }: Props) => {
  return (
    <Stack gap={4}>
      <InviteFriends type="event" eventId={eventId} />
      <Heading is="h4" variant="small">
        <Localized id="share-it" />
      </Heading>
      <SharingActions sharingMessage={sharingMessage} />
    </Stack>
  )
}

export default ShareContent
