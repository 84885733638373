import { useContext } from 'react'

import { AmplitudeContext } from './core/amplitude-context'
import useAmplitudeCookies from './core/hooks/useAmplitudeCookies'

const useAmplitude = () => {
  const context = useContext(AmplitudeContext)
  const { deviceId, sessionId } = useAmplitudeCookies()
  return { amplitude: context ? context.amplitude : null, deviceId, sessionId }
}

export default useAmplitude
