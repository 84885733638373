import * as React from 'react'
import CircularProgress from '@eversports/klimt-primitives/CircularProgress'

import { PublicUser } from '../../../PublicUserProfile.types'

import { UserType } from './FriendshipButton.types'
import Friends from './Friends'
import RequestedFriendship from './RequestedFriendship'
import AddFriend from './AddFriend'
import FriendshipRequestedPending from './FriendshipRequestedPending'
import useFriendOfUserRelationshipType from './hooks/useFriendOfUserRelationshipType'

interface Props {
  friend: PublicUser
}

const FriendshipButton = ({ friend }: Props) => {
  const { userType } = useFriendOfUserRelationshipType({ userHandle: friend.handle })

  if (!userType) {
    return <CircularProgress size={20} />
  }

  switch (userType) {
    case UserType.SELF:
      return null
    case UserType.FRIENDS:
      return <Friends />
    case UserType.FRIENDSHIP_REQUEST_PENDING:
      return <FriendshipRequestedPending friend={friend} />
    case UserType.REQUESTED_FRIENDSHIP:
      return <RequestedFriendship />
    case UserType.NOT_FRIENDS:
    default:
      return <AddFriend friend={friend} />
  }
}

export default FriendshipButton
