import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import { useParams } from 'react-router-dom'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { capitalize } from '../utils/capitalize-str'
import { Localized } from '../../../localization/react'
import { createIcsUrl } from '../../../helpers/create-ics-url'
import EditCalendarIcon from '../assets/edit-calendar.svg'
import { useSaveMatchToCalendarMatchQuery } from '../../../graphql'
import useMatchUrl from '../hooks/use-match-url'
import useLoggedInUserType from '../hooks/use-logged-in-user-type'
import { pageTypeMapping, userTypeMapping } from '../Match.constants'
import usePageType from '../hooks/use-page-type'
import { MatchStatus } from '../Match.types'
import useMatchStatus from '../hooks/use-match-status'

import ActionWrapper from './ActionWrapper'
import { ActionWrapperVariant } from './AdditionalActions.types'

interface Props {
  variant?: ActionWrapperVariant
}

const SaveMatchToCalendar = ({ variant }: Props) => {
  const { id } = useParams<{ id: string }>()
  const { amplitude } = useAmplitude()
  const { userType } = useLoggedInUserType()
  const pageType = usePageType()
  const matchStatus = useMatchStatus()
  const { language } = useSettingsContext()

  const { data } = useSaveMatchToCalendarMatchQuery({ variables: { matchId: id } })
  const matchUrl = useMatchUrl()

  if (!data) {
    return null
  }

  const { venue, start, end, sport, organizerNote, court } = data.match

  const handleSaveToCalendarClick = React.useCallback(() => {
    if (amplitude) {
      amplitude.logEvent('Clicked Add to Calendar', {
        userType: userTypeMapping[userType],
        from: pageTypeMapping[pageType],
      })
    }

    const sportName = capitalize(sport.name)
    const summary = `${sportName} @ ${venue.name}`
    const courtName = court?.name ? '\n\n' + court.name : ''
    const note = organizerNote ? '\n\n' + organizerNote : ''

    const calendarDescription = matchUrl + courtName + note

    const icsUrl = createIcsUrl({
      summary,
      start,
      end,
      address: venue.address,
      description: calendarDescription,
    })

    // Format download file name
    const matchDate = new Date(start)
    const localShortDate = matchDate.toLocaleDateString(language, {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    const fileName = `${sportName}_${localShortDate}`
    // Download the file this way instead of window.open() to avoid adblockers
    const link = document.createElement('a')
    link.download = fileName
    link.href = encodeURI(`data:text/calendar;charset=utf8,${icsUrl}`)
    link.click()
    link.remove()
  }, [amplitude])

  const isDisabled = matchStatus !== MatchStatus.UPCOMING

  return (
    <ActionWrapper onClick={handleSaveToCalendarClick} isDisabled={isDisabled} variant={variant}>
      <img src={EditCalendarIcon} alt="Add date to calendar" loading="lazy" width="14px" height="14px" />
      <Text>
        <Localized id="save-to-calendar" />
      </Text>
    </ActionWrapper>
  )
}

export default SaveMatchToCalendar
