interface Props<T> {
  recentSearches?: Array<T>
  databaseResults?: Array<T>
}

function filterDatabaseResults<T>({ recentSearches, databaseResults }: Props<T & { id: string }>) {
  if (!databaseResults) return

  if (!recentSearches) return databaseResults

  const recentSearchIds = recentSearches.map((searchResult) => searchResult.id)

  return databaseResults.filter((databaseResult) => !recentSearchIds.includes(databaseResult.id))
}

export default filterDatabaseResults
