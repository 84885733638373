import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { extractStatusCodeFromError } from '@eversports/react-app-base/create-apollo-client'
import { useHttpStatus } from '@eversports/react-app-base/http-status/use-http-status'

import {
  SportCategory,
  useDiscoverDiscoverContextByCityAndSportSlugQuery,
  useDiscoverVenueSearchLazyQuery,
  VenueSearchSort,
} from '../../../../graphql'
import { formatDateAsQueryVariable, formatTimeAsQueryVariable } from '../../../../utils/format-date-time'
import { useDiscoverReducer, useDiscoverState } from '../../DiscoverContext'
import {
  DISCOVER_OFFERING_TYPE_TO_AMPLITUDE_NAME_MAPPING,
  NUMBER_OF_LISTING_RESULTS_TO_LOAD,
} from '../../Discover.constants'
import { getBoundingBoxFromQueryResult } from '../../helpers/get-bounding-box-from-query-result'
import LoadingPage from '../../../../components/LoadingPage'
import setSearchParams from '../../../../utils/set-search-params'
import getDiscoverScreenInitialFilters from '../../helpers/get-discover-screen-initial-filters'
import useUrlParams from '../../../../hooks/use-url-params'
import NotFound from '../NotFound'

import DiscoverVenuesComponent from './DiscoverVenuesComponent'

const DiscoverVenues = () => {
  const dispatch = useDiscoverReducer()
  const {
    venueOfferingsActiveFilters,
    venueActivityAvailabilityActiveFilters,
    venueAttributeActiveFilters,
    subSportsActiveFilters,
    initialSearchBoundingBox,
    venueCourtSlotDateActiveFilter,
    venueCourtSlotTimeActiveFilter,
    venueCourtAreasActiveFilters,
    venueCourtSurfaceActiveFilters,
    venueAmenitiesActiveFilters,
    shouldConsiderDistanceForSearch,
    userMapLocation,
    sport: discoverSport,
  } = useDiscoverState()

  const { amplitude } = useAmplitude()
  const { sportSlug, citySlug } = useParams<{ sportSlug: string; citySlug: string }>()
  const initialFilters = getDiscoverScreenInitialFilters()
  const { show } = useUrlParams()
  const { setStatus } = useHttpStatus()

  const courtSlotDateAsString = formatDateAsQueryVariable(venueCourtSlotDateActiveFilter)
  const courtSlotTimeAsString = venueCourtSlotTimeActiveFilter
    ? formatTimeAsQueryVariable(venueCourtSlotTimeActiveFilter)
    : null

  const ballsportFilters = {
    courtAvailability: { date: courtSlotDateAsString, time: courtSlotTimeAsString },
    courtSurfaces: venueCourtSurfaceActiveFilters,
    courtAreas: venueCourtAreasActiveFilters,
    venueAmenities: venueAmenitiesActiveFilters,
    venueAttributes: venueAttributeActiveFilters,
  }

  const studioFilters = {
    activityAvailabilities: venueActivityAvailabilityActiveFilters,
    venueOfferingTypes: venueOfferingsActiveFilters,
    venueAttributes: venueAttributeActiveFilters,
  }

  const categoryFilters =
    (discoverSport?.category as SportCategory) !== SportCategory.FITNESS ? ballsportFilters : studioFilters

  const discoverContextFilters = {
    courtSurfaces: initialFilters.venueCourtSurfaceActiveFilters,
    courtAreas: initialFilters.venueCourtAreasActiveFilters,
    venueAmenities: initialFilters.venueAmenitiesActiveFilters,
    venueAttributes: initialFilters.venueAttributeActiveFilters,
    activityAvailabilities: initialFilters.venueActivityAvailabilityActiveFilters,
    venueOfferingTypes: initialFilters.venueOfferingsActiveFilters,
  }

  const {
    data,
    loading,
    error,
    refetch: refetchDiscoverContext,
  } = useDiscoverDiscoverContextByCityAndSportSlugQuery({
    variables: {
      citySlug,
      sportSlug,
      filters: discoverContextFilters,
    },
    nextFetchPolicy: 'standby',
  })

  const [discoverVenueSearchLazyQuery, { data: venueSearchData, previousData: previousVenueSearchData }] =
    useDiscoverVenueSearchLazyQuery()

  // Refetch the context when the sport slug or city slug changes.
  useEffect(() => {
    if (!venueSearchData && !previousVenueSearchData) return
    void refetchDiscoverContext({
      citySlug,
      sportSlug,
      filters: discoverContextFilters,
    })
  }, [sportSlug, citySlug])

  useEffect(() => {
    if (!data) return

    const { northEast, southWest } = getBoundingBoxFromQueryResult(data.discoverContextByCityAndSportSlug.boundingBox)
    const sport = data.discoverContextByCityAndSportSlug.sport

    dispatch({ type: 'SET_DISCOVERY_SPORT', payload: sport })
    dispatch({
      type: 'SET_INITIAL_SEARCH_BOUNDING_BOX',
      payload: {
        northEast,
        southWest,
      },
    })

    // If this is a ballsports/funsport page, do a second request to fetch court slots availability
    if (sport.category !== SportCategory.FITNESS) {
      const city = data.discoverContextByCityAndSportSlug.city
      void discoverVenueSearchLazyQuery({
        variables: {
          city: city?.id,
          filters: {
            boundingBox: { northEast, southWest },
            sports: subSportsActiveFilters || [sport.id],
            ...categoryFilters,
          },
          slotsToDiscoverFilter: {
            courtAvailability: { date: courtSlotDateAsString, time: courtSlotTimeAsString },
            sport: sport.id,
            courtSurfaces: venueCourtSurfaceActiveFilters,
            courtAreas: venueCourtAreasActiveFilters,
          },
        },
      })
    }
  }, [data])

  useEffect(() => {
    if (!initialSearchBoundingBox || !discoverSport) return
    const city = data?.discoverContextByCityAndSportSlug.city
    void discoverVenueSearchLazyQuery({
      variables: {
        city: city?.id,
        sort: shouldConsiderDistanceForSearch ? VenueSearchSort.CONSIDER_DISTANCE : VenueSearchSort.DEFAULT,
        userLocation: userMapLocation,
        filters: {
          boundingBox: initialSearchBoundingBox,
          sports: subSportsActiveFilters || [discoverSport.id],
          ...categoryFilters,
        },
        slotsToDiscoverFilter: {
          courtAvailability: { date: courtSlotDateAsString, time: courtSlotTimeAsString },
          sport: discoverSport.id,
          courtSurfaces: venueCourtSurfaceActiveFilters,
          courtAreas: venueCourtAreasActiveFilters,
        },
      },
    })

    // Sync the selected search params with the url
    setSearchParams({
      venueCourtSurfaceActiveFilters,
      venueCourtAreasActiveFilters,
      venueAmenitiesActiveFilters,
      venueOfferingsActiveFilters,
      venueActivityAvailabilityActiveFilters,
      venueAttributeActiveFilters,
      subSportsActiveFilters,
    })
  }, [
    shouldConsiderDistanceForSearch,
    venueCourtSlotDateActiveFilter,
    venueCourtSlotTimeActiveFilter,
    venueCourtSurfaceActiveFilters,
    venueCourtAreasActiveFilters,
    venueAmenitiesActiveFilters,
    venueOfferingsActiveFilters,
    venueActivityAvailabilityActiveFilters,
    venueAttributeActiveFilters,
    subSportsActiveFilters,
    initialSearchBoundingBox,
    discoverSport,
  ])

  useEffect(() => {
    if (!amplitude || !discoverSport || !data) return

    const isCategoryWithTimeFilter = Boolean((discoverSport.category as SportCategory) !== SportCategory.FITNESS)

    const totalNumberOfResults = data.discoverContextByCityAndSportSlug?.venueSearch.edges.length
    const numberOfInitialResults = Math.min(totalNumberOfResults, NUMBER_OF_LISTING_RESULTS_TO_LOAD)
    const tabsAvailable = data.discoverContextByCityAndSportSlug.offerings.map(
      (offering) => DISCOVER_OFFERING_TYPE_TO_AMPLITUDE_NAME_MAPPING[offering],
    )

    // Log the viewing event of the page (same as in the old page)
    amplitude.logEvent('Viewed Discovery Sport City', {
      city: citySlug,
      sport: sportSlug,
      tab: 'venue',
      tabsAvailable: ['venue', ...tabsAvailable],
      hasFilters: true,
      hasSortings: false,
      hasTimeFilter: isCategoryWithTimeFilter,
      numberOfInitialResults,
      category: discoverSport.category.toLowerCase(),
      show,
    })
  }, [amplitude, data, discoverSport])

  const initialSearchResults = useMemo(() => {
    return data?.discoverContextByCityAndSportSlug?.venueSearch.edges
      .map((edge) => edge.node)
      .filter((venue) => venue.location.latitude && venue.location.longitude)
  }, [data])

  const onlineVenues = useMemo(
    () => data?.discoverContextByCityAndSportSlug?.onlineVenues.edges.map((edge) => edge.node),
    [data],
  )

  const venueSearchResults = useMemo(() => {
    return venueSearchData?.venueSearch.edges
      .map((edge) => edge.node)
      .filter((venue) => venue.location.latitude && venue.location.longitude)
  }, [venueSearchData])

  if (error) {
    const status = extractStatusCodeFromError(error)
    if (status) {
      setStatus(status)
    }
    if (status === 404) {
      return <NotFound />
    }
  }

  if (!data || loading) {
    return <LoadingPage />
  }

  const {
    content,
    meta,
    filters,
    city,
    offerings,
    sport: { children: subSports, ...sport },
  } = data.discoverContextByCityAndSportSlug

  if (!city || !meta || !content) {
    throw new Error(
      'The useDiscoverDiscoverContextByCityAndSportSlugQuery was probably executed with the coordinate argument instead of the city slug.',
    )
  }

  const isInitialRender = !previousVenueSearchData && !venueSearchData
  const listingResults = isInitialRender ? initialSearchResults : venueSearchResults

  return (
    <DiscoverVenuesComponent
      meta={meta}
      content={content}
      city={city}
      sport={sport}
      subSports={subSports}
      onlineVenues={onlineVenues}
      availableFilters={filters}
      listingResults={listingResults}
      offerings={offerings}
    />
  )
}

export default DiscoverVenues
