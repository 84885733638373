import Stack from '@eversports/klimt-primitives/Stack'
import Svg from '@eversports/klimt-primitives/Svg'
import Text from '@eversports/klimt-primitives/Text'
import * as React from 'react'

import { Localized } from '../../../../localization/react'
import { EventBookableItem } from '../../ActivityDetails.types'
import checkHasOneSession from '../../helpers/check-has-one-session'

import RoomIcon from './assets/room.svg'

interface Props {
  activity: EventBookableItem
}

const Room = ({ activity }: Props) => {
  const { eventSessions } = activity

  const hasOneSession = checkHasOneSession(eventSessions)

  if (hasOneSession) {
    const court = eventSessions[0].court
    if (court) {
      return (
        <Stack gap={1}>
          <Stack direction="row" alignItems="center" flexWrap="wrap" columnGap={2} rowGap={1}>
            <Svg src={RoomIcon} width={12} height={12} />
            <Text color="midnight" sx={{ fontWeight: 'bold' }}>
              <Localized id="activity-details-room-header" />
            </Text>
          </Stack>
          <Text color="midnight">{court.name}</Text>
        </Stack>
      )
    }
  }

  return null
}

export default Room
