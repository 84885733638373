import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../localization/react'

import SearchResults from './SearchResults'
import { InventorySearchResult, LocationSearchResult } from './Search.types'

interface Props<T> {
  searchValue: string
  suggestedResults?: Array<T>
  handleResultClick: (value: any) => void | Promise<void>
  handleClose: () => void
  selectedIndex?: number
}

const NoResults = ({
  searchValue,
  suggestedResults,
  handleResultClick,
  handleClose,
  selectedIndex,
}: Props<LocationSearchResult | InventorySearchResult>) => {
  const hasSuggestedResults = suggestedResults && suggestedResults.length > 0
  return (
    <>
      <Box paddingX={{ xs: 3, lg: 2 }} paddingTop={2} paddingBottom={4}>
        <Text variant="small" color="carbon">
          <Localized id="search-no-results" params={{ query: searchValue }} />
        </Text>
      </Box>
      {hasSuggestedResults && (
        <>
          <Box paddingX={{ xs: 3, lg: 2 }} paddingY={2} sx={{ borderTop: '1px solid mediumgray' }}>
            <Text variant="small" color="midnight" sx={{ fontWeight: 'bold' }}>
              <Localized id="search-suggested-results-text" />
            </Text>
          </Box>
          <SearchResults
            results={suggestedResults}
            handleResultClick={handleResultClick}
            handleClose={handleClose}
            selectedIndex={selectedIndex}
          />
        </>
      )}
    </>
  )
}

export default NoResults
