import * as React from 'react'
import { default as MuiButton, ButtonProps } from '@mui/material/Button'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import { borderRadius } from '@eversports/design-tokens/border-radius'
import useRenderLock from '@eversports/klimt-utilities/use-render-lock'

type Variant = { [key: string]: Pick<ButtonProps, 'variant' | 'sx'> }

const variants: Variant = {
  primary: {
    variant: 'contained',
    sx: {
      backgroundColor: 'primary.main',
      color: 'white',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: boxShadows.hoverPrimary,
      },
    },
  },
  secondary: {
    variant: 'outlined',
    sx: {
      backgroundColor: 'white',
      color: 'primary.main',
      border: '1px solid',
      borderColor: 'primary.main',
      '&:hover': {
        boxShadow: boxShadows.hover,
        backgroundColor: 'white',
      },
    },
  },
  tertiary: {
    variant: 'outlined',
    sx: {
      backgroundColor: 'white',
      color: 'midnight',
      border: '0.5px solid',
      borderColor: '#E0E0E0',
      boxShadow: boxShadows.default,
      '&:hover': {
        boxShadow: boxShadows.hover,
        backgroundColor: 'white',
        borderColor: '#E0E0E0',
      },
    },
  },
  danger: {
    variant: 'contained',
    sx: {
      backgroundColor: 'red',
      color: 'white',
      boxShadow: boxShadows.default,
      '&:hover': {
        boxShadow: boxShadows.hover,
        backgroundColor: 'red',
      },
    },
  },
}

interface Props extends Omit<ButtonProps, 'variant'> {
  variant: keyof typeof variants
}

const Button: React.FC<React.PropsWithChildren<Props>> = ({ variant, sx, ...props }) => {
  const locked = useRenderLock()
  const { variant: buttonVariant, sx: buttonVariantStyles } = variants[variant]

  // If the button is of type submit, always prevent it from being activated before JS is loaded on the client
  // to prevent unwanted form submit behavior
  const disabled = props.type === 'submit' ? locked || props.disabled : props.disabled

  return (
    <MuiButton
      {...props}
      sx={{
        ...buttonVariantStyles,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        borderRadius: `${borderRadius.button}px`,
        ...sx,
      }}
      variant={buttonVariant}
      disabled={disabled}
    />
  )
}

export default Button
