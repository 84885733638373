import * as React from 'react'

import FilterDrawer from '../../../../../../components/FilterDrawer'
import { Localized } from '../../../../../../localization/react'
import { Action } from '../../helpers/discover-sport-matches-reducer'
import { MatchVenue } from '../../../../../../App.types'

import VenuesFilterForm from './VenuesFilterForm'

interface Props {
  dispatch: React.Dispatch<Action>
  venues: Array<MatchVenue>
  selectedVenues?: Array<string>
}

const DrawerVenuesFilter = ({ dispatch, venues, selectedVenues }: Props) => {
  return (
    <FilterDrawer header={<Localized id="filters-matches-venues-heading" />} selectedFilterValues={selectedVenues}>
      <VenuesFilterForm dispatch={dispatch} variant="drawer" venues={venues} selectedVenues={selectedVenues} />
    </FilterDrawer>
  )
}

export default DrawerVenuesFilter
