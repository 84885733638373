import { LatLng, MarketplaceListingPagesPrefix, Sport, VenueOfferingType } from '../../App.types'

export interface City {
  name: string
  slug: string
}

export interface DiscoverySport extends Sport {
  category: SportCategory
}

export interface SportWithParents extends Sport {
  parents: Array<Sport>
}

export enum SportCategory {
  BALLSPORT = 'BALLSPORT',
  FITNESS = 'FITNESS',
  FUNSPORT = 'FUNSPORT',
}

export interface SeoContent {
  heading: string
  description: string | null
  sections: Array<Section>
  bookableSports: Array<Sport>
  similarSports: Array<Sport>
}

export interface Section {
  heading: string
  paragraph: string
}

export interface PopularCity extends City {
  popularSports: Array<Sport>
}

export interface BoundingBox {
  southWest: LatLng
  northEast: LatLng
}

export enum DiscoverOfferingType {
  DISCOVER_OFFERING_CAMP_ACTIVITY = 'DISCOVER_OFFERING_CAMP_ACTIVITY',
  DISCOVER_OFFERING_CLASS_ACTIVITY = 'DISCOVER_OFFERING_CLASS_ACTIVITY',
  DISCOVER_OFFERING_COURSE_ACTIVITY = 'DISCOVER_OFFERING_COURSE_ACTIVITY',
  DISCOVER_OFFERING_EDUCATION_ACTIVITY = 'DISCOVER_OFFERING_EDUCATION_ACTIVITY',
  DISCOVER_OFFERING_EVENT_ACTIVITY = 'DISCOVER_OFFERING_EVENT_ACTIVITY',
  DISCOVER_OFFERING_LIVESTREAM = 'DISCOVER_OFFERING_LIVESTREAM',
  DISCOVER_OFFERING_OPENTRAINING_ACTIVITY = 'DISCOVER_OFFERING_OPENTRAINING_ACTIVITY',
  DISCOVER_OFFERING_RETREAT_ACTIVITY = 'DISCOVER_OFFERING_RETREAT_ACTIVITY',
  DISCOVER_OFFERING_TRAINING_ACTIVITY = 'DISCOVER_OFFERING_TRAINING_ACTIVITY',
  DISCOVER_OFFERING_VIDEO = 'DISCOVER_OFFERING_VIDEO',
  DISCOVER_OFFERING_PUBLIC_MATCH = 'DISCOVER_OFFERING_PUBLIC_MATCH',
  DISCOVER_OFFERING_WORKSHOP_ACTIVITY = 'DISCOVER_OFFERING_WORKSHOP_ACTIVITY',
}

export const DiscoverOfferingToMarketplaceListingPageMapping = {
  [DiscoverOfferingType.DISCOVER_OFFERING_CLASS_ACTIVITY]: MarketplaceListingPagesPrefix.CLASSES,
  [DiscoverOfferingType.DISCOVER_OFFERING_COURSE_ACTIVITY]: MarketplaceListingPagesPrefix.COURSES,
  [DiscoverOfferingType.DISCOVER_OFFERING_EVENT_ACTIVITY]: MarketplaceListingPagesPrefix.EVENTS,
  [DiscoverOfferingType.DISCOVER_OFFERING_EDUCATION_ACTIVITY]: MarketplaceListingPagesPrefix.EDUCATION,
  [DiscoverOfferingType.DISCOVER_OFFERING_WORKSHOP_ACTIVITY]: MarketplaceListingPagesPrefix.WORKSHOPS,
  [DiscoverOfferingType.DISCOVER_OFFERING_TRAINING_ACTIVITY]: MarketplaceListingPagesPrefix.TRAININGS,
  [DiscoverOfferingType.DISCOVER_OFFERING_RETREAT_ACTIVITY]: MarketplaceListingPagesPrefix.RETREATS,
  [DiscoverOfferingType.DISCOVER_OFFERING_LIVESTREAM]: MarketplaceListingPagesPrefix.LIVESTREAMS,
  [DiscoverOfferingType.DISCOVER_OFFERING_VIDEO]: MarketplaceListingPagesPrefix.VIDEOS,
  [DiscoverOfferingType.DISCOVER_OFFERING_PUBLIC_MATCH]: MarketplaceListingPagesPrefix.PUBLIC_MATCHES,
  [DiscoverOfferingType.DISCOVER_OFFERING_CAMP_ACTIVITY]: MarketplaceListingPagesPrefix.CAMPS,
  [DiscoverOfferingType.DISCOVER_OFFERING_OPENTRAINING_ACTIVITY]: MarketplaceListingPagesPrefix.OPEN_TRAININGS,
}

export type VenueOfferingFilterType =
  | VenueOfferingType.VENUE_OFFERING_LIVESTREAM
  | VenueOfferingType.VENUE_OFFERING_VIDEO
  | VenueOfferingType.VENUE_OFFERING_INDOOR_ACTIVITY
  | VenueOfferingType.VENUE_OFFERING_OUTDOOR_ACTIVITY

export enum ActivityAvailability {
  ACTIVITY_AVAILABILITY_MORNING = 'ACTIVITY_AVAILABILITY_MORNING',
  ACTIVITY_AVAILABILITY_AFTERNOON = 'ACTIVITY_AVAILABILITY_AFTERNOON',
  ACTIVITY_AVAILABILITY_EVENING = 'ACTIVITY_AVAILABILITY_EVENING',
}

export enum VenueAttributes {
  VENUE_ATTRIBUTES_FREE_TRIAL = 'VENUE_ATTRIBUTES_FREE_TRIAL',
  VENUE_ATTRIBUTES_INTRO_OFFER = 'VENUE_ATTRIBUTES_INTRO_OFFER',
  VENUE_ATTRIBUTES_CORPORATE_DISCOUNT = 'VENUE_ATTRIBUTES_CORPORATE_DISCOUNT',
}

export enum CourtAreaType {
  INDOOR = 'INDOOR',
  OUTDOOR = 'OUTDOOR',
}

export const CourtAreaTypeToNameMapping = {
  [CourtAreaType.INDOOR]: 'filters-court-area-indoor',
  [CourtAreaType.OUTDOOR]: 'filters-court-area-outdoor',
} as const

export enum CourtSurfaceType {
  ARTIFICIAL_GRASS = 'ARTIFICIAL_GRASS',
  ARTIFICIAL_ICE = 'ARTIFICIAL_ICE',
  ASH = 'ASH',
  ASPHALT = 'ASPHALT',
  CARPET = 'CARPET',
  CARPET_GRANULE = 'CARPET_GRANULE',
  CONCRETE = 'CONCRETE',
  FELT = 'FELT',
  GRANULE = 'GRANULE',
  GRASS = 'GRASS',
  HARD_COURT = 'HARD_COURT',
  ICE = 'ICE',
  OPTICOURT = 'OPTICOURT',
  PARQUET = 'PARQUET',
  PVC = 'PVC',
  RED_COURT = 'RED_COURT',
  SAND = 'SAND',
  SYNTHETIC = 'SYNTHETIC',
  TARTAN = 'TARTAN',
  WATER = 'WATER',
}

export const CourtSurfaceTypeToNameMapping = {
  [CourtSurfaceType.ARTIFICIAL_GRASS]: 'filters-court-surface-type-artificial-grass',
  [CourtSurfaceType.ARTIFICIAL_ICE]: 'filters-court-surface-type-artificial-ice',
  [CourtSurfaceType.ASH]: 'filters-court-surface-type-ash',
  [CourtSurfaceType.ASPHALT]: 'filters-court-surface-type-asphalt',
  [CourtSurfaceType.CARPET]: 'filters-court-surface-type-carpet',
  [CourtSurfaceType.CARPET_GRANULE]: 'filters-court-surface-type-carpet-granule',
  [CourtSurfaceType.CONCRETE]: 'filters-court-surface-type-concrete',
  [CourtSurfaceType.FELT]: 'filters-court-surface-type-felt',
  [CourtSurfaceType.GRANULE]: 'filters-court-surface-type-granuale',
  [CourtSurfaceType.GRASS]: 'filters-court-surface-type-grass',
  [CourtSurfaceType.HARD_COURT]: 'filters-court-surface-type-hard-court',
  [CourtSurfaceType.ICE]: 'filters-court-surface-type-ice',
  [CourtSurfaceType.OPTICOURT]: 'filters-court-surface-type-opticourt',
  [CourtSurfaceType.PARQUET]: 'filters-court-surface-type-parquet',
  [CourtSurfaceType.PVC]: 'filters-court-surface-type-pvc',
  [CourtSurfaceType.RED_COURT]: 'filters-court-surface-type-red-court',
  [CourtSurfaceType.SAND]: 'filters-court-surface-type-sand',
  [CourtSurfaceType.SYNTHETIC]: 'filters-court-surface-type-synthetic',
  [CourtSurfaceType.TARTAN]: 'filters-court-surface-type-tartan',
  [CourtSurfaceType.WATER]: 'filters-court-surface-type-water',
} as const

export interface AvailableFiltersType {
  surfaces: Array<CourtSurfaceType> | null
}

export enum VenueSearchSort {
  CONSIDER_DISTANCE = 'CONSIDER_DISTANCE',
  DEFAULT = 'DEFAULT',
}
