import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'

const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/

interface Props {
  encodedTo: string
  external?: boolean
  blank?: boolean
  ariaLabel?: string
}

export interface HideFromCrawlerProps extends Props {
  type: 'hidden'
}

export interface SEOLinkProps extends Props {
  type: 'crawler'
  crawlerLink: string
}

const SeoLink: React.FC<React.PropsWithChildren<HideFromCrawlerProps | SEOLinkProps>> = (props) => {
  const { children, external = false, blank = false, encodedTo, ariaLabel, type } = props

  // Check that the url is encoded and not seen by Google
  if (!base64regex.test(encodedTo)) {
    throw new Error(`The SEO url is not base64 encoded: ${encodedTo}`)
  }

  const initialTo = type === 'crawler' ? props.crawlerLink : '#'

  const [to, setTo] = useState(initialTo)
  const linkElRef = useRef<HTMLAnchorElement | null>(null)

  const blankProps = blank ? { target: `_blank`, rel: 'noopener' } : {}
  const ariaLabelProps = ariaLabel ? { 'aria-label': ariaLabel } : {}

  useEffect(() => {
    if (!linkElRef.current) return

    const handleFirstInteraction = () => setTo(atob(encodedTo))

    linkElRef.current.addEventListener('touchstart', handleFirstInteraction, { once: true })
    linkElRef.current.addEventListener('mousedown', handleFirstInteraction, { once: true })
    linkElRef.current.addEventListener('onfocus', handleFirstInteraction, { once: true })
    linkElRef.current.addEventListener('mousemove', handleFirstInteraction, { once: true })

    return () => {
      if (!linkElRef.current) return
      linkElRef.current.removeEventListener('touchstart', handleFirstInteraction)
      linkElRef.current.removeEventListener('mousedown', handleFirstInteraction)
      linkElRef.current.removeEventListener('onfocus', handleFirstInteraction)
      linkElRef.current.removeEventListener('mousemove', handleFirstInteraction)
    }
  }, [])

  return external ? (
    <a ref={linkElRef} href={to} {...blankProps} {...ariaLabelProps} style={{ display: 'contents' }}>
      {children}
    </a>
  ) : (
    <RouterLink ref={linkElRef} to={to} {...ariaLabelProps} {...blankProps} style={{ display: 'contents' }}>
      {children}
    </RouterLink>
  )
}

export default SeoLink
