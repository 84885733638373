import * as React from 'react'
import { getLanguageFromLanguageWithLocale, Language, SUPPORTED_LANGUAGES } from '@eversports/language'
import { isToday, isTomorrow } from 'date-fns'
import { useEffect, useState } from 'react'

import { Localized } from '../../localization/react'

interface Props {
  value: Date
}

const getLanguage = (): Language => {
  const locale = getLanguageFromLanguageWithLocale(navigator.language)
  return SUPPORTED_LANGUAGES.has(locale) ? locale : 'en'
}

const FormatDate = ({ value }: Props) => {
  const [language, setLanguage] = useState<Language>('en')

  useEffect(() => {
    setLanguage(getLanguage())
  }, [])

  if (isToday(value)) return <Localized id="today" />
  if (isTomorrow(value)) return <Localized id="tomorrow" />
  const dateToShow = value.toLocaleDateString(language, {
    month: '2-digit',
    day: '2-digit',
  })
  return <>{dateToShow}</>
}

export default FormatDate
