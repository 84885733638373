import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import FilledStar from '@eversports/design-tokens/assets/icons/filled-star.svg'
import EmptyStar from '@eversports/design-tokens/assets/icons/empty-star.svg'
import Link from '@eversports/klimt-primitives/Link'

import { Localized } from '../../../../localization/react'
import { VENUE_PROFILE_RATING_ID } from '../../VenueProfile.constants'

interface Props {
  rating: number | null
  reviewCount: number
}

const Rating = ({ rating, reviewCount }: Props) => {
  return (
    <Stack direction="row" alignItems="center" sx={{ cursor: rating ? 'pointer' : 'default' }}>
      <img
        src={reviewCount ? FilledStar : EmptyStar}
        alt="rating"
        loading="lazy"
        width="14px"
        height="14px"
        style={{ marginRight: '8px' }}
      />
      <Link to={`#${VENUE_PROFILE_RATING_ID}`} external>
        <Text
          variant="regular"
          sx={{ color: 'darkgray', fontWeight: 'bold', textDecoration: rating ? 'underline' : 'default' }}
        >
          {rating ? (
            <Localized id="rating-review-count" params={{ rating, reviewCount }} />
          ) : (
            <Localized id="rating-no-reviews" />
          )}
        </Text>
      </Link>
    </Stack>
  )
}

export default Rating
