import * as React from 'react'
import { addWeeks } from 'date-fns'
import DatePicker from '@eversports/klimt-patterns/DatePicker'
import Text from '@eversports/klimt-primitives/Text'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import { Localized } from '../../../localization/react'
import { useDiscoverReducer, useDiscoverState } from '../DiscoverContext'

import { DiscoverFilterTypes } from './Filters.types'
import getVenueFilterAmplitudeEventName from './helpers/get-venue-filter-amplitude-event-name'

interface Props {
  isMobile?: boolean
}

export const VenueCourtSlotDateFilter = ({ isMobile = false }: Props) => {
  const dispatch = useDiscoverReducer()
  const { venueCourtSlotDateActiveFilter } = useDiscoverState()
  const maxDate = addWeeks(new Date(), 2)

  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()

  const handleDateChange = (date: Date | null) => {
    if (!date) return

    logAmplitudeEventOnce({
      eventName: getVenueFilterAmplitudeEventName(),
      eventProperties: { type: DiscoverFilterTypes.date, tab: 'venue' },
    })

    dispatch({ type: 'UPDATE_COURT_SLOT_DATE_FILTER', payload: date })
  }

  return (
    <DatePicker
      value={venueCourtSlotDateActiveFilter}
      onChange={handleDateChange}
      maxDate={maxDate}
      variant="pulsating"
      isMobile={isMobile}
    >
      <Text variant="small" sx={{ color: 'darkgray', textAlign: 'center', mb: isMobile ? 4 : 0 }}>
        <Localized id="only-bookable-14-days-ahead" />
      </Text>
    </DatePicker>
  )
}
