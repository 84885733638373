import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SharingMessage } from '@eversports/klimt-patterns/SharingActions'

import { useUseSharingMessagePublicUserQuery } from '../../../graphql'
import { useIntl } from '../../../localization/react'
import { UserType } from '../PublicUserProfile.types'

import useLoggedInUserType from './use-logged-in-user-type'

const useSharingMessage = (): SharingMessage => {
  const intl = useIntl()
  const { userType } = useLoggedInUserType()
  const { userHandle } = useParams<{ userHandle: string }>()
  const { data } = useUseSharingMessagePublicUserQuery({ variables: { handle: userHandle } })
  const [url, setUrl] = useState<string | undefined>()

  useEffect(() => {
    setUrl(`${window.location.origin}/user/${userHandle}`)
  }, [])

  if (!url || !data) return { encodedMessage: '', translatedMessage: '', linkToCopy: '' }

  const isProfileOwner = userType === UserType.PROFILE_OWNER

  //   Message that is shared when the user is the owner of the profile:
  //
  //   Add me on Eversports!
  //   ➡️ ${url}
  //
  //   Message that is shared otherwise:
  //
  //   View ${firstName}'s Profile on Eversports!
  //   ➡️ ${url}
  const translatedMessage = isProfileOwner
    ? intl.userProfileSharingMessage({ url })
    : intl.anotherUserProfileSharingMessage({ url, firstName: data.publicUser.firstName })

  return {
    encodedMessage: encodeURIComponent(translatedMessage),
    translatedMessage: translatedMessage,
    linkToCopy: url,
  }
}

export default useSharingMessage
