import { typography } from '@eversports/design-tokens/typography'
import { palette } from '@eversports/design-tokens/palette'

// Reference: https://www.joshwcomeau.com/css/custom-css-reset/
const resetCss = `
    *, *::before, *::after {
      box-sizing: border-box;
    }
    * {
      margin: 0;
    }
    html, body {
      font-size: ${typography.body.regular.fontSize}px;
      color: ${palette.darkgray};
    }
    body {
      line-height: ${typography.body.regular.lineHeight};
      -webkit-font-smoothing: antialiased;
    }
    img, picture, video, canvas, svg {
      display: block;
      max-width: 100%;
    }
    input, button, textarea, select {
      font: inherit;
    }
    p, h1, h2, h3, h4, h5, h6 {
      overflow-wrap: break-word;
    }
    #root {
      isolation: isolate;
    }
    a {
      text-decoration: none;
    }
`

export default resetCss
