import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import PlaceholderImage from '../assets/gallery-placeholder-image.webp'
import { Image } from '../../../VenueProfile.types'

import { customBreakpoints } from './Gallery.constants'

interface Props {
  idx: number
  onClick: () => void
  image?: Image
}

const GalleryImage = ({ image, idx, onClick: handleClick }: Props) => {
  const theme = useTheme()

  // We just know that this breakpoint represents a specific larger size where we need to render a second main image
  const isCustomBreakpoint6 = useMediaQuery(theme.breakpoints.up(customBreakpoints.CUSTOM_BREAKPOINT_6))
  const isCustomBreakpoint7 = useMediaQuery(theme.breakpoints.up(customBreakpoints.CUSTOM_BREAKPOINT_7))

  const isMainImage = idx === 0 || ((isCustomBreakpoint6 || isCustomBreakpoint7) && idx === 1)
  const imgUrl = isMainImage ? image?.xLarge : image?.small ?? undefined
  const canViewImage = Boolean(imgUrl)

  return (
    <Box
      onClick={canViewImage ? handleClick : null}
      sx={{
        borderRadius: 1,
        overflow: 'hidden',
        cursor: canViewImage ? 'pointer' : 'default',
        '&:hover': { boxShadow: canViewImage ? boxShadows.hover : 'default' },
        backgroundColor: 'lightgray',
      }}
    >
      <img
        src={imgUrl ?? PlaceholderImage}
        alt={imgUrl ? 'Image of the Venue' : 'Venue Profile Image'}
        loading="eager"
        width="100%"
        height="100%"
        style={{ objectFit: 'cover' }}
      />
    </Box>
  )
}

export default GalleryImage
