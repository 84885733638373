import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import Text from '@eversports/klimt-primitives/Text'
import Processing from '@eversports/klimt-primitives/Processing'
import OutlinedCheckmarkIcon from '@eversports/design-tokens/assets/icons/outlined-checkmark.svg'
import Box from '@eversports/klimt-primitives/Box'

import { Localized } from '../../../../localization/react'
import MobileButtonContainer from '../components/MobileButtonContainer'

interface ManageParticipationProps {
  handleClick: () => void
  isUserLeavingMatch: boolean
  isMobile: boolean
  isDisabled: boolean
}

type ManageParticipantComponentProps = Omit<ManageParticipationProps, 'isMobile'>

const ManageParticipationComponent = ({
  handleClick,
  isUserLeavingMatch,
  isDisabled,
}: ManageParticipantComponentProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      textAlign="center"
      justifyContent={['space-between', 'center']}
      sx={{ minHeight: '45px', flexWrap: 'wrap' }}
      gap={2}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
        px={3}
        py={2}
        flex={[1.5, 'auto']}
        sx={{ borderRadius: 10, backgroundColor: 'rgba(33, 166, 150, 0.08)' }}
      >
        <Icon src={OutlinedCheckmarkIcon} color="primary" size="small" />
        <Text variant="badge" sx={{ color: 'primary.main' }}>
          <Localized id="match-participation-successful-message" />
        </Text>
      </Stack>
      <Box flex={[1, 'auto']}>
        <Processing isLoading={isUserLeavingMatch}>
          <Text
            variant="badge"
            sx={{
              color: 'red',
              cursor: 'pointer',
              opacity: isDisabled ? 0.25 : 1,
              pointerEvents: isDisabled ? 'none' : 'initial',
            }}
            onClick={handleClick}
          >
            <Localized id="match-participation-remove-button" />
          </Text>
        </Processing>
      </Box>
    </Stack>
  )
}

const ManageParticipation = ({ isUserLeavingMatch, handleClick, isMobile, isDisabled }: ManageParticipationProps) => {
  return isMobile ? (
    <MobileButtonContainer>
      <ManageParticipationComponent
        isUserLeavingMatch={isUserLeavingMatch}
        handleClick={handleClick}
        isDisabled={isDisabled}
      />
    </MobileButtonContainer>
  ) : (
    <ManageParticipationComponent
      isUserLeavingMatch={isUserLeavingMatch}
      handleClick={handleClick}
      isDisabled={isDisabled}
    />
  )
}

export default ManageParticipation
