import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Skeleton from '@eversports/klimt-primitives/Skeleton'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

interface Props {
  numberOfCopies: number
}

const MatchSkeleton = ({ numberOfCopies }: Props) => {
  const matchCards = [...Array(numberOfCopies)]
  return (
    <>
      {matchCards.map((_, index) => (
        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid',
            borderColor: 'mediumgray',
            boxShadow: boxShadows.default,
            borderRadius: 1,
            height: '100%',
            '&:hover': {
              boxShadow: boxShadows.hover,
            },
          }}
          key={index}
        >
          <Stack gap={2} py={4} px={3}>
            <Stack direction="row" justifyContent="space-between">
              <Skeleton variant="rectangular" width="30%" />
              <Skeleton variant="circular" width="42px" height="26px" />
            </Stack>
            <Skeleton variant="rectangular" width="70%" />
            <Skeleton variant="rectangular" width="60%" />
          </Stack>
          <Stack
            py={2}
            px={3}
            sx={{
              background: 'linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0) 100%)',
              position: 'relative',
            }}
          >
            <Skeleton variant="rectangular" width="30%" />
          </Stack>
        </Box>
      ))}
    </>
  )
}

export default MatchSkeleton
