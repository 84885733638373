import { EventType } from '../../../App.types'

export const EventTypeToNameMapping = {
  [EventType.CAMP]: 'activity-event-type-camp',
  [EventType.CLASS]: 'activity-event-type-class',
  [EventType.COURSE]: 'activity-event-type-course',
  [EventType.EDUCATION]: 'activity-event-type-education',
  [EventType.EVENT]: 'activity-event-type-event',
  [EventType.OPENTRAINING]: 'activity-event-type-open-training',
  [EventType.RETREAT]: 'activity-event-type-retreat',
  [EventType.TRAINING]: 'activity-event-type-training',
  [EventType.WORKSHOP]: 'activity-event-type-workshop',
} as const
