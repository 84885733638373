import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box from '@eversports/klimt-primitives/Box'

const BottomFixedSection = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <Box
      sx={{
        py: 2,
        px: 4,
        zIndex: 2,
        bottom: 0,
        backgroundColor: 'white',
        position: 'fixed',
        width: '100%',
        border: '1px solid',
        borderColor: 'lightgray',
        boxShadow: boxShadows.default,
        display: { xs: 'flex', lg: 'none' },
      }}
    >
      {children}
    </Box>
  )
}

export default BottomFixedSection
