import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'

interface ClickableProps {
  onClick?: () => void
}

export const Badge = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <Box
      sx={{
        px: 4,
        py: 1,
        background: '#FFFFFF',
        border: '0.5px solid',
        borderColor: 'lightgray',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 2px #DDDDDD',
        borderRadius: 80,
        '&:hover': { backgroundColor: 'lightgray' },
      }}
    >
      <Text variant="badge" sx={{ fontWeight: 'bold' }}>
        {children}
      </Text>
    </Box>
  )
}

export const TagBadge = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        background: '#EFFFFD',
        border: '1px solid #CCFFF9',
        boxSizing: 'border-box',
        borderRadius: 80,
      }}
    >
      <Text variant="small" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        {children}
      </Text>
    </Box>
  )
}

export const SvgIconBadge = ({ children, onClick }: React.PropsWithChildren<ClickableProps>) => {
  return (
    <Box
      sx={{
        background: '#FFFFFF',
        border: '0.5px solid',
        borderColor: 'lightgray',
        boxShadow: '0px 1px 2px #DDDDDD',
        borderRadius: 80,
        cursor: 'pointer',
        width: '44px',
        height: '44px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}

export default Badge
