import { MatchesFiltersState } from './Matches.types'

export const AMPLITUDE_VENUE_PROFILE_MATCH_FILTER_EVENT = 'Used Venue Profile Match Filter'
export const NUMBER_OF_INITIAL_MATCHES_FETCHED = 12
export const NUMBER_OF_SHOWN_PARTICIPANTS = 2

export const matchesFiltersInitialState: MatchesFiltersState = {
  sports: undefined,
  date: null,
}
