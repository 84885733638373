import * as React from 'react'
import { useParams } from 'react-router-dom'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Processing from '@eversports/klimt-primitives/Processing'
import { useState } from 'react'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { useCommentSectionAddMatchCommentMutation, useCommentsSectionMatchQuery } from '../../../graphql'
import Card from '../components/Card'
import { Localized } from '../../../localization/react'
import { userTypeMapping } from '../Match.constants'
import useLoggedInUserType from '../hooks/use-logged-in-user-type'

import Comment from './Comment'
import NewCommentInput from './NewCommentInput'
import FullscreenCommentsSection from './FullscreenCommentsSection'
import CommentsArea from './CommentsArea'
import { NUMBER_OF_PREVIEW_COMMENTS } from './CommentsSection.constants'
import useMatchComments from './hooks/useMatchComments'
import ViewMoreComments from './ViewMoreComments'

const CommentsSection = () => {
  const { id } = useParams<{ id: string }>()
  const { amplitude } = useAmplitude()
  const { userType } = useLoggedInUserType()
  const [isFullscreenCommentSectionOpen, setIsFullscreenCommentSectionOpen] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    comments,
    loading: isLoadingMoreComments,
    fetchMoreComments,
    fetchLastComment,
    hasMoreResults,
  } = useMatchComments()
  const { data } = useCommentsSectionMatchQuery({ variables: { matchId: id } })
  const [addMatchComment, { loading }] = useCommentSectionAddMatchCommentMutation({
    onCompleted: fetchLastComment,
  })

  const handleCommentSubmit = ({ comment }: { comment: string }) => {
    void addMatchComment({ variables: { matchId: id, text: comment } })
    if (amplitude) {
      amplitude.logEvent('Added Match Comment', {
        currentNumberOfParticipants: data?.match.participants.length,
        userType: userTypeMapping[userType],
        settings: {
          maxParticipants: data?.match.maxParticipants,
          level: data?.match.level,
          competitionType: data?.match.competitionType,
          gender: data?.match.gender,
          visibility: data?.match.visibility,
        },
      })
    }
  }

  const handleShowMoreComments = () => {
    fetchMoreComments()
  }

  const handleShowCommentsInModal = () => {
    setIsFullscreenCommentSectionOpen(true)
    handleShowMoreComments()
    if (amplitude) {
      amplitude.logEvent('Viewed Match Comments Details', {
        currentNumberOfParticipants: data?.match.participants.length,
        userType: userTypeMapping[userType],
        settings: {
          maxParticipants: data?.match.maxParticipants,
          level: data?.match.level,
          competitionType: data?.match.competitionType,
          gender: data?.match.gender,
          visibility: data?.match.visibility,
        },
      })
    }
  }

  const handleDialogClose = () => {
    setIsFullscreenCommentSectionOpen(false)
  }

  const isCommentSectionEmpty = comments.length === 0

  return (
    <Card sx={{ px: 1 }}>
      <Stack direction="row" alignItems="center" gap={2} p={4}>
        <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>
          <Localized id="comments-section-title" />
        </Text>
        {/* TODO: Uncomment when we have notifications */}
        {/*<Text variant="xsmall" sx={{ color: 'primary.main', textDecoration: 'underline', cursor: 'pointer' }}>*/}
        {/*  <Localized id="comments-section-notifications-title" />*/}
        {/*</Text>*/}
      </Stack>
      <Processing isLoading={loading}>
        {isMobile ? (
          <CommentsArea variant="contained" isEmpty={isCommentSectionEmpty}>
            {comments.slice(0, NUMBER_OF_PREVIEW_COMMENTS).map((comment) => (
              <Comment key={comment.id} comment={comment} />
            ))}
            {comments.length > NUMBER_OF_PREVIEW_COMMENTS && (
              <ViewMoreComments handleClick={handleShowCommentsInModal} isLoadingMoreComments={isLoadingMoreComments} />
            )}
            <FullscreenCommentsSection
              isLoadingMoreComments={isLoadingMoreComments}
              isOpen={isFullscreenCommentSectionOpen}
              onClose={handleDialogClose}
              onSubmit={handleCommentSubmit}
              hasMoreResults={hasMoreResults}
              onShowMoreComments={handleShowMoreComments}
              isEmpty={isCommentSectionEmpty}
              comments={comments}
            />
          </CommentsArea>
        ) : (
          <CommentsArea variant="contained" isEmpty={isCommentSectionEmpty}>
            {comments.map((comment) => (
              <Comment key={comment.id} comment={comment} />
            ))}
            {hasMoreResults && (
              <ViewMoreComments handleClick={handleShowMoreComments} isLoadingMoreComments={isLoadingMoreComments} />
            )}
          </CommentsArea>
        )}
      </Processing>
      <NewCommentInput onSubmit={handleCommentSubmit} />
    </Card>
  )
}

export default CommentsSection
