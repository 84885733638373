import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import { CitySearchResult } from '../../Search.types'
import { useIntl } from '../../../../localization/react'

export const getLocationPlaceholder = (location?: CitySearchResult) => {
  const intl = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (!location) {
    return intl.searchForLocationLabel()
  }

  if (location.isCurrentLocation) {
    return `${location.name}, ${location.country} (${intl.searchCurrentLocation()})`
  }

  if (isMobile) {
    return location.name
  }

  return `${location.name}, ${location.country}`
}
