import * as React from 'react'
import { useEffect, useState } from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Dialog, { DialogContent, DialogTitle } from '@eversports/klimt-primitives/Dialog'
import Button from '@eversports/klimt-primitives/Button'
import Stack from '@eversports/klimt-primitives/Stack'
import { useHistory, useParams } from 'react-router-dom'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import CircularProgress from '@eversports/klimt-primitives/CircularProgress'

import TrashIcon from '../assets/trash.svg'
import { Localized } from '../../../localization/react'
import { MatchStatus, UserType } from '../Match.types'
import { BallsportMatchState, useCancelMatchMatchQuery, useCancelMatchMutation } from '../../../graphql'
import { originMapping, pageTypeMapping } from '../Match.constants'
import useLoggedInUserType from '../hooks/use-logged-in-user-type'
import usePageType from '../hooks/use-page-type'
import useMatchStatus from '../hooks/use-match-status'
import useUrlParams from '../../../hooks/use-url-params'

import ActionWrapper from './ActionWrapper'
import { ActionWrapperVariant } from './AdditionalActions.types'

interface Props {
  variant?: ActionWrapperVariant
}

const CancelMatch = ({ variant }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const history = useHistory()
  const { amplitude } = useAmplitude()
  const matchStatus = useMatchStatus()

  const { id } = useParams<{ id: string }>()
  const { origin } = useUrlParams()
  const { userType } = useLoggedInUserType()
  const pageType = usePageType()

  const { data } = useCancelMatchMatchQuery({ variables: { matchId: id } })

  const [cancelMatch, { loading, error: cancelError }] = useCancelMatchMutation({
    variables: { matchId: id, origin: originMapping[origin] },
    onCompleted: (cancelData) => {
      if (cancelData && cancelData.cancelMatch.__typename === 'BallsportMatch') {
        history.replace(cancelData.cancelMatch.relativeLink)
      }
      if (cancelData && cancelData.cancelMatch.__typename === 'ExpectedErrors') {
        const error = cancelData.cancelMatch.errors[0]
        setErrorMessage(error.message)
      }
    },
  })

  useEffect(() => {
    if (cancelError) {
      throw new Error(cancelError.message)
    }
  }, [cancelError])

  const openDialog = React.useCallback(() => {
    if (amplitude) {
      amplitude.logEvent('Clicked Cancel Button', {
        from: pageTypeMapping[pageType],
      })
    }
    setIsDialogOpen(true)
  }, [amplitude])

  const handleClickConfirm = async () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Confirm Cancel Button')
    }
    await cancelMatch()
  }

  const canCancel = userType === UserType.ORGANIZER && data?.match.state !== BallsportMatchState.CANCELLED

  if (!canCancel || !data || !data.match.relativeLink) {
    return null
  }

  const isDisabled = matchStatus !== MatchStatus.UPCOMING

  return (
    <>
      <ActionWrapper onClick={openDialog} isDisabled={isDisabled} variant={variant}>
        <img src={TrashIcon} alt="Cancel match" loading="lazy" width="14px" height="14px" />
        <Text>
          <Localized id="cancel-match" />
        </Text>
      </ActionWrapper>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        sx={{
          '& .MuiDialog-paper': {
            background: '#ffffff',
            border: '1px solid #F2F2F2',
            boxSizing: 'border-box',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.12)',
            borderRadius: '16px',
            width: { xs: '90vw', md: '680px' },
          },
        }}
      >
        <DialogTitle>
          <Text variant="large" sx={{ textAlign: 'center', fontWeight: 'bold', margin: 2 }}>
            <Localized id="cancel-match-and-booking" />
          </Text>
        </DialogTitle>
        <DialogContent>
          <Stack gap={5}>
            <Text sx={{ color: errorMessage ? 'red' : 'initial' }}>
              {errorMessage ?? <Localized id="cancel-modal-information" />}
            </Text>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
              {!errorMessage && (
                <Button
                  onClick={() => void handleClickConfirm()}
                  disabled={loading || Boolean(errorMessage)}
                  variant="danger"
                  sx={{
                    flex: 1,
                    padding: 3,
                  }}
                >
                  {loading ? (
                    <CircularProgress size="20px" sx={{ color: 'white' }} />
                  ) : (
                    <Text sx={{ color: 'white', fontWeight: 'bold' }}>
                      <Localized id="cancel-modal-confirm-cancel" />
                    </Text>
                  )}
                </Button>
              )}
              <Button variant="tertiary" onClick={() => setIsDialogOpen(false)} sx={{ flex: 1, padding: 3 }}>
                <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>
                  <Localized id="dismiss-action" />
                </Text>
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CancelMatch
