import { LocationType } from '../ActivityDetails.types'

export const formatLocation = (location: LocationType) => {
  const { street, detail: doorNumber, zip, city } = location

  const streetAndNumber = doorNumber ? `${street} ${doorNumber}` : street
  const zipAndCity = zip && city ? `, ${zip} ${city}` : city ?? ''

  return streetAndNumber.concat(zipAndCity)
}
