import * as React from 'react'
import Alert from '@eversports/klimt-primitives/Alert'
import Text from '@eversports/klimt-primitives/Text'
import Snackbar from '@eversports/klimt-primitives/Snackbar'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import { Localized } from '../../../../../localization/react'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const PrivateMatchToggledSnackbar = ({ isOpen, onClose }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: isMobile ? 'center' : 'right' }}
      sx={{ bottom: '80px' }}
      onClose={onClose}
    >
      <Alert severity="success">
        <Text sx={{ color: 'black' }}>
          <Localized id="private-match-toggled-snackbar-message" />
        </Text>
      </Alert>
    </Snackbar>
  )
}

export default PrivateMatchToggledSnackbar
