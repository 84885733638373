import * as React from 'react'
import Icon from '@eversports/klimt-primitives/Icon'
import DotsMenuIcon from '@eversports/design-tokens/assets/icons/dots-menu.svg'
import { useState } from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box from '@eversports/klimt-primitives/Box'

import { UserType } from '../Match.types'
import useLoggedInUserType from '../hooks/use-logged-in-user-type'
import Drawer from '../../../components/Drawer'

import SaveMatchToCalendar from './SaveMatchToCalendar'
import OpenInGoogleMaps from './OpenInGoogleMaps'
import CancelMatch from './CancelMatch'
import ShareMatch from './ShareMatch'

const MobileAdditionalActions = () => {
  const { userType } = useLoggedInUserType()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleClick = () => {
    setIsDrawerOpen(true)
  }

  const handleClose = () => {
    setIsDrawerOpen(false)
  }

  return (
    <>
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'mediumgray',
          backgroundColor: 'white',
          boxShadow: boxShadows.default,
          borderRadius: 1,
          padding: 1,
        }}
        onClick={handleClick}
      >
        <Icon src={DotsMenuIcon} size="default" color="black" />
      </Box>
      <Drawer open={isDrawerOpen} onClose={handleClose} sx={{ '.MuiDrawer-paper': { padding: 0 } }}>
        <SaveMatchToCalendar variant="expanded" />
        <OpenInGoogleMaps variant="expanded" />
        {userType === UserType.ORGANIZER ? <CancelMatch variant="expanded" /> : <ShareMatch variant="expanded" />}
      </Drawer>
    </>
  )
}

export default MobileAdditionalActions
