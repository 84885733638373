import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Animation from '@eversports/klimt-animations/Animation'

import { Localized } from '../localization/react'
import RegisterAnimation from '../assets/register-animation.lottie'

interface Props {
  handleClick: () => void
  descriptionType: 'joining-a-match' | 'favoriting-venue' | 'adding-a-friend'
}

const LoginOrRegisterDisclaimer = ({ handleClick, descriptionType }: Props) => {
  return (
    <Stack gap={3} alignItems="center">
      <Animation src={RegisterAnimation} style={{ width: 'fit-content' }} loop autoplay />
      <Stack gap={1}>
        <Heading is="h3" variant="large">
          <Localized id="guest-disclaimer-section-title" />
        </Heading>
        <Text sx={{ color: 'darkgray' }}>
          <Localized id="login-or-register-disclaimer-description" params={{ descriptionType }} />
        </Text>
      </Stack>
      <Button variant="primary" sx={{ p: 3 }} onClick={handleClick} fullWidth>
        <Text sx={{ color: 'white', fontWeight: 'bold', textShadow: boxShadows.default, textTransform: 'none' }}>
          <Localized id="guest-disclaimer-section-button" />
        </Text>
      </Button>
    </Stack>
  )
}

export default LoginOrRegisterDisclaimer
