import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'

interface Props {
  name: string
}

const FriendUpcomingParticipationName = ({ name }: Props) => {
  return (
    <Text
      variant="large"
      sx={{ fontWeight: 'bold', color: 'midnight', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
    >
      {name}
    </Text>
  )
}

export default FriendUpcomingParticipationName
