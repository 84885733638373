import {
  RatingType,
  MonetaryValue,
  Venue as AppVenue,
  VenueAmenityType,
  VenueOfferingType,
  Sport,
  Company,
  Location,
} from '../../App.types'
import { VenueFaqQuestionType } from '../../graphql'

export interface Venue extends Omit<AppVenue, 'profileImage' | 'location'> {
  amenities: Array<VenueAmenityType>
  offerings: Array<VenueOfferingType>
  images: Array<Image>
  about: About
  location: Location
  city: City
  company: Company
  trainers: Array<Trainer>
  reviews: Array<Review>
  faqs: Array<Faq>
  cheapestPrice?: MonetaryValue | null
  cheapestTrialProductPrice?: MonetaryValue | null
  logo: Image | null
  contact: Contact
  ratings: Array<Rating>
  sports: Array<Sport>
  hideAddress: boolean
}

export type ImageSize = 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'

export interface Image {
  xLarge?: string
  large?: string
  medium?: string
  small?: string
  xSmall?: string
}
export interface About {
  general: string
  team: string | null
}

export interface City {
  id: string
  slug: string
}

export interface Trainer {
  id: string
  image: Image | null
  nickname: string | null
  sports: Array<Sport>
  cursor: string | undefined
}

interface User {
  id: string
  firstName: string
  lastName: string
  profilePicture: Image | null
}

export interface Review {
  id: string
  comment: string | null
  user: User
  createdAt: Date | string
  cursor: string | undefined
}

export interface Faq {
  question: VenueFaqQuestionType
  answer: string
}

export interface Contact {
  email: string | null
  facebook: string | null
  instagram: string | null
  website: string | null
  telephone: string | null
}

export interface Rating {
  type: RatingType
  value: number
}

export const RatingTypeToNameMapping = {
  ROOM_QUALITY: 'rating-type-room-quality',
  FRIENDLINESS_SERVICE: 'rating-type-friendliness-and-service',
  CLEANLINESS: 'rating-type-cleanliness',
  ATMOSPHERE: 'rating-type-atmosphere',
  PRICE_PERFORMANCE: 'rating-type-price-performance',
} as const

export const FaqTypeToNameMapping = {
  FIRST_TIME: 'faq-type-what-to-know-for-first-visit',
  EQUIPMENT: 'faq-type-what-to-know-about-needed-equipment',
  CANCEL_INFO: 'faq-type-what-to-know-about-cancelling',
  EXTRA_INFO: 'faq-type-extra-info-to-know',
} as const

export type VenueOffering = VenueOfferingType
