import * as React from 'react'
import KlimtCheckbox, { CheckboxProps } from '@eversports/klimt-primitives/Checkbox'
import { useFormContext, Controller, ControllerProps } from 'react-hook-form'

export type Props = CheckboxProps & Omit<ControllerProps, 'render'>

const Checkbox: React.FC<React.PropsWithChildren<Props>> = ({
  rules,
  name,
  shouldUnregister,
  defaultValue,
  ...props
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      render={({ field: { value: fieldValue, name, onChange: fieldOnChange, onBlur } }) => {
        return (
          <KlimtCheckbox
            name={name}
            value={props.value}
            checked={Array.isArray(fieldValue) && fieldValue.includes(props.value)}
            onBlur={onBlur}
            onChange={(event, checked) => {
              if (checked) {
                fieldOnChange([...fieldValue, event.target.value])
              } else {
                fieldOnChange(
                  Array.isArray(fieldValue) && fieldValue.filter((value: string) => value !== event.target.value),
                )
              }
            }}
            {...props}
          />
        )
      }}
    />
  )
}

export default Checkbox
