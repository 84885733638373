import { LatLng, Venue } from '../../../../App.types'
import createMapMarker, { focusedMarkerIcon, markerIcon } from '../../../../components/Map/helpers/create-map-marker'
import isMarkerDisabled from '../../../../components/Map/helpers/is-marker-disabled'
import isMarkerFocused from '../../../../components/Map/helpers/is-marker-focused'
import { SportCategory } from '../../Discover.types'

interface CreateMarkerOptions {
  map: google.maps.Map
  venue: Venue
  location: LatLng
  activeMarker?: google.maps.Marker
  setActiveMarker: (marker: google.maps.Marker) => void
  setClickedVenueId: (venueId: string) => void
  category?: SportCategory
}

export function createDiscoverMapMarker({
  map,
  venue,
  location,
  activeMarker,
  setActiveMarker,
  setClickedVenueId,
  category,
}: CreateMarkerOptions): google.maps.Marker {
  // Create custom marker pins
  const marker = createMapMarker({
    map,
    type: 'default',
    isDisabled: category !== SportCategory.FITNESS && venue.slotsToDiscover?.length === 0,
    marker: { latitude: location.latitude, longitude: location.longitude },
  })

  // Open info window on marker click and change to active icon
  marker.addListener('click', () => {
    // Reset current active pin marker if it exists
    if (activeMarker) {
      const isDisabled = isMarkerDisabled(activeMarker)
      activeMarker.setOptions({
        icon: markerIcon({ isDisabled }),
      })
    }

    // We use React components for the cards
    setClickedVenueId(venue.id)

    // Update reference to this marker
    setActiveMarker(marker)

    // Set marker icon to active
    marker.setOptions({
      icon: focusedMarkerIcon({ isDisabled: isMarkerDisabled(marker) }),
    })
  })

  marker.addListener('mouseover', () => {
    if (isMarkerFocused(marker)) return
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Url exists for the ReadonlyIcon type
    marker.setOptions({ icon: { url: marker.getIcon()?.url, scaledSize: new google.maps.Size(42, 53) } })
  })

  marker.addListener('mouseout', () => {
    if (isMarkerFocused(marker)) return
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Url exists for the ReadonlyIcon type
    marker.setOptions({ icon: { url: marker.getIcon()?.url, scaledSize: new google.maps.Size(36, 45) } })
  })

  return marker
}
