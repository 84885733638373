import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box from '@eversports/klimt-primitives/Box'

import { Action } from '../helpers/matches-filters-reducer'
import ContainedXLWidthContainer from '../../../../../components/ContainedXLWidthContainer'
import { MatchesFiltersState } from '../Matches.types'
import { MatchSport } from '../../../../../App.types'

import DateFilter from './DateFilter'
import SportsFilter from './SportsFilter'
import ResetFilters from './ResetFilters'

interface Props {
  sports?: Array<MatchSport>
  appliedFilters: MatchesFiltersState
  dispatch: React.Dispatch<Action>
}

const Filters = ({ sports, appliedFilters, dispatch }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box sx={{ backgroundColor: 'lightgray', boxShadow: boxShadows.default, paddingX: { xs: 0, lg: 8 } }}>
      <ContainedXLWidthContainer>
        <Stack direction="row" gap={4} paddingY={2} paddingX={{ xs: 5, md: 0 }}>
          <SportsFilter
            dispatch={dispatch}
            sports={sports}
            selectedSports={appliedFilters.sports}
            isMobile={isMobile}
          />
          <DateFilter selectedDate={appliedFilters.date} dispatch={dispatch} isMobile={isMobile} />
          <ResetFilters appliedFilters={appliedFilters} dispatch={dispatch} />
        </Stack>
      </ContainedXLWidthContainer>
    </Box>
  )
}

export default Filters
