import { useState, useEffect } from 'react'

export enum SearchType {
  LOCATION = 'LOCATION',
  INVENTORY = 'INVENTORY',
}

const RECENT_LOCATION_SEARCHES_KEY = 'rcnt_lctn'
const RECENT_INVENTORY_SEARCHES_KEY = 'rcnt_invntry'
const MAX_NUMBER_TO_STORE = 4

const RECENT_SEARCH_KEYS: { [key in SearchType]: string } = {
  [SearchType.LOCATION]: RECENT_LOCATION_SEARCHES_KEY,
  [SearchType.INVENTORY]: RECENT_INVENTORY_SEARCHES_KEY,
}

function useRecentSearches<T>(recentSearchType: SearchType) {
  const [recentSearches, setRecentSearches] = useState<Array<T & { id: string }>>()

  const getArrayFromLocalStorage = (key: SearchType) => {
    const storedValue = window.localStorage.getItem(RECENT_SEARCH_KEYS[key])
    if (storedValue && JSON.parse(storedValue)) {
      return JSON.parse(storedValue) as Array<T & { id: string }>
    }
    return []
  }

  const setToLocalStorage = ({ key, value }: { key: SearchType; value: T & { id: string } }) => {
    const currentArr = getArrayFromLocalStorage(key)

    let newSearchArr = [value]

    if (currentArr.length) {
      // If the result was already stored, filter it out, because we will move it to the front of the array.
      const filteredValues = currentArr.filter((savedValue) => savedValue.id !== value.id)
      newSearchArr = [...newSearchArr, ...filteredValues].slice(0, MAX_NUMBER_TO_STORE)
    }

    window.localStorage.setItem(RECENT_SEARCH_KEYS[key], JSON.stringify(newSearchArr))
  }

  const updateRecentSearches = ({ key, value }: { key: SearchType; value: T & { id: string } }) => {
    setToLocalStorage({ key, value })
    const filteredCurrentSearches = recentSearches?.filter((search) => search.id !== value.id) ?? []
    const newSearches = [value, ...filteredCurrentSearches].slice(0, MAX_NUMBER_TO_STORE)
    setRecentSearches(newSearches)
  }

  useEffect(() => {
    const savedSearches = getArrayFromLocalStorage(recentSearchType)
    if (savedSearches.length) {
      setRecentSearches(savedSearches)
    }
  }, [])

  return {
    recentSearches,
    updateRecentSearches,
  }
}

export default useRecentSearches
