import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import { useRouteMatch } from 'react-router-dom'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import { Localized } from '../../../localization/react'
import { RecommendationsSectionWrapper } from '../RecommendationsSectionWrapper'
import RecommendationsViewAllButton from '../RecommendationsViewAllButton'
import { DEFAULT_NUMBER_OF_CARDS_TO_SHOW } from '../Recommendations'
import ContainedXLWidthContainer from '../../../components/ContainedXLWidthContainer'
import { Teacher } from '../../../App.types'

import TeachersIcon from './assets/teachers-icon.svg'
import TeacherPlaceholder from './TeacherPlaceholder'
import TeacherSkeleton from './TeacherSkeleton'
import TeacherCard from './TeacherCard'

interface Props {
  teachers?: Array<Teacher>
  loading: boolean
}

const TeachersSection = ({ teachers, loading }: Props) => {
  const { url } = useRouteMatch()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (!loading && teachers?.length === 0) {
    return null
  }

  const showViewAllButton = teachers ? teachers.length > DEFAULT_NUMBER_OF_CARDS_TO_SHOW : false
  const shownTeachers = teachers?.slice(0, DEFAULT_NUMBER_OF_CARDS_TO_SHOW)
  const showTeacherPlaceholder =
    shownTeachers && shownTeachers.length > 0 && shownTeachers.length < DEFAULT_NUMBER_OF_CARDS_TO_SHOW

  return (
    <Stack
      sx={{
        backgroundColor: 'white',
        px: { xs: 4, md: 8 },
        boxShadow: '0px 1px 1px rgba(20, 20, 20, 0.2)',
        border: '0.5px solid',
        borderColor: 'lightgray',
      }}
    >
      <ContainedXLWidthContainer>
        <Stack py={10} gap={4}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center" gap={2}>
              <img src={TeachersIcon} alt="Teachers Icon" />
              <Heading is="h3">
                <Localized id="recent-teachers-header" />
              </Heading>
            </Stack>
            {showViewAllButton && !isMobile && <RecommendationsViewAllButton linkUrl={`${url}/teachers`} />}
          </Stack>
          <RecommendationsSectionWrapper>
            {loading || !shownTeachers ? (
              <TeacherSkeleton numberOfCopies={DEFAULT_NUMBER_OF_CARDS_TO_SHOW} />
            ) : (
              <>
                {shownTeachers.map((teacher, index) => (
                  <TeacherCard key={index} teacher={teacher} venue={teacher.recentlyTrainedUserAt} />
                ))}
                {showTeacherPlaceholder && <TeacherPlaceholder />}
              </>
            )}
          </RecommendationsSectionWrapper>
          {showViewAllButton && isMobile && <RecommendationsViewAllButton linkUrl={`${url}/teachers`} />}
        </Stack>
      </ContainedXLWidthContainer>
    </Stack>
  )
}

export default TeachersSection
