import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import AddIcon from '@eversports/design-tokens/assets/icons/add.svg'
import MinusIcon from '@eversports/design-tokens/assets/icons/minus.svg'
import Icon from '@eversports/klimt-primitives/Icon'

import { LatLng } from '../../App.types'

import MapIconBadge from './MapIconBadge'
import MapGeoLocation from './MapGeoLocation'
import MapShowGoogleMaps from './MapShowGoogleMaps'
import { MapControlSettings } from './Map.types'

interface Props extends MapControlSettings {
  map: google.maps.Map
  onGeoLocationControlClick?: (userLocation: LatLng) => void
}

const handleClickZoom = (map: google.maps.Map, factor: number) => () => map.setZoom(map.getZoom() + factor)

const MapControls = ({
  map,
  onGeoLocationControlClick,
  showZoomControls = true,
  showGeoLocationControl = true,
  showOpenGoogleMaps = false,
}: Props) => {
  const googleMapsCenter = map.getCenter()
  return (
    <>
      <Box sx={{ position: 'absolute', top: '16px', right: '20px' }}>
        <Stack spacing={2}>
          {showZoomControls && (
            <Stack>
              <MapIconBadge onClick={handleClickZoom(map, 1)} isTop={true}>
                <Icon src={AddIcon} size="default" color="darkgray" />
              </MapIconBadge>
              <MapIconBadge onClick={handleClickZoom(map, -1)} isBottom={true}>
                <Icon src={MinusIcon} size="default" color="darkgray" />
              </MapIconBadge>
            </Stack>
          )}
          {showGeoLocationControl && <MapGeoLocation map={map} onClick={onGeoLocationControlClick} />}
        </Stack>
      </Box>
      <Box sx={{ position: 'absolute', bottom: '16px', right: '20px' }}>
        {showOpenGoogleMaps && (
          <MapShowGoogleMaps
            type="url"
            latitude={googleMapsCenter.lat()}
            longitude={googleMapsCenter.lng()}
            urlVariant="directions"
          />
        )}
      </Box>
    </>
  )
}

export default MapControls
