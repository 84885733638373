import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import Link from '@eversports/klimt-primitives/Link'

import { Localized } from '../../../localization/react'
import { From } from '../../../App.types'

import FriendsAttendingPromotionProfileImage from './assets/friends-attending-promotion-profile.webp'
import FriendsAttendingPromotionClassCalendarImage from './assets/friends-attending-promotion-class-calendar.webp'

interface Props {
  userHandle: string
}

const FriendsAttendingPromotionContent = ({ userHandle }: Props) => {
  return (
    <Stack gap={6.5}>
      <Heading is="h3" textAlign="center">
        <Localized id="friends-attending-promotion-title" />
      </Heading>
      <Stack gap={4}>
        <Heading is="h4" variant="small">
          <Localized id="friends-attending-promotion-subtitle" />
        </Heading>
        <Stack gap={2}>
          <Text sx={{ color: 'midnight' }}>
            <Localized id="friends-attending-promotion-advantage-1" />
          </Text>
          <Stack direction="row" alignItems="center" gap={2}>
            <img loading="lazy" src={FriendsAttendingPromotionProfileImage} width={80} height={150} />
            <Text variant="small" sx={{ color: 'secondary.main' }}>
              <Localized id="friends-attending-promotion-profile-image-description" />
            </Text>
          </Stack>
        </Stack>
        <Stack gap={2}>
          <Text sx={{ color: 'midnight' }}>
            <Localized id="friends-attending-promotion-advantage-2" />
          </Text>
          <Stack direction="row" alignItems="center" gap={2}>
            <img loading="lazy" src={FriendsAttendingPromotionClassCalendarImage} width={88} height={80} />
            <Text variant="small" sx={{ color: 'secondary.main' }}>
              <Localized id="friends-attending-promotion-class-calendar-image-description" />
            </Text>
          </Stack>
          <Text sx={{ color: 'midnight' }}>
            <Localized id="friends-attending-promotion-advantage-3" />
          </Text>
        </Stack>
      </Stack>
      <Stack gap={2}>
        <Text variant="small">
          <Localized id="friends-attending-promotion-text" />
        </Text>
      </Stack>
      <Link to={`/user/${userHandle}/friends?from=${From.FRIENDS_ATTENDING_PLACEHOLDER}`} blank>
        <Button
          variant="primary"
          sx={{
            textTransform: 'none',
            paddingY: 2,
            paddingX: 4,
            margin: 'auto',
            width: { xs: '100%', sm: 'fit-content' },
          }}
        >
          <Text sx={{ color: 'white', fontWeight: 'bold' }}>
            <Localized id="friends-attending-promotion-button" />
          </Text>
        </Button>
      </Link>
    </Stack>
  )
}

export default FriendsAttendingPromotionContent
