import { Origin } from '../../graphql'
import { Origin as MatchOrigin } from '../../App.types'

import {
  PaymentState,
  CourtAreaType,
  CourtSurfaceType,
  UserType,
  PageType,
  MatchStatus,
  BallsportMatchState,
} from './Match.types'

export const paymentStatusTranslations: {
  [key in Extract<PaymentState, PaymentState.PAYMENT_STATE_OPEN | PaymentState.PAYMENT_STATE_SUCCEEDED>]: string
} = {
  [PaymentState.PAYMENT_STATE_OPEN]: 'payment-state-open',
  [PaymentState.PAYMENT_STATE_SUCCEEDED]: 'payment-state-succeeded',
} as const

export const CourtAreaTypeToNameMapping = {
  [CourtAreaType.INDOOR]: 'match-court-area-indoor',
  [CourtAreaType.OUTDOOR]: 'match-court-area-outdoor',
} as const

export const CourtSurfaceTypeToNameMapping = {
  [CourtSurfaceType.ARTIFICIAL_GRASS]: 'match-court-surface-type-artificial-grass',
  [CourtSurfaceType.ARTIFICIAL_ICE]: 'match-court-surface-type-artificial-ice',
  [CourtSurfaceType.ASH]: 'match-court-surface-type-ash',
  [CourtSurfaceType.ASPHALT]: 'match-court-surface-type-asphalt',
  [CourtSurfaceType.CARPET]: 'match-court-surface-type-carpet',
  [CourtSurfaceType.CARPET_GRANULE]: 'match-court-surface-type-carpet-granule',
  [CourtSurfaceType.CONCRETE]: 'match-court-surface-type-concrete',
  [CourtSurfaceType.FELT]: 'match-court-surface-type-felt',
  [CourtSurfaceType.GRANULE]: 'match-court-surface-type-granuale',
  [CourtSurfaceType.GRASS]: 'match-court-surface-type-grass',
  [CourtSurfaceType.HARD_COURT]: 'match-court-surface-type-hard-court',
  [CourtSurfaceType.ICE]: 'match-court-surface-type-ice',
  [CourtSurfaceType.OPTICOURT]: 'match-court-surface-type-opticourt',
  [CourtSurfaceType.PARQUET]: 'match-court-surface-type-parquet',
  [CourtSurfaceType.PVC]: 'match-court-surface-type-pvc',
  [CourtSurfaceType.RED_COURT]: 'match-court-surface-type-red-court',
  [CourtSurfaceType.SAND]: 'match-court-surface-type-sand',
  [CourtSurfaceType.SYNTHETIC]: 'match-court-surface-type-synthetic',
  [CourtSurfaceType.TARTAN]: 'match-court-surface-type-tartan',
  [CourtSurfaceType.WATER]: 'match-court-surface-type-water',
} as const

export const GOOGLE_MAPS_LINK = 'https://www.google.com/maps/search/?api=1'

export const userTypeMapping: { [key in UserType]: string } = {
  [UserType.ORGANIZER]: 'organizer',
  [UserType.PARTICIPANT]: 'participant',
  [UserType.EVERSPORTS_GUEST]: 'eversports guest',
  [UserType.EXTERNAL_GUEST]: 'external guest',
}

export const matchStatusMapping: { [key in MatchStatus]: string } = {
  [MatchStatus.UPCOMING]: 'future',
  [MatchStatus.IN_PROGRESS]: 'ongoing',
  [MatchStatus.COMPLETED]: 'past',
}

export const pageTypeMapping: { [key in PageType]: string } = {
  [PageType.THANK_YOU]: 'match thank you',
  [PageType.OVERVIEW]: 'match detail',
  [PageType.CALENDAR]: 'match detail',
  [PageType.DISCOVER]: 'match detail',
  [PageType.VENUE_PROFILE]: 'match detail',
}

export const fromMapping: { [key in PageType]: string } = {
  [PageType.THANK_YOU]: 'checkout',
  [PageType.OVERVIEW]: 'my bookings',
  [PageType.CALENDAR]: 'court calendar',
  [PageType.DISCOVER]: 'discover',
  [PageType.VENUE_PROFILE]: 'venue profile',
}

export const originMapping: { [key in MatchOrigin]: Origin } = {
  [MatchOrigin.MARKETPLACE]: Origin.ORIGIN_MARKETPLACE,
  [MatchOrigin.WIDGET]: Origin.ORIGIN_WIDGET,
}

export const VALID_BALLSPORT_MATCH_STATES = [
  BallsportMatchState.ACCEPTED,
  BallsportMatchState.MOVED,
  BallsportMatchState.REQUESTED,
]
export const NUMBER_OF_INITIAL_COMMENTS_FETCHED = 3
