import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Svg from '@eversports/klimt-primitives/Svg'

import Star from '../assets/star.svg'
import { Localized } from '../../../../localization/react'
import { Venue } from '../../ActivityDetails.types'

interface Props {
  venue: Venue
}

const NoReviews = ({ venue }: Props) => (
  <Stack direction="row" gap={2}>
    <Stack direction="row" alignItems="center" gap={1}>
      <Svg src={Star} width={12} height={12} />
      <Text color="midnight" sx={{ fontWeight: 'bold' }}>
        <Localized id="activity-details-venue-reviews-header" />
      </Text>
    </Stack>
    <Stack direction="row" gap={1}>
      <Text color="midnight" sx={{ fontWeight: 'bold' }}>
        {venue.rating}
      </Text>
      <Text>
        <Localized id="rating-no-reviews" />
      </Text>
    </Stack>
  </Stack>
)

export default NoReviews
