import * as React from 'react'
import { default as MuiTextField, TextFieldProps } from '@mui/material/TextField'

type Props = Omit<TextFieldProps, 'variant' | 'size'>

const TextField = ({ label, ...props }: Props) => {
  return <MuiTextField label={label} variant="outlined" size="small" {...props} />
}

export default TextField
