import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../localization/react'
import NotFoundImage from '../../../static/not-found.svg'
import useGetTopContentHeight from '../../hooks/useGetTopContentHeight'

const NotFoundActivity = () => {
  const { DESKTOP_TOP_CONTENT_HEIGHT: TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])

  return (
    <Stack justifyContent="center" alignItems="center" flex={1} height={`calc(100vh - ${TOP_CONTENT_HEIGHT}px)`}>
      <img src={NotFoundImage} alt="Not Found Image" style={{ maxWidth: '750px' }} />
      <Link to="/">
        <Button variant="primary" sx={{ px: 2, py: 1 }}>
          <Text variant="badge" sx={{ color: 'white' }}>
            <Localized id="back-to-home" />
          </Text>
        </Button>
      </Link>
    </Stack>
  )
}

export default NotFoundActivity
