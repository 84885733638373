import * as React from 'react'
import { default as MuiTypography, TypographyProps } from '@mui/material/Typography'
import { HeadingVariants, headingVariants } from '@eversports/design-tokens/typography'

interface Props extends Omit<TypographyProps, 'variant'> {
  variant?: keyof HeadingVariants
  is: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const Heading: React.FC<React.PropsWithChildren<Props>> = ({ variant = 'regular', is, sx, ...rest }) => {
  const { fontSize, fontWeight, lineHeight } = headingVariants[variant]
  return <MuiTypography {...rest} variant={is} sx={{ fontSize, fontWeight, lineHeight, ...sx }} />
}

export default Heading
