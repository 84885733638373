import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import RadioGroup from '@eversports/klimt-forms/RadioGroup'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import Button from '@eversports/klimt-primitives/Button'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import { Localized } from '../../../../localization/react'
import ModalOption from '../../../../components/ModalOption'
import ModalRadioButton from '../../../../components/ModalRadioButton'
import { PrivacySettingVisibility } from '../../../../App.types'

import VisibilityTypeContent from './VisibilityTypeContent'

interface Props {
  selectedVisibility: PrivacySettingVisibility
  onApply: (visibility: PrivacySettingVisibility) => void
  onClose: () => void
  isMobile?: boolean
}

const EditPrivacySettingContent = ({ selectedVisibility, onApply, onClose, isMobile }: Props) => {
  const methods = useForm({
    defaultValues: { VISIBILITY: selectedVisibility },
  })

  const updatedVisibility = methods.watch('VISIBILITY')

  const handleClick = () => {
    onApply(updatedVisibility)
  }

  return (
    <Stack padding={{ xs: 0, md: 6 }} minWidth={{ xs: '100%', md: '450px' }} gap={3}>
      {!isMobile && (
        <Stack gap={2}>
          <Text variant="large" sx={{ fontWeight: 'bold', color: 'black' }}>
            <Localized id="visibility-setting-modal-title" />
          </Text>
          <Text variant="small" sx={{ color: 'darkgray' }}>
            <Localized id="visibility-setting-modal-description" />
          </Text>
        </Stack>
      )}
      <Stack gap={3}>
        <Form methods={methods}>
          <RadioGroup name="VISIBILITY">
            <Stack gap={2}>
              {Object.keys(PrivacySettingVisibility).map((visibility) => (
                <ModalOption key={visibility} isSelected={(updatedVisibility as string) === visibility}>
                  <ModalRadioButton
                    label={<VisibilityTypeContent visibility={visibility as PrivacySettingVisibility} />}
                    value={visibility}
                  />
                </ModalOption>
              ))}
            </Stack>
          </RadioGroup>
        </Form>
      </Stack>
      <Stack
        direction="row"
        width="100%"
        gap={4}
        sx={{ position: { xs: 'initial', md: 'absolute' }, bottom: 0, left: 0, padding: { xs: 0, md: 4 } }}
      >
        <Button variant="tertiary" sx={{ p: 3, flex: 1 }} onClick={onClose}>
          <Text sx={{ color: 'text', fontWeight: 'bold', textShadow: boxShadows.default, textTransform: 'none' }}>
            <Localized id="visibility-setting-modal-dismiss-button" />
          </Text>
        </Button>
        <Button
          variant="primary"
          onClick={handleClick}
          sx={{
            p: 3,
            flex: 1.5,
          }}
        >
          <Text sx={{ color: 'white', fontWeight: 'bold', textShadow: boxShadows.default, textTransform: 'none' }}>
            <Localized id="visibility-setting-modal-apply-button" />
          </Text>
        </Button>
      </Stack>
    </Stack>
  )
}

export default EditPrivacySettingContent
