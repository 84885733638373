import { AmplitudeClient } from '@eversports/amplitude-react/core/amplitude'

import { CitySearchResult, VenueSearchResult, SportSearchResult, InventorySearchResult } from '../../Search.types'

const AMPLITUDE_INVENTORY_SEARCH_EVENT_NAME = 'Used Search'

type ResultType = 'sport-in-city' | 'venue' | 'facilities-in-city' | null

interface InventoryFields {
  resultTypeClicked: ResultType
  searchTerm: string
  isRecent?: boolean
  city?: string
  where: string
  isNewSearch: boolean
}

interface ResultProps {
  searchTerm: string
  location: CitySearchResult
  amplitude: AmplitudeClient
}

type SportFields = InventoryFields & { sport: string }

const trackSportResult = ({ result, searchTerm, amplitude }: ResultProps & { result: SportSearchResult }) => {
  const fields: SportFields = {
    resultTypeClicked: 'sport-in-city',
    searchTerm,
    isRecent: result?.isRecent,
    sport: result.sportSlug,
    city: result.citySlug,
    where: window.location.pathname,
    isNewSearch: true,
  }
  amplitude.logEvent(AMPLITUDE_INVENTORY_SEARCH_EVENT_NAME, fields)
}

type VenueFields = InventoryFields & { venue: { slug: string } }

const trackVenueResult = ({ result, searchTerm, location, amplitude }: ResultProps & { result: VenueSearchResult }) => {
  const fields: VenueFields = {
    resultTypeClicked: 'venue',
    searchTerm,
    isRecent: result.isRecent,
    city: location.slug,
    venue: {
      slug: result.slug,
    },
    where: window.location.pathname,
    isNewSearch: true,
  }
  amplitude.logEvent(AMPLITUDE_INVENTORY_SEARCH_EVENT_NAME, fields)
}

const trackAllSportsInCityResult = ({ searchTerm, location, amplitude }: ResultProps) => {
  const fields: InventoryFields = {
    searchTerm,
    resultTypeClicked: 'facilities-in-city',
    city: location.slug,
    where: window.location.pathname,
    isNewSearch: true,
  }
  amplitude.logEvent(AMPLITUDE_INVENTORY_SEARCH_EVENT_NAME, fields)
}

// For the case where there are no results, or when the user clicks the "magnify glass" search button.
const trackInputSearch = ({ searchTerm, location, amplitude }: ResultProps) => {
  const fields: InventoryFields = {
    searchTerm,
    resultTypeClicked: null,
    city: location.slug,
    where: window.location.pathname,
    isNewSearch: true,
  }
  amplitude.logEvent(AMPLITUDE_INVENTORY_SEARCH_EVENT_NAME, fields)
}

interface Props<T> {
  result?: T
  searchTerm: string
  location: CitySearchResult
  amplitude: AmplitudeClient | null
}

export const trackInventorySearch = ({ result, searchTerm, location, amplitude }: Props<InventorySearchResult>) => {
  if (!amplitude) return

  if (!result) {
    return trackInputSearch({ searchTerm, location, amplitude })
  }

  switch (result.__typename) {
    case 'SportSearchResult':
      return trackSportResult({ result, searchTerm, location, amplitude })
    case 'VenueSearchResult':
      return trackVenueResult({ result, searchTerm, location, amplitude })
    case 'AllSportsInCity':
      return trackAllSportsInCityResult({ searchTerm, location, amplitude })
  }
}
