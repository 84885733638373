import * as React from 'react'
import { getLanguageFromLanguageWithLocale } from '@eversports/language'
import { setHours, setMinutes } from 'date-fns'

interface Props {
  value: number
}

const Hour = ({ value }: Props) => {
  const date = setMinutes(setHours(new Date(), value), 0)
  const hourToShow = date.toLocaleTimeString(getLanguageFromLanguageWithLocale(navigator.language), {
    hour: '2-digit',
    minute: '2-digit',
  })
  return <>{hourToShow}</>
}

export default Hour
