import { VenueOfferingType, VenueProfileTabsPrefix } from '../../../../../App.types'
import Routes from '../../../VenueProfile.routes'

const getActiveTab = (pathname: string) => {
  const venueTab = pathname.split('/')[1] as VenueProfileTabsPrefix
  // Videos, team and public matches tabs don't follow the same url logic
  const isVideosTab = pathname.split('/')[3] === 'videos'

  const teamRoute = Routes.TEAM.split('/')[1]
  const isTeamTab = pathname.split('/')[3] === teamRoute

  const publicMatchesRoute = Routes.MATCHES.split('/')[1]
  const isPublicMatchesTab = pathname.split('/')[3] === publicMatchesRoute

  const tabPath = `/${venueTab}/` as VenueProfileTabsPrefix
  switch (tabPath) {
    case VenueProfileTabsPrefix.CLASSES:
      return VenueOfferingType.VENUE_OFFERING_CLASS_ACTIVITY
    case VenueProfileTabsPrefix.COURSES:
      return VenueOfferingType.VENUE_OFFERING_COURSE_ACTIVITY
    case VenueProfileTabsPrefix.CAMPS:
      return VenueOfferingType.VENUE_OFFERING_CAMP_ACTIVITY
    case VenueProfileTabsPrefix.EVENTS:
      return VenueOfferingType.VENUE_OFFERING_EVENT_ACTIVITY
    case VenueProfileTabsPrefix.EDUCATION:
      return VenueOfferingType.VENUE_OFFERING_EDUCATION_ACTIVITY
    case VenueProfileTabsPrefix.WORKSHOPS:
      return VenueOfferingType.VENUE_OFFERING_WORKSHOP_ACTIVITY
    case VenueProfileTabsPrefix.RETREATS:
      return VenueOfferingType.VENUE_OFFERING_RETREAT_ACTIVITY
    case VenueProfileTabsPrefix.TRAINING:
      return VenueOfferingType.VENUE_OFFERING_TRAINING_ACTIVITY
    case VenueProfileTabsPrefix.OPEN_TRAININGS:
      return VenueOfferingType.VENUE_OFFERING_OPENTRAINING_ACTIVITY
    case VenueProfileTabsPrefix.PRICES:
      return VenueOfferingType.VENUE_OFFERING_SHOP

    default:
      if (isVideosTab) {
        return VenueOfferingType.VENUE_OFFERING_VIDEO
      } else if (isTeamTab) {
        return VenueOfferingType.VENUE_OFFERING_TEAM
      } else if (isPublicMatchesTab) {
        return VenueOfferingType.VENUE_OFFERING_PUBLIC_MATCHES
      }
      return null
  }
}

export default getActiveTab
