import * as React from 'react'
import { useState, useEffect } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import { motion, LazyMotion, domMax, useMotionValue } from '@eversports/klimt-utilities/framer-motion'
import Stack from '@eversports/klimt-primitives/Stack'

import { Image } from '../../../VenueProfile.types'
import { CarouselDirection } from '../ImagesGallery'

import Thumbnail from './Thumbnail'

export const THUMBNAILS_CONTAINER_HEIGHT = 80
export const GAP_PER_IMAGE = 2
export const IMAGE_WIDTH = 100

interface DraggableThumbnailProps {
  images: Array<Image>
  activeImageIndex: number
  handleThumbnailClick: (value: number) => () => void
  thumbnailContainerWidth: number
}

const DraggableThumbnails = ({
  images,
  activeImageIndex,
  handleThumbnailClick,
  thumbnailContainerWidth,
}: DraggableThumbnailProps) => {
  const [isDragging, setIsDragging] = useState(false)

  const leftDragConstraint = thumbnailContainerWidth - window.innerWidth + 64
  const dragXOffset = useMotionValue(0)

  const startDragging = () => setIsDragging(true)
  const stopDragging = () => setIsDragging(false)

  const handleClick = (index: number) => () => {
    if (!isDragging) {
      handleThumbnailClick(index)()
    }
    stopDragging()
  }

  // If the active thumbnail is offscreen, shift container to have it centered.
  useEffect(() => {
    const imageNumber = activeImageIndex + 1
    const imageLeftOffset = imageNumber * IMAGE_WIDTH + imageNumber * GAP_PER_IMAGE

    const currentLeftOffset = dragXOffset.get()
    const differenceBetweenOffsets = Math.abs(imageLeftOffset) - Math.abs(currentLeftOffset)

    // See if it's offscreen to the left, or offscreen to the right.
    if (differenceBetweenOffsets < IMAGE_WIDTH || differenceBetweenOffsets > window.innerWidth - IMAGE_WIDTH) {
      const screenCenterX = window.innerWidth / 2
      const shiftThumbnailsLeftBy = imageLeftOffset - screenCenterX
      dragXOffset.set(-shiftThumbnailsLeftBy)
    }
  }, [activeImageIndex])

  return (
    <LazyMotion features={domMax}>
      <motion.div
        drag="x"
        layout
        dragMomentum={false}
        dragConstraints={{
          left: -leftDragConstraint,
          right: 0,
        }}
        style={{
          display: 'flex',
          height: '100%',
          width: thumbnailContainerWidth,
          overflowX: 'hidden',
          margin: '0 auto',
          x: dragXOffset,
        }}
        onDrag={startDragging}
        onClick={stopDragging}
        onTouchEnd={stopDragging}
      >
        {images.map((image, index) => (
          <Thumbnail
            key={index}
            imgSrc={image.xSmall!}
            isSelected={activeImageIndex === index}
            onClick={handleClick(index)}
          />
        ))}
      </motion.div>
    </LazyMotion>
  )
}

interface Props {
  images: Array<Image>
  activeImageIndex: number
  setActiveImageIndex: React.Dispatch<React.SetStateAction<number>>
  setCarouselDirection: React.Dispatch<React.SetStateAction<CarouselDirection>>
}

const Thumbnails = ({ images, activeImageIndex, setActiveImageIndex, setCarouselDirection }: Props) => {
  const thumbnailContainerWidth = images.length * IMAGE_WIDTH + images.length * GAP_PER_IMAGE * 2
  const isDraggingDisabled = thumbnailContainerWidth <= window.innerWidth

  const handleThumbnailClick = (index: number) => () => {
    const animationDirection = index < activeImageIndex ? 'right' : 'left'
    setCarouselDirection(animationDirection)
    setActiveImageIndex(index)
  }

  return (
    <Box
      sx={{
        minHeight: `${THUMBNAILS_CONTAINER_HEIGHT}px`,
        maxHeight: `${THUMBNAILS_CONTAINER_HEIGHT}px`,
        overflowX: 'hidden',
        cursor: isDraggingDisabled ? 'pointer' : 'grab',
        '&:active': { cursor: isDraggingDisabled ? 'auto ' : 'grabbing' },
      }}
    >
      {isDraggingDisabled ? (
        <Stack flexDirection="row" justifyContent="center" height="100%">
          {images.map((image, index) => (
            <Thumbnail
              key={index}
              imgSrc={image.xSmall!}
              isSelected={activeImageIndex === index}
              onClick={handleThumbnailClick(index)}
            />
          ))}
        </Stack>
      ) : (
        <DraggableThumbnails
          images={images}
          activeImageIndex={activeImageIndex}
          handleThumbnailClick={handleThumbnailClick}
          thumbnailContainerWidth={thumbnailContainerWidth}
        />
      )}
    </Box>
  )
}

export default Thumbnails
