import JSCookies, { type CookieAttributes } from 'js-cookie'

import getUserTrackingPreferences from '../helpers/get-user-tracking-preferences'

interface Result {
  getCookie: (key: string) => string | undefined
  setFunctionalCookie: (key: string, value: any, options?: CookieAttributes) => void
  setTrackingCookie: (key: string, value: any, options?: CookieAttributes) => void
}

const useCookies = (): Result => {
  const getCookie = (name: string) => {
    return JSCookies.get(name)
  }

  const setFunctionalCookie = (name: string, value: any, options?: CookieAttributes) => {
    JSCookies.set(name, JSON.stringify(value), options)
  }

  const setTrackingCookie = (name: string, value: any, options?: CookieAttributes) => {
    const { ANALYTICS: isAnalyticsTrackingAllowed } = getUserTrackingPreferences()

    if (isAnalyticsTrackingAllowed) {
      setFunctionalCookie(name, value, options)
    }
  }

  return { getCookie, setFunctionalCookie, setTrackingCookie }
}

export default useCookies
