import * as React from 'react'
import Skeleton from '@eversports/klimt-primitives/Skeleton'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'

import { Localized } from '../localization/react'

interface Props {
  numberOfSkeletons: number
}

const Placeholder = () => (
  <Stack direction="row" alignItems="center" gap={2}>
    <Skeleton variant="circular" height="32px" width="32px" />
    <Skeleton variant="rectangular" height="40px" width="100%" />
  </Stack>
)

const FriendsLoadingPlaceholder = ({ numberOfSkeletons }: Props) => {
  const placeholders = [...Array(numberOfSkeletons)]
  return (
    <>
      <Heading is="h4" variant="small">
        <Localized id="invite-friends-title" />
      </Heading>
      <Stack gap={2} sx={{ maxHeight: '250px', overflow: 'scroll' }}>
        {placeholders.map((_, index) => (
          <Placeholder key={index} />
        ))}
      </Stack>
    </>
  )
}

export default FriendsLoadingPlaceholder
