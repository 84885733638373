import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import * as React from 'react'

import defaultTheme from './theme'

interface Props {
  theme?: typeof defaultTheme
}

const ThemeProvider = ({ children, theme = defaultTheme }: React.PropsWithChildren<Props>) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}

export default ThemeProvider
