import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import { keyframes } from '@eversports/klimt-design-components/emotion'

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 26, 26, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 26, 26, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 26, 26, 0);
  }
`

const RedPulsatingBlob = () => {
  return (
    <Box
      sx={{
        background: 'red',
        borderRadius: '50%',
        margin: 1.5,
        height: '6px',
        width: '6px',
        transform: 'scale(1)',
        boxShadow: '0 0 0 0 rgba(255, 26, 26, 1)',
        animation: `${pulse} 2s infinite`,
      }}
    />
  )
}

export default RedPulsatingBlob
