import { Venue } from '../../../../App.types'
import { SportCategory } from '../../Discover.types'
import isMarkerDisabled from '../../../../components/Map/helpers/is-marker-disabled'
import { markerIcon } from '../../../../components/Map/helpers/create-map-marker'

import { createDiscoverMapMarker } from './create-discover-map-marker'

interface CreateMarkersOptions {
  map: google.maps.Map
  markers: Map<string, google.maps.Marker>
  venues: Array<Venue>
  setMarkers: (markers: Map<string, google.maps.Marker>) => void
  activeMarker?: google.maps.Marker
  setClickedVenueId: (venueId: string) => void
  setActiveMarker: (marker: google.maps.Marker) => void
  category?: SportCategory
}

const createDiscoverMapMarkers = ({
  map,
  markers,
  venues,
  setMarkers,
  activeMarker,
  setClickedVenueId,
  setActiveMarker,
  category,
}: CreateMarkersOptions) => {
  // Create list of venues to add & markers to remove
  const newMarkers: Map<string, google.maps.Marker> = new Map()
  const markersToRemove = new Map(markers)

  for (const venue of venues) {
    // The graphql endpoint has these typed as possibly null - so checking for typescript
    if (!venue.location.latitude || !venue.location.longitude) return
    // Try to retrieve marker
    const marker = markersToRemove.get(venue.id)

    // If marker exists already, keep it in the new map, otherwise create a new marker
    if (marker) {
      // If category is not fitness, we might need to change the marker icons, even if the marker already exists
      if (category && category !== SportCategory.FITNESS) {
        const isMarkerCurrentlyDisabled = isMarkerDisabled(marker)
        const shouldMarkerBeDisabled = venue.slotsToDiscover?.length === 0

        if (!isMarkerCurrentlyDisabled && shouldMarkerBeDisabled) {
          marker.setOptions({
            icon: markerIcon({ isDisabled: true }),
          })
        }

        if (isMarkerCurrentlyDisabled && !shouldMarkerBeDisabled) {
          marker.setOptions({
            icon: markerIcon({ isDisabled: false }),
          })
        }
      }

      newMarkers.set(venue.id, marker)
    } else {
      const newMarker = createDiscoverMapMarker({
        location: { longitude: venue.location.longitude, latitude: venue.location.latitude },
        map,
        venue,
        activeMarker,
        setActiveMarker,
        setClickedVenueId,
        category,
      })
      newMarkers.set(venue.id, newMarker)
    }

    // Update list of necessary deletions
    markersToRemove.delete(venue.id)
  }

  // Remove markers which should not be shown anymore because they are not in the current venue list
  for (const marker of markersToRemove.values()) {
    marker.setMap(null)
  }

  // Set newly calculated markers
  setMarkers(newMarkers)
}

export default createDiscoverMapMarkers
