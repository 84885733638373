import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Faq, FaqTypeToNameMapping } from '../../VenueProfile.types'
import AccordionList from '../../../../components/AccordionList/AccordionList'
import AccordionItem from '../../../../components/AccordionList/AccordionItem'
import { Localized } from '../../../../localization/react'
import { VenueFaqQuestionType } from '../../../../graphql'

interface Props {
  faqs: Array<Faq>
  venueName: string
}

const Faq = ({ faqs, venueName }: Props) => {
  const { amplitude } = useAmplitude()

  const handleClick = (question: string) => () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Venue FAQ', { faq: { question } })
    }
  }

  return (
    <AccordionList>
      {faqs.map((faq, index) => {
        const questionNumber = index + 1
        return (
          <Box onClick={handleClick(faq.question)} key={index}>
            <AccordionItem
              headerNode={
                <Text style={{ fontWeight: 'bold', textTransform: 'none' }}>
                  {`${questionNumber}. `}
                  {faq.question === VenueFaqQuestionType.USP ? (
                    <Localized id="faq-type-why-visit-this-venue" params={{ venueName }} />
                  ) : (
                    <Localized id={FaqTypeToNameMapping[faq.question]} />
                  )}
                </Text>
              }
              contentText={faq.answer}
            />
          </Box>
        )
      })}
    </AccordionList>
  )
}

export default Faq
