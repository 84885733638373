import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'
import Svg from '@eversports/klimt-primitives/Svg'

import Star from '../assets/star.svg'
import { Localized } from '../../../../localization/react'
import { Review, Venue } from '../../ActivityDetails.types'
import Pagination from '../../components/Pagination'

import ReviewCard from './ReviewCard'
import ReviewContent from './ReviewContent'
import ShowMore from './ShowMore'
import { REVIEW_CARD_GAP, REVIEW_CARD_WIDTH } from './Reviews.constants'

interface Props {
  shownReviews: Array<Review>
  venue: Venue
  activeIndex: number
  handleCardClick: (id: string) => () => void
  hasMoreReviews: boolean
  togglePopup: () => void
  handleBack: () => void
  handleForward: () => void
}

const ReviewList = ({
  shownReviews,
  venue,
  activeIndex,
  handleCardClick,
  hasMoreReviews,
  togglePopup,
  handleBack,
  handleForward,
}: Props) => {
  const isFirstPage = activeIndex === 0
  return (
    <Stack gap={4} sx={{ overflow: 'hidden' }}>
      <Stack direction="row" gap={2}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Svg src={Star} width={12} height={12} />
          <Text color="midnight" sx={{ fontWeight: 'bold' }}>
            <Localized id="activity-details-venue-reviews-header" />
          </Text>
        </Stack>
        <Stack direction="row" gap={1}>
          <Text color="midnight" sx={{ fontWeight: 'bold' }}>
            {venue.rating}
          </Text>
          <Text color="carbon">{`(${venue.reviewCount})`}</Text>
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ maxWidth: { md: '600px', lg: '100%' } }}>
        <Stack
          direction={{ sm: 'row' }}
          gap={`${REVIEW_CARD_GAP}px`}
          sx={{
            width: '100%',
            transform: {
              xs: 'none',
              sm: `translateX(${activeIndex * -1 * (REVIEW_CARD_WIDTH + REVIEW_CARD_GAP)}px)`,
            },
            transition: '0.7s ease',
          }}
        >
          {shownReviews.map((review) => (
            <ReviewCard key={review.id} handleClick={handleCardClick(review.id)}>
              <ReviewContent review={review} />
            </ReviewCard>
          ))}
        </Stack>
        {hasMoreReviews && (
          <Box
            sx={{
              boxShadow: '0 0 5px 10px white',
              zIndex: 1,
              flexShrink: 0,
              display: { xs: 'none', sm: 'block' },
            }}
          />
        )}
      </Stack>
      <Box sx={{ display: { xs: hasMoreReviews ? 'block' : 'none', sm: 'none' } }}>
        <ShowMore handleClick={togglePopup} />
      </Box>
      <Box sx={{ display: { xs: 'none', sm: hasMoreReviews ? 'block' : 'none' } }}>
        <Pagination isFirstPage={isFirstPage} handleBack={handleBack} handleForward={handleForward} />
      </Box>
    </Stack>
  )
}
export default ReviewList
