import * as React from 'react'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import Drawer from '../../../../../components/Drawer'

import useSharingMessage from './hooks/useSharingMessage'
import ShareContent from './ShareContent'

interface Props {
  isOpen: boolean
  toggleDialog: () => void
  eventId: string
}

const ShareActions = ({ isOpen, toggleDialog, eventId }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const sharingMessage = useSharingMessage(eventId)

  return isMobile ? (
    <Drawer open={isOpen} onClose={toggleDialog}>
      <ShareContent sharingMessage={sharingMessage} eventId={eventId} />
    </Drawer>
  ) : (
    <Dialog open={isOpen} onClose={toggleDialog}>
      <DialogContent>
        <ShareContent sharingMessage={sharingMessage} eventId={eventId} />
      </DialogContent>
    </Dialog>
  )
}

export default ShareActions
