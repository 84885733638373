import * as React from 'react'
import Stack, { StackProps } from '@eversports/klimt-primitives/Stack'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

type BoxProps = Pick<StackProps, 'sx'>

const ContentWrapper = ({ children, sx }: React.PropsWithChildren<BoxProps>) => {
  return (
    <Stack
      sx={{
        gap: 4,
        padding: 4,
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'lightgray',
        boxShadow: boxShadows.default,
        borderRadius: { md: 1 },
        width: '100%',
        ...sx,
      }}
    >
      {children}
    </Stack>
  )
}

export default ContentWrapper
