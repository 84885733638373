import * as React from 'react'
import { useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Svg from '@eversports/klimt-primitives/Svg'
import Text from '@eversports/klimt-primitives/Text'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import { Localized } from '../../../localization/react'

import FriendsAttendingIcon from './assets/friends-attending.svg'
import FriendsAttendingPromotion from './FriendsAttendingPromotion'
import PlaceholderFriend from './PlaceholderFriend'

interface Props {
  userHandle: string
}

const FriendsAttendingPlaceholder = ({ userHandle }: Props) => {
  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleDialog = () => {
    logAmplitudeEventOnce({
      eventName: 'Viewed Friends Attending Placeholder',
      eventProperties: {},
    })
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Svg src={FriendsAttendingIcon} width={12} height={10} />
          <Text sx={{ fontWeight: 'bold', color: 'midnight' }}>
            <Localized id="friends-attending-section-title" />
          </Text>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ cursor: 'pointer', width: 'fit-content' }}
          onClick={toggleDialog}
        >
          <PlaceholderFriend index={0} />
          <PlaceholderFriend index={1} />
          <Text variant="small" sx={{ color: 'carbon', textDecoration: 'underline', marginLeft: 2 }}>
            <Localized id="friends-attending-add-friends-learn-more" />
          </Text>
        </Stack>
      </Stack>
      <FriendsAttendingPromotion isOpen={isModalOpen} toggleDialog={toggleDialog} userHandle={userHandle} />
    </>
  )
}

export default FriendsAttendingPlaceholder
