import { EventTypeToVenueProfileTab } from '../../../../../PublicUserProfile.types'
import { EventBookableItem } from '../FriendsUpcomingParticipations.types'

const getEventBookableItemLink = (publicEventSessionParticipation: EventBookableItem) => {
  return `/e${EventTypeToVenueProfileTab[publicEventSessionParticipation.eventType]}${
    publicEventSessionParticipation.shortId
  }`
}

export default getEventBookableItemLink
