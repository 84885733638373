import * as React from 'react'
import ChevronLeft from '@eversports/design-tokens/assets/icons/chevron-left.svg'
import ChevronRight from '@eversports/design-tokens/assets/icons/chevron-right.svg'
import Box from '@eversports/klimt-primitives/Box'
import Stack, { StackProps } from '@eversports/klimt-primitives/Stack'

import useMediaQueryBreakpoint from '../../../../../hooks/useMediaQueryBreakpoint'
import { Image } from '../../../VenueProfile.types'
import { CarouselDirection } from '../ImagesGallery'
import FavoriteAndShareButtons from '../../../components/FavoriteAndShareButtons'
import { MOBILE_IMAGE_HEIGHT } from '../ImagesGallery.constants'

import Arrow from './Arrow'
import SlidingImage from './SlidingImage'
import useGalleryKeyboardShortcuts from './hooks/useGalleryKeyboardShortcuts'
import Dots from './Dots'

const SlidingImageWrapper: React.FC<React.PropsWithChildren<StackProps>> = ({ children, sx, onClick }) => (
  <Stack
    onClick={onClick}
    justifyContent="center"
    alignItems="center"
    sx={{
      height: '100%',
      position: 'relative',
      width: '100%',
      overflowX: 'hidden',
      ...sx,
    }}
  >
    {children}
  </Stack>
)

interface Props {
  images: Array<Image>
  activeImageIndex: number
  setActiveImageIndex: React.Dispatch<React.SetStateAction<number>>
  carouselDirection: CarouselDirection
  setCarouselDirection: React.Dispatch<React.SetStateAction<CarouselDirection>>
  openLightbox?: () => void
  isInLightbox?: boolean
  handleClose?: () => void
}

const Carousel = ({
  images,
  activeImageIndex,
  setActiveImageIndex,
  carouselDirection,
  setCarouselDirection,
  openLightbox,
  handleClose,
  isInLightbox = false,
}: Props) => {
  const handleForward = () => {
    setCarouselDirection('left')
    setActiveImageIndex((prevIndex) => {
      const isAtEnd = prevIndex + 1 === images.length
      return isAtEnd ? 0 : prevIndex + 1
    })
  }

  const handleBack = () => {
    setCarouselDirection('right')
    setActiveImageIndex((prevIndex) => {
      const isAtBeginning = prevIndex - 1 < 0
      return isAtBeginning ? images.length - 1 : prevIndex - 1
    })
  }

  const shouldUseKeyboardShortcuts = isInLightbox && images.length > 1
  useGalleryKeyboardShortcuts({ handleForward, handleBack, shouldUseShortcuts: shouldUseKeyboardShortcuts })

  const screenBreakpoint = useMediaQueryBreakpoint()

  if (!screenBreakpoint) return null
  const isMobile = screenBreakpoint === 'xs'

  const shownImage = images[activeImageIndex]
  const imageSrc = shownImage.xLarge!
  const showArrows = images.length > 1

  const handleImageClick = () => {
    if (isInLightbox && handleClose) {
      handleClose()
    }
    if (!isInLightbox && openLightbox) {
      openLightbox()
    }
  }

  return isMobile ? (
    <Box
      sx={{
        width: '100%',
        height: isInLightbox ? '100%' : `${MOBILE_IMAGE_HEIGHT}px`,
        overflowY: 'hidden',
        touchAction: 'pan-x',
        position: 'relative',
      }}
    >
      <SlidingImageWrapper onClick={handleImageClick}>
        <SlidingImage
          imgSrc={imageSrc}
          index={activeImageIndex}
          direction={carouselDirection}
          handleBack={handleBack}
          handleForward={handleForward}
          disableClick={isInLightbox}
          styles={{
            minWidth: isInLightbox ? '100%' : 'auto',
            height: isInLightbox ? 'auto' : `${MOBILE_IMAGE_HEIGHT}px`,
            width: isInLightbox ? 'auto' : '100%',
          }}
        />
      </SlidingImageWrapper>
      {!isInLightbox && <FavoriteAndShareButtons />}
      <Dots imageCount={images.length} activeImageIndex={activeImageIndex} />
    </Box>
  ) : (
    <Stack
      onClick={handleClose}
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      {showArrows && (
        <Box flexBasis="10%" sx={{ zIndex: '2' }}>
          <Arrow iconSrc={ChevronLeft} onClick={handleBack} />
        </Box>
      )}
      <SlidingImageWrapper sx={{ flexBasis: '80%', pb: 8 }}>
        <SlidingImage
          imgSrc={imageSrc}
          index={activeImageIndex}
          direction={carouselDirection}
          handleBack={handleBack}
          handleForward={handleForward}
          disableClick
          styles={{ maxHeight: '100%' }}
        />
      </SlidingImageWrapper>
      {showArrows && (
        <Box flexBasis="10%" sx={{ zIndex: '2' }}>
          <Arrow iconSrc={ChevronRight} onClick={handleForward} />
        </Box>
      )}
    </Stack>
  )
}

export default Carousel
