import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { Court } from '../../Match.types'
import { Localized } from '../../../../localization/react'
import { CourtAreaTypeToNameMapping, CourtSurfaceTypeToNameMapping } from '../../Match.constants'
import CourtIcon from '../../assets/court.svg'

const CourtDetails = ({ court }: { court: Court }) => {
  const { name, area, surface } = court
  return (
    <Stack alignItems={{ xs: 'center', sm: 'initial' }} flex={{ xs: 1, sm: 'initial' }} gap={1}>
      <img src={CourtIcon} width="16px" height="12px" alt="Court" />
      <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>{name}</Text>
      <Stack direction="row" alignItems="center" gap={0.5}>
        {area && (
          <Text variant="xsmall">
            <Localized id={CourtAreaTypeToNameMapping[area] as any} />
          </Text>
        )}
        {surface && (
          <>
            <Text variant="xsmall">-</Text>
            <Text variant="xsmall">
              <Localized id={CourtSurfaceTypeToNameMapping[surface] as any} />
            </Text>
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default CourtDetails
