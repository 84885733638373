import * as React from 'react'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import KlimtDrawer from '@eversports/klimt-primitives/Drawer'

import Drawer from '../../../../components/Drawer'
import { PrivacySettingVisibility } from '../../../../App.types'

import EditPrivacySettingContent from './EditPrivacySettingContent'

interface Props {
  isOpen: boolean
  onClose: () => void
  selectedVisibility: PrivacySettingVisibility
  onApply: (visibility: PrivacySettingVisibility) => void
}

const EditPrivacySetting = ({ selectedVisibility, isOpen, onApply, onClose }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return isMobile ? (
    <Drawer open={isOpen} onClose={onClose}>
      <EditPrivacySettingContent selectedVisibility={selectedVisibility} onApply={onApply} onClose={onClose} />
    </Drawer>
  ) : (
    <KlimtDrawer anchor="right" open={isOpen} onClose={onClose} sx={{ padding: 6 }}>
      <EditPrivacySettingContent selectedVisibility={selectedVisibility} onApply={onApply} onClose={onClose} />
    </KlimtDrawer>
  )
}

export default EditPrivacySetting
