import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'
import EversportsLogoIcon from '@eversports/design-tokens/assets/logo/icon.svg'
import Link from '@eversports/klimt-primitives/Link'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import SectionCard from '../components/SectionCard'
import { FavoriteVenue } from '../../../PublicUserProfile.types'
import { VenueProfileTabsPrefix } from '../../../../../App.types'
import useLoggedInUserType from '../../../hooks/use-logged-in-user-type'
import { userTypeMapping } from '../../../PublicUserProfile.constants'

interface Props {
  venue: FavoriteVenue
}

const FavouriteVenue = ({ venue }: Props) => {
  const { userType } = useLoggedInUserType()
  const { amplitude } = useAmplitude()

  const handleClick = () => {
    if (!amplitude) return
    amplitude.logEvent('Clicked Favorite Venue Tile', {
      venue: { name: venue.name },
      userType: userTypeMapping[userType],
    })
  }

  return (
    <Link to={`${VenueProfileTabsPrefix.OVERVIEW}${venue.slug}`}>
      <SectionCard onClick={handleClick} variant="dense">
        <Stack direction="row" alignItems="center" gap={4}>
          <Box
            component="img"
            src={venue.logo?.medium ?? EversportsLogoIcon}
            sx={{
              width: '40px',
              height: '40px',
              border: '1px solid',
              borderColor: 'mediumgray',
              objectFit: 'cover',
              borderRadius: 0.5,
            }}
          ></Box>
          <Text sx={{ color: 'midnight' }}>{venue.name}</Text>
        </Stack>
      </SectionCard>
    </Link>
  )
}

export default FavouriteVenue
