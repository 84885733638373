import { breakpoints } from '@eversports/design-tokens/breakpoints'
import styled from '@eversports/klimt-design-components/styled'
import Stack from '@eversports/klimt-primitives/Stack'

import { customBreakpoints } from './Gallery.constants'

// max-width of card needs to consider padding from parent
export const GalleryWrapper = styled(Stack)`
  display: grid;
  gap: 12px 16px;
  grid-template-rows: 162px 162px;

  position: relative;

  justify-content: center;
  width: fit-content;
  margin: 0 auto;

  @media screen and (max-width: ${breakpoints.sm - 1}px) {
    display: none;
    height: 289px;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-template-columns: 448px 216px;
    height: 332px;

    div:nth-child(1) {
      grid-row: span 2;
    }
  }

  @media screen and (min-width: ${customBreakpoints.CUSTOM_BREAKPOINT_1}px) {
    grid-template-columns: 484px 236px;
    grid-template-rows: 177px 177px;
    gap: 8px 10px;
    height: 360px;
  }

  @media screen and (min-width: ${customBreakpoints.CUSTOM_BREAKPOINT_2}px) {
    grid-template-columns: 390px 186px 186px;
    grid-template-rows: 140px 140px;
    gap: 12px;
    height: 288px;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    grid-template-columns: 428px 208px 208px;
    grid-template-rows: 156px 156px;
    gap: 10px;
    height: 324px;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    grid-template-columns: 472px 228px 228px;
    grid-template-rows: 171px 171px;
    gap: 12px 14px;
    height: 352px;
  }

  @media screen and (min-width: ${customBreakpoints.CUSTOM_BREAKPOINT_3}px) {
    grid-template-columns: 438px 212px 212px 212px;
    grid-template-rows: 159px 159px;
    gap: 10px 12px;
    height: 327px;
  }

  @media screen and (min-width: ${customBreakpoints.CUSTOM_BREAKPOINT_4}px) {
    grid-template-columns: 478px 232px 232px 232px;
    grid-template-rows: 174px 174px;
    gap: 10px 12px;
    height: 356px;
  }

  @media screen and (min-width: ${customBreakpoints.CUSTOM_BREAKPOINT_5}px) {
    grid-template-columns: 496px 240px 240px 240px;
    grid-template-rows: 180px 180px;
    gap: 12px 16px;
    height: 370px;
  }

  @media screen and (min-width: ${customBreakpoints.CUSTOM_BREAKPOINT_6}px) {
    grid-template-columns: 448px 448px 216px 216px;
    grid-template-rows: 162px 162px;
    height: 334px;

    div:nth-child(2) {
      grid-row: span 2;
    }
  }

  @media screen and (min-width: ${breakpoints.xl}px) {
    grid-template-columns: 480px 480px 232px 232px;
    grid-template-rows: 174px 174px;
    gap: 12px 16px;
    height: 360px;
  }

  @media screen and (min-width: ${customBreakpoints.CUSTOM_BREAKPOINT_7}px) {
    grid-template-columns: 504px 504px 252px 252px;
    grid-template-rows: 182px 182px;
    gap: 10px 8px;
    height: 378px;
  }
`
