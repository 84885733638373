import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import { PublicUser, UserType } from '../../../PublicUserProfile.types'
import useLoggedInUserType from '../../../hooks/use-logged-in-user-type'

import UserProfilePicture from './UserProfilePicture'
import ShareUserProfileButton from './ShareUserProfileButton'
import FriendshipButton from './FriendshipButton'
import BackButton from './BackButton'
import SettingsButton from './SettingsButton'
import FriendsCount from './FriendsCount'

type Props = Pick<PublicUser, 'firstName' | 'lastName' | 'handle' | 'bio' | 'profilePicture' | 'friendsCount'>

const UserDetailsCard = ({ firstName, lastName, handle, bio, profilePicture, friendsCount }: Props) => {
  const { userType } = useLoggedInUserType()

  return (
    <Box
      sx={{
        paddingX: { xs: 4, md: 11 },
        paddingY: 6,
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'lightgray',
        boxShadow: boxShadows.default,
        borderRadius: [0, 1],
        width: '100%',
        position: 'relative',
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        textAlign={{ xs: 'center', md: 'initial' }}
        gap={{ xs: 2, md: 11 }}
        alignItems="center"
      >
        {userType === UserType.PROFILE_OWNER && <BackButton />}
        <UserProfilePicture profilePicture={profilePicture?.medium} />
        <Stack gap={{ xs: 4, md: 6 }}>
          <Stack gap={3}>
            <Stack gap={1}>
              <Heading is="h3" sx={{ color: 'black' }}>
                {firstName} {lastName}
              </Heading>
              <Stack direction="row" alignItems="center" margin={{ xs: 'auto', md: 'initial' }} gap={2}>
                <Text variant="small" sx={{ color: 'carbon' }}>
                  @{handle}
                </Text>
                {Boolean(friendsCount) && <FriendsCount count={friendsCount!} handle={handle} />}
              </Stack>
            </Stack>
            {bio && <Text>{bio}</Text>}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent={{ xs: 'center', md: 'initial' }} gap={6}>
            <FriendshipButton />
            <Stack
              direction="row"
              gap={2}
              sx={{
                position: { xs: 'absolute', md: 'relative' },
                top: { xs: '16px', md: 'initial' },
                right: { xs: '16px', md: 'initial' },
              }}
            >
              <ShareUserProfileButton />
              {userType === UserType.PROFILE_OWNER && <SettingsButton />}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

export default UserDetailsCard
