import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../localization/react'
import { Company } from '../../App.types'

interface Props {
  company: Company
  isOnline: boolean
}

const MultipleLocationsTag = ({ company, isOnline }: Props) => {
  const locationCount = company.venues.length
  const hasMultipleLocations = !isOnline && locationCount > 1

  if (!hasMultipleLocations) {
    return null
  }

  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        border: '1px solid #7700ee45',
        boxSizing: 'border-box',
        borderRadius: 80,
        background: '#7700ee0f',
        width: 'fit-content',
      }}
    >
      <Text variant="small" sx={{ fontWeight: 'bold', color: 'secondary.main' }}>
        <Localized id="venue-profile-more-locations-tag" params={{ locationCount }} />
      </Text>
    </Box>
  )
}
export default MultipleLocationsTag
