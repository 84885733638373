import { VenueOfferingType } from '../../../../App.types'
import { VenueOffering } from '../../VenueProfile.types'

export const NOT_SHOWN_VENUE_OFFERINGS = [
  VenueOfferingType.VENUE_OFFERING_LIVESTREAM,
  VenueOfferingType.VENUE_OFFERING_INDOOR_ACTIVITY,
  VenueOfferingType.VENUE_OFFERING_OUTDOOR_ACTIVITY,
]

export const INTERNAL_LINKED_TABS: Array<VenueOffering> = [
  VenueOfferingType.VENUE_OFFERING_TEAM,
  VenueOfferingType.VENUE_OFFERING_PUBLIC_MATCHES,
]
