import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { useParams } from 'react-router-dom'

import { Localized } from '../../../localization/react'
import { useConnectToSeeContentPublicUserQuery } from '../../../graphql'
import { PrivacySettingField } from '../../../App.types'

import ConnectToSeeMoreImage from './assets/connect-to-see-more.webp'

interface Props {
  field: PrivacySettingField
}

const ConnectToSeeContent = ({ field }: Props) => {
  const { userHandle } = useParams<{ userHandle: string }>()
  const { data } = useConnectToSeeContentPublicUserQuery({ variables: { handle: userHandle } })

  if (!data) return null

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '100%', md: '400px' },
        background: 'rgba(255,255,255,0.7)',
        backdropFilter: 'blur(4px)',
        textAlign: 'center',
        padding: 10,
      }}
    >
      <Stack gap={5} justifyContent="center" alignItems="center" margin="auto" height="100%">
        <img src={ConnectToSeeMoreImage} width="60px" height="50px" alt="connect to see more image" />
        <Stack gap={1} alignItems="center">
          <Text variant="large" sx={{ fontWeight: 'bold', color: 'midnight' }}>
            <Localized id="connect-to-see-more-content" params={{ firstName: data.publicUser.firstName, field }} />
          </Text>
          <Text>
            <Localized id="this-profile-information-is-only-visible-to-connected-users" />
          </Text>
        </Stack>
      </Stack>
    </Box>
  )
}

export default ConnectToSeeContent
