import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import { Localized } from '../../../localization/react'
import { RecommendationsSectionWrapper } from '../RecommendationsSectionWrapper'
import RecommendationsViewAllButton from '../RecommendationsViewAllButton'
import Routes from '../Recommendations.routes'
import AppRoutes from '../../../App.routes'
import TicketIcon from '../assets/ticket-icon.svg'
import { RecommendationsIconSmall } from '../RecommendationsIcon'
import { DEFAULT_NUMBER_OF_CARDS_TO_SHOW } from '../Recommendations'
import ContainedXLWidthContainer from '../../../components/ContainedXLWidthContainer'

import TrialCard from './TrialCard'
import TrialCardsSkeletons from './TrialCardsSkeletons'
import { Venue } from './TrialCards.types'

interface Props {
  venues?: Array<Venue>
  loading: boolean
}

const TRIAL_OFFERS_SCREEN_URL = `${AppRoutes.RECOMMENDATIONS}${Routes.TRIAL_OFFER_RECOMMENDATIONS}`

const TrialCardsSection = ({ venues, loading }: Props) => {
  const showViewAll = venues && venues.length > DEFAULT_NUMBER_OF_CARDS_TO_SHOW
  const venuesToShow = venues ? venues.slice(0, DEFAULT_NUMBER_OF_CARDS_TO_SHOW) : []

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (!loading && venues?.length === 0) {
    return null
  }

  return (
    <Stack
      sx={{
        backgroundColor: 'white',
        px: { xs: 4, md: 8 },
        boxShadow: '0px 1px 1px rgba(20, 20, 20, 0.2)',
        border: '0.5px solid',
        borderColor: 'lightgray',
        borderTop: 'none',
      }}
    >
      <ContainedXLWidthContainer>
        <Stack py={10} gap={4}>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="flex-end">
            <Stack flexDirection="row" alignItems="flex-end" spacing={4}>
              <RecommendationsIconSmall>
                <img src={TicketIcon} alt="ticket-icon" width="16px" height="auto" loading="lazy" />
              </RecommendationsIconSmall>
              <Heading is="h3">
                <Localized id="trial-offers-title" />
              </Heading>
            </Stack>
            {showViewAll && !isMobile && <RecommendationsViewAllButton linkUrl={TRIAL_OFFERS_SCREEN_URL} />}
          </Stack>
          <RecommendationsSectionWrapper>
            {loading || !venues ? (
              <TrialCardsSkeletons numberOfCopies={DEFAULT_NUMBER_OF_CARDS_TO_SHOW} />
            ) : (
              venuesToShow.map((venue, idx) => <TrialCard key={idx} venue={venue} />)
            )}
          </RecommendationsSectionWrapper>
          {showViewAll && isMobile && <RecommendationsViewAllButton linkUrl={TRIAL_OFFERS_SCREEN_URL} />}
        </Stack>
      </ContainedXLWidthContainer>
    </Stack>
  )
}

export default TrialCardsSection
