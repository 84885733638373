import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import useGetTopContentHeight from '../../../../hooks/useGetTopContentHeight'
import { Localized, useIntl } from '../../../../localization/react'
import getStaticPageMetadata from '../../../../helpers/get-static-page-metadata'
import MetaHelmet from '../../../../components/MetaHelmet'

import PrivateProfileImage from './assets/private-profile.webp'

const PrivateProfile = () => {
  const intl = useIntl()
  const { origin } = useSettingsContext()
  const { DESKTOP_TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])

  const meta = getStaticPageMetadata({
    title: intl.privateProfilePageTitle(),
    description: intl.privateProfilePageDescription(),
    url: origin,
  })

  return (
    <>
      <MetaHelmet meta={meta} noIndex />
      <Stack
        sx={{
          backgroundColor: 'limelite',
          height: `calc(100vh - ${DESKTOP_TOP_CONTENT_HEIGHT}px)`,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        component="main"
        gap={3}
      >
        <img src={PrivateProfileImage} alt="private profile default image" width="64px" height="64px" />
        <Stack gap={2} textAlign="center">
          <Text variant="large" sx={{ fontWeight: 'bold', color: 'midnight' }}>
            <Localized id="private-profile-title" />
          </Text>
          <Text variant="small" sx={{ color: 'midnight' }}>
            <Localized id="private-profile-description" />
          </Text>
        </Stack>
      </Stack>
    </>
  )
}

export default PrivateProfile
