import { SupportedTLD } from '@eversports/react-app-base/tld-configs'

import { FromType, MatchCompetitionType, MatchGender, MatchLevel } from './App.types'

export const ManagerLinksMapping: { [key in SupportedTLD]: { business: string; corporate: string } } = {
  de: {
    business:
      'https://www.eversportsmanager.com/de/partner-werden/?utm_source=Referrals&utm_medium=marketplace-for-studios&utm_campaign=mp-b2b-landingpage',
    corporate: 'https://www.eversportscorporate.com/de/corporate-sports/',
  },
  at: {
    business:
      'https://www.eversportsmanager.com/de/partner-werden/?utm_source=Referrals&utm_medium=marketplace-for-studios&utm_campaign=mp-b2b-landingpage',
    corporate: 'https://www.eversportscorporate.com/de/corporate-sports/',
  },
  ch: {
    business:
      'https://www.eversportsmanager.com/de/partner-werden/?utm_source=Referrals&utm_medium=marketplace-for-studios&utm_campaign=mp-b2b-landingpage',
    corporate: 'https://www.eversportscorporate.com/corporate-sports/',
  },
  fr: {
    business:
      'https://www.eversportsmanager.com/fr/devenir-partenaire/?utm_source=Referrals&utm_medium=marketplace-for-studios&utm_campaign=mp-b2b-landingpage',
    corporate: 'https://www.eversportscorporate.com/corporate-sports/',
  },
  nl: {
    business:
      'https://www.eversportsmanager.com/nl/partner-worden/?utm_source=Referrals&utm_medium=marketplace-for-studios&utm_campaign=mp-b2b-landingpage',
    corporate: 'https://www.eversportscorporate.com/corporate-sports/',
  },
  be: {
    business:
      'https://www.eversportsmanager.com/nl/partner-worden/?utm_source=Referrals&utm_medium=marketplace-for-studios&utm_campaign=mp-b2b-landingpage',
    corporate: 'https://www.eversportscorporate.com/corporate-sports/',
  },
  es: {
    business:
      'https://www.eversportsmanager.com/es/?utm_source=Referrals&utm_medium=marketplace-for-studios&utm_campaign=mp-b2b-landingpage',
    corporate: 'https://www.eversportscorporate.com/corporate-sports/',
  },
  it: {
    business:
      'https://www.eversportsmanager.com/it/?utm_source=Referrals&utm_medium=marketplace-for-studios&utm_campaign=mp-b2b-landingpage',
    corporate: 'https://www.eversportscorporate.com/corporate-sports/',
  },
}

export const TRANSITION_FOR_MAP_ANIMATION = { ease: 'easeInOut', duration: 0.8 }
export const DO_NOT_TRACK_PROPERTY_KEY = 'dnt'
export const DO_NOT_TRACK_ADVERTISING_PROPERTY_KEY = 'dnt_a'

export const matchLevelTranslation = {
  [MatchLevel.BEGINNER]: 'match-rule-level-beginner',
  [MatchLevel.INTERMEDIATE]: 'match-rule-level-intermediate',
  [MatchLevel.ADVANCED]: 'match-rule-level-advanced',
  [MatchLevel.ANY]: 'match-rule-level-any',
} as const

export const matchCompetitionTypeTranslation = {
  [MatchCompetitionType.FRIENDLY]: 'match-rule-competition-type-friendly',
  [MatchCompetitionType.COMPETITIVE]: 'match-rule-competition-type-competitive',
} as const

export const matchGenderTranslation = {
  [MatchGender.MALE_ONLY]: 'match-rule-gender-male-only',
  [MatchGender.FEMALE_ONLY]: 'match-rule-gender-female-only',
  [MatchGender.UNISEX]: 'match-rule-gender-unisex',
} as const

export const DEFAULT_CITY_SLUGS: { [key in SupportedTLD]: string } = {
  de: 'berlin',
  es: 'madrid',
  nl: 'amsterdam',
  fr: 'paris',
  it: 'milan',
  at: 'wien',
  ch: 'zuerich',
  be: 'bruxelles',
}

export enum AuthRoutes {
  LOGIN = '/auth',
  REGISTER = '/auth/register',
}

export const fromMapping: { [key in FromType]: string } = {
  [FromType.HEADER]: 'header',
  [FromType.USER_MENU]: 'user menu',
  [FromType.MATCH_DETAIL]: 'match detail',
  [FromType.MY_ACCOUNT]: 'my account',
  [FromType.MY_FRIENDS_LIST]: 'my friends',
  [FromType.MY_SENT_FRIEND_REQUESTS]: 'my sent friend requests',
  [FromType.MY_RECEIVED_FRIEND_REQUESTS]: 'my received friend requests',
  [FromType.USER_FRIENDS_LIST]: 'user friends',
  [FromType.MY_PROFILE]: 'my profile',
  [FromType.USER_SEARCH]: 'user search',
  [FromType.ACTIVITY_FRIENDS]: 'activity friends',
  [FromType.FRIENDS_ATTENDING_PLACEHOLDER]: 'friends attending placeholder',
  [FromType.SHARE_WITH_FRIENDS_PLACEHOLDER]: 'share with friends placeholder',
  [FromType.PROMOTION_NOTIFICATION_CENTER]: 'promotion notification center',
  [FromType.SUGGESTED_FRIENDS_MUTUAL_ACTIVITY]: 'friend suggestion - mutual activity',
  [FromType.SUGGESTED_FRIENDS_MUTUAL_FRIEND]: 'friend suggestion - mutual friend',
  [FromType.FRIENDS_UPCOMING_PARTICIPATIONS_PLACEHOLDER]: 'friends upcoming activities placeholder',
}

export const DEFAULT_SOCIAL_SHARING_IMAGE_LINK =
  'https://files.eversports.com/673af717-6c34-4121-9c76-8af2b6d39e7c/espng-large.webp'
