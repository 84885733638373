import * as React from 'react'
import { default as MuiTabs, TabsProps } from '@mui/material/Tabs'

export { TabsProps }

export const Tabs = (props: TabsProps) => {
  return <MuiTabs {...props} />
}

export default Tabs
