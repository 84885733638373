import * as React from 'react'
import { useEffect, useState } from 'react'
import Tooltip, { TooltipProps } from '@eversports/klimt-primitives/Tooltip'
import ClickAwayListener from '@eversports/klimt-utilities/ClickAwayListener'

type PickedTooltipProps = Pick<
  TooltipProps,
  | 'placement'
  | 'children'
  | 'disableTouchListener'
  | 'disableFocusListener'
  | 'open'
  | 'onClose'
  | 'onOpen'
  | 'componentsProps'
>

export interface Props extends PickedTooltipProps {
  content: React.ReactNode
  timeout?: number
}

const PromotionalTooltip = ({ children, content, open, onClose, onOpen, timeout = 500, ...rest }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = (event: Event | React.SyntheticEvent<Element, Event>) => {
    if (onClose) {
      onClose(event)
    }
    setIsOpen(false)
  }

  useEffect(() => {
    // Only allow this behaviour when the component is not controlled
    if (open !== undefined) return
    const timeoutInterval = setTimeout(() => handleOpen(), timeout)
    return () => {
      clearInterval(timeoutInterval)
    }
  }, [])

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Tooltip
        arrow
        title={content}
        disableHoverListener
        open={open ?? isOpen}
        onOpen={onOpen ?? handleOpen}
        onClose={onClose ?? handleClose}
        {...rest}
      >
        <div>{children}</div>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default PromotionalTooltip
