import * as React from 'react'
import { useState, useRef } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { Localized, useIntl } from '../../../../../localization/react'
import { EventDifficultyLevel } from '../../../ActivityDetails.types'

import Info from './assets/tooltipInfo.svg'
import DifficultyDescriptionsPopup from './DifficultyDescriptionsPopup'
import { DifficultyToNameMapping } from './Difficulty.constants'

interface Props {
  difficulty: EventDifficultyLevel
}

const Difficulty = ({ difficulty }: Props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const containerRef = useRef<HTMLDivElement | null>(null)
  const intl = useIntl()

  const togglePopup = () => setIsPopupOpen(!isPopupOpen)

  return (
    <>
      <Stack
        onClick={togglePopup}
        gap={2}
        direction="row"
        alignItems="center"
        forwardedRef={containerRef}
        sx={{ cursor: 'pointer', width: 'fit-content' }}
      >
        <Text color="darkgray" sx={{ fontWeight: 'bold' }}>
          <Localized id="activity-details-difficulty-header" />
        </Text>
        <Text color="darkgray">
          <Localized id={DifficultyToNameMapping[difficulty] as any} />
        </Text>
        <img src={Info} width="12px" height="12px" alt={intl.infoToolTipAltText()} />
      </Stack>
      <DifficultyDescriptionsPopup
        isOpen={isPopupOpen}
        handleClose={togglePopup}
        difficulty={difficulty}
        anchorEl={containerRef.current}
      />
    </>
  )
}

export default Difficulty
