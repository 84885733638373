import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { Sport } from '../../../../App.types'

interface Props {
  sports: Array<Sport>
}

// TODO - see if our plan to define a central interlinking system can decide which of these sport+city combos should be linked
// Otherwise, we are linking to discover pages that should definitely not be crawled
const OfferedSports = ({ sports }: Props) => (
  <Stack component="ul" direction="row" flexWrap="wrap" gap={2.5} pl={0} sx={{ listStyle: 'none' }}>
    {sports.map((sport) => (
      <li key={sport.id}>
        <Text variant="label" color="darkgray">
          {sport.name}
        </Text>
      </li>
    ))}
  </Stack>
)

export default OfferedSports
