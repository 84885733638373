import * as React from 'react'
import { useEffect } from 'react'
import TextField from '@eversports/klimt-primitives/TextField'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import Stack from '@eversports/klimt-primitives/Stack'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import { Sport } from '../../../App.types'
import { Localized } from '../../../localization/react'
import { useDiscoverReducer, useDiscoverState } from '../DiscoverContext'
import FilterMenu from '../../../components/FilterMenu'
import FilterCheckbox from '../../../components/FilterCheckbox'

import CollapsibleFilter from './components/CollapsibleFilter'
import getVenueFilterAmplitudeEventName from './helpers/get-venue-filter-amplitude-event-name'
import { DiscoverFilterTypes } from './Filters.types'

interface InitialValues {
  subSports: Array<string>
}

interface Props {
  availableSubSports: Array<Sport>
}

export const DesktopSubSportsFilterComponent: React.FC<React.PropsWithChildren<Props>> = ({ availableSubSports }) => {
  const { subSportsActiveFilters } = useDiscoverState()

  const subSportsActiveFiltersNames = subSportsActiveFilters
    ? subSportsActiveFilters
        .map((activeFilter) => {
          return availableSubSports.find((sport) => sport.id === activeFilter)?.name as string
        })
        .reverse()
    : []

  const numberOfPossibleFilters = availableSubSports.length
  const areAllFiltersSelected = subSportsActiveFiltersNames.length === numberOfPossibleFilters

  return (
    <FilterMenu
      header={<Localized id="filters-sub-sports-heading" />}
      activeFilters={areAllFiltersSelected ? ['filters-studio-all-selected'] : subSportsActiveFiltersNames}
    >
      <SubSportsFilterForm availableSubSports={availableSubSports} />
    </FilterMenu>
  )
}

export const MobileSubSportsFilterComponent: React.FC<React.PropsWithChildren<Props>> = ({ availableSubSports }) => {
  return (
    <CollapsibleFilter header={<Localized id="filters-sub-sports-heading" />}>
      <SubSportsFilterForm availableSubSports={availableSubSports} />
    </CollapsibleFilter>
  )
}

const SubSportsFilterForm: React.FC<React.PropsWithChildren<Props>> = ({ availableSubSports }) => {
  const dispatch = useDiscoverReducer()
  const { subSportsActiveFilters } = useDiscoverState()
  const [shownAvailableSubSports, setShownAvailableSubSports] = React.useState(availableSubSports)
  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()

  const methods = useForm<InitialValues>({
    defaultValues: { subSports: subSportsActiveFilters || [] },
  })

  const { isDirty } = methods.formState
  const subSports = methods.watch('subSports')

  // Reset the form when venue active filters are reset and the form has been changed
  useEffect(() => {
    if (!isDirty || subSportsActiveFilters?.length) return
    methods.reset({ subSports: [] })
  }, [subSportsActiveFilters])

  useEffect(() => {
    if (subSports.length) {
      logAmplitudeEventOnce({
        eventName: getVenueFilterAmplitudeEventName(),
        eventProperties: { type: DiscoverFilterTypes.subSport, tab: 'venue' },
      })
    }
    dispatch({ type: 'UPDATE_SUB_SPORTS_FILTER', payload: subSports })
  }, [subSports])

  const handleSearch = (value: string) => {
    setShownAvailableSubSports(
      availableSubSports.filter((sport) => sport.name.toLowerCase().includes(value.toLowerCase())),
    )
  }

  return (
    <Stack spacing={3}>
      <TextField
        label={<Localized id="filters-sub-sports-search-label" />}
        onChange={({ target }) => handleSearch(target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: 10,
          },
        }}
      />
      <Form methods={methods}>
        <Stack spacing={2}>
          {shownAvailableSubSports.map((sport) => (
            <FilterCheckbox key={sport.id} value={sport.id} label={sport.name} name="subSports" />
          ))}
        </Stack>
      </Form>
    </Stack>
  )
}
