import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import ContainedXLWidthContainer from '../../components/ContainedXLWidthContainer'

import { AssignedMembership } from './InvoiceHistory.types'
import Header from './Header'
import InvoiceTable from './InvoiceTable'
import MobileInvoiceCards from './InvoiceTable/MobileInvoiceCards'

interface Props {
  assignedProduct: AssignedMembership
}

const InvoiceHistoryComponent = ({ assignedProduct }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const productName = assignedProduct.product.name
  const [firstVenue] = assignedProduct.product.venues
  const venueName = firstVenue.name
  const periods = assignedProduct.periods
  const invoices = periods.flatMap((period) => period.invoices)

  return (
    <Stack
      sx={{
        backgroundColor: 'eggshell',
      }}
      component="main"
    >
      <ContainedXLWidthContainer>
        <Header productName={productName} venueName={venueName} />
        <Box
          sx={{
            p: { xs: 4, md: 8 },
            pt: 4,
            width: '100%',
          }}
        >
          {isMobile ? <MobileInvoiceCards invoices={invoices} /> : <InvoiceTable invoices={invoices} />}
        </Box>
      </ContainedXLWidthContainer>
    </Stack>
  )
}

export default InvoiceHistoryComponent
