import { default as React, useEffect, useRef, useState } from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import useDocumentWidth from '../../hooks/useDocumentWidth'

interface Sport {
  id: string
  name: string
  slug: string
}

interface Props {
  maxNumberOfVisibleSports?: number
  sports: Array<Sport>
}

const DEFAULT_MAX_NUMBER_OF_VISIBLE_SPORTS_DESKTOP = 4
const DEFAULT_MAX_NUMBER_OF_VISIBLE_SPORTS_MOBILE = 2
const AVERAGE_SPORT_TAG_WIDTH = 85
const EXTRA_SPORTS_TAG_WIDTH = 35
const GAP_UNIT = 10

const SportTags = ({ maxNumberOfVisibleSports, sports }: Props) => {
  const theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)
  const documentWidth = useDocumentWidth()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const defaultMaxNumberOfVisibleSports = isMobile
    ? DEFAULT_MAX_NUMBER_OF_VISIBLE_SPORTS_MOBILE
    : DEFAULT_MAX_NUMBER_OF_VISIBLE_SPORTS_DESKTOP
  const numberOfShownSportTags = maxNumberOfVisibleSports || defaultMaxNumberOfVisibleSports

  const [numberOfVisibleSportTags, setNumberOfVisibleSportTags] = useState<number>(numberOfShownSportTags)
  const [numberOfExtraSportsTags, setNumberOfExtraSportsTags] = useState<number>(sports.length - numberOfShownSportTags)

  const shownSportTags = sports.slice(0, numberOfVisibleSportTags)

  useEffect(() => {
    if (!ref.current) return

    const numberOfVisibleSportTags = Math.floor(
      (ref.current.offsetWidth - EXTRA_SPORTS_TAG_WIDTH - defaultMaxNumberOfVisibleSports * GAP_UNIT) /
        AVERAGE_SPORT_TAG_WIDTH,
    )
    const numberOfExtraSportsTags = sports.length - numberOfVisibleSportTags

    setNumberOfVisibleSportTags(Math.floor(numberOfVisibleSportTags))
    setNumberOfExtraSportsTags(numberOfExtraSportsTags)
  }, [ref.current, documentWidth])

  return (
    <Stack
      direction="row"
      gap={2.5}
      alignItems="center"
      sx={{ overflow: 'auto', whiteSpace: 'nowrap', height: '25.5px' }}
      forwardedRef={ref}
    >
      {shownSportTags.map((sport) => (
        <Text
          key={sport.id}
          variant="label"
          sx={{
            color: 'darkgray',
          }}
        >
          {sport.name}
        </Text>
      ))}
      {numberOfExtraSportsTags > 0 && (
        <Text
          variant="label"
          sx={{
            color: 'darkgray',
            border: '1px solid',
            borderColor: 'lightgray',
            borderRadius: 20,
            px: 2,
            py: 0.5,
          }}
        >
          + {numberOfExtraSportsTags}
        </Text>
      )}
    </Stack>
  )
}

export default SportTags
