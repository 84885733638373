import * as React from 'react'

import Box from '../Box'
import CircularProgress from '../CircularProgress'
import Stack from '../Stack'

interface Props {
  isLoading?: boolean
}

const Processing = ({ isLoading, children }: React.PropsWithChildren<Props>) => {
  return (
    <Box sx={{ position: 'relative', width: 'auto' }}>
      <Box sx={{ pointerEvents: isLoading ? 'none' : 'auto', opacity: isLoading ? 0.25 : 1 }}>{children}</Box>
      {isLoading && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <CircularProgress size={30} />
        </Stack>
      )}
    </Box>
  )
}

export default Processing
