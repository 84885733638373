import { MatchVisibility } from '../../Match.types'
import { MatchSettingsState, Validations } from '../DetailsSection.types'

import getRequiredFieldsForDesiredVisibility from './get-required-fields-for-desired-visibility'
import isMatchStateValid from './is-match-state-valid'

interface Args {
  state: MatchSettingsState
  desiredVisibility: MatchVisibility
  validations: Array<Validations>
}

const checkDesiredMatchVisibilityValidity = ({ state, desiredVisibility, validations }: Args): boolean => {
  const requiredFieldsForDesiredVisibility = getRequiredFieldsForDesiredVisibility({ desiredVisibility, validations })
  return isMatchStateValid({ state, requiredFields: requiredFieldsForDesiredVisibility })
}

export default checkDesiredMatchVisibilityValidity
