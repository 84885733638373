import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import Text from '@eversports/klimt-primitives/Text'
import EmailIcon from '@eversports/design-tokens/assets/icons/email.svg'
import { assertNever } from '@eversports/klimt-utilities/assert-never'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../localization/react'

import SharingActionCopyText from './SharingActionCopyText'
import SharingActionBox from './SharingActionBox'
import WhatsAppIcon from './assets/whatsapp.svg'
import SharingActionMessage from './SharingActionMessage'
import SharingActionCopyLink from './SharingActionCopyLink'

import { ShareType } from '@eversports/klimt-patterns/SharingActions/SharingActions.types'

interface EncodedMessageType {
  type: ShareType.Whatsapp | ShareType.Email | ShareType.Messages
  encodedMessage: string
}

interface TranslatedMessageType {
  type: ShareType.CopyText
  translatedMessage: string
}

interface LinkType {
  type: ShareType.CopyLink
  link: string
}

type Props = EncodedMessageType | TranslatedMessageType | LinkType

const SharingAction = (props: Props) => {
  const { amplitude } = useAmplitude()

  const trackClick = (type: ShareType) => {
    if (amplitude) {
      amplitude.logEvent('Clicked Sharing Option', {
        type,
      })
    }
  }

  switch (props.type) {
    case ShareType.CopyText:
      return <SharingActionCopyText trackClick={trackClick} translatedMessage={props.translatedMessage} />
    case ShareType.CopyLink:
      return <SharingActionCopyLink trackClick={trackClick} link={props.link} />
    case ShareType.Email:
      return (
        <Stack alignItems="center" justifyContent="center" gap={2} flexGrow={1} flexShrink={0}>
          <Link to={`mailto:?body=${props.encodedMessage}`} external blank ariaLabel="email friends">
            <SharingActionBox onClick={() => trackClick(props.type)}>
              <Icon src={EmailIcon} color="midnight" size="default" />
            </SharingActionBox>
          </Link>
          <Text variant="small" textAlign="center" sx={{ color: 'midnight' }}>
            <Localized id="sharing-actions-email" />
          </Text>
        </Stack>
      )
    case ShareType.Messages:
      return <SharingActionMessage trackClick={trackClick} message={props.encodedMessage} />
    case ShareType.Whatsapp:
      return (
        <Stack alignItems="center" justifyContent="center" gap={2} flexGrow={1} flexShrink={0}>
          <Link
            to={`https://api.whatsapp.com/send?text=${props.encodedMessage}`}
            data-action="share/whatsapp/share"
            ariaLabel="whatsapp friends"
            external
            blank
          >
            <SharingActionBox onClick={() => trackClick(props.type)} disableGutters>
              <Icon src={WhatsAppIcon} color="midnight" size="x-large" />
            </SharingActionBox>
          </Link>
          <Text variant="small" textAlign="center" sx={{ color: 'midnight' }}>
            <Localized id="sharing-actions-whatsapp" />
          </Text>
        </Stack>
      )
    default:
      return assertNever(props)
  }
}

export default SharingAction
