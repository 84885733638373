import { MarketplaceListingPagesPrefix, DiscoverPrefix } from '../../../../App.types'
import { DiscoverOfferingType, DiscoverySport } from '../../Discover.types'

const OfferingsTabs = {
  [DiscoverOfferingType.DISCOVER_OFFERING_CLASS_ACTIVITY]: {
    toPrefix: MarketplaceListingPagesPrefix.CLASSES,
    localizedId: 'classes-and-trainings-offering-heading',
    isExternal: true,
  },
  [DiscoverOfferingType.DISCOVER_OFFERING_TRAINING_ACTIVITY]: {
    toPrefix: MarketplaceListingPagesPrefix.TRAININGS,
    localizedId: 'trainings-offering-heading',
    isExternal: true,
  },
  [DiscoverOfferingType.DISCOVER_OFFERING_LIVESTREAM]: {
    toPrefix: MarketplaceListingPagesPrefix.LIVESTREAMS,
    localizedId: 'livestreams-offering-heading',
    isExternal: true,
  },
  [DiscoverOfferingType.DISCOVER_OFFERING_VIDEO]: {
    toPrefix: MarketplaceListingPagesPrefix.VIDEOS,
    localizedId: 'videos-offering-heading',
    isExternal: true,
  },
  [DiscoverOfferingType.DISCOVER_OFFERING_PUBLIC_MATCH]: {
    toPrefix: MarketplaceListingPagesPrefix.PUBLIC_MATCHES,
    localizedId: 'public-match-offering-heading',
    isExternal: false,
    isNew: true,
  },
  [DiscoverOfferingType.DISCOVER_OFFERING_COURSE_ACTIVITY]: {
    toPrefix: MarketplaceListingPagesPrefix.COURSES,
    localizedId: 'courses-offering-heading',
    isExternal: true,
  },
  [DiscoverOfferingType.DISCOVER_OFFERING_WORKSHOP_ACTIVITY]: {
    toPrefix: MarketplaceListingPagesPrefix.WORKSHOPS,
    localizedId: 'workshops-offering-heading',
    isExternal: true,
  },
  [DiscoverOfferingType.DISCOVER_OFFERING_EVENT_ACTIVITY]: {
    toPrefix: MarketplaceListingPagesPrefix.EVENTS,
    localizedId: 'events-offering-heading',
    isExternal: true,
  },
  [DiscoverOfferingType.DISCOVER_OFFERING_RETREAT_ACTIVITY]: {
    toPrefix: MarketplaceListingPagesPrefix.RETREATS,
    localizedId: 'retreats-offering-heading',
    isExternal: true,
  },
  [DiscoverOfferingType.DISCOVER_OFFERING_EDUCATION_ACTIVITY]: {
    toPrefix: MarketplaceListingPagesPrefix.EDUCATION,
    localizedId: 'education-offering-heading',
    isExternal: true,
  },
  [DiscoverOfferingType.DISCOVER_OFFERING_CAMP_ACTIVITY]: {
    toPrefix: MarketplaceListingPagesPrefix.CAMPS,
    localizedId: 'camp-offering-heading',
    isExternal: true,
  },
  [DiscoverOfferingType.DISCOVER_OFFERING_OPENTRAINING_ACTIVITY]: {
    toPrefix: MarketplaceListingPagesPrefix.OPEN_TRAININGS,
    localizedId: 'open-trainings-offering-heading',
    isExternal: true,
  },
}

interface Props {
  offerings: Array<DiscoverOfferingType>
  sport: DiscoverySport
  citySlug: string
}

const getOfferingTabs = ({ offerings, sport, citySlug }: Props) => {
  const sportCityParams = `${sport.slug}/${citySlug}`

  const consolidatedOfferings = offerings.map((offering) =>
    offering === DiscoverOfferingType.DISCOVER_OFFERING_TRAINING_ACTIVITY
      ? DiscoverOfferingType.DISCOVER_OFFERING_CLASS_ACTIVITY
      : offering,
  )

  const uniqueOfferings = Array.from(new Set<DiscoverOfferingType>(consolidatedOfferings))

  const offeringTabs = uniqueOfferings.map((offering) => {
    const { toPrefix, ...rest } = OfferingsTabs[offering]

    if (offering === DiscoverOfferingType.DISCOVER_OFFERING_LIVESTREAM) {
      return {
        to: `${toPrefix}/sport/${sport.slug}`,
        offering,
        ...rest,
      }
    }

    if (offering === DiscoverOfferingType.DISCOVER_OFFERING_VIDEO) {
      return {
        to: `${toPrefix}?sport=${sport.id}`,
        offering,
        ...rest,
      }
    }

    if (offering === DiscoverOfferingType.DISCOVER_OFFERING_PUBLIC_MATCH) {
      return {
        to: `${toPrefix}/${citySlug}/${sport.slug}`,
        offering,
        ...rest,
      }
    }

    return {
      to: `${toPrefix}/${sportCityParams}`,
      offering,
      ...rest,
    }
  })

  // Reference to the own discovery page
  const discoveryPageOfferingTab = {
    to: `${DiscoverPrefix.APP_SPORT_CITY}/${sportCityParams}`,
    crawlerLink: `${DiscoverPrefix.SEO_SPORT_CITY}/${sportCityParams}`,
    localizedId: 'studios-heading',
    isExternal: false,
    offering: null,
  }

  return [discoveryPageOfferingTab, ...offeringTabs]
}

export default getOfferingTabs
