import { SupportedTLD } from '@eversports/react-app-base/tld-configs'

export const DEFAULT_CITY_SLUGS_BY_TLD: { [key in SupportedTLD]: string } = {
  at: 'wien',
  nl: 'amsterdam',
  de: 'berlin',
  es: 'barcelona',
  ch: 'zuerich',
  it: 'rome',
  be: 'bruxelles',
  fr: 'paris',
}

export const DEFAULT_SPORT_SLUGS_BY_TLD: { [key in SupportedTLD]: Array<string> } = {
  ch: ['yoga', 'pilates', 'fitness', 'squash', 'meditation', 'funktionelles-training', 'tennis', 'badminton', 'padel'],
  at: ['yoga', 'pilates', 'fitness', 'tennis', 'meditation', 'tanzen', 'padel', 'fussball', 'boxen'],
  de: [
    'yoga',
    'pilates',
    'fitness',
    'tanzen',
    'crossfit',
    'tennis',
    'rueckenfit',
    'stretching',
    'funktionelles-training',
  ],
  fr: ['yoga', 'pilates', 'poledance', 'stretching', 'tanzen', 'bauch-beine-po', 'boxen', 'fitness'],
  it: ['yoga'],
  be: [
    'yoga',
    'poledance',
    'pilates',
    'fitness',
    'stretching',
    'bauch-beine-po',
    'boxen',
    'tanzen',
    'schwimmen',
    'funktionelles-training',
  ],
  nl: ['pilates', 'yoga', 'fitness', 'meditation', 'tanzen', 'barre', 'zirkeltraining', 'stretching', 'poledance'],
  es: ['yoga', 'pilates', 'tanzen', 'zumba', 'zirkeltraining', 'funktionelles-training', 'hiit'],
}
