import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import { useEffect } from 'react'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import { useDiscoverReducer, useDiscoverState } from '../DiscoverContext'
import { Localized } from '../../../localization/react'
import { CourtAreaTypeToNameMapping, CourtAreaType } from '../Discover.types'
import FilterMenu from '../../../components/FilterMenu'
import FilterCheckbox from '../../../components/FilterCheckbox'

import { DiscoverFilterTypes } from './Filters.types'
import CollapsibleFilter from './components/CollapsibleFilter'
import getVenueFilterAmplitudeEventName from './helpers/get-venue-filter-amplitude-event-name'

interface InitialValues {
  venueCourtAreas: Array<CourtAreaType>
}

const VenueCourtAreaFilterForm = () => {
  const dispatch = useDiscoverReducer()
  const { venueCourtAreasActiveFilters } = useDiscoverState()

  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()

  const methods = useForm<InitialValues>({
    defaultValues: { venueCourtAreas: venueCourtAreasActiveFilters || [] },
  })

  const { isDirty } = methods.formState
  const venueCourtAreas = methods.watch('venueCourtAreas')

  // Reset the form when venue active filters are reset and the form has been changed
  useEffect(() => {
    if (!isDirty || venueCourtAreasActiveFilters?.length) return
    methods.reset({ venueCourtAreas: [] })
  }, [venueCourtAreasActiveFilters])

  useEffect(() => {
    if (venueCourtAreas.length) {
      logAmplitudeEventOnce({
        eventName: getVenueFilterAmplitudeEventName(),
        eventProperties: { type: DiscoverFilterTypes.area, tab: 'venue' },
      })
    }

    dispatch({ type: 'UPDATE_COURT_AREA_FILTER', payload: venueCourtAreas })
  }, [venueCourtAreas])

  return (
    <Form methods={methods}>
      <Stack spacing={2}>
        {Object.entries(CourtAreaTypeToNameMapping).map(([key, value]) => (
          <FilterCheckbox key={key} name="venueCourtAreas" value={key} label={<Localized id={value as any} />} />
        ))}
      </Stack>
    </Form>
  )
}

export const DesktopVenueCourtAreaFilter = ({ isDefaultOpen }: { isDefaultOpen: boolean }) => {
  const { venueCourtAreasActiveFilters } = useDiscoverState()

  const venueCourtAreaActiveFiltersTranslationKeys = venueCourtAreasActiveFilters
    ? venueCourtAreasActiveFilters
        .map((activeFilter) => {
          return CourtAreaTypeToNameMapping[activeFilter]
        })
        .reverse()
    : []

  const numberOfPossibleFilters = Object.keys(CourtAreaTypeToNameMapping).length
  const areAllFiltersSelected = venueCourtAreaActiveFiltersTranslationKeys.length === numberOfPossibleFilters

  return (
    <FilterMenu
      isDefaultOpen={isDefaultOpen}
      header={<Localized id="filters-court-area-heading" />}
      activeFilters={
        !areAllFiltersSelected ? venueCourtAreaActiveFiltersTranslationKeys : ['filters-studio-all-selected']
      }
    >
      <VenueCourtAreaFilterForm />
    </FilterMenu>
  )
}

export const MobileVenueCourtAreaFilter = () => {
  return (
    <CollapsibleFilter header={<Localized id="filters-court-area-heading" />}>
      <VenueCourtAreaFilterForm />
    </CollapsibleFilter>
  )
}
