import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import { Localized } from '../../../../localization/react'

import BellIcon from './assets/bell-icon.svg'
import FakeNotification from './FakeNotification'
import { FAKE_NOTIFICATIONS } from './NotificationPromotion.constants'

interface Props {
  hasFriends: boolean
}

const NotificationPromotionContent = ({ hasFriends }: Props) => {
  const notificationsToShow = hasFriends ? FAKE_NOTIFICATIONS.slice(1, 2) : FAKE_NOTIFICATIONS
  return (
    <Stack gap={6}>
      <Box
        sx={{
          margin: 'auto',
          borderRadius: 10,
          backgroundColor: '#D3EDEA',
          boxShadow: boxShadows.hover,
          border: '4px solid',
          borderColor: 'white',
          paddingX: 10,
          paddingY: 9,
        }}
      >
        <img src={BellIcon} width="42px" height="48px" />
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Heading is="h1" variant="large" sx={{ color: 'black' }}>
          {hasFriends ? (
            <Localized id="has-friends-notification-promotion-title" />
          ) : (
            <Localized id="no-friends-notification-promotion-title" />
          )}
        </Heading>
        <Text sx={{ color: 'midnight' }}>
          {hasFriends ? (
            <Localized id="has-friends-notification-promotion-text" />
          ) : (
            <Localized id="no-friends-notification-promotion-text" />
          )}
        </Text>
      </Box>
      {notificationsToShow.map((notification) => (
        <FakeNotification notification={notification} key={notification.headerTextKey} />
      ))}
    </Stack>
  )
}

export default NotificationPromotionContent
