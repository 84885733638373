import { Venue } from '../pages/VenueProfile/VenueProfile.types'

export enum StructuredDataType {
  AGGREGATE_RATING = 'AggregateRating',
  SPORTS_ACTIVITY_LOCATION = 'SportsActivityLocation',
  POSTAL_ADDRESS = 'PostalAddress',
}

export interface AggregateRatingStructuredData {
  '@context': string
  '@type': StructuredDataType.AGGREGATE_RATING
  itemReviewed: {
    '@type': StructuredDataType.SPORTS_ACTIVITY_LOCATION
    name: string
    address: {
      '@type': StructuredDataType.POSTAL_ADDRESS
      addressLocality: string
      addressRegion: string
    }
  }
  ratingValue: string
  bestRating: string
  reviewCount: string
}

interface Props {
  venue: Venue
}

export default function getAggregateRatingStructuredData({ venue }: Props): AggregateRatingStructuredData | undefined {
  const {
    name,
    location: { city, country },
    rating,
    reviewCount,
  } = venue

  if (!rating || !reviewCount) {
    return undefined
  }

  return {
    '@context': 'http://schema.org',
    '@type': StructuredDataType.AGGREGATE_RATING,
    itemReviewed: {
      '@type': StructuredDataType.SPORTS_ACTIVITY_LOCATION,
      name: name,
      address: {
        '@type': StructuredDataType.POSTAL_ADDRESS,
        addressLocality: city,
        addressRegion: country,
      },
    },
    ratingValue: rating?.toString() ?? '0',
    bestRating: '5',
    reviewCount: reviewCount?.toString() ?? '0',
  }
}
