import * as React from 'react'

import CollapsibleFilter from '../../../../Filters/components/CollapsibleFilter'
import { Localized } from '../../../../../../localization/react'
import { Action } from '../../helpers/discover-sport-matches-reducer'
import { MatchLevel } from '../../../../../../App.types'

import LevelsFilterForm from './LevelsFilterForm'

interface Props {
  dispatch: React.Dispatch<Action>
  selectedLevels?: Array<MatchLevel>
}

export const CollapsibleLevelsFilter = ({ dispatch, selectedLevels }: Props) => {
  return (
    <CollapsibleFilter header={<Localized id="filters-matches-level-heading" />}>
      <LevelsFilterForm dispatch={dispatch} selectedLevels={selectedLevels} />
    </CollapsibleFilter>
  )
}

export default CollapsibleLevelsFilter
