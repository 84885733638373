import { MonetaryValue } from '../../App.types'

export interface AssignedMembership {
  id: string
  periods: Array<Period>
  product: Membership
}

interface Period {
  id: string
  invoices: Array<Invoice>
}

export interface Invoice {
  id: string
  number: string
  amount: MonetaryValue
  downloadLink: string | null
  issueDate: string | null
  usedPaymentServices: Array<PaymentServiceType>
  state: InvoiceState
}

export enum PaymentServiceType {
  PAYMENT_SERVICE_BANCONTACT = 'PAYMENT_SERVICE_BANCONTACT',
  PAYMENT_SERVICE_CASH = 'PAYMENT_SERVICE_CASH',
  PAYMENT_SERVICE_CHEQUECARD = 'PAYMENT_SERVICE_CHEQUECARD',
  PAYMENT_SERVICE_CHEQUEVACANCE = 'PAYMENT_SERVICE_CHEQUEVACANCE',
  PAYMENT_SERVICE_CREDIT = 'PAYMENT_SERVICE_CREDIT',
  PAYMENT_SERVICE_CREDITCARD = 'PAYMENT_SERVICE_CREDITCARD',
  PAYMENT_SERVICE_CREDITCARD_OTC = 'PAYMENT_SERVICE_CREDITCARD_OTC',
  PAYMENT_SERVICE_DEBITCARD = 'PAYMENT_SERVICE_DEBITCARD',
  PAYMENT_SERVICE_IDEAL = 'PAYMENT_SERVICE_IDEAL',
  PAYMENT_SERVICE_MANUAL = 'PAYMENT_SERVICE_MANUAL',
  PAYMENT_SERVICE_MARKETINGDISCOUNT = 'PAYMENT_SERVICE_MARKETINGDISCOUNT',
  PAYMENT_SERVICE_PAYPAL = 'PAYMENT_SERVICE_PAYPAL',
  PAYMENT_SERVICE_SEPA = 'PAYMENT_SERVICE_SEPA',
  PAYMENT_SERVICE_SEPA_ONLINE = 'PAYMENT_SERVICE_SEPA_ONLINE',
  PAYMENT_SERVICE_SOFORT = 'PAYMENT_SERVICE_SOFORT',
  PAYMENT_SERVICE_EPS = 'PAYMENT_SERVICE_EPS',
  PAYMENT_SERVICE_GIROPAY = 'PAYMENT_SERVICE_GIROPAY',
  PAYMENT_SERVICE_TRANSFER = 'PAYMENT_SERVICE_TRANSFER',
  PAYMENT_SERVICE_TWINT = 'PAYMENT_SERVICE_TWINT',
  PAYMENT_SERVICE_TWINT_OTC = 'PAYMENT_SERVICE_TWINT_OTC',
  PAYMENT_SERVICE_VOUCHER = 'PAYMENT_SERVICE_VOUCHER',
  PAYMENT_SERVICE_VOUCHER_OTC = 'PAYMENT_SERVICE_VOUCHER_OTC',
}

export const PaymentServiceTypeToNameMapping = {
  [PaymentServiceType.PAYMENT_SERVICE_BANCONTACT]: 'payment-service-type-bancontact',
  [PaymentServiceType.PAYMENT_SERVICE_CASH]: 'payment-service-type-cash',
  [PaymentServiceType.PAYMENT_SERVICE_CHEQUECARD]: 'payment-service-type-chequecard',
  [PaymentServiceType.PAYMENT_SERVICE_CHEQUEVACANCE]: 'payment-service-type-chequevacance',
  [PaymentServiceType.PAYMENT_SERVICE_CREDIT]: 'payment-service-type-credit',
  [PaymentServiceType.PAYMENT_SERVICE_CREDITCARD]: 'payment-service-type-creditcard',
  [PaymentServiceType.PAYMENT_SERVICE_CREDITCARD_OTC]: 'payment-service-type-creditcard-otc',
  [PaymentServiceType.PAYMENT_SERVICE_DEBITCARD]: 'payment-service-type-debitcard',
  [PaymentServiceType.PAYMENT_SERVICE_IDEAL]: 'payment-service-type-ideal',
  [PaymentServiceType.PAYMENT_SERVICE_MANUAL]: 'payment-service-type-manual',
  [PaymentServiceType.PAYMENT_SERVICE_MARKETINGDISCOUNT]: 'payment-service-type-marketing-discount',
  [PaymentServiceType.PAYMENT_SERVICE_PAYPAL]: 'payment-service-type-paypal',
  [PaymentServiceType.PAYMENT_SERVICE_SEPA]: 'payment-service-type-sepa',
  [PaymentServiceType.PAYMENT_SERVICE_SEPA_ONLINE]: 'payment-service-type-sepa-online',
  [PaymentServiceType.PAYMENT_SERVICE_SOFORT]: 'payment-service-type-sofort',
  [PaymentServiceType.PAYMENT_SERVICE_EPS]: 'payment-service-type-eps',
  [PaymentServiceType.PAYMENT_SERVICE_GIROPAY]: 'payment-service-type-giropay',
  [PaymentServiceType.PAYMENT_SERVICE_TRANSFER]: 'payment-service-type-transfer',
  [PaymentServiceType.PAYMENT_SERVICE_TWINT]: 'payment-service-type-twint',
  [PaymentServiceType.PAYMENT_SERVICE_TWINT_OTC]: 'payment-service-type-twint-otc',
  [PaymentServiceType.PAYMENT_SERVICE_VOUCHER]: 'payment-service-type-voucher',
  [PaymentServiceType.PAYMENT_SERVICE_VOUCHER_OTC]: 'payment-service-type-voucher-otc',
} as const

interface Venue {
  id: string
  name: string
}

interface Membership {
  id: string
  name: string
  venues: Array<Venue>
}

export enum InvoiceState {
  INVOICE_STATE_FULLY_PAID = 'INVOICE_STATE_FULLY_PAID',
  INVOICE_STATE_OPEN = 'INVOICE_STATE_OPEN',
  INVOICE_STATE_REFUND = 'INVOICE_STATE_REFUND',
  INVOICE_STATE_CANCELLED = 'INVOICE_STATE_CANCELLED',
}
