import * as React from 'react'
import Avatar from '@eversports/klimt-primitives/Avatar'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import ExpandableContent from '@eversports/klimt-patterns/ExpandableContent'
import FormattedContent from '@eversports/klimt-primitives/FormattedContent'

import { Localized, useIntl } from '../../../../localization/react'
import { Trainer } from '../../ActivityDetails.types'
import DefaultAvatar from '../../assets/avatar.svg'

const MAX_HEIGHT_TRAINER_INFO = 62

interface Props {
  trainer: Trainer
}

const TrainerContent = ({ trainer }: Props) => {
  const intl = useIntl()
  const { nickname, education, description, image } = trainer
  return (
    <>
      <Stack direction="row" alignItems="center" gap={3}>
        <Avatar
          src={image?.xSmall ?? DefaultAvatar}
          alt={intl.activityDetailsTeacherLabel()}
          sx={{ width: { xs: '50px', lg: '60px' }, height: { xs: '50px', lg: '60px' } }}
        />
        <Text color="midnight" sx={{ fontWeight: 'bold' }}>
          {nickname}
        </Text>
      </Stack>
      {description && (
        <Stack>
          <Text color="midnight" sx={{ fontWeight: 'bold' }}>
            <Localized id="activity-details-trainer-description" />
          </Text>
          <ExpandableContent clampedTextHeight={MAX_HEIGHT_TRAINER_INFO}>
            <FormattedContent htmlContent={description} />
          </ExpandableContent>
        </Stack>
      )}
      {education && (
        <Stack>
          <Text color="midnight" sx={{ fontWeight: 'bold' }}>
            <Localized id="activity-details-trainer-education" />
          </Text>
          <ExpandableContent clampedTextHeight={MAX_HEIGHT_TRAINER_INFO}>
            <FormattedContent htmlContent={education} />
          </ExpandableContent>
        </Stack>
      )}
    </>
  )
}

export default TrainerContent
