import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import Divider from '@eversports/klimt-primitives/Divider'

import { Localized } from '../../../../../localization/react'
import { PublicUser as PublicUserType } from '../../../ActivityDetails.types'
import { From } from '../../../../../App.types'
import UserInfo from '../../../../../components/UserInfo'

interface Props {
  friends: Array<PublicUserType>
}

const AllParticipatingFriendsContent = ({ friends }: Props) => {
  return (
    <Stack padding={6} paddingTop={0} gap={{ xs: 1, md: 4 }} minWidth={{ xs: '100%', md: '550px' }}>
      <Stack
        gap={{ xs: 4, md: 8 }}
        sx={{
          zIndex: 2,
          backgroundColor: 'white',
          position: 'sticky',
          top: { xs: '58px', md: 0 },
          paddingY: 5,
        }}
      >
        <Heading is="h3" textAlign="center" display={{ xs: 'none', md: 'initial' }}>
          <Localized id="friends-attending-section-title" />
        </Heading>
      </Stack>
      <Stack gap={{ xs: 4, md: 6 }} alignItems="center">
        {friends.map((friend) => (
          <Stack gap={3} width="100%" key={friend.id}>
            <UserInfo
              firstName={friend.firstName}
              lastName={friend.lastName}
              handle={friend.handle}
              profilePicture={friend.profilePicture?.small}
              from={From.ACTIVITY_FRIENDS}
            />
            <Divider sx={{ border: 0, borderTop: '1px solid', borderColor: 'mediumgray' }} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

export default AllParticipatingFriendsContent
