import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import StoreBadge from '../../../components/StoreBadge'
import { Localized } from '../../../localization/react'
import SectionWrapper from '../components/SectionWrapper'

import DownloadAppsStock from './assets/download-apps-stock.webp'
import DownloadAppsPhone from './assets/download-apps-phone.webp'

const DownloadApps = () => (
  <SectionWrapper sx={{ contentVisibility: 'auto' }}>
    <Stack
      sx={{
        flexDirection: { sm: 'row' },
        alignItems: 'center',
        position: 'relative',
        paddingBottom: { xs: 8, sm: 0 },
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          order: { sm: 1 },
          width: '100%',
          position: 'relative',
        }}
      >
        <img
          src={DownloadAppsPhone}
          loading="lazy"
          alt="Preview of Eversports app"
          width={282}
          height={524}
          style={{
            width: '50%',
            height: 'auto',
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            width: { xs: '75%', sm: '50%' },
            transform: { xs: 'rotate(5deg) translateX(-35%)', sm: 'rotate(5deg) translateX(-50%)' },
            borderRadius: 1,
            background: 'white',
            padding: 2,
            boxShadow: boxShadows.default,
            flexShrink: 0,
          }}
        >
          <img
            src={DownloadAppsStock}
            loading="lazy"
            alt=""
            width={288}
            height={188}
            style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
          />
        </Box>
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Heading
          is="h2"
          variant="xlarge"
          color="midnight"
          sx={{ fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'", fontWeight: 500 }}
        >
          <Localized id="home-rebrand-download-app-title" />
        </Heading>
        <Text variant="large" sx={{ paddingBottom: 6 }}>
          <Localized id="home-rebrand-download-app-description" />
        </Text>
        <Stack direction="row" gap={4} justifyContent={{ xs: 'center', sm: 'flex-start', flexWrap: 'wrap' }}>
          <StoreBadge type="app-store" width="160px" height="fit-content" colorVariant="black" />
          <StoreBadge type="play-store" width="180px" height="fit-content" colorVariant="transparent" />
        </Stack>
      </Stack>
    </Stack>
  </SectionWrapper>
)

export default DownloadApps
