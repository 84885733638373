import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../../localization/react'
import { MatchVisibility } from '../../../Match.types'

interface Props {
  value: MatchVisibility
  isSelected: boolean
  isDisabled: boolean
}

const MatchVisibilityToggleRadioButtonLabel = ({ value, isSelected, isDisabled }: Props) => {
  return (
    <Box sx={{ width: 'inherit', whiteSpace: 'nowrap', opacity: isDisabled ? 0.5 : 1 }}>
      <Text sx={{ color: isSelected && !isDisabled ? 'primary.main' : 'text', fontWeight: 'bold' }}>
        <Localized id="match-visibility-toggle-label" params={{ visibility: value }} />
      </Text>
      <Text variant="xsmall" sx={{ color: isSelected && !isDisabled ? 'primary.main' : 'carbon' }}>
        <Localized id="match-visibility-toggle-label-subtitle" params={{ visibility: value }} />
      </Text>
    </Box>
  )
}

export default MatchVisibilityToggleRadioButtonLabel
