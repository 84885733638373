import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Link from '@eversports/klimt-primitives/Link'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { MatchSport, VenueProfileTabsPrefix } from '../../../../App.types'
import { Localized } from '../../../../localization/react'
import getTimeRangeForDate from '../../../../utils/get-time-range-for-date'

import CreateYourMatchSvg from './assets/create-your-match-icon.svg'
import CreateYourMatchCardAdvantage from './CreateYourMatchCardAdvantage'

interface Props {
  venueSlug: string
  sports?: Array<MatchSport>
  selectedSports?: Array<string>
  selectedDate: Date | null
}

const CreateYourMatchCard = ({ venueSlug, sports, selectedSports, selectedDate }: Props) => {
  const { amplitude } = useAmplitude()
  let to = `${VenueProfileTabsPrefix.SPORTS_FIELDS}${venueSlug}`
  const timeRange = getTimeRangeForDate(selectedDate)
  const selectedSportId = selectedSports?.length === 1 ? selectedSports[0] : null
  const selectedSportSlug =
    sports && selectedSportId ? sports.find((sport) => sport.id === selectedSportId)?.slug : null

  if (timeRange && selectedSportSlug) {
    to = `${VenueProfileTabsPrefix.SPORTS_FIELDS}${venueSlug}?sport=${selectedSportSlug}&date=${timeRange.start}`
  } else if (timeRange) {
    to = `${VenueProfileTabsPrefix.SPORTS_FIELDS}${venueSlug}?date=${timeRange.start}`
  } else if (selectedSportSlug) {
    to = `${VenueProfileTabsPrefix.SPORTS_FIELDS}${venueSlug}?sport=${selectedSportSlug}`
  }

  const handleTileClick = () => {
    if (!amplitude) return
    amplitude.logEvent('Clicked Create New Match Tile')
  }

  return (
    <Link to={to} external blank>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding={4}
        onClick={handleTileClick}
        sx={{
          backgroundColor: 'white',
          border: '1px solid',
          borderColor: 'primary.main',
          boxShadow: boxShadows.defaultPrimary,
          borderRadius: 2,
          cursor: 'pointer',
          '&:hover': {
            boxShadow: boxShadows.hover,
          },
        }}
      >
        <Stack gap={2}>
          <Heading is="h3" sx={{ color: 'primary.main' }}>
            <Localized id="create-your-match-card-title" />
          </Heading>
          <CreateYourMatchCardAdvantage index={1} />
          <CreateYourMatchCardAdvantage index={2} />
        </Stack>
        <img src={CreateYourMatchSvg} width="52px" height="52px" />
      </Stack>
    </Link>
  )
}

export default CreateYourMatchCard
