import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import Processing from '@eversports/klimt-primitives/Processing'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../../../../localization/react'
import { From } from '../../../../../../../App.types'
import { PublicUser } from '../../../../../PublicUserProfile.types'
import UserInfo from '../../../../../../../components/UserInfo'

import CancelFriendRequest from './CancelFriendRequest'

interface Props {
  friend: PublicUser
}

const SentFriendRequest = ({ friend }: Props) => {
  return (
    <Processing isLoading={false}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <UserInfo
          profilePicture={friend.profilePicture?.small}
          firstName={friend.firstName}
          lastName={friend.lastName}
          handle={friend.handle}
          from={From.MY_SENT_FRIEND_REQUESTS}
        />
        <Stack direction="row" alignItems="center" flexShrink={0} gap={2}>
          <Box sx={{ backgroundColor: 'lightgray', borderRadius: 10, paddingY: 1, paddingX: 3 }}>
            <Text variant="xsmall" sx={{ color: 'midnight' }}>
              <Localized id="friend-request-badge" />
            </Text>
          </Box>
          <CancelFriendRequest friendId={friend.id} />
        </Stack>
      </Stack>
    </Processing>
  )
}

export default SentFriendRequest
