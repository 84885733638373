import CircularProgress from '@eversports/klimt-primitives/CircularProgress'
import * as React from 'react'
import { useState } from 'react'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { LatLng } from '../../App.types'

import MapIconBadge from './MapIconBadge'
import CurrentLocation from './assets/current-geolocation.svg'
import setMyLocationMarker from './helpers/create-geolocation-markers'
import { getNavigatorLocation } from './helpers/get-geolocations'
import { getLocationFromCookie, setLocationCookie } from './helpers/get-set-geolocation-cookies'
import MapSnackbar from './MapSnackbar'

interface Props {
  map: google.maps.Map
  onClick?: (userMapLocation: LatLng) => void
}

const MapGeoLocation = ({ map, onClick }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false)
  const { amplitude } = useAmplitude()

  const openErrorMessage = () => setIsOpenSnackbar(true)

  // get the geolocation from a cookie, or the browser navigator
  const getGeolocation = async () => {
    const locationCookie = getLocationFromCookie()

    if (!locationCookie) {
      setIsLoading(true)
      return await getNavigatorLocation()
        .catch(() => openErrorMessage())
        .finally(() => setIsLoading(false))
    }

    return locationCookie
  }

  // use the location to position the map,
  // add blue dot markers to the map,
  // and save the location in a cookie (if allowed)
  const handleClickGoToCurrentLocation = async () => {
    const location = await getGeolocation()

    if (location) {
      map.setCenter(location)
      map.setZoom(16)
      setMyLocationMarker(map, location)
      setLocationCookie(location)
      if (onClick) {
        onClick({ latitude: location.lat, longitude: location.lng })
      }
    }

    if (amplitude) {
      amplitude.logEvent('Clicked Geolocation')
    }
  }

  return (
    <>
      <MapIconBadge onClick={() => void handleClickGoToCurrentLocation()}>
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <img src={CurrentLocation} alt="Map View" width="20px" height="20px" loading="lazy" />
        )}
      </MapIconBadge>
      <MapSnackbar open={isOpenSnackbar} setIsOpen={setIsOpenSnackbar} />
    </>
  )
}

export default MapGeoLocation
