import Icon from '@eversports/klimt-primitives/Icon'
import Stack from '@eversports/klimt-primitives/Stack'
import * as React from 'react'
import ChevronLeft from '@eversports/design-tokens/assets/icons/chevron-left.svg'
import ChevronRight from '@eversports/design-tokens/assets/icons/chevron-right.svg'

import IconBadge from '../../../../components/Pagination/IconBadge'

interface Props {
  isFirstPage: boolean
  isLastPage: boolean
  setPage: React.Dispatch<React.SetStateAction<number>>
  onClick?: () => void
  isLoading: boolean
}

const ReviewPagination = ({ isFirstPage, isLastPage, setPage, isLoading, onClick: handleClick }: Props) => {
  const handleBack = () => {
    if (isFirstPage || isLoading) return
    if (handleClick) handleClick()
    setPage((page) => page - 1)
  }

  const handleForward = () => {
    if (isLastPage || isLoading) return
    if (handleClick) handleClick()
    setPage((page) => page + 1)
  }

  const hidePagination = isFirstPage && isLastPage

  return hidePagination ? null : (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={4} sx={{ py: 2 }}>
      <IconBadge onClick={handleBack} isDisabled={isFirstPage}>
        <Icon src={ChevronLeft} size="large" color={isFirstPage ? 'mediumgray' : 'darkgray'} />
      </IconBadge>
      <IconBadge onClick={handleForward} isDisabled={isLastPage}>
        <Icon src={ChevronRight} size="large" color={isLastPage ? 'mediumgray' : 'darkgray'} />
      </IconBadge>
    </Stack>
  )
}

export default ReviewPagination
