import * as React from 'react'

import { Localized } from '../localization/react'

interface Props {
  value: number
  currencyCode: string
}

// We assume that all received values will be in cents, so we need to convert them
const MonetaryValue: React.FC<React.PropsWithChildren<Props>> = ({ value: valueInCents, currencyCode }) => {
  const value = valueInCents / 100
  return <Localized id="monetary-value" params={{ total: { value, currency: currencyCode } }} />
}

export default MonetaryValue
