import * as React from 'react'
import Processing from '@eversports/klimt-primitives/Processing'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import NoSsr from '@eversports/klimt-design-components/NoSsr'

import { Localized } from '../../../../../../localization/react'

import useFriendsUpcomingParticipations from './hooks/useFriendsUpcomingParticipations'
import getFriendsUpcomingParticipationsBlocks from './helpers/get-friends-upcoming-participations-blocks'
import FriendsUpcomingParticipationsBlock from './FriendsUpcomingParticipationsBlock'
import NoFriendsUpcomingParticipations from './NoFriendsUpcomingParticipations'
import FriendsUpcomingParticipationsPlaceholder from './FriendsUpcomingParticipationsPlaceholder'

interface Props {
  userHandle: string
}

const FriendsUpcomingParticipations = ({ userHandle }: Props) => {
  const { friendsUpcomingParticipations, loading, initialLoading, fetchMore, hasMoreResults } =
    useFriendsUpcomingParticipations()

  if (initialLoading) {
    return <FriendsUpcomingParticipationsPlaceholder />
  }

  const friendsUpcomingParticipantsBlocks = getFriendsUpcomingParticipationsBlocks(friendsUpcomingParticipations)

  if (!friendsUpcomingParticipantsBlocks.length) {
    return <NoFriendsUpcomingParticipations userHandle={userHandle} />
  }

  return (
    <Stack gap={{ xs: 6, md: 4 }} paddingTop={{ xs: 4, md: 0 }}>
      {friendsUpcomingParticipantsBlocks.map((block) => (
        <FriendsUpcomingParticipationsBlock
          key={block.date}
          date={block.date}
          upcomingParticipations={block.upcomingParticipations}
        />
      ))}
      {hasMoreResults && (
        <Processing isLoading={loading}>
          <Box
            sx={{ backgroundColor: 'limelite', borderRadius: 1, padding: 2, width: '100%', cursor: 'pointer' }}
            onClick={fetchMore}
          >
            <Text variant="small" sx={{ color: 'primary.main', fontWeight: 'bold', textAlign: 'center' }}>
              <Localized id="load-more-upcoming-friends-participations-button" />
            </Text>
          </Box>
        </Processing>
      )}
    </Stack>
  )
}

const FriendsUpcomingParticipationsWrapper = ({ userHandle }: Props) => {
  return (
    <NoSsr fallback={<FriendsUpcomingParticipationsPlaceholder />}>
      <FriendsUpcomingParticipations userHandle={userHandle} />
    </NoSsr>
  )
}

export default FriendsUpcomingParticipationsWrapper
