import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box from '@eversports/klimt-primitives/Box'

import { BaseNotification } from '../Notifications.types'
import NotificationIcon from '../NotificationIcon'

import NotificationTimeStamp from './NotificationTimeStamp'

interface Props {
  notification: BaseNotification
  onClick?: () => void
}

const NotificationContent = ({ notification, onClick }: Props) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        padding: 4,
        border: '1px solid',
        borderColor: 'mediumgray',
        borderRadius: 1,
        backgroundColor: 'white',
        boxShadow: boxShadows.default,
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: boxShadows.hover,
        },
      }}
    >
      <Stack direction="row" alignItems="flex-start" gap={4}>
        {notification.icon && <NotificationIcon icon={notification.icon} />}
        <Stack gap={1} width="100%">
          {notification.headerText && (
            <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>{notification.headerText}</Text>
          )}
          {notification.text && <Text sx={{ color: 'darkgray' }}>{notification.text}</Text>}
          <NotificationTimeStamp createdAt={notification.created} isNew={!notification.seen} />
        </Stack>
      </Stack>
    </Box>
  )
}

export default NotificationContent
