import * as React from 'react'
import { useEffect, useState } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../localization/react'
import { RecommendationsSectionWrapper } from '../RecommendationsSectionWrapper'
import useGetTopContentHeight from '../../../hooks/useGetTopContentHeight'

import TeacherSkeleton from './TeacherSkeleton'
import useRecommendationsTeachers, { NUMBER_OF_INITIAL_TEACHER_CARDS_FETCHED } from './hooks/useRecommendationsTeachers'
import TeachersIcon from './assets/teachers-icon.svg'
import TeacherCard from './TeacherCard'

const TeachersPage = () => {
  const { amplitude } = useAmplitude()
  const [isLoadingMoreTeachers, setIsLoadingMoreTeachers] = useState(false)
  const { teachers, fetchMore, hasMoreResults, loading } = useRecommendationsTeachers()
  const { DESKTOP_TOP_CONTENT_HEIGHT: TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR', 'PAGE_TITLE'])

  const handleShowMore = async () => {
    if (amplitude) {
      amplitude.logEvent('Triggered Load More Trainers')
    }

    setIsLoadingMoreTeachers(true)
    await fetchMore()
  }

  useEffect(() => {
    setIsLoadingMoreTeachers(false)
  }, [teachers])

  useEffect(() => {
    if (!amplitude) return
    amplitude.logEvent('Viewed Trainer Overview')
  }, [amplitude])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Stack
      sx={{
        backgroundColor: 'white',
        minHeight: `calc(100vh - ${TOP_CONTENT_HEIGHT}px)`,
      }}
      component="main"
    >
      <Box
        sx={{
          px: { xs: 4, md: 7 },
          py: { xs: 5, md: 10 },
        }}
      >
        <Stack gap={10}>
          <Stack justifyContent="center" alignItems="center" spacing={5}>
            <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
              <img src={TeachersIcon} alt="Teachers Icon" width="44px" height="44px" />
              <Heading is="h1" variant="xlarge">
                <Localized id="recent-teachers-header" />
              </Heading>
            </Stack>
            <Text variant="large" sx={{ color: 'darkgray', textAlign: 'center' }}>
              <Localized id="recent-teachers-sub-header" />
            </Text>
          </Stack>
          <RecommendationsSectionWrapper>
            {teachers &&
              teachers.map((teacher, index) => (
                <TeacherCard key={index} teacher={teacher} venue={teacher.recentlyTrainedUserAt} />
              ))}
            {(isLoadingMoreTeachers || loading) && (
              <TeacherSkeleton numberOfCopies={NUMBER_OF_INITIAL_TEACHER_CARDS_FETCHED} />
            )}
          </RecommendationsSectionWrapper>
          {hasMoreResults && (
            <Button
              variant="tertiary"
              onClick={() => void handleShowMore()}
              sx={{ padding: 3, margin: 'auto', width: '50%', maxWidth: '448px' }}
            >
              <Text sx={{ fontWeight: 'bold', textTransform: 'none' }}>
                <Localized id="load-more" />
              </Text>
            </Button>
          )}
        </Stack>
      </Box>
    </Stack>
  )
}

export default TeachersPage
