import { From, FromType } from '../App.types'

import useUrlParams from './use-url-params'

const useFromParam = () => {
  const { from } = useUrlParams()
  return getFromParam(from as From)
}

export const getFromParam = (from: From) => {
  switch (from) {
    case From.HEADER:
      return FromType.HEADER
    case From.USER_MENU:
      return FromType.USER_MENU
    case From.MY_ACCOUNT:
      return FromType.MY_ACCOUNT
    case From.MATCH_DETAIL:
      return FromType.MATCH_DETAIL
    case From.MY_FRIENDS_LIST:
      return FromType.MY_FRIENDS_LIST
    case From.MY_SENT_FRIEND_REQUESTS:
      return FromType.MY_SENT_FRIEND_REQUESTS
    case From.MY_RECEIVED_FRIEND_REQUESTS:
      return FromType.MY_RECEIVED_FRIEND_REQUESTS
    case From.USER_FRIENDS_LIST:
      return FromType.USER_FRIENDS_LIST
    case From.MY_PROFILE:
      return FromType.MY_PROFILE
    case From.USER_SEARCH:
      return FromType.USER_SEARCH
    case From.ACTIVITY_FRIENDS:
      return FromType.ACTIVITY_FRIENDS
    case From.FRIENDS_ATTENDING_PLACEHOLDER:
      return FromType.FRIENDS_ATTENDING_PLACEHOLDER
    case From.SHARE_WITH_FRIENDS_PLACEHOLDER:
      return FromType.SHARE_WITH_FRIENDS_PLACEHOLDER
    case From.PROMOTION_NOTIFICATION_CENTER:
      return FromType.PROMOTION_NOTIFICATION_CENTER
    case From.SUGGESTED_FRIENDS_MUTUAL_ACTIVITY:
      return FromType.SUGGESTED_FRIENDS_MUTUAL_ACTIVITY
    case From.SUGGESTED_FRIENDS_MUTUAL_FRIEND:
      return FromType.SUGGESTED_FRIENDS_MUTUAL_FRIEND
    case From.FRIENDS_UPCOMING_PARTICIPATIONS_PLACEHOLDER:
      return FromType.FRIENDS_UPCOMING_PARTICIPATIONS_PLACEHOLDER
    default:
      return null
  }
}

export default useFromParam
