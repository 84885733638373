import * as React from 'react'
import { StaticDatePicker as MuiStaticDatePicker, StaticDatePickerProps } from '@mui/x-date-pickers/StaticDatePicker'
import TextField from '@mui/material/TextField'

import DateLocalizationProvider from './DateLocalizationProvider'

type Props = Omit<StaticDatePickerProps<Date, Date>, 'renderInput'>

const StaticDatePicker = ({ ...props }: Props) => {
  return (
    <DateLocalizationProvider>
      <MuiStaticDatePicker
        {...props}
        renderInput={(params) => <TextField {...params} />}
        showToolbar={false}
        componentsProps={{
          actionBar: { actions: [] },
        }}
      />
    </DateLocalizationProvider>
  )
}

export default StaticDatePicker
