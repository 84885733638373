import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'

import { useIntl } from '../../../localization/react'
import { CitySearchResult, LocationSearchResult, SearchComponentProps } from '../Search.types'
import { getLocationPlaceholder } from '../SearchInput/helpers/get-location-placeholder'
import LocationSvg from '../SearchInput/LocationSvg'
import SearchComponent from '../SearchComponent'
import { STACKED_POSITIONS } from '../Search.constants'

import GeoLocationErrorSnackbar from './GeoLocationErrorSnackbar'

interface Props<T> extends SearchComponentProps<T> {
  location?: CitySearchResult
  hasGeoLocationError?: boolean
}

const SearchLocationComponent = ({
  searchValue,
  setSearchValue,
  results,
  suggestedResults,
  handleResultClick,
  handleResultEnter,
  isLoading,
  hasGeoLocationError,
  location,
  searchPosition,
  hasInputLabels,
}: Props<LocationSearchResult>) => {
  const intl = useIntl()

  const searchPlaceholder = getLocationPlaceholder(location)
  const inputLabel = intl.searchForLocationLabel()

  return (
    <Box flex={{ xs: STACKED_POSITIONS.includes(searchPosition) ? 1 : 2, sm: 2 }}>
      <GeoLocationErrorSnackbar hasError={hasGeoLocationError} />
      <SearchComponent
        inputLabel={inputLabel}
        hasInputLabels={hasInputLabels}
        searchPosition={searchPosition}
        iconAdornment={<LocationSvg size={12} />}
        placeholder={searchPlaceholder}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        results={results}
        suggestedResults={suggestedResults}
        handleResultClick={handleResultClick}
        handleResultEnter={handleResultEnter}
        isLoading={isLoading}
      />
    </Box>
  )
}

export default SearchLocationComponent
