import * as React from 'react'
import { useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'

import { ScheduleSession } from '../../ActivityDetails.types'
import { Localized } from '../../../../localization/react'
import Drawer from '../../components/Drawer'

import ScheduleCardContent from './ScheduleCardContent'

const DEFAULT_CARDS_TO_SHOW = 2

interface Props {
  sessions: Array<ScheduleSession>
}

const ScheduleCards = ({ sessions }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const togglePopup = () => {
    setIsModalOpen(!isModalOpen)
  }

  const hasMoreCards = sessions.length > DEFAULT_CARDS_TO_SHOW
  const shownMobileSessions = sessions.slice(0, DEFAULT_CARDS_TO_SHOW)

  return (
    <>
      <Stack gap={4} alignItems="center">
        <Stack
          sx={{
            borderTop: '1px solid',
            borderColor: 'mediumgray',
            width: '100%',
          }}
        >
          {shownMobileSessions.map((session) => (
            <ScheduleCardContent key={session.id} session={session} />
          ))}
        </Stack>
        {hasMoreCards && (
          <Box onClick={togglePopup} sx={{ width: '100%', textAlign: 'center' }}>
            <Text variant="label">
              <Localized id="activity-detail-products-show-more" />
            </Text>
          </Box>
        )}
      </Stack>
      <Drawer isOpen={isModalOpen} handleClose={togglePopup}>
        <Box sx={{ paddingY: 5, background: 'white' }}>
          <Text variant="large" color="midnight" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            <Localized id="activity-detail-schedule-heading" />
          </Text>
        </Box>
        <Stack alignItems="center" sx={{ overflow: 'scroll', 'div:last-child': { borderBottom: 'none' } }}>
          {sessions.map((session) => (
            <ScheduleCardContent key={session.id} session={session} />
          ))}
        </Stack>
      </Drawer>
    </>
  )
}

export default ScheduleCards
