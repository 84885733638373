export const SHOW_SAVED_BADGE_TIME = 1000
export const TIME_UNTIL_SAVE = 750

export const savedMessageVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      delay: 0.1,
      duration: 0.2,
    },
  },
}
