import * as React from 'react'
import { TableBody, TableRow, TableCell } from '@eversports/klimt-primitives/Table'
import Text from '@eversports/klimt-primitives/Text'
import MonetaryValue from '@eversports/klimt-localization/MonetaryValue'

import { Localized } from '../../../localization/react'
import { Invoice } from '../InvoiceHistory.types'

import DownloadInvoiceButton from './DownloadInvoiceButton'
import InvoiceStatusBadge from './InvoiceStatusBadge'
import UsedPaymentMethods from './UsedPaymentMethods'
import InvoiceDate from './InvoiceDate'

interface Props {
  invoices: Array<Invoice>
}

const TableContent = ({ invoices }: Props) => {
  return (
    <TableBody>
      {invoices.length ? (
        invoices.map((invoice) => (
          <TableRow key={invoice.number} sx={{ '&:last-child th': { border: 0 }, td: { borderColor: 'lightgray' } }}>
            <TableCell>
              <InvoiceDate invoiceDate={invoice.issueDate} />
            </TableCell>
            <TableCell>
              <UsedPaymentMethods usedPaymentServices={invoice.usedPaymentServices} invoiceNumber={invoice.number} />
            </TableCell>
            <TableCell>
              <Text>
                <MonetaryValue value={invoice.amount.value} currencyCode={invoice.amount.currency} />
              </Text>
            </TableCell>
            <TableCell>
              <InvoiceStatusBadge invoiceState={invoice.state} />
            </TableCell>
            <TableCell>
              <DownloadInvoiceButton invoiceId={invoice.id} downloadLink={invoice.downloadLink} />
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow sx={{ '&:last-child th': { border: 0 }, td: { borderColor: 'lightgray' } }}>
          <TableCell colSpan={5}>
            <Text textAlign="center">
              <Localized id="invoice-history-table-text-no-invoices" />
            </Text>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )
}

export default TableContent
