import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import { Localized } from '../../../../../localization/react'

interface Props {
  onClick: () => void
}

const ViewGalleryButton = ({ onClick: handleClick }: Props) => (
  <Box
    onClick={handleClick}
    sx={{
      background: '#141414cc',
      boxShadow: boxShadows.default,
      position: 'absolute',
      cursor: 'pointer',
      borderRadius: 10,
      paddingY: 2,
      paddingX: 3,
      bottom: '16px',
      right: '16px',
      display: { xs: 'none', sm: 'block' },
      '&:hover': {
        boxShadow: boxShadows.hover,
      },
    }}
  >
    <Text variant="small" sx={{ color: 'white', fontWeight: 'bold' }}>
      <Localized id="venue-profile-view-image-gallery-button" />
    </Text>
  </Box>
)

export default ViewGalleryButton
