import { default as React, createContext, Dispatch, useContext, useReducer } from 'react'

import { LatLng, ShownVenueAmenitiesType } from '../../App.types'

import {
  ActivityAvailability,
  BoundingBox,
  CourtAreaType,
  DiscoverySport,
  VenueAttributes,
  VenueOfferingFilterType,
  CourtSurfaceType,
} from './Discover.types'

interface DiscoverContextData {
  venueOfferingsActiveFilters?: Array<VenueOfferingFilterType>
  venueActivityAvailabilityActiveFilters?: Array<ActivityAvailability>
  venueAttributeActiveFilters?: Array<VenueAttributes>
  subSportsActiveFilters?: Array<string>
  initialSearchBoundingBox?: BoundingBox
  hoveredVenue?: string
  clickedVenueId?: string
  showDesktopListing: boolean
  showMobileMapView: boolean
  showFilterView: boolean
  venueCourtAreasActiveFilters?: Array<CourtAreaType>
  sport?: DiscoverySport
  venueCourtSlotDateActiveFilter: Date
  venueCourtSlotTimeActiveFilter: Date | null
  venueAmenitiesActiveFilters?: Array<ShownVenueAmenitiesType>
  venueCourtSurfaceActiveFilters?: Array<CourtSurfaceType>
  shouldConsiderDistanceForSearch?: boolean
  userMapLocation?: LatLng
}

interface UpdateOfferingsFilter {
  type: 'UPDATE_OFFERING_FILTER'
  payload: Array<VenueOfferingFilterType>
}

interface UpdateActivityAvailabilityFilter {
  type: 'UPDATE_ACTIVITY_AVAILABILITY_FILTER'
  payload: Array<ActivityAvailability>
}

interface UpdateVenueAttributesFilter {
  type: 'UPDATE_ATTRIBUTES_FILTERS'
  payload: Array<VenueAttributes>
}

interface UpdateSubSportsFilter {
  type: 'UPDATE_SUB_SPORTS_FILTER'
  payload: Array<string>
}

interface UpdateCourtAreaFilter {
  type: 'UPDATE_COURT_AREA_FILTER'
  payload: Array<CourtAreaType>
}

interface SetInitialSearchBoundingBox {
  type: 'SET_INITIAL_SEARCH_BOUNDING_BOX'
  payload: BoundingBox
}

interface ToggleShowListing {
  type: 'TOGGLE_SHOW_LISTING'
}

interface ResetFilters {
  type: 'RESET_FILTERS'
}

interface SetDiscoverySport {
  type: 'SET_DISCOVERY_SPORT'
  payload: DiscoverySport
}

interface SetHoveredVenue {
  type: 'SET_VENUE_HOVERED'
  payload?: string
}

interface ToggleMapView {
  type: 'TOGGLE_MAP_VIEW'
}

interface ToggleFilterView {
  type: 'TOGGLE_FILTER_VIEW'
}

interface SetClickedVenueId {
  type: 'SET_CLICKED_VENUE_ID'
  payload?: string
}

interface UpdateCourtSlotDateFilter {
  type: 'UPDATE_COURT_SLOT_DATE_FILTER'
  payload: Date
}

interface UpdateCourtSlotTimeFilter {
  type: 'UPDATE_COURT_SLOT_TIME_FILTER'
  payload: Date | null
}

interface UpdateVenueAmenitiesFilter {
  type: 'UPDATE_AMENITIES_FILTER'
  payload: Array<ShownVenueAmenitiesType>
}

interface UpdateVenueCourtSurfaceFilter {
  type: 'UPDATE_COURT_SURFACE_FILTER'
  payload: Array<CourtSurfaceType>
}

interface SetShouldConsiderDistanceForSearch {
  type: 'SET_SHOULD_CONSIDER_DISTANCE_FOR_SEARCH'
  payload: LatLng
}

type Action =
  | UpdateOfferingsFilter
  | UpdateActivityAvailabilityFilter
  | UpdateVenueAttributesFilter
  | UpdateSubSportsFilter
  | SetInitialSearchBoundingBox
  | ToggleShowListing
  | ResetFilters
  | SetDiscoverySport
  | SetHoveredVenue
  | ToggleMapView
  | ToggleFilterView
  | SetClickedVenueId
  | UpdateCourtSlotDateFilter
  | UpdateCourtSlotTimeFilter
  | UpdateCourtAreaFilter
  | UpdateVenueCourtSurfaceFilter
  | UpdateVenueAmenitiesFilter
  | SetShouldConsiderDistanceForSearch

export const Context = createContext<undefined | [DiscoverContextData, Dispatch<Action>]>(undefined)

export function useDiscoverState() {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('Discover context is not correctly defined')
  }

  return context[0]
}

export function useDiscoverReducer() {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('Discover state context is not correctly defined')
  }

  return context[1]
}

const discoverStateReducer = (state: DiscoverContextData, action: Action): DiscoverContextData => {
  switch (action.type) {
    case 'UPDATE_OFFERING_FILTER':
      return { ...state, venueOfferingsActiveFilters: action.payload.length ? action.payload : undefined }
    case 'UPDATE_ACTIVITY_AVAILABILITY_FILTER':
      return { ...state, venueActivityAvailabilityActiveFilters: action.payload.length ? action.payload : undefined }
    case 'UPDATE_ATTRIBUTES_FILTERS':
      return { ...state, venueAttributeActiveFilters: action.payload.length ? action.payload : undefined }
    case 'UPDATE_SUB_SPORTS_FILTER':
      return { ...state, subSportsActiveFilters: action.payload.length ? action.payload : undefined }
    case 'SET_INITIAL_SEARCH_BOUNDING_BOX':
      return { ...state, initialSearchBoundingBox: action.payload }
    case 'TOGGLE_SHOW_LISTING':
      return { ...state, showDesktopListing: !state.showDesktopListing }
    case 'SET_DISCOVERY_SPORT':
      return { ...state, sport: action.payload }
    case 'SET_VENUE_HOVERED':
      return { ...state, hoveredVenue: action.payload }
    case 'TOGGLE_MAP_VIEW':
      return { ...state, showMobileMapView: !state.showMobileMapView }
    case 'TOGGLE_FILTER_VIEW':
      return { ...state, showFilterView: !state.showFilterView }
    case 'SET_CLICKED_VENUE_ID':
      return { ...state, clickedVenueId: action.payload }
    case 'UPDATE_COURT_SLOT_DATE_FILTER':
      return { ...state, venueCourtSlotDateActiveFilter: action.payload }
    case 'UPDATE_COURT_SLOT_TIME_FILTER':
      return { ...state, venueCourtSlotTimeActiveFilter: action.payload }
    case 'UPDATE_AMENITIES_FILTER':
      return { ...state, venueAmenitiesActiveFilters: action.payload.length ? action.payload : undefined }
    case 'UPDATE_COURT_AREA_FILTER':
      return { ...state, venueCourtAreasActiveFilters: action.payload.length ? action.payload : undefined }
    case 'UPDATE_COURT_SURFACE_FILTER':
      return { ...state, venueCourtSurfaceActiveFilters: action.payload.length ? action.payload : undefined }
    case 'SET_SHOULD_CONSIDER_DISTANCE_FOR_SEARCH':
      return { ...state, shouldConsiderDistanceForSearch: true, userMapLocation: action.payload }
    case 'RESET_FILTERS':
      return {
        ...state,
        venueOfferingsActiveFilters: undefined,
        venueActivityAvailabilityActiveFilters: undefined,
        venueAttributeActiveFilters: undefined,
        subSportsActiveFilters: undefined,
        venueAmenitiesActiveFilters: undefined,
        venueCourtAreasActiveFilters: undefined,
        venueCourtSurfaceActiveFilters: undefined,
      }
    default:
      throw new Error('Invalid reducer action type')
  }
}

interface Props {
  initialFilters: Partial<DiscoverContextData>
}

export const DiscoverStateProvider = ({ children, initialFilters }: React.PropsWithChildren<Props>) => {
  const initialState: DiscoverContextData = {
    venueOfferingsActiveFilters: initialFilters.venueOfferingsActiveFilters,
    venueActivityAvailabilityActiveFilters: initialFilters.venueActivityAvailabilityActiveFilters,
    venueAttributeActiveFilters: initialFilters.venueAttributeActiveFilters,
    subSportsActiveFilters: initialFilters.subSportsActiveFilters,
    venueAmenitiesActiveFilters: initialFilters.venueAmenitiesActiveFilters,
    venueCourtAreasActiveFilters: initialFilters.venueCourtAreasActiveFilters,
    venueCourtSurfaceActiveFilters: initialFilters.venueCourtSurfaceActiveFilters,
    shouldConsiderDistanceForSearch: initialFilters.shouldConsiderDistanceForSearch,
    venueCourtSlotDateActiveFilter: new Date(),
    venueCourtSlotTimeActiveFilter: null,
    userMapLocation: undefined,
    initialSearchBoundingBox: undefined,
    clickedVenueId: undefined,
    hoveredVenue: undefined,
    showDesktopListing: true,
    showMobileMapView: false,
    showFilterView: false,
  }

  const value = useReducer(discoverStateReducer, initialState)
  return <Context.Provider value={value}>{children}</Context.Provider>
}
