import { useRef, useEffect } from 'react'

const useDebounce = <T extends Array<any>>(fn: (...args: T) => void, delay: number): ((...args: T) => void) => {
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const debouncedFn = (...args: T) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    const newTimerId = setTimeout(() => {
      fn(...args)
    }, delay)
    timerRef.current = newTimerId
  }

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  return debouncedFn
}

export default useDebounce
