import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../../localization/react'
import { Branch as BranchType } from '../../../../../App.types'
import { VENUE_PROFILE_BRANCHES_ID } from '../../../VenueProfile.constants'
import { ScrollAnchor } from '../../../../../components/ScrollAnchor'

import { BranchBadge } from './Branch'
import BranchesModal from './BranchesModal'

interface Props {
  branches: Array<BranchType>
  headerOffset: number
}

const DEFAULT_NUMBER_OF_SHOWN_BRANCHES = 6

const Branches = ({ branches, headerOffset }: Props) => {
  if (branches.length === 0) {
    return null
  }

  const shownBranches = branches.slice(0, DEFAULT_NUMBER_OF_SHOWN_BRANCHES)
  const hasMoreBranches = branches.length > DEFAULT_NUMBER_OF_SHOWN_BRANCHES

  return (
    <Stack gap={3} position="relative">
      <ScrollAnchor hash={VENUE_PROFILE_BRANCHES_ID} topOffset={-headerOffset} />
      <Stack direction="row" alignItems="center" gap={3}>
        <Text color="midnight" sx={{ fontWeight: 'bold' }}>
          <Localized id="branches-section-title" />
        </Text>
        <Text variant="small" sx={{ color: 'darkgray' }}>
          <Localized id="branches-section-subtitle" />
        </Text>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={3}>
        {shownBranches.map((branch) => (
          <BranchBadge branch={branch} key={branch.id} />
        ))}
      </Stack>
      {hasMoreBranches && <BranchesModal branches={branches} />}
    </Stack>
  )
}

export default Branches
