import * as React from 'react'
import { useState } from 'react'
import Accordion, { AccordionSummary } from '@eversports/klimt-primitives/Accordion'

import AccordionContent from './AccordionContent'

interface Props {
  headerNode: React.ReactNode
  contentText: string
  contentColor?: string
  isInitiallyOpen?: boolean
}

const AccordionItem: React.FC<React.PropsWithChildren<Props>> = ({
  headerNode,
  contentText,
  contentColor = '#333',
  isInitiallyOpen = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen)

  const toggleAccordion = () => setIsOpen(!isOpen)

  return (
    <Accordion expanded={isOpen} onChange={toggleAccordion} disableGutters>
      <AccordionSummary>{headerNode}</AccordionSummary>
      <AccordionContent text={contentText} contentColor={contentColor} />
    </Accordion>
  )
}

export default AccordionItem
