import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Alert from '@eversports/klimt-primitives/Alert'

import { Localized } from '../../../../localization/react'
import { SpotLimit } from '../../ActivityDetails.types'

interface Props {
  availableSpots: SpotLimit | null
  isBookable: boolean
}

const UrgencyAlert = ({ availableSpots, isBookable }: Props) => {
  if (!isBookable || !availableSpots) {
    return null
  }

  const hasUnlimitedSpots = availableSpots?.__typename === 'UnlimitedSpots'
  const noSpotsLeft = availableSpots?.__typename === 'LimitedSpots' && availableSpots.limit < 1

  if (hasUnlimitedSpots || availableSpots.displayCapped || noSpotsLeft) {
    return null
  }

  return (
    <Alert
      severity="warning"
      icon={false}
      sx={{
        justifyContent: 'center',
        borderRadius: 0.5,
        width: '100%',
        boxShadow: 'none',
        border: '1px solid rgba(242, 153, 74, 0.2)',
        padding: 0,
        '& .MuiAlert-message': { paddingY: 0.5 },
      }}
    >
      <Text>
        <Localized id="activity-details-urgency-limited-spots" params={{ freeSpots: availableSpots.limit }} />
      </Text>
    </Alert>
  )
}
export default UrgencyAlert
