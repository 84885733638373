import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import { useEffect } from 'react'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import { useDiscoverReducer, useDiscoverState } from '../DiscoverContext'
import { ActivityAvailability } from '../Discover.types'
import { Localized } from '../../../localization/react'
import FilterMenu from '../../../components/FilterMenu'
import FilterCheckbox from '../../../components/FilterCheckbox'
import FilterDrawer from '../../../components/FilterDrawer'

import CollapsibleFilter from './components/CollapsibleFilter'
import { filterHeadingMapping, FilterKey } from './Filters.constants'
import { DiscoverFilterTypes, FilterFormProps } from './Filters.types'
import getVenueFilterAmplitudeEventName from './helpers/get-venue-filter-amplitude-event-name'

const filterHeadingKey = filterHeadingMapping.VENUE_ACTIVITY_AVAILABILITY

interface InitialValues {
  activityAvailability: Array<ActivityAvailability>
}

const VenueActivityAvailabilityMapping = {
  [ActivityAvailability.ACTIVITY_AVAILABILITY_MORNING]: 'filters-activities-availability-morning',
  [ActivityAvailability.ACTIVITY_AVAILABILITY_AFTERNOON]: 'filters-activities-availability-afternoon',
  [ActivityAvailability.ACTIVITY_AVAILABILITY_EVENING]: 'filters-activities-availability-evening',
}

const VenueActivityAvailabilityFilterForm = ({ variant = 'default' }: FilterFormProps) => {
  const dispatch = useDiscoverReducer()
  const { venueActivityAvailabilityActiveFilters } = useDiscoverState()
  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()

  const methods = useForm<InitialValues>({
    defaultValues: { activityAvailability: venueActivityAvailabilityActiveFilters || [] },
  })

  const { isDirty } = methods.formState
  const activityAvailability = methods.watch('activityAvailability')

  // keep different renders of the form in sync with the state
  useEffect(() => {
    methods.reset({ activityAvailability: venueActivityAvailabilityActiveFilters ?? [] })
  }, [venueActivityAvailabilityActiveFilters])

  // update context state
  useEffect(() => {
    if (!isDirty) return
    if (activityAvailability.length) {
      logAmplitudeEventOnce({
        eventName: getVenueFilterAmplitudeEventName(),
        eventProperties: { type: DiscoverFilterTypes.activityAvailability, tab: 'venue' },
      })
    }
    dispatch({ type: 'UPDATE_ACTIVITY_AVAILABILITY_FILTER', payload: activityAvailability })
  }, [activityAvailability])

  return (
    <Form methods={methods}>
      <Stack spacing={variant === 'drawer' ? 0 : 2}>
        {Object.entries(VenueActivityAvailabilityMapping).map(([key, value]) => (
          <FilterCheckbox
            key={key}
            name="activityAvailability"
            value={key}
            label={<Localized id={value as any} />}
            variant={variant}
          />
        ))}
      </Stack>
    </Form>
  )
}

export const DesktopVenueActivityAvailabilityFilter: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { venueActivityAvailabilityActiveFilters } = useDiscoverState()

  const venueActivityAvailabilityActiveFiltersTranslationKeys = venueActivityAvailabilityActiveFilters
    ? venueActivityAvailabilityActiveFilters
        .map((activeFilter) => {
          return VenueActivityAvailabilityMapping[activeFilter]
        })
        .reverse()
    : []

  const numberOfPossibleFilters = Object.keys(VenueActivityAvailabilityMapping).length
  const areAllFiltersSelected = venueActivityAvailabilityActiveFiltersTranslationKeys.length === numberOfPossibleFilters

  return (
    <FilterMenu
      header={<Localized id={filterHeadingKey} />}
      activeFilters={
        areAllFiltersSelected ? ['filters-studio-all-selected'] : venueActivityAvailabilityActiveFiltersTranslationKeys
      }
    >
      <VenueActivityAvailabilityFilterForm />
    </FilterMenu>
  )
}

export const MobileVenueActivityAvailabilityFilter = () => {
  return (
    <CollapsibleFilter header={<Localized id={filterHeadingKey} />}>
      <VenueActivityAvailabilityFilterForm />
    </CollapsibleFilter>
  )
}

export const MobileVenueActivityAvailabilityFilterDrawer = () => {
  const discoverState = useDiscoverState()
  const filterValues = discoverState[FilterKey.VENUE_ACTIVITY_AVAILABILITY]

  const dispatch = useDiscoverReducer()
  const resetFilter = () => {
    dispatch({ type: 'UPDATE_ACTIVITY_AVAILABILITY_FILTER', payload: [] })
  }

  return (
    <FilterDrawer
      header={<Localized id={filterHeadingKey} />}
      selectedFilterValues={filterValues}
      onReset={resetFilter}
    >
      <VenueActivityAvailabilityFilterForm variant="drawer" />
    </FilterDrawer>
  )
}
