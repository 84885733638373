import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import SeoLink from '@eversports/klimt-primitives/SeoLink'

import { Localized } from '../../../../localization/react'

import { City } from './NotFound.types'

interface Props {
  cities: Array<City>
}

const OtherCities = ({ cities }: Props) => (
  <Stack gap={3} maxWidth={{ md: '50vw' }}>
    <Heading is="h2" variant="small" color="midnight">
      <Localized id="discover-no-results-popular-cities-label" />
    </Heading>
    <Stack direction="row" gap={3} flexWrap="wrap">
      {cities.map((city) => {
        return (
          <SeoLink key={city.id} encodedTo={btoa(`/b/${city.slug}`)} type="hidden">
            <Button
              variant="tertiary"
              sx={{ px: 3, py: 2, border: '1px solid', borderColor: 'lightgray', textTransform: 'none' }}
            >
              <Text>{city.name}</Text>
            </Button>
          </SeoLink>
        )
      })}
    </Stack>
  </Stack>
)

export default OtherCities
