import { PurchaseOption } from '../../../ActivityDetails.types'

const getProductPriceValue = (product: PurchaseOption) => {
  switch (product.__typename) {
    case 'Membership':
      return product.paymentPlan.recurring.value
    case 'Ticket':
      return product.standardPrice.value
    case 'Card':
    case 'SinglePurchase':
      return product.price.value
  }
}

const isTrialCard = (product: PurchaseOption) => product.__typename === 'Card' && product.isTrialCard
const isMembership = (product: PurchaseOption) => product.__typename === 'Membership'

const groupProducts = (products: Array<PurchaseOption>) => {
  const trialCards: Array<PurchaseOption> = []
  const memberships: Array<PurchaseOption> = []
  const otherProducts: Array<PurchaseOption> = []

  products.forEach((product) => {
    if (isTrialCard(product)) return trialCards.push(product)
    if (isMembership(product)) return memberships.push(product)
    return otherProducts.push(product)
  })

  return { trialCards, memberships, otherProducts }
}

const sortProductsByCheapest = (productA: PurchaseOption, productB: PurchaseOption) => {
  const productAValue = getProductPriceValue(productA)
  const productBValue = getProductPriceValue(productB)
  return productAValue - productBValue
}

// Order the products from cheapest to most expensive.
// Have the trial products at the beginning, and the memberships at the end.
// Start by grouping the products, then sorting each group.
const groupAndSortPurchaseOptions = (products: Array<PurchaseOption>) => {
  const { trialCards, memberships, otherProducts } = groupProducts(products)

  const sortedTrialCards = trialCards.sort(sortProductsByCheapest)
  const sortedMemberships = memberships.sort(sortProductsByCheapest)
  const sortedOtherProducts = otherProducts.sort(sortProductsByCheapest)

  return [...sortedTrialCards, ...sortedOtherProducts, ...sortedMemberships]
}

export default groupAndSortPurchaseOptions
