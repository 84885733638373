import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

import { Teacher } from '../../App.types'

import RecommendationsHero from './RecommendationsHero'
import { TrialCardsSection } from './TrialCards'
import { TeachersSection } from './Teachers'
import { Venue } from './TrialCards/TrialCards.types'

interface Props {
  venues?: Array<Venue>
  teachers?: Array<Teacher>
  loading: boolean
}

const RecommendationsComponent = ({ venues, teachers, loading }: Props) => {
  return (
    <Stack
      sx={{
        backgroundColor: 'white',
        height: 'auto',
      }}
      component="main"
    >
      <RecommendationsHero />
      <Stack gap={1} sx={{ backgroundColor: 'eggshell' }}>
        <TrialCardsSection venues={venues} loading={loading} />
        <TeachersSection teachers={teachers} loading={loading} />
      </Stack>
    </Stack>
  )
}

export default RecommendationsComponent
