import styled from '@eversports/klimt-design-components/styled'

// max-width of card needs to consider padding from parent
export const RecommendationsSectionWrapper = styled('div')`
  display: grid;
  gap: 16px;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(446px, 1fr));

  @media screen and (max-width: 478px) {
    grid-template-columns: 1fr;
  }
`
