import * as React from 'react'
import { useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'

import { PurchaseOption } from '../../ActivityDetails.types'
import { Localized } from '../../../../localization/react'
import Drawer from '../../components/Drawer'

import PriceCardContent from './PriceCardContent'

const DEFAULT_CARDS_TO_SHOW = 3

interface Props {
  purchaseOptions: Array<PurchaseOption>
  isCorporateUser: boolean
}

const PriceCards = ({ purchaseOptions, isCorporateUser }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const togglePopup = () => {
    setIsModalOpen(!isModalOpen)
  }

  const hasMoreCards = purchaseOptions.length > DEFAULT_CARDS_TO_SHOW
  const shownPurchaseOptions = purchaseOptions.slice(0, DEFAULT_CARDS_TO_SHOW)

  return (
    <>
      <Stack gap={4} alignItems="center">
        <Stack
          sx={{
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: 'mediumgray',
            width: '100%',
            'div:last-child': { borderBottom: 'none' },
          }}
        >
          {shownPurchaseOptions.map((purchaseOption) => (
            <PriceCardContent
              key={purchaseOption.id}
              purchaseOption={purchaseOption}
              isCorporateUser={isCorporateUser}
            />
          ))}
        </Stack>
        {hasMoreCards && (
          <Box onClick={togglePopup}>
            <Text color="midnight" variant="small" sx={{ fontWeight: 'bold' }}>
              <Localized id="activity-detail-products-show-more" />
            </Text>
          </Box>
        )}
      </Stack>
      <Drawer isOpen={isModalOpen} handleClose={togglePopup}>
        <Box sx={{ paddingY: 5, background: 'white' }}>
          <Text variant="large" color="midnight" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            <Localized id="activity-detail-products-heading" />
          </Text>
        </Box>
        <Stack alignItems="center" sx={{ overflow: 'scroll', 'div:last-child': { borderBottom: 'none' } }}>
          {purchaseOptions.map((purchaseOption) => (
            <PriceCardContent
              key={purchaseOption.id}
              purchaseOption={purchaseOption}
              isCorporateUser={isCorporateUser}
            />
          ))}
        </Stack>
      </Drawer>
    </>
  )
}

export default PriceCards
