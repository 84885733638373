import { useEffect } from 'react'

type ClickOutsideHandler = (event: MouseEvent | TouchEvent) => void

interface Props {
  contentRef: React.RefObject<HTMLElement>
  isOpen: boolean
  handleClickOutside: ClickOutsideHandler
  isMobile: boolean
}

function useHandleClickOutsideContent({ contentRef, isOpen, handleClickOutside, isMobile }: Props) {
  useEffect(() => {
    if (!isOpen || isMobile) return

    function handleClick(event: MouseEvent | TouchEvent) {
      if (contentRef.current && !contentRef.current.contains(event.target as Node)) {
        handleClickOutside(event)
      }
    }

    document.addEventListener('mousedown', handleClick)
    document.addEventListener('touchstart', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
      document.removeEventListener('touchstart', handleClick)
    }
  }, [contentRef, handleClickOutside, isOpen])
}

export default useHandleClickOutsideContent
