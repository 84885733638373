import { ApolloClient, ApolloError, ApolloLink, createHttpLink, ServerError } from '@apollo/client'
import { InMemoryCache, NormalizedCacheObject, PossibleTypesMap, TypePolicies } from '@apollo/client/cache'
import { onError, ErrorResponse } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import 'isomorphic-fetch'

export interface CreateApolloOptions {
  typePolicies?: TypePolicies
  possibleTypes?: PossibleTypesMap
  uri: string

  onError?(error: ErrorResponse): void
}

interface Options extends CreateApolloOptions {
  state?: NormalizedCacheObject
  serverOptions?: {
    acceptLanguageHeader?: string
    cookieHeader?: string
  }
}

export default createApolloClient

function createApolloClient({ uri, state, possibleTypes, typePolicies, serverOptions, ...options }: Options) {
  const cache = state
    ? new InMemoryCache({ possibleTypes, typePolicies }).restore(state)
    : new InMemoryCache({ possibleTypes, typePolicies })

  const httpLink = serverOptions
    ? createHttpLink({
        uri,
        credentials: 'include',
        headers: {
          Cookie: serverOptions.cookieHeader!,
          'Accept-Language': serverOptions.acceptLanguageHeader!,
        },
      })
    : createHttpLink({ uri, credentials: 'include' })

  const errorLink = onError((error) => {
    const { graphQLErrors, networkError } = error
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) =>
        // eslint-disable-next-line no-console
        console.log(`[GraphQL error]: Message: ${message ? JSON.stringify(message) : message}`),
      )
    }
    if (networkError) {
      // eslint-disable-next-line no-console
      console.log('[Network error]', networkError)
    }
    if (options.onError) options.onError(error)
  })
  const retryLink = new RetryLink({
    delay: {
      initial: 1000,
    },
    attempts: {
      // only retry 500 errors
      retryIf: (error: unknown) => (error as ServerError)?.response?.status >= 500,
    },
  })

  const isBrowser = (process as unknown as { browser: boolean }).browser

  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser,
    link: ApolloLink.from([retryLink, errorLink, httpLink]),
    cache,
  })
}

export function extractStatusCodeFromError(error: ApolloError): number | undefined {
  const { graphQLErrors } = error
  if (graphQLErrors) {
    return (graphQLErrors[0]?.extensions?.exception as { status?: number } | undefined)?.status
  }
  return undefined
}
