import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import EversportsLogoIcon from '@eversports/design-tokens/assets/logo/icon.svg'

interface Props {
  src?: string
}

const FriendUpcomingParticipationImage = ({ src }: Props) => {
  return (
    <Box
      sx={{
        borderRadius: 2,
        width: 120,
        flexShrink: 0,
        backgroundImage: `url(${src ?? EversportsLogoIcon})`,
        backgroundSize: src ? 'cover' : 'auto',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    />
  )
}

export default FriendUpcomingParticipationImage
