import * as React from 'react'
import { useState } from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import RequestedFriendIcon from '@eversports/design-tokens/assets/icons/requested-friend.svg'
import Processing from '@eversports/klimt-primitives/Processing'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../../../../localization/react'
import { useRequestedFriendshipButtonCancelFriendRequestMutation } from '../../../../../../graphql'
import ConfirmationDisclaimer from '../../../../components/ConfirmationDisclaimer'

interface Props {
  publicUserId?: string
}

const RequestedFriendshipButton = ({ publicUserId }: Props) => {
  const { amplitude } = useAmplitude()
  const [isOpen, setIsOpen] = useState(false)
  const [cancelFriendRequest, { loading }] = useRequestedFriendshipButtonCancelFriendRequestMutation({
    variables: { userId: publicUserId! },
  })

  const toggleDisclaimer = () => {
    setIsOpen(!isOpen)
  }

  const cancelRequest = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Confirm Remove Sent Friend Request')
    }
    toggleDisclaimer()
    void cancelFriendRequest()
  }

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Cancel Friend Request Button')
    }
    toggleDisclaimer()
  }

  const handleDismiss = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Dismiss Remove Sent Friend Request')
    }
    toggleDisclaimer()
  }

  return (
    <>
      <Processing isLoading={loading}>
        <Button
          variant="tertiary"
          sx={{ textTransform: 'none', paddingY: { xs: 3, md: 2 }, paddingX: 6, width: 'fit-content' }}
          onClick={handleClick}
        >
          <Stack alignItems="center" direction="row" gap={2}>
            <Icon size="small" color="midnight" src={RequestedFriendIcon} />
            <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>
              <Localized id="requested-friendship-button-text" />
            </Text>
          </Stack>
        </Button>
      </Processing>
      <ConfirmationDisclaimer
        title={<Localized id="confirmation-disclaimer-requested-friendship-title" />}
        description={<Localized id="confirmation-disclaimer-requested-friendship-description" />}
        confirmationText={<Localized id="confirmation-disclaimer-requested-friendship-cancel-request" />}
        dismissText={<Localized id="confirmation-disclaimer-requested-friendship-dismiss" />}
        onConfirm={cancelRequest}
        onDismiss={handleDismiss}
        onClose={toggleDisclaimer}
        isOpen={isOpen}
      />
    </>
  )
}

export default RequestedFriendshipButton
