import Stack from '@eversports/klimt-primitives/Stack'
import * as React from 'react'

import { Venue } from '../VenueProfile.types'

import ImagesGallery from './ImagesGallery'
import NavigationTabs from './NavigationTabs'

interface Props {
  venue: Venue
}

const Header = ({ venue }: Props) => {
  return (
    <Stack gap={{ xs: 0, sm: 4 }} sx={{ width: '100%' }}>
      <ImagesGallery images={venue.images} />
      <NavigationTabs venueOfferings={venue.offerings} venueSlug={venue.slug} />
    </Stack>
  )
}

export default Header
