import * as React from 'react'
import { useRef, useEffect } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'

interface Props {
  handleClick: () => void | Promise<void>
  isFocused?: boolean
  isLastItem: boolean
}

const ResultItemWrapper = ({ children, handleClick, isFocused, isLastItem }: React.PropsWithChildren<Props>) => {
  const itemRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (itemRef.current && isFocused) {
      itemRef.current.scrollIntoView({ block: 'nearest' })
    }
  }, [isFocused])

  return (
    <Box
      forwardedRef={itemRef}
      onClick={handleClick}
      paddingX={{ xs: 3, lg: 2 }}
      sx={{
        width: '100%',
        backgroundColor: isFocused ? 'lightgray' : 'inherit',
        '&:hover': { backgroundColor: 'lightgray', cursor: 'pointer' },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={4}
        paddingY={2}
        paddingX={{ md: 2 }}
        sx={{
          borderBottom: isLastItem ? 'none' : '1px solid',
          borderBottomColor: 'lightgray',
        }}
      >
        {children}
      </Stack>
    </Box>
  )
}
export default ResultItemWrapper
