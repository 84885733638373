import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'

interface Props {
  position: 'top' | 'bottom'
}

const CommentsAreaDelimiter = ({ position }: Props) => {
  return (
    <Box
      sx={{
        height: '6px',
        background: 'linear-gradient(180deg, rgba(187, 187, 187, 0.4) 0%, rgba(255, 255, 255, 0) 100%)',
        transform: position === 'bottom' ? 'matrix(1, 0, 0, -1, 0, 0)' : 'initial',
        flexShrink: 0,
      }}
    />
  )
}

export default CommentsAreaDelimiter
