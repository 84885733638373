import * as React from 'react'
import { useState, useEffect } from 'react'

interface Props {
  src: string
  autoplay?: boolean
  loop?: boolean
  style?: React.CSSProperties
}

const Player = ({ ...props }: Props) => {
  const [isLottieLoaded, setIsLottieLoaded] = useState(false)

  useEffect(() => {
    void import(
      /* webpackChunkName: "lottie" */
      /* webpackMode: "lazy" */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore This module doesn't have types
      '@dotlottie/player-component'
    ).then((lottie) => {
      if (lottie) {
        setIsLottieLoaded(true)
      }
    })
  }, [])

  return isLottieLoaded ? <dotlottie-player {...props} /> : null
}

export default Player
