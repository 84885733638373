import * as React from 'react'
import { useState } from 'react'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import Popover from '@eversports/klimt-primitives/Popover'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import Drawer from '../../../../../../components/Drawer'

import ProfileVisibilitySettingActionItem from './ProfileVisibilitySettingActionItem'

interface Props {
  isOpen: boolean
  toggleDialog: () => void
  anchorEl: Element | null
}

const SettingsActions = ({ isOpen, anchorEl, toggleDialog }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isVisibilitySettingOpen, setIsVisibilitySettingOpen] = useState(false)

  const toggleVisibilitySetting = () => {
    setIsVisibilitySettingOpen(!isVisibilitySettingOpen)
  }

  return isMobile ? (
    <Drawer
      open={isOpen}
      onClose={toggleDialog}
      sx={{
        display: isVisibilitySettingOpen ? 'none' : 'initial',
      }}
    >
      <ProfileVisibilitySettingActionItem isOpen={isVisibilitySettingOpen} onClick={toggleVisibilitySetting} />
    </Drawer>
  ) : (
    <Popover
      anchorEl={anchorEl}
      open={isOpen}
      onClose={toggleDialog}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiPopover-paper': {
          border: '1px solid',
          borderColor: 'mediumgray',
          boxShadow: boxShadows.hover,
          borderRadius: '0px 8px 8px 8px',
          display: isVisibilitySettingOpen ? 'none' : 'initial',
        },
        top: '8px',
      }}
    >
      <ProfileVisibilitySettingActionItem isOpen={isVisibilitySettingOpen} onClick={toggleVisibilitySetting} />
    </Popover>
  )
}

export default SettingsActions
