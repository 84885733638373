import * as React from 'react'
import NoSsr from '@eversports/klimt-design-components/NoSsr'
import Box from '@eversports/klimt-primitives/Box'
import Link from '@eversports/klimt-primitives/Link'
import Skeleton from '@eversports/klimt-primitives/Skeleton'

import MarkerIcon from '../../../static/marker.webp'

import { getStaticMapSrc } from './helpers/get-static-map-src'
import MapShowGoogleMaps from './MapShowGoogleMaps'
import { getGoogleMapsLink } from './helpers/get-google-maps-link'

const SkeletonMap = () => <Skeleton variant="rectangular" width="100%" height="100%" />

interface FormattedMapImageProps {
  staticMapSrc: string
}

const FormattedMapImage = ({ staticMapSrc }: FormattedMapImageProps) => (
  <Box sx={{ position: 'relative' }}>
    <img src={staticMapSrc} alt="Google maps" width="100%" height="100%" style={{ objectFit: 'cover' }} />
    <img
      src={MarkerIcon}
      loading="lazy"
      width="40px"
      height="50px"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
      }}
    />
  </Box>
)

interface Props {
  latitude: number
  longitude: number
  width: number
  height: number
  zoomLevel?: number
  scale?: number
  showOpenGoogleMaps?: boolean
}

const StaticMap = ({
  latitude,
  longitude,
  width,
  height,
  zoomLevel = 15,
  scale = 2,
  showOpenGoogleMaps = false,
}: Props) => {
  const staticMapSrc = getStaticMapSrc({ latitude, longitude, width, height, zoomLevel, scale })
  const url = getGoogleMapsLink({ variant: 'search', latitude, longitude })

  return (
    <Box
      sx={{
        width,
        maxWidth: '100%',
        borderRadius: 1,
        overflow: 'hidden',
        border: '1px solid',
        borderColor: 'mediumgray',
      }}
    >
      <NoSsr defer fallback={<SkeletonMap />}>
        {showOpenGoogleMaps ? (
          <Link to={url} external blank>
            <Box sx={{ position: 'relative' }}>
              <FormattedMapImage staticMapSrc={staticMapSrc} />
              <Box sx={{ position: 'absolute', bottom: '4px', right: '4px' }}>
                <MapShowGoogleMaps type="display" sizeVariant="small" />
              </Box>
            </Box>
          </Link>
        ) : (
          <FormattedMapImage staticMapSrc={staticMapSrc} />
        )}
      </NoSsr>
    </Box>
  )
}

export default StaticMap
