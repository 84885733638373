import * as React from 'react'
import { useState } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import CorporateBadgeModal from './CorporateBadgeModal'
import CorporateBadgeComponent from './CorporateBadgeComponent'

interface Props {
  canOpenModal?: boolean
  citySlug?: string
}

const CorporateBadge = ({ canOpenModal, citySlug }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()

  const toggleModal = () => {
    logAmplitudeEventOnce({ eventName: 'Clicked Corporate Modal', eventProperties: {} })
    setIsOpen(!isOpen)
  }

  if (canOpenModal && citySlug) {
    return (
      <>
        <Box onClick={toggleModal} sx={{ cursor: 'pointer' }}>
          <CorporateBadgeComponent showInfoIcon />
        </Box>
        <CorporateBadgeModal isOpen={isOpen} toggleModal={toggleModal} citySlug={citySlug} />
      </>
    )
  }

  return <CorporateBadgeComponent />
}

export default CorporateBadge
