import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

import { PaymentServiceTypeToNameMapping, PaymentServiceType } from '../InvoiceHistory.types'
import { Localized } from '../../../localization/react'

interface Props {
  usedPaymentServices: Array<PaymentServiceType>
  invoiceNumber: string
}

const UsedPaymentMethods = ({ usedPaymentServices, invoiceNumber }: Props) => {
  return (
    <Stack gap={1}>
      {usedPaymentServices.map((paymentService) => (
        <Text key={`${invoiceNumber}_${paymentService}`}>
          <Localized id={PaymentServiceTypeToNameMapping[paymentService]} />
        </Text>
      ))}
    </Stack>
  )
}

export default UsedPaymentMethods
