import { VenueProfileTabsPrefix } from '../App.types'

interface Props {
  venueSlug: string
  sportSlug?: string
  selectedDate?: string
  hasAvailableSlots?: boolean
}

const getLinkToSportsFieldsCalendar = ({ venueSlug, sportSlug, selectedDate, hasAvailableSlots }: Props) => {
  if (!hasAvailableSlots) {
    return `${VenueProfileTabsPrefix.OVERVIEW}${venueSlug}`
  }

  if (sportSlug && selectedDate) {
    return `${VenueProfileTabsPrefix.SPORTS_FIELDS}${venueSlug}?sport=${sportSlug}&date=${selectedDate}`
  }

  return `${VenueProfileTabsPrefix.SPORTS_FIELDS}${venueSlug}`
}

export default getLinkToSportsFieldsCalendar
