import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import CheckmarkIcon from '@eversports/design-tokens/assets/icons/checkmark.svg'
import Box from '@eversports/klimt-primitives/Box'
import { useState } from 'react'
import Processing from '@eversports/klimt-primitives/Processing'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../../../../localization/react'
import { ProfilePublicUserDocument, useFriendsButtonEndFriendshipMutation } from '../../../../../../graphql'
import ConfirmationDisclaimer from '../../../../components/ConfirmationDisclaimer'

interface Props {
  publicUserId?: string
}

const FriendsButton = ({ publicUserId }: Props) => {
  const { amplitude } = useAmplitude()
  const [isOpen, setIsOpen] = useState(false)
  const [endFriendship, { loading }] = useFriendsButtonEndFriendshipMutation({
    variables: { userId: publicUserId! },
    refetchQueries: [ProfilePublicUserDocument],
  })

  const toggleDisclaimer = () => {
    setIsOpen(!isOpen)
  }

  const removeFriend = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Confirm Remove Friend Button')
    }
    toggleDisclaimer()
    void endFriendship()
  }

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Remove Friend Button')
    }
    toggleDisclaimer()
  }

  const handleDismiss = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Dismiss Remove Friend Button')
    }
    toggleDisclaimer()
  }

  return (
    <>
      <Processing isLoading={loading}>
        <Box
          sx={{
            paddingY: { xs: 3, md: 2 },
            paddingX: 6,
            width: 'fit-content',
            border: '1px solid',
            borderColor: 'mediumgray',
            cursor: 'pointer',
            borderRadius: 10,
          }}
          onClick={handleClick}
        >
          <Stack alignItems="center" direction="row" gap={2}>
            <Icon size="small" color="carbon" src={CheckmarkIcon} />
            <Text sx={{ color: 'carbon', fontWeight: 'bold' }}>
              <Localized id="friends-button-text" />
            </Text>
          </Stack>
        </Box>
      </Processing>
      <ConfirmationDisclaimer
        title={<Localized id="confirmation-disclaimer-remove-friendship-title" />}
        description={<Localized id="confirmation-disclaimer-remove-friendship-description" />}
        confirmationText={<Localized id="confirmation-disclaimer-remove-friendship" />}
        dismissText={<Localized id="confirmation-disclaimer-remove-friendship-dismiss" />}
        onConfirm={removeFriend}
        onDismiss={handleDismiss}
        onClose={toggleDisclaimer}
        isOpen={isOpen}
      />
    </>
  )
}

export default FriendsButton
