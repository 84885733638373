import * as React from 'react'
import { SharingMessage } from '@eversports/klimt-patterns/SharingActions'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'
import Heading from '@eversports/klimt-primitives/Heading'
import DefaultSharingActions from '@eversports/klimt-patterns/SharingActions/SharingActions'

import { Localized } from '../../../localization/react'

interface Props {
  sharingMessage: SharingMessage
}

const SharingActionsContent = ({ sharingMessage }: Props) => {
  return (
    <Stack gap={4}>
      <Heading is="h4" variant="small">
        <Localized id="share-it" />
      </Heading>
      <Box p={3} sx={{ backgroundColor: 'eggshell', border: '1px dashed', borderColor: 'mediumgray', borderRadius: 1 }}>
        <Text variant="small" sx={{ whiteSpace: 'pre-wrap' }}>
          {sharingMessage.translatedMessage}
        </Text>
      </Box>
      <DefaultSharingActions sharingMessage={sharingMessage} />
    </Stack>
  )
}

export default SharingActionsContent
