import * as React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Stack from '@eversports/klimt-primitives/Stack'

import useGetTopContentHeight from '../../hooks/useGetTopContentHeight'
import { Meta } from '../../App.types'

import { DesktopSidebar } from './Sidebar'
import VenueProfileContentContainer from './VenueProfileContentContainer'
import { Venue } from './VenueProfile.types'
import Routes from './VenueProfile.routes'
import { VenueMatches, VenueOverview, VenueTeam } from './Content'

interface Props {
  venue: Venue
  meta: Meta
}

const VenueProfileContent = ({ venue, meta }: Props) => {
  const { url, path } = useRouteMatch()
  const { DESKTOP_TOP_CONTENT_HEIGHT: TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR', 'PAGE_TITLE'])

  return (
    <Switch>
      <Route exact path={path}>
        <VenueProfileContentContainer variant="with-sidebar">
          <VenueOverview venue={venue} meta={meta} headerOffset={TOP_CONTENT_HEIGHT} />
          <DesktopSidebar venue={venue} headerOffset={TOP_CONTENT_HEIGHT} />
        </VenueProfileContentContainer>
      </Route>
      <Route path={`${url}${Routes.TEAM}`}>
        <VenueProfileContentContainer variant="with-sidebar">
          <VenueTeam venue={venue} meta={meta} />
          <DesktopSidebar venue={venue} headerOffset={TOP_CONTENT_HEIGHT} />
        </VenueProfileContentContainer>
      </Route>
      <Route path={`${url}${Routes.MATCHES}`}>
        <Stack gap={4} sx={{ width: '100%' }}>
          <VenueMatches venue={venue} meta={meta} />
        </Stack>
      </Route>
    </Switch>
  )
}

export default VenueProfileContent
