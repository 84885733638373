import type Rollbar from 'rollbar'

export default class ClientLogger {
  public static log = (...args: Array<Rollbar.LogArgument>) => {
    const rollbar = ClientLogger.rollbar()

    if (rollbar) rollbar.log(...args)
    // eslint-disable-next-line no-console
    else console.log(...args)
  }

  public static debug = (...args: Array<Rollbar.LogArgument>) => {
    const rollbar = ClientLogger.rollbar()

    if (rollbar) rollbar.debug(...args)
    // eslint-disable-next-line no-console
    else console.debug(...args)
  }

  public static info = (...args: Array<Rollbar.LogArgument>) => {
    const rollbar = ClientLogger.rollbar()

    if (rollbar) rollbar.info(...args)
    // eslint-disable-next-line no-console
    else console.info(...args)
  }

  public static warn = (...args: Array<Rollbar.LogArgument>) => {
    const rollbar = ClientLogger.rollbar()

    if (rollbar) rollbar.warn(...args)
    // eslint-disable-next-line no-console
    else console.warn(...args)
  }

  public static warning = (...args: Array<Rollbar.LogArgument>) => {
    const rollbar = ClientLogger.rollbar()

    if (rollbar) rollbar.warning(...args)
    // eslint-disable-next-line no-console
    else console.warn(...args)
  }

  public static error = (...args: Array<Rollbar.LogArgument>) => {
    const rollbar = ClientLogger.rollbar()

    if (rollbar) rollbar.error(...args)
    // eslint-disable-next-line no-console
    else console.error(...args)
  }

  public static critical = (...args: Array<Rollbar.LogArgument>) => {
    const rollbar = ClientLogger.rollbar()

    if (rollbar) rollbar.critical(...args)
    // eslint-disable-next-line no-console
    else console.error(...args)
  }

  private static rollbar = (): Rollbar | undefined =>
    (typeof window !== 'undefined' && (window as unknown as { Rollbar: Rollbar }).Rollbar) || undefined
}
