import { Testimonial } from './Testimonials.types'
import TestimonialAlexisAT from './assets/testimonial-alexis-at.webp'
import TestimonialCamilleNL from './assets/testimonial-camille-nl.webp'
import TestimonialClaudiaNL from './assets/testimonial-claudia-nl.webp'

export const TESTIMONIAL_1: Testimonial = {
  name: 'Claudia',
  countryTLD: 'nl',
  quoteLocalizedId: 'testimonial-claudia-netherlands',
  avatarSrc: TestimonialClaudiaNL,
}

export const TESTIMONIAL_2: Testimonial = {
  name: 'Alexis',
  countryTLD: 'at',
  quoteLocalizedId: 'testimonial-alexis-austria',
  avatarSrc: TestimonialAlexisAT,
}

export const TESTIMONIAL_3: Testimonial = {
  name: 'Camille',
  countryTLD: 'nl',
  quoteLocalizedId: 'testimonial-camille-netherlands',
  avatarSrc: TestimonialCamilleNL,
}
