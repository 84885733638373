import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box from '@eversports/klimt-primitives/Box'

interface Props {
  isSelected?: boolean
}

const ModalOption = ({ children, isSelected }: React.PropsWithChildren<Props>) => {
  return (
    <Box
      sx={{
        pl: 3,
        border: '1px solid',
        borderColor: isSelected ? 'primary.main' : 'mediumgray',
        borderRadius: 1,
        flex: 1,
        boxShadow: boxShadows.default,
        cursor: 'pointer',
        '> label': {
          width: '100%',
        },
        '&:hover': {
          borderColor: 'primary.main',
        },
      }}
    >
      {children}
    </Box>
  )
}

export default ModalOption
