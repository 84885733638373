import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import { useEffect } from 'react'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import { useDiscoverReducer, useDiscoverState } from '../DiscoverContext'
import { Localized } from '../../../localization/react'
import { VenueOfferingFilterType } from '../Discover.types'
import { VenueOfferingType } from '../../../App.types'
import FilterMenu from '../../../components/FilterMenu'
import FilterCheckbox, { CheckboxVariant } from '../../../components/FilterCheckbox'
import FilterDrawer from '../../../components/FilterDrawer'

import CollapsibleFilter from './components/CollapsibleFilter'
import { filterHeadingMapping, FilterKey } from './Filters.constants'
import { DiscoverFilterTypes } from './Filters.types'
import getVenueFilterAmplitudeEventName from './helpers/get-venue-filter-amplitude-event-name'

const filterHeadingKey = filterHeadingMapping.VENUE_OFFERINGS

interface InitialValues {
  venueOfferings: Array<VenueOfferingFilterType>
}

const VenueOfferingsMapping = {
  [VenueOfferingType.VENUE_OFFERING_INDOOR_ACTIVITY]: 'filters-studio-activities-indoor-activity',
  [VenueOfferingType.VENUE_OFFERING_OUTDOOR_ACTIVITY]: 'filters-studio-activities-outdoor-activity',
  [VenueOfferingType.VENUE_OFFERING_LIVESTREAM]: 'filters-studio-activities-livestream',
  [VenueOfferingType.VENUE_OFFERING_VIDEO]: 'filters-studio-activities-video',
}

interface Props {
  variant?: CheckboxVariant
}

const VenueOfferingsFilterForm = ({ variant = 'default' }: Props) => {
  const dispatch = useDiscoverReducer()
  const { venueOfferingsActiveFilters } = useDiscoverState()
  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()

  const methods = useForm<InitialValues>({
    defaultValues: { venueOfferings: [] },
  })

  const { isDirty } = methods.formState
  const venueOfferings = methods.watch('venueOfferings')

  // keep different renders of the form in sync with the state
  useEffect(() => {
    methods.reset({ venueOfferings: venueOfferingsActiveFilters ?? [] })
  }, [venueOfferingsActiveFilters])

  // update context state
  useEffect(() => {
    if (!isDirty) return
    if (venueOfferings.length) {
      logAmplitudeEventOnce({
        eventName: getVenueFilterAmplitudeEventName(),
        eventProperties: { type: DiscoverFilterTypes.offering, tab: 'venue' },
      })
    }
    dispatch({ type: 'UPDATE_OFFERING_FILTER', payload: venueOfferings })
  }, [venueOfferings])

  return (
    <Form methods={methods}>
      <Stack spacing={variant === 'drawer' ? 0 : 2}>
        {Object.entries(VenueOfferingsMapping).map(([key, value]) => (
          <FilterCheckbox
            key={key}
            name="venueOfferings"
            value={key}
            label={<Localized id={value as any} />}
            variant={variant}
          />
        ))}
      </Stack>
    </Form>
  )
}

export const DesktopVenueOfferingsFilter = ({ isDefaultOpen }: { isDefaultOpen: boolean }) => {
  const { venueOfferingsActiveFilters } = useDiscoverState()

  const venueOfferingsActiveFiltersTranslationKeys = venueOfferingsActiveFilters
    ? venueOfferingsActiveFilters
        .map((activeFilter) => {
          return VenueOfferingsMapping[activeFilter]
        })
        .reverse()
    : []

  const numberOfPossibleFilters = Object.keys(VenueOfferingsMapping).length
  const areAllFiltersSelected = venueOfferingsActiveFiltersTranslationKeys.length === numberOfPossibleFilters

  return (
    <FilterMenu
      isDefaultOpen={isDefaultOpen}
      header={<Localized id={filterHeadingKey} />}
      activeFilters={
        !areAllFiltersSelected ? venueOfferingsActiveFiltersTranslationKeys : ['filters-studio-all-selected']
      }
    >
      <VenueOfferingsFilterForm />
    </FilterMenu>
  )
}

export const MobileVenueOfferingsFilter = () => {
  return (
    <CollapsibleFilter header={<Localized id={filterHeadingKey} />}>
      <VenueOfferingsFilterForm />
    </CollapsibleFilter>
  )
}

export const MobileVenueOfferingsFilterDrawer = () => {
  const discoverState = useDiscoverState()
  const filterValues = discoverState[FilterKey.VENUE_OFFERINGS]

  const dispatch = useDiscoverReducer()
  const resetFilter = () => {
    dispatch({ type: 'UPDATE_OFFERING_FILTER', payload: [] })
  }

  return (
    <FilterDrawer
      header={<Localized id={filterHeadingKey} />}
      selectedFilterValues={filterValues}
      onReset={resetFilter}
    >
      <VenueOfferingsFilterForm variant="drawer" />
    </FilterDrawer>
  )
}
