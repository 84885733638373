import * as React from 'react'

import { Localized } from '../localization/react'
import { assertNever } from '../utilities/assert-never'

// NOTE(@rfbotto):
// This will be extended to have more variants
// That's why im already creating the variant instead of using a single prop
export type FormatVariants = 'short'
export type TimeValue = Date | string

interface Props {
  variant: FormatVariants
  value: TimeValue
}

export const FormatTime = ({ variant, value }: Props) => {
  const date = new Date(value)
  const dateString = date.toISOString()

  switch (variant) {
    case 'short':
      return (
        <time dateTime={dateString}>
          <Localized id="short-format-time" params={{ date }} />
        </time>
      )
    default:
      return assertNever(variant)
  }
}

export default FormatTime
