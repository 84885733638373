import * as React from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'

interface Props<TFormValues extends object> extends React.FormHTMLAttributes<HTMLFormElement> {
  methods: UseFormReturn<TFormValues, object>
}

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  methods,
  children,
  ...props
}: Props<TFormValues>) => {
  return (
    <FormProvider {...methods}>
      <form {...props}>{children}</form>
    </FormProvider>
  )
}

export default Form
