import { Image, Meta } from '../App.types'
import { DEFAULT_SOCIAL_SHARING_IMAGE_LINK } from '../App.constants'

interface Args {
  title: string
  url: string
  description?: string | null
  image?: Image | null
}

const getStaticPageMetadata = ({ title, description = null, url, image }: Args): Meta => {
  const imgSrc = (image && image?.large) ?? DEFAULT_SOCIAL_SHARING_IMAGE_LINK
  return {
    title,
    description,
    social: {
      openGraph: {
        type: 'website',
        siteName: 'Eversports',
        title,
        description,
        image: { large: imgSrc },
        url,
      },
      twitter: {
        type: 'website',
        title,
        description,
        image: { large: imgSrc },
      },
    },
  }
}

export default getStaticPageMetadata
