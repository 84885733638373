import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Avatar from '@eversports/klimt-primitives/Avatar'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import SeoLink from '@eversports/klimt-primitives/SeoLink'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import { Localized } from '../../../../localization/react'
import { Trainer } from '../../VenueProfile.types'
import TrainerDefaultImage from '../../../../assets/teacher-default-image.webp'
import TeacherCardContainer from '../../../../components/TeacherCard/TeacherCardContainer'

import { NUMBER_OF_MORE_AVATARS_TO_SHOW } from './Trainers'

const AVATAR_DIMENSION = 80

interface Props {
  trainers: Array<Trainer>
  linkToTeamPage: string
}

const TrainersMoreCard = ({ trainers, linkToTeamPage }: Props) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('lg', 1200))

  const numberOfTrainersToShow = isSmallScreen ? 1 : NUMBER_OF_MORE_AVATARS_TO_SHOW

  const shownTrainers = trainers.slice(0, numberOfTrainersToShow)
  const hasMoreTrainers = trainers.length > numberOfTrainersToShow

  return (
    <SeoLink type="hidden" encodedTo={btoa(linkToTeamPage)}>
      <TeacherCardContainer isClickable>
        <Stack direction="row" justifyContent="center" alignItems="center" gap={2} height="100%">
          {shownTrainers.map((trainer, idx) => (
            <Avatar
              key={idx}
              src={trainer.image?.xSmall ?? TrainerDefaultImage}
              sx={{
                height: `${AVATAR_DIMENSION}px`,
                width: `${AVATAR_DIMENSION}px`,
                border: '1px solid white',
                filter: `drop-shadow(${boxShadows.default})`,
                borderRadius: 10,
                ml: idx === 0 ? 0 : -10,
              }}
            />
          ))}
          {hasMoreTrainers && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: `${AVATAR_DIMENSION}px`,
                height: `${AVATAR_DIMENSION}px`,
                borderRadius: 10,
                boxShadow: boxShadows.default,
                ml: -10,
                backgroundColor: 'white',
                zIndex: 1,
              }}
            >
              <Text sx={{ fontWeight: 'bold', textTransform: 'none' }}>
                <Localized id="venue-profile-trainers-has-more" />
              </Text>
            </Box>
          )}
        </Stack>
      </TeacherCardContainer>
    </SeoLink>
  )
}

export default TrainersMoreCard
