import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import EversportsWhiteLogoIcon from '@eversports/design-tokens/assets/logo/icon-white.svg'

import { Image } from '../../../../App.types'

import {
  DESKTOP_VENUE_IMAGE_HEIGHT,
  DESKTOP_VENUE_IMAGE_WIDTH,
  MAP_VENUE_IMAGE_HEIGHT,
  MAP_VENUE_IMAGE_WIDTH,
  MOBILE_VENUE_IMAGE_HEIGHT,
  MOBILE_VENUE_IMAGE_WIDTH,
} from './ListingResult.constants'

interface Props {
  venueProfileImage: Image
  venueName: string
  loading: 'lazy' | 'eager'
}

export const VenueImage = ({ venueProfileImage, venueName, loading }: Props) => {
  return (
    <>
      <Box
        sx={{
          minHeight: MOBILE_VENUE_IMAGE_HEIGHT,
          minWidth: MOBILE_VENUE_IMAGE_WIDTH,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          position: 'relative',
          overflow: 'hidden',
          display: { sm: 'none' },
        }}
      >
        <img
          src={venueProfileImage.medium}
          alt={venueName}
          loading={loading}
          height={MOBILE_VENUE_IMAGE_HEIGHT}
          width={MOBILE_VENUE_IMAGE_WIDTH}
          style={{
            objectFit: 'cover',
            position: 'absolute',
            minWidth: '100%',
            minHeight: '100%',
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        />
      </Box>
      <Box
        sx={{
          minHeight: DESKTOP_VENUE_IMAGE_HEIGHT,
          minWidth: DESKTOP_VENUE_IMAGE_WIDTH,
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
          position: 'relative',
          overflow: 'hidden',
          display: { xs: 'none', sm: 'block' },
        }}
      >
        <img
          src={venueProfileImage.small}
          alt={venueName}
          loading={loading}
          height={DESKTOP_VENUE_IMAGE_HEIGHT}
          width={DESKTOP_VENUE_IMAGE_WIDTH}
          style={{
            objectFit: 'cover',
            position: 'absolute',
            minWidth: '100%',
            minHeight: '100%',
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        />
      </Box>
    </>
  )
}

export const MapVenueImage = ({ venueProfileImage, venueName }: Props) => {
  return (
    <img
      src={venueProfileImage.small}
      alt={venueName}
      loading="lazy"
      height={MAP_VENUE_IMAGE_HEIGHT}
      width={MAP_VENUE_IMAGE_WIDTH}
      style={{
        objectFit: 'cover',
        borderRadius: '8px',
      }}
    />
  )
}

export const NoProfileImage = () => (
  <Box
    sx={{
      backgroundColor: 'lightgray',
      minHeight: { xs: MOBILE_VENUE_IMAGE_HEIGHT, sm: DESKTOP_VENUE_IMAGE_HEIGHT },
      minWidth: { xs: MOBILE_VENUE_IMAGE_WIDTH, sm: DESKTOP_VENUE_IMAGE_WIDTH },
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      position: 'relative',
      overflow: 'hidden',
    }}
  >
    <Stack justifyContent="center" alignItems="center" height="100%">
      <img src={EversportsWhiteLogoIcon} alt="Eversports Icon" width="64px" loading="lazy" />
    </Stack>
  </Box>
)

export const MapNoProfileImage = () => (
  <Box
    sx={{
      backgroundColor: 'lightgray',
      height: MAP_VENUE_IMAGE_HEIGHT,
      width: MAP_VENUE_IMAGE_WIDTH,
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <img src={EversportsWhiteLogoIcon} alt="Eversports Icon" loading="lazy" width="32px" />
  </Box>
)
