import * as React from 'react'

import { Action } from '../../helpers/matches-filters-reducer'
import { MatchSport } from '../../../../../../App.types'

import MobileSportsFilter from './MobileSportsFilter'
import DesktopSportsFilter from './DesktopSportsFilter'

interface Props {
  sports?: Array<MatchSport>
  dispatch: React.Dispatch<Action>
  isMobile?: boolean
  selectedSports?: Array<string>
}

const SportsFilter = ({ sports, dispatch, selectedSports, isMobile }: Props) => {
  if (!sports) return null
  return isMobile ? (
    <MobileSportsFilter dispatch={dispatch} sports={sports} selectedSports={selectedSports} />
  ) : (
    <DesktopSportsFilter dispatch={dispatch} sports={sports} selectedSports={selectedSports} />
  )
}

export default SportsFilter
