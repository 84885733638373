import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'

import { Localized } from '../../../../../localization/react'
import { FavoriteVenue, FavoriteVenueConnection, UserType } from '../../../PublicUserProfile.types'
import PrivacySettingButton from '../../../components/PrivacySettingButton'
import useLoggedInUserType from '../../../hooks/use-logged-in-user-type'
import PrivateInformation from '../../../components/PrivateInformation'
import { PrivacySettingField } from '../../../../../App.types'

import FavouriteVenuesIcon from './assets/favourite-venues-icon.webp'
import FavouriteVenue from './FavouriteVenue'
import NoFavouriteVenues from './NoFavouriteVenues'
import ShowAllFavouriteVenues from './ShowAllFavouriteVenues'

interface Props {
  venues: FavoriteVenueConnection | null
}

const NUMBER_OF_FAVOURITE_VENUES_TO_SHOW = 3

const FavouriteVenuesContent = ({ venues }: { venues: Array<FavoriteVenue> }) => {
  return venues.length ? (
    <>
      {venues.map((favouriteVenue) => (
        <FavouriteVenue key={favouriteVenue.id} venue={favouriteVenue} />
      ))}
    </>
  ) : (
    <NoFavouriteVenues />
  )
}

const FavouriteVenues = ({ venues }: Props) => {
  const { userType } = useLoggedInUserType()
  const favouriteVenuesToShow = venues?.edges
    ?.slice(0, NUMBER_OF_FAVOURITE_VENUES_TO_SHOW)
    .map((edge) => edge.node.venue)

  return (
    <Stack gap={3} paddingX={{ xs: 4, lg: 0 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={3}>
        <Stack direction="row" alignItems="center" gap={2}>
          <img src={FavouriteVenuesIcon} width="17px" height="16px" />
          <Heading
            is="h3"
            sx={{ color: 'midnight', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
          >
            <Localized id="user-profile-favourite-venues-title" />
          </Heading>
        </Stack>
        {userType === UserType.PROFILE_OWNER && <PrivacySettingButton field={PrivacySettingField.FAVORITE_VENUES} />}
      </Stack>
      {favouriteVenuesToShow ? (
        <FavouriteVenuesContent venues={favouriteVenuesToShow} />
      ) : (
        <PrivateInformation field={PrivacySettingField.FAVORITE_VENUES} />
      )}
      {venues?.edges && venues.edges.length > NUMBER_OF_FAVOURITE_VENUES_TO_SHOW && <ShowAllFavouriteVenues />}
    </Stack>
  )
}

export default FavouriteVenues
