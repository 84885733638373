import * as React from 'react'
import { useEffect } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Divider from '@eversports/klimt-primitives/Divider'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import { Meta, OnlineVenue, ShowType, Venue } from '../../../../App.types'
import { SeoContent, SportWithParents } from '../../Discover.types'
import ListingWrapperWithAnimation from '../../Listing/ListingWrapperWithAnimation'
import Listing from '../../Listing'
import MapWrapperWithAnimation from '../../Map/MapWrapperWithAnimation'
import { useDiscoverReducer, useDiscoverState } from '../../DiscoverContext'
import Map from '../../Map'
import DiscoverViewToggle from '../../DiscoverViewToggle'
import DiscoverCityFilters from '../../Filters/DiscoverCityFilters'
import SportPills from '../../SportPills'
import useGetTopContentHeight from '../../../../hooks/useGetTopContentHeight'
import MetaHelmet from '../../../../components/MetaHelmet'
import useUrlParams from '../../../../hooks/use-url-params'

interface Props {
  meta: Meta
  onlineVenues?: Array<OnlineVenue>
  listingResults?: Array<Venue>
  content: SeoContent
  bookableSports: Array<SportWithParents>
  citySlug: string
}

const DiscoverCityComponent = ({ listingResults, onlineVenues, content, bookableSports, meta, citySlug }: Props) => {
  const { origin } = useSettingsContext()
  const { showDesktopListing } = useDiscoverState()
  const dispatch = useDiscoverReducer()

  const { show } = useUrlParams()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const { DESKTOP_TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])

  const canonicalHref = citySlug ? new URL(`/b/${citySlug}`, origin).href : undefined

  useEffect(() => {
    if (show === ShowType.MAP) {
      dispatch({ type: 'TOGGLE_MAP_VIEW' })
    }
  }, [])

  return (
    <>
      <MetaHelmet meta={meta} canonicalHref={canonicalHref} noIndex />
      <Stack
        sx={{
          backgroundColor: 'white',
          height: '100%',
        }}
        component="main"
      >
        <Stack
          sx={{
            position: { xs: 'sticky' },
            top: { xs: '0', lg: DESKTOP_TOP_CONTENT_HEIGHT },
            zIndex: 6,
            boxShadow: boxShadows.default,
            backgroundColor: 'white',
          }}
        >
          <SportPills sports={bookableSports} />
          <DiscoverCityFilters />
        </Stack>
        <Stack direction="row" position="relative">
          <ListingWrapperWithAnimation isListingOpen={showDesktopListing}>
            <Listing listingResults={listingResults} onlineVenues={onlineVenues} heading={content.heading} />
          </ListingWrapperWithAnimation>
          <MapWrapperWithAnimation isListingOpen={showDesktopListing}>
            <Map venues={listingResults} />
          </MapWrapperWithAnimation>
        </Stack>
        {isMobile && <DiscoverViewToggle />}
      </Stack>
      <Divider sx={{ border: '1px solid', borderColor: 'mediumgray' }} />
    </>
  )
}

export default DiscoverCityComponent
