import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../localization/react'
import NotFoundImage from '../../../static/not-found.svg'

const NotFoundMatch = () => {
  return (
    <Stack justifyContent="center" alignItems="center" flex={1}>
      <img src={NotFoundImage} alt="Not Found Image" style={{ maxWidth: '750px' }} />
      <Link to="/">
        <Button variant="primary" sx={{ px: 2, py: 1 }}>
          <Text variant="badge" sx={{ color: 'white' }}>
            <Localized id="match-not-found-call-to-action" />
          </Text>
        </Button>
      </Link>
    </Stack>
  )
}

export default NotFoundMatch
