import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

interface Props {
  onClick?: () => void
  disableGutters?: boolean
}

const SharingActionBox = React.forwardRef<HTMLElement, React.PropsWithChildren<Props>>(function InnerComponent(
  { children, onClick, disableGutters },
  ref,
) {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        border: '0.5px solid',
        borderColor: 'lightgray',
        boxShadow: boxShadows.default,
        borderRadius: 1,
        p: disableGutters ? 0 : 3,
        '&:hover': {
          boxShadow: '0px 4px 4px rgb(20 20 20 / 20%)',
        },
      }}
      onClick={onClick}
      forwardedRef={ref}
    >
      {children}
    </Box>
  )
})

export default SharingActionBox
