import * as React from 'react'
import { useEffect, useState } from 'react'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import Stack from '@eversports/klimt-primitives/Stack'
import TextField from '@eversports/klimt-primitives/TextField'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import FilterCheckbox, { CheckboxVariant } from '../../../../../../components/FilterCheckbox'
import { Localized } from '../../../../../../localization/react'
import { Action } from '../../helpers/discover-sport-matches-reducer'
import { Sport } from '../../../../../../App.types'
import { AMPLITUDE_DISCOVER_SPORT_CITY_FILTER_EVENT } from '../../../../Discover.constants'
import { DiscoverFilterTypes } from '../../../../Filters/Filters.types'

interface Props {
  dispatch: React.Dispatch<Action>
  sports: Array<Sport>
  variant?: CheckboxVariant
  selectedSports?: Array<string>
}

interface InitialValues {
  sports?: Array<string>
}

const SportsFilterForm = ({ dispatch, sports, selectedSports, variant = 'default' }: Props) => {
  const { amplitude } = useAmplitude()
  const [shownSports, setShownSports] = useState(sports)

  const methods = useForm<InitialValues>({ defaultValues: { sports: [] } })
  const newlySelectedSports = methods.watch('sports')
  const { isDirty } = methods.formState

  useEffect(() => {
    methods.reset({ sports: selectedSports ?? [] })
  }, [selectedSports])

  useEffect(() => {
    if (!isDirty) return
    dispatch({ type: 'UPDATE_DISCOVER_MATCHES_SPORTS_FILTER', payload: newlySelectedSports })
    if (amplitude) {
      amplitude.logEvent(AMPLITUDE_DISCOVER_SPORT_CITY_FILTER_EVENT, { type: DiscoverFilterTypes.sport, tab: 'match' })
    }
  }, [newlySelectedSports])

  const handleSearch = (value: string) => {
    setShownSports(sports.filter((sport) => sport.name.toLowerCase().includes(value.toLowerCase())))
  }

  return (
    <Stack spacing={3}>
      <TextField
        label={<Localized id="filters-sub-sports-search-label" />}
        onChange={({ target }) => handleSearch(target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: 10,
          },
          marginX: { xs: 3, lg: 0 },
        }}
      />
      <Form methods={methods}>
        <Stack spacing={2}>
          {shownSports.map((sport) => (
            <FilterCheckbox key={sport.id} value={sport.id} label={sport.name} name="sports" variant={variant} />
          ))}
        </Stack>
      </Form>
    </Stack>
  )
}

export default SportsFilterForm
