import * as React from 'react'
import Drawer from '@eversports/klimt-primitives/Drawer'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const PickerDrawer = ({ isOpen, onClose, children }: React.PropsWithChildren<Props>) => {
  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          border: '1px solid',
          borderColor: 'lightgray',
          borderRadius: '16px 16px 0px 0px',
        },
      }}
    >
      {children}
    </Drawer>
  )
}

export default PickerDrawer
