import { assertNever } from '@eversports/klimt-utilities/assert-never'

import { MatchMissingField, MatchVisibility } from '../../Match.types'
import { Validations } from '../DetailsSection.types'

interface Args {
  desiredVisibility: MatchVisibility
  validations: Array<Validations>
}

const getRequiredFieldsForDesiredVisibility = ({ desiredVisibility, validations }: Args): Array<MatchMissingField> => {
  const publicMatchValidations = validations.find(
    (validation) => validation.__typename === 'PublicMatchVisibilityValidation',
  )

  const privateMatchValidations = validations.find(
    (validation) => validation.__typename === 'PrivateMatchVisibilityValidation',
  )

  switch (desiredVisibility) {
    case MatchVisibility.PUBLIC:
      return publicMatchValidations!.requiredFields
    case MatchVisibility.PRIVATE:
      return privateMatchValidations!.requiredFields
    default:
      return assertNever(desiredVisibility)
  }
}

export default getRequiredFieldsForDesiredVisibility
