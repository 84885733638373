import { DO_NOT_TRACK_PROPERTY_KEY, DO_NOT_TRACK_ADVERTISING_PROPERTY_KEY } from '../App.constants'
import { TrackingType } from '../App.types'

const TRACKING_PROPERTIES = {
  [TrackingType.ANALYTICS]: DO_NOT_TRACK_PROPERTY_KEY,
  [TrackingType.ADVERTISING]: DO_NOT_TRACK_ADVERTISING_PROPERTY_KEY,
}

function setUserTrackingPreferences(updatedPreferences: { [key in TrackingType]: boolean }) {
  const updatedPreferenceTypes = Object.keys(updatedPreferences) as Array<keyof typeof TrackingType>

  updatedPreferenceTypes.forEach((type) => {
    const updatedTrackingValue = updatedPreferences[type] ? '0' : '1'
    window.localStorage.setItem(TRACKING_PROPERTIES[type], updatedTrackingValue)
  })
}

export default setUserTrackingPreferences
