import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../../localization/react'
import { PROMOTED_CITY_SLUGS } from '../Home.constants'
import SectionWrapper from '../components/SectionWrapper'

import CitySportsCard from './components/CitySportsCard'

const CitySportSeoLinks = () => {
  const { tld } = useSettingsContext()
  const citySlugs = PROMOTED_CITY_SLUGS[tld]

  // This is a possible condition.
  // For .es and .it we don't have enough cities + sports that navigate to valid pages.
  if (!citySlugs.length) {
    return null
  }

  return (
    <SectionWrapper sx={{ marginBottom: 15, contentVisibility: 'auto' }}>
      <Heading is="h3" variant="small" color="midnight" sx={{ marginBottom: 1 }}>
        <Localized id="home-city-sport-seo-links-header" />
      </Heading>
      <Text variant="large" sx={{ marginBottom: 4 }}>
        <Localized id="home-city-sport-seo-links-description" />
      </Text>
      <Stack
        component="ul"
        sx={{
          flexDirection: 'row',
          gap: 4,
          overflowX: 'auto',
          scrollSnapType: 'x',
          scrollBehavior: 'smooth',
          padding: 0,
        }}
      >
        {citySlugs.map((citySlug) => (
          <CitySportsCard key={`city-seo-links-${citySlug}`} citySlug={citySlug} />
        ))}
      </Stack>
    </SectionWrapper>
  )
}

export default CitySportSeoLinks
