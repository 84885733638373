import * as React from 'react'
import { useState } from 'react'
import Text from '@eversports/klimt-primitives/Text'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { useParams } from 'react-router-dom'

import ShareIcon from '../assets/share.svg'
import SharingActions from '../components/SharingActions'
import { Localized } from '../../../localization/react'
import useMatchStatus from '../hooks/use-match-status'
import { MatchStatus } from '../Match.types'
import useLoggedInUserType from '../hooks/use-logged-in-user-type'
import { userTypeMapping } from '../Match.constants'

import { ActionWrapperVariant } from './AdditionalActions.types'
import ActionWrapper from './ActionWrapper'

interface Props {
  variant?: ActionWrapperVariant
}

const ShareMatch = ({ variant }: Props) => {
  const matchStatus = useMatchStatus()
  const { amplitude } = useAmplitude()
  const { userType } = useLoggedInUserType()
  const { id } = useParams<{ id: string }>()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen)
  }

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Match Share Button', {
        userType: userTypeMapping[userType],
      })
    }
    setIsDialogOpen(true)
  }

  const isDisabled = matchStatus !== MatchStatus.UPCOMING

  return (
    <>
      <ActionWrapper onClick={handleClick} isDisabled={isDisabled} variant={variant}>
        <img src={ShareIcon} alt="Share Match" loading="lazy" width="14px" height="14px" />
        <Text>
          <Localized id="share-match" />
        </Text>
      </ActionWrapper>
      <SharingActions isOpen={isDialogOpen} toggleDialog={toggleDialog} matchId={id} />
    </>
  )
}

export default ShareMatch
