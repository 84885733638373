import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import MonetaryValue from '@eversports/klimt-localization/MonetaryValue'

import CashIcon from '../../assets/cash.svg'
import { MonetaryValue as MonetaryValueType } from '../../../../App.types'
import { Localized } from '../../../../localization/react'

interface Props {
  price: MonetaryValueType
  maxParticipants: number | null
}

const CourtPrice = ({ price, maxParticipants }: Props) => {
  const pricePerParticipant = maxParticipants ? price.value / maxParticipants : null

  return (
    <Stack alignItems={{ xs: 'center', sm: 'initial' }} flex={{ xs: 1, sm: 'initial' }} gap={1}>
      <img src={CashIcon} width="16px" height="12px" alt="Court" />
      <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>
        <MonetaryValue value={price.value} currencyCode={price.currency} />
      </Text>
      <Text variant="xsmall">
        {pricePerParticipant ? (
          <Localized
            id="price-per-player"
            params={{
              price: <MonetaryValue value={pricePerParticipant} currencyCode={price.currency} />,
              maxParticipants,
            }}
          />
        ) : (
          <Localized id="overall-price" />
        )}
      </Text>
    </Stack>
  )
}

export default CourtPrice
