import Box from '@eversports/klimt-primitives/Box'
import * as React from 'react'
import { AnimatePresence, motion } from '@eversports/klimt-utilities/framer-motion'

import { useDiscoverReducer, useDiscoverState } from '../DiscoverContext'
import { SvgIconBadge } from '../Listing/Badge'
import { TRANSITION_FOR_MAP_ANIMATION } from '../../../App.constants'
import { Venue } from '../../../App.types'
import MapWrapper from '../../../components/Map/MapWrapper'
import MapListingResultWithSlots from '../Listing/MapListingResult/MapListingResultWithSlots'
import MapListingResult from '../Listing/MapListingResult/MapListingResult'
import { isSportABallsport } from '../../../helpers/is-sport-a-ballsport'

import DblChevronLeft from './assets/Dbl_Chevron_Left.svg'
import DiscoverMap from './DiscoverMap'

interface Props {
  venues?: Array<Venue>
}

const Map = ({ venues }: Props) => {
  const dispatch = useDiscoverReducer()
  const { initialSearchBoundingBox, showDesktopListing, clickedVenueId, sport } = useDiscoverState()

  const setClickedVenueId = (venueId: string | undefined) => {
    dispatch({ type: 'SET_CLICKED_VENUE_ID', payload: venueId })
  }

  return (
    <>
      <Box
        sx={{
          background: 'linear-gradient(90deg, #C4C4C4 0%, rgba(0, 0, 0, 0.12) 0.01%, rgba(0, 0, 0, 0) 100%)',
          width: '12px',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 4,
        }}
      />
      <MapWrapper>
        <DiscoverMap
          initialSearchBoundingBox={initialSearchBoundingBox!}
          setClickedVenueId={setClickedVenueId}
          venues={venues}
        />
        <AnimatePresence>
          {clickedVenueId &&
            (sport && isSportABallsport(sport) ? (
              <MapListingResultWithSlots venueId={clickedVenueId} sport={sport} />
            ) : (
              <MapListingResult venueId={clickedVenueId} />
            ))}
        </AnimatePresence>
      </MapWrapper>
      <Box sx={{ position: 'absolute', top: '16px', left: '16px', display: { xs: 'none', lg: 'block' } }}>
        <motion.div
          animate={{
            rotate: showDesktopListing ? 0 : 180,
          }}
          transition={TRANSITION_FOR_MAP_ANIMATION}
        >
          <SvgIconBadge onClick={() => dispatch({ type: 'TOGGLE_SHOW_LISTING' })}>
            <img
              src={DblChevronLeft}
              alt={showDesktopListing ? 'Close Listings' : 'Open Listings'}
              width="18px"
              height="13px"
              loading="lazy"
            />
          </SvgIconBadge>
        </motion.div>
      </Box>
    </>
  )
}

export default Map
