import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'

import { CorporateBenefits } from '../../../App.types'
import { UserProfileRoutes } from '../UserMenu/UserMenu.constants'
import CorporatePlaceholderLogo from '../assets/corporate-placeholder.webp'
import { ColorContrast } from '../Header.types'

import CorporateBenefitLabel from './CorporateBenefitsLabel'

interface Props {
  corporateBenefits?: CorporateBenefits | null
  colorContrast?: ColorContrast
}

const CorporateBenefits = ({ corporateBenefits, colorContrast = ColorContrast.NORMAL }: Props) => {
  if (!corporateBenefits) return null

  const { corporatePartner, availableBudget, hasSpecialPriceAccess } = corporateBenefits

  if (!availableBudget && !hasSpecialPriceAccess) return null

  return (
    <Link to={UserProfileRoutes.MY_CORPORATE_BENEFITS} external ariaLabel="User corporate benefits">
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        sx={{
          px: 0,
          py: { xs: 4, lg: 0 },
          flexShrink: 0,
          '&:hover': { backgroundColor: { xs: 'lightgray', lg: 'transparent' } },
        }}
      >
        <Box
          sx={{
            borderRadius: 10,
            border: '1px solid',
            borderColor: 'lightgray',
            overflow: 'hidden',
          }}
        >
          <Box
            component="img"
            src={corporatePartner.logo?.xSmall ?? CorporatePlaceholderLogo}
            alt={`${corporatePartner.name} logo`}
            loading="lazy"
            width={{ xs: '16px', lg: '20px' }}
            height={{ xs: '16px', lg: '20px' }}
          />
        </Box>
        <CorporateBenefitLabel
          availableBudget={availableBudget}
          corporatePartnerName={corporatePartner.name}
          colorContrast={colorContrast}
        />
      </Stack>
    </Link>
  )
}

export default CorporateBenefits
