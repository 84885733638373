import Heading from '@eversports/klimt-primitives/Heading'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import * as React from 'react'

import { Localized } from '../../../localization/react'

interface Props {
  sportName: string
  isMobile: boolean
}

const OverviewBannerSectionMessage = ({ sportName, isMobile }: Props) => {
  return (
    <Heading is="h1" variant={isMobile ? 'regular' : 'large'} sx={{ color: 'white', textShadow: boxShadows.default }}>
      <Localized id="match-overview-banner-title" params={{ sportName }} />
    </Heading>
  )
}

export default OverviewBannerSectionMessage
