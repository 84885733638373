import * as React from 'react'
import { useParams } from 'react-router-dom'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'

import { useMatchVisibilityBadgeMatchQuery } from '../../../../../graphql'
import { visibilityTypeTranslation } from '../MatchVisibility.types'
import { Localized } from '../../../../../localization/react'
import { UserType } from '../../../Match.types'
import useLoggedInUserType from '../../../hooks/use-logged-in-user-type'

const MatchVisibilityBadge = () => {
  const { id } = useParams<{ id: string }>()
  const { userType } = useLoggedInUserType()
  const { data } = useMatchVisibilityBadgeMatchQuery({ variables: { matchId: id } })

  if (!data || userType === UserType.ORGANIZER) return null

  return (
    <Box sx={{ backgroundColor: 'rgba(33, 166, 150, 0.08)', borderRadius: 1 }} px={[1, 4]} py={[1, 2]}>
      <Text variant="xsmall" sx={{ color: 'primary.main' }}>
        <Localized id={visibilityTypeTranslation[data.match.visibility]} />
      </Text>
    </Box>
  )
}

export default MatchVisibilityBadge
