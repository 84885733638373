import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import SportTags from '../../../components/SportTags'
import TeacherAvatar from '../../../components/TeacherCard/TeacherAvatar'

import { Props } from './TeacherCard'

const TeacherCardInfo = ({ teacher, venue }: Props) => {
  const teacherName = `${teacher?.firstName ?? ''} ${teacher?.lastName ?? ''}`
  return (
    <Stack px={4} gap={4}>
      <Stack direction="row" alignItems="center" gap={2}>
        <TeacherAvatar imgSrc={teacher.image?.xSmall} />
        <Stack gap={1}>
          <Text variant="label">{teacher.nickname ?? teacherName}</Text>
          <Text variant="small" sx={{ color: 'darkgray' }}>
            {venue?.name}
          </Text>
        </Stack>
      </Stack>
      <SportTags sports={teacher.sports} />
    </Stack>
  )
}

export default TeacherCardInfo
