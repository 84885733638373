import * as React from 'react'
import { useEffect, useReducer, useState } from 'react'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Button from '@eversports/klimt-primitives/Button'
import Box from '@eversports/klimt-primitives/Box'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import { useParams } from 'react-router-dom'
import Processing from '@eversports/klimt-primitives/Processing'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../../../localization/react'
import matchSettingsReducer from '../helpers/match-settings-reducer'
import { useMatchSettingsModalMatchQuery, useMatchSettingsModalUpdateMatchMutation } from '../../../../../graphql'
import { MatchMissingField, MatchVisibility } from '../../../Match.types'
import checkDesiredMatchVisibilityValidity from '../../helpers/check-desired-match-visibility-validity'
import getRequiredFieldsForDesiredVisibility from '../../helpers/get-required-fields-for-desired-visibility'
import { MatchSettingsState } from '../MatchSettings.types'
import { MIN_NUMBER_OF_PLAYERS } from '../MatchSettings.constants'
import PublicMatchCreatedDisclaimer from '../../PublicMatchCreatedDisclaimer'

import MaxNumberOfPlayers from './MaxNumberOfPlayers'
import RequiredLevel from './RequiredLevel'
import CompetitionType from './CompetitionType'
import PlayerGender from './PlayerGender'

interface Props {
  isOpen: boolean
  onClose: () => void
  desiredVisibility: MatchVisibility
}

const MatchSettingsModal = ({ isOpen, onClose, desiredVisibility }: Props) => {
  const theme = useTheme()
  const { id } = useParams<{ id: string }>()
  const { amplitude } = useAmplitude()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isDesiredMatchVisibilityValid, setIsDesiredMatchVisibilityValid] = useState(false)
  const [publicMatchWasCreated, setPublicMatchWasCreated] = useState(false)

  const { data } = useMatchSettingsModalMatchQuery({ variables: { matchId: id } })
  const [updateMatch, { loading }] = useMatchSettingsModalUpdateMatchMutation({
    onCompleted: () => {
      onClose()
      setPublicMatchWasCreated(desiredVisibility === MatchVisibility.PUBLIC)
    },
  })

  const initialState: MatchSettingsState = {
    level: data?.match.level,
    competitionType: data?.match.competitionType,
    gender: data?.match.gender,
    maxParticipants: data?.match.maxParticipants,
    visibility: data?.match.visibility || MatchVisibility.PRIVATE,
  }

  const [state, dispatch] = useReducer(matchSettingsReducer, initialState)

  const handleSubmit = () => {
    const { gender, competitionType, maxParticipants, level } = state
    void updateMatch({
      variables: {
        matchId: id,
        fields: { gender, competitionType, maxParticipants, level, visibility: desiredVisibility },
      },
    })
    if (amplitude) {
      amplitude.logEvent('Updated Match Settings', {
        sport: data?.match.sport.slug,
        settings: {
          maxParticipants,
          level,
          competitionType,
          gender,
          visibility: desiredVisibility,
        },
      })
    }
  }

  const handleClose = () => {
    dispatch({ type: 'RESET_MATCH_SETTINGS', payload: initialState })
    onClose()
  }

  useEffect(() => {
    if (!data) return
    setIsDesiredMatchVisibilityValid(
      checkDesiredMatchVisibilityValidity({
        state,
        desiredVisibility,
        validations: data.match.matchVisibilityValidations,
      }),
    )
  }, [state, desiredVisibility, data])

  if (!data) return null

  const minNumberOfPlayers =
    data.match.participants.length < MIN_NUMBER_OF_PLAYERS ? MIN_NUMBER_OF_PLAYERS : data.match.participants.length
  const requiredFields = getRequiredFieldsForDesiredVisibility({
    desiredVisibility,
    validations: data.match.matchVisibilityValidations,
  })
  const venueSlug = data?.match.venue.slug
  const bookingState = data?.match.state

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullScreen={isMobile}
        sx={{ '& .MuiDialog-paper': { maxWidth: '450px' } }}
      >
        <Processing isLoading={loading}>
          <DialogContent>
            <Stack>
              <Text sx={{ fontWeight: 'bold', textAlign: 'center', mb: 9 }}>
                <Localized id="participation-rules-title" />
              </Text>
              <Stack gap={6}>
                <MaxNumberOfPlayers
                  initialNumberOfPlayers={data.match.maxParticipants}
                  minNumberOfPlayers={minNumberOfPlayers}
                  isRequired={requiredFields.includes(MatchMissingField.MAX_PARTICIPANTS)}
                  dispatch={dispatch}
                />
                <RequiredLevel
                  initialLevel={data.match.level}
                  isRequired={requiredFields.includes(MatchMissingField.LEVEL)}
                  dispatch={dispatch}
                />
                <CompetitionType
                  initialType={data.match.competitionType}
                  isRequired={requiredFields.includes(MatchMissingField.COMPETITION_TYPE)}
                  dispatch={dispatch}
                />
                <PlayerGender
                  initialGender={data.match.gender}
                  isRequired={requiredFields.includes(MatchMissingField.GENDER)}
                  dispatch={dispatch}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <Box
            sx={{
              backgroundColor: 'white',
              boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.16)',
              position: 'sticky',
              bottom: 0,
              py: 3,
              px: 4,
            }}
          >
            <Stack direction="row" width="100%" gap={4}>
              <Button variant="tertiary" sx={{ p: 3, flex: 1 }} onClick={handleClose}>
                <Text sx={{ color: 'text', fontWeight: 'bold', textShadow: boxShadows.default, textTransform: 'none' }}>
                  <Localized id="dismiss-players-removal-button" />
                </Text>
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={!isDesiredMatchVisibilityValid}
                sx={{
                  p: 3,
                  flex: 1.5,
                }}
              >
                <Text
                  sx={{ color: 'white', fontWeight: 'bold', textShadow: boxShadows.default, textTransform: 'none' }}
                >
                  {!isDesiredMatchVisibilityValid ? (
                    <Localized id="match-rules-fill-out-required-fields" />
                  ) : (
                    <Localized id="match-rules-call-to-action" params={{ desiredVisibility }} />
                  )}
                </Text>
              </Button>
            </Stack>
          </Box>
        </Processing>
      </Dialog>
      {venueSlug && (
        <PublicMatchCreatedDisclaimer
          venueSlug={venueSlug}
          matchWasPublished={publicMatchWasCreated}
          bookingState={bookingState}
        />
      )}
    </>
  )
}

export default MatchSettingsModal
