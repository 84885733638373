import { differenceInMinutes } from 'date-fns'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import { SharingMessage } from '@eversports/klimt-patterns/SharingActions'

import { useIntl } from '../../../../../../localization/react'
import { useSharingMessageGetBookableItemQuery } from '../../../../../../graphql'
import Routes from '../../../../../../App.routes'

const useSharingMessage = (activityId: string): SharingMessage => {
  const intl = useIntl()
  const { language, origin } = useSettingsContext()

  const activityUrl = new URL(Routes.ACTIVITY_DETAILS.replace(':activityId', activityId), origin).href

  const { data } = useSharingMessageGetBookableItemQuery({
    variables: { id: activityId },
  })

  if (!data) return { encodedMessage: '', translatedMessage: '', linkToCopy: '' }

  const activity = data.getEventBookableItem
  const { sport, activityName, venue } = activity
  const eventSession = activity.eventSessions?.[0]

  const start = new Date(eventSession.start)
  const end = new Date(eventSession.end)

  const formattedDate = new Intl.DateTimeFormat(language).format(start)
  const duration = differenceInMinutes(end, start)

  const startTime = start.toLocaleTimeString(language, {
    hour: '2-digit',
    minute: '2-digit',
  })

  const endTime = end.toLocaleTimeString(language, {
    hour: '2-digit',
    minute: '2-digit',
  })

  const dayOfTheWeek = new Intl.DateTimeFormat(language, {
    weekday: 'long',
  }).format(start)

  const params = {
    sportName: sport.name,
    eventType: activity.eventType,
    activityName: activityName,
    dayOfTheWeek,
    formattedDate,
    startTime,
    endTime,
    duration,
    venueName: venue.name,
    trainerName: eventSession?.trainer?.nickname ?? 'unknown',
    activityUrl,
  }

  //   Message that is shared:
  //
  //   Hey, I found this ${sport.name} ${event.type} on Eversports: ${activity.name}
  //   📅 ${dayOfTheWeek} ${formattedDate}
  //   ⏰ ${startTime} - ${endTime} (${duration}min)
  //   🏠 ${venueName}
  //   👫 Instructed by ${trainer.nickname}
  //
  //   ➡️ Save your spot here: ${hostname}${relativeLink}
  const translatedMessage = intl.activitySingleSessionSharingMessage(params)

  return {
    encodedMessage: encodeURIComponent(translatedMessage),
    translatedMessage: translatedMessage,
    linkToCopy: activityUrl,
  }
}

export default useSharingMessage
