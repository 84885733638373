import * as React from 'react'
import { MouseEventHandler } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import OverlayLink from '@eversports/klimt-patterns/OverlayLink'
import Box from '@eversports/klimt-primitives/Box'
import { typography } from '@eversports/design-tokens/typography'
import { borderRadius } from '@eversports/design-tokens/border-radius'

import ListingCard from '../ListingCard'
import SportTags from '../../../../components/SportTags'
import { OnlineVenue, Venue } from '../../../../App.types'
import { DiscoverySport } from '../../Discover.types'
import { venueHasCorporatePrice } from '../../../../helpers/venue-has-corporate-price'
import CorporateBadge from '../../../../components/CorporateBadge'
import { isSportABallsport } from '../../../../helpers/is-sport-a-ballsport'
import MultipleLocationsTag from '../../../../components/MultipleLocationsTag'

import Rating from './Rating'
import { NoProfileImage, VenueImage } from './VenueImage'
import VenueTags from './VenueTags'
import VenueAddress from './VenueAddress'
import CourtSlots from './CourtSlots'

interface Props {
  venue: Venue | OnlineVenue
  handleMouseEnter?: MouseEventHandler<HTMLDivElement>
  handleMouseLeave?: MouseEventHandler<HTMLDivElement>
  sport?: DiscoverySport
  imageLoadingStrategy: 'lazy' | 'eager'
  to: string
}

const ListingResultComponent = ({
  venue,
  handleMouseEnter,
  handleMouseLeave,
  imageLoadingStrategy,
  sport,
  to,
}: Props) => {
  const hasSpecialCorporatePrice = venueHasCorporatePrice(venue.specialPriceTypes)
  const isOnline = !('address' in venue)
  return (
    <ListingCard onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <OverlayLink to={to} external blank ariaLabel={`View offerings and information for ${venue.name}`} />
      <Stack direction={{ xs: 'column', sm: 'row' }}>
        {venue.profileImage ? (
          <VenueImage venueProfileImage={venue.profileImage} venueName={venue.name} loading={imageLoadingStrategy} />
        ) : (
          <NoProfileImage />
        )}
        <Stack
          sx={{
            gap: { xs: 2, sm: 3 },
            padding: { xs: 3, sm: 4 },
            overflow: 'auto',
            width: '100%',
            border: '0.5px solid',
            borderColor: 'mediumgray',
            borderRadius: 1,
            borderTopLeftRadius: 0,
            borderTopRightRadius: { xs: 0, sm: borderRadius.small },
            borderBottomLeftRadius: 0,
          }}
        >
          <Box
            component="h2"
            sx={{
              fontSize: { xs: typography.header.regular.fontSize, sm: typography.header.small.fontSize },
              lineHeight: { xs: typography.header.regular.lineHeight, sm: typography.header.small.lineHeight },
              fontWeight: 700,
              textOverflow: { xs: 'ellipsis', sm: 'unset' },
              overflow: { xs: 'hidden', sm: 'auto' },
              whiteSpace: { xs: 'nowrap', sm: 'unset' },
              marginBottom: { xs: 1, sm: 0 },
            }}
          >
            {venue.name}
          </Box>
          <Rating rating={venue.rating} reviewCount={venue.reviewCount} />
          {isSportABallsport(sport) ? (
            <>
              <CourtSlots slots={venue.slotsToDiscover} venueId={venue.id} to={to} />
              <MultipleLocationsTag company={venue.company} isOnline={isOnline} />
              {hasSpecialCorporatePrice && <CorporateBadge />}
            </>
          ) : (
            <>
              <SportTags sports={venue.sports} />
              <Stack gap={2} direction="row" flexWrap="wrap">
                <MultipleLocationsTag company={venue.company} isOnline={isOnline} />
                <VenueTags tags={venue.tags} />
                {hasSpecialCorporatePrice && <CorporateBadge />}
              </Stack>
            </>
          )}
          <VenueAddress address={isOnline ? undefined : venue.address} />
        </Stack>
      </Stack>
    </ListingCard>
  )
}

export default ListingResultComponent
