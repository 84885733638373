import * as React from 'react'
import { useEffect, useState } from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import MinusIcon from '@eversports/design-tokens/assets/icons/minus.svg'
import AddIcon from '@eversports/design-tokens/assets/icons/add.svg'
import Icon from '@eversports/klimt-primitives/Icon'

import { Localized } from '../../../../../localization/react'
import { Action } from '../helpers/match-settings-reducer'

import ResetField from './components/ResetField'

interface Props {
  dispatch: React.Dispatch<Action>
  initialNumberOfPlayers?: number | null
  minNumberOfPlayers: number
  isRequired?: boolean
}

const MaxNumberOfPlayersOperationBox = ({
  type,
  isDisabled = false,
  onClick,
}: {
  type: 'minus' | 'plus'
  isDisabled?: boolean
  onClick: () => void
}) => {
  const OperationIcon = type === 'minus' ? MinusIcon : AddIcon
  return (
    <Box
      sx={{ flex: 1, display: 'flex', justifyContent: 'center', cursor: isDisabled ? 'cursor' : 'pointer' }}
      onClick={onClick}
    >
      <Icon src={OperationIcon} size="default" color={isDisabled ? 'gray' : 'black'} />
    </Box>
  )
}

const MaxNumberOfPlayers = ({ dispatch, initialNumberOfPlayers, minNumberOfPlayers, isRequired = false }: Props) => {
  const [numberOfPlayers, setNumberOfPlayers] = useState(initialNumberOfPlayers)
  const isMinusDisabled = !numberOfPlayers || minNumberOfPlayers === numberOfPlayers

  useEffect(() => {
    if (!numberOfPlayers) return
    dispatch({ type: 'UPDATE_MATCH_MAX_PLAYER_COUNT', payload: numberOfPlayers })
  }, [numberOfPlayers])

  const handleMinus = () => {
    if (isMinusDisabled) return
    if (numberOfPlayers > minNumberOfPlayers) {
      setNumberOfPlayers(numberOfPlayers - 1)
    }
  }

  const handlePlus = () => {
    const newNumberOfPlayers = numberOfPlayers ? numberOfPlayers + 1 : minNumberOfPlayers
    setNumberOfPlayers(newNumberOfPlayers)
  }

  const resetField = () => {
    setNumberOfPlayers(null)
    dispatch({ type: 'UPDATE_MATCH_MAX_PLAYER_COUNT', payload: null })
  }

  const noPlayersSetMessage = isRequired ? (
    <Localized id="no-number-of-players-set" />
  ) : (
    <Localized id="unlimited-players" />
  )

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={0.5}>
        <Text variant="small" sx={{ fontWeight: 'bold' }}>
          <Localized id="match-rule-max-number-of-players" />
        </Text>
        {isRequired && <Text sx={{ color: 'red', fontWeight: 'bold', verticalAlign: 'text-top' }}>*</Text>}
        {numberOfPlayers && <ResetField onClick={resetField} />}
      </Stack>
      <Box sx={{ border: '1px solid', borderColor: 'mediumgray', borderRadius: 1, padding: 2, width: '100%' }}>
        <Stack direction="row" alignItems="center">
          <MaxNumberOfPlayersOperationBox type="minus" isDisabled={isMinusDisabled} onClick={handleMinus} />
          {numberOfPlayers ? (
            <Text variant="large" sx={{ flex: 1, textAlign: 'center' }}>
              {numberOfPlayers}
            </Text>
          ) : (
            <Text variant="large" sx={{ flex: 1, textAlign: 'center', color: 'gray' }}>
              {noPlayersSetMessage}
            </Text>
          )}
          <MaxNumberOfPlayersOperationBox type="plus" onClick={handlePlus} />
        </Stack>
      </Box>
    </Stack>
  )
}

export default MaxNumberOfPlayers
