import { useState } from 'react'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import AmplitudeIdentify from '@eversports/amplitude-react/amplitude-identify'

import { User } from '../App.types'
import { useUseLoggedInUserMeLazyQuery } from '../graphql'

export type LoggedInUser = { data?: User | null; hasRecommendations: boolean }

interface Result {
  getLoggedInUser: () => void
  user: LoggedInUser
  isLoading: boolean
}

const useLoggedInUser = (): Result => {
  const { amplitude } = useAmplitude()
  const [user, setUser] = useState<LoggedInUser>({ data: undefined, hasRecommendations: false })
  const [getLoggedInUser, { loading, error }] = useUseLoggedInUserMeLazyQuery({
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onCompleted: (data) => {
      const isValidUser = data.me?.hasVerifiedEmail
      const hasRecommendations = Boolean(
        data.me?.recommendation?.venuesWithTrialCards.edges.length ||
          data.me?.recommendation?.recentTrainers.edges.length,
      )

      const loggedInUser = {
        data: isValidUser ? data.me : undefined,
        hasRecommendations: isValidUser ? hasRecommendations : false,
      }

      if (isValidUser && loggedInUser.data && amplitude) {
        const identifyEvent = new AmplitudeIdentify().set('numberOfFriends', loggedInUser.data.friendsCount)
        amplitude.identify(identifyEvent)
      }

      setUser(loggedInUser)
    },
  })

  const handleGetLoggedInUser = () => {
    void getLoggedInUser()
  }

  if (error) {
    if (error.networkError) {
      throw new Error(error.networkError.message)
    }

    throw new Error(`${error.name} - ${error.message}`)
  }

  return { getLoggedInUser: handleGetLoggedInUser, user, isLoading: loading }
}

export default useLoggedInUser
