import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import * as React from 'react'
import Divider from '@eversports/klimt-primitives/Divider'
import Button from '@eversports/klimt-primitives/Button'
import Link from '@eversports/klimt-primitives/Link'

import { Localized } from '../../../../localization/react'
import { VenueProfileTabsPrefix } from '../../../../App.types'

import NoMatchesFoundImage from './assets/no-matches-found.webp'
import CreateYourMatchCardAdvantage from './CreateYourMatchCardAdvantage'

interface Props {
  venueSlug: string
}

const NoMatchesFound = ({ venueSlug }: Props) => {
  const to = `${VenueProfileTabsPrefix.SPORTS_FIELDS}${venueSlug}`

  return (
    <Stack gap={4} paddingY={6} textAlign="center" alignItems="center" width="fit-content" margin="auto">
      <img src={NoMatchesFoundImage} alt="No matches found image" width="40px" height="40px" />
      <Stack gap={1}>
        <Text variant="large" sx={{ fontWeight: 'bold' }}>
          <Localized id="no-matches-found-title" />
        </Text>
        <Text variant="small">
          <Localized id="no-matches-found-description" />
        </Text>
      </Stack>
      <Divider orientation="horizontal" sx={{ marginY: 3 }} flexItem />
      <Stack gap={3}>
        <Text variant="large" sx={{ fontWeight: 'bold' }}>
          <Localized id="no-matches-how-to-create-a-public-match" />
        </Text>
        <CreateYourMatchCardAdvantage index={1} />
        <CreateYourMatchCardAdvantage index={2} />
      </Stack>
      <Link to={to} external>
        <Button variant="secondary">
          <Text variant="xsmall" sx={{ color: 'primary.main', textTransform: 'none', fontWeight: 'bold' }}>
            <Localized id="no-matches-view-court-schedule" />
          </Text>
        </Button>
      </Link>
    </Stack>
  )
}

export default NoMatchesFound
