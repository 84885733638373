import * as React from 'react'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import SharingActions, { SharingMessage } from '@eversports/klimt-patterns/SharingActions'

import { Localized } from '../../../localization/react'
import Drawer from '../../../components/Drawer'
import useSharingMessage from '../hooks/use-sharing-message'

interface Props {
  isOpen: boolean
  toggleDialog: () => void
}

const UserProfileSharingActionsContent = ({ sharingMessage }: { sharingMessage: SharingMessage }) => {
  return (
    <Stack gap={4}>
      <Heading is="h3" variant="large">
        <Localized id="share-with-friends" />
      </Heading>
      <Box p={3} sx={{ backgroundColor: 'eggshell', border: '1px dashed', borderColor: 'mediumgray', borderRadius: 1 }}>
        <Text variant="small" sx={{ whiteSpace: 'pre-wrap' }}>
          {sharingMessage.translatedMessage}
        </Text>
      </Box>
      <SharingActions sharingMessage={sharingMessage} />
    </Stack>
  )
}

const UserProfileSharingActions = ({ isOpen, toggleDialog }: Props) => {
  const theme = useTheme()
  const sharingMessage = useSharingMessage()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return isMobile ? (
    <Drawer open={isOpen} onClose={toggleDialog}>
      <UserProfileSharingActionsContent sharingMessage={sharingMessage} />
    </Drawer>
  ) : (
    <Dialog open={isOpen} onClose={toggleDialog}>
      <DialogContent>
        <UserProfileSharingActionsContent sharingMessage={sharingMessage} />
      </DialogContent>
    </Dialog>
  )
}

export default UserProfileSharingActions
