import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'

interface Props {
  title: React.ReactNode
  subtitle: React.ReactNode
  image: React.ReactNode
}

const RecapOverviewItem = ({ title, subtitle, image }: Props) => {
  return (
    <Stack direction="row" alignItems="center" gap={9}>
      {image}
      <Stack>
        <Heading is="h5" variant="xlarge" sx={{ color: 'midnight' }}>
          {title}
        </Heading>
        <Text variant="large" sx={{ color: 'midnight' }}>
          {subtitle}
        </Text>
      </Stack>
    </Stack>
  )
}

export default RecapOverviewItem
