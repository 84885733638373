import * as React from 'react'
import { useState } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import { CitySearchResult } from '../../../components/Search/Search.types'
import useUrlParams from '../../../hooks/use-url-params'
import { ShowType } from '../../../App.types'
import Search from '../../../components/Search'
import { Localized } from '../../../localization/react'
import EversportsFlywheelLinks from '../../../components/EversportsFlywheelLinks'

import { DEFAULT_CITY_SLUGS_BY_TLD, DEFAULT_SPORT_SLUGS_BY_TLD } from './Hero.constants'
import SportBadges from './components/SportBadges'
import HeroImg from './assets/hero.webp'

const Hero = () => {
  const [location, setLocation] = useState<CitySearchResult>()

  const updateLocation = (newLocation: CitySearchResult) => {
    setLocation(newLocation)
  }

  // TODO - See if some of this logic we can move directly to the search bar (but this would require testing beyond the homepage)
  // This is the same logic we have on the current homepage
  const { show } = useUrlParams()
  const showSearchOpen = show === ShowType.SEARCH
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { tld } = useSettingsContext()
  const DEFAULT_CITY_SLUG = DEFAULT_CITY_SLUGS_BY_TLD[tld]
  const DEFAULT_SPORT_SLUGS = DEFAULT_SPORT_SLUGS_BY_TLD[tld]

  return (
    <Stack
      sx={{
        alignItems: 'center',
        background: { xs: 'white', sm: '#004349' },
        marginX: { xs: 4, sm: 0 },
        marginTop: { xs: 6, sm: 0 },
        marginBottom: 15,
        paddingTop: { sm: 15 },
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        '::before': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '130px',
          bottom: { sm: '4%', lg: '-6%' },
          right: { sm: '15%', lg: '20%' },
          borderRadius: '50%',
          backgroundColor: { xs: 'transparent', sm: '#21a696' },
          transform: 'rotate(354deg)',
          filter: 'blur(40px)',
        },
        '::after': {
          content: '""',
          position: 'absolute',
          width: '45%',
          height: '200px',
          bottom: '-20%',
          right: '6%',
          borderRadius: '50%',
          backgroundColor: { xs: 'transparent', sm: '#004349' },
          transform: 'rotate(354deg)',
          filter: 'blur(24px)',
        },
      }}
    >
      <Stack sx={{ width: '100%', maxWidth: '1162px', paddingX: { sm: 4 } }}>
        <Box
          component="h1"
          sx={{
            fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
            fontSize: '32px',
            fontWeight: 'bold',
            lineHeight: 'normal',
            color: { xs: 'midnight', sm: 'white' },
            zIndex: 1,
            paddingBottom: 2,
            paddingX: { xs: 4, sm: 0 },
          }}
        >
          <Localized id="home-rebrand-header" />
        </Box>
        <Text
          variant="large"
          sx={{ color: { xs: 'darkgray', sm: '#EDF8F6' }, paddingBottom: 6, zIndex: 1, paddingX: { xs: 4, sm: 0 } }}
        >
          <Localized id="home-rebrand-subheader" />
        </Text>
        <Stack
          sx={{
            alignItems: { xs: 'center', sm: 'flex-start' },
            position: 'relative',
            background: { xs: '#004349', sm: 'transparent' },
            '::before': {
              content: '""',
              backgroundColor: { xs: '#21a696', sm: 'transparent' },
              position: 'absolute',
              width: '100%',
              height: '420px',
              bottom: '-28%',
              borderRadius: '50%',
              filter: 'blur(40px)',
            },
            '::after': {
              content: '""',
              backgroundColor: { xs: '#004349', sm: 'transparent' },
              position: 'absolute',
              width: '85%',
              height: '320px',
              bottom: '-12%',
              right: '-26%',
              borderRadius: '50%',
              transform: 'rotate(323deg)',
              filter: 'blur(30px)',
            },
          }}
        >
          <Stack
            sx={{
              gap: 4,
              zIndex: 2,
              width: '100%',
              maxWidth: { md: '600px' },
              height: { xs: '560px', sm: '460px', lg: '360px' },
              position: { xs: 'relative', sm: 'static' },
              top: { xs: '-16px', sm: 0 },
              paddingBottom: { xs: 0, md: 0 },
              paddingX: { xs: 4, sm: 0 },
            }}
          >
            <Search
              updateParentLocation={updateLocation}
              variant={isMobile ? 'stacked' : 'default'}
              hasInputLabels
              isDefaultOpen={showSearchOpen}
            />
            <SportBadges citySlug={location?.slug || DEFAULT_CITY_SLUG} sportSlugs={DEFAULT_SPORT_SLUGS} />
            <Stack
              sx={{
                flexDirection: { sm: 'row' },
                gap: { xs: 2, sm: 4 },
                position: 'absolute',
                bottom: '16px',
              }}
            >
              <EversportsFlywheelLinks />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          right: { xs: 0, lg: '24px', xl: '10%' },
          bottom: 0,
          width: { xs: '100%', sm: '540px' },
          zIndex: 1,
        }}
      >
        <img
          src={HeroImg}
          alt=""
          width={540}
          height={330}
          style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
        />
      </Box>
    </Stack>
  )
}

export default Hero
