import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import Processing from '@eversports/klimt-primitives/Processing'

import { Localized } from '../../../../../../localization/react'
import SectionCard from '../../components/SectionCard'
import NoFriendsOrRequests from '../../components/NoFriendsOrRequests'
import { FriendConnection } from '../../Friends.types'

import Friend from './Friend'
import useFriends from './hooks/useFriends'

interface Props {
  initiallyFetchedFriends: FriendConnection | null
  friendsCount: number
}

const FriendsList = ({ initiallyFetchedFriends, friendsCount }: Props) => {
  const { friends, loading, fetchMore, hasMoreResults, onFriendRemoval } = useFriends({ initiallyFetchedFriends })

  if (!friends.length) {
    return <NoFriendsOrRequests />
  }

  return (
    <SectionCard>
      <Heading is="h3" sx={{ color: 'midnight' }}>
        <Localized id="friends-section-title" params={{ friendsCount }} />
      </Heading>
      {friends.map((friend) => (
        <Friend key={friend.id} friend={friend} onFriendRemoval={onFriendRemoval} />
      ))}
      {hasMoreResults && (
        <Processing isLoading={loading}>
          <Box
            sx={{ backgroundColor: 'limelite', borderRadius: 1, padding: 2, width: '100%', cursor: 'pointer' }}
            onClick={fetchMore}
          >
            <Text variant="small" sx={{ color: 'primary.main', fontWeight: 'bold', textAlign: 'center' }}>
              <Localized id="load-more-friends-button" />
            </Text>
          </Box>
        </Processing>
      )}
    </SectionCard>
  )
}

export default FriendsList
