const ONE_YEAR_IN_SECONDS = 31536000
const ONE_MONTH_IN_SECONDS = 2592000
const ONE_DAY_IN_SECONDS = 86400
const ONE_HOUR_IN_SECONDS = 3600
const ONE_MINUTE_IN_SECONDS = 60

export type TimeFrame = 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds'

export const getRelativeTime = (date: string | Date): { timeValue: number; timeFrame: TimeFrame } => {
  const asDate = typeof date === 'string' ? new Date(date) : date
  const now = new Date()

  const seconds = Math.floor((Number(now) - Number(asDate)) / 1000)

  const numberOfYears = seconds / ONE_YEAR_IN_SECONDS

  if (numberOfYears > 1) {
    return {
      timeValue: Math.floor(numberOfYears),
      timeFrame: 'years',
    }
  }
  const numberOfMonths = seconds / ONE_MONTH_IN_SECONDS
  if (numberOfMonths > 1) {
    return {
      timeValue: Math.floor(numberOfMonths),
      timeFrame: 'months',
    }
  }
  const numberOfDays = seconds / ONE_DAY_IN_SECONDS
  if (numberOfDays > 1) {
    return {
      timeValue: Math.floor(numberOfDays),
      timeFrame: 'days',
    }
  }
  const numberOfHours = seconds / ONE_HOUR_IN_SECONDS
  if (numberOfHours > 1) {
    return {
      timeValue: Math.floor(numberOfHours),
      timeFrame: 'hours',
    }
  }
  const numberOfMinutes = seconds / ONE_MINUTE_IN_SECONDS
  if (numberOfMinutes > 1) {
    return {
      timeValue: Math.floor(numberOfMinutes),
      timeFrame: 'minutes',
    }
  }
  return {
    timeValue: seconds,
    timeFrame: 'seconds',
  }
}
