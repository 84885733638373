import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import { useEffect } from 'react'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import { useDiscoverReducer, useDiscoverState } from '../DiscoverContext'
import { Localized } from '../../../localization/react'
import { ShownVenueAmenitiesType, AmenityTypeToNameMapping } from '../../../App.types'
import FilterMenu from '../../../components/FilterMenu'
import FilterCheckbox from '../../../components/FilterCheckbox'
import FilterDrawer from '../../../components/FilterDrawer'

import CollapsibleFilter from './components/CollapsibleFilter'
import { filterHeadingMapping, FilterKey } from './Filters.constants'
import { DiscoverFilterTypes, FilterFormProps } from './Filters.types'
import getVenueFilterAmplitudeEventName from './helpers/get-venue-filter-amplitude-event-name'

const filterHeadingKey = filterHeadingMapping.VENUE_AMENITIES

interface InitialValues {
  venueAmenities: Array<ShownVenueAmenitiesType>
}

const VenueAmenitiesFilterForm = ({ variant = 'default' }: FilterFormProps) => {
  const dispatch = useDiscoverReducer()
  const { venueAmenitiesActiveFilters } = useDiscoverState()

  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()

  const methods = useForm<InitialValues>({
    defaultValues: { venueAmenities: venueAmenitiesActiveFilters || [] },
  })

  const { isDirty } = methods.formState
  const venueAmenities = methods.watch('venueAmenities')

  // keep different renders of the form in sync with the state
  useEffect(() => {
    methods.reset({ venueAmenities: venueAmenitiesActiveFilters ?? [] })
  }, [venueAmenitiesActiveFilters])

  // update context state
  useEffect(() => {
    if (!isDirty) return
    if (venueAmenities.length) {
      logAmplitudeEventOnce({
        eventName: getVenueFilterAmplitudeEventName(),
        eventProperties: { type: DiscoverFilterTypes.amenity, tab: 'venue' },
      })
    }
    dispatch({ type: 'UPDATE_AMENITIES_FILTER', payload: venueAmenities })
  }, [venueAmenities])

  return (
    <Form methods={methods}>
      <Stack spacing={variant === 'drawer' ? 0 : 2}>
        {Object.entries(AmenityTypeToNameMapping).map(([key, value]) => (
          <FilterCheckbox
            key={key}
            name="venueAmenities"
            value={key}
            label={<Localized id={value as any} />}
            variant={variant}
          />
        ))}
      </Stack>
    </Form>
  )
}

export const DesktopVenueAmenitiesFilter = () => {
  const { venueAmenitiesActiveFilters } = useDiscoverState()

  const venueAmenitiesFiltersTranslationKeys = venueAmenitiesActiveFilters
    ? venueAmenitiesActiveFilters
        .map((activeFilter) => {
          return AmenityTypeToNameMapping[activeFilter]
        })
        .reverse()
    : []

  const numberOfPossibleFilters = Object.keys(AmenityTypeToNameMapping).length
  const areAllFiltersSelected = venueAmenitiesFiltersTranslationKeys.length === numberOfPossibleFilters

  return (
    <FilterMenu
      header={<Localized id={filterHeadingKey} />}
      activeFilters={areAllFiltersSelected ? ['filters-studio-all-selected'] : venueAmenitiesFiltersTranslationKeys}
    >
      <VenueAmenitiesFilterForm />
    </FilterMenu>
  )
}

export const MobileVenueAmenitiesFilter = () => {
  return (
    <CollapsibleFilter header={<Localized id={filterHeadingKey} />}>
      <VenueAmenitiesFilterForm />
    </CollapsibleFilter>
  )
}

export const MobileVenueAmenitiesFilterDrawer = () => {
  const discoverState = useDiscoverState()
  const filterValues = discoverState[FilterKey.VENUE_AMENITIES]

  const dispatch = useDiscoverReducer()
  const resetFilter = () => {
    dispatch({ type: 'UPDATE_AMENITIES_FILTER', payload: [] })
  }

  return (
    <FilterDrawer
      header={<Localized id={filterHeadingKey} />}
      selectedFilterValues={filterValues}
      onReset={resetFilter}
    >
      <VenueAmenitiesFilterForm variant="drawer" />
    </FilterDrawer>
  )
}
