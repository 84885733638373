import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

import VenueProfileFavoriteButton from './VenueProfileFavoriteButton'
import VenueShareButton from './VenueShareButton'

const FavoriteAndShareButtons = () => {
  return (
    <Stack
      gap={3}
      zIndex={1}
      direction="row"
      alignItems="align-start"
      flexShrink={0}
      sx={{ position: { xs: 'absolute', sm: 'initial' }, top: 12, right: 12 }}
    >
      <VenueProfileFavoriteButton />
      <VenueShareButton />
    </Stack>
  )
}

export default FavoriteAndShareButtons
