import * as React from 'react'
import { useParams } from 'react-router-dom'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { useParticipantsMatchQuery } from '../../../../graphql'
import { Localized } from '../../../../localization/react'
import useLoggedInUserType from '../../hooks/use-logged-in-user-type'

import Participant from './Participant'
import RemoveParticipants from './RemoveParticipants'

const Participants = () => {
  const { id } = useParams<{ id: string }>()
  const { userId } = useLoggedInUserType()
  const { data } = useParticipantsMatchQuery({ variables: { matchId: id } })

  if (!data) {
    return null
  }

  const maxParticipants = data.match.maxParticipants
  const userParticipants = data.match.participants.map((participant) => participant.user)

  const organizer = userParticipants.find((participant) => participant.id === data.match.organizerV2.id)
  const loggedInUserParticipant = userParticipants.find((participant) => participant.id === userId)
  const otherUserParticipants = userParticipants.filter(
    (participant) => participant.id !== data.match.organizerV2.id && participant.id !== userId,
  )

  return (
    <Stack gap={3}>
      <Stack direction="row" alignItems="center" gap={2}>
        {maxParticipants ? (
          <Text variant="small" sx={{ fontWeight: 'bold' }}>
            <Localized
              id="match-participants-with-count-with-max-participants"
              params={{ current: userParticipants.length, max: maxParticipants }}
            />
          </Text>
        ) : (
          <Text variant="small">
            <Localized id="match-participants-count" params={{ count: userParticipants.length }} />
          </Text>
        )}
        {otherUserParticipants.length > 0 && <RemoveParticipants />}
      </Stack>
      <Stack direction="row" flexWrap="wrap" alignItems="center" gap={2}>
        {organizer && (
          <Participant
            handle={organizer.handle}
            firstName={organizer.firstName}
            lastName={organizer.lastName}
            isActiveUser={organizer.id === userId}
            profilePicture={organizer.profilePicture?.xSmall}
            isOrganizer
          />
        )}
        {otherUserParticipants.map((participant) => (
          <Participant
            handle={participant.handle}
            firstName={participant.firstName}
            lastName={participant.lastName}
            isActiveUser={participant.id === userId}
            profilePicture={participant.profilePicture?.xSmall}
            key={participant.id}
          />
        ))}
        {loggedInUserParticipant && organizer && loggedInUserParticipant.id !== organizer.id && (
          <Participant
            handle={loggedInUserParticipant.handle}
            firstName={loggedInUserParticipant.firstName}
            lastName={loggedInUserParticipant.lastName}
            profilePicture={loggedInUserParticipant.profilePicture?.xSmall}
            isActiveUser={true}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default Participants
