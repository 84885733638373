import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box from '@eversports/klimt-primitives/Box'

interface Props {
  onClick?: () => void
  forwardedRef?: React.Ref<HTMLDivElement>
}

const ActionWrapper = ({ children, forwardedRef, onClick }: React.PropsWithChildren<Props>) => {
  return (
    <Box
      onClick={onClick}
      forwardedRef={forwardedRef}
      sx={{
        paddingY: 2,
        paddingX: { xs: 2, md: 4 },
        width: 'fit-content',
        height: 'fit-content',
        border: '1px solid',
        borderColor: 'mediumgray',
        borderRadius: 10,
        cursor: 'pointer',
        backgroundColor: 'white',
        boxShadow: boxShadows.default,
      }}
    >
      {children}
    </Box>
  )
}

export default ActionWrapper
