import * as React from 'react'
import { useState } from 'react'
import Processing from '@eversports/klimt-primitives/Processing'
import Icon from '@eversports/klimt-primitives/Icon'
import CloseIcon from '@eversports/design-tokens/assets/icons/close.svg'
import Box from '@eversports/klimt-primitives/Box'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { useDeclineFriendRequestDeclineFriendRequestMutation } from '../../../../../../../graphql'
import { Localized } from '../../../../../../../localization/react'
import ConfirmationDisclaimer from '../../../../../components/ConfirmationDisclaimer'

interface Props {
  friendId: string
}

const DeclineFriendRequest = ({ friendId }: Props) => {
  const { amplitude } = useAmplitude()
  const [isOpen, setIsOpen] = useState(false)
  const [declineFriendRequest, { loading }] = useDeclineFriendRequestDeclineFriendRequestMutation({
    variables: { userId: friendId },
  })

  const toggleDisclaimer = () => {
    setIsOpen(!isOpen)
  }

  const declineRequest = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Confirm Remove Received Friend Request')
    }
    toggleDisclaimer()
    void declineFriendRequest()
  }

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Decline Friend Request Button')
    }
    toggleDisclaimer()
  }

  const handleDismiss = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Dismiss Remove Received Request Button')
    }
    toggleDisclaimer()
  }

  return (
    <>
      <Processing isLoading={loading}>
        <Box sx={{ cursor: 'pointer', flexShrink: 0 }} onClick={handleClick}>
          <Icon size="default" color="gray" src={CloseIcon} />
        </Box>
      </Processing>
      <ConfirmationDisclaimer
        title={<Localized id="confirmation-disclaimer-received-friendship-request-title" />}
        description={<Localized id="confirmation-disclaimer-received-friendship-request-description" />}
        confirmationText={<Localized id="confirmation-disclaimer-received-friendship-request-decline-request" />}
        dismissText={<Localized id="confirmation-disclaimer-received-friendship-request-dismiss" />}
        onConfirm={declineRequest}
        onDismiss={handleDismiss}
        onClose={toggleDisclaimer}
        isOpen={isOpen}
      />
    </>
  )
}

export default DeclineFriendRequest
