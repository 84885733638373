import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import Stack from '@eversports/klimt-primitives/Stack'
import { useLocation } from 'react-router-dom'
import NoSsr from '@eversports/klimt-design-components/NoSsr'
import Box from '@eversports/klimt-primitives/Box'
import HorizontalLogo from '@eversports/design-tokens/assets/logo/filled-horizontal.svg'

import { ManagerLinksMapping } from '../../App.constants'
import useLoggedInUser from '../../hooks/useLoggedInUser'
import NPSBanner from '../../components/Header/NPSBanner'
import Search from '../../components/Search'
import MobileUserMenuDialogComponent from '../../components/Header/UserMenu/MobileUserMenuDialogComponent'
import DesktopHeaderItems from '../../components/Header/DesktopHeaderItems'

const PERCENT_SCROLL_TO_SHOW_SEARCH = 0.67

const Header = () => {
  const [scrollY, setScrollY] = useState(0)
  const [windowHeight, setWindowHeight] = useState(500)
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const { tld } = useSettingsContext()
  const location = useLocation()
  const managerLinks = ManagerLinksMapping[tld]

  const { getLoggedInUser, user, isLoading: isLoadingUser } = useLoggedInUser()

  const showSearchInHeader = scrollY > windowHeight * PERCENT_SCROLL_TO_SHOW_SEARCH

  const authUrlParams = `?origin=eversport&redirectPath=${encodeURIComponent(`${location.pathname}${location.search}`)}`

  useEffect(() => {
    getLoggedInUser()
  }, [])

  useEffect(() => {
    setWindowHeight(window.innerHeight)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY)
    }
    window.addEventListener('scroll', handleScroll, {
      passive: true,
    })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <Stack
        forwardedRef={containerRef}
        component="header"
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          rowGap: 4,
          px: { xs: 3, md: 8 },
          py: 2,
          height: { md: '56px' }, // account for search bar that appears after scroll
          position: 'sticky',
          top: 0,
          zIndex: 7,
          backgroundColor: 'white',
          border: '1px solid',
          borderColor: 'mediumgray',
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ paddingRight: { md: 3 }, gap: { md: 5 } }}>
          <img src={HorizontalLogo} width={160} height={32} alt="Eversports logo" />
          {showSearchInHeader && (
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Search />
            </Box>
          )}
        </Stack>
        {isLoadingUser ? (
          <Box sx={{ width: '32px' }} />
        ) : (
          <NoSsr>
            <MobileUserMenuDialogComponent user={user} authUrlParams={authUrlParams} />
            <DesktopHeaderItems managerLinks={managerLinks} user={user} authUrlParams={authUrlParams} />
          </NoSsr>
        )}
        {showSearchInHeader && (
          <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}>
            <Search />
          </Box>
        )}
      </Stack>
      <NPSBanner />
    </>
  )
}

export default Header
