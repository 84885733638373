import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../localization/react'

interface Props {
  headerLocalizedId: 'home-brand-promise-1-header' | 'home-brand-promise-2-header' | 'home-brand-promise-3-header'
  descriptionLocalizedId:
    | 'home-brand-promise-1-description'
    | 'home-brand-promise-2-description'
    | 'home-brand-promise-3-description'
  icon: string
}

const BrandPromiseCard = ({ headerLocalizedId, descriptionLocalizedId, icon }: Props) => (
  <Box
    component="li"
    sx={{
      position: 'relative',
      width: { xs: '340px', md: '376px' },
      background: '#EDF8F6',
      borderRadius: 2,
      paddingX: 4,
      paddingTop: 6,
      paddingBottom: 8,
      flex: { md: 1 },
      flexShrink: 0,
      scrollSnapAlign: 'start',
    }}
  >
    <Box sx={{ height: '100%' }}>
      <Heading is="h3" variant="large" sx={{ marginBottom: 1, color: 'midnight', fontSize: '22px', maxWidth: '85%' }}>
        <Localized id={headerLocalizedId} />
      </Heading>
      <Text variant="large" sx={{ color: 'midnight' }}>
        <Localized id={descriptionLocalizedId} />
      </Text>
    </Box>
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        right: 0,
        top: 0,
        paddingLeft: 2,
        paddingBottom: 2,
        borderRadius: '0px 0px 0px 16px',
        background: 'white',
      }}
    >
      <img
        src={icon}
        width={48}
        height={48}
        alt=""
        loading="lazy"
        style={{
          flexShrink: 0,
          borderRadius: '8px',
          padding: '8px',
          background: '#EDF8F6',
        }}
      />
    </Box>
  </Box>
)

export default BrandPromiseCard
