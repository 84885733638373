import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import FilledHorizontalLogo from '@eversports/design-tokens/assets/logo/filled-horizontal.svg'
import Box from '@eversports/klimt-primitives/Box'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Avatar from '@eversports/klimt-primitives/Avatar'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import { useParams } from 'react-router-dom'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import { useEffect, useState } from 'react'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'
import ReactConfetti from 'react-confetti'

import { Localized, useIntl } from '../../localization/react'
import getStaticPageMetadata from '../../helpers/get-static-page-metadata'
import MetaHelmet from '../../components/MetaHelmet'
import { useYearlyRecapRecapQuery } from '../../graphql'
import LoadingPage from '../../components/LoadingPage'
import useUrlParams from '../../hooks/use-url-params'

import DifferentSportsImage from './assets/different-sports-image.webp'
import TotalMinutesImage from './assets/total-minutes-image.webp'
import ActiveDaysImage from './assets/active-days-image.webp'
import MostActiveMonthImage from './assets/most-active-month-image.webp'
import TopSportImage from './assets/top-sport-image.webp'
import RecapSection from './RecapSection'
import RecapOverview from './RecapOverview'
import findMostActiveMonth from './helpers/find-most-active-month'
import RecapOverviewImage from './RecapOverviewImage'
import RecapVenueLogo from './RecapVenueLogo'
import BackgroundPatternImage from './assets/background-pattern-image.webp'

const YearlyRecap = () => {
  const intl = useIntl()
  const { view } = useUrlParams()
  const { amplitude, logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const { origin } = useSettingsContext()
  const { recapToken } = useParams<{ recapToken: string }>()
  const { data, loading } = useYearlyRecapRecapQuery({ variables: { year: 2023, token: recapToken } })
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (!amplitude || !data) return
    logAmplitudeEventOnce({
      eventName: 'Viewed 2023 Yearly Recap',
      eventProperties: {
        userType: view ? 'visitor' : 'owner',
      },
    })
  }, [amplitude, data])

  useEffect(() => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }, [])

  if (!data || loading) {
    return <LoadingPage />
  }

  const recapUrl = `${origin}/user/${recapToken}/yearly-recap`

  const meta = getStaticPageMetadata({
    title: intl.yearlyRecapPageMetaTitle({ firstName: data.recap.user.firstName }),
    description: intl.yearlyRecapPageMetaDescription({ firstName: data.recap.user.firstName }),
    image: data.recap.user.profilePicture,
    url: recapUrl,
  })

  const mostActiveMonth = findMostActiveMonth(data.recap)

  return (
    <>
      <MetaHelmet meta={meta} noIndex />
      {windowSize.width > 0 && (
        <ReactConfetti
          width={windowSize.width}
          height={windowSize.height}
          style={{ pointerEvents: 'none' }}
          numberOfPieces={windowSize.width > 600 ? 1000 : 500}
          recycle={false}
          onConfettiComplete={(confetti) => {
            confetti?.reset()
          }}
        />
      )}
      <Box
        width="100%"
        sx={{
          backgroundImage: `url(${BackgroundPatternImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: { xs: 'center', lg: 'initial' },
          height: '100svh',
          overflowY: 'scroll',
          scrollSnapType: 'y mandatory',
        }}
      >
        <Stack alignItems="center" maxWidth="600px" margin="auto" gap={6}>
          <Stack
            gap={6}
            padding={4}
            width="100%"
            minHeight="100svh"
            justifyContent="center"
            sx={{ scrollSnapAlign: 'start' }}
          >
            <Stack gap={6} textAlign="center" justifyContent="center" alignItems="center">
              <Box paddingBottom={6}>
                <img width="185px" height="38px" src={FilledHorizontalLogo} />
              </Box>
              <Stack textAlign="center">
                <Heading is="h1" sx={{ color: 'midnight' }}>
                  <Localized id="yearly-recap-title" params={{ firstName: data.recap.user.firstName }} />
                </Heading>
                <Text sx={{ color: 'midnight' }}>
                  <Localized id="yearly-recap-subtitle" />
                </Text>
              </Stack>
              <Avatar
                src={data.recap.user.profilePicture?.large}
                sx={{
                  width: '240px',
                  height: '240px',
                  backgroundColor: 'gray',
                  boxShadow: boxShadows.hover,
                }}
              />
            </Stack>
            <Stack gap={4} textAlign="center">
              <Heading is="h2" sx={{ fontSize: '57px', lineHeight: '64px', color: 'midnight' }}>
                <Localized id="yearly-recap-your-year" params={{ year: String(data.recap.year) }} />
              </Heading>
              <Text variant="large" sx={{ fontWeight: 'bold', color: 'midnight' }}>
                <Localized id="yearly-recap-your-year-with-eversports" />
              </Text>
            </Stack>
          </Stack>
          <RecapSection
            title={
              <Localized id="yearly-recap-sports-section-title" params={{ totalSportsCount: data.recap.sportsCount }} />
            }
            subtitle={<Localized id="yearly-recap-sports-section-subtitle" />}
            image={<RecapOverviewImage src={DifferentSportsImage} variant="large" />}
          />
          <RecapSection
            title={data.recap.sports[0].sport.name}
            subtitle={<Localized id="yearly-recap-top-sport-section-subtitle" />}
            image={<RecapOverviewImage src={TopSportImage} variant="large" />}
            listItems={data.recap.sports}
          />
          <RecapSection
            title={
              <Localized
                id="yearly-recap-total-minutes-section-title"
                params={{ totalMinutes: data.recap.minutesActive }}
              />
            }
            subtitle={<Localized id="yearly-recap-total-minutes-section-subtitle" />}
            image={<RecapOverviewImage src={TotalMinutesImage} variant="large" />}
          />
          <RecapSection
            title={data.recap.venues[0].venue.name}
            subtitle={<Localized id="yearly-recap-top-venues-section-subtitle" />}
            image={<RecapVenueLogo src={data.recap.venues[0].venue.logo?.large} variant="large" />}
            listItems={data.recap.venues}
          />
          <RecapSection
            title={
              <Localized
                id="yearly-recap-active-days-section-title"
                params={{ totalDaysCount: data.recap.activeDays }}
              />
            }
            subtitle={<Localized id="yearly-recap-active-days-section-subtitle" />}
            image={<RecapOverviewImage src={ActiveDaysImage} variant="large" />}
          />
          <RecapSection
            title={
              <Localized
                id="yearly-recap-most-active-month-section-title"
                params={{ monthName: <Localized id="month-of-the-year" params={{ month: mostActiveMonth?.month }} /> }}
              />
            }
            subtitle={
              <Localized
                id="yearly-recap-most-active-month-section-subtitle"
                params={{ monthBookingCount: mostActiveMonth?.bookingCount }}
              />
            }
            image={<RecapOverviewImage src={MostActiveMonthImage} variant="large" />}
          />
          <RecapOverview recap={data.recap} recapUrl={recapUrl} />
        </Stack>
      </Box>
    </>
  )
}

export default YearlyRecap
