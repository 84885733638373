import * as React from 'react'
import { useEffect } from 'react'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { useRecommendationsMeQuery } from '../../graphql'

import RecommendationsComponent from './RecommendationsComponent'

export const DEFAULT_NUMBER_OF_CARDS_TO_SHOW = 3
const DEFAULT_GET_CARD_COUNT = DEFAULT_NUMBER_OF_CARDS_TO_SHOW + 1

const Recommendations = () => {
  const { amplitude } = useAmplitude()
  const { data, loading } = useRecommendationsMeQuery({
    variables: { first: DEFAULT_GET_CARD_COUNT },
  })

  useEffect(() => {
    if (!amplitude) return
    amplitude.logEvent('Viewed Recommendations Page')
  }, [amplitude])

  const teachers = data?.me?.recommendation?.recentTrainers.edges.map(({ node }) => node) || []
  const venues =
    data?.me?.recommendation?.venuesWithTrialCards.edges.map((edge) => {
      const { trialCards: trialCardsWithEdges, ...venueProperties } = edge.node
      const trialCards = trialCardsWithEdges.edges.map((edge) => ({ ...edge.node }))
      return {
        ...venueProperties,
        trialCards,
      }
    }) || []

  return <RecommendationsComponent venues={venues} teachers={teachers} loading={loading} />
}

export default Recommendations
