import * as React from 'react'
import { useEffect, useState } from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Processing from '@eversports/klimt-primitives/Processing'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import Button from '@eversports/klimt-primitives/Button'

import { Localized } from '../localization/react'

import Friend from './Friend'
import { FriendConnection, FriendEdge, FriendsToInviteForm } from './InviteFriends.types'
import { FRIENDS_TO_INVITE_FORM_KEY } from './InviteFriends.constants'

interface Props {
  fetchFriends: (args: { after?: string }) => void
  fetchedFriends?: FriendConnection
  formMethods: ReturnType<typeof useForm<FriendsToInviteForm>>
  onSubmit: () => void
  isLoading: boolean
  isSubmitting: boolean
}

const InviteFriends = ({ fetchFriends, fetchedFriends, formMethods, onSubmit, isLoading, isSubmitting }: Props) => {
  const [hasMoreResults, setHasMoreResults] = useState(false)
  const [friends, setFriends] = useState<Array<FriendEdge>>([])

  useEffect(() => {
    if (!fetchedFriends) return
    const newlyFetchedFriends = fetchedFriends.edges || []
    const newFriends = friends ? [...friends, ...newlyFetchedFriends] : newlyFetchedFriends
    setFriends(newFriends)
    setHasMoreResults(fetchedFriends.pageInfo.hasNextPage)
  }, [fetchedFriends])

  const fetchMoreFriends = () => {
    const cursor = friends[friends.length - 1].cursor
    void fetchFriends({
      after: cursor,
    })
  }

  const friendsWithoutCursor = friends.length
    ? friends.map((edge) => edge.node.publicUser)
    : fetchedFriends?.edges.map((edge) => edge.node.publicUser)

  const friendsIdsToBeInvited = formMethods.watch(FRIENDS_TO_INVITE_FORM_KEY)

  return (
    <>
      <Heading is="h4" variant="small">
        <Localized id="invite-friends-title" />
      </Heading>
      <Form methods={formMethods}>
        <Processing isLoading={isSubmitting}>
          <Stack
            sx={{
              maxHeight: '250px',
              overflow: 'scroll',
              '& > label': { borderBottom: '1px solid', borderColor: 'lightgray' },
            }}
          >
            {friendsWithoutCursor?.map((friend) => <Friend key={friend.id} friend={friend} />)}
            {hasMoreResults && (
              <Processing isLoading={isLoading}>
                <Text
                  sx={{ color: 'primary.main', textAlign: 'center', cursor: 'pointer', paddingY: 1.5 }}
                  onClick={fetchMoreFriends}
                >
                  <Localized id="invite-friends-load-more" />
                </Text>
              </Processing>
            )}
          </Stack>
        </Processing>
      </Form>
      {friendsIdsToBeInvited.length > 0 && (
        <Button variant="primary" sx={{ py: 2, width: '100%' }} onClick={onSubmit}>
          <Text variant="badge" sx={{ color: 'white', textTransform: 'none' }}>
            <Localized
              id="invite-friends-send-notification-button"
              params={{ numberOfSelectedFriends: friendsIdsToBeInvited.length }}
            />
          </Text>
        </Button>
      )}
    </>
  )
}

export default InviteFriends
