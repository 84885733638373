import * as React from 'react'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import RadioGroup from '@eversports/klimt-forms/RadioGroup'
import { useEffect } from 'react'

import { MatchGender, MatchMissingField } from '../../../Match.types'
import { genderTranslation } from '../MatchSettings.constants'
import { Localized } from '../../../../../localization/react'
import { Action } from '../helpers/match-settings-reducer'
import ModalOption from '../../../../../components/ModalOption'
import ModalRadioButton from '../../../../../components/ModalRadioButton'

import { PlayerGenderForm } from './MatchSettingsModal.types'
import ResetField from './components/ResetField'

interface Props {
  dispatch: React.Dispatch<Action>
  initialGender?: MatchGender | null
  isRequired?: boolean
}

const PlayerGender = ({ dispatch, initialGender, isRequired = false }: Props) => {
  const methods = useForm<PlayerGenderForm>({
    defaultValues: { GENDER: initialGender },
  })

  const playerGender = methods.watch(MatchMissingField.GENDER)

  useEffect(() => {
    if (!playerGender) return
    dispatch({ type: 'UPDATE_MATCH_PLAYER_GENDER', payload: playerGender })
  }, [playerGender])

  const resetField = () => {
    methods.reset({ GENDER: null })
    dispatch({ type: 'UPDATE_MATCH_PLAYER_GENDER', payload: null })
  }

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={0.5}>
        <Text variant="small" sx={{ fontWeight: 'bold' }}>
          <Localized id="match-rule-gender-title" />
        </Text>
        {isRequired && <Text sx={{ color: 'red', fontWeight: 'bold', verticalAlign: 'text-top' }}>*</Text>}
        {playerGender && <ResetField onClick={resetField} />}
      </Stack>
      <Form methods={methods}>
        <RadioGroup name={MatchMissingField.GENDER}>
          <Stack direction="row" gap={4} width="100%">
            {Object.keys(MatchGender).map((gender) => (
              <ModalOption key={gender} isSelected={(playerGender as string) === gender}>
                <ModalRadioButton
                  label={
                    <Text variant="badge" width="100%" p={2}>
                      <Localized id={genderTranslation[gender as MatchGender]} />
                    </Text>
                  }
                  value={gender}
                />
              </ModalOption>
            ))}
          </Stack>
        </RadioGroup>
      </Form>
    </Stack>
  )
}

export default PlayerGender
