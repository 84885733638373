import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { useParams } from 'react-router-dom'

import { Localized } from '../../../localization/react'
import { PageType } from '../Match.types'
import usePageType from '../hooks/use-page-type'
import { useMatchQuery } from '../../../graphql'
import useUrlParams from '../../../hooks/use-url-params'
import { Origin } from '../../../App.types'

import Header from './Header'

const MatchHeader = () => {
  const { returnTo, origin } = useUrlParams()
  const pageType = usePageType()

  const { id } = useParams<{ id: string }>()
  const { error } = useMatchQuery({ variables: { matchId: id } })

  if (error) {
    return <Header redirectLink={origin === Origin.WIDGET ? returnTo : undefined} />
  }

  return pageType === PageType.THANK_YOU ? (
    <Header redirectLink={origin === Origin.WIDGET ? returnTo : undefined}>
      <Stack justifyContent="center" alignItems="center" flex={1}>
        <Text sx={{ fontWeight: 'bold' }}>
          <Localized id="match-thank-you-page-title" />
        </Text>
      </Stack>
    </Header>
  ) : (
    <Header>
      <Stack justifyContent="center" alignItems="center" flex={1}>
        <Text sx={{ fontWeight: 'bold' }}>
          <Localized id="match-booking-page-title" />
        </Text>
      </Stack>
    </Header>
  )
}
export default MatchHeader
