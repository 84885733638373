import * as React from 'react'

import UserMenuLink from './UserMenu/UserMenuLink'
import { RecommendationsUserItem } from './UserMenu/UserMenuItems'

interface Props {
  hasRecommendations: boolean
}

const RecommendationsPageLink = ({ hasRecommendations }: Props) => {
  if (!hasRecommendations) return null

  return <UserMenuLink userItem={RecommendationsUserItem} />
}

export default RecommendationsPageLink
