import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box from '@eversports/klimt-primitives/Box'
import SeoLink from '@eversports/klimt-primitives/SeoLink'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import { Localized } from '../../../localization/react'
import { ShowType } from '../../../App.types'
import { DEFAULT_CITY_SLUGS } from '../Home.constants'
import OffsetMapPin from '../components/OffsetMapPin'
import SectionWrapper from '../components/SectionWrapper'
import BrandMapPin from '../assets/map-pin-brand.svg'
import FoldedMapIcon from '../assets/folded-map.svg'
import SampleMap from '../assets/sample-map.webp'

// TODO - when we have the hero section with the location from the search bar, pass that in for the city slug for the map (keep default if there's no location on initial visit)
const WhereIsEversports = () => {
  const { tld } = useSettingsContext()
  const DEFAULT_CITY_SLUG = DEFAULT_CITY_SLUGS[tld]
  return (
    <SectionWrapper sx={{ marginBottom: 5, contentVisibility: 'auto' }}>
      <Heading
        is="h2"
        variant="xlarge"
        color="midnight"
        sx={{
          fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'",
          fontWeight: 500,
          marginBottom: 1,
        }}
      >
        <Localized id="home-where-is-eversports-header" />
      </Heading>
      <Text variant="large" sx={{ marginBottom: 4, maxWidth: '450px' }}>
        <Localized id="home-where-is-eversports-description" />
      </Text>
      <SeoLink type="hidden" encodedTo={btoa(`/b/${DEFAULT_CITY_SLUG}?show=${ShowType.MAP}`)}>
        <Box
          sx={{
            background: `no-repeat url(${SampleMap})`,
            backgroundSize: 'cover',
            width: '100%',
            height: '196px',
            marginBottom: 10,
            borderRadius: 1,
            overflow: 'hidden',
            boxShadow: boxShadows.hover,
            position: 'relative',
            border: '1px solid',
            borderColor: 'mediumgray',
            '&:hover': { boxShadow: '0px 16px 24px rgba(20, 20, 20, 0.2)' },
          }}
        >
          <Stack
            sx={{
              alignItems: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          >
            <img src={BrandMapPin} width="54" height="66" alt="" />
            <Stack
              sx={{
                gap: 2,
                flexDirection: 'row',
                alignItems: 'center',
                paddingX: 4,
                paddingY: 1,
                backgroundColor: 'midnight',
                color: 'white',
                borderRadius: 1,
              }}
            >
              <img src={FoldedMapIcon} alt="" width="12" height="12" loading="lazy" />
              <Localized id="home-view-on-map" />
            </Stack>
          </Stack>
          <OffsetMapPin top="15%" left="10%" />
          <OffsetMapPin top="85%" left="8%" />
          <OffsetMapPin top="40%" left="25%" />
          <OffsetMapPin top="48%" left="65%" />
          <OffsetMapPin top="36%" left="80%" />
          <OffsetMapPin top="75%" left="90%" />
        </Box>
      </SeoLink>
    </SectionWrapper>
  )
}

export default WhereIsEversports
