import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import * as React from 'react'
import { useState } from 'react'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { useParams } from 'react-router-dom'
import ShareIcon from '@eversports/design-tokens/assets/icons/share.svg'
import Icon from '@eversports/klimt-primitives/Icon'

import { Localized } from '../../../../localization/react'
import { useVenueShareButtonVenueContextQuery } from '../../../../graphql'

import FavoriteAndShareButtonsBox from './components/FavoriteAndShareButtonsBox'
import VenueButtonSharingActions from './VenueShareButtonActions'

const VenueShareButton = () => {
  const { amplitude } = useAmplitude()
  const { venueSlug } = useParams<{ venueSlug: string }>()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { data } = useVenueShareButtonVenueContextQuery({ variables: { slug: venueSlug } })

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen)
  }

  const handleClick = () => {
    setIsDialogOpen(true)
    if (amplitude && data) {
      amplitude.logEvent('Clicked Venue Profile Share Button', {
        venue: {
          name: data.venueContext.venue.name,
          slug: data.venueContext.venue.slug,
          rating: data.venueContext.venue.rating,
          reviewCount: data.venueContext.venue.reviewCount,
          numberOfImages: data.venueContext.venue.images.length,
        },
      })
    }
  }

  return (
    <>
      <FavoriteAndShareButtonsBox onClick={handleClick}>
        <Stack direction="row" alignItems="center" gap={3}>
          <Text variant="xsmall" sx={{ color: 'black', fontWeight: 'bold', display: { xs: 'none', sm: 'initial' } }}>
            <Localized id="share-venue-profile" />
          </Text>
          <Icon src={ShareIcon} size="small" color="midnight" />
        </Stack>
      </FavoriteAndShareButtonsBox>
      <VenueButtonSharingActions
        isOpen={isDialogOpen}
        toggleDialog={toggleDialog}
        venueId={data ? data.venueContext.venue.id : ''}
      />
    </>
  )
}

export default VenueShareButton
