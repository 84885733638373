import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Avatar from '@eversports/klimt-primitives/Avatar'
import Tooltip from '@eversports/klimt-primitives/Tooltip'
import Text from '@eversports/klimt-primitives/Text'

import { PublicUser } from '../../ActivityDetails.types'

import getInitials from './utils/get-initials'

interface Props {
  index: number
  friend: PublicUser
}

const ParticipatingFriend = ({ index, friend }: Props) => {
  return (
    <Tooltip
      arrow
      title={
        <Text sx={{ color: 'white' }}>
          {friend.firstName} {friend.lastName}
        </Text>
      }
    >
      <span>
        <Avatar
          src={friend.profilePicture?.small}
          sx={{
            height: `44px`,
            width: `44px`,
            border: '1px solid',
            borderColor: 'lightgray',
            backgroundColor: 'white',
            color: 'darkgray',
            filter: `drop-shadow(${boxShadows.default})`,
            marginLeft: index === 0 ? 0 : -2,
            borderRadius: 10,
          }}
        >
          {getInitials({ firstname: friend.firstName, lastname: friend.lastName })}
        </Avatar>
      </span>
    </Tooltip>
  )
}

export default ParticipatingFriend
