import * as React from 'react'
import { default as MuiDrawer, DrawerProps } from '@mui/material/Drawer'

export { DrawerProps }

const Drawer = (props: DrawerProps) => {
  return <MuiDrawer {...props} />
}

export default Drawer
