import * as React from 'react'
import MonetaryValue from '@eversports/klimt-localization/MonetaryValue'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import { Invoice } from '../InvoiceHistory.types'

import DownloadInvoiceButton from './DownloadInvoiceButton'
import InvoiceStatusBadge from './InvoiceStatusBadge'
import UsedPaymentMethods from './UsedPaymentMethods'
import InvoiceDate from './InvoiceDate'

interface Props {
  invoice: Invoice
}

const MobileInvoiceCard = ({ invoice }: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      width="100%"
      p={4}
      sx={{
        borderRadius: 1,
        boxShadow: boxShadows.default,
        background: 'white',
        border: '1px solid',
        borderColor: 'lightgray',
      }}
    >
      <Stack gap={2}>
        <InvoiceDate invoiceDate={invoice.issueDate} />
        <UsedPaymentMethods usedPaymentServices={invoice.usedPaymentServices} invoiceNumber={invoice.number} />
        <InvoiceStatusBadge invoiceState={invoice.state} />
      </Stack>
      <Stack gap={3} alignItems="flex-end" justifyContent="space-between">
        <Text variant="large" sx={{ fontWeight: 'bold' }}>
          <MonetaryValue value={invoice.amount.value} currencyCode={invoice.amount.currency} />
        </Text>
        <DownloadInvoiceButton invoiceId={invoice.id} downloadLink={invoice.downloadLink} />
      </Stack>
    </Stack>
  )
}

export default MobileInvoiceCard
