import * as React from 'react'
import Skeleton from '@eversports/klimt-primitives/Skeleton'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'

import { TRIAL_CARD_LOGO_LENGTH } from './TrialCardVenueLogo'

const TrialCardSkeleton = () => (
  <Box
    sx={{
      height: '192px',
      background: 'white',
      border: '0.5px solid',
      borderColor: 'lightgray',
      boxSizing: 'border-box',
      boxShadow: '0px 1px 1px rgba(20, 20, 20, 0.2)',
      borderRadius: 1,
      p: 4,
      cursor: 'pointer',
    }}
  >
    <Stack spacing={5} justifyContent="space-between" sx={{ height: '100%' }}>
      <Box display="grid" gridTemplateColumns={`1fr ${TRIAL_CARD_LOGO_LENGTH}px`} sx={{ gridGap: 4 }}>
        <Stack gap={2}>
          <Skeleton variant="text" height="20px" width="156px" />
          <Stack direction="row" gap={2}>
            <Skeleton variant="text" width="55px" height="18px" />
            <Skeleton variant="text" width="125px" height="18px" />
          </Stack>
          <Stack direction="row" gap={2}>
            <Skeleton variant="text" width="55px" height="18px" />
            <Skeleton variant="text" width="125px" height="18px" />
          </Stack>
          <Stack direction="row" gap={2}>
            <Skeleton variant="text" width="55px" height="18px" />
            <Skeleton variant="text" width="125px" height="18px" />
          </Stack>
        </Stack>
        <Skeleton
          variant="rectangular"
          sx={{
            minHeight: TRIAL_CARD_LOGO_LENGTH,
            minWidth: TRIAL_CARD_LOGO_LENGTH,
            borderRadius: '0px 8px 0px 0px',
            position: 'relative',
            top: '-16px',
            right: '-16px',
          }}
        />
      </Box>
      <Skeleton variant="text" height="20px" width="250px" />
    </Stack>
  </Box>
)

interface Props {
  numberOfCopies: number
}

const TrialCardsSkeletons = ({ numberOfCopies }: Props) => {
  const tempCopies = [...Array(numberOfCopies)]
  return (
    <>
      {tempCopies.map((_, i) => (
        <TrialCardSkeleton key={i} />
      ))}
    </>
  )
}

export default TrialCardsSkeletons
