import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import ExpandableContent from '@eversports/klimt-patterns/ExpandableContent'
import FormattedContent from '@eversports/klimt-primitives/FormattedContent'

import { Localized } from '../../../../localization/react'
import { About, Faq } from '../../VenueProfile.types'
import VenueProfileSectionWrapper from '../../VenueProfileSectionWrapper'
import { Sport } from '../../../../App.types'

import Faqs from './Faqs'
import OfferedSports from './OfferedSports'

const CLAMPED_TEXT_HEIGHT = 144

interface Props {
  about: About
  faqs: Array<Faq>
  sports: Array<Sport>
  venueName: string
}

const AdditionalInfo = ({ about, faqs, sports, venueName }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const showAboutTeam = Boolean(about.team)
  const showFaqs = faqs.length > 0
  const showHeader = showAboutTeam || showFaqs

  return (
    <VenueProfileSectionWrapper>
      {showHeader && (
        <Heading is="h2" color="midnight">
          <Localized id="venue-profile-further-info-title" />
        </Heading>
      )}
      {about.team && (
        <Stack spacing={2}>
          <Heading is="h3" color="midnight" variant="small">
            <Localized id="venue-profile-meet-the-team" />
          </Heading>
          {isMobile ? (
            <ExpandableContent clampedTextHeight={CLAMPED_TEXT_HEIGHT}>
              <FormattedContent htmlContent={about.team} />
            </ExpandableContent>
          ) : (
            <FormattedContent htmlContent={about.team} />
          )}
        </Stack>
      )}
      {showFaqs && (
        <Stack spacing={2}>
          <Heading is="h3" color="midnight" variant="small">
            <Localized id="venue-profile-frequently-asked-questions" />
          </Heading>
          <Faqs faqs={faqs} venueName={venueName} />
        </Stack>
      )}
      <Stack spacing={2.5}>
        <Heading is="h4" color="midnight" variant="small">
          <Localized id="venue-profile-offered-sports" />
        </Heading>
        <OfferedSports sports={sports} />
      </Stack>
    </VenueProfileSectionWrapper>
  )
}

export default AdditionalInfo
