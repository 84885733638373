import * as React from 'react'
import { useEffect, useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Text from '@eversports/klimt-primitives/Text'
import Icon from '@eversports/klimt-primitives/Icon'
import EditIcon from '@eversports/design-tokens/assets/icons/edit.svg'
import Processing from '@eversports/klimt-primitives/Processing'
import Box from '@eversports/klimt-primitives/Box'

import {
  usePrivacySettingButtonUpdatePrivacySettingsMutation,
  usePrivacySettingButtonMeQuery,
} from '../../../../graphql'
import { Localized } from '../../../../localization/react'
import { PrivacySettingField, PrivacySettingVisibility } from '../../../../App.types'

import EditPrivacySetting from './EditPrivacySetting'

interface Props {
  field: PrivacySettingField
  onClick?: () => void
}

const PrivacySettingButton = ({ field, onClick }: Props) => {
  const { data } = usePrivacySettingButtonMeQuery()
  const [updatePrivacySetting, { loading }] = usePrivacySettingButtonUpdatePrivacySettingsMutation()
  const [updatedVisibility, setUpdatedVisibility] = useState<PrivacySettingVisibility>()
  const [isEditPrivacySettingOpen, setIsEditPrivacySettingOpen] = useState(false)

  useEffect(() => {
    if (!updatedVisibility) return
    void updatePrivacySetting({ variables: { args: { fieldInputs: [{ field, visibility: updatedVisibility }] } } })
  }, [updatedVisibility])

  const handleVisibilityChange = (visibility: PrivacySettingVisibility) => {
    setUpdatedVisibility(visibility)
    handleClose()
  }

  const handleClick = () => {
    if (onClick) onClick()
    setIsEditPrivacySettingOpen(true)
  }

  const handleClose = () => {
    setIsEditPrivacySettingOpen(false)
  }

  if (!data || !data.me) return null

  const privacySettings = data.me.privacySettings
  const privacySetting = privacySettings.find((setting) => setting.field === field)

  if (!privacySetting) return null

  const visibility = updatedVisibility ?? privacySetting.visibility

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          border: '1px solid',
          borderColor: 'mediumgray',
          borderRadius: 10,
          boxShadow: boxShadows.default,
          cursor: 'pointer',
          paddingX: 3,
          paddingY: 1,
          flexShrink: 0,
        }}
        onClick={handleClick}
      >
        <Processing isLoading={loading}>
          <Stack direction="row" gap={2}>
            <Text variant="small" color={visibility === PrivacySettingVisibility.PRIVATE ? 'red' : 'carbon'}>
              <Localized id="privacy-setting-button-visibility" params={{ visibility }} />
            </Text>
            <Icon src={EditIcon} color="carbon" size="small" />
          </Stack>
        </Processing>
      </Box>
      <EditPrivacySetting
        selectedVisibility={visibility}
        isOpen={isEditPrivacySettingOpen}
        onClose={handleClose}
        onApply={handleVisibilityChange}
      />
    </>
  )
}

export default PrivacySettingButton
