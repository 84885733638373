import * as React from 'react'

/*
Use this function as a helper to prevent a submit button from being clicked before js is loaded and therefore maybe
pushing form data to the url.

@example Simple login form
function Form() {
  const locked = useRenderLock()
  return (
    <form>
      <input type="email" name="email" />
      <input type="password" name="password" />
      <input type="submit" disabled={locked} />
    </form>
  )
}
 */
function useRenderLock() {
  const [locked, setLocked] = React.useState(true)

  React.useEffect(() => {
    setLocked(false)
  }, [])

  return locked
}

export default useRenderLock
