import * as React from 'react'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import RadioGroup from '@eversports/klimt-forms/RadioGroup'
import { useEffect } from 'react'

import { MatchCompetitionType, MatchMissingField } from '../../../Match.types'
import { Localized } from '../../../../../localization/react'
import { Action } from '../helpers/match-settings-reducer'
import CompetitionTypeContent from '../components/CompetitionTypeContent'
import ModalOption from '../../../../../components/ModalOption'
import ModalRadioButton from '../../../../../components/ModalRadioButton'

import { TypeOfMatchForm } from './MatchSettingsModal.types'
import ResetField from './components/ResetField'

interface Props {
  dispatch: React.Dispatch<Action>
  initialType?: MatchCompetitionType | null
  isRequired?: boolean
}

const CompetitionType = ({ dispatch, initialType, isRequired = false }: Props) => {
  const methods = useForm<TypeOfMatchForm>({
    defaultValues: { COMPETITION_TYPE: initialType },
  })

  const typeOfMatch = methods.watch(MatchMissingField.COMPETITION_TYPE)

  useEffect(() => {
    if (!typeOfMatch) return
    dispatch({ type: 'UPDATE_MATCH_COMPETITION_TYPE', payload: typeOfMatch })
  }, [typeOfMatch])

  const resetField = () => {
    methods.reset({ COMPETITION_TYPE: null })
    dispatch({ type: 'UPDATE_MATCH_COMPETITION_TYPE', payload: null })
  }

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={0.5}>
        <Text variant="small" sx={{ fontWeight: 'bold' }}>
          <Localized id="match-rule-competition-type-title" />
        </Text>
        {isRequired && <Text sx={{ color: 'red', fontWeight: 'bold', verticalAlign: 'text-top' }}>*</Text>}
        {typeOfMatch && <ResetField onClick={resetField} />}
      </Stack>
      <Form methods={methods}>
        <RadioGroup name={MatchMissingField.COMPETITION_TYPE}>
          <Stack gap={2}>
            {Object.keys(MatchCompetitionType).map((type) => (
              <ModalOption key={type} isSelected={(typeOfMatch as string) === type}>
                <ModalRadioButton
                  label={<CompetitionTypeContent competitionType={type as MatchCompetitionType} />}
                  value={type}
                />
              </ModalOption>
            ))}
          </Stack>
        </RadioGroup>
      </Form>
    </Stack>
  )
}

export default CompetitionType
