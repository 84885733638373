import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import SeoLink from '@eversports/klimt-primitives/SeoLink'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../localization/react'
import { DiscoverPrefix } from '../../../../App.types'

import SportBadge from './SportBadge'

interface Props {
  citySlug: string
  sportSlugs: Array<string>
}

const SportBadges = ({ citySlug, sportSlugs }: Props) => (
  <Stack
    sx={{
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: 3,
      rowGap: 2,
    }}
  >
    {sportSlugs.map((sportSlug) => {
      const to = `${DiscoverPrefix.APP_SPORT_CITY}/${sportSlug}/${citySlug}`
      const crawlerLink = `${DiscoverPrefix.SEO_SPORT_CITY}/${sportSlug}/${citySlug}`
      return (
        <SeoLink
          key={`sportBadge_${citySlug}_${sportSlug}`}
          encodedTo={btoa(to)}
          crawlerLink={crawlerLink}
          type="crawler"
        >
          <SportBadge>
            <Text variant="large" color="lightgray">
              <Localized id={sportSlug as any} />
            </Text>
          </SportBadge>
        </SeoLink>
      )
    })}
    <SeoLink key={`sportBadge_${citySlug}`} encodedTo={btoa(`/b/${citySlug}`)} type="hidden">
      <SportBadge>
        <Text variant="large" color="lightgray">
          <Localized id="home-rebrand-all-sports-in-my-city" />
        </Text>
      </SportBadge>
    </SeoLink>
  </Stack>
)

export default SportBadges
