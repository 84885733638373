import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface Props {
  to: string
  external?: boolean
  blank?: boolean
  ariaLabel?: string
}

const OverlayLink: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  external = false,
  blank = false,
  to,
  ariaLabel,
}) => {
  const blankProps = blank ? { target: `_blank`, rel: 'noopener' } : {}
  const ariaLabelProps = ariaLabel ? { 'aria-label': ariaLabel } : {}

  const overlayStyles: React.CSSProperties = {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    position: 'absolute',
    display: 'block',
    zIndex: 1,
  }

  return external ? (
    <a href={to} {...blankProps} {...ariaLabelProps} style={overlayStyles}>
      {children}
    </a>
  ) : (
    <RouterLink to={to} {...blankProps} {...ariaLabelProps} style={overlayStyles}>
      {children}
    </RouterLink>
  )
}

export default OverlayLink
