import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import { useEffect } from 'react'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import { useDiscoverReducer, useDiscoverState } from '../DiscoverContext'
import { VenueAttributes } from '../Discover.types'
import { Localized } from '../../../localization/react'
import FilterMenu from '../../../components/FilterMenu'
import FilterCheckbox from '../../../components/FilterCheckbox'
import FilterDrawer from '../../../components/FilterDrawer'
import { isSportABallsport } from '../../../helpers/is-sport-a-ballsport'

import CollapsibleFilter from './components/CollapsibleFilter'
import { filterHeadingMapping, FilterKey } from './Filters.constants'
import { DiscoverFilterTypes, FilterFormProps } from './Filters.types'
import getVenueFilterAmplitudeEventName from './helpers/get-venue-filter-amplitude-event-name'

const filterHeadingKey = filterHeadingMapping.VENUE_ATTRIBUTES

interface InitialValues {
  venueAttributes: Array<VenueAttributes>
}

const ballsportAttributes = [VenueAttributes.VENUE_ATTRIBUTES_CORPORATE_DISCOUNT]

const VenueAttributesMapping = {
  [VenueAttributes.VENUE_ATTRIBUTES_FREE_TRIAL]: 'filters-studio-attributes-free-trial',
  [VenueAttributes.VENUE_ATTRIBUTES_INTRO_OFFER]: 'filters-studio-attributes-intro-offer',
  [VenueAttributes.VENUE_ATTRIBUTES_CORPORATE_DISCOUNT]: 'filters-studio-attributes-corporate-discount',
}

const VenueAttributesFilterForm = ({ variant = 'default' }: FilterFormProps) => {
  const dispatch = useDiscoverReducer()
  const { venueAttributeActiveFilters, sport } = useDiscoverState()
  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()

  const methods = useForm<InitialValues>({
    defaultValues: { venueAttributes: venueAttributeActiveFilters || [] },
  })

  const { isDirty } = methods.formState
  const venueAttributes = methods.watch('venueAttributes')

  // keep different renders of the form in sync with the state
  useEffect(() => {
    methods.reset({ venueAttributes: venueAttributeActiveFilters ?? [] })
  }, [venueAttributeActiveFilters])

  // update context state
  useEffect(() => {
    if (!isDirty) return
    if (venueAttributes.length) {
      logAmplitudeEventOnce({
        eventName: getVenueFilterAmplitudeEventName(),
        eventProperties: { type: DiscoverFilterTypes.specialDeal, tab: 'venue' },
      })
    }
    dispatch({ type: 'UPDATE_ATTRIBUTES_FILTERS', payload: venueAttributes })
  }, [venueAttributes])

  return (
    <Form methods={methods}>
      <Stack spacing={variant === 'drawer' ? 0 : 2}>
        {Object.entries(VenueAttributesMapping)
          .filter(([key]) => {
            // If we are looking at specific SPORT city pages, and it's a ballsport, then show the ballsport attributes
            return !isSportABallsport(sport) || ballsportAttributes.includes(key as VenueAttributes)
          })
          .map(([key, value]) => (
            <FilterCheckbox
              key={key}
              name="venueAttributes"
              value={key}
              label={<Localized id={value as any} />}
              variant={variant}
            />
          ))}
      </Stack>
    </Form>
  )
}

export const DesktopVenueAttributesFilter: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { venueAttributeActiveFilters } = useDiscoverState()

  const venueAttributeActiveFiltersTranslationKeys = venueAttributeActiveFilters
    ? venueAttributeActiveFilters
        .map((activeFilter) => {
          return VenueAttributesMapping[activeFilter]
        })
        .reverse()
    : []

  const numberOfPossibleFilters = Object.keys(VenueAttributesMapping).length
  const areAllFiltersSelected = venueAttributeActiveFiltersTranslationKeys.length === numberOfPossibleFilters

  return (
    <FilterMenu
      header={<Localized id={filterHeadingKey} />}
      activeFilters={
        !areAllFiltersSelected ? venueAttributeActiveFiltersTranslationKeys : ['filters-studio-all-selected']
      }
    >
      <VenueAttributesFilterForm />
    </FilterMenu>
  )
}

export const MobileVenueAttributesFilter = () => {
  return (
    <CollapsibleFilter header={<Localized id={filterHeadingKey} />}>
      <VenueAttributesFilterForm />
    </CollapsibleFilter>
  )
}

export const MobileVenueAttributesFilterDrawer = () => {
  const discoverState = useDiscoverState()
  const filterValues = discoverState[FilterKey.VENUE_ATTRIBUTES]

  const dispatch = useDiscoverReducer()
  const resetFilter = () => {
    dispatch({ type: 'UPDATE_ATTRIBUTES_FILTERS', payload: [] })
  }

  return (
    <FilterDrawer
      header={<Localized id={filterHeadingKey} />}
      selectedFilterValues={filterValues}
      onReset={resetFilter}
    >
      <VenueAttributesFilterForm variant="drawer" />
    </FilterDrawer>
  )
}
