import * as React from 'react'

import PrimaryMapPin from '../assets/map-pin.svg'

interface Props {
  top: string
  left: string
}

const OffsetMapPin = ({ top, left }: Props) => (
  <img
    src={PrimaryMapPin}
    width="28"
    height="36"
    alt=""
    loading="lazy"
    style={{
      position: 'absolute',
      top,
      left,
    }}
  />
)

export default OffsetMapPin
