import * as React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Stack from '@eversports/klimt-primitives/Stack'
import RadioGroup from '@eversports/klimt-primitives/RadioGroup'
import Processing from '@eversports/klimt-primitives/Processing'
import Box from '@eversports/klimt-primitives/Box'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { MatchStatus, MatchVisibility, UserType } from '../../../Match.types'
import MatchSettingsModal from '../../MatchSettings/MatchSettingsModal'
import { useMatchVisibilityToggleMatchQuery, useMatchVisibilityToggleUpdateMatchMutation } from '../../../../../graphql'
import checkDesiredMatchVisibilityValidity from '../../helpers/check-desired-match-visibility-validity'
import useLoggedInUserType from '../../../hooks/use-logged-in-user-type'
import useMatchStatus from '../../../hooks/use-match-status'
import PublicMatchCreatedDisclaimer from '../../PublicMatchCreatedDisclaimer'

import MatchVisibilityToggleRadioButton from './MatchVisibilityToggleRadioButton'
import PrivateMatchToggledSnackbar from './PrivateMatchToggledSnackbar'

const MatchVisibilityToggle = () => {
  const { id } = useParams<{ id: string }>()
  const { amplitude } = useAmplitude()
  const { userType } = useLoggedInUserType()
  const matchStatus = useMatchStatus()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [updatedVisibility, setUpdatedVisibility] = useState<MatchVisibility | null>(null)

  const { data } = useMatchVisibilityToggleMatchQuery({ variables: { matchId: id } })
  const [desiredVisibility, setDesiredVisibility] = useState<MatchVisibility | undefined>(data?.match.visibility)
  const [updateVisibility, { loading }] = useMatchVisibilityToggleUpdateMatchMutation()
  const isDisabled = matchStatus !== MatchStatus.UPCOMING

  if (userType !== UserType.ORGANIZER) return null

  const handleClick = (clickedVisibility: MatchVisibility) => {
    if (isDisabled) return

    setDesiredVisibility(clickedVisibility)

    if (!data || data?.match.visibility === clickedVisibility || clickedVisibility === desiredVisibility) return

    if (amplitude) {
      amplitude.logEvent('Clicked Match Visibility Toggle', { clickedVisibility })
    }

    const state = {
      level: data?.match.level,
      competitionType: data?.match.competitionType,
      gender: data?.match.gender,
      maxParticipants: data?.match.maxParticipants,
      visibility: data?.match.visibility,
    }

    const isClickedVisibilityValid = checkDesiredMatchVisibilityValidity({
      state,
      desiredVisibility: clickedVisibility,
      validations: data.match.matchVisibilityValidations,
    })

    if (isClickedVisibilityValid) {
      void updateVisibility({
        variables: { matchId: id, fields: { visibility: clickedVisibility } },
        onCompleted: () => {
          if (amplitude) {
            amplitude.logEvent('Updated Match Settings', { ...state, visibility: clickedVisibility })
          }
          setUpdatedVisibility(clickedVisibility)
        },
      })
    } else {
      setIsModalOpen(true)
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    // Reset desired visibility to current visibility if the user dismissed the modal and the visibility hasn't changed
    if (desiredVisibility !== data?.match.visibility) {
      setDesiredVisibility(data?.match.visibility)
    }
  }

  const venueSlug = data?.match.venue.slug
  const bookingState = data?.match.state

  return (
    <>
      <RadioGroup name="visibility" value={data?.match.visibility}>
        <Stack direction="row" gap={4} alignItems="center" justifyContent={['initial', 'flex-end']}>
          <Box flex={['1', 'initial']}>
            <Processing isLoading={loading && (data?.match.visibility as MatchVisibility) === MatchVisibility.PUBLIC}>
              <MatchVisibilityToggleRadioButton
                value={MatchVisibility.PRIVATE}
                currentVisibility={data?.match.visibility}
                onClick={() => handleClick(MatchVisibility.PRIVATE)}
                isDisabled={isDisabled}
              />
            </Processing>
          </Box>
          <Box flex={['1', 'initial']}>
            <Processing isLoading={loading && (data?.match.visibility as MatchVisibility) === MatchVisibility.PRIVATE}>
              <MatchVisibilityToggleRadioButton
                value={MatchVisibility.PUBLIC}
                currentVisibility={data?.match.visibility}
                onClick={() => handleClick(MatchVisibility.PUBLIC)}
                isDisabled={isDisabled}
              />
            </Processing>
          </Box>
        </Stack>
      </RadioGroup>
      {desiredVisibility && (
        <MatchSettingsModal isOpen={isModalOpen} onClose={handleCloseModal} desiredVisibility={desiredVisibility} />
      )}
      {venueSlug && (
        <PublicMatchCreatedDisclaimer
          venueSlug={venueSlug}
          bookingState={bookingState}
          matchWasPublished={updatedVisibility === MatchVisibility.PUBLIC}
        />
      )}
      <PrivateMatchToggledSnackbar
        isOpen={updatedVisibility === MatchVisibility.PRIVATE}
        onClose={() => setUpdatedVisibility(null)}
      />
    </>
  )
}

export default MatchVisibilityToggle
