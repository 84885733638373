import { MonetaryValue } from '../../../../../App.types'
import { PurchaseOption } from '../../../ActivityDetails.types'

const getProductMonetaryValue = (product: PurchaseOption): MonetaryValue => {
  switch (product.__typename) {
    case 'Membership':
      return product.paymentPlan.recurring
    case 'Ticket':
      return product.standardPrice
    case 'Card':
    case 'SinglePurchase':
      return product.price
  }
}

function getCheapestProduct(purchaseOptions: Array<PurchaseOption>): MonetaryValue | undefined {
  return purchaseOptions.reduce<MonetaryValue | undefined>((cheapestProduct, purchaseOption) => {
    const currentPrice = getProductMonetaryValue(purchaseOption)

    if (cheapestProduct === undefined || currentPrice.value < cheapestProduct.value) {
      return currentPrice
    }

    return cheapestProduct
  }, undefined)
}

export default getCheapestProduct
