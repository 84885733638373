import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Stack from '@eversports/klimt-primitives/Stack'

const FriendUpcomingParticipationCard = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <Stack
      sx={{
        padding: 4,
        borderRadius: { xs: 0, md: 1 },
        boxShadow: boxShadows.default,
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'mediumgray',
        gap: 2,
      }}
    >
      {children}
    </Stack>
  )
}

export default FriendUpcomingParticipationCard
