import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import SeoLink from '@eversports/klimt-primitives/SeoLink'

import { Localized } from '../../localization/react'

import { AuthRoutes } from './UserMenu/UserMenu.constants'

interface Props {
  authUrlParams: string
}

const LoginButton = ({ authUrlParams }: Props) => (
  <SeoLink
    type="crawler"
    encodedTo={btoa(`${AuthRoutes.LOGIN}${authUrlParams}`)}
    crawlerLink={AuthRoutes.LOGIN}
    external
  >
    <Button variant="secondary" sx={{ flex: 1 }}>
      <Text variant="small" sx={{ fontWeight: 'bold', color: 'primary.main', textTransform: 'none' }}>
        <Localized id="user-menu-item-login" />
      </Text>
    </Button>
  </SeoLink>
)

export default LoginButton
