import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Link from '@eversports/klimt-primitives/Link'
import { useParams } from 'react-router-dom'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../localization/react'
import Card from '../components/Card'
import { useDetailsSectionMatchQuery } from '../../../graphql'
import { Origin, VenueProfileTabsPrefix } from '../../../App.types'
import AdditionalActions from '../AdditionalActions'
import { getDurationInMinutes } from '../../../helpers/get-duration-in-minutes'
import { formatDate } from '../../../helpers/format-date'
import { formatTime } from '../../../helpers/format-time'
import useUrlParams from '../../../hooks/use-url-params'

import CourtDetails from './components/CourtDetails'
import OverflowText from './components/OverflowText'
import CourtPrice from './components/CourtPrice'
import MatchSettings from './MatchSettings'
import { MatchVisibilityBadge } from './MatchVisibility'

const DetailsSection = () => {
  const { id } = useParams<{ id: string }>()
  const { amplitude } = useAmplitude()
  const { language } = useSettingsContext()
  const { origin } = useUrlParams()
  const { data } = useDetailsSectionMatchQuery({ variables: { matchId: id } })

  if (!data) {
    return null
  }

  const { sport, venue, start, end, maxParticipants, court, price } = data.match

  const duration = getDurationInMinutes(start, end)
  const date = formatDate(language, start)
  const startTime = formatTime(language, start)
  const endTime = formatTime(language, end)

  const trackVenueLinkClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Venue Link')
    }
  }

  return (
    <Card sx={{ p: 4 }}>
      <Stack gap={6.5}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <Stack gap={1}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Text variant="large" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                {sport.name}
              </Text>
              <MatchVisibilityBadge />
            </Stack>
            <Stack gap={0.5}>
              {origin === Origin.WIDGET ? (
                <OverflowText>{venue.name}</OverflowText>
              ) : (
                <Link to={`${VenueProfileTabsPrefix.OVERVIEW}${venue.slug}`} ariaLabel="Venue Profile" blank>
                  <OverflowText isUnderlined onClick={trackVenueLinkClick}>
                    {venue.name}
                  </OverflowText>
                </Link>
              )}
              <Stack direction="row" flexWrap="wrap" gap={[1, 2]}>
                <Text variant="xsmall">{date}</Text>
                <Text variant="xsmall" sx={{ color: 'gray' }}>
                  •
                </Text>
                <Text variant="xsmall">
                  {startTime} - {endTime} (
                  <Localized id="match-duration-in-minutes-short" params={{ duration }} />)
                </Text>
              </Stack>
            </Stack>
          </Stack>
          <AdditionalActions />
        </Stack>
        <Stack direction="row" alignItems="flex-start" justifyContent={{ xs: 'center', sm: 'initial' }} gap={8}>
          {court && <CourtDetails court={court} />}
          {price && <CourtPrice price={price} maxParticipants={maxParticipants} />}
        </Stack>
        <MatchSettings />
      </Stack>
    </Card>
  )
}

export default DetailsSection
