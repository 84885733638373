import * as React from 'react'
import { useState, useEffect } from 'react'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Venue } from '../../ActivityDetails.types'

import ReviewsPopup from './ReviewsPopup'
import useVenueReviews from './hooks/useVenueReviews'
import ReviewsList from './ReviewList'
import NoReviews from './NoReviews'

const MOBILE_REVIEWS_TO_SHOW = 4
const DESKTOP_REVIEWS_TO_SHOW = 2
const ITERATE_BY = 2

interface Props {
  venue: Venue
}

const Reviews = ({ venue }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [scrollToId, setScrollToId] = useState<string | undefined>()

  const { amplitude } = useAmplitude()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { allReviews, hasMoreReviewsToFetch, fetchMoreReviews, newQueryData } = useVenueReviews({ venueId: venue.id })

  const shownReviews = isMobile ? allReviews?.slice(0, MOBILE_REVIEWS_TO_SHOW) : allReviews

  const isFirstPage = activeIndex === 0
  const reviewCount = allReviews.length
  const hasMoreReviews = reviewCount > (isMobile ? MOBILE_REVIEWS_TO_SHOW : DESKTOP_REVIEWS_TO_SHOW)

  const logAmplitudeEvent = () => {
    if (amplitude) {
      amplitude.logEvent('Triggered More Venue Reviews')
    }
  }

  const handleBack = () => {
    if (isFirstPage) return
    setActiveIndex((prevIndex) => {
      if (prevIndex - ITERATE_BY < 0) {
        return reviewCount - ITERATE_BY
      }
      return prevIndex - ITERATE_BY
    })
  }

  const handleForward = () => {
    logAmplitudeEvent()

    let nextIndex = activeIndex + ITERATE_BY
    const maxIndex = reviewCount - ITERATE_BY

    if (nextIndex > maxIndex && !hasMoreReviewsToFetch) {
      nextIndex = 0
    }

    // If we're near the end, fetch more reviews
    const indexForNextFetch = reviewCount - ITERATE_BY * 2

    if (nextIndex >= indexForNextFetch && hasMoreReviewsToFetch) {
      const cursor = allReviews[reviewCount - 1]?.cursor
      void fetchMoreReviews(cursor)
    }

    setActiveIndex(nextIndex)
  }

  const handleShowMore = () => {
    logAmplitudeEvent()
    const cursor = allReviews[reviewCount - 1]?.cursor
    void fetchMoreReviews(cursor)
  }

  const togglePopup = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleCardClick = (cardId: string) => () => {
    setScrollToId(cardId)
    togglePopup()
  }

  // If new data has come in, and the modal is open, then scroll to the newly fetched reviews.
  useEffect(() => {
    if (!newQueryData || !isModalOpen) return

    const firstNewReviewId = newQueryData.reviews.edges?.[0]?.node.id

    if (firstNewReviewId) {
      setScrollToId(firstNewReviewId)
    }
  }, [newQueryData])

  if (!allReviews.length) {
    return <NoReviews venue={venue} />
  }

  return (
    <>
      <ReviewsList
        shownReviews={shownReviews}
        hasMoreReviews={hasMoreReviews}
        handleCardClick={handleCardClick}
        handleBack={handleBack}
        handleForward={handleForward}
        activeIndex={activeIndex}
        venue={venue}
        togglePopup={togglePopup}
      />
      <ReviewsPopup
        reviews={allReviews}
        isOpen={isModalOpen}
        togglePopup={togglePopup}
        scrollToId={scrollToId}
        handleShowMore={handleShowMore}
        hasMoreReviews={Boolean(hasMoreReviewsToFetch)}
      />
    </>
  )
}

export default Reviews
