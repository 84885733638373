import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../../../../localization/react'
import { Match } from '../../../../../App.types'
import MatchCard, { MatchSkeleton } from '../../../../../components/MatchCard'

import { NUMBER_OF_INITIAL_MATCHES_FETCHED } from './Matches.constants'

interface Props {
  matches: Array<Match>
  loadingFilterMatches: boolean
  loadingMoreMatches: boolean
  hasMoreResults?: boolean
  onFetchMoreMatches: () => void
}

const Matches = ({ matches, loadingFilterMatches, loadingMoreMatches, hasMoreResults, onFetchMoreMatches }: Props) => {
  return (
    <Stack gap={[2, 4]} paddingY={{ xs: 2, md: 0 }}>
      {loadingFilterMatches || matches.length > 0 ? (
        <Box display="grid" gap={{ xs: 3, md: 4 }} gridTemplateColumns={{ xs: '1fr', md: 'repeat(4, 1fr)' }}>
          {loadingFilterMatches ? (
            <MatchSkeleton numberOfCopies={NUMBER_OF_INITIAL_MATCHES_FETCHED} />
          ) : (
            <>
              {matches.map((match) => (
                <MatchCard key={match.id} match={match} variant="discovery" />
              ))}
              {loadingMoreMatches && <MatchSkeleton numberOfCopies={NUMBER_OF_INITIAL_MATCHES_FETCHED} />}
            </>
          )}
        </Box>
      ) : (
        <Text sx={{ fontWeight: 'bold' }}>
          <Localized id="no-matches-found" />
        </Text>
      )}
      {hasMoreResults && (
        <Button
          variant="tertiary"
          onClick={onFetchMoreMatches}
          sx={{ padding: 3, margin: 'auto', width: { xs: '100%', sm: '50%' }, maxWidth: '448px' }}
        >
          <Text sx={{ fontWeight: 'bold', textTransform: 'none' }}>
            <Localized id="load-more" />
          </Text>
        </Button>
      )}
    </Stack>
  )
}

export default Matches
