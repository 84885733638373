import * as React from 'react'
import KlimtDrawer, { DrawerProps } from '@eversports/klimt-primitives/Drawer'

const Drawer = ({ children, sx, ...rest }: DrawerProps) => {
  return (
    <KlimtDrawer
      anchor="bottom"
      sx={{
        '& .MuiDrawer-paper': {
          borderRadius: '16px 16px 0px 0px',
          p: 4,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </KlimtDrawer>
  )
}

export default Drawer
