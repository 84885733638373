import * as React from 'react'
import { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useDebounce from '@eversports/klimt-utilities/use-debounce'

import {
  SportSearchResult,
  VenueSearchResult,
  SearchProps,
  InventorySearchResult,
  CitySearchResult,
} from '../Search.types'
import useRecentSearches, { SearchType } from '../hooks/useRecentSearches'
import {
  ALL_SPORTS_CITY_RESULT,
  DESKTOP_NUMBER_OF_SHOWN_RESULTS,
  MOBILE_NUMBER_OF_SHOWN_RESULTS,
  NUMBER_OF_QUERY_RESULTS,
} from '../Search.constants'
import {
  useSearchInventorySearchLazyQuery,
  useSearchDiscoverContextByCityAndSportSlugLazyQuery,
} from '../../../graphql'
import getInventoryResultLink from '../helpers/get-inventory-result-link'
import { filterAndFormatInventoryRecentSearches } from '../helpers/format-and-filter-recent-searches'
import filterDatabaseResults from '../helpers/filter-database-results'
import { DiscoverPrefix } from '../../../App.types'

import SearchInventoryComponent from './SearchInventoryComponent'
import { trackInventorySearch } from './helpers/track-inventory.search'

interface Props extends SearchProps {
  location: CitySearchResult
  isDefaultOpen: boolean
}

const SearchInventory = ({ location, hasInputLabels, searchPosition, hasSearchButton, isDefaultOpen }: Props) => {
  const [searchValue, setSearchValue] = useState('')

  const { pathname } = useLocation()
  const history = useHistory()
  const { amplitude } = useAmplitude()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { sportSlug, citySlug } = useParams<{ sportSlug?: string; citySlug?: string }>()

  const [getSportName, { data: sportData }] = useSearchDiscoverContextByCityAndSportSlugLazyQuery()

  const { recentSearches, updateRecentSearches } = useRecentSearches<SportSearchResult | VenueSearchResult>(
    SearchType.INVENTORY,
  )
  const shownRecentSearches = filterAndFormatInventoryRecentSearches({
    recentSearches,
    searchValue,
    location,
  })

  const [getInventorySearchResults, { data, previousData, loading: isQueryLoading }] =
    useSearchInventorySearchLazyQuery()

  const debouncedServerCall = useDebounce(
    (value: string) =>
      void getInventorySearchResults({
        variables: {
          coordinate: { latitude: location.coordinate.latitude, longitude: location.coordinate.longitude },
          searchTerm: value?.toLowerCase(),
          limit: NUMBER_OF_QUERY_RESULTS,
        },
      }),
    150,
  )

  const queryResult = data?.inventorySearch ?? previousData?.inventorySearch ?? undefined

  const allSportsInCityResult = {
    ...ALL_SPORTS_CITY_RESULT,
    cityId: location.id,
    cityName: location.name,
    citySlug: location.slug,
  }

  const filteredQueryResults = filterDatabaseResults<SportSearchResult | VenueSearchResult>({
    databaseResults: queryResult,
    recentSearches: shownRecentSearches,
  })

  const results: Array<InventorySearchResult> = [...(shownRecentSearches ?? []), ...(filteredQueryResults ?? [])]

  const NUMBER_OF_SHOWN_RESULTS = isMobile ? MOBILE_NUMBER_OF_SHOWN_RESULTS : DESKTOP_NUMBER_OF_SHOWN_RESULTS

  const shownResults = [
    ...(!searchValue ? [allSportsInCityResult] : []),
    ...results.slice(0, NUMBER_OF_SHOWN_RESULTS - 1),
    ...(searchValue && (results.length || isQueryLoading) ? [allSportsInCityResult] : []),
  ]

  const handleResultClick = (searchResult: InventorySearchResult) => {
    if (searchResult && searchResult.__typename !== 'AllSportsInCity') {
      updateRecentSearches({ key: SearchType.INVENTORY, value: searchResult })
    }
    trackInventorySearch({ result: searchResult, searchTerm: searchValue, location, amplitude })
  }

  const handleResultEnter = (searchResult: InventorySearchResult) => {
    handleResultClick(searchResult)

    if (!searchResult && searchValue) {
      window.location.href = `${DiscoverPrefix.APP_SPORT_CITY}/${encodeURIComponent(searchValue)}/${location.slug}`
    } else {
      const goToUrl = getInventoryResultLink(searchResult)
      history.push(goToUrl)
    }
  }

  useEffect(() => {
    const currentSportName = sportData?.discoverContextByCityAndSportSlug.sport.name

    if (isDefaultOpen) {
      debouncedServerCall(searchValue)
      return
    }

    // Don't make an extra server call if they're on that page.
    if (currentSportName && currentSportName === searchValue) return

    if (searchValue) {
      debouncedServerCall(searchValue)
    }
  }, [searchValue, isDefaultOpen])

  useEffect(() => {
    setSearchValue('')
  }, [pathname])

  useEffect(() => {
    if (sportSlug && citySlug) {
      void getSportName({ variables: { sportSlug, citySlug } })
    }
  }, [sportSlug, citySlug])

  // When on a discover sport city page, populate the search bar with the current sport.
  useEffect(() => {
    if (sportData) {
      const sportName = sportData.discoverContextByCityAndSportSlug.sport.name
      setSearchValue(sportName)
    }
  }, [sportData])

  return (
    <SearchInventoryComponent
      searchPosition={searchPosition}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      results={shownResults}
      handleResultClick={handleResultClick}
      handleResultEnter={handleResultEnter}
      hasInputLabels={hasInputLabels}
      hasSearchButton={hasSearchButton}
      location={location}
      isLoading={isQueryLoading}
      isDefaultOpen={isDefaultOpen}
    />
  )
}

export default SearchInventory
