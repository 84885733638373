import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import { Localized } from '../../localization/react'
import { LoggedInUser } from '../../hooks/useLoggedInUser'
import { From, ManagerLinks as ManagerLinksProps } from '../../App.types'
import FriendsConnectionButton from '../FriendsConnectionButton'

import CorporateBenefits from './CorporateBenefits'
import DesktopUserMenuDropdownComponent from './UserMenu/DesktopUserMenuDropdownComponent'
import { ColorContrast } from './Header.types'
import { NotificationsButton } from './NotificationsButton'

interface Props extends ManagerLinksProps {
  user: LoggedInUser
  authUrlParams: string
  colorContrast?: ColorContrast
}

const DesktopHeaderItems = ({ managerLinks, user, authUrlParams, colorContrast = ColorContrast.NORMAL }: Props) => {
  const textColor = colorContrast === ColorContrast.NORMAL ? 'darkgray' : 'white'
  const fontWeight = colorContrast === ColorContrast.NORMAL ? 'normal' : 'bold'

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  if (user.data) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        gap={6}
        flex="1"
        display={{ xs: 'none', lg: 'flex' }}
      >
        {!isMobile && <NotificationsButton colorContrast={colorContrast} />}
        <FriendsConnectionButton
          colorContrast={colorContrast}
          to={`/user/${user.data.handle}/friends?from=${From.HEADER}`}
        />
        <CorporateBenefits corporateBenefits={user.data.corporateBenefits} colorContrast={colorContrast} />
        <DesktopUserMenuDropdownComponent user={user} authUrlParams={authUrlParams} colorContrast={colorContrast} />
      </Stack>
    )
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      gap={4}
      flex="1"
      display={{ xs: 'none', lg: 'flex' }}
    >
      <Link to={managerLinks.corporate} external blank ariaLabel="Eversports corporate program">
        <Text sx={{ color: textColor, fontWeight }}>
          <Localized id="header-for-corporates" />
        </Text>
      </Link>
      <Text variant="small" sx={{ color: textColor }}>
        |
      </Text>
      <Link to={managerLinks.business} external blank ariaLabel="Eversports business offerings">
        <Text sx={{ color: textColor, fontWeight }}>
          <Localized id="header-for-businesses" />
        </Text>
      </Link>
      <DesktopUserMenuDropdownComponent user={user} authUrlParams={authUrlParams} colorContrast={colorContrast} />
    </Stack>
  )
}

export default DesktopHeaderItems
