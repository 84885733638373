import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

interface Props {
  imgSrc: string
  iconSrc: string
  title: string
  description: string
}

const FeatureCard = ({ imgSrc, iconSrc, title, description }: Props) => (
  <Stack
    component="li"
    sx={{
      padding: 4,
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'mediumgray',
      flex: 1,
      width: '100%',
    }}
  >
    <img
      src={imgSrc}
      alt=""
      loading="lazy"
      width={334}
      height={140}
      style={{
        width: '100%',
        minHeight: '140px',
        objectFit: 'cover',
        border: '1px solid',
        borderColor: '#E0E0E0',
        borderRadius: '8px',
      }}
    />
    <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 2, paddingTop: 2 }}>
      <img src={iconSrc} alt="" loading="lazy" width={16} height={16} />
      <Text variant="large" color="midnight" sx={{ fontWeight: 'bold' }}>
        {title}
      </Text>
    </Stack>
    <Text variant="large" sx={{ paddingTop: 1 }}>
      {description}
    </Text>
  </Stack>
)

export default FeatureCard
