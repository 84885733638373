import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'

import SectionWrapper from '../../components/SectionWrapper'
import { Rating, Review } from '../../VenueProfile.types'
import { Localized } from '../../../../localization/react'
import { VENUE_PROFILE_RATING_ID } from '../../VenueProfile.constants'
import { ScrollAnchor } from '../../../../components/ScrollAnchor'

import Categories from './Categories'
import Comments from './Comments'

interface Props {
  rating: number | null
  reviewCount: number
  headerOffset: number
  ratings: Array<Rating>
  reviews: Array<Review>
  id: string
}

const Reviews = ({ rating, reviewCount, ratings, reviews, headerOffset, id }: Props) => {
  return (
    <SectionWrapper>
      <ScrollAnchor hash={VENUE_PROFILE_RATING_ID} topOffset={-headerOffset} />
      <Heading is="h2" color="midnight">
        <Localized id="venue-profile-title-reviews" />
      </Heading>
      <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 8, lg: '10%' }}>
        <Categories reviewCount={reviewCount} rating={rating} ratings={ratings} />
        <Comments reviews={reviews} id={id} />
      </Stack>
    </SectionWrapper>
  )
}

export default Reviews
