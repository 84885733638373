import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../../localization/react'

const Friends = () => {
  return (
    <Text sx={{ color: 'gray', textAlign: 'center', paddingY: { xs: 3, md: 2 }, paddingX: 6 }}>
      <Localized id="friends" />
    </Text>
  )
}

export default Friends
