import { default as React, useEffect, useState } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import { motion } from '@eversports/klimt-utilities/framer-motion'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import { TRANSITION_FOR_MAP_ANIMATION } from '../../../App.constants'
import { useDiscoverState } from '../DiscoverContext'
import useGetTopContentHeight from '../../../hooks/useGetTopContentHeight'
import { getTopContentElements } from '../helpers/get-top-content-elements'

const listingVariants = {
  openListing: {
    transform: 'translateX(0%)',
    marginRight: '0vw',
  },
  closeListing: {
    transform: 'translateX(-100%)',
    marginRight: '-100vw',
  },
}

interface Props {
  isListingOpen: boolean
  hasOfferings?: boolean
}

const ListingWrapperWithAnimation = ({
  isListingOpen,
  children,
  hasOfferings = false,
}: React.PropsWithChildren<Props>) => {
  const theme = useTheme()
  const { showMobileMapView } = useDiscoverState()
  const [windowInnerHeight, setWindowInnerHeight] = useState(0)
  const isInMobileView = useMediaQuery(theme.breakpoints.down('lg'))
  let topContentElements = getTopContentElements()

  if (!topContentElements.some((element) => element === 'OFFERINGS') && hasOfferings) {
    topContentElements = [...topContentElements, 'OFFERINGS']
  }

  const { MOBILE_TOP_CONTENT_HEIGHT, DESKTOP_TOP_CONTENT_HEIGHT } = useGetTopContentHeight(topContentElements)

  const topOffset = isInMobileView ? MOBILE_TOP_CONTENT_HEIGHT : DESKTOP_TOP_CONTENT_HEIGHT

  useEffect(() => {
    setWindowInnerHeight(window.innerHeight)
  }, [])

  return (
    <Box
      sx={{
        width: { xs: '100vw', lg: '55vw' },
        height: { lg: '100%' },
        minHeight: `calc(${windowInnerHeight ? `${windowInnerHeight}px` : '100vh'} - ${topOffset}px)`,
        marginRight: isInMobileView && showMobileMapView ? '-100%' : 'none',
        transform: isInMobileView && showMobileMapView ? 'translateX(-100%)' : 'none',
        backgroundColor: { xs: 'eggshell', lg: 'limelite' },
        contentVisibility: 'auto',
      }}
    >
      {isInMobileView ? (
        <motion.div style={{ overflowY: 'scroll', height: '100%' }}>{children}</motion.div>
      ) : (
        <motion.div
          initial={false}
          variants={listingVariants}
          animate={isListingOpen ? 'openListing' : 'closeListing'}
          style={{ width: 'inherit' }}
          transition={TRANSITION_FOR_MAP_ANIMATION}
        >
          {children}
        </motion.div>
      )}
    </Box>
  )
}

export default ListingWrapperWithAnimation
