export enum AuthRoutes {
  LOGIN = '/auth',
  REGISTER = '/auth/register',
}

export enum UserProfileRoutes {
  HOME = '/',
  RECOMMENDATIONS = '/u/recommendations',
  MY_BOOKINGS = '/u',
  MY_VIDEOS = '/u/videos',
  MY_PASSES = '/u/credits',
  MY_FAVORITES = '/u/facilities',
  MY_INFO = '/u/edit',
  MY_PAYMENT_METHODS = '/u/payment-methods',
  MY_CORPORATE_BENEFITS = '/u/corporate-benefits',
  SIGNOUT = '/u/logout',
  NOTIFICATIONS = '/u/notifications',
}
