import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import MonetaryValue from '@eversports/klimt-localization/MonetaryValue'
import Text from '@eversports/klimt-primitives/Text'

import { PurchaseOption } from '../../ActivityDetails.types'
import { Localized } from '../../../../localization/react'

import TicketPriceText from './TicketPriceText'
import { getCorporateSpecial } from './helpers/get-corporate-special'

interface Props {
  purchaseOption: PurchaseOption
  isCorporateUser: boolean
}

const PriceText = ({ purchaseOption, isCorporateUser }: Props) => {
  switch (purchaseOption.__typename) {
    case 'SinglePurchase':
      return (
        <Text color="midnight" sx={{ fontWeight: { xs: 'bold', sm: 'normal' } }}>
          <MonetaryValue value={purchaseOption.price.value} currencyCode={purchaseOption.price.currency} />
        </Text>
      )
    case 'Card': {
      const corporateSpecial = getCorporateSpecial(purchaseOption)
      if (isCorporateUser && corporateSpecial && corporateSpecial.price) {
        return (
          <Stack direction="row" gap={2}>
            <Text color="midnight" sx={{ fontWeight: { xs: 'bold', sm: 'normal' } }}>
              <MonetaryValue value={corporateSpecial.price.value} currencyCode={corporateSpecial.price.currency} />
            </Text>
            <Text color="gray" sx={{ textDecoration: 'line-through' }}>
              <MonetaryValue value={purchaseOption.price.value} currencyCode={purchaseOption.price.currency} />
            </Text>
          </Stack>
        )
      }
      return (
        <Text color="midnight" sx={{ fontWeight: { xs: 'bold', sm: 'normal' } }}>
          <MonetaryValue value={purchaseOption.price.value} currencyCode={purchaseOption.price.currency} />
        </Text>
      )
    }
    case 'Membership': {
      const { paymentPlan } = purchaseOption
      const { recurring, interval, promotion } = paymentPlan
      return (
        <Stack direction="row" gap={2}>
          {promotion ? (
            <Stack direction="column" gap={1}>
              <Text color="midnight" sx={{ fontWeight: { xs: 'bold', sm: 'normal' } }}>
                <Localized
                  id="activity-details-membership-price"
                  params={{
                    recurringValue: { value: promotion.price.value / 100, currency: promotion.price.currency },
                    intervalType: interval.type,
                    intervalValue: interval.value,
                  }}
                />
              </Text>
              <Text color="gray" sx={{ textDecoration: 'line-through' }}>
                <Localized
                  id="activity-details-membership-price"
                  params={{
                    recurringValue: { value: recurring.value / 100, currency: recurring.currency },
                    intervalType: interval.type,
                    intervalValue: interval.value,
                  }}
                />
              </Text>
            </Stack>
          ) : (
            <Text color="midnight" sx={{ fontWeight: { xs: 'bold', sm: 'normal' } }}>
              <Localized
                id="activity-details-membership-price"
                params={{
                  recurringValue: { value: recurring.value / 100, currency: recurring.currency },
                  intervalType: interval.type,
                  intervalValue: interval.value,
                }}
              />
            </Text>
          )}
        </Stack>
      )
    }
    case 'Ticket': {
      return <TicketPriceText ticket={purchaseOption} />
    }
  }
}

export default PriceText
