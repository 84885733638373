import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import FilledStar from '@eversports/design-tokens/assets/icons/filled-star.svg'
import EmptyStar from '@eversports/design-tokens/assets/icons/empty-star.svg'

import { Localized } from '../../../../localization/react'

interface Props {
  rating: number | null
  reviewCount: number
}

const Rating: React.FC<React.PropsWithChildren<Props>> = ({ rating, reviewCount }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <img src={reviewCount ? FilledStar : EmptyStar} alt="rating" loading="lazy" width="14px" height="14px" />
      <Text variant="small" sx={{ color: 'darkgray', fontWeight: 'bold' }}>
        {rating ? (
          <Localized id="rating-review-count" params={{ rating, reviewCount }} />
        ) : (
          <Localized id="rating-no-reviews" />
        )}
      </Text>
    </Stack>
  )
}

export default Rating
