import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

import { TagBadge } from '../Badge'
import { Tag } from '../../../../App.types'

interface Props {
  tags: Array<Tag>
}

const VenueTags = ({ tags }: Props) => {
  return (
    // Setting a minHeight, so it always occupies this space even if no tag exists
    <Stack direction="row" spacing={2} sx={{ minHeight: '29px' }}>
      {tags.map(({ name }) => (
        <TagBadge key={name}>{name}</TagBadge>
      ))}
    </Stack>
  )
}

export default VenueTags
