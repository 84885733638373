import * as React from 'react'
import { useEffect } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Drawer from '@eversports/klimt-primitives/Drawer'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import { Localized } from '../../localization/react'

import MobileDrawerSearchInput from './SearchInput/MobileDrawerSearchInput'

interface Props {
  isOpen: boolean
  closeResults: () => void
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  isLoading?: boolean
  inputLabel: string
}

const MobileDrawerSearch = ({
  children,
  isOpen,
  closeResults,
  searchValue,
  setSearchValue,
  isLoading,
  inputLabel,
}: React.PropsWithChildren<Props>) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // Disable background scroll
  useEffect(() => {
    if (!isMobile) return

    document.body.style.position = isOpen ? 'fixed' : 'static'
    document.body.style.height = isOpen ? '100%' : 'auto'
    return () => {
      document.body.style.position = 'static'
      document.body.style.height = 'auto'
    }
  }, [isOpen, isMobile])

  return (
    <Drawer
      open={isOpen && isMobile}
      onClose={closeResults}
      anchor="bottom"
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': {
          border: '1px solid',
          borderColor: 'lightgray',
          borderRadius: '16px 16px 0px 0px',
          height: '80vh', // Grow the content so that it is visible above the iPhone keyboard.
          padding: 4,
          paddingTop: 0,
        },
      }}
    >
      <Stack gap={2} paddingY={4} sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
        <Stack direction="row">
          <span style={{ flexBasis: '10%' }} />
          <Text variant="large" sx={{ fontWeight: 'bold', textAlign: 'center', flexBasis: '80%' }}>
            {inputLabel}
          </Text>
          <Text onClick={closeResults} color="primary.main" sx={{ flexBasis: '10%' }}>
            <Localized id="search-close-mobile-drawer" />
          </Text>
        </Stack>
        <MobileDrawerSearchInput searchValue={searchValue} setSearchValue={setSearchValue} isLoading={isLoading} />
      </Stack>
      <Box sx={{ overflowY: 'scroll' }}>{children}</Box>
    </Drawer>
  )
}
export default MobileDrawerSearch
