import * as React from 'react'
import { Color } from '@eversports/design-tokens/palette'

import Svg, { SvgProps } from '@eversports/klimt-primitives/Svg'

const sizeVariants = {
  'x-small': 8,
  small: 16,
  default: 20,
  large: 32,
  'x-large': 48,
}

type Size = keyof typeof sizeVariants

interface Props extends Omit<SvgProps, 'width' | 'height'> {
  size: Size
  color: Color
}

const Icon = ({ size, ...rest }: Props) => {
  const sizeValue = sizeVariants[size]

  return <Svg width={sizeValue} height={sizeValue} {...rest} />
}

export default Icon
