import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'

import { From } from '../../../../App.types'
import { PublicUser } from '../../PublicUserProfile.types'
import UserInfo from '../../../../components/UserInfo'

import FriendshipButton from './FriendshipButton'

interface Props {
  user: PublicUser
  from: From
}

const PublicUser = ({ user, from }: Props) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <UserInfo
        profilePicture={user.profilePicture?.small}
        firstName={user.firstName}
        lastName={user.lastName}
        handle={user.handle}
        from={from}
      />
      <Box sx={{ width: '150px', display: 'flex', justifyContent: 'end' }}>
        <FriendshipButton friend={user} />
      </Box>
    </Stack>
  )
}

export default PublicUser
