import * as React from 'react'
import { Tab, TabProps } from '@eversports/klimt-primitives/Tabs'

const FriendsTab = (props: TabProps) => {
  return (
    <Tab
      disableRipple
      sx={{
        flex: { xs: 'none', sm: 1 },
        textAlign: 'left',
        textTransform: 'none',
        color: 'carbon',
        paddingBottom: 4,
        paddingX: 2,
        fontSize: '14px',
        '&.Mui-selected': {
          backgroundColor: 'white',
          color: 'primary.main',
          fontWeight: 'bold',
        },
      }}
      {...props}
    />
  )
}

export default FriendsTab
