import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Divider from '@eversports/klimt-primitives/Divider'

import TeacherCardButton from '../../../components/TeacherCard/TeacherCardButton'
import TeacherCardContainer from '../../../components/TeacherCard/TeacherCardContainer'

import { Props } from './TeacherCard'
import TeacherCardInfo from './TeacherCardInfo'

const DisabledTeacherCard = ({ teacher, venue }: Props) => (
  <TeacherCardContainer>
    <Stack gap={4}>
      <TeacherCardInfo teacher={teacher} venue={venue} />
      <Divider sx={{ borderColor: 'ligthgray' }} />
      <TeacherCardButton isActiveAtVenue={false} />
    </Stack>
  </TeacherCardContainer>
)

export default DisabledTeacherCard
