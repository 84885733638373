import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Skeleton from '@eversports/klimt-primitives/Skeleton'

export const SuggestedFriendsSkeleton = () => {
  return (
    <Skeleton variant="rectangular" sx={{ minWidth: '190px', flex: 1, padding: 4, height: '194px', borderRadius: 1 }} />
  )
}

const SuggestedFriendsPlaceholder = () => {
  return (
    <Stack gap={2} marginBottom={4}>
      <Stack direction="row" sx={{ overflowX: 'scroll' }} gap={2}>
        <SuggestedFriendsSkeleton />
        <SuggestedFriendsSkeleton />
        <SuggestedFriendsSkeleton />
      </Stack>
    </Stack>
  )
}

export default SuggestedFriendsPlaceholder
