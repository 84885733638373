import { UserType } from '../FriendshipButton.types'
import {
  useUseFriendOfUserRelationshipTypeMeQuery,
  useUseFriendOfUserRelationshipTypPublicUserQuery,
} from '../../../../../../graphql'

interface Props {
  userHandle: string
}

const useFriendOfUserRelationshipType = ({ userHandle }: Props) => {
  const { data: friendData } = useUseFriendOfUserRelationshipTypPublicUserQuery({ variables: { handle: userHandle } })
  const { data: meData } = useUseFriendOfUserRelationshipTypeMeQuery()

  if (!friendData || !meData) {
    return { UserType: null }
  }

  if (friendData?.publicUser.id === meData?.me?.id) {
    return { userType: UserType.SELF }
  }

  if (friendData?.publicUser.isYourFriend) {
    return { userType: UserType.FRIENDS }
  }

  if (friendData?.publicUser.pendingFriendRequest?.from.id === meData?.me?.id) {
    return { userType: UserType.REQUESTED_FRIENDSHIP }
  }

  if (friendData?.publicUser.pendingFriendRequest?.to.id === meData?.me?.id) {
    return { userType: UserType.FRIENDSHIP_REQUEST_PENDING }
  }

  return { userType: UserType.NOT_FRIENDS }
}

export default useFriendOfUserRelationshipType
