import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import Skeleton from '@eversports/klimt-primitives/Skeleton'

import { Localized } from '../../../localization/react'

interface Props {
  count?: number
}

const ListingResultsCount: React.FC<React.PropsWithChildren<Props>> = ({ count }) => {
  const isCountANumber = typeof count === 'number'
  return (
    <Stack alignItems="flex-start">
      <Box
        sx={{
          background: '#FAFAFA',
          border: '1px solid #DDDDDD',
          boxSizing: 'border-box',
          borderRadius: '4px',
          padding: '4px 8px',
        }}
      >
        <Text variant="badge" sx={{ color: 'darkgray', fontWeight: 'bold', textTransform: 'uppercase' }}>
          {isCountANumber ? (
            <Localized id="number-of-listing-results-found" params={{ count }} />
          ) : (
            <Skeleton variant="text" width={120} />
          )}
        </Text>
      </Box>
    </Stack>
  )
}

export default ListingResultsCount
