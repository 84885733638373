import * as React from 'react'
import { useRef, useEffect, useState } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import Stack from '@eversports/klimt-primitives/Stack'

import { SearchPosition } from './Search.types'
import useHandleClickOutsideContent from './hooks/useHandleClickOutside'

const SHADOW_HEIGHT = 16

interface Props {
  searchPosition: SearchPosition
  inputLabel?: string
  isOpen: boolean
  closeResults: () => void
}

const DropdownContainer = ({
  children,
  searchPosition,
  inputLabel,
  isOpen,
  closeResults,
}: React.PropsWithChildren<Props>) => {
  const [showOverflowShadow, setShowOverflowShadow] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const containerRef = useRef<HTMLDivElement | null>(null)
  const scrollableRef = useRef<HTMLDivElement | null>(null)

  useHandleClickOutsideContent({
    contentRef: containerRef,
    handleClickOutside: closeResults,
    isOpen,
    isMobile,
  })

  useEffect(() => {
    if (!scrollableRef?.current?.scrollHeight || !containerRef?.current?.clientHeight) {
      return
    }

    // If we have navigated with the keyboard to the bottom of the list, then hide the overflow shadow.
    if (
      scrollableRef.current.scrollHeight - scrollableRef.current.scrollTop - SHADOW_HEIGHT <
      containerRef.current.clientHeight
    ) {
      setShowOverflowShadow(false)
      return
    }

    // If we have an overflowing list, then show the shadow.
    if (scrollableRef.current.scrollHeight > containerRef.current.clientHeight) {
      setShowOverflowShadow(true)
      return
    }
  }, [children])

  if (!isOpen) {
    return null
  }

  return (
    <Box sx={{ display: { xs: 'none', sm: 'block' }, width: '100%', position: 'relative' }}>
      <Stack
        forwardedRef={containerRef}
        paddingX={1}
        paddingTop={2}
        alignItems="flex-start"
        sx={{
          borderRadius: '0px 0px 12px 12px',
          boxShadow: boxShadows.hover,
          border: '1px solid mediumgray',
          backgroundColor: 'white',
          position: 'absolute',
          overflow: 'hidden',
          zIndex: 5,
          maxHeight: '260px',
          width: {
            xs: '100%',
            lg: searchPosition === SearchPosition.LEFT && !inputLabel ? '150%' : '100%',
            xl: '100%',
          },
        }}
      >
        <Stack
          forwardedRef={scrollableRef}
          sx={{ overflow: 'scroll', width: '100%', paddingBottom: `${SHADOW_HEIGHT}px` }}
        >
          {children}
        </Stack>
        <Box
          sx={{
            display: showOverflowShadow ? 'block' : 'none',
            position: 'absolute',
            zIndex: 6,
            width: '100%',
            height: `${SHADOW_HEIGHT}px`,
            bottom: '0px',
            boxShadow: '0px -8px 10px rgba(255, 255, 255, 0.9)',
            background: 'white',
          }}
        />
      </Stack>
    </Box>
  )
}

export default DropdownContainer
