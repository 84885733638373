import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import SeoLink from '@eversports/klimt-primitives/SeoLink'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../localization/react'
import NewBadge from '../NewBadge'

import { NavigationTabVariant } from './NavigationTabs.types'
import NavigationTabBox from './NavigationTabBox'

interface Props {
  to: string
  crawlerLink?: string
  localizedId: string
  isActive?: boolean
  isExternal?: boolean
  isNew?: boolean
  handleClick?: () => void
  variant: NavigationTabVariant
}

const NavigationTabLink = ({
  to,
  crawlerLink,
  isExternal,
  children,
}: React.PropsWithChildren<Pick<Props, 'to' | 'crawlerLink' | 'isExternal'>>) => {
  if (crawlerLink) {
    return (
      <SeoLink type="crawler" crawlerLink={crawlerLink} encodedTo={btoa(to)} external={isExternal}>
        {children}
      </SeoLink>
    )
  }

  return (
    <SeoLink type="hidden" encodedTo={btoa(to)} external={isExternal}>
      {children}
    </SeoLink>
  )
}

const NavigationTab = ({
  to,
  crawlerLink,
  localizedId,
  isActive = false,
  isExternal = true,
  isNew = false,
  variant,
}: Props) => (
  <NavigationTabLink to={to} crawlerLink={crawlerLink} isExternal={isExternal}>
    <NavigationTabBox
      variant={variant}
      sx={{
        backgroundColor: isActive ? 'rgba(33, 166, 150, 0.08)' : 'none',
        '&:hover': {
          backgroundColor: isActive ? 'rgba(33, 166, 150, 0.08)' : 'lightgray',
        },
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
        {isNew && <NewBadge />}
        <Text sx={{ color: isActive ? 'primary.main' : 'text', fontWeight: isActive ? 'bold' : 'initial' }}>
          <Localized id={localizedId as any} />
        </Text>
      </Stack>
    </NavigationTabBox>
  </NavigationTabLink>
)

export default NavigationTab
