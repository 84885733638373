import FocusedMarkerIcon from '../../../../static/focused-marker.webp'
import DisabledFocusedMarkerIcon from '../../../../static/disabled-focused-marker.webp'

const FOCUSED_MARKER_URLS = [FocusedMarkerIcon, DisabledFocusedMarkerIcon]

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Url exists for the ReadonlyIcon type
const isMarkerFocused = (marker: google.maps.Marker) => FOCUSED_MARKER_URLS.includes(marker.getIcon()?.url)

export default isMarkerFocused
