import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

import { PublicParticipation } from './FriendsUpcomingParticipations.types'
import FriendsUpcomingParticipationsBlockDate from './FriendsUpcomingParticipationsBlockDate'
import FriendUpcomingParticipation from './FriendUpcomingParticipation'

interface Props {
  date: string
  upcomingParticipations: Array<PublicParticipation>
}

const FriendsUpcomingParticipationsBlock = ({ date, upcomingParticipations }: Props) => {
  return (
    <Stack gap={3}>
      <FriendsUpcomingParticipationsBlockDate date={date} />
      <Stack gap={{ xs: 1, md: 3 }}>
        {upcomingParticipations.map((upcomingParticipation) => (
          <FriendUpcomingParticipation key={upcomingParticipation.id} upcomingParticipation={upcomingParticipation} />
        ))}
      </Stack>
    </Stack>
  )
}

export default FriendsUpcomingParticipationsBlock
