import { useEffect, useRef, useState } from 'react'

import { MapSettings, MapType } from '../Map.types'

type Props = MapType & MapSettings

const styles: Array<google.maps.MapTypeStyle> = [
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]

const useMap = (props: Props) => {
  const ref = useRef(null)
  const [map, setMap] = useState<google.maps.Map>()

  useEffect(() => {
    const map = new google.maps.Map(ref.current, {
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      gestureHandling: props.gestureHandling || 'cooperative',
      disableDefaultUI: true,
      zoom: props.initialZoom || 10,
      styles,
    })

    if (props.type === 'bounding-box') {
      const mapInitialBounds = {
        sw: {
          lat: props.initialBoundingBox.southWest.latitude,
          lng: props.initialBoundingBox.southWest.longitude,
        },
        ne: {
          lat: props.initialBoundingBox.northEast.latitude,
          lng: props.initialBoundingBox.northEast.longitude,
        },
      }
      map.fitBounds(new google.maps.LatLngBounds(mapInitialBounds.sw, mapInitialBounds.ne))
    }

    if (props.type === 'center') {
      map.setCenter(new google.maps.LatLng(props.center.latitude, props.center.longitude))
    }

    setMap(map)
  }, [])

  return { map, mapRef: ref }
}

export default useMap
