import * as React from 'react'
import { Wrapper as GoogleAPIWrapper } from '@googlemaps/react-wrapper'
import NoSsr from '@eversports/klimt-design-components/NoSsr'

import { getSharedConfig } from '../../config/get-config'

import MapLoadingWrapper from './MapLoadingWrapper'

const MapWrapper = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <NoSsr defer fallback={<MapLoadingWrapper />}>
      <GoogleAPIWrapper apiKey={getSharedConfig().GOOGLE_CLIENT_API_KEY}>{children}</GoogleAPIWrapper>
    </NoSsr>
  )
}

export default MapWrapper
