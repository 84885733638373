import * as React from 'react'
import styled from '@eversports/klimt-design-components/styled'
import Stack from '@eversports/klimt-primitives/Stack'

type Color = 'white' | 'gray'

const LoadingDot = styled('div')<{ index: number; color: Color }>`
  background-color: ${(props) => props.color};
  border-radius: 100%;
  height: 10px;
  width: 10px;
  animation: flash 1.5s infinite;
  animation-delay: ${(props) => props.index * 0.2}s;

  @keyframes flash {
    0%,
    100% {
      opacity: 1;
    }

    60% {
      opacity: 0.2;
    }
  }
`

interface Props {
  color?: Color
}

const LoadingDots = ({ color = 'white' }: Props) => (
  <Stack direction="row" alignItems="center" gap={2} sx={{ height: '21px' }}>
    <LoadingDot index={0} color={color} />
    <LoadingDot index={1} color={color} />
    <LoadingDot index={2} color={color} />
  </Stack>
)

export default LoadingDots
