import * as React from 'react'
import { useEffect, useState } from 'react'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import {
  PublicProfilePrivacyTermsDisclaimerMeDocument,
  usePublicProfilePrivacyTermsDisclaimerMeQuery,
  usePublicProfilePrivacyTermsDisclaimerUpdatePrivacySettingsMutation,
} from '../../graphql'
import { PrivacySettingField, PrivacySettingVisibility } from '../../App.types'

import PublicProfilePrivacyTermsDisclaimerComponent from './PublicProfilePrivacyTermsDisclaimerComponent'

const PublicProfilePrivacyTermsDisclaimer = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [showBanner, setShowBanner] = useState(false)

  const { data } = usePublicProfilePrivacyTermsDisclaimerMeQuery()
  const [updatePrivacySetting, { loading }] = usePublicProfilePrivacyTermsDisclaimerUpdatePrivacySettingsMutation({
    onCompleted: () => setShowBanner(false),
    refetchQueries: [PublicProfilePrivacyTermsDisclaimerMeDocument],
  })

  useEffect(() => {
    if (!data || !data?.me) return

    if (data.me.hasVerifiedEmail && !data.me.hasAcceptedPublicProfileTerms) {
      setShowBanner(true)
    }
  }, [data])

  if (!data?.me) {
    return null
  }

  const handleClick = () => {
    void updatePrivacySetting({
      variables: {
        args: { fieldInputs: [{ field: PrivacySettingField.PROFILE, visibility: PrivacySettingVisibility.PUBLIC }] },
      },
    })
  }

  return showBanner ? (
    <PublicProfilePrivacyTermsDisclaimerComponent isMobile={isMobile} onClick={handleClick} isLoading={loading} />
  ) : null
}

export default PublicProfilePrivacyTermsDisclaimer
