import { OfferingToVenueTabMapping, VenueOfferingType, VenueProfileTabsPrefix } from '../App.types'

interface Props {
  venueSlug: string
  firstVenueOffering?: VenueOfferingType
}

const getLinkToVenueFirstOffering = ({ venueSlug, firstVenueOffering }: Props) => {
  if (!firstVenueOffering) {
    return `${VenueProfileTabsPrefix.OVERVIEW}${venueSlug}`
  }

  switch (firstVenueOffering) {
    case VenueOfferingType.VENUE_OFFERING_VIDEO:
      return `${VenueProfileTabsPrefix.OVERVIEW}${venueSlug}/videos`
    default:
      return `${OfferingToVenueTabMapping[firstVenueOffering]}${venueSlug}`
  }
}

export default getLinkToVenueFirstOffering
