import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import { useParams } from 'react-router-dom'
import Menu from '@eversports/klimt-primitives/Menu'
import { useRef, useState } from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { useMatchSettingsParticipantMatchQuery } from '../../../../graphql'
import { userTypeMapping } from '../../Match.constants'
import useLoggedInUserType from '../../hooks/use-logged-in-user-type'
import Drawer from '../../../../components/Drawer'

import { MatchSetting } from './MatchSetting/MatchSetting.types'
import { MatchSettingParticipant } from './MatchSetting'
import RequiredLevelContent from './components/RequiredLevelContent'
import CompetitionTypeContent from './components/CompetitionTypeContent'

const MatchSettingsParticipant = () => {
  const { id } = useParams<{ id: string }>()
  const { amplitude } = useAmplitude()
  const { userType } = useLoggedInUserType()
  const levelElement = useRef(null)
  const typeElement = useRef(null)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [shownSetting, setShownSetting] = useState<string | null>(null)
  const { data } = useMatchSettingsParticipantMatchQuery({ variables: { matchId: id } })

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (!data || (!data.match.level && !data.match.competitionType && !data.match.gender)) {
    return null
  }

  const handleClick = (elementClicked: 'competition-type' | 'level') => {
    if (isMobile) {
      setIsDrawerOpen(true)
    } else {
      if (elementClicked === 'competition-type') {
        setAnchorEl(typeElement.current)
        setShownSetting('competition-type')
      } else {
        setAnchorEl(levelElement.current)
        setShownSetting('level')
      }
    }

    if (amplitude) {
      amplitude.logEvent('Clicked Match Settings Info', {
        userType: userTypeMapping[userType],
        settings: {
          maxParticipants: data?.match.maxParticipants,
          level: data?.match.level,
          competitionType: data?.match.competitionType,
          gender: data?.match.gender,
          visibility: data?.match.visibility,
        },
      })
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  return (
    <Stack direction="row" gap={4}>
      <MatchSettingParticipant
        type={MatchSetting.LEVEL}
        level={data.match.level}
        forwardedRef={levelElement}
        onClick={() => handleClick('level')}
      />
      <MatchSettingParticipant
        type={MatchSetting.COMPETITION_TYPE}
        competitionType={data.match.competitionType}
        forwardedRef={typeElement}
        onClick={() => handleClick('competition-type')}
      />
      <MatchSettingParticipant type={MatchSetting.GENDER} gender={data.match.gender} />
      {isMobile ? (
        <Drawer open={isDrawerOpen} onClose={handleDrawerClose}>
          {data.match.level && <RequiredLevelContent level={data.match.level} />}
          {data.match.competitionType && <CompetitionTypeContent competitionType={data.match.competitionType} />}
        </Drawer>
      ) : (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            '& .MuiMenu-paper': {
              border: '1px solid',
              borderColor: 'mediumgray',
              boxShadow: boxShadows.default,
              borderRadius: 1,
              borderTopLeftRadius: 0,
              marginTop: 1,
            },
          }}
        >
          {shownSetting === 'level' && data.match.level && <RequiredLevelContent level={data.match.level} />}
          {shownSetting === 'competition-type' && data.match.competitionType && (
            <CompetitionTypeContent competitionType={data.match.competitionType} />
          )}
        </Menu>
      )}
    </Stack>
  )
}

export default MatchSettingsParticipant
