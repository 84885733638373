import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import AddFriendIcon from '@eversports/design-tokens/assets/icons/add-friends.svg'
import Processing from '@eversports/klimt-primitives/Processing'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../../../../localization/react'
import { useAddToFriendsButtonRequestFriendshipMutation } from '../../../../../../graphql'

interface Props {
  publicUserId?: string
}

const AddToFriendsButton = ({ publicUserId }: Props) => {
  const { amplitude } = useAmplitude()
  const [addToFriends, { loading }] = useAddToFriendsButtonRequestFriendshipMutation({
    variables: { userId: publicUserId! },
  })

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Add Friend Button')
    }
    void addToFriends()
  }

  return (
    <Processing isLoading={loading}>
      <Button
        variant="primary"
        sx={{ textTransform: 'none', paddingY: { xs: 3, md: 2 }, paddingX: 6, width: 'fit-content' }}
        onClick={handleClick}
      >
        <Stack alignItems="center" direction="row" gap={2}>
          <Icon size="small" color="white" src={AddFriendIcon} />
          <Text sx={{ color: 'white', fontWeight: 'bold' }}>
            <Localized id="add-to-friends-button-text" />
          </Text>
        </Stack>
      </Button>
    </Processing>
  )
}

export default AddToFriendsButton
