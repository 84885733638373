import * as React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import DiscoverScreen from '../../DiscoverScreen'

import DiscoverVenues from './DiscoverVenues'

const DiscoverVenuesPage = () => {
  const { sportSlug, citySlug } = useParams<{ sportSlug: string; citySlug: string }>()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sportSlug, citySlug])

  return (
    <DiscoverScreen>
      <DiscoverVenues />
    </DiscoverScreen>
  )
}

export default DiscoverVenuesPage
