import { NUMBER_OF_SHOWN_RECENT_SEARCHES } from '../Search.constants'
import { CitySearchResult, SportSearchResult, VenueSearchResult } from '../Search.types'

interface Props<T> {
  recentSearches?: Array<T>
  searchValue: string
}

export const filterAndFormatLocationRecentSearches = ({ recentSearches, searchValue }: Props<CitySearchResult>) => {
  return recentSearches
    ?.filter((searchResult) => searchResult.name.toLowerCase().includes(searchValue.toLowerCase()))
    ?.slice(0, NUMBER_OF_SHOWN_RECENT_SEARCHES)
    .map((searchResult) => ({ ...searchResult, isRecent: true }))
}

interface InvetoryRecentSearchProps extends Props<VenueSearchResult | SportSearchResult> {
  location?: CitySearchResult
}

export const filterAndFormatInventoryRecentSearches = ({
  recentSearches,
  searchValue,
  location,
}: InvetoryRecentSearchProps) => {
  return recentSearches
    ?.filter((searchResult) => searchResult.name.toLowerCase().includes(searchValue.toLowerCase()))
    ?.filter((searchResult) => location && searchResult.cityId === location.id)
    ?.slice(0, NUMBER_OF_SHOWN_RECENT_SEARCHES)
    .map((searchResult) => ({ ...searchResult, isRecent: true }))
}
