import * as React from 'react'
import { useParams } from 'react-router-dom'
import { assertNever } from '@eversports/klimt-utilities/assert-never'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { useEffect, useState } from 'react'

import {
  JoinMatchAnswer,
  useJoinMatchMatchQuery,
  useJoinMatchMutation,
  useLeaveMatchMutation,
} from '../../../../graphql'
import { AuthRoutes } from '../../../../components/Header/UserMenu/UserMenu.constants'
import useLoggedInUserType from '../../hooks/use-logged-in-user-type'
import { MatchStatus, UserType } from '../../Match.types'
import { pageTypeMapping } from '../../Match.constants'
import usePageType from '../../hooks/use-page-type'
import useMatchStatus from '../../hooks/use-match-status'
import { Localized } from '../../../../localization/react'
import ErrorSnackbar from '../components/ErrorSnackbar'

import ManageParticipation from './ManageParticipation'
import JoinMatchButton from './JoinMatchButton'
import GuestJoinMatchButton from './GuestJoinMatchButton'
import JoinedMatchDisclaimer from './JoinedMatchDisclaimer'

interface Props {
  isMobile: boolean
}

const JoinMatch = ({ isMobile }: Props) => {
  const { id } = useParams<{ id: string }>()
  const { userType, userId } = useLoggedInUserType()
  const matchStatus = useMatchStatus()

  const [expectedErrorMessage, setExpectedErrorMessage] = useState<string | null>(null)
  const [userJoinedMatch, setUserJoinedMatch] = useState(false)
  const [joinMatch, { loading: isUserJoiningMatch, data: joinMatchData }] = useJoinMatchMutation({
    onCompleted: () => setUserJoinedMatch(true),
  })
  const [leaveMatch, { loading: isUserLeavingMatch, data: leaveMatchData }] = useLeaveMatchMutation()
  const { data } = useJoinMatchMatchQuery({ variables: { matchId: id } })

  const pageType = usePageType()
  const { amplitude } = useAmplitude()

  useEffect(() => {
    const joinMatchExpectedError =
      joinMatchData?.joinMatchV2.__typename === 'ExpectedErrors' ? joinMatchData?.joinMatchV2.errors[0] : null
    const leaveMatchExpectedError =
      leaveMatchData?.leaveMatchV2.__typename === 'ExpectedErrors' ? leaveMatchData?.leaveMatchV2.errors[0] : null
    const expectedError = joinMatchExpectedError || leaveMatchExpectedError

    if (expectedError) {
      setExpectedErrorMessage(expectedError.message)
    }
  }, [joinMatchData, leaveMatchData])

  if (!data) {
    return null
  }

  const trackParticipationOnAmplitude = React.useCallback(
    ({ isJoining }: { isJoining: boolean }) => {
      if (amplitude) {
        amplitude.logEvent(`Clicked ${isJoining ? 'Join' : 'Leave'} Match Button`, {
          from: pageTypeMapping[pageType],
          sport: data.match.sport.slug,
          currentPlayerCount: data.match.participants.length,
          hasNotes: Boolean(data.match.organizerNote),
        })
      }
    },
    [amplitude],
  )

  const handleParticipantJoin = () => {
    if (!userId) {
      const urlParams = `?redirectPath=${encodeURIComponent(data.match.relativeLink)}`
      window.location.href = `${window.location.origin}${AuthRoutes.REGISTER}${urlParams}`
    } else {
      trackParticipationOnAmplitude({ isJoining: true })
      void joinMatch({ variables: { matchId: id, answer: JoinMatchAnswer.YES } })
    }
  }

  const handleParticipantRemoval = () => {
    trackParticipationOnAmplitude({ isJoining: false })
    void leaveMatch({ variables: { matchId: id } })
  }

  const isUpcomingMatch = matchStatus === MatchStatus.UPCOMING
  const hasMatchReachedMaxParticipants = Boolean(data.match.maxParticipants === data.match.participants.length)

  switch (userType) {
    case UserType.ORGANIZER:
      return null
    case UserType.PARTICIPANT:
      return (
        <>
          <ManageParticipation
            isUserLeavingMatch={isUserLeavingMatch}
            handleClick={handleParticipantRemoval}
            isMobile={isMobile}
            isDisabled={!isUpcomingMatch}
          />
          {expectedErrorMessage && (
            <ErrorSnackbar
              isOpen={Boolean(expectedErrorMessage)}
              errorMessage={expectedErrorMessage}
              onClose={() => setExpectedErrorMessage(null)}
            />
          )}
          <JoinedMatchDisclaimer isMobile={isMobile} userJoinedMatch={userJoinedMatch} />
        </>
      )
    case UserType.EVERSPORTS_GUEST:
      return (
        <>
          <JoinMatchButton
            isUserJoiningMatch={isUserJoiningMatch}
            handleClick={handleParticipantJoin}
            isDisabled={!isUpcomingMatch || hasMatchReachedMaxParticipants}
            isMobile={isMobile}
          >
            <Localized id={hasMatchReachedMaxParticipants ? 'match-is-full' : 'logged-in-user-join-match-button'} />
          </JoinMatchButton>
          {expectedErrorMessage && (
            <ErrorSnackbar
              isOpen={Boolean(expectedErrorMessage)}
              errorMessage={expectedErrorMessage}
              onClose={() => setExpectedErrorMessage(null)}
            />
          )}
        </>
      )
    case UserType.EXTERNAL_GUEST:
      return (
        <GuestJoinMatchButton
          handleClick={handleParticipantJoin}
          isDisabled={!isUpcomingMatch || hasMatchReachedMaxParticipants}
          isMobile={isMobile}
        >
          <Localized id={hasMatchReachedMaxParticipants ? 'match-is-full' : 'guest-join-match-button'} />
        </GuestJoinMatchButton>
      )
    default:
      return assertNever(userType)
  }
}

export default JoinMatch
