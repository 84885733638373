import * as React from 'react'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import { useEffect, useState } from 'react'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import { VenueProfileTabsPrefix } from '../../../../App.types'
import Routes from '../../../VenueProfile/VenueProfile.routes'
import { BallsportMatchState } from '../../Match.types'
import Drawer from '../../../../components/Drawer'

import PublicMatchCreatedDisclaimerContent from './PublicMatchCreatedDisclaimerContent'

interface Props {
  venueSlug: string
  matchWasPublished: boolean
  bookingState?: BallsportMatchState
}

const PublicMatchCreatedDisclaimer = ({ venueSlug, matchWasPublished, bookingState }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const to = `${VenueProfileTabsPrefix.OVERVIEW}${venueSlug}${Routes.MATCHES}`

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  useEffect(() => {
    if (matchWasPublished) {
      setIsDialogOpen(true)
    }
  }, [matchWasPublished])

  return isMobile ? (
    <Drawer open={isDialogOpen} onClose={closeDialog}>
      <PublicMatchCreatedDisclaimerContent onClick={closeDialog} bookingState={bookingState} to={to} />
    </Drawer>
  ) : (
    <Dialog open={isDialogOpen} onClose={closeDialog} maxWidth="xs">
      <DialogContent>
        <PublicMatchCreatedDisclaimerContent onClick={closeDialog} bookingState={bookingState} to={to} />
      </DialogContent>
    </Dialog>
  )
}

export default PublicMatchCreatedDisclaimer
