import * as React from 'react'
import { useEffect, useState } from 'react'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import Stack from '@eversports/klimt-primitives/Stack'
import TextField from '@eversports/klimt-primitives/TextField'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import FilterCheckbox, { CheckboxVariant } from '../../../../../../components/FilterCheckbox'
import { Localized } from '../../../../../../localization/react'
import { Action } from '../../helpers/discover-sport-matches-reducer'
import { MatchVenue } from '../../../../../../App.types'
import { AMPLITUDE_DISCOVER_SPORT_CITY_FILTER_EVENT } from '../../../../Discover.constants'
import { DiscoverFilterTypes } from '../../../../Filters/Filters.types'

interface Props {
  dispatch: React.Dispatch<Action>
  venues: Array<MatchVenue>
  variant?: CheckboxVariant
  selectedVenues?: Array<string>
}

interface InitialValues {
  venues?: Array<string>
}

const SportsFilterForm = ({ dispatch, venues, selectedVenues, variant = 'default' }: Props) => {
  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const [shownVenues, setShownVenues] = useState(venues)

  const methods = useForm<InitialValues>({ defaultValues: { venues: [] } })
  const newlySelectedVenues = methods.watch('venues')
  const { isDirty } = methods.formState

  useEffect(() => {
    methods.reset({ venues: selectedVenues ?? [] })
  }, [selectedVenues])

  useEffect(() => {
    if (!isDirty) return
    if (newlySelectedVenues?.length) {
      logAmplitudeEventOnce({
        eventName: AMPLITUDE_DISCOVER_SPORT_CITY_FILTER_EVENT,
        eventProperties: { type: DiscoverFilterTypes.venue, tab: 'match' },
      })
    }
    dispatch({ type: 'UPDATE_DISCOVER_MATCHES_VENUES_FILTER', payload: newlySelectedVenues })
  }, [newlySelectedVenues])

  const handleSearch = (value: string) => {
    setShownVenues(venues.filter((venue) => venue.name.toLowerCase().includes(value.toLowerCase())))
  }

  return (
    <Stack spacing={3}>
      <TextField
        label={<Localized id="filters-sub-sports-search-label" />}
        onChange={({ target }) => handleSearch(target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: 10,
          },
          marginX: { xs: 3, lg: 0 },
        }}
      />
      <Form methods={methods}>
        <Stack spacing={2}>
          {shownVenues.map((venue) => (
            <FilterCheckbox key={venue.id} value={venue.id} label={venue.name} name="venues" variant={variant} />
          ))}
        </Stack>
      </Form>
    </Stack>
  )
}

export default SportsFilterForm
