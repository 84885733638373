import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

import { Meta } from '../../App.types'

import { Venue } from './VenueProfile.types'
import VenueProfileHeaderContainer from './VenueProfileHeaderContainer'
import Header from './Header'
import VenueProfileContent from './VenueProfileContent'

interface Props {
  venue: Venue
  meta: Meta
}

const VenueProfileComponent = ({ venue, meta }: Props) => (
  <Stack
    sx={{
      backgroundColor: 'eggshell',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    component="main"
  >
    <VenueProfileHeaderContainer>
      <Header venue={venue} />
    </VenueProfileHeaderContainer>
    <VenueProfileContent venue={venue} meta={meta} />
  </Stack>
)

export default VenueProfileComponent
