import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

import { SpotLimit, EventRegistrationWindowState, OpenTimeRange, RegistrationState } from '../../ActivityDetails.types'

import BookNowButton from './BookNowButton'
import UrgencyAlert from './UrgencyAlert'
import NotBookableAlert from './NotBookableAlert'

interface Props {
  bookableItemId: string
  venueId: string
  availableSpots: SpotLimit | null
  registrationState: RegistrationState
  registrationWindowState: EventRegistrationWindowState
  registrationWindow: OpenTimeRange
}

const CallToAction = ({
  availableSpots,
  bookableItemId,
  venueId,
  registrationState,
  registrationWindowState,
  registrationWindow,
}: Props) => {
  return (
    <Stack gap={2} alignItems="center" sx={{ width: '100%' }}>
      <BookNowButton bookableItemId={bookableItemId} venueId={venueId} registrationState={registrationState} />
      <UrgencyAlert availableSpots={availableSpots} isBookable={registrationState.bookable} />
      <NotBookableAlert
        registrationState={registrationState}
        registrationWindowState={registrationWindowState}
        registrationWindow={registrationWindow}
      />
    </Stack>
  )
}
export default CallToAction
