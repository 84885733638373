import { List } from '@eversports/klimt-primitives/List'
import Skeleton from '@eversports/klimt-primitives/Skeleton'
import * as React from 'react'

interface Props {
  numberOfSlotsToShow: number
}

const CourtSlotsSkeleton = ({ numberOfSlotsToShow }: Props) => {
  const skeletonSlots = [...Array(numberOfSlotsToShow)]
  return (
    <List sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', p: 0, gap: 2, marginRight: [0, 8] }}>
      {skeletonSlots.map((_slot, i) => (
        <Skeleton key={i} variant="rectangular" sx={{ width: ['72px', '60px'], height: ['21.5px', '37.5px'] }} />
      ))}
    </List>
  )
}

export default CourtSlotsSkeleton
