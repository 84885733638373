import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'

import Drawer from '../../../components/Drawer'

interface Props {
  title: React.ReactElement
  description: React.ReactElement
  confirmationText: React.ReactElement
  dismissText: React.ReactElement
  onConfirm: () => void
  onDismiss: () => void
}

const ActionButton = ({ children, onClick }: React.PropsWithChildren<{ onClick: () => void }>) => {
  return (
    <Box
      sx={{
        paddingY: 3,
        paddingX: 6,
        width: '100%',
        border: '1px solid',
        borderColor: 'mediumgray',
        borderRadius: 10,
        cursor: 'pointer',
        ':hover': {
          boxShadows: boxShadows.hover,
        },
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}

const ConfirmationDisclaimerContent = ({
  title,
  description,
  confirmationText,
  dismissText,
  onConfirm,
  onDismiss,
}: Props) => {
  return (
    <Stack gap={6}>
      <Stack gap={2} alignItems="center">
        <Heading is="h4" sx={{ color: 'black' }}>
          {title}
        </Heading>
        <Text variant="small" sx={{ color: 'darkbt' }}>
          {description}
        </Text>
      </Stack>
      <Stack gap={3} textAlign="center">
        <ActionButton onClick={onConfirm}>
          <Text sx={{ color: 'red', fontWeight: 'bold' }}>{confirmationText}</Text>
        </ActionButton>
        <ActionButton onClick={onDismiss}>
          <Text sx={{ color: 'black', fontWeight: 'bold' }}>{dismissText}</Text>
        </ActionButton>
      </Stack>
    </Stack>
  )
}

interface OpenDialogProps {
  isOpen: boolean
  onClose: () => void
}

const ConfirmationDisclaimer = ({ isOpen, onClose, ...rest }: Props & OpenDialogProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return isMobile ? (
    <Drawer open={isOpen} onClose={onClose}>
      <ConfirmationDisclaimerContent {...rest} />
    </Drawer>
  ) : (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs">
      <DialogContent>
        <ConfirmationDisclaimerContent {...rest} />
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmationDisclaimer
