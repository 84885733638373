import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Svg from '@eversports/klimt-primitives/Svg'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../localization/react'
import { RegistrationState, SpotLimit, EventRegistrationValidationProblem } from '../../ActivityDetails.types'

import Participants from './assets/availability.svg'

interface Props {
  availableSpots: SpotLimit | null
  registrationState: RegistrationState
}

const Availability = ({ availableSpots, registrationState }: Props) => {
  const notBookable = !registrationState.bookable
  const notRegistered = !registrationState.validationProblems.includes(
    EventRegistrationValidationProblem.ALREADY_REGISTERED,
  )

  if (!availableSpots || (notBookable && notRegistered)) {
    return null
  }

  if (availableSpots.__typename === 'UnlimitedSpots') {
    return (
      <Stack direction="row" alignItems="center" flexWrap="wrap" columnGap={2} rowGap={1}>
        <Svg src={Participants} width={12} height={12} />
        <Text color="midnight" sx={{ fontWeight: 'bold' }}>
          <Localized id="activity-detail-availability-label" />
        </Text>
        <Text color="midnight" variant="small" sx={{ flexBasis: '100%' }}>
          <Localized id="activity-details-unlimited-availability" />
        </Text>
      </Stack>
    )
  }

  const { displayCapped, limit } = availableSpots
  const noSpotsLeft = limit === 0

  if (noSpotsLeft) {
    return null
  }

  // Display capped means that there are more spots than the limit available.
  // But we don't want to disclose the exact number of spots for that event.
  // So we have a generic message that says "more than {limit} spots available"
  if (displayCapped) {
    return (
      <Stack direction="row" alignItems="center" flexWrap="wrap" columnGap={2} rowGap={1}>
        <Svg src={Participants} width={12} height={12} />
        <Text color="midnight" sx={{ fontWeight: 'bold' }}>
          <Localized id="activity-detail-availability-label" />
        </Text>
        <Text color="midnight" sx={{ flexBasis: '100%' }}>
          <Localized id="activity-details-generic-availability-remaining" params={{ freeSpots: limit }} />
        </Text>
      </Stack>
    )
  }

  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" columnGap={2} rowGap={1}>
      <Svg src={Participants} width={12} height={12} />
      <Text color="midnight" sx={{ fontWeight: 'bold' }}>
        <Localized id="activity-detail-availability-label" />
      </Text>
      <Text color="midnight" sx={{ flexBasis: '100%' }}>
        <Localized id="activity-details-specific-availability-remaining" params={{ freeSpots: limit }} />
      </Text>
    </Stack>
  )
}

export default Availability
