import * as React from 'react'
import { useState, useEffect } from 'react'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useDebounce from '@eversports/klimt-utilities/use-debounce'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { CitySearchResult, LocationSearchResult, SearchProps } from '../Search.types'
import {
  CURRENT_LOCATION_RESULT,
  DESKTOP_NUMBER_OF_SHOWN_RESULTS,
  MOBILE_NUMBER_OF_SHOWN_RESULTS,
} from '../Search.constants'
import useRecentSearches, { SearchType } from '../hooks/useRecentSearches'
import { filterAndFormatLocationRecentSearches } from '../helpers/format-and-filter-recent-searches'
import { useSearchCitySearchQuery, useSearchCitySearchLazyQuery } from '../../../graphql'
import filterDatabaseResults from '../helpers/filter-database-results'
import { useIntl } from '../../../localization/react'

import useGeoLocation from './hooks/useGeoLocation'
import SearchLocationComponent from './SearchLocationComponent'
import { trackLocationSearch } from './helpers/track-location.search'
import formatLocationDisplayName from './helpers/format-location-display-name'

interface Props extends SearchProps {
  location?: CitySearchResult
  handleLocationUpdate: (value: CitySearchResult) => void
}

const SearchLocation = ({ location, handleLocationUpdate, hasInputLabels, searchPosition }: Props) => {
  const [searchValue, setSearchValue] = useState('')

  const intl = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { amplitude } = useAmplitude()
  const history = useHistory()
  const { pathname: currentPath } = useLocation()
  const params = useParams<{ citySlug?: string }>()

  const displayName = formatLocationDisplayName({ location, currentLocationLabel: intl.searchCurrentLocation() })

  const { isLoading: isGeoLocationLoading, hasError: hasGeoLocationError, handleGetGeoLocation } = useGeoLocation()

  const { recentSearches, updateRecentSearches } = useRecentSearches<CitySearchResult>(SearchType.LOCATION)
  const shownRecentSearches = filterAndFormatLocationRecentSearches({ recentSearches, searchValue })

  const { data: initialData } = useSearchCitySearchQuery()

  const [getCitySearchResults, { data, previousData, loading: isQueryLoading }] = useSearchCitySearchLazyQuery()

  const debouncedServerCall = useDebounce(
    (value: string) =>
      void getCitySearchResults({
        variables: {
          searchTerm: value,
        },
      }),
    150,
  )

  const queryResult = data?.citySearch ?? previousData?.citySearch ?? undefined

  // If we're on a city-specific page, and the user updated the search location, go to the page for that city
  const handleUrlUpdate = (searchResult: CitySearchResult) => {
    const currentCitySlug = params?.citySlug
    const newCitySlug = searchResult.slug

    if (currentCitySlug && currentCitySlug !== newCitySlug) {
      const newUrl = currentPath.replace(currentCitySlug, newCitySlug)
      history.push(newUrl)
    }
  }

  const handleResultClick = async (searchResult: LocationSearchResult) => {
    trackLocationSearch({ searchTerm: searchValue, result: searchResult, amplitude })

    let citySearchResult = searchResult as CitySearchResult | undefined

    if (searchResult.__typename === 'currentLocation') {
      const geoLocationResult = await handleGetGeoLocation()
      citySearchResult = geoLocationResult
    }

    if (!citySearchResult) return

    handleLocationUpdate(citySearchResult)
    handleUrlUpdate(citySearchResult)
  }

  // We will first try to load the location from local storage.
  // If no value is stored, then we will use the first result from the database.
  useEffect(() => {
    if (location || !queryResult || !queryResult.length) return
    handleLocationUpdate(queryResult[0])
  }, [location, queryResult])

  // update the locale storage search results
  useEffect(() => {
    if (!location) return
    updateRecentSearches({ key: SearchType.LOCATION, value: location })
  }, [location])

  useEffect(() => {
    if (!displayName) return
    setSearchValue(displayName)
  }, [displayName])

  useEffect(() => {
    // Don't do an extra query when we update the search value to the properly formatted display name.
    if (location && searchValue === displayName) return
    debouncedServerCall(searchValue)
  }, [searchValue])

  const filteredQueryResults = filterDatabaseResults<CitySearchResult>({
    databaseResults: queryResult,
    recentSearches: shownRecentSearches,
  })

  const combinedResults: Array<LocationSearchResult> = [...(shownRecentSearches ?? []), ...(filteredQueryResults ?? [])]

  const NUMBER_OF_SHOWN_RESULTS = isMobile ? MOBILE_NUMBER_OF_SHOWN_RESULTS : DESKTOP_NUMBER_OF_SHOWN_RESULTS

  const shownResults = combinedResults.slice(0, NUMBER_OF_SHOWN_RESULTS - 1)

  if (searchValue && shownResults.length) {
    shownResults.push(CURRENT_LOCATION_RESULT)
  }
  if (!searchValue) {
    shownResults.unshift(CURRENT_LOCATION_RESULT)
  }

  const suggestedResults = searchValue && !shownResults.length ? initialData?.citySearch ?? [] : []
  const shownSuggestedResults = suggestedResults.slice(0, NUMBER_OF_SHOWN_RESULTS)

  return (
    <SearchLocationComponent
      searchPosition={searchPosition}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      results={shownResults}
      suggestedResults={shownSuggestedResults}
      handleResultClick={handleResultClick}
      handleResultEnter={handleResultClick}
      isLoading={isGeoLocationLoading || isQueryLoading}
      hasGeoLocationError={hasGeoLocationError}
      location={location}
      hasInputLabels={hasInputLabels}
    />
  )
}

export default SearchLocation
