const createMapMarker = (
  map: google.maps.Map,
  position: google.maps.LatLngLiteral,
  markerOptions: Partial<google.maps.MarkerOptions>,
) =>
  new google.maps.Marker({
    ...markerOptions,
    position,
    map,
  })

let blueHaloMarker: google.maps.Marker | null = null
let blueDotMarker: google.maps.Marker | null = null

const setMyLocationMarker = (map: google.maps.Map, position: google.maps.LatLngLiteral) => {
  const baseLocationMarkerDesign = {
    path: google.maps.SymbolPath.CIRCLE,
    scale: 8,
    fillColor: '#1B84FF',
  }

  // create geolocation markers if they don't exist
  if (!blueHaloMarker) {
    blueHaloMarker = createMapMarker(map, position, {
      icon: {
        ...baseLocationMarkerDesign,
        fillOpacity: 0.2,
        strokeWeight: 0,
      },
      title: 'blue-halo',
    })
  }

  if (!blueDotMarker) {
    blueDotMarker = createMapMarker(map, position, {
      icon: {
        ...baseLocationMarkerDesign,
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#ffffff',
      },
      title: 'blue-dot',
    })
  }
}

export default setMyLocationMarker
