import { EventDifficultyLevel } from '../../../ActivityDetails.types'

import BeginnerIcon from './assets/Beginner.svg'
import IntermediateIcon from './assets/Intermediate.svg'
import AdvancedIcon from './assets/Advanced.svg'

export const DifficultyIconMapping: { [key in EventDifficultyLevel]: string } = {
  [EventDifficultyLevel.BEGINNER]: BeginnerIcon as string,
  [EventDifficultyLevel.INTERMEDIATE]: IntermediateIcon as string,
  [EventDifficultyLevel.PROFESSIONAL]: AdvancedIcon as string,
  [EventDifficultyLevel.ALL]: '', // No icon for All
}

export const DifficultyToNameMapping: { [key in EventDifficultyLevel]: string } = {
  [EventDifficultyLevel.BEGINNER]: 'activity-difficulty-beginner',
  [EventDifficultyLevel.INTERMEDIATE]: 'activity-difficulty-intermediate',
  [EventDifficultyLevel.PROFESSIONAL]: 'activity-difficulty-professional',
  [EventDifficultyLevel.ALL]: 'activity-difficulty-all-levels',
} as const

export const DifficultyToDescriptionMapping: { [key in EventDifficultyLevel]: string } = {
  [EventDifficultyLevel.BEGINNER]: 'activity-difficulty-beginner-description',
  [EventDifficultyLevel.INTERMEDIATE]: 'activity-difficulty-intermediate-description',
  [EventDifficultyLevel.PROFESSIONAL]: 'activity-difficulty-professional-description',
  [EventDifficultyLevel.ALL]: 'activity-difficulty-all-levels-description',
} as const
