import { useParams } from 'react-router-dom'

import { useUseMatchStatusQuery } from '../../../graphql'
import isMatchInThePast from '../helpers/is-match-in-the-past'
import { MatchStatus } from '../Match.types'
import isMatchInTheFuture from '../helpers/is-match-in-the-future'

const useMatchStatus = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useUseMatchStatusQuery({ variables: { matchId: id } })

  if (!data) {
    return null
  }

  const startDate = new Date(data.match.start)
  const endDate = new Date(data.match.end)

  if (isMatchInThePast({ matchEndDate: endDate })) {
    return MatchStatus.COMPLETED
  }

  if (isMatchInTheFuture({ matchStartDate: startDate })) {
    return MatchStatus.UPCOMING
  }

  return MatchStatus.IN_PROGRESS
}

export default useMatchStatus
