import { useEffect, useState } from 'react'

import getCookieByName from '../../helpers/get-cookie-by-name'
import { useAmplitudeContext } from '../amplitude-context'

const useAmplitudeCookies = () => {
  const context = useAmplitudeContext()
  const [amplitudeSessionId, setAmplitudeSessionId] = useState<string | null>(null)
  const [amplitudeDeviceId, setAmplitudeDeviceId] = useState<string | null>(null)

  useEffect(() => {
    if (!context?.amplitude) return

    const storedDeviceId = getCookieByName('amplitudeDeviceId')
    const storedSessionId = Number(getCookieByName('amplitudeSessionId'))
    const currentDeviceId = context.amplitude.getDeviceId()
    const currentSessionId = context.amplitude.getSessionId()

    if (currentDeviceId && storedDeviceId !== currentDeviceId) {
      document.cookie = `amplitudeDeviceId=${currentDeviceId}; path=/;`
    }

    if (currentSessionId && storedSessionId !== currentSessionId) {
      document.cookie = `amplitudeSessionId=${currentSessionId}; path=/;`
    }

    if (!amplitudeDeviceId && currentDeviceId) {
      setAmplitudeDeviceId(currentDeviceId)
    }

    if (!amplitudeSessionId && currentSessionId) {
      setAmplitudeSessionId(currentSessionId.toString())
    }
  }, [context])

  return { deviceId: amplitudeDeviceId, sessionId: amplitudeSessionId }
}

export default useAmplitudeCookies
