import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'

import { DesktopVenueAttributesFilter, MobileVenueAttributesFilterDrawer } from './VenueAttributesFilter'
import ResetAllFilters from './ResetAllFilters'
import { DesktopVenueAmenitiesFilter, MobileVenueAmenitiesFilterDrawer } from './VenueAmenitiesFilter'

const Filters = () => {
  return (
    <Box
      sx={{
        py: { xs: 3, lg: 2 },
        px: { xs: 3, lg: 8 },
        backgroundColor: { xs: 'white', lg: 'eggshell' },
        height: { xs: '63px', lg: '55px' }, // prevent layout shift
        overflow: 'auto',
        whiteSpace: 'nowrap',
      }}
    >
      <MobileFilters />
      <DesktopFilters />
    </Box>
  )
}

const MobileFilters = () => {
  return (
    <Stack
      direction="row"
      gap={3}
      justifyContent="space-between"
      alignItems="center"
      display={{ xs: 'flex', lg: 'none' }}
    >
      <MobileVenueAttributesFilterDrawer />
      <MobileVenueAmenitiesFilterDrawer />
    </Stack>
  )
}

const DesktopFilters = () => (
  <Stack direction="row" gap={4} display={{ xs: 'none', lg: 'flex' }}>
    <DesktopVenueAttributesFilter />
    <DesktopVenueAmenitiesFilter />
    <ResetAllFilters />
  </Stack>
)

export default React.memo(Filters)
