import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'

import { Localized } from '../../../localization/react'
import SectionWrapper from '../components/SectionWrapper'

import TestimonialCard from './TestimonialCard'
import { TESTIMONIAL_1, TESTIMONIAL_2, TESTIMONIAL_3 } from './Testimonials.constants'
import Squiggle from './assets/squiggle.svg'

const Testimonials = () => {
  return (
    <Stack
      sx={{
        width: '100%',
        alignItems: 'center',
        backgroundColor: { sm: 'rgb(247, 251, 251)' },
        position: 'relative',
        marginBottom: 15,
        contentVisibility: 'auto',
      }}
    >
      <SectionWrapper sx={{ zIndex: 1, paddingY: { sm: 10 } }}>
        <Heading
          is="h2"
          variant="xlarge"
          color="midnight"
          sx={{ fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'", fontWeight: 500 }}
        >
          <Localized id="home-rebrand-testimonials-header" />
        </Heading>
        <Text variant="large">
          <Localized id="home-rebrand-testimonials-description" />
        </Text>
        <Stack sx={{ flexDirection: { sm: 'row' }, gap: 4, paddingTop: 4, flexWrap: 'wrap' }}>
          <TestimonialCard testimonial={TESTIMONIAL_1} />
          <TestimonialCard testimonial={TESTIMONIAL_2} />
          <TestimonialCard testimonial={TESTIMONIAL_3} />
        </Stack>
      </SectionWrapper>
      <Box component="img" src={Squiggle} sx={{ position: 'absolute', width: '100%', display: { sm: 'none' } }} />
    </Stack>
  )
}

export default Testimonials
