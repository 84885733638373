import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'

import { Image } from '../../../../App.types'

import ImagePlaceholder from './assets/image-placeholder.webp'

const MOBILE_CONTAINER_HEIGHT = 257
const DESKTOP_CONTAINER_HEIGHT = 277

const BlurredImage = ({ imageSrc }: { imageSrc: string }) => (
  <Box
    sx={{
      background: `url(${imageSrc}) center / cover no-repeat`,
      width: '100%',
      height: '100%',
    }}
  >
    <Box
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(6px)',
        height: '100%',
      }}
    />
  </Box>
)

interface Props {
  activityImage: Image | null
  activityName: string
  venueName: string
}

const FocusImageWithBackdrop = ({ activityImage, activityName, venueName }: Props) => (
  <>
    <Box
      component="img"
      src={activityImage?.small ?? ImagePlaceholder}
      alt={`${activityName} @ ${venueName}`}
      sx={{
        display: { xs: 'block', sm: 'none' },
        objectFit: 'cover',
        width: '100%',
        height: `${MOBILE_CONTAINER_HEIGHT}px`,
      }}
    />
    <Box
      sx={{
        display: { xs: 'none', sm: 'block' },
        position: 'relative',
        height: `${DESKTOP_CONTAINER_HEIGHT}px`,
      }}
    >
      <Box sx={{ position: 'absolute', top: 0, width: '100%', height: '100%' }}>
        <BlurredImage imageSrc={activityImage?.large ?? ImagePlaceholder} />
      </Box>
      <Box
        component="img"
        src={activityImage?.large ?? ImagePlaceholder}
        alt={`${activityName} @ ${venueName}`}
        sx={{
          aspectRatio: '4/3',
          objectFit: 'cover',
          height: '100%',
          margin: '0 auto',
          position: 'relative',
          zIndex: 1,
        }}
      />
    </Box>
  </>
)

export default FocusImageWithBackdrop
