import * as React from 'react'
import Alert from '@eversports/klimt-primitives/Alert'
import Text from '@eversports/klimt-primitives/Text'
import Snackbar from '@eversports/klimt-primitives/Snackbar'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

interface Props {
  isOpen: boolean
  errorMessage: string
  onClose: () => void
}

const ErrorSnackbar = ({ isOpen, errorMessage, onClose }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: isMobile ? 'center' : 'right' }}
      sx={{ bottom: '80px' }}
      onClose={onClose}
    >
      <Alert severity="error" variant="filled">
        <Text sx={{ color: 'white' }}>{errorMessage}</Text>
      </Alert>
    </Snackbar>
  )
}

export default ErrorSnackbar
