import * as React from 'react'
import { default as MuiPopover, PopoverProps as MuiPopoverProps } from '@mui/material/Popover'

export type PopoverProps = Pick<
  MuiPopoverProps,
  'id' | 'open' | 'onClose' | 'children' | 'anchorEl' | 'anchorPosition' | 'anchorOrigin' | 'sx' | 'transformOrigin'
>

const Popover = (props: PopoverProps) => {
  return <MuiPopover {...props} />
}

export default Popover
