import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'

interface ClickableProps {
  isDisabled?: boolean
  onClick?: () => void
}

const IconBadge: React.FC<React.PropsWithChildren<ClickableProps>> = ({ children, onClick, isDisabled = false }) => {
  return (
    <Box
      sx={{
        p: 2,
        background: '#FFFFFF',
        border: '0.5px solid',
        borderColor: 'lightgray',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 2px #DDDDDD',
        borderRadius: 80,
        cursor: isDisabled ? 'default' : 'pointer',
        backgroundColor: isDisabled ? 'lightgray' : 'white',
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}

export default IconBadge
