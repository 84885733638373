import Box from '@eversports/klimt-primitives/Box'
import * as React from 'react'

import MapWrapper from '../../../../components/Map/MapWrapper'
import Map from '../../../../components/Map/Map'

interface Props {
  latitude?: number | null
  longitude?: number | null
}

const VenueMap = ({ latitude, longitude }: Props) => {
  if (!latitude || !longitude) {
    return null
  }

  const venueLocation = { latitude, longitude }
  const markers = [{ latitude, longitude }]

  return (
    <Box width="100%" height="220px" position="relative" borderRadius={1} overflow="hidden">
      <MapWrapper>
        <Map
          type="center"
          center={venueLocation}
          markers={markers}
          showGeoLocationControl={false}
          showOpenGoogleMaps={true}
          initialZoom={15}
        />
      </MapWrapper>
    </Box>
  )
}

export default VenueMap
