import * as React from 'react'
import { default as MuiTypography, TypographyProps } from '@mui/material/Typography'
import { TextVariants, textVariants } from '@eversports/design-tokens/typography'

interface Props extends Omit<TypographyProps, 'variant' | 'component'> {
  variant?: keyof TextVariants
  component?: React.ElementType
}

const Text = ({ variant = 'regular', component = 'p', sx, ...rest }: Props) => {
  const { fontSize, fontWeight, lineHeight } = textVariants[variant]
  return <MuiTypography {...rest} component={component} sx={{ fontSize, fontWeight, lineHeight, ...sx }} />
}

export default Text
