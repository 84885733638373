import { useEffect, useState } from 'react'

import { InventorySearchResult, LocationSearchResult } from '../Search.types'

interface Props<T> {
  results: Array<T>
  isResultsDropdownOpen: boolean
  onArrow: () => void
  onClose: () => void
  onEnter: (value?: T) => void | Promise<void>
}

function useKeyboardToNavigateResults({
  results,
  isResultsDropdownOpen,
  onArrow: handleArrow,
  onClose: handleClose,
  onEnter: handleEnter,
}: Props<InventorySearchResult | LocationSearchResult>) {
  const [selectedIndex, setSelectedIndex] = useState(0)

  function handleKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Tab':
      case 'Escape': {
        setSelectedIndex(0)
        handleClose()
        break
      }
      case 'ArrowDown': {
        handleArrow()
        setSelectedIndex((prevIndex) => {
          if (prevIndex + 1 === results.length) {
            return 0
          }
          return Math.min(prevIndex + 1, results.length - 1)
        })
        break
      }
      case 'ArrowUp': {
        handleArrow()
        setSelectedIndex((prevIndex) => {
          if (prevIndex - 1 < 0) {
            return results.length - 1
          }
          return Math.max(prevIndex - 1, 0)
        })
        break
      }
      case 'Enter': {
        const result = results?.[selectedIndex] ?? undefined
        void handleEnter(result)
        setSelectedIndex(0)
        handleClose()
        if (event.target instanceof HTMLInputElement) {
          event.target.blur()
        }
        break
      }
      default:
        setSelectedIndex(0)
        break
    }
  }

  useEffect(() => {
    if (isResultsDropdownOpen) {
      document.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [results, selectedIndex, isResultsDropdownOpen])

  return { selectedIndex }
}

export default useKeyboardToNavigateResults
