import * as React from 'react'
import { MouseEventHandler } from 'react'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

interface Props {
  disabledHover?: boolean
  onMouseEnter?: MouseEventHandler<HTMLDivElement>
  onMouseLeave?: MouseEventHandler<HTMLDivElement>
}

const ListingCard = ({ children, disabledHover, onMouseEnter, onMouseLeave }: React.PropsWithChildren<Props>) => {
  return (
    <Box
      component="li"
      display="block"
      sx={{
        borderRadius: 1,
        backgroundColor: 'white',
        boxShadow: boxShadows.default,
        transition: 'box-shadow ease-in-out',
        position: 'relative',
        contentVisibility: 'auto',
        '&:hover': !disabledHover
          ? {
              boxShadow: boxShadows.hover,
            }
          : {},
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Box>
  )
}

export default ListingCard
