import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import { Helmet } from 'react-helmet-async'

import useGetTopContentHeight from '../../hooks/useGetTopContentHeight'
import { Localized, useIntl } from '../../localization/react'

const DataSubProcessors = () => {
  const intl = useIntl()
  const { DESKTOP_TOP_CONTENT_HEIGHT: TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])
  const pdfUrl = `https://files.eversports.com/data-subprocessors.pdf`

  return (
    <>
      <Helmet>
        <title>{`Eversports - ${intl.dataSubProcessorsHeading()}`}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'eggshell',
        }}
      >
        <Stack
          sx={{
            height: `calc(100vh - ${TOP_CONTENT_HEIGHT}px)`,
            gap: 8,
            maxWidth: 1280,
            width: '100%',
            margin: '0 auto',
            paddingTop: 12,
          }}
          component="main"
        >
          <h1>
            <Localized id="data-sub-processors-heading" />
          </h1>
          <object data={pdfUrl} type="application/pdf" width="100%" height="100%">
            <iframe src={`https://docs.google.com/gview?url=${pdfUrl}&embedded=true`} width="100%" height="100%" />
          </object>
        </Stack>
      </Box>
    </>
  )
}

export default DataSubProcessors
