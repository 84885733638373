import DisabledMarkerIcon from '../../../../static/disabled-marker.webp'
import DisabledFocusedMarkerIcon from '../../../../static/disabled-focused-marker.webp'

const DISABLED_MARKER_URLS = [DisabledMarkerIcon, DisabledFocusedMarkerIcon]

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Url exists for the ReadonlyIcon type
const isMarkerDisabled = (marker: google.maps.Marker) => DISABLED_MARKER_URLS.includes(marker.getIcon()?.url)

export default isMarkerDisabled
