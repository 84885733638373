import * as React from 'react'
import FormattedContent from '@eversports/klimt-primitives/FormattedContent'
import styled from '@eversports/klimt-design-components/styled'
import { AccordionDetails } from '@eversports/klimt-primitives/Accordion'

interface ContentProps {
  contentcolor: string
}

const Content = styled('div')<ContentProps>`
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: ${(props) => props.contentcolor};
  }

  & > ul {
    margin: 4px 0px;

    & > li {
      padding: 4px 0px;
    }

    & > li > a {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      color: #333333;
    }
  }
`
interface Props {
  text: string
  contentColor: string
}

const AccordionContent = ({ text, contentColor }: Props) => {
  const ColoredContent: React.FC<React.PropsWithChildren<unknown>> = React.memo(({ children, ...rest }) => (
    <Content contentcolor={contentColor} {...rest}>
      {children}
    </Content>
  ))

  return (
    <AccordionDetails
      sx={{
        padding: 4,
        backgroundColor: 'lightgray',
        borderRadius: '0px 0px 16px 16px',
        margin: 0,
      }}
    >
      <FormattedContent htmlContent={text} WrapperComponent={ColoredContent} />
    </AccordionDetails>
  )
}

export default AccordionContent
