import * as React from 'react'

import { useFriendsAttendingGetEventBookableItemQuery, useFriendsAttendingMeQuery } from '../../../graphql'

import ParticipatingFriends from './ParticipatingFriends'
import FriendsAttendingPlaceholder from './FriendsAttendingPlaceholder'

interface Props {
  activityId: string
}

const FriendsAttending = ({ activityId }: Props) => {
  const { data: meData } = useFriendsAttendingMeQuery()
  const { data: bookableItemData } = useFriendsAttendingGetEventBookableItemQuery({
    variables: { id: activityId },
  })

  if (!meData?.me || !bookableItemData) {
    return null
  }

  if (meData && meData.me.friendsCount < 1) {
    return <FriendsAttendingPlaceholder userHandle={meData.me.handle} />
  }

  return <ParticipatingFriends friends={bookableItemData.getEventBookableItem.participatingFriends} />
}

export default FriendsAttending
