import Box from '@eversports/klimt-primitives/Box'
import * as React from 'react'
import { useEffect } from 'react'

import useMap from './hooks/useMap'
import { MapControlSettings, Marker, MapSettings, MapType } from './Map.types'
import createMapMarkers from './helpers/create-map-markers'
import MapControls from './MapControls'

interface CommonProps extends MapControlSettings, MapSettings {
  markers: Array<Marker>
}

type Props = MapType & CommonProps

const Map = (props: Props) => {
  const { markers, showGeoLocationControl, showOpenGoogleMaps, showZoomControls, ...mapSettings } = props
  const { map, mapRef } = useMap({ ...mapSettings })

  useEffect(() => {
    if (!map) return
    createMapMarkers({ map, markers, clickable: false })
  }, [map])

  return (
    <>
      <Box sx={{ width: '100%', height: '100%' }} forwardedRef={mapRef} id="map" />
      {map && (
        <MapControls
          map={map}
          showZoomControls={showZoomControls}
          showGeoLocationControl={showGeoLocationControl}
          showOpenGoogleMaps={showOpenGoogleMaps}
        />
      )}
    </>
  )
}

export default Map
