import * as React from 'react'
import { useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import CloseIcon from '@eversports/design-tokens/assets/icons/close.svg'
import Processing from '@eversports/klimt-primitives/Processing'
import Box from '@eversports/klimt-primitives/Box'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { useFriendsButtonEndFriendshipMutation } from '../../../../../../graphql'
import { Localized } from '../../../../../../localization/react'
import ConfirmationDisclaimer from '../../../../components/ConfirmationDisclaimer'
import { From } from '../../../../../../App.types'
import { PublicUser } from '../../../../PublicUserProfile.types'
import UserInfo from '../../../../../../components/UserInfo'

interface Props {
  friend: PublicUser
  onFriendRemoval: (friendId: string) => void
}

const Friend = ({ friend, onFriendRemoval }: Props) => {
  const { amplitude } = useAmplitude()
  const [isOpen, setIsOpen] = useState(false)
  const [endFriendship, { loading }] = useFriendsButtonEndFriendshipMutation({
    variables: { userId: friend.id },
    onCompleted: () => {
      onFriendRemoval(friend.id)
    },
  })

  const toggleDisclaimer = () => {
    setIsOpen(!isOpen)
  }

  const removeFriend = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Confirm Remove Friend Button')
    }
    toggleDisclaimer()
    void endFriendship()
  }

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Remove Friend Button')
    }
    toggleDisclaimer()
  }

  const handleDismiss = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Dismiss Remove Friend Button')
    }
    toggleDisclaimer()
  }

  return (
    <>
      <Processing isLoading={loading}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <UserInfo
            profilePicture={friend.profilePicture?.small}
            firstName={friend.firstName}
            lastName={friend.lastName}
            handle={friend.handle}
            from={From.MY_FRIENDS_LIST}
          />
          <Box onClick={handleClick} sx={{ cursor: 'pointer' }}>
            <Icon size="default" color="gray" src={CloseIcon} />
          </Box>
        </Stack>
      </Processing>
      <ConfirmationDisclaimer
        title={<Localized id="confirmation-disclaimer-remove-friendship-title" />}
        description={<Localized id="confirmation-disclaimer-remove-friendship-description" />}
        confirmationText={<Localized id="confirmation-disclaimer-remove-friendship" />}
        dismissText={<Localized id="confirmation-disclaimer-remove-friendship-dismiss" />}
        onConfirm={removeFriend}
        onDismiss={handleDismiss}
        onClose={toggleDisclaimer}
        isOpen={isOpen}
      />
    </>
  )
}

export default Friend
