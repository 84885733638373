// A date as string, formatted as "YYYY-MM-DD", such as 2007-12-03
// Used for GraphQL date filter param
export const formatDateAsQueryVariable = (value: Date) => {
  const isoDate = toLocalIsoString(value) // 2022-01-05T12:00:00.000
  const [date] = isoDate.split('T') // 2022-01-05
  return date
}

export const toISOStringWithoutSeconds = (value: Date) => {
  value.setSeconds(0, 0)
  return value.toISOString()
}

const toLocalIsoString = (value: Date) => {
  const tzOffset = value.getTimezoneOffset() * 60000
  // we shift from UTC to local TZ => remove Z because it's no longer UTC
  return new Date(value.getTime() - tzOffset).toISOString().replace('Z', '')
}

// A time string representing a time without timezone, using the format "HH:mm:ss" or "12:00:00"
// Used for GraphQL time filter param
export const formatTimeAsQueryVariable = (value: Date) => {
  const localTimeAsString = value.toTimeString() // 14:39:07 GMT-0600 (PDT)
  const [fullTime] = localTimeAsString.split(' ') // 14:39:07
  return fullTime
}

// A time string representing a time without timezone, using the format "HHmm"
// Used for GraphQL time filter param
export const formatTimeAsURLParam = (value: Date) => {
  const localTimeAsString = value.toTimeString() // 14:39:07 GMT-0600 (PDT)
  const [hour, minutes] = localTimeAsString.split(':') // 14 39 07 GMT-0600 (PDT)
  return `${hour}${minutes}`
}
