import * as React from 'react'
import { isToday } from 'date-fns'
import { useState, useEffect } from 'react'
import TimePicker from '@eversports/klimt-patterns/TimePicker'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import { useDiscoverReducer, useDiscoverState } from '../DiscoverContext'

import { DiscoverFilterTypes } from './Filters.types'
import getVenueFilterAmplitudeEventName from './helpers/get-venue-filter-amplitude-event-name'

const HOURS_TO_SHOW = [...Array(24).keys()]

const getAvailableHoursToday = () => {
  const currentDate = new Date()
  const currentHour = currentDate.getHours()
  const hours = []
  for (let i = currentHour; i < 24; i++) {
    hours.push(i)
  }
  return hours
}

interface Props {
  isMobile?: boolean
}

const VenueCourtSlotTimeFilter = ({ isMobile = false }: Props) => {
  const dispatch = useDiscoverReducer()
  const [availableHours, setAvailableHours] = useState<Array<number>>([])
  const { venueCourtSlotTimeActiveFilter, venueCourtSlotDateActiveFilter } = useDiscoverState()

  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()

  const handleTimeChange = (timeAsDate: Date | null) => {
    if (timeAsDate) {
      logAmplitudeEventOnce({
        eventName: getVenueFilterAmplitudeEventName(),
        eventProperties: { type: DiscoverFilterTypes.time, tab: 'venue' },
      })
    }

    dispatch({ type: 'UPDATE_COURT_SLOT_TIME_FILTER', payload: timeAsDate })
  }

  useEffect(() => {
    const isActiveDateFilterToday = isToday(venueCourtSlotDateActiveFilter)

    if (isActiveDateFilterToday) {
      const availableHoursToday = getAvailableHoursToday()

      if (venueCourtSlotTimeActiveFilter) {
        const activeHourFilter = venueCourtSlotTimeActiveFilter.getHours()
        const isActiveHourFilterOutsideTodaysAvailability = !availableHoursToday.includes(activeHourFilter)

        if (isActiveHourFilterOutsideTodaysAvailability) {
          dispatch({ type: 'UPDATE_COURT_SLOT_TIME_FILTER', payload: null })
        }
      }

      setAvailableHours(availableHoursToday)
    } else {
      setAvailableHours(HOURS_TO_SHOW)
    }
  }, [venueCourtSlotDateActiveFilter])

  return (
    <TimePicker
      value={venueCourtSlotTimeActiveFilter}
      onChange={handleTimeChange}
      hoursToShow={availableHours}
      isMobile={isMobile}
    />
  )
}

export default VenueCourtSlotTimeFilter
