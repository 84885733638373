import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'

import { useNumberOfPendingFriendRequestsMeQuery } from '../../graphql'
import { ColorContrast } from '../Header/Header.types'

interface Props {
  variant?: 'numerical' | 'dot'
  colorContrast?: ColorContrast
}

const NumberOfPendingFriendRequests = ({ colorContrast = ColorContrast.NORMAL, variant = 'numerical' }: Props) => {
  const { data } = useNumberOfPendingFriendRequestsMeQuery()

  if (!data || !(data && data.me?.receivedFriendRequestsCount)) {
    return null
  }

  const numberOfPendingFriendRequests =
    data.me.receivedFriendRequestsCount > 99 ? '99' : data.me.receivedFriendRequestsCount

  if (variant === 'dot') {
    return (
      <Text
        variant="xsmall"
        position="absolute"
        textAlign="center"
        borderRadius={10}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={16}
        height={16}
        top={-10}
        right={-10}
        sx={{
          color: colorContrast === ColorContrast.NORMAL ? 'white' : 'red',
          backgroundColor: colorContrast === ColorContrast.NORMAL ? 'red' : 'white',
          fontWeight: 'bold',
        }}
      >
        {numberOfPendingFriendRequests}
      </Text>
    )
  }

  return (
    <Text
      variant="xsmall"
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingX={1.5}
      borderRadius={10}
      sx={{
        color: colorContrast === ColorContrast.NORMAL ? 'white' : 'red',
        backgroundColor: colorContrast === ColorContrast.NORMAL ? 'red' : 'white',
        fontWeight: 'bold',
      }}
    >
      {numberOfPendingFriendRequests}
    </Text>
  )
}

export default NumberOfPendingFriendRequests
