import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import LocationIcon from '@eversports/design-tokens/assets/icons/location.svg'
import Icon from '@eversports/klimt-primitives/Icon'

import { Localized } from '../../../../localization/react'

interface Props {
  address?: string
}

const VenueAddress = ({ address }: Props) => {
  return (
    <Stack direction="row" mt="auto" gap={2}>
      <Icon src={LocationIcon} size="small" color="darkgray" />
      <Text variant="small" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
        {address ? address : <Localized id="listing-result-online-only-venue" />}
      </Text>
    </Stack>
  )
}

export default VenueAddress
