import { useEffect, useMemo, useState } from 'react'

import { MatchVisibility, useUseMatchesMatchesLazyQuery, useUseMatchesMatchesQuery } from '../../graphql'
import { toISOStringWithoutSeconds } from '../../utils/format-date-time'
import { Match, MatchFilters } from '../../App.types'

import { NUMBER_OF_INITIAL_MATCHES_FETCHED } from './useMatches.constants'

type MatchWithCursor = Match & { cursor?: string }

interface Result {
  matches: Array<MatchWithCursor>
  loading: boolean
  loadingFilterMatches: boolean
  loadingMoreMatches: boolean
  filterMatches: (filters: MatchFilters) => void
  loadMoreMatches: (filters: MatchFilters) => void
  hasMoreResults?: boolean
}

interface Props {
  initialFilters?: MatchFilters
  initialData?: {
    matches?: Array<MatchWithCursor>
    hasMoreResults?: boolean
  }
}

const useMatches = ({ initialFilters, initialData }: Props): Result => {
  const currentDate = useMemo(() => toISOStringWithoutSeconds(new Date()), [])
  const [matches, setMatches] = useState<Array<MatchWithCursor> | undefined>(initialData?.matches)
  const [hasMoreResults, setHasMoreResults] = useState<boolean | undefined>(initialData?.hasMoreResults)

  const [getMatches, { data: extraMatches, loading: loadingMoreMatches }] = useUseMatchesMatchesLazyQuery()
  const [fetchFilteredMatches, { data: filteredMatchesData, loading: loadingFilterMatches }] =
    useUseMatchesMatchesLazyQuery()

  const { data, loading } = useUseMatchesMatchesQuery({
    variables: {
      first: NUMBER_OF_INITIAL_MATCHES_FETCHED,
      filters: {
        ...initialFilters,
        visibility: MatchVisibility.PUBLIC,
        timeRange: { start: currentDate },
      },
    },
    skip: Boolean(initialData),
  })

  const initialHasMoreResults = initialData?.hasMoreResults || data?.matches.pageInfo.hasNextPage
  const initiallyFetchedMatches = data?.matches.edges.map(({ node, cursor }) => ({ ...node, cursor })) || []
  const initialMatches = initialData?.matches || initiallyFetchedMatches

  const filterMatches = ({ timeRange, ...filters }: MatchFilters) => {
    void fetchFilteredMatches({
      variables: {
        first: NUMBER_OF_INITIAL_MATCHES_FETCHED,
        filters: {
          ...filters,
          timeRange: timeRange ? timeRange : { start: currentDate },
          visibility: MatchVisibility.PUBLIC,
        },
      },
    })
  }

  const loadMoreMatches = ({ timeRange, ...filters }: MatchFilters) => {
    if (hasMoreResults === false || !(matches || initialMatches)) return
    const indexOfLastMatch = matches && matches.length > 0 ? matches.length - 1 : initialMatches.length - 1
    const cursor =
      matches && matches.length > 0 ? matches[indexOfLastMatch]?.cursor : initialMatches[indexOfLastMatch]?.cursor
    void getMatches({
      variables: {
        first: NUMBER_OF_INITIAL_MATCHES_FETCHED,
        after: cursor,
        filters: {
          ...filters,
          timeRange: timeRange ? timeRange : { start: currentDate },
          visibility: MatchVisibility.PUBLIC,
        },
      },
    })
  }

  useEffect(() => {
    if (!extraMatches?.matches.edges.length || !(matches || initialMatches)) return
    const fetchedMatches = extraMatches.matches.edges.map(({ node, cursor }) => ({ ...node, cursor }))
    const existingMatches = matches && matches.length > 0 ? matches : initialMatches
    setHasMoreResults(Boolean(extraMatches?.matches.pageInfo.hasNextPage))
    setMatches([...existingMatches, ...fetchedMatches])
  }, [extraMatches])

  useEffect(() => {
    if (!filteredMatchesData) return
    const fetchedMatches = filteredMatchesData.matches.edges.map(({ node, cursor }) => ({ ...node, cursor }))
    setHasMoreResults(Boolean(filteredMatchesData?.matches.pageInfo.hasNextPage))
    setMatches(fetchedMatches)
  }, [filteredMatchesData])

  useEffect(() => {
    if (!data || initialData?.matches?.length) return
    const fetchedMatches = data.matches.edges.map(({ node, cursor }) => ({ ...node, cursor }))
    setHasMoreResults(Boolean(data?.matches.pageInfo.hasNextPage))
    setMatches(fetchedMatches)
  }, [data])

  useEffect(() => {
    if (!initialData || matches?.length) return
    setHasMoreResults(initialData.hasMoreResults)
    setMatches(initialData.matches)
  }, [initialData])

  return {
    filterMatches,
    loadMoreMatches,
    loading: loading,
    loadingFilterMatches,
    loadingMoreMatches,
    hasMoreResults: hasMoreResults ?? initialHasMoreResults,
    matches: matches ? matches : initialMatches,
  }
}

export default useMatches
