import * as React from 'react'

import { Localized } from '../../../../../localization/react'
import { AmenityTypeToNameMapping, VenueAmenityType } from '../../../../../App.types'

import ShowerIcon from './assets/shower.svg'
import FoodIcon from './assets/food.svg'
import ShopAndRentalIcon from './assets/shop-and-rental.svg'
import WellnessOfferIcon from './assets/wellness-offer.svg'
import ChangingRoomIcon from './assets/changing-room.svg'
import ParkingIcon from './assets/parking.svg'
import PayWithCardIcon from './assets/pay-with-card.svg'

interface AmenityIcon {
  amenity: VenueAmenityType
  icon: string
  label: JSX.Element
}

const AmenityIconsMap: Array<AmenityIcon> = [
  {
    amenity: VenueAmenityType.VENUE_AMENITY_SANITARY,
    icon: ShowerIcon,
    label: <Localized id={AmenityTypeToNameMapping.VENUE_AMENITY_SANITARY} />,
  },
  {
    amenity: VenueAmenityType.VENUE_AMENITY_GASTRONOMY,
    icon: FoodIcon,
    label: <Localized id={AmenityTypeToNameMapping.VENUE_AMENITY_GASTRONOMY} />,
  },
  {
    amenity: VenueAmenityType.VENUE_AMENITY_SHOP,
    icon: ShopAndRentalIcon,
    label: <Localized id={AmenityTypeToNameMapping.VENUE_AMENITY_SHOP} />,
  },
  {
    amenity: VenueAmenityType.VENUE_AMENITY_WELLNESS,
    icon: WellnessOfferIcon,
    label: <Localized id={AmenityTypeToNameMapping.VENUE_AMENITY_WELLNESS} />,
  },
  {
    amenity: VenueAmenityType.VENUE_AMENITY_LOCKERROOM,
    icon: ChangingRoomIcon,
    label: <Localized id={AmenityTypeToNameMapping.VENUE_AMENITY_LOCKERROOM} />,
  },
  {
    amenity: VenueAmenityType.VENUE_AMENITY_PARKING,
    icon: ParkingIcon,
    label: <Localized id={AmenityTypeToNameMapping.VENUE_AMENITY_PARKING} />,
  },
  {
    amenity: VenueAmenityType.VENUE_AMENITY_CARDPAYMENT,
    icon: PayWithCardIcon,
    label: <Localized id={AmenityTypeToNameMapping.VENUE_AMENITY_CARDPAYMENT} />,
  },
]

export default AmenityIconsMap
