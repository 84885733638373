import * as React from 'react'

import { Trainer } from '../../ActivityDetails.types'
import { EventType } from '../../../../App.types'

import SingleTrainer from './SingleTrainer'
import MultipleTrainers from './MultipleTrainers'

interface Props {
  trainers: Array<Trainer>
  eventType: EventType
}

const Trainers = ({ trainers, eventType }: Props) => {
  if (!trainers.length) return null

  if (trainers.length === 1) {
    return <SingleTrainer trainer={trainers[0]} eventType={eventType} />
  }

  return <MultipleTrainers trainers={trainers} eventType={eventType} />
}

export default Trainers
