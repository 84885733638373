import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Heading from '@eversports/klimt-primitives/Heading'
import Breadcrumbs from '@eversports/klimt-primitives/Breadcrumbs'
import Link from '@eversports/klimt-primitives/Link'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box from '@eversports/klimt-primitives/Box'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import Icon from '@eversports/klimt-primitives/Icon'
import ChevronLeft from '@eversports/design-tokens/assets/icons/chevron-left.svg'

import { Localized } from '../../../localization/react'
import { UserProfileTabsPrefix } from '../../../../src/App.types'

interface Props {
  productName: string
  venueName: string
}

const Header = ({ productName, venueName }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return isMobile ? (
    <Stack display={{ xs: 'flex', md: 'none' }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        p={4}
        sx={{
          backgroundColor: 'white',
          boxShadow: boxShadows.default,
          border: '0.5px solid',
          borderColor: 'lightgray',
        }}
      >
        <Link external to={`${UserProfileTabsPrefix.OVERVIEW}${UserProfileTabsPrefix.PASSES_AND_MEMBERSHIPS}`}>
          <Stack flex={1} direction="row" gap={1} alignItems="center">
            <Icon src={ChevronLeft} size="default" color="primary" />
            <Text color="primary" sx={{ fontWeight: 'bold' }}>
              <Localized id="back-link" />
            </Text>
          </Stack>
        </Link>
        <Text flex={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          <Localized id="invoice-history-page-header" />
        </Text>
        <Box flex={1} />
      </Stack>
      <Box
        display={{ xs: 'flex', md: 'none' }}
        justifyContent="center"
        alignItems="center"
        width="100%"
        p={4}
        sx={{ backgroundColor: 'white', boxShadow: boxShadows.default, border: '1px solid', borderColor: 'lightgray' }}
      >
        <Text sx={{ fontWeight: 'bold' }}>
          {venueName} - {productName}
        </Text>
      </Box>
    </Stack>
  ) : (
    <Stack
      display={{ xs: 'none', md: 'flex' }}
      width="100%"
      py={5}
      px={8}
      gap={2}
      sx={{ backgroundColor: 'white', boxShadow: boxShadows.default, border: '1px solid', borderColor: 'lightgray' }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">
          <Text>
            <Localized id="breadcrumb-navigation-item-home" />
          </Text>
        </Link>
        <Link external to={`${UserProfileTabsPrefix.OVERVIEW}${UserProfileTabsPrefix.PASSES_AND_MEMBERSHIPS}`}>
          <Text>
            <Localized id="breadcrumb-navigation-item-passes-and-memberships" />
          </Text>
        </Link>
        <Text>
          <Localized id="breadcrumb-navigation-invoice-history" />
        </Text>
      </Breadcrumbs>
      <Heading is="h3">
        {venueName} - {productName}
      </Heading>
    </Stack>
  )
}

export default Header
