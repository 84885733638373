import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import Skeleton from '@eversports/klimt-primitives/Skeleton'
import Link from '@eversports/klimt-primitives/Link'
import SeoLink from '@eversports/klimt-primitives/SeoLink'

import { useOfferingsDiscoverContextByCityAndSportSlugQuery } from '../../../../graphql'
import getOfferingTabs from '../../../Discover/Offerings/helpers/get-offering-tabs'
import { Localized } from '../../../../localization/react'
import { DiscoverOfferingType, DiscoverySport } from '../../../Discover/Discover.types'
import { OfferingIcons } from '../CitySportOfferings.constants'
import VenueIcon from '../assets/venue.svg'

import OfferingCard from './OfferingCard'

const PLACEHOLDER_CARDS = 8

interface Tab {
  to: string
  crawlerLink?: string
  localizedId: string
  isExternal: boolean
  offering: DiscoverOfferingType | null
}

interface Props {
  citySlug?: string
  sport?: DiscoverySport
}

const Offerings = ({ citySlug, sport }: Props) => {
  if (!citySlug || !sport) return null

  const { data, loading, error } = useOfferingsDiscoverContextByCityAndSportSlugQuery({
    variables: { citySlug, sportSlug: sport.slug },
  })

  if (loading) {
    return (
      <Stack sx={{ flexDirection: 'row', gap: 4, overflowX: 'auto', paddingY: 2, gridColumn: 'span 2' }}>
        {Array.from(Array(PLACEHOLDER_CARDS)).map((_, idx) => (
          <Skeleton
            key={`offering_loading_${idx}`}
            variant="rectangular"
            sx={{
              borderRadius: 1,
              width: { xs: '132px', sm: '148px' },
              height: '100px',
              flexShrink: 0,
            }}
          />
        ))}
      </Stack>
    )
  }

  const offerings = data?.discoverContextByCityAndSportSlug?.offerings ?? []

  if (error) {
    return (
      <Box>
        <Localized id="home-rebrand-no-offerings" />
      </Box>
    )
  }

  // Will return at the very least "Venues"
  const offeringTabs: Array<Tab> = getOfferingTabs({ offerings, citySlug, sport })

  return (
    <Stack sx={{ flexDirection: 'row', gap: 4, overflowX: 'auto', paddingY: 2, gridColumn: 'span 2' }}>
      {offeringTabs.map(({ offering, to, localizedId, crawlerLink }) => {
        const key = `${offering}_${sport.slug}_${citySlug}`
        const icon = !offering ? VenueIcon : OfferingIcons[offering]

        if (crawlerLink) {
          return (
            <SeoLink key={key} type="crawler" encodedTo={btoa(to)} crawlerLink={crawlerLink}>
              <OfferingCard icon={icon} localizedId={localizedId} />
            </SeoLink>
          )
        }

        return (
          <Link key={key} to={to} external>
            <OfferingCard icon={icon} localizedId={localizedId} />
          </Link>
        )
      })}
    </Stack>
  )
}

export default Offerings
