import { GoogleMapsUrlVariant } from '../MapShowGoogleMaps'

interface Props {
  variant: GoogleMapsUrlVariant
  latitude: number
  longitude: number
}

export const getGoogleMapsLink = ({ variant, latitude, longitude }: Props) => {
  if (variant === 'search') {
    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
  }
  return `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`
}
