import { SupportedTLD } from '@eversports/react-app-base/tld-configs'

import { DiscoverOfferingType } from '../../Discover/Discover.types'

import Camp from './assets/camp.svg'
import Class from './assets/class.svg'
import Course from './assets/course.svg'
import Education from './assets/education.svg'
import Event from './assets/event.svg'
import LiveStream from './assets/live-stream.svg'
import OpenTraining from './assets/open-training.svg'
import PublicMatch from './assets/public-match.svg'
import Retreat from './assets/retreat.svg'
import Video from './assets/video.svg'
import Workshop from './assets/workshop.svg'
import { CitySearchCity } from './CitySportOfferings.types'

export const OfferingIcons: { [key in DiscoverOfferingType]: string } = {
  [DiscoverOfferingType.DISCOVER_OFFERING_CLASS_ACTIVITY]: Class,
  [DiscoverOfferingType.DISCOVER_OFFERING_TRAINING_ACTIVITY]: Class,
  [DiscoverOfferingType.DISCOVER_OFFERING_LIVESTREAM]: LiveStream,
  [DiscoverOfferingType.DISCOVER_OFFERING_VIDEO]: Video,
  [DiscoverOfferingType.DISCOVER_OFFERING_PUBLIC_MATCH]: PublicMatch,
  [DiscoverOfferingType.DISCOVER_OFFERING_COURSE_ACTIVITY]: Course,
  [DiscoverOfferingType.DISCOVER_OFFERING_WORKSHOP_ACTIVITY]: Workshop,
  [DiscoverOfferingType.DISCOVER_OFFERING_EVENT_ACTIVITY]: Event,
  [DiscoverOfferingType.DISCOVER_OFFERING_RETREAT_ACTIVITY]: Retreat,
  [DiscoverOfferingType.DISCOVER_OFFERING_EDUCATION_ACTIVITY]: Education,
  [DiscoverOfferingType.DISCOVER_OFFERING_CAMP_ACTIVITY]: Camp,
  [DiscoverOfferingType.DISCOVER_OFFERING_OPENTRAINING_ACTIVITY]: OpenTraining,
}

export const DEFAULT_CITIES: { [key in SupportedTLD]: CitySearchCity } = {
  at: { slug: 'wien', name: 'Wien', id: '316', country: 'AT' },
  nl: { slug: 'amsterdam', name: 'Amsterdam', id: '5543', country: 'NL' },
  de: { slug: 'berlin', name: 'Berlin', id: '334', country: 'DE' },
  es: { slug: 'barcelona', name: 'Barcelona', id: '6444', country: 'ES' },
  ch: { slug: 'zuerich', name: 'Zürich', id: '5828', country: 'CH' },
  it: { slug: 'roma', name: 'Roma', id: '6622', country: 'IT' },
  be: { slug: 'bruxelles', name: 'Bruxelles', id: '5720', country: 'BE' },
  fr: { slug: 'paris', name: 'Paris', id: '6347', country: 'FR' },
}
