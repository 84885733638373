import { OfferingToVenueTabMapping, VenueOfferingType } from '../../../../../App.types'
import Routes from '../../../VenueProfile.routes'
import { VenueOffering } from '../../../VenueProfile.types'
import { OfferingToTabNameMapping } from '../../../VenueProfile.constants'

interface Props {
  venueOffering: VenueOffering
  venueSlug: string
}

const getTabFromVenueOffering = ({ venueOffering, venueSlug }: Props) => {
  const offeringToVenueTabMapping = OfferingToVenueTabMapping[venueOffering]
  const isVideoTab = venueOffering === VenueOfferingType.VENUE_OFFERING_VIDEO
  const isTeamTab = venueOffering === VenueOfferingType.VENUE_OFFERING_TEAM
  const isPublicMatchesTab = venueOffering === VenueOfferingType.VENUE_OFFERING_PUBLIC_MATCHES

  let to = `${offeringToVenueTabMapping}${venueSlug}`

  if (isVideoTab) {
    to = `${offeringToVenueTabMapping}${venueSlug}/videos`
  }

  if (isPublicMatchesTab) {
    to = `${offeringToVenueTabMapping}${venueSlug}${Routes.MATCHES}`
  }

  if (isTeamTab) {
    to = `${offeringToVenueTabMapping}${venueSlug}${Routes.TEAM}`
  }

  const localizedId = OfferingToTabNameMapping[venueOffering]

  return { to, localizedId }
}

export default getTabFromVenueOffering
