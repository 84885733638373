interface Props {
  summary: string
  start: string
  end: string
  address: string
  description: string
}

export const createIcsUrl = ({ summary, start, end, description, address }: Props) => {
  const formattedDescription = description.replace(/\n/g, '\\n').replace(/,/, '\\,')
  const formattedAddress = address.replace(/,/, '\\,').replace(/\s\s/, ' ')
  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `DTSTART:${formatDateForICS(start)}`,
    `DTEND:${formatDateForICS(end)}`,
    `SUMMARY:${summary}`,
    `LOCATION:${formattedAddress}`,
    `DESCRIPTION:${formattedDescription}`,
    'BEGIN:VALARM',
    'TRIGGER:-PT12H',
    'REPEAT:1',
    'ACTION:DISPLAY',
    'END:VALARM',
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n')
}

const formatDateForICS = (date: string) => {
  // as ISOString
  let isoDateString = new Date(date).toISOString()
  // remove milliseconds
  isoDateString = isoDateString.split('.')[0] + 'Z'
  // remove dashes and colons
  isoDateString = isoDateString.replace(/-/g, '')
  isoDateString = isoDateString.replace(/:/g, '')

  // now formatted as YYYYMMDDTHHMMSSZ
  return isoDateString
}
