import { CitySearchResult } from '../../Search.types'

interface Props {
  location?: CitySearchResult
  currentLocationLabel: string
}

// Example: "Vienna, AT" or "Vienna, AT (Current Location)"
export default function formatLocationDisplayName({ location, currentLocationLabel }: Props) {
  if (!location) return

  let displayedSearchValue = `${location.name}, ${location.country}`

  if (location.isCurrentLocation) {
    displayedSearchValue = `${location.name}, ${location.country} (${currentLocationLabel})`
  }

  return displayedSearchValue
}
