import * as React from 'react'
import { useEffect, useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import Box from '@eversports/klimt-primitives/Box'

import { Localized } from '../../../localization/react'
import { RecommendationsSectionWrapper } from '../RecommendationsSectionWrapper'
import { RecommendationsIconLarge } from '../RecommendationsIcon'
import TicketIcon from '../assets/ticket-icon.svg'
import useGetTopContentHeight from '../../../hooks/useGetTopContentHeight'

import TrialCard from './TrialCard'
import TrialCardsSkeleton from './TrialCardsSkeletons'
import useRecommendationsTrialCards, {
  NUMBER_OF_INITIAL_TRIAL_CARDS_FETCHED,
} from './hooks/useRecommendationsTrialCards'

const TrialCardsPage = () => {
  const { amplitude } = useAmplitude()
  const [isLoadingMoreVenues, setIsLoadingMoreVenues] = useState(false)
  const { venues, loading, fetchMore, hasMoreResults } = useRecommendationsTrialCards()
  const { DESKTOP_TOP_CONTENT_HEIGHT: TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR', 'PAGE_TITLE'])

  const handleShowMore = async () => {
    if (amplitude) {
      amplitude.logEvent('Triggered Load More Trial Offers')
    }

    setIsLoadingMoreVenues(true)
    await fetchMore()
  }

  useEffect(() => {
    setIsLoadingMoreVenues(false)
  }, [venues])

  useEffect(() => {
    if (!amplitude) return
    amplitude.logEvent('Viewed Trial Offer Overview')
  }, [amplitude])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Stack
      sx={{
        backgroundColor: 'white',
        minHeight: `calc(100vh - ${TOP_CONTENT_HEIGHT}px)`,
      }}
      component="main"
    >
      <Box
        sx={{
          px: { xs: 4, md: 7 },
          py: { xs: 5, md: 10 },
        }}
      >
        <Stack gap={10}>
          <Stack justifyContent="center" alignItems="center" spacing={5}>
            <Stack direction="row" alignItems="center">
              <RecommendationsIconLarge>
                <img src={TicketIcon} alt="ticket icon" width="22px" height="auto" loading="lazy" />
              </RecommendationsIconLarge>
              <Heading is="h1" variant="xlarge">
                <Localized id="trial-offers-title" />
              </Heading>
            </Stack>
            <Text variant="large" sx={{ color: 'darkgray', textAlign: 'center', display: { xs: 'none', md: 'block' } }}>
              <Localized id="trial-offers-subtitle" />
            </Text>
          </Stack>
          <RecommendationsSectionWrapper>
            {venues && venues.map((venue, idx) => <TrialCard key={idx} venue={venue} />)}
            {(isLoadingMoreVenues || loading) && (
              <TrialCardsSkeleton numberOfCopies={NUMBER_OF_INITIAL_TRIAL_CARDS_FETCHED} />
            )}
          </RecommendationsSectionWrapper>
          {hasMoreResults && (
            <Button
              onClick={() => void handleShowMore()}
              variant="tertiary"
              sx={{ px: 6, py: 3, alignSelf: 'center', width: { xs: '100%', sm: '448px' } }}
            >
              <Text sx={{ textTransform: 'none', fontWeight: 'bold' }}>
                <Localized id="load-more" />
              </Text>
            </Button>
          )}
        </Stack>
      </Box>
    </Stack>
  )
}

export default TrialCardsPage
