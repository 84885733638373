import * as React from 'react'
import { MouseEvent } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import { MenuItem, MenuList } from '@eversports/klimt-primitives/Menu'

import { Localized } from '../localization/react'

import Hour from './components/Hour'

interface Props {
  hoursToShow: Array<number>
  hour: number | null
  handleHourClick: (event: MouseEvent<HTMLLIElement>) => void
  handleClearTime: () => void
  isMobile?: boolean
}

const TimePickerContent = ({ hoursToShow, hour, handleHourClick, handleClearTime, isMobile }: Props) => {
  return (
    <Stack gap={3} alignItems="center" sx={isMobile ? { px: 10, py: 4 } : {}}>
      <Stack direction="row" gap={3}>
        <Stack gap={1} alignItems="center">
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'lightgray',
              maxHeight: '192px',
              overflow: 'auto',
              borderRadius: 1,
            }}
          >
            <MenuList sx={{ p: 0 }} autoFocusItem={Boolean(hour)} variant="selectedMenu">
              {hoursToShow.map((hourToShow) => (
                <MenuItem
                  key={hourToShow}
                  value={hourToShow}
                  onClick={handleHourClick}
                  selected={hour === hourToShow}
                  sx={{ minHeight: 'auto' }}
                >
                  <Text sx={{ color: hour === hourToShow ? 'primary.main' : 'text' }}>
                    <Hour value={hourToShow} />
                  </Text>
                </MenuItem>
              ))}
            </MenuList>
          </Box>
        </Stack>
      </Stack>
      {typeof hour === 'number' && (
        <Text
          sx={{ color: 'red', fontWeight: 'bold', textAlign: 'center', cursor: 'pointer' }}
          onClick={handleClearTime}
        >
          <Localized id="clear" />
        </Text>
      )}
    </Stack>
  )
}

export default TimePickerContent
