import { matchPath } from 'react-router-dom'

import Routes from '../../../../App.routes'
import {
  AMPLITUDE_DISCOVER_CITY_FILTER_EVENT,
  AMPLITUDE_DISCOVER_SPORT_CITY_FILTER_EVENT,
} from '../../Discover.constants'

const getVenueFilterAmplitudeEventName = () => {
  const pathname = window.location.pathname

  const isDiscoverCityPage = matchPath(pathname, { path: Routes.DISCOVER_CITY, exact: true })

  const isSeoDiscoverSportCityPage = matchPath(pathname, { path: Routes.SEO_DISCOVER_SPORT_CITY, exact: true })

  const isAppDiscoverSportCityPage = matchPath(pathname, { path: Routes.APP_DISCOVER_SPORT_CITY, exact: true })

  if (isDiscoverCityPage) {
    return AMPLITUDE_DISCOVER_CITY_FILTER_EVENT
  }

  if (isSeoDiscoverSportCityPage || isAppDiscoverSportCityPage) {
    return AMPLITUDE_DISCOVER_SPORT_CITY_FILTER_EVENT
  }

  throw new Error('getVenueFilterAmplitudeEventName was used in a non valid discover page')
}

export default getVenueFilterAmplitudeEventName
