import * as React from 'react'
import { useParams } from 'react-router-dom'

import { VALID_BALLSPORT_MATCH_STATES } from '../Match.constants'
import { useCallToActionMatchQuery } from '../../../graphql'

import InviteFriends from './InviteFriends'
import JoinMatch from './JoinMatch'

interface Props {
  isMobile?: boolean
}

const CallToAction = ({ isMobile = false }: Props) => {
  const { id } = useParams<{ id: string }>()
  const { data } = useCallToActionMatchQuery({ variables: { matchId: id } })

  if (!data) {
    return null
  }

  if (VALID_BALLSPORT_MATCH_STATES.includes(data.match.state)) {
    return (
      <>
        <JoinMatch isMobile={isMobile} />
        <InviteFriends isMobile={isMobile} />
      </>
    )
  }
  return null
}

export default CallToAction
