import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import Link from '@eversports/klimt-primitives/Link'

import { Localized } from '../localization/react'

import NoFriendsImage from './assets/no-friends.svg'

interface Props {
  to: string
  isExternal?: boolean
}

const NoFriendsPlaceholder = ({ to, isExternal = false }: Props) => {
  return (
    <Stack gap={1} margin="auto" maxWidth="350px">
      <img src={NoFriendsImage} width="100%" />
      <Stack gap={6}>
        <Stack sx={{ textAlign: 'center' }}>
          <Heading is="h3">
            <Localized id="invite-friends-no-friends-title" />
          </Heading>
          <Text sx={{ color: 'darkgray' }}>
            <Localized id="invite-friends-no-friends-description" />
          </Text>
        </Stack>
        <Link to={to} external={isExternal}>
          <Button variant="primary" sx={{ py: 2, width: '100%' }}>
            <Text variant="badge" sx={{ color: 'white', textTransform: 'none' }}>
              <Localized id="invite-friends-no-friends-button" />
            </Text>
          </Button>
        </Link>
      </Stack>
    </Stack>
  )
}

export default NoFriendsPlaceholder
