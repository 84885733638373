interface SearchResult {
  id: string
  name: string
  matchedName: string
  secondaryMatch: string | null
}

export interface CurrentLocation {
  id: string
  __typename: 'currentLocation'
}

export interface CitySearchResult extends SearchResult {
  id: string
  __typename: 'CitySearchResult'
  name: string
  coordinate: {
    latitude: number
    longitude: number
  }
  country: string
  slug: string
  isRecent?: boolean
  isCurrentLocation?: boolean
}

export type LocationSearchResult = CitySearchResult | CurrentLocation

export interface CityLocalStorage {
  id: string
  country: string
  lat: number
  lng: number
  name: string
  slug: string
}

export interface AllSportsInCity {
  id: string
  __typename: 'AllSportsInCity'
  cityId: string
  cityName: string
  citySlug: string
}

export interface VenueSearchResult extends SearchResult {
  __typename: 'VenueSearchResult'
  location: { city: string; zip: string }
  slug: string
  isRecent?: boolean
  citySlug: string
  cityId: string
}

export interface SportSearchResult extends SearchResult {
  __typename: 'SportSearchResult'
  cityId: string
  cityName: string
  citySlug: string
  sportId: string
  sportSlug: string
  isRecent?: boolean
}

export type InventorySearchResult = AllSportsInCity | SportSearchResult | VenueSearchResult

// Could not find a way to use (value: T)
export interface SearchComponentProps<T> {
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  results: Array<T>
  suggestedResults?: Array<T>
  handleResultClick: (value: any) => void | Promise<void>
  handleResultEnter: (value?: any) => void | Promise<void>
  searchPosition: SearchPosition
  isLoading: boolean
  hasInputLabels?: boolean
  hasSearchButton?: boolean
}

export enum SearchType {
  LOCATION = 'LOCATION',
  INVENTORY = 'INVENTORY',
}

export enum SearchPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}
export interface SearchProps {
  hasInputLabels?: boolean
  hasSearchButton?: boolean
  searchPosition: SearchPosition
}
