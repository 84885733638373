import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import { useParams } from 'react-router-dom'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import { UserType } from '../Match.types'
import { VALID_BALLSPORT_MATCH_STATES } from '../Match.constants'
import { useAdditionalActionsMatchQuery } from '../../../graphql'
import useLoggedInUserType from '../hooks/use-logged-in-user-type'

import SaveMatchToCalendar from './SaveMatchToCalendar'
import CancelMatch from './CancelMatch'
import ShareMatch from './ShareMatch'
import OpenInGoogleMaps from './OpenInGoogleMaps'
import MobileAdditionalActions from './MobileAdditionalActions'

const AdditionalActions = () => {
  const { userType } = useLoggedInUserType()
  const { id } = useParams<{ id: string }>()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { data } = useAdditionalActionsMatchQuery({ variables: { matchId: id } })

  if (!data) {
    return null
  }

  if (VALID_BALLSPORT_MATCH_STATES.includes(data.match.state)) {
    return isMobile ? (
      <MobileAdditionalActions />
    ) : (
      <Box width="fit-content">
        <Stack direction="row" flexWrap="wrap" justifyContent="flex-end" width="100%" gap={4}>
          <SaveMatchToCalendar />
          <OpenInGoogleMaps />
          {userType === UserType.ORGANIZER ? <CancelMatch /> : <ShareMatch />}
        </Stack>
      </Box>
    )
  }

  return null
}

export default AdditionalActions
