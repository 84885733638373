import * as React from 'react'
import { useState, useEffect } from 'react'
import Snackbar from '@eversports/klimt-primitives/Snackbar'
import Box from '@eversports/klimt-primitives/Box'
import CloseIcon from '@eversports/design-tokens/assets/icons/close.svg'
import Icon from '@eversports/klimt-primitives/Icon'

import { Localized } from '../../../localization/react'

interface Props {
  hasError?: boolean
}

const GeoLocationErrorSnackbar = ({ hasError }: Props) => {
  const [isOpen, setIsOpen] = useState(hasError)
  const handleClose = () => setIsOpen(false)

  useEffect(() => {
    setIsOpen(hasError)
  }, [hasError])

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      message={<Localized id="search-geolocation-not-found-error-message" />}
      action={
        <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
          <Icon src={CloseIcon} size="default" color="white" />
        </Box>
      }
    />
  )
}

export default GeoLocationErrorSnackbar
