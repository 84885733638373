import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { assertNever } from '@eversports/klimt-utilities/assert-never'
import Link from '@eversports/klimt-primitives/Link'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import ParticipatingFriends from '../../../../../ActivityDetails/FriendAttending/ParticipatingFriends'

import { PublicParticipation } from './FriendsUpcomingParticipations.types'
import FriendUpcomingParticipationTimeRange from './FriendUpcomingParticipationTimeRange'
import FriendUpcomingParticipationSport from './FriendUpcomingParticipationSport'
import FriendUpcomingParticipationImage from './FriendUpcomingParticipationImage'
import FriendUpcomingParticipationName from './FriendUpcomingParticipationName'
import FriendUpcomingParticipationAddress from './FriendUpcomingParticipationAddress'
import getEventBookableItemLink from './helpers/get-event-bookable-item-link'
import FriendUpcomingParticipationCard from './FriendUpcomingParticipationCard'
import FriendUpcomingParticipationState from './FriendUpcomingParticipationState'

interface Props {
  upcomingParticipation: PublicParticipation
}

const FriendUpcomingParticipationContent = ({ upcomingParticipation, onClick }: Props & { onClick: () => void }) => {
  switch (upcomingParticipation.__typename) {
    case 'PublicCourtBooking':
      return (
        <Stack gap={3} onClick={onClick}>
          <Stack direction="row" gap={2}>
            <FriendUpcomingParticipationImage src={upcomingParticipation.bookableItem.venue.images[0]?.medium} />
            <Stack gap={2} sx={{ overflow: 'hidden' }}>
              <FriendUpcomingParticipationName name={upcomingParticipation.bookableItem.sport.name} />
              <FriendUpcomingParticipationTimeRange
                start={upcomingParticipation.timeRange.start}
                end={upcomingParticipation.timeRange.end}
              />
              <Stack gap={2} direction="row">
                <FriendUpcomingParticipationSport name={upcomingParticipation.bookableItem.sport.name} />
                {upcomingParticipation.participationState && (
                  <FriendUpcomingParticipationState state={upcomingParticipation.participationState} />
                )}
              </Stack>
            </Stack>
          </Stack>
          <FriendUpcomingParticipationAddress
            venueName={upcomingParticipation.bookableItem.venue.name}
            street={upcomingParticipation.bookableItem.venue.location.street}
            zip={upcomingParticipation.bookableItem.venue.location.zip}
            city={upcomingParticipation.bookableItem.venue.location.city}
          />
        </Stack>
      )
    case 'PublicEventSessionParticipation':
      return (
        <Link to={getEventBookableItemLink(upcomingParticipation.bookableItem)} external blank>
          <Stack gap={3} onClick={onClick}>
            <Stack direction="row" gap={2}>
              <FriendUpcomingParticipationImage src={upcomingParticipation.bookableItem.activityImage?.medium} />
              <Stack gap={2} sx={{ overflow: 'hidden' }}>
                <FriendUpcomingParticipationName name={upcomingParticipation.bookableItem.activityName} />

                <FriendUpcomingParticipationTimeRange
                  start={upcomingParticipation.timeRange.start}
                  end={upcomingParticipation.timeRange.end}
                />
                <Stack gap={2} direction="row">
                  <FriendUpcomingParticipationSport name={upcomingParticipation.bookableItem.sport.name} />
                  {upcomingParticipation.participationState && (
                    <FriendUpcomingParticipationState state={upcomingParticipation.participationState} />
                  )}
                </Stack>
              </Stack>
            </Stack>
            <FriendUpcomingParticipationAddress
              venueName={upcomingParticipation.bookableItem.venue.name}
              street={upcomingParticipation.bookableItem.venue.location.street}
              zip={upcomingParticipation.bookableItem.venue.location.zip}
              city={upcomingParticipation.bookableItem.venue.location.city}
            />
          </Stack>
        </Link>
      )
    case 'BallsportMatch':
      return (
        <Stack gap={3} onClick={onClick}>
          <Stack direction="row" gap={2}>
            <FriendUpcomingParticipationImage src={upcomingParticipation.venue.images[0]?.medium} />
            <Stack gap={2} sx={{ overflow: 'hidden' }}>
              <FriendUpcomingParticipationName name={upcomingParticipation.sport.name} />

              <FriendUpcomingParticipationTimeRange
                start={upcomingParticipation.start}
                end={upcomingParticipation.end}
              />
              <Stack gap={2} direction="row">
                <FriendUpcomingParticipationSport name={upcomingParticipation.sport.name} />
                {upcomingParticipation.participationState && (
                  <FriendUpcomingParticipationState state={upcomingParticipation.participationState} />
                )}
              </Stack>
            </Stack>
          </Stack>
          <FriendUpcomingParticipationAddress
            venueName={upcomingParticipation.venue.name}
            street={upcomingParticipation.venue.location.street}
            zip={upcomingParticipation.venue.location.zip}
            city={upcomingParticipation.venue.location.city}
          />
        </Stack>
      )
    default:
      return assertNever(upcomingParticipation)
  }
}

const FriendUpcomingParticipation = ({ upcomingParticipation }: Props) => {
  const { amplitude } = useAmplitude()
  const attendingFriends = {
    nodes: upcomingParticipation.attendingFriends?.edges.map((edge) => edge.node) || [],
  }

  const showParticipatingFriends = attendingFriends.nodes.length > 0
  const handleClick = () => {
    if (!amplitude) return
    amplitude.logEvent(' Clicked Friends Upcoming Activities Tile', {
      type: upcomingParticipation.__typename === 'PublicEventSessionParticipation' ? 'activity' : 'court booking',
    })
  }

  return (
    <FriendUpcomingParticipationCard>
      <FriendUpcomingParticipationContent upcomingParticipation={upcomingParticipation} onClick={handleClick} />
      {showParticipatingFriends && <ParticipatingFriends friends={attendingFriends} />}
    </FriendUpcomingParticipationCard>
  )
}

export default FriendUpcomingParticipation
