import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../../../../localization/react'
import { levelDescriptionTranslation, levelTranslation } from '../MatchSettings.constants'
import { MatchLevel } from '../../../Match.types'

interface Props {
  level: MatchLevel
}

const RequiredLevelContent = ({ level }: Props) => {
  return (
    <Stack gap={0.5} p={3} width="100%">
      <Text variant="badge">
        <Localized id={levelTranslation[level]} />
      </Text>
      <Text variant="xsmall">
        <Localized id={levelDescriptionTranslation[level]} />
      </Text>
    </Stack>
  )
}

export default RequiredLevelContent
