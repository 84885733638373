import * as React from 'react'
import { default as MuiAccordion, AccordionProps } from '@mui/material/Accordion'
import styled from '@eversports/klimt-design-components/styled'

const StyledAccordion = styled(MuiAccordion)`
  background: #ffffff;
  box-shadow: 0px 1px 2px #dddddd;
  border-radius: 16px;
  border: 0.5px solid #f2f2f2;

  :first-of-type,
  :last-of-type {
    border-radius: 16px;
  }

  :before {
    content: none;
  }
`

export const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = (props) => {
  return <StyledAccordion {...props} />
}

export default Accordion
