import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Avatar from '@eversports/klimt-primitives/Avatar'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import MenuIcon from '@eversports/design-tokens/assets/icons/menu.svg'
import CloseIcon from '@eversports/design-tokens/assets/icons/close.svg'
import Icon from '@eversports/klimt-primitives/Icon'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import Box from '@eversports/klimt-primitives/Box'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'
import Svg from '@eversports/klimt-primitives/Svg/Svg'
import { useState } from 'react'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import RecommendationsPageLink from '../RecommendationsPageLink'
import { LoggedInUser } from '../../../hooks/useLoggedInUser'
import RegisterButton from '../RegisterButton'
import LoginButton from '../LoginButton'
import CorporateBenefits from '../CorporateBenefits'
import { ColorContrast } from '../Header.types'
import { NotificationsButton, NotificationsUserMenuLink } from '../NotificationsButton'

import {
  HomeUserItem,
  LoggedInUserMenuItems,
  NonLoggedInUserMenuItems,
  PublicUserFeatureMenuItems,
} from './UserMenuItems'
import UserMenuLink from './UserMenuLink'

const AVATAR_DIMENSION = 28

interface Props {
  user: LoggedInUser
  authUrlParams: string
  colorContrast?: ColorContrast
}

const MobileUserMenuDialogComponent = ({ user, authUrlParams, colorContrast = ColorContrast.NORMAL }: Props) => {
  const { tld } = useSettingsContext()
  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const [isOpen, setIsOpen] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const toggleDialog = () => {
    setIsOpen(!isOpen)
    if (isOpen === false) {
      logAmplitudeEventOnce({
        eventName: 'Clicked User Menu',
        eventProperties: {},
      })
    }
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const userMenuItems = user.data ? LoggedInUserMenuItems : NonLoggedInUserMenuItems({ tld })

  return (
    <>
      {user.data ? (
        <Stack direction="row" alignItems="center" flexShrink={0} gap={3} sx={{ display: { xs: 'flex', lg: 'none' } }}>
          {isMobile && <NotificationsButton colorContrast={colorContrast} />}
          <Avatar
            src={user.data?.profilePicture?.xSmall}
            onClick={toggleDialog}
            sx={{
              width: AVATAR_DIMENSION,
              height: AVATAR_DIMENSION,
            }}
          />
        </Stack>
      ) : (
        <Box
          onClick={toggleDialog}
          sx={{
            cursor: 'pointer',
            width: AVATAR_DIMENSION,
            height: AVATAR_DIMENSION,
            display: { xs: 'flex', lg: 'none' },
            flexShrink: 0,
          }}
        >
          <Svg
            src={MenuIcon}
            width={24}
            height={24}
            color={colorContrast === ColorContrast.NORMAL ? 'darkgray' : 'white'}
          />
        </Box>
      )}
      <Dialog open={isOpen} onClose={toggleDialog} fullScreen keepMounted>
        <DialogContent sx={{ p: 3, backgroundColor: 'eggshell' }}>
          <Box
            onClick={toggleDialog}
            sx={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', marginBottom: 3 }}
          >
            <Icon src={CloseIcon} size="default" color="darkgray" />
          </Box>
          {user.data && (
            <>
              <Stack direction="row" alignItems="center" gap={3} marginBottom={1.5}>
                <Avatar src={user.data.profilePicture?.xSmall} sx={{ width: 50, height: 50 }} />
                <Text variant="large" color="midnight" sx={{ fontWeight: 'bold' }}>
                  {user.data.firstName}
                </Text>
              </Stack>
              <UserMenuLink userItem={HomeUserItem} onClose={handleClose} />
              <CorporateBenefits corporateBenefits={user.data.corporateBenefits} />
              <NotificationsUserMenuLink />
              {PublicUserFeatureMenuItems({ userHandle: user.data.handle }).map((item) => (
                <UserMenuLink key={item.to} userItem={item} onClose={handleClose} />
              ))}
              <RecommendationsPageLink hasRecommendations={user.hasRecommendations} />
            </>
          )}
          {!user.data && (
            <Stack direction="row" gap={2} mb={3}>
              <RegisterButton authUrlParams={authUrlParams} />
              <LoginButton authUrlParams={authUrlParams} />
            </Stack>
          )}
          {userMenuItems.map((item) => (
            <UserMenuLink key={item.to} userItem={item} onClose={handleClose} />
          ))}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default MobileUserMenuDialogComponent
