import getParsedSearchParams from '../../../utils/get-parsed-search-params'
import {
  ActivityAvailability,
  CourtAreaType,
  CourtSurfaceType,
  VenueAttributes,
  VenueOfferingFilterType,
} from '../Discover.types'
import { ShownVenueAmenitiesType } from '../../../App.types'

export const defaultDiscoverScreenInitialFilters = {
  venueCourtSurfaceActiveFilters: undefined,
  venueCourtAreasActiveFilters: undefined,
  venueAmenitiesActiveFilters: undefined,
  venueOfferingsActiveFilters: undefined,
  venueActivityAvailabilityActiveFilters: undefined,
  venueAttributeActiveFilters: undefined,
  subSportsActiveFilters: undefined,
  shouldConsiderDistanceForSearch: false,
}

const getDiscoverScreenInitialFilters = () => {
  const parsedSearchParams = getParsedSearchParams()

  if (!parsedSearchParams.size) return defaultDiscoverScreenInitialFilters

  const getArrayParam = (name: string) =>
    Array.isArray(parsedSearchParams.get(name)) ? (parsedSearchParams.get(name) as Array<string>) : undefined

  return {
    venueCourtSurfaceActiveFilters: getArrayParam('venueCourtSurfaceActiveFilters') as Array<CourtSurfaceType>,
    venueCourtAreasActiveFilters: getArrayParam('venueCourtAreasActiveFilters') as Array<CourtAreaType>,
    venueAmenitiesActiveFilters: getArrayParam('venueAmenitiesActiveFilters') as Array<ShownVenueAmenitiesType>,
    venueOfferingsActiveFilters: getArrayParam('venueOfferingsActiveFilters') as Array<VenueOfferingFilterType>,
    venueActivityAvailabilityActiveFilters: getArrayParam(
      'venueActivityAvailabilityActiveFilters',
    ) as Array<ActivityAvailability>,
    venueAttributeActiveFilters: getArrayParam('venueAttributeActiveFilters') as Array<VenueAttributes>,
    subSportsActiveFilters: getArrayParam('subSportsActiveFilters') as Array<string>,
  }
}

export default getDiscoverScreenInitialFilters
