import { assertNever } from '@eversports/klimt-utilities/assert-never'

import { BaseNotification } from '../Notifications.types'
import { eventTypeToUrlMapping } from '../Notifications.constants'

const getNotificationLink = (notification: BaseNotification): { to: string; isExternal: boolean } | null => {
  if (!notification.link) return null
  switch (notification.link.__typename) {
    case 'NotificationLinkEvent':
      return {
        to: `/e/${eventTypeToUrlMapping[notification.link.event.eventType]}/${notification.link.event.shortId}`,
        isExternal: false,
      }
    case 'NotificationLinkEventSession':
      return {
        to: `/e/${eventTypeToUrlMapping[notification.link.bookableItem.eventType]}/${
          notification.link.bookableItem.shortId
        }`,
        isExternal: false,
      }
    case 'NotificationLinkMatch':
      return { to: `/match/${notification.link.match.id}`, isExternal: false }
    case 'NotificationLinkUrl':
      return { to: notification.link.url, isExternal: true }
    case 'NotificationLinkUser':
      return { to: `/user/${notification.link.user.handle}`, isExternal: false }
    case 'NotificationLinkVenue':
      return { to: `/s/${notification.link.venue.slug}`, isExternal: false }
    default:
      return assertNever(notification.link)
  }
}

export default getNotificationLink
