import * as React from 'react'
import { assertNever } from '@eversports/klimt-utilities/assert-never'
import Stack from '@eversports/klimt-primitives/Stack'

import useLoggedInUserType from '../../hooks/use-logged-in-user-type'
import { UserType } from '../../Match.types'
import { MatchVisibilityToggle } from '../MatchVisibility'

import MatchSettingsOrganizer from './MatchSettingsOrganizer'
import MatchSettingsParticipant from './MatchSettingsParticipant'

const MatchSettings = () => {
  const { userType } = useLoggedInUserType()

  switch (userType) {
    case UserType.ORGANIZER:
      return (
        <Stack
          direction={['column', 'row']}
          alignItems={['initial', 'center']}
          justifyContent="space-between"
          width="100%"
          gap={6}
        >
          <MatchSettingsOrganizer />
          <MatchVisibilityToggle />
        </Stack>
      )
    case UserType.PARTICIPANT:
    case UserType.EVERSPORTS_GUEST:
    case UserType.EXTERNAL_GUEST:
      return <MatchSettingsParticipant />
    default:
      return assertNever(userType)
  }
}

export default MatchSettings
