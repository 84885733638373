import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../localization/react'

import NoCommentsPlaceholderImage from './assets/no-comments-placeholder-image.webp'

const NoCommentsPlaceholderSection = () => {
  return (
    <Stack gap={2} justifyContent="center" alignItems="center">
      <img src={NoCommentsPlaceholderImage} width="96px" height="80px" loading="lazy" />
      <Text sx={{ color: 'gray', textShadow: '0px 1px 0px #FFFFFF' }}>
        <Localized id="no-comments-placeholder-text" />
      </Text>
    </Stack>
  )
}

export default NoCommentsPlaceholderSection
