import * as React from 'react'
import { default as MuiTab, TabProps } from '@mui/material/Tab'

type TabPropsWithoutId = Omit<TabProps, 'id'>

export interface Props extends TabPropsWithoutId {
  id: number
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

export const Tab = ({ id, ...rest }: Props) => {
  return <MuiTab {...rest} {...a11yProps(id)} />
}

export default Tab
