import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Divider from '@eversports/klimt-primitives/Divider'

import NavigationTab from './NavigationTab'
import { NavigationTabVariant, Tab } from './NavigationTabs.types'

interface Props {
  tabs: Array<Tab>
  activeTabIndex: number
  tabVariant: NavigationTabVariant
}

const NavigationTabs = ({ tabs, activeTabIndex, tabVariant }: Props) => (
  <Stack
    flexDirection="row"
    sx={{
      maxWidth: '100vw',
      overflow: 'auto',
      whiteSpace: 'nowrap',
    }}
  >
    {tabs.map((tab, i) => {
      const isCurrentTabActive = i === activeTabIndex || (i === 0 && activeTabIndex === null)
      const isPreviousTabActive = i > 0 && i - 1 === activeTabIndex
      return (
        <React.Fragment key={tab.to}>
          {i !== 0 && !isCurrentTabActive && !isPreviousTabActive && (
            <Divider orientation="vertical" sx={{ my: 3 }} flexItem />
          )}
          <NavigationTab
            to={tab.to}
            crawlerLink={tab.crawlerLink}
            localizedId={tab.localizedId}
            isExternal={tab.isExternal}
            isNew={tab.isNew}
            isActive={isCurrentTabActive}
            variant={tabVariant}
          />
        </React.Fragment>
      )
    })}
  </Stack>
)

export default NavigationTabs
