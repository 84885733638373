import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Icon from '@eversports/klimt-primitives/Icon'
import Link from '@eversports/klimt-primitives/Link'
import ChevronLeft from '@eversports/design-tokens/assets/icons/chevron-left.svg'

import { UserProfileTabsPrefix } from '../../../../../App.types'

const BackButton = () => {
  return (
    <Link to={UserProfileTabsPrefix.OVERVIEW} external>
      <Box
        sx={{
          display: { xs: 'initial', md: 'none' },
          padding: 2,
          width: 'fit-content',
          height: 'fit-content',
          border: '1px solid',
          borderColor: 'mediumgray',
          borderRadius: 10,
          cursor: 'pointer',
          backgroundColor: 'white',
          boxShadow: boxShadows.default,
          position: 'absolute',
          top: '16px',
          left: '16px',
        }}
      >
        <Icon src={ChevronLeft} size="small" color="midnight" />
      </Box>
    </Link>
  )
}

export default BackButton
