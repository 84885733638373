import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import * as React from 'react'

import { Localized } from '../../../localization/react'
import { City, SeoContent } from '../Discover.types'
import { Sport } from '../../../App.types'
import ContainedXLWidthContainer from '../../../components/ContainedXLWidthContainer'

import SportTagsSection from './SportTagsSection'
import ManagerPromoSection from './ManagerPromoSection'

interface Props {
  content: SeoContent | null
  sport: Sport
  city: City
}

const SeoSection: React.FC<React.PropsWithChildren<Props>> = ({ content, sport, city }) => {
  const hasBookableSports = Boolean(content?.bookableSports?.length)
  const hasSimiliarSports = Boolean(content?.similarSports?.length)

  return (
    <Box sx={{ contentVisibility: 'auto' }}>
      <ManagerPromoSection />
      <Box sx={{ backgroundColor: '#F9F8FA', position: 'relative', zIndex: 2 }}>
        <ContainedXLWidthContainer>
          <Stack gap={5} sx={{ px: 6, py: 8 }}>
            {hasBookableSports && (
              <SportTagsSection
                city={city}
                sports={content!.bookableSports}
                heading={
                  <Localized
                    id="bookable-sports-in-city-heading"
                    params={{ sportName: sport.name, cityName: city.name }}
                  />
                }
              />
            )}
            {hasSimiliarSports && (
              <SportTagsSection
                city={city}
                sports={content!.similarSports}
                heading={<Localized id="similar-sports-in-city-heading" params={{ sportName: sport.name }} />}
              />
            )}
          </Stack>
        </ContainedXLWidthContainer>
      </Box>
    </Box>
  )
}

export default React.memo(SeoSection)
