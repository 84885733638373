import { BreakpointType, breakpoints } from '@eversports/design-tokens/breakpoints'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import { customBreakpoints, CustomBreakpointType } from '../Gallery.constants'

const NUMBER_OF_IMAGES_TO_SHOW: { [key in BreakpointType | CustomBreakpointType]: number } = {
  xs: 1,
  sm: 3,
  CUSTOM_BREAKPOINT_1: 3,
  CUSTOM_BREAKPOINT_2: 5,
  md: 5,
  lg: 5,
  CUSTOM_BREAKPOINT_3: 7,
  CUSTOM_BREAKPOINT_4: 7,
  CUSTOM_BREAKPOINT_5: 7,
  CUSTOM_BREAKPOINT_6: 6,
  xl: 6,
  CUSTOM_BREAKPOINT_7: 6,
}

export const getNumberOfImagesToShow = (screenBreakpoint: BreakpointType | undefined): number => {
  const theme = useTheme()
  const isCustomBreakpoint1 = useMediaQuery(
    theme.breakpoints.between(customBreakpoints.CUSTOM_BREAKPOINT_1, customBreakpoints.CUSTOM_BREAKPOINT_2),
  )
  const isCustomBreakpoint2 = useMediaQuery(
    theme.breakpoints.between(customBreakpoints.CUSTOM_BREAKPOINT_2, breakpoints.md),
  )
  const isCustomBreakpoint3 = useMediaQuery(
    theme.breakpoints.between(customBreakpoints.CUSTOM_BREAKPOINT_3, customBreakpoints.CUSTOM_BREAKPOINT_4),
  )
  const isCustomBreakpoint4 = useMediaQuery(
    theme.breakpoints.between(customBreakpoints.CUSTOM_BREAKPOINT_4, customBreakpoints.CUSTOM_BREAKPOINT_5),
  )
  const isCustomBreakpoint5 = useMediaQuery(
    theme.breakpoints.between(customBreakpoints.CUSTOM_BREAKPOINT_5, customBreakpoints.CUSTOM_BREAKPOINT_6),
  )
  const isCustomBreakpoint6 = useMediaQuery(
    theme.breakpoints.between(customBreakpoints.CUSTOM_BREAKPOINT_6, breakpoints.xl),
  )
  const isCustomBreakpoint7 = useMediaQuery(
    theme.breakpoints.between(breakpoints.xl, customBreakpoints.CUSTOM_BREAKPOINT_7),
  )

  let numberOfImagesToShow = 0

  if (isCustomBreakpoint1) {
    numberOfImagesToShow = NUMBER_OF_IMAGES_TO_SHOW.CUSTOM_BREAKPOINT_1
  } else if (isCustomBreakpoint2) {
    numberOfImagesToShow = NUMBER_OF_IMAGES_TO_SHOW.CUSTOM_BREAKPOINT_2
  } else if (isCustomBreakpoint3) {
    numberOfImagesToShow = NUMBER_OF_IMAGES_TO_SHOW.CUSTOM_BREAKPOINT_3
  } else if (isCustomBreakpoint4) {
    numberOfImagesToShow = NUMBER_OF_IMAGES_TO_SHOW.CUSTOM_BREAKPOINT_4
  } else if (isCustomBreakpoint5) {
    numberOfImagesToShow = NUMBER_OF_IMAGES_TO_SHOW.CUSTOM_BREAKPOINT_5
  } else if (isCustomBreakpoint6) {
    numberOfImagesToShow = NUMBER_OF_IMAGES_TO_SHOW.CUSTOM_BREAKPOINT_6
  } else if (isCustomBreakpoint7) {
    numberOfImagesToShow = NUMBER_OF_IMAGES_TO_SHOW.CUSTOM_BREAKPOINT_7
  } else if (screenBreakpoint) {
    numberOfImagesToShow = NUMBER_OF_IMAGES_TO_SHOW[screenBreakpoint]
  }

  return numberOfImagesToShow
}
