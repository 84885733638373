import Stack from '@eversports/klimt-primitives/Stack'
import Animation from '@eversports/klimt-animations/Animation'
import Text from '@eversports/klimt-primitives/Text'
import Link from '@eversports/klimt-primitives/Link'
import Button from '@eversports/klimt-primitives/Button'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import * as React from 'react'

import { Localized } from '../../../../localization/react'
import SuccessActionAnimation from '../../assets/success-action-animation.lottie'
import { BallsportMatchState } from '../../Match.types'

interface Props {
  onClick: () => void
  to: string
  bookingState?: BallsportMatchState
}

const PublicMatchCreatedDisclaimerContent = ({ onClick, bookingState, to }: Props) => {
  return (
    <Stack gap={3} alignItems="center">
      <Animation src={SuccessActionAnimation} style={{ width: '160px', height: '160px' }} autoplay />
      <Stack gap={1} alignItems="center">
        <Text sx={{ fontWeight: 'bold' }}>
          <Localized id="public-match-created-disclaimer-title" />
        </Text>
        {bookingState && (
          <Text sx={{ color: 'darkgray', textAlign: 'center' }}>
            <Localized
              id="public-match-created-disclaimer-description"
              params={{
                bookingState,
                matchesLink: (
                  <Link to={to} external>
                    <Text component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
                      <Localized id="public-match-created-disclaimer-matches-link-text" />
                    </Text>
                  </Link>
                ),
              }}
            />
          </Text>
        )}
      </Stack>
      <Button variant="primary" sx={{ p: 3 }} onClick={onClick} fullWidth>
        <Text sx={{ color: 'white', fontWeight: 'bold', textShadow: boxShadows.default, textTransform: 'none' }}>
          <Localized id="public-match-created-disclaimer-button" />
        </Text>
      </Button>
    </Stack>
  )
}

export default PublicMatchCreatedDisclaimerContent
