import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { assertNever } from '@eversports/klimt-utilities/assert-never'
import Link from '@eversports/klimt-primitives/Link'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import SectionCard from '../components/SectionCard'
import { PublicParticipation } from '../../../PublicUserProfile.types'
import { userTypeMapping } from '../../../PublicUserProfile.constants'
import useLoggedInUserType from '../../../hooks/use-logged-in-user-type'

import ParticipationDate from './ParticipationDate'
import ParticipationVenueInfo from './ParticipationVenueInfo'
import ParticipationTime from './ParticipationTime'
import getEventBookableItemLink from './helpers/get-event-bookable-item-link'

interface Props {
  participation: PublicParticipation
}

const UpcomingParticipation = ({ participation }: Props) => {
  const { userType } = useLoggedInUserType()
  const { amplitude } = useAmplitude()

  const handleClick = () => {
    if (!amplitude) return
    amplitude.logEvent('Clicked Upcoming Booking Tile', {
      type: participation.__typename === 'PublicEventSessionParticipation' ? 'activity' : 'court booking',
      userType: userTypeMapping[userType],
      venue: {
        name:
          participation.__typename === 'BallsportMatch'
            ? participation.venue.name
            : participation.bookableItem.venue.name,
      },
    })
  }

  switch (participation.__typename) {
    case 'PublicEventSessionParticipation':
      return (
        <Link to={getEventBookableItemLink(participation.bookableItem)} external blank>
          <SectionCard onClick={handleClick}>
            <Stack gap={4}>
              <Stack gap={1}>
                <Text sx={{ color: 'midnight' }}>{participation.bookableItem.sport.name}</Text>
                <Text sx={{ fontWeight: 'bold', color: 'midnight' }}>{participation.bookableItem.activityName}</Text>
              </Stack>
              <Stack direction="row" alignItems="center" gap={4}>
                <ParticipationDate date={participation.timeRange.start} />
                <ParticipationTime start={participation.timeRange.start} end={participation.timeRange.end} />
              </Stack>
              <ParticipationVenueInfo venue={participation.bookableItem.venue} />
            </Stack>
          </SectionCard>
        </Link>
      )
    case 'PublicCourtBooking':
      return (
        <SectionCard onClick={handleClick}>
          <Stack gap={4}>
            <Text sx={{ fontWeight: 'bold', color: 'midnight' }}>{participation.bookableItem.sport.name}</Text>
            <Stack direction="row" alignItems="center" gap={4}>
              <ParticipationDate date={participation.timeRange.start} />
              <ParticipationTime start={participation.timeRange.start} end={participation.timeRange.end} />
            </Stack>
            <ParticipationVenueInfo venue={participation.bookableItem.venue} />
          </Stack>
        </SectionCard>
      )
    case 'BallsportMatch':
      return (
        <SectionCard onClick={handleClick}>
          <Stack gap={4}>
            <Text sx={{ fontWeight: 'bold', color: 'midnight' }}>{participation.sport.name}</Text>
            <Stack direction="row" alignItems="center" gap={4}>
              <ParticipationDate date={participation.start} />
              <ParticipationTime start={participation.start} end={participation.end} />
            </Stack>
            <ParticipationVenueInfo venue={participation.venue} />
          </Stack>
        </SectionCard>
      )
    default:
      return assertNever(participation)
  }
}

export default UpcomingParticipation
