import * as React from 'react'

import { Teacher, BaseVenue } from '../../../App.types'

import DisabledTeacherCard from './DisabledTeacherCard'
import ActiveTeacherCard from './ActiveTeacherCard'

export interface Props {
  teacher: Omit<Teacher, 'recentlyTrainedUserAt'>
  venue: BaseVenue | null
}

const TeacherCard = ({ teacher, venue }: Props) => {
  return !venue || !venue.offerings.length ? (
    <DisabledTeacherCard teacher={teacher} venue={venue} />
  ) : (
    <ActiveTeacherCard teacher={teacher} venue={venue} />
  )
}

export default TeacherCard
