import { getSharedConfig } from '../../../config/get-config'

const GOOGLE_STATIC_MAPS_API_URL = 'https://maps.googleapis.com/maps/api/staticmap'

interface Props {
  latitude: number
  longitude: number
  width: number
  height: number
  zoomLevel: number
  scale: number
}

export const getStaticMapSrc = ({ latitude, longitude, width, height, zoomLevel, scale }: Props) => {
  const staticMapKey = getSharedConfig().GOOGLE_CLIENT_API_KEY

  const params = {
    center: `${latitude},${longitude}`,
    zoom: `${zoomLevel}`,
    size: `${width}x${height}`,
    scale: `${scale}`,
    markers: `size:small|${latitude},${longitude}`,
    key: staticMapKey,
  }

  const staticMapUrl = new URL(GOOGLE_STATIC_MAPS_API_URL)
  const searchParams = new URLSearchParams(params)
  staticMapUrl.search = searchParams.toString()

  return staticMapUrl.toString()
}
