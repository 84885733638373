export interface CorporatePartner {
  id: string
  name: string
  logo: Image | null
}

interface LimitedBudget {
  __typename: 'Limited'
  displayCapped: boolean
  limit: {
    value: number
    currency: string
  }
}

interface UnlimitedBudget {
  __typename: 'Unlimited'
  type: string
}

export interface AvailableBudget {
  display: LimitedBudget | UnlimitedBudget
}

export interface CorporateBenefits {
  __typename: 'CorporateBenefits'
  availableBudget: AvailableBudget | null
  corporatePartner: CorporatePartner
  hasSpecialPriceAccess: boolean
}

export interface User {
  id: string
  handle: string
  firstName: string
  profilePicture: Image | null
  corporateBenefits: CorporateBenefits | null
}

export enum VenueOfferingType {
  VENUE_OFFERING_VIDEO = 'VENUE_OFFERING_VIDEO',
  VENUE_OFFERING_LIVESTREAM = 'VENUE_OFFERING_LIVESTREAM',
  VENUE_OFFERING_INDOOR_ACTIVITY = 'VENUE_OFFERING_INDOOR_ACTIVITY',
  VENUE_OFFERING_OUTDOOR_ACTIVITY = 'VENUE_OFFERING_OUTDOOR_ACTIVITY',
  VENUE_OFFERING_CLASS_ACTIVITY = 'VENUE_OFFERING_CLASS_ACTIVITY',
  VENUE_OFFERING_TRAINING_ACTIVITY = 'VENUE_OFFERING_TRAINING_ACTIVITY',
  VENUE_OFFERING_COURSE_ACTIVITY = 'VENUE_OFFERING_COURSE_ACTIVITY',
  VENUE_OFFERING_WORKSHOP_ACTIVITY = 'VENUE_OFFERING_WORKSHOP_ACTIVITY',
  VENUE_OFFERING_EVENT_ACTIVITY = 'VENUE_OFFERING_EVENT_ACTIVITY',
  VENUE_OFFERING_RETREAT_ACTIVITY = 'VENUE_OFFERING_RETREAT_ACTIVITY',
  VENUE_OFFERING_EDUCATION_ACTIVITY = 'VENUE_OFFERING_EDUCATION_ACTIVITY',
  VENUE_OFFERING_CAMP_ACTIVITY = 'VENUE_OFFERING_CAMP_ACTIVITY',
  VENUE_OFFERING_OPENTRAINING_ACTIVITY = 'VENUE_OFFERING_OPENTRAINING_ACTIVITY',
  VENUE_OFFERING_SHOP = 'VENUE_OFFERING_SHOP',
  VENUE_OFFERING_SPORTS_FIELDS = 'VENUE_OFFERING_SPORTS_FIELDS',
  VENUE_OFFERING_PUBLIC_MATCHES = 'VENUE_OFFERING_PUBLIC_MATCHES',
  VENUE_OFFERING_TEAM = 'VENUE_OFFERING_TEAM',
}

export enum VenueAmenityType {
  VENUE_AMENITY_CARDIO = 'VENUE_AMENITY_CARDIO',
  VENUE_AMENITY_CARDPAYMENT = 'VENUE_AMENITY_CARDPAYMENT',
  VENUE_AMENITY_COURSES = 'VENUE_AMENITY_COURSES',
  VENUE_AMENITY_FITNESSDEVICES = 'VENUE_AMENITY_FITNESSDEVICES',
  VENUE_AMENITY_GASTRONOMY = 'VENUE_AMENITY_GASTRONOMY',
  VENUE_AMENITY_LOCKERROOM = 'VENUE_AMENITY_LOCKERROOM',
  VENUE_AMENITY_OTHER = 'VENUE_AMENITY_OTHER',
  VENUE_AMENITY_PARKING = 'VENUE_AMENITY_PARKING',
  VENUE_AMENITY_POOL = 'VENUE_AMENITY_POOL',
  VENUE_AMENITY_SANITARY = 'VENUE_AMENITY_SANITARY',
  VENUE_AMENITY_SHOP = 'VENUE_AMENITY_SHOP',
  VENUE_AMENITY_WELLNESS = 'VENUE_AMENITY_WELLNESS',
}

export type ShownVenueAmenitiesType = Extract<
  VenueAmenityType,
  | VenueAmenityType.VENUE_AMENITY_SANITARY
  | VenueAmenityType.VENUE_AMENITY_GASTRONOMY
  | VenueAmenityType.VENUE_AMENITY_SHOP
  | VenueAmenityType.VENUE_AMENITY_WELLNESS
  | VenueAmenityType.VENUE_AMENITY_LOCKERROOM
  | VenueAmenityType.VENUE_AMENITY_PARKING
  | VenueAmenityType.VENUE_AMENITY_CARDPAYMENT
>

export const AmenityTypeToNameMapping = {
  [VenueAmenityType.VENUE_AMENITY_SANITARY]: 'venue-amenity-shower',
  [VenueAmenityType.VENUE_AMENITY_GASTRONOMY]: 'venue-amenity-food',
  [VenueAmenityType.VENUE_AMENITY_SHOP]: 'venue-amenity-shop-and-rental',
  [VenueAmenityType.VENUE_AMENITY_WELLNESS]: 'venue-amenity-wellness-offer',
  [VenueAmenityType.VENUE_AMENITY_LOCKERROOM]: 'venue-amenity-changing-room',
  [VenueAmenityType.VENUE_AMENITY_PARKING]: 'venue-amenity-parking',
  [VenueAmenityType.VENUE_AMENITY_CARDPAYMENT]: 'venue-amenity-pay-with-card',
} as const

export enum VenueProfileTabsPrefix {
  OVERVIEW = '/s/',
  CLASSES = '/scl/',
  COURSES = '/sk/',
  CAMPS = '/sc/',
  EVENTS = '/sv/',
  EDUCATION = '/se/',
  WORKSHOPS = '/sw/',
  RETREATS = '/srt/',
  TRAINING = '/st/',
  OPEN_TRAININGS = '/sft/',
  PRICES = '/sp/',
  TEAM = '/trainers',
  SPORTS_FIELDS = '/sb/',
}

export const OfferingToVenueTabMapping = {
  [VenueOfferingType.VENUE_OFFERING_CLASS_ACTIVITY]: VenueProfileTabsPrefix.CLASSES,
  [VenueOfferingType.VENUE_OFFERING_COURSE_ACTIVITY]: VenueProfileTabsPrefix.COURSES,
  [VenueOfferingType.VENUE_OFFERING_CAMP_ACTIVITY]: VenueProfileTabsPrefix.CAMPS,
  [VenueOfferingType.VENUE_OFFERING_EVENT_ACTIVITY]: VenueProfileTabsPrefix.EVENTS,
  [VenueOfferingType.VENUE_OFFERING_EDUCATION_ACTIVITY]: VenueProfileTabsPrefix.EDUCATION,
  [VenueOfferingType.VENUE_OFFERING_WORKSHOP_ACTIVITY]: VenueProfileTabsPrefix.WORKSHOPS,
  [VenueOfferingType.VENUE_OFFERING_RETREAT_ACTIVITY]: VenueProfileTabsPrefix.RETREATS,
  [VenueOfferingType.VENUE_OFFERING_TRAINING_ACTIVITY]: VenueProfileTabsPrefix.TRAINING,
  [VenueOfferingType.VENUE_OFFERING_OPENTRAINING_ACTIVITY]: VenueProfileTabsPrefix.OPEN_TRAININGS,
  [VenueOfferingType.VENUE_OFFERING_SHOP]: VenueProfileTabsPrefix.PRICES,
  [VenueOfferingType.VENUE_OFFERING_VIDEO]: VenueProfileTabsPrefix.OVERVIEW,
  [VenueOfferingType.VENUE_OFFERING_SPORTS_FIELDS]: VenueProfileTabsPrefix.SPORTS_FIELDS,
  [VenueOfferingType.VENUE_OFFERING_PUBLIC_MATCHES]: VenueProfileTabsPrefix.OVERVIEW,
  [VenueOfferingType.VENUE_OFFERING_TEAM]: VenueProfileTabsPrefix.OVERVIEW,
  // We map them to the overview tab as they don't have a tab representation in the venue profile
  [VenueOfferingType.VENUE_OFFERING_LIVESTREAM]: VenueProfileTabsPrefix.OVERVIEW,
  [VenueOfferingType.VENUE_OFFERING_INDOOR_ACTIVITY]: VenueProfileTabsPrefix.OVERVIEW,
  [VenueOfferingType.VENUE_OFFERING_OUTDOOR_ACTIVITY]: VenueProfileTabsPrefix.OVERVIEW,
}

export const isOfferingASingleSessionActivity = (venueOfferingType: VenueOfferingType) =>
  [
    VenueOfferingType.VENUE_OFFERING_CLASS_ACTIVITY,
    VenueOfferingType.VENUE_OFFERING_TRAINING_ACTIVITY,
    VenueOfferingType.VENUE_OFFERING_OPENTRAINING_ACTIVITY,
  ].includes(venueOfferingType)

// TODO: Keep "BaseVenue" as the "Venue" exported from App.types
// In the Discover.types, create an extended Venue, and move OnlineVenue there
// Update the reference in all the Discover files.
export interface BaseVenue {
  id: string
  name: string
  slug: string
  offerings: Array<VenueOfferingType>
}

export interface Venue extends BaseVenue {
  profileImage: Image | null
  rating: number | null
  reviewCount: number
  sports: Array<Sport>
  tags: Array<Tag>
  address: string
  location: {
    latitude: number | null
    longitude: number | null
  }
  company: Company
  slotsToDiscover?: Array<Slot>
  specialPriceTypes: Array<SpecialPriceType>
}

export interface Branch {
  id: string
  name: string
  logo: Image | null
  location: Location
  slug: string
}

export interface Location {
  latitude?: number | null
  longitude?: number | null
  city: string
  zip: string
  country: string
}

export interface Company {
  venues: Array<Branch>
}

export interface Slot {
  start: string
  end: string
  isAvailable: boolean
}

export type OnlineVenue = Omit<Venue, 'location' | 'address'>

export interface ManagerLinks {
  managerLinks: {
    business: string
    corporate: string
  }
}

export interface Image {
  xSmall?: string
  small?: string
  medium?: string
  large?: string
}

export interface LatLng {
  latitude: number
  longitude: number
}

export interface Sport {
  id: string
  name: string
  slug: string
}

export interface Tag {
  name: string
}

export interface MonetaryValue {
  currency: string
  value: number
}

export interface Teacher {
  id: string
  firstName: string | null
  lastName: string | null
  image: Image | null
  recentlyTrainedUserAt: BaseVenue | null
  sports: Array<Sport>
  nickname: string
}

export enum RatingType {
  ATMOSPHERE = 'ATMOSPHERE',
  CLEANLINESS = 'CLEANLINESS',
  FRIENDLINESS_SERVICE = 'FRIENDLINESS_SERVICE',
  PRICE_PERFORMANCE = 'PRICE_PERFORMANCE',
  ROOM_QUALITY = 'ROOM_QUALITY',
}

interface SocialInterface {
  type: string
  siteName: string
  title: string
  description: string | null
  url: string
  image: Image
}

interface Social {
  openGraph: SocialInterface
  twitter: Pick<SocialInterface, 'type' | 'title' | 'description' | 'image'>
}

export interface Meta {
  title: string
  description: string | null
  social: Social
}

export enum UserProfileTabsPrefix {
  OVERVIEW = '/u',
  PASSES_AND_MEMBERSHIPS = '/credits',
}

export enum TrackingType {
  ANALYTICS = 'ANALYTICS',
  ADVERTISING = 'ADVERTISING',
}

export enum SpecialPriceType {
  CORPORATE_EMPLOYEES = 'CORPORATE_EMPLOYEES',
}

export interface Match {
  id: string
  start: string
  end: string
  venue: MatchVenue
  sport: MatchSport
  participants: Array<Participants>
  maxParticipants: number | null
  level: MatchLevel | null
  competitionType: MatchCompetitionType | null
  gender: MatchGender | null
  visibility: MatchVisibility
  price: MonetaryValue | null
}

export interface MatchUser {
  __typename: 'User'
  id: string
  firstName: string
  lastName: string
  profilePicture: Image | null
}

interface Participants {
  id: string
  user: PublicUserV2 | MatchUser
}

export interface PublicUserV2 {
  __typename: 'PublicUserV2'
  id: string
  firstName: string
  lastName: string
  profilePicture: Image | null
}

export interface MatchVenue {
  id: string
  slug: string
  name: string
  location: {
    city: string
    zip: string
  }
  logo: Image | null
}

export interface MatchSport {
  id: string
  name: string
  slug: string
  category: SportCategory
}

export enum MatchCompetitionType {
  COMPETITIVE = 'COMPETITIVE',
  FRIENDLY = 'FRIENDLY',
}

export enum MatchGender {
  UNISEX = 'UNISEX',
  MALE_ONLY = 'MALE_ONLY',
  FEMALE_ONLY = 'FEMALE_ONLY',
}

export enum MatchVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum MatchLevel {
  ANY = 'ANY',
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
}

enum SportCategory {
  BALLSPORT = 'BALLSPORT',
  FITNESS = 'FITNESS',
  FUNSPORT = 'FUNSPORT',
}

interface OpenEndTimeRangeInput {
  start: string
  end?: string
}

interface Coordinate {
  latitude: number
  longitude: number
}

export type MatchFilters = {
  competitionTypes?: Array<MatchCompetitionType>
  genders?: Array<MatchGender>
  hasOpenSpots?: boolean
  levels?: Array<MatchLevel>
  sportIds?: Array<string>
  timeRange?: OpenEndTimeRangeInput
  venueId?: string
  venueIds?: Array<string>
  visibility?: MatchVisibility
  geoSearch?: { coordinate: Coordinate; radius: number }
}

export enum EventType {
  CAMP = 'Camp',
  CLASS = 'Class',
  COURSE = 'Course',
  EDUCATION = 'Education',
  EVENT = 'Event',
  OPENTRAINING = 'OpenTraining',
  RETREAT = 'Retreat',
  TRAINING = 'Training',
  WORKSHOP = 'Workshop',
}

export enum DiscoverPrefix {
  SEO_SPORT_CITY = '/l',
  APP_SPORT_CITY = '/a/l',
}

export enum MarketplaceListingPagesPrefix {
  CLASSES = '/dcl',
  TRAININGS = '/dtr',
  COURSES = '/dco',
  WORKSHOPS = '/dwo',
  EVENTS = '/dev',
  RETREATS = '/dre',
  EDUCATION = '/ded',
  CAMPS = '/dca',
  OPEN_TRAININGS = '/dotr',
  LIVESTREAMS = '/doc/online-classes',
  VIDEOS = '/discover/videos',
  PUBLIC_MATCHES = '/matches',
}

export enum From {
  HEADER = 'header',
  USER_MENU = 'user-menu',
  MY_ACCOUNT = 'my-account',
  MY_PROFILE = 'my-profile',
  MATCH_DETAIL = 'match-detail',
  MY_FRIENDS_LIST = 'my-friends-list',
  MY_SENT_FRIEND_REQUESTS = 'my-sent-friend-requests',
  MY_RECEIVED_FRIEND_REQUESTS = 'my-received-friend-requests',
  USER_FRIENDS_LIST = 'user-friends-list',
  USER_SEARCH = 'user-search',
  ACTIVITY_FRIENDS = 'activity-friends',
  FRIENDS_ATTENDING_PLACEHOLDER = 'friends-attending-placeholder',
  SHARE_WITH_FRIENDS_PLACEHOLDER = 'share-with-friends-placeholder',
  PROMOTION_NOTIFICATION_CENTER = 'promotion-notification-center',
  SUGGESTED_FRIENDS_MUTUAL_FRIEND = 'suggested-friends-mutual-friend',
  SUGGESTED_FRIENDS_MUTUAL_ACTIVITY = 'suggested-friends-mutual-activity',
  FRIENDS_UPCOMING_PARTICIPATIONS_PLACEHOLDER = 'friends-upcoming-participations-placeholder',
}

export enum FromType {
  HEADER = 'HEADER',
  USER_MENU = 'USER_MENU',
  MY_ACCOUNT = 'MY_ACCOUNT',
  MATCH_DETAIL = 'MATCH_DETAIL',
  MY_FRIENDS_LIST = 'MY_FRIENDS',
  MY_SENT_FRIEND_REQUESTS = 'MY_SENT_REQUESTS',
  MY_RECEIVED_FRIEND_REQUESTS = 'MY_RECEIVED_REQUESTS',
  USER_FRIENDS_LIST = 'USER_FRIENDS',
  MY_PROFILE = 'MY_PROFILE',
  USER_SEARCH = 'USER_SEARCH',
  ACTIVITY_FRIENDS = 'ACTIVITY_FRIENDS',
  FRIENDS_ATTENDING_PLACEHOLDER = 'FRIENDS_ATTENDING_PLACEHOLDER',
  SHARE_WITH_FRIENDS_PLACEHOLDER = 'SHARE_WITH_FRIENDS_PLACEHOLDER',
  PROMOTION_NOTIFICATION_CENTER = 'PROMOTION_NOTIFICATION_CENTER',
  SUGGESTED_FRIENDS_MUTUAL_FRIEND = 'SUGGESTED_FRIENDS_MUTUAL_FRIEND',
  SUGGESTED_FRIENDS_MUTUAL_ACTIVITY = 'SUGGESTED_FRIENDS_MUTUAL_ACTIVITY',
  FRIENDS_UPCOMING_PARTICIPATIONS_PLACEHOLDER = 'FRIENDS_UPCOMING_PARTICIPATIONS_PLACEHOLDER',
}

export enum ShowType {
  FILTERS = 'filters',
  MAP = 'map',
  SEARCH = 'search',
}

export enum PrivacySettingVisibility {
  PUBLIC = 'PUBLIC',
  FRIEND = 'FRIEND',
  PRIVATE = 'PRIVATE',
}

export enum PrivacySettingField {
  FAVORITE_VENUES = 'FAVORITE_VENUES',
  UPCOMING_BOOKINGS = 'UPCOMING_BOOKINGS',
  PROFILE = 'PROFILE',
  FRIENDS = 'FRIENDS',
}

export enum Origin {
  MARKETPLACE = 'MARKETPLACE',
  WIDGET = 'WIDGET',
}

export interface URLParams {
  origin: Origin
  returnTo?: string
  from?: string
  show?: string
  view?: string
}

export class NotFoundError extends Error {
  type = 'NotFoundError'

  constructor(name: string, message: string, ...args: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(...args, message)
    Object.setPrototypeOf(this, NotFoundError.prototype)
    this.name = name
  }
}

export class ForbiddenError extends Error {
  type = 'ForbiddenError'

  constructor(message: string, ...args: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(...args, message)
    Object.setPrototypeOf(this, ForbiddenError.prototype)
    this.name = 'ForbiddenError'
  }
}

export type CustomError = Error & { type: 'NotFoundError' | 'ForbiddenError' | 'Error' }
