import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../../../localization/react'

interface Props {
  onClick: () => void
}

const ResetField = ({ onClick }: Props) => {
  return (
    <Text
      variant="small"
      sx={{ color: 'primary.main', textDecoration: 'underline', cursor: 'pointer', ml: 2 }}
      onClick={onClick}
    >
      <Localized id="reset-participation-rule" />
    </Text>
  )
}

export default ResetField
