import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import IconLogo from '@eversports/design-tokens/assets/logo/icon.svg'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import { Localized } from '../../../localization/react'
import { getRelativeTime } from '../../../helpers/get-relative-time'
import OrganizerCrown from '../assets/organizer-crown.svg'
import useLoggedInUserType from '../hooks/use-logged-in-user-type'
import { UserType } from '../Match.types'

import { Comment } from './CommentsSection.types'

interface Props {
  comment: Comment
}

const Comment = ({ comment }: Props) => {
  const { userType, userId } = useLoggedInUserType()
  const commentDate = getRelativeTime(comment.createdAt)
  const isOrganizer = userType === UserType.ORGANIZER && comment.commentator.userId === userId

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        px: 3,
        py: 2,
        backgroundColor: 'white',
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'mediumgray',
        boxShadow: boxShadows.default,
      }}
    >
      <img
        src={comment.commentator.profilePicture?.xSmall || IconLogo}
        style={{ borderRadius: '80px' }}
        alt="User Picture"
        height="24px"
        width="24px"
        loading="lazy"
      />
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Text sx={{ color: 'midnight' }}>{comment.commentator.name}</Text>
            {isOrganizer && <img src={OrganizerCrown} alt="Organizer Crown" width="12px" height="12px" />}
          </Stack>
          <Text sx={{ color: 'gray' }}>•</Text>
          <Text sx={{ color: 'carbon' }}>
            <Localized
              id="date-time-relative-to-now"
              params={{ timeFrame: commentDate.timeFrame, timeValue: commentDate.timeValue }}
            />
          </Text>
        </Stack>
        <Text variant="xsmall">{comment.text}</Text>
      </Stack>
    </Stack>
  )
}

export default Comment
