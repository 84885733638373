import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Menu from '@eversports/klimt-primitives/Menu'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Avatar from '@eversports/klimt-primitives/Avatar'
import { useRef } from 'react'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'
import SeoLink from '@eversports/klimt-primitives/SeoLink'
import Icon from '@eversports/klimt-primitives/Icon'

import { Localized } from '../../../localization/react'
import { LoggedInUser } from '../../../hooks/useLoggedInUser'
import RegisterButton from '../RegisterButton'
import { ColorContrast } from '../Header.types'
import { textColorContrastMapping } from '../Header.constants'
import RecommendationsPageLink from '../RecommendationsPageLink'

import DashMenu from './assets/DashMenu.svg'
import { HomeUserItem, LoggedInUserMenuItems, NotificationsUserItem, PublicUserFeatureMenuItems } from './UserMenuItems'
import { AuthRoutes } from './UserMenu.constants'
import UserMenuLink from './UserMenuLink'

interface Props {
  user: LoggedInUser
  authUrlParams: string
  colorContrast?: ColorContrast
}

const DesktopUserMenuDropdownComponent = ({ user, authUrlParams, colorContrast = ColorContrast.NORMAL }: Props) => {
  return (
    <Stack direction="row">
      {user.data ? (
        <LoggedInMenuItems user={user} colorContrast={colorContrast} />
      ) : (
        <NonLoggedInMenuItems authUrlParams={authUrlParams} colorContrast={colorContrast} />
      )}
    </Stack>
  )
}

const NonLoggedInMenuItems = ({
  authUrlParams,
  colorContrast,
}: {
  authUrlParams: string
  colorContrast: ColorContrast
}) => {
  return (
    <Stack direction="row" alignItems="center" gap={4}>
      <Text variant="small" sx={{ color: colorContrast === ColorContrast.NORMAL ? 'darkgray' : 'white' }}>
        |
      </Text>
      <SeoLink
        type="crawler"
        encodedTo={btoa(`${AuthRoutes.LOGIN}${authUrlParams}`)}
        crawlerLink={AuthRoutes.LOGIN}
        external
        ariaLabel="User login"
      >
        <Text
          variant="small"
          sx={{ fontWeight: 'bold', color: colorContrast === ColorContrast.NORMAL ? 'primary.main' : 'white' }}
        >
          <Localized id="header-login" />
        </Text>
      </SeoLink>
      <RegisterButton authUrlParams={authUrlParams} />
    </Stack>
  )
}

const LoggedInMenuItems = ({ user, colorContrast }: { user: LoggedInUser; colorContrast: ColorContrast }) => {
  const userMenuRef = useRef(null)
  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null)

  const handleClick = () => {
    setAnchorEl(userMenuRef.current)
    logAmplitudeEventOnce({
      eventName: 'Clicked User Menu',
      eventProperties: {},
    })
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!user.data) return null

  return (
    <>
      <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>
        <Stack forwardedRef={userMenuRef} direction="row" alignItems="center" gap={3}>
          <Avatar src={user.data.profilePicture?.xSmall} />
          <Text variant="label" color={textColorContrastMapping[colorContrast]} sx={{ fontWeight: 'bold' }}>
            {user.data.firstName}
          </Text>
          <Icon src={DashMenu} color={textColorContrastMapping[colorContrast]} size="small" />
        </Stack>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock
        sx={{
          '& .MuiMenu-paper': {
            border: '1px solid #F2F2F2',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.12)',
            borderRadius: '16px',
            padding: '8px 16px',
            marginTop: '10px',
          },
          '& .MuiMenu-list': {
            padding: 0,
          },
        }}
      >
        <UserMenuLink userItem={HomeUserItem} onClose={handleClose} />
        <UserMenuLink userItem={NotificationsUserItem} onClose={handleClose} />
        {PublicUserFeatureMenuItems({ userHandle: user.data.handle }).map((item) => (
          <UserMenuLink key={item.to} userItem={item} onClose={handleClose} />
        ))}
        <RecommendationsPageLink hasRecommendations={user.hasRecommendations} />
        {LoggedInUserMenuItems.map((item) => (
          <UserMenuLink key={item.to} userItem={item} onClose={handleClose} />
        ))}
      </Menu>
    </>
  )
}

export default DesktopUserMenuDropdownComponent
