import Box from '@eversports/klimt-primitives/Box'
import FormattedContent from '@eversports/klimt-primitives/FormattedContent'
import Stack from '@eversports/klimt-primitives/Stack'
import Svg from '@eversports/klimt-primitives/Svg'
import Text from '@eversports/klimt-primitives/Text'
import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import { Color } from '@eversports/design-tokens/palette'

import VenueIcon from './assets/venue.svg'
import RunnerIcon from './assets/runner.svg'
import { InventorySearchResult, LocationSearchResult } from './Search.types'
import ResultItemWrapper from './ResultItemWrapper'
import LocationResultIcon from './assets/locationResult.svg'
import GeoLocationIcon from './assets/geoLocation.svg'
import SearchAllSportsInCity from './assets/all-sport-in-city.svg'
import RecentSearchIcon from './assets/recentSearch.svg'
import { Localized, useIntl } from './../../localization/react'
import HighlightedResult from './components/HighlightedResult'
import getInventoryResultLink from './helpers/get-inventory-result-link'

interface ResultIconProps {
  iconSrc: string
  color: Color
  backgroundColor: string
}

const ResultIcon = ({ iconSrc, color, backgroundColor }: ResultIconProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '28px',
      height: '28px',
      borderRadius: 0.5,
      backgroundColor,
      flexShrink: 0,
    }}
  >
    <Svg src={iconSrc} width={20} height={20} color={color} />
  </Box>
)

interface Props<T> {
  results: Array<T>
  handleResultClick: (value: T) => void | Promise<void>
  selectedIndex?: number
  handleClose: () => void
}

const SearchResults = ({
  results,
  handleResultClick,
  selectedIndex,
  handleClose,
}: Props<LocationSearchResult | InventorySearchResult>) => {
  const intl = useIntl()
  return (
    <>
      {results.map((result, index) => {
        const isLastItem = index === results.length - 1
        const isFocused = selectedIndex === index

        const isRecent =
          (result.__typename === 'SportSearchResult' ||
            result.__typename === 'VenueSearchResult' ||
            result.__typename === 'CitySearchResult') &&
          Boolean(result?.isRecent)

        const handleClick = async () => {
          await handleResultClick(result)
          handleClose()
        }

        switch (result.__typename) {
          case 'CitySearchResult':
            return (
              <ResultItemWrapper
                key={result.id}
                handleClick={handleClick}
                isFocused={isFocused}
                isLastItem={isLastItem}
              >
                {isRecent ? (
                  <ResultIcon iconSrc={RecentSearchIcon} color="tertiary" backgroundColor="rgba(242, 153, 74, 0.12)" />
                ) : (
                  <ResultIcon iconSrc={LocationResultIcon} color="darkgray" backgroundColor="mediumgray" />
                )}
                <Box sx={{ display: 'inline-flex', color: 'midnight' }}>
                  <Stack gap={1} alignItems="flex-start">
                    {isRecent ? (
                      <>
                        <Text variant="large" color="midnight">
                          {result.name}
                        </Text>
                        <Text variant="small" color="carbon">
                          {result.country}
                        </Text>
                      </>
                    ) : (
                      <>
                        <FormattedContent htmlContent={result.matchedName} WrapperComponent={HighlightedResult} />
                        <Text variant="small" color="carbon">
                          {result.country}
                        </Text>
                      </>
                    )}
                  </Stack>
                </Box>
              </ResultItemWrapper>
            )

          case 'currentLocation':
            return (
              <ResultItemWrapper
                key={result.id}
                handleClick={handleClick}
                isFocused={isFocused}
                isLastItem={isLastItem}
              >
                <ResultIcon iconSrc={GeoLocationIcon} color="primary" backgroundColor="rgba(33, 166, 150, 0.12)" />
                <Text variant="large" color="primary.main" sx={{ paddingY: 2.5 }}>
                  <Localized id="search-current-location" />
                </Text>
              </ResultItemWrapper>
            )

          case 'VenueSearchResult':
            return (
              <Link key={result.id} to={getInventoryResultLink(result)} ariaLabel={result.name}>
                <ResultItemWrapper handleClick={handleClick} isFocused={isFocused} isLastItem={isLastItem}>
                  {isRecent ? (
                    <ResultIcon
                      iconSrc={RecentSearchIcon}
                      color="tertiary"
                      backgroundColor="rgba(242, 153, 74, 0.12)"
                    />
                  ) : (
                    <ResultIcon iconSrc={VenueIcon} color="secondary" backgroundColor="rgba(119, 0, 238, 0.12)" />
                  )}
                  <Stack gap={1} alignItems="flex-start">
                    {isRecent ? (
                      <Text variant="large" color="midnight">
                        {result.name}
                      </Text>
                    ) : (
                      <Box sx={{ display: 'inline-flex', color: 'midnight' }}>
                        <FormattedContent htmlContent={result.matchedName} WrapperComponent={HighlightedResult} />
                      </Box>
                    )}
                    <Text variant="small" color="carbon">
                      {`${result.location.zip} ${result.location.city}`}
                    </Text>
                  </Stack>
                </ResultItemWrapper>
              </Link>
            )

          case 'SportSearchResult': {
            return (
              <Link
                key={result.id}
                to={getInventoryResultLink(result)}
                ariaLabel={intl.searchSportInCity({ sportName: result.name, cityName: result.cityName })}
              >
                <ResultItemWrapper handleClick={handleClick} isFocused={isFocused} isLastItem={isLastItem}>
                  {isRecent ? (
                    <ResultIcon
                      iconSrc={RecentSearchIcon}
                      color="tertiary"
                      backgroundColor="rgba(242, 153, 74, 0.12)"
                    />
                  ) : (
                    <ResultIcon iconSrc={RunnerIcon} color="primary" backgroundColor="rgba(33, 166, 150, 0.12)" />
                  )}
                  <Stack gap={1} alignItems="flex-start">
                    {isRecent ? (
                      <Text variant="large" color="midnight">
                        {result.name}
                      </Text>
                    ) : (
                      <Box sx={{ display: 'inline-flex', color: 'midnight' }}>
                        <FormattedContent htmlContent={result.matchedName} WrapperComponent={HighlightedResult} />
                      </Box>
                    )}
                    <Text variant="small" color="carbon">
                      {`in ${result.cityName}`}
                    </Text>
                  </Stack>
                </ResultItemWrapper>
              </Link>
            )
          }

          case 'AllSportsInCity':
            return (
              <Link
                key={result.id}
                to={getInventoryResultLink(result)}
                ariaLabel={intl.searchVenuesInCity({ cityName: result.cityName })}
              >
                <ResultItemWrapper handleClick={handleClick} isFocused={isFocused} isLastItem={isLastItem}>
                  <ResultIcon iconSrc={SearchAllSportsInCity} color="darkgray" backgroundColor="mediumgray" />
                  <Stack gap={1} alignItems="flex-start">
                    <Text variant="large" color="midnight">
                      <Localized id="search-view-all-sports-in-city" params={{ cityName: result.cityName }} />
                    </Text>
                    <Text variant="small" color="carbon">
                      <Localized id="search-view-venues-and-studios" />
                    </Text>
                  </Stack>
                </ResultItemWrapper>
              </Link>
            )
        }
      })}
    </>
  )
}

export default SearchResults
