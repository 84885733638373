import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'

import { Localized } from '../../localization/react'

interface ButtonProps {
  isActiveAtVenue: boolean
  isOverviewPage?: boolean
}

const TeacherCardButton = ({ isActiveAtVenue, isOverviewPage = false }: ButtonProps) => {
  return !isActiveAtVenue ? (
    <Button disabled variant="primary" sx={{ width: 'fit-content', margin: 'auto', textTransform: 'none' }}>
      <Text variant="small" sx={{ color: 'darkgray', fontWeight: 'bold' }}>
        <Localized id="recent-teachers-teacher-card-button-no-classes-available" />
      </Text>
    </Button>
  ) : (
    <Button
      variant="secondary"
      sx={{
        width: 'fit-content',
        margin: 'auto',
        textTransform: 'none',
        '&:hover': { boxShadow: 'none', backgroundColor: 'transparent' },
      }}
    >
      <Text variant="small" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
        {isOverviewPage ? (
          <Localized id="recent-teachers-teacher-card-button-venue-overview" />
        ) : (
          <Localized id="recent-teachers-teacher-card-button-class-schedule" />
        )}
      </Text>
    </Button>
  )
}

export default TeacherCardButton
