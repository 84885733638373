import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Link from '@eversports/klimt-primitives/Link'
import { useParams } from 'react-router-dom'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../localization/react'
import MapIcon from '../assets/map.svg'
import { GOOGLE_MAPS_LINK } from '../Match.constants'
import { useOpenInGoogleMapsMatchQuery } from '../../../graphql'

import ActionWrapper from './ActionWrapper'
import { ActionWrapperVariant } from './AdditionalActions.types'

interface Props {
  variant?: ActionWrapperVariant
}

const OpenInGoogleMaps = ({ variant }: Props) => {
  const { id } = useParams<{ id: string }>()
  const { amplitude } = useAmplitude()

  const { data } = useOpenInGoogleMapsMatchQuery({ variables: { matchId: id } })

  if (!data) {
    return null
  }

  const { latitude, longitude } = data.match.venue.location

  if (!latitude || !longitude) {
    return null
  }

  const trackClick = React.useCallback(() => {
    if (amplitude) {
      amplitude.logEvent('Clicked Open in Maps')
    }
  }, [amplitude])

  return (
    <Link to={`${GOOGLE_MAPS_LINK}&query=${latitude},${longitude}`} blank external>
      <ActionWrapper onClick={trackClick} variant={variant}>
        <img src={MapIcon} alt="Add location in Google Maps" loading="lazy" width="14px" height="14px" />
        <Text>
          <Localized id="open-maps" />
        </Text>
      </ActionWrapper>
    </Link>
  )
}

export default OpenInGoogleMaps
