import * as React from 'react'
import { useEffect, useState } from 'react'
import { assertNever } from '@eversports/klimt-utilities/assert-never'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { useParams } from 'react-router-dom'

import useLoggedInUserType from '../../hooks/use-logged-in-user-type'
import { MatchStatus, UserType } from '../../Match.types'
import { Localized } from '../../../../localization/react'
import SharingActions from '../../components/SharingActions'
import MobileButtonContainer from '../components/MobileButtonContainer'
import ParticipationButton from '../components/ParticipationButton'
import { useInviteFriendsQuery } from '../../../../graphql'
import usePageType from '../../hooks/use-page-type'
import { pageTypeMapping } from '../../Match.constants'
import useMatchStatus from '../../hooks/use-match-status'

import { InviteFriendsModalLocalStorageKey } from './InviteFriends.constants'

interface Props {
  isMobile: boolean
}

const InviteFriends = ({ isMobile }: Props) => {
  const { userType } = useLoggedInUserType()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { amplitude } = useAmplitude()
  const matchStatus = useMatchStatus()

  const pageType = usePageType()
  const { id } = useParams<{ id: string }>()
  const { data } = useInviteFriendsQuery({ variables: { matchId: id } })

  useEffect(() => {
    let timer: null | ReturnType<typeof setTimeout> = null
    const hasSeenInviteFriendsModal = window.localStorage.getItem(InviteFriendsModalLocalStorageKey)

    if (!hasSeenInviteFriendsModal) {
      timer = setTimeout(() => setIsDialogOpen(true), 1000)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  if (!data) {
    return null
  }

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen)
    if (!window.localStorage.getItem(InviteFriendsModalLocalStorageKey)) {
      window.localStorage.setItem(InviteFriendsModalLocalStorageKey, 'true')
    }
  }

  const handleInviteButtonClick = () => {
    if (amplitude) {
      amplitude.logEvent('Clicked Invite Match Button', {
        from: pageTypeMapping[pageType],
        sport: data?.match.sport.slug,
        hasNotes: Boolean(data?.match.organizerNote),
      })
    }
    toggleDialog()
  }

  const isDisabled = matchStatus !== MatchStatus.UPCOMING

  switch (userType) {
    case UserType.ORGANIZER:
      return (
        <>
          {isMobile ? (
            <MobileButtonContainer>
              <ParticipationButton onClick={handleInviteButtonClick} isDisabled={isDisabled}>
                <Localized id="organizer-sharing-button" />
              </ParticipationButton>
            </MobileButtonContainer>
          ) : (
            <ParticipationButton onClick={handleInviteButtonClick} isDisabled={isDisabled}>
              <Localized id="organizer-sharing-button" />
            </ParticipationButton>
          )}
          <SharingActions isOpen={isDialogOpen} toggleDialog={toggleDialog} matchId={id} />
        </>
      )
    case UserType.PARTICIPANT:
    case UserType.EVERSPORTS_GUEST:
    case UserType.EXTERNAL_GUEST:
      return null
    default:
      return assertNever(userType)
  }
}

export default InviteFriends
