import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import { useParams } from 'react-router-dom'

import { useBannerSectionMatchQuery } from '../../../graphql'
import useLoggedInUserType from '../hooks/use-logged-in-user-type'

import PaymentStatus from './components/PaymentStatus'
import AccessCode from './components/AccessCode'
import BannerBackground from './assets/banner-background.webp'
import BannerMessageContainer from './BannerMessageContainer'
import BannerSectionMessage from './BannerSectionMessage'

const BannerSection = () => {
  const { id } = useParams<{ id: string }>()
  const { userType } = useLoggedInUserType()
  const { data } = useBannerSectionMatchQuery({ variables: { matchId: id } })

  if (!data) {
    return null
  }

  const match = data.match
  const organizer = data.match.organizerV2
  const sport = data.match.sport

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      position="relative"
      sx={{
        backgroundImage: `url(${BannerBackground as string})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        boxShadow: { xs: 'none', sm: boxShadows.default },
      }}
    >
      <BannerMessageContainer>
        <BannerSectionMessage sportName={sport.name} bookingState={match.state} />
        <Stack direction="row" alignItems="center" gap={4}>
          <PaymentStatus isPaid={match.isPaid} approvalState={match.state} />
          {organizer.__typename === 'PrivateMatchOrganizer' && organizer.customer?.accessPin && (
            <AccessCode accessCode={organizer.customer.accessPin} approvalState={match.state} userType={userType} />
          )}
        </Stack>
      </BannerMessageContainer>
    </Stack>
  )
}

export default BannerSection
