import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../../../localization/react'
import ContentWrapper from '../../components/ContentWrapper'
import { Trainer } from '../../ActivityDetails.types'
import getTrainerTranslationKey from '../../helpers/get-trainer-translation-key'
import { EventType } from '../../../../App.types'

import TrainerContent from './TrainerContent'

interface Props {
  trainer: Trainer
  eventType: EventType
}

const SingleTrainer = ({ trainer, eventType }: Props) => {
  const trainerHeadingKey = getTrainerTranslationKey(eventType)
  const { education, description } = trainer

  // Don't show if there's no descriptive info.
  // In that case, we will show just the teacher's name in the sidebar instead.
  const hideSectionOnDesktop = !description && !education

  return (
    <ContentWrapper sx={{ display: { xs: 'flex', md: hideSectionOnDesktop ? 'none' : 'flex' } }}>
      <Stack gap={4}>
        <Heading is="h2" color="midnight">
          <Localized id={trainerHeadingKey} params={{ trainerCount: 1 }} />
        </Heading>
        <TrainerContent trainer={trainer} />
      </Stack>
    </ContentWrapper>
  )
}

export default SingleTrainer
