import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { breakpoints } from '@eversports/design-tokens/breakpoints'

const ContainedXLWidthContainer = ({ children }: React.PropsWithChildren<unknown>) => (
  <Stack display="block" width="100%" maxWidth={breakpoints.xl} mx="auto" zIndex="1">
    {children}
  </Stack>
)

export default ContainedXLWidthContainer
