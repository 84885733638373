import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import { useEffect, useState } from 'react'
import { TabPanel } from '@eversports/klimt-primitives/Tabs'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import { UserFeaturePreviews } from '@eversports/react-app-base/user-feature-previews'

import useGetTopContentHeight from '../../../../../hooks/useGetTopContentHeight'
import { Localized, useIntl } from '../../../../../localization/react'
import NumberOfPendingFriendRequests from '../../../../../components/NumberOfPendingFriendRequests'
import MetaHelmet from '../../../../../components/MetaHelmet'
import getStaticPageMetadata from '../../../../../helpers/get-static-page-metadata'
import { User } from '../Friends.types'
import useEnabledFeaturePreviews from '../../../../../hooks/useEnabledFeaturePreviews'
import { FromType } from '../../../../../App.types'

import FriendsList from './FriendsList'
import RequestsList from './RequestsList'
import FriendsTabs from './FriendsTabs'
import FriendsTab from './FriendsTab'
import Navigation from './Navigation'
import { TabPanelIndexToAmplitudeEventNameMap } from './MyFriends.constants'
import FindFriends from './FindFriends'
import { FriendsTabsIndex, FriendsTabsIndexWithActivites } from './MyFriends.types'
import FriendsUpcomingParticipations from './FriendsUpcomingParticipations'

interface Props {
  me: User
  from: FromType | null
}

const FindFriendsCTAFromTypes = [
  FromType.FRIENDS_ATTENDING_PLACEHOLDER,
  FromType.SHARE_WITH_FRIENDS_PLACEHOLDER,
  FromType.PROMOTION_NOTIFICATION_CENTER,
  FromType.FRIENDS_UPCOMING_PARTICIPATIONS_PLACEHOLDER,
]

const MyFriendsComponent = ({ me, from }: Props) => {
  const intl = useIntl()
  const { origin } = useSettingsContext()
  const { amplitude } = useAmplitude()
  const featurePreviews = useEnabledFeaturePreviews()
  const [panelSelected, setPanelSelected] = useState(0)
  const isFriendsActivitiesTabEnabled = featurePreviews.includes(UserFeaturePreviews.FRIENDS_ACTIVITIES)
  const tabsIndex = isFriendsActivitiesTabEnabled ? FriendsTabsIndexWithActivites : FriendsTabsIndex

  // TODO remove this useEffect once we have individual routes per tab
  useEffect(() => {
    if (from && FindFriendsCTAFromTypes.includes(from) && isFriendsActivitiesTabEnabled) {
      setPanelSelected(FriendsTabsIndexWithActivites.FIND_FRIENDS)
    }
  }, [from, isFriendsActivitiesTabEnabled])

  const handleChange = (_event: React.SyntheticEvent, selectedPanel: FriendsTabsIndexWithActivites) => {
    if (amplitude) {
      amplitude.logEvent(TabPanelIndexToAmplitudeEventNameMap[selectedPanel])
    }
    setPanelSelected(selectedPanel)
  }

  const { DESKTOP_TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])

  const meta = getStaticPageMetadata({
    title: intl.friendsPageMetaTitle(),
    description: intl.friendsPageMetaDescription(),
    url: `${origin}/friends`,
  })

  return (
    <>
      <MetaHelmet meta={meta} noIndex />
      <Box
        sx={{
          backgroundColor: 'limelite',
          minHeight: `calc(100vh - ${DESKTOP_TOP_CONTENT_HEIGHT}px)`,
          height: '100%',
        }}
        component="main"
      >
        <Box sx={{ backgroundColor: 'white', borderBottom: '1px solid', borderColor: 'mediumgray' }}>
          <Stack
            margin="auto"
            maxWidth={{ xs: '100%', md: '600px' }}
            boxSizing="content-box"
            gap={{ xs: 3, md: 4 }}
            paddingTop={4}
          >
            <Navigation />
            <FriendsTabs value={panelSelected} onChange={handleChange}>
              {isFriendsActivitiesTabEnabled && <FriendsTab id={0} label={<Localized id="friends-activities-tab" />} />}
              <FriendsTab id={1} label={<Localized id="find-friends-tab" />} />
              <FriendsTab id={2} label={<Localized id="friends-tab" />} />
              <FriendsTab
                id={3}
                label={
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Localized id="friends-requests-tab" />
                    <NumberOfPendingFriendRequests />
                  </Stack>
                }
              />
            </FriendsTabs>
          </Stack>
        </Box>
        <Stack
          margin="auto"
          maxWidth={{ xs: '100%', md: '600px' }}
          boxSizing="content-box"
          padding={{ xs: 0, md: 6 }}
          paddingBottom={{ xs: 6 }}
          gap={{ xs: 3, md: 6 }}
        >
          {isFriendsActivitiesTabEnabled && (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore This is a valid value when rendered
            <TabPanel index={panelSelected} value={tabsIndex.FRIENDS_ACTIVITIES}>
              <FriendsUpcomingParticipations userHandle={me.handle} />
            </TabPanel>
          )}
          <TabPanel value={panelSelected} index={tabsIndex.FIND_FRIENDS}>
            <FindFriends profileImage={me.profilePicture?.small} />
          </TabPanel>
          <TabPanel value={panelSelected} index={tabsIndex.FRIENDS}>
            <FriendsList initiallyFetchedFriends={me.friends} friendsCount={me.friendsCount} />
          </TabPanel>
          <TabPanel value={panelSelected} index={tabsIndex.FRIEND_REQUESTS}>
            <RequestsList
              sentRequests={me.sentFriendRequests}
              receivedRequests={me.receivedFriendRequests}
              receivedRequestsCount={me.receivedFriendRequestsCount}
              sentRequestsCount={me.sentFriendRequestsCount}
            />
          </TabPanel>
        </Stack>
      </Box>
    </>
  )
}

export default MyFriendsComponent
