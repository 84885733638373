import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import * as React from 'react'

import { Localized } from '../../../../../localization/react'
import { DiscoverMatchesFiltersState } from '../DiscoverSportMatches.types'
import { Action } from '../helpers/discover-sport-matches-reducer'

interface Props {
  appliedFilters: DiscoverMatchesFiltersState
  dispatch: React.Dispatch<Action>
}

const ResetFilters = ({ appliedFilters, dispatch }: Props) => {
  const handleClick = () => {
    dispatch({ type: 'RESET_DISCOVER_MATCHES_FILTERS' })
  }

  const isVisible = Boolean(
    appliedFilters.sports?.length ||
      appliedFilters.venues?.length ||
      appliedFilters.levels?.length ||
      appliedFilters.competitionTypes?.length ||
      appliedFilters.date,
  )

  return isVisible ? (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      onClick={handleClick}
      display={{ xs: 'none', md: 'flex' }}
      gap={1}
      sx={{
        border: '1px solid',
        backgroundColor: 'white',
        borderColor: 'mediumgray',
        boxSizing: 'border-box',
        borderRadius: 10,
        cursor: 'pointer',
        py: 2,
        px: 5,
        '&:hover': {
          borderColor: 'primary.main',
        },
      }}
    >
      <Text variant="small" sx={{ color: 'primary.main' }}>
        <Localized id="filters-reset-all" />
      </Text>
    </Stack>
  ) : null
}

export default ResetFilters
