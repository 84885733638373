import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import { useEffect } from 'react'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import { useDiscoverReducer, useDiscoverState } from '../DiscoverContext'
import { CourtSurfaceType, CourtSurfaceTypeToNameMapping } from '../Discover.types'
import { Localized } from '../../../localization/react'
import FilterMenu from '../../../components/FilterMenu'
import FilterCheckbox from '../../../components/FilterCheckbox'

import { DiscoverFilterTypes } from './Filters.types'
import CollapsibleFilter from './components/CollapsibleFilter'
import getVenueFilterAmplitudeEventName from './helpers/get-venue-filter-amplitude-event-name'

interface InitialValues {
  venueCourtSurfaces: Array<CourtSurfaceType>
}

const VenueCourtSurfaceFilterForm = ({ availableSurfaces }: SurfaceFilterType) => {
  const dispatch = useDiscoverReducer()
  const { venueCourtSurfaceActiveFilters } = useDiscoverState()

  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()

  const methods = useForm<InitialValues>({
    defaultValues: { venueCourtSurfaces: venueCourtSurfaceActiveFilters || [] },
  })

  const { isDirty } = methods.formState
  const venueCourtSurfaces = methods.watch('venueCourtSurfaces')

  // Reset the form when venue active filters are reset and the form has been changed
  useEffect(() => {
    if (!isDirty || venueCourtSurfaceActiveFilters?.length) return
    methods.reset({ venueCourtSurfaces: [] })
  }, [venueCourtSurfaceActiveFilters])

  useEffect(() => {
    if (venueCourtSurfaces.length) {
      logAmplitudeEventOnce({
        eventName: getVenueFilterAmplitudeEventName(),
        eventProperties: { type: DiscoverFilterTypes.surface, tab: 'venue' },
      })
    }

    dispatch({ type: 'UPDATE_COURT_SURFACE_FILTER', payload: venueCourtSurfaces })
  }, [venueCourtSurfaces])

  return availableSurfaces ? (
    <Form methods={methods}>
      <Stack spacing={2}>
        {availableSurfaces.map((surface) => (
          <FilterCheckbox
            key={surface}
            name="venueCourtSurfaces"
            value={surface}
            label={<Localized id={CourtSurfaceTypeToNameMapping[surface] as any} />}
          />
        ))}
      </Stack>
    </Form>
  ) : null
}

interface SurfaceFilterType {
  availableSurfaces: Array<CourtSurfaceType> | null
}

export const DesktopVenueCourtSurfaceFilter = ({ availableSurfaces }: SurfaceFilterType) => {
  const { venueCourtSurfaceActiveFilters } = useDiscoverState()

  const venueCourtSurfaceFiltersTranslationKeys = venueCourtSurfaceActiveFilters
    ? venueCourtSurfaceActiveFilters
        .map((activeFilter) => {
          return CourtSurfaceTypeToNameMapping[activeFilter]
        })
        .reverse()
    : []

  const numberOfPossibleFilters = Object.keys(CourtSurfaceTypeToNameMapping).length
  const areAllFiltersSelected = venueCourtSurfaceFiltersTranslationKeys.length === numberOfPossibleFilters

  return (
    <FilterMenu
      header={<Localized id="filters-court-surface-heading" />}
      activeFilters={areAllFiltersSelected ? ['filters-court-all-selected'] : venueCourtSurfaceFiltersTranslationKeys}
    >
      <VenueCourtSurfaceFilterForm availableSurfaces={availableSurfaces} />
    </FilterMenu>
  )
}

export const MobileVenueCourtSurfaceFilter = ({ availableSurfaces }: SurfaceFilterType) => {
  return (
    <CollapsibleFilter header={<Localized id="filters-court-surface-heading" />}>
      <VenueCourtSurfaceFilterForm availableSurfaces={availableSurfaces} />
    </CollapsibleFilter>
  )
}
