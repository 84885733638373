import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

interface Props {
  isClickable?: boolean
  handleClick?: () => void
}

const TeacherCardContainer = ({ children, isClickable = false, handleClick }: React.PropsWithChildren<Props>) => (
  <Box
    onClick={handleClick}
    sx={{
      background: 'white',
      border: '0.5px solid',
      borderColor: 'lightgray',
      boxShadow: boxShadows.default,
      borderRadius: 1,
      minHeight: { xs: 'auto', md: '196px' },
      height: '100%',
      overflow: 'auto',
      py: { xs: 2, md: 4 },
      '&:hover': isClickable ? { boxShadow: boxShadows.hover } : 'none',
    }}
  >
    {children}
  </Box>
)

export default TeacherCardContainer
