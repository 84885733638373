import * as React from 'react'
import { useState, useEffect } from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Text from '@eversports/klimt-primitives/Text'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import CloseIcon from '@eversports/design-tokens/assets/icons/close.svg'
import Icon from '@eversports/klimt-primitives/Icon'

import { Localized } from '../../../../../localization/react'
import useLoggedInUserType from '../../../hooks/use-logged-in-user-type'
import { TOOLTIP_TYPE, userTypeMapping } from '../../../PublicUserProfile.constants'
import { UserType } from '../../../PublicUserProfile.types'
import { From } from '../../../../../App.types'
import PromotionalTooltip from '../../../components/PromotionalTooltip'

interface Props {
  count: number
  handle: string
}

const FriendsCount = ({ handle, count }: Props) => {
  const { amplitude } = useAmplitude()
  const { userType } = useLoggedInUserType()
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  useEffect(() => {
    if (userType !== UserType.FRIENDS) return
    if (
      window.localStorage.getItem(TOOLTIP_TYPE.SHARE_PROFILE) &&
      window.localStorage.getItem(TOOLTIP_TYPE.UPCOMING_BOOKINGS) &&
      !window.localStorage.getItem(TOOLTIP_TYPE.FRIENDS_OF_USER)
    ) {
      setIsTooltipOpen(true)
    }
  }, [])

  const handleTooltipClose = () => {
    localStorage.setItem(TOOLTIP_TYPE.FRIENDS_OF_USER, 'true')
    setIsTooltipOpen(false)
  }

  const handleClick = () => {
    handleTooltipClose()
    if (!amplitude) return
    amplitude.logEvent('Clicked Friends Count', { userType: userTypeMapping[userType] })
  }

  const from = userType === UserType.PROFILE_OWNER ? `?from=${From.MY_PROFILE}` : ''

  return (
    <>
      <Text variant="small" sx={{ color: 'gray' }}>
        •
      </Text>
      <PromotionalTooltip
        open={isTooltipOpen}
        content={
          <Stack gap={1}>
            <Stack direction="row" justifyContent="space-between">
              <Text sx={{ color: 'white', textAlign: 'left', fontWeight: 'bold' }}>
                <Localized id="user-connection-tooltip-friends-of-user-title" />
              </Text>
              <Box onClick={handleTooltipClose} sx={{ cursor: 'pointer' }}>
                <Icon size="small" color="white" src={CloseIcon} />
              </Box>
            </Stack>
            <Text variant="small" sx={{ color: 'white' }}>
              <Localized id="user-connection-tooltip-friends-of-user-description" />
            </Text>
          </Stack>
        }
      >
        <Link to={`${handle}/friends${from}`}>
          <Text sx={{ color: 'midnight', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleClick}>
            <Localized id="friends-count" params={{ count }} />
          </Text>
        </Link>
      </PromotionalTooltip>
    </>
  )
}

export default FriendsCount
