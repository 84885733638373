import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

import { BaseNotification } from './Notifications.types'
import Notification from './Notification'
import NotificationsBlockDate from './NotificationsBlockDate'

interface Props {
  date: string
  notifications: Array<BaseNotification>
}

const NotificationBlock = ({ date, notifications }: Props) => {
  return (
    <Stack gap={3} paddingX={{ xs: 4, sm: 0 }}>
      <NotificationsBlockDate date={date} />
      {notifications.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </Stack>
  )
}

export default NotificationBlock
