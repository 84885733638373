import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box from '@eversports/klimt-primitives/Box'
import Link from '@eversports/klimt-primitives/Link'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import MonetaryValue from '@eversports/klimt-localization/MonetaryValue'
import Avatar from '@eversports/klimt-primitives/Avatar'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { useLocation } from 'react-router-dom'

import { Localized } from '../../localization/react'
import { getDurationInMinutes } from '../../helpers/get-duration-in-minutes'
import { formatTime } from '../../helpers/format-time'
import { Match } from '../../App.types'
import getDaysUntilMatch from '../../helpers/get-days-until-match'
import { matchCompetitionTypeTranslation, matchGenderTranslation, matchLevelTranslation } from '../../App.constants'

import MatchDate from './MatchDate'
import OpenSpots from './OpenSpots'

interface Props {
  match: Match
  variant?: 'discovery' | 'venue-profile'
}

const NUMBER_OF_SHOWN_PARTICIPANTS = 2

const MatchCard = ({ match, variant = 'venue-profile' }: Props) => {
  const location = useLocation()
  const { language } = useSettingsContext()
  const { amplitude } = useAmplitude()
  const { start, end, maxParticipants, participants, sport, price } = match

  const userParticipants = participants.map((participant) => participant.user)

  const openSpots = maxParticipants ? maxParticipants - userParticipants.length : undefined
  const pricePerParticipant = price && maxParticipants ? price.value / maxParticipants : undefined
  const duration = getDurationInMinutes(start, end)
  const startTime = formatTime(language, start)
  const endTime = formatTime(language, end)

  const participantsToShow = userParticipants.slice(0, NUMBER_OF_SHOWN_PARTICIPANTS)
  const numberOfHiddenParticipants = userParticipants.length - participantsToShow.length

  const handleTileClick = () => {
    if (!amplitude) return
    amplitude.logEvent('Clicked Match Tile', {
      sport: {
        slug: sport.slug,
      },
      settings: {
        maxParticipants,
        level: match.level,
        competitionType: match.competitionType,
        gender: match.gender,
        visibility: match.visibility,
      },
      currentNumberOfParticipants: participants.length,
      pricePerParticipant:
        pricePerParticipant !== undefined && price
          ? {
              value: Number(Math.round(pricePerParticipant).toFixed(2)) / 100,
              currency: price.currency,
            }
          : undefined,
      daysUntilMatch: getDaysUntilMatch({ matchDate: new Date(start) }),
    })
  }

  return (
    <Link to={`/match/${match.id}?from=${location.pathname}`}>
      <Box
        onClick={handleTileClick}
        sx={{
          backgroundColor: 'white',
          border: '1px solid',
          borderColor: 'mediumgray',
          boxShadow: boxShadows.default,
          borderRadius: 1,
          height: '100%',
          '&:hover': {
            boxShadow: boxShadows.hover,
          },
        }}
      >
        <Stack gap={2} py={4} px={3}>
          <Stack direction="row" justifyContent="space-between">
            <Text variant="large" sx={{ fontWeight: 'bold' }}>
              {sport.name}
            </Text>
            {openSpots !== undefined && <OpenSpots openSpots={openSpots} />}
          </Stack>
          <Stack direction="row" flexWrap="wrap" gap={[1, 2]}>
            <MatchDate date={start} />
            <Text variant="small" sx={{ color: 'gray' }}>
              •
            </Text>
            <Text variant="small">
              {startTime} - {endTime} (
              <Localized id="match-duration-in-minutes-short" params={{ duration }} />)
            </Text>
          </Stack>
          <Stack direction="row" gap={1}>
            <Text variant="small">
              <Localized id={matchLevelTranslation[match.level!]} />
            </Text>
            <Text variant="small" sx={{ color: 'gray' }}>
              •
            </Text>
            <Text variant="small">
              <Localized id={matchCompetitionTypeTranslation[match.competitionType!]} />
            </Text>
            <Text variant="small" sx={{ color: 'gray' }}>
              •
            </Text>
            <Text variant="small">
              <Localized id={matchGenderTranslation[match.gender!]} />
            </Text>
          </Stack>
          {variant === 'discovery' && (
            <Stack direction="row" gap={2}>
              <Avatar
                alt={`${match.venue.name} Profile Picture`}
                src={match.venue.logo?.xSmall}
                sx={{
                  width: '33px',
                  height: '33px',
                  border: '1px solid',
                  borderColor: 'mediumgray',
                  backgroundColor: 'gray',
                }}
              />
              <Stack>
                <Text variant="small">{match.venue.name}</Text>
                <Text variant="xsmall" sx={{ color: 'carbon' }}>
                  {match.venue.location.zip}, {match.venue.location.city}
                </Text>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack
          py={2}
          px={3}
          sx={{
            background: 'linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0) 100%)',
            position: 'relative',
            minHeight: 37,
          }}
        >
          {pricePerParticipant !== undefined && price && (
            <Text>
              <Localized
                id="public-matches-price-per-player"
                params={{
                  price: (
                    <Text sx={{ fontWeight: 'bold' }} component="span">
                      <MonetaryValue value={pricePerParticipant} currencyCode={price.currency} />
                    </Text>
                  ),
                }}
              />
            </Text>
          )}
          <Stack direction="row" sx={{ position: 'absolute', right: 12, top: -12 }}>
            {participantsToShow.map((participant, id) => (
              <Avatar
                key={participant.id}
                alt="User Picture"
                src={participant.profilePicture?.xSmall}
                sx={{
                  marginLeft: id === 0 ? 0 : '-12px',
                  width: '24px',
                  height: '24px',
                  border: '1px solid white',
                  backgroundColor: 'gray',
                }}
              />
            ))}
            {numberOfHiddenParticipants > 0 && (
              <Box
                sx={{
                  marginLeft: -3,
                  width: '24px',
                  height: '24px',
                  borderRadius: 10,
                  border: '1px solid white',
                  backgroundColor: 'gray',
                  position: 'relative',
                  textAlign: 'center',
                }}
              >
                <Text sx={{ color: 'white' }}>+{numberOfHiddenParticipants}</Text>
              </Box>
            )}
          </Stack>
        </Stack>
      </Box>
    </Link>
  )
}

export default MatchCard
