import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import { isToday, isTomorrow } from 'date-fns'

import { Localized } from '../../localization/react'

interface Props {
  date: string
}

const getDateToDisplay = (date: string) => {
  const { language } = useSettingsContext()
  const dateObj = new Date(date)

  if (isToday(dateObj)) return <Localized id="today" />
  if (isTomorrow(dateObj)) return <Localized id="tomorrow" />

  const dateToShow = dateObj.toLocaleDateString(language, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })

  return <>{dateToShow}</>
}

const NotificationsBlockDate = ({ date }: Props) => {
  return (
    <Heading is="h4" sx={{ color: 'midnight' }}>
      {getDateToDisplay(date)}
    </Heading>
  )
}

export default NotificationsBlockDate
