import useCookies from '../../../hooks/useCookies'

const GEOLOCATION_COOKIE_KEY = 'discoveryMapGeoLocation'
const EXPIRE_MINUTES = 20

export const getLocationFromCookie = (): google.maps.LatLngLiteral | undefined => {
  const { getCookie } = useCookies()

  // see if cookie exists
  const rawLocationCookie = getCookie(GEOLOCATION_COOKIE_KEY)
  const locationCookieValue = rawLocationCookie?.replace(`${GEOLOCATION_COOKIE_KEY}=`, '')
  if (!locationCookieValue) return

  // if cookie exists, parse the value
  // then check for location specific properties
  const parsedLocation: google.maps.LatLngLiteral | undefined = JSON.parse(locationCookieValue)
  if (!parsedLocation || !parsedLocation.lat || !parsedLocation.lng) return
  return parsedLocation
}

export const setLocationCookie = (position: google.maps.LatLngLiteral) => {
  const { setTrackingCookie } = useCookies()

  // geolocation cookie should expire in X minutes - here we have 20 minutes
  // the js-cookie library takes the number of days for the expiry time
  // in order to have a cookie expire in less than a day, create a fraction
  const expireTime = EXPIRE_MINUTES / (24 * 60)

  setTrackingCookie(GEOLOCATION_COOKIE_KEY, position, { path: '/', expires: expireTime })
}
