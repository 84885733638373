import { default as MuiTableContainer, TableContainerProps } from '@mui/material/TableContainer'
import * as React from 'react'

import TableContainerCard from './TableContainerCard'

export const TableContainer: React.FC<React.PropsWithChildren<TableContainerProps>> = ({ sx, ...props }) => {
  return <MuiTableContainer {...props} component={TableContainerCard} sx={{ padding: 0, ...sx }} />
}

export default TableContainer
