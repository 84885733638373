import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Text from '@eversports/klimt-primitives/Text'
import Breadcrumbs from '@eversports/klimt-primitives/Breadcrumbs'

import { UserProfileTabsPrefix } from '../../../../App.types'
import { Localized } from '../../../../localization/react'

const BreadcrumbsNavigation = () => {
  return (
    <Breadcrumbs sx={{ display: { xs: 'none', md: 'initial' } }} aria-label="breadcrumb">
      <Link external to={UserProfileTabsPrefix.OVERVIEW}>
        <Text sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
          <Localized id="breadcrumb-navigation-item-user-profile" />
        </Text>
      </Link>
      <Text>
        <Localized id="breadcrumb-navigation-item-user-public-profile" />
      </Text>
    </Breadcrumbs>
  )
}

export default BreadcrumbsNavigation
