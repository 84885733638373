import * as React from 'react'
import { motion, AnimatePresence, LazyMotion, domMax, MotionStyle } from '@eversports/klimt-utilities/framer-motion'

import { CarouselDirection } from '../ImagesGallery'

const variants = {
  enter: (direction: CarouselDirection) => {
    return {
      x: direction === 'left' ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: CarouselDirection) => {
    return {
      zIndex: 0,
      x: direction === 'right' ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const DRAG_THRESHOLD = 50

interface Props {
  imgSrc: string
  index: number
  direction: CarouselDirection
  handleBack: () => void
  handleForward: () => void
  disableClick: boolean
  styles?: MotionStyle | undefined
}

const SlidingImage = ({ imgSrc, index, direction, handleBack, handleForward, disableClick, styles }: Props) => (
  <LazyMotion features={domMax}>
    <AnimatePresence initial={false} custom={direction}>
      <motion.img
        key={index}
        src={imgSrc}
        alt="Venue Image"
        loading={index === 0 ? 'eager' : 'lazy'}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: 0.5 }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        onClick={(e) => {
          if (disableClick) {
            e.stopPropagation()
          }
        }}
        onDragEnd={(_, { offset }) => {
          if (offset.x < -DRAG_THRESHOLD) {
            handleForward()
          } else if (offset.x > DRAG_THRESHOLD) {
            handleBack()
          }
        }}
        style={{
          ...styles,
          position: 'absolute',
          objectFit: 'cover',
        }}
      />
    </AnimatePresence>
  </LazyMotion>
)

export default SlidingImage
