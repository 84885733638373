import { useContext } from 'react'

import { HttpStatusContext } from './http-status-context'

export function useHttpStatus() {
  const context = useContext(HttpStatusContext)
  if (!context) {
    return { setStatus: () => void 0 }
  }
  return { setStatus: context.setStatus }
}
