import * as React from 'react'
import { useState } from 'react'
import PickerDrawer from '@eversports/klimt-patterns/components/PickerDrawer'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../localization/react'

import MobileFilter from './MobileFilter'

interface Props {
  header: JSX.Element
  onReset?: () => void
  selectedFilterValues?: Array<unknown>
}

const FilterDrawer = ({
  header,
  selectedFilterValues,
  onReset: handleReset,
  children,
}: React.PropsWithChildren<Props>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleFilterOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <MobileFilter label={header} valuesSelected={selectedFilterValues} handleClick={handleFilterOpen} />
      <PickerDrawer isOpen={Boolean(anchorEl)} onClose={handleClose}>
        <Stack sx={{ maxHeight: '67vh', pb: 8 }}>
          <Stack
            direction="row"
            sx={{
              padding: 5,
              position: 'sticky',
              top: '0',
              zIndex: 1,
              background: 'white',
            }}
          >
            <Heading is="h4" variant="small" sx={{ textAlign: 'center', flex: 1 }}>
              {header}
            </Heading>
            {handleReset && (
              <Text variant="small" color="primary.main" onClick={handleReset} sx={{ textDecoration: 'underline' }}>
                <Localized id="filters-reset" />
              </Text>
            )}
          </Stack>
          {children}
        </Stack>
      </PickerDrawer>
    </>
  )
}

export default FilterDrawer
