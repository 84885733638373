import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Divider from '@eversports/klimt-primitives/Divider'

import { Contact } from '../../VenueProfile.types'

import ContactIcon from './ContactIcon'

interface Props {
  contact: Contact
}

const ContactIcons = ({ contact }: Props) => {
  const hasContactInformation = Object.values(contact)
    .filter((value) => value !== 'Contact')
    .some(Boolean)

  if (!hasContactInformation) return null

  return (
    <>
      <Stack direction="row" justifyContent="center" alignItems="center" gap={4}>
        {contact.instagram && <ContactIcon type="instagram" to={contact.instagram} />}
        {contact.facebook && <ContactIcon type="facebook" to={contact.facebook} />}
        {contact.email && <ContactIcon type="email" to={contact.email} />}
        {contact.website && <ContactIcon type="website" to={contact.website} />}
        {contact.telephone && <ContactIcon type="telephone" to={contact.telephone} />}
      </Stack>
      <Divider sx={{ borderColor: 'lightgray' }} />
    </>
  )
}

export default ContactIcons
