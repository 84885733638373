import * as React from 'react'
import { assertNever } from '@eversports/klimt-utilities/assert-never'

import { NPSBannerStep } from './NPSBanner.constants'
import NPSBannerScore from './NPSBannerScore'
import NPSBannerComment from './NPSBannerComment'
import NPSBannerThankYou from './NPSBannerThankYou'

interface Props {
  sliderValue: number | null
  comment: string
  setSliderValue: (value: number) => void
  setComment: (value: string) => void
  step: NPSBannerStep
  handleNextStep: () => void
  handlePreviousStep: () => void
}

const NPSBannerComponentContent = ({
  sliderValue,
  comment,
  setSliderValue,
  setComment,
  step,
  handleNextStep,
  handlePreviousStep,
}: Props) => {
  switch (step) {
    case NPSBannerStep.SCORE:
      return <NPSBannerScore score={sliderValue} onChange={setSliderValue} onButtonClick={handleNextStep} />
    case NPSBannerStep.COMMENT:
      return (
        <NPSBannerComment
          comment={comment}
          onChange={setComment}
          onButtonClick={handleNextStep}
          onEditClick={handlePreviousStep}
        />
      )
    case NPSBannerStep.THANK_YOU:
      return <NPSBannerThankYou />
    default:
      return assertNever(step)
  }
}

export default NPSBannerComponentContent
