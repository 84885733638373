import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import styled from '@eversports/klimt-design-components/styled'
import Link from '@eversports/klimt-primitives/Link'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { useLocation } from 'react-router-dom'

import VenueAddress from '../../Discover/Listing/ListingResult/VenueAddress'
import { Localized } from '../../../localization/react'
import { VenueProfileTabsPrefix } from '../../../App.types'

import { Venue } from './TrialCards.types'
import TrialCardDetails from './TrialCardDetails'
import TrialCardVenueLogo, { TRIAL_CARD_LOGO_LENGTH } from './TrialCardVenueLogo'

const TrialOfferDetailGrid = styled('div')`
  display: grid;
  gap: 8px;
  align-items: center;
  grid-template-columns: 55px 1fr;
`

const MAX_OFFERS_TO_SHOW = 3

const TrialCard = ({ venue }: { venue: Venue }) => {
  const { trialCards, logo, address, name, slug } = venue

  const { amplitude } = useAmplitude()
  const { pathname } = useLocation()

  const trialCardsToShow = trialCards.slice(0, MAX_OFFERS_TO_SHOW)

  const hasMoreOffers = trialCards?.length > MAX_OFFERS_TO_SHOW

  const handleCardClick = () => {
    if (amplitude) {
      const trialCardPrices = trialCardsToShow.map((t) => t.price)
      const pricesFormatted = trialCardPrices.map((p) => `${(p.value / 100).toFixed(2)} ${p.currency}`)
      const hasFreeTrial = trialCardPrices.some((p) => p.value === 0)

      const trialCardNames = trialCardsToShow.map((t) => t.name)
      const hasDigitInName = trialCardNames.some((name) => /\d/.test(name), false)

      amplitude.logEvent('Clicked Trial Offer Tile', {
        from: pathname,
        venue: {
          name,
        },
        trialCard: {
          numberOfTrials: trialCardsToShow.length,
          hasFreeTrial,
          hasDigitInName,
          prices: pricesFormatted,
          names: trialCardNames,
        },
      })
    }
  }

  return (
    <Link to={`${VenueProfileTabsPrefix.PRICES}${slug}`} external blank>
      <Box
        onClick={handleCardClick}
        sx={{
          minHeight: '192px',
          background: 'white',
          border: '0.5px solid',
          borderColor: 'lightgray',
          boxSizing: 'border-box',
          boxShadow: '0px 1px 1px rgba(20, 20, 20, 0.2)',
          borderRadius: 1,
          p: 4,
          cursor: 'pointer',
          '&:hover': { boxShadow: boxShadows.hover },
        }}
      >
        <Stack spacing={5} justifyContent="space-between" sx={{ height: '100%' }}>
          <Box display="grid" gridTemplateColumns={`1fr ${TRIAL_CARD_LOGO_LENGTH}px`} sx={{ gridGap: 4 }}>
            <Stack spacing={2}>
              <Text variant="small" sx={{ fontWeight: 'bold' }}>
                {name}
              </Text>
              <TrialOfferDetailGrid>
                {trialCardsToShow.map((trialCard) => (
                  <TrialCardDetails key={trialCard.id} price={trialCard.price} description={trialCard.name} />
                ))}
                {hasMoreOffers && (
                  <Text variant="small" style={{ gridColumnStart: 2 }}>
                    <Localized id="trial-offers-has-more" />
                  </Text>
                )}
              </TrialOfferDetailGrid>
            </Stack>
            <TrialCardVenueLogo logo={logo} />
          </Box>
          <VenueAddress address={address} />
        </Stack>
      </Box>
    </Link>
  )
}

export default TrialCard
