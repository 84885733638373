import * as React from 'react'
import { assertNever } from '@eversports/klimt-utilities/assert-never'

import { BallsportMatchState } from '../Match.types'

import OverviewBannerSectionMessage from './OverviewBannerSectionMessage'
import BookingStatus from './components/BookingStatus'

interface Props {
  bookingState: BallsportMatchState
  sportName: string
  isMobile: boolean
}

const ParticipantBannerSectionMessage = ({ bookingState, sportName, isMobile }: Props) => {
  switch (bookingState) {
    case BallsportMatchState.ACCEPTED:
    case BallsportMatchState.MOVED:
      return <OverviewBannerSectionMessage sportName={sportName} isMobile={isMobile} />
    case BallsportMatchState.CANCELLED:
    case BallsportMatchState.REJECTED:
    case BallsportMatchState.PLANNED:
      return <BookingStatus type={bookingState} />
    case BallsportMatchState.REQUESTED:
      return <BookingStatus type={bookingState} />
    default:
      return assertNever(bookingState)
  }
}

export default ParticipantBannerSectionMessage
