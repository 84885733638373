import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import CheckmarkIcon from '@eversports/design-tokens/assets/icons/checkmark.svg'
import UnpaidIcon from '@eversports/design-tokens/assets/icons/unpaid.svg'

import { paymentStatusTranslations } from '../../Match.constants'
import { Localized } from '../../../../localization/react'
import { BallsportMatchState } from '../../Match.types'

import BannerSectionBadge from './BannerSectionBadge'

interface Props {
  isPaid: boolean
  approvalState: BallsportMatchState
}

const PaymentStatus = ({ isPaid, approvalState }: Props) => {
  if (
    approvalState === BallsportMatchState.CANCELLED ||
    approvalState === BallsportMatchState.REJECTED ||
    approvalState === BallsportMatchState.PLANNED
  ) {
    return null
  }

  if (isPaid) {
    return (
      <BannerSectionBadge>
        <Stack alignItems="center" direction="row" sx={{ gap: 2 }}>
          <Icon src={CheckmarkIcon} size="small" color="white" />
          <Text sx={{ color: 'white' }}>
            <Localized id={paymentStatusTranslations.PAYMENT_STATE_SUCCEEDED as any} />
          </Text>
        </Stack>
      </BannerSectionBadge>
    )
  }

  return (
    <BannerSectionBadge>
      <Stack alignItems="center" direction="row" sx={{ gap: 2 }}>
        <Icon src={UnpaidIcon} size="small" color="tertiary" />
        <Text sx={{ color: 'tertiary' }}>
          <Localized id={paymentStatusTranslations.PAYMENT_STATE_OPEN as any} />
        </Text>
      </Stack>
    </BannerSectionBadge>
  )
}

export default PaymentStatus
