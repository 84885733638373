import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../../localization/react'
import { AvailableBudget } from '../../../App.types'
import { ColorContrast } from '../Header.types'
import { textColorContrastMapping } from '../Header.constants'

interface CorporateBenefitLabelProps {
  availableBudget: AvailableBudget | null
  corporatePartnerName: string
  colorContrast: ColorContrast
}

const CorporateBenefitLabel = ({
  availableBudget,
  corporatePartnerName,
  colorContrast,
}: CorporateBenefitLabelProps) => {
  if (!availableBudget) {
    // If there's no budget, then the user just has special pricing benefits
    return (
      <Text
        variant="small"
        sx={{ fontWeight: 'bold', color: textColorContrastMapping[colorContrast], textTransform: 'none' }}
      >
        <Localized id="header-corporate-benefits-label" params={{ corporatePartnerName }} />
      </Text>
    )
  }

  if (availableBudget.display.__typename === 'Unlimited') {
    return (
      <Text
        variant="small"
        sx={{ fontWeight: 'bold', color: textColorContrastMapping[colorContrast], textTransform: 'none' }}
      >
        <Localized id="header-corporate-benefits-unlimited-budget" />
      </Text>
    )
  }

  const budgetLeft = availableBudget.display.limit.value / 100
  const currency = availableBudget.display.limit.currency

  return (
    <Stack direction="row" gap={1}>
      <Text
        variant="small"
        sx={{ fontWeight: 'bold', color: textColorContrastMapping[colorContrast], textTransform: 'none' }}
      >
        <Localized id="header-corporate-benefits-budget-left-label" />
      </Text>
      <Text
        variant="small"
        sx={{
          fontWeight: 'bold',
          color: colorContrast === ColorContrast.NORMAL ? 'primary.main' : 'white',
          textTransform: 'none',
        }}
      >
        <Localized
          id="header-corporate-benefits-budget-left-amount"
          params={{
            availableBudget: {
              value: budgetLeft,
              currency,
            },
            hasMoreBudgetLabel: availableBudget.display.displayCapped ? '>' : '',
          }}
        />
      </Text>
    </Stack>
  )
}

export default CorporateBenefitLabel
