import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Avatar from '@eversports/klimt-primitives/Avatar'

import { Localized } from '../../../localization/react'

import RightQuotes from './assets/right-quotes.svg'
import { Testimonial } from './Testimonials.types'

interface Props {
  testimonial: Testimonial
}

const TestimonialCard = ({ testimonial }: Props) => {
  const { name, countryTLD, quoteLocalizedId, avatarSrc } = testimonial
  return (
    <Stack
      sx={{
        display: 'grid',
        gridTemplateColumns: '40px 1fr 24px',
        gridTemplateRows: '40px 1fr',
        gap: 2,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'mediumgray',
        boxShadow: boxShadows.default,
        background: 'white',
        flex: 1,
        padding: 4,
        minWidth: '240px',
      }}
    >
      <Avatar src={avatarSrc} sx={{ width: '100%', height: '100%' }} />
      <Stack>
        <Text color="midnight">{name}</Text>
        <Text color="midnight" variant="small">
          <Localized id="tld-country-name" params={{ tld: countryTLD }} />
        </Text>
      </Stack>
      <img src={RightQuotes} alt="" loading="lazy" width={24} height={24} />
      <Text variant="large" sx={{ gridColumn: 'span 3' }}>
        <Localized id={quoteLocalizedId} />
      </Text>
    </Stack>
  )
}

export default TestimonialCard
