import * as React from 'react'

import { useDiscoverReducer, useDiscoverState } from '../../DiscoverContext'
import getLinkToVenueFirstOffering from '../../../../helpers/get-link-to-venue-first-offering'
import { VenueOfferingType } from '../../../../App.types'
import { DiscoverySport } from '../../Discover.types'
import { formatDateAsQueryVariable, formatTimeAsQueryVariable } from '../../../../utils/format-date-time'
import { useMapListingResultWithSlotsVenueQuery } from '../../../../graphql'
import getLinkToSportsFieldsCalendar from '../../../../helpers/get-link-to-sports-fields-calendar'

import MapListingResultComponent from './MapListingResultComponent'

interface MapListingResultProps {
  venueId: string
  sport: DiscoverySport
}

const MapListingResultWithSlots = ({ venueId, sport }: MapListingResultProps) => {
  const {
    venueCourtSlotTimeActiveFilter,
    venueCourtSlotDateActiveFilter,
    venueCourtAreasActiveFilters,
    venueCourtSurfaceActiveFilters,
  } = useDiscoverState()

  const courtSlotDateAsString = formatDateAsQueryVariable(venueCourtSlotDateActiveFilter)
  const courtSlotTimeAsString = venueCourtSlotTimeActiveFilter
    ? formatTimeAsQueryVariable(venueCourtSlotTimeActiveFilter)
    : null

  const { data } = useMapListingResultWithSlotsVenueQuery({
    variables: {
      venueId,
      filters: {
        sport: sport.id,
        courtAvailability: { date: courtSlotDateAsString, time: courtSlotTimeAsString },
        courtAreas: venueCourtAreasActiveFilters,
        courtSurfaces: venueCourtSurfaceActiveFilters,
      },
    },
  })

  const dispatch = useDiscoverReducer()

  if (!data) return null

  const handleClick = (e: MouseEvent) => {
    e.preventDefault()
    dispatch({ type: 'SET_CLICKED_VENUE_ID', payload: undefined })
  }
  const venue = data.venue

  const hasSportsFieldCalendar =
    (venue.offerings[0] as VenueOfferingType) === VenueOfferingType.VENUE_OFFERING_SPORTS_FIELDS

  const to = hasSportsFieldCalendar
    ? getLinkToSportsFieldsCalendar({
        sportSlug: sport.slug,
        venueSlug: venue.slug,
        selectedDate: courtSlotDateAsString,
        hasAvailableSlots: Boolean(venue?.slotsToDiscover?.length),
      })
    : getLinkToVenueFirstOffering({
        venueSlug: venue.slug,
        firstVenueOffering: venue.offerings[0],
      })

  return <MapListingResultComponent venue={venue} slots={venue.slotsToDiscover} to={to} handleClick={handleClick} />
}

export default MapListingResultWithSlots
