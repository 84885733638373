import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'

export const RecommendationsIconLarge: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Box mr={2} sx={{ borderRadius: 1, backgroundColor: 'rgba(33, 166, 150, 0.1)', px: 3, py: 3, width: 'fit-content' }}>
    {children}
  </Box>
)

export const RecommendationsIconSmall: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Box
    mr={2}
    sx={{ borderRadius: 0.5, backgroundColor: 'rgba(33, 166, 150, 0.1)', px: 1.5, py: 1.5, width: 'fit-content' }}
  >
    {children}
  </Box>
)

export default RecommendationsIconLarge
