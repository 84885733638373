import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import SectionWrapper from '../components/SectionWrapper'
import { Venue } from '../VenueProfile.types'
import { VenueOfferingType } from '../../../App.types'

import CallToActionPrices from './CallToAction/CallToActionPrices'
import ContactIcons from './ContactInformation/ContactIcons'
import CorporateDisclaimer from './ContactInformation/CorporateDisclaimer'
import CallToActionButton from './CallToAction/CallToActionButton'
import BottomFixedSection from './BottomFixedSection'

interface Props {
  venue: Venue
}

const MobileSidebar = ({ venue }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const hasPublicMatches = venue.offerings.some(
    (offering) => offering === VenueOfferingType.VENUE_OFFERING_PUBLIC_MATCHES,
  )

  if (!isMobile) return null

  return (
    <>
      <SectionWrapper sx={{ py: 5, px: 4, display: { xs: 'flex', lg: 'none' } }}>
        <Stack gap={4}>
          {!hasPublicMatches && (
            <CallToActionPrices
              cheapestPrice={venue.cheapestPrice}
              cheapestTrialProductPrice={venue.cheapestTrialProductPrice}
              slug={venue.slug}
            />
          )}
          <ContactIcons contact={venue.contact} />
          <CorporateDisclaimer />
        </Stack>
      </SectionWrapper>
      <BottomFixedSection>
        <CallToActionButton offerings={venue.offerings} slug={venue.slug} />
      </BottomFixedSection>
    </>
  )
}

export default MobileSidebar
