import throttle from '@eversports/klimt-utilities/throttle'
import Box from '@eversports/klimt-primitives/Box'
import * as React from 'react'
import { useEffect, useLayoutEffect, useState } from 'react'

import { useDiscoverReducer, useDiscoverState } from '../DiscoverContext'
import { BoundingBox } from '../Discover.types'
import { LatLng, Venue } from '../../../App.types'
import useMap from '../../../components/Map/hooks/useMap'
import MapControls from '../../../components/Map/MapControls'
import { focusedMarkerIcon, markerIcon } from '../../../components/Map/helpers/create-map-marker'
import isMarkerFocused from '../../../components/Map/helpers/is-marker-focused'
import isMarkerDisabled from '../../../components/Map/helpers/is-marker-disabled'

import createDiscoverMapMarkers from './helpers/create-discover-map-markers'

interface Props {
  initialSearchBoundingBox: BoundingBox
  setClickedVenueId: (venueId: string | undefined) => void
  venues?: Array<Venue>
}

const BOUNDING_BOX_UPDATE_THROTTLING_MILLISECONDS = 1000

const DiscoverMap = ({ initialSearchBoundingBox, venues, setClickedVenueId }: Props) => {
  const dispatch = useDiscoverReducer()
  const { hoveredVenue, showMobileMapView, sport, clickedVenueId } = useDiscoverState()

  const { map, mapRef } = useMap({
    type: 'bounding-box',
    initialBoundingBox: initialSearchBoundingBox,
    gestureHandling: 'greedy',
  })

  // Mobile map view initial handling
  const [isMobileMapViewLoaded, setIsMobileMapViewLoaded] = useState(false)

  // Map markers management
  const [markers, setMarkers] = useState<Map<string, google.maps.Marker>>(new Map())

  // Keep a reference to the current active marker
  const [activeMarker, setActiveMarker] = useState<google.maps.Marker | undefined>(undefined)

  const handleGeoLocationControlClick = (userLocation: LatLng) => {
    dispatch({ type: 'SET_SHOULD_CONSIDER_DISTANCE_FOR_SEARCH', payload: userLocation })
  }

  useEffect(() => {
    if (!markers) return

    markers.forEach((marker) => {
      const isDisabled = isMarkerDisabled(marker)
      if (isMarkerFocused(marker)) {
        marker.setOptions({
          icon: markerIcon({ isDisabled }),
        })
      }
    })

    if (activeMarker) {
      const isDisabled = isMarkerDisabled(activeMarker)
      if (!isMarkerFocused(activeMarker)) {
        activeMarker.setOptions({
          icon: focusedMarkerIcon({ isDisabled }),
        })
      }
    }
  }, [activeMarker])

  // Because the mobile view of the map has display:none, the bounding box is not correctly calculated on initial
  // load, and for that reason we need to reset it once the user has toggled the map view for the first time
  useEffect(() => {
    if (isMobileMapViewLoaded || !map || !showMobileMapView) return

    const mapInitialBounds = {
      sw: {
        lat: initialSearchBoundingBox.southWest.latitude,
        lng: initialSearchBoundingBox.southWest.longitude,
      },
      ne: {
        lat: initialSearchBoundingBox.northEast.latitude,
        lng: initialSearchBoundingBox.northEast.longitude,
      },
    }

    // Reset the bounds of the map to make sure we have the appropriate viewport view
    map.fitBounds(new google.maps.LatLngBounds(mapInitialBounds.sw, mapInitialBounds.ne))
    map.setOptions({ gestureHandling: 'cooperative' })

    setIsMobileMapViewLoaded(true)
  }, [showMobileMapView])

  useEffect(() => {
    if (!map) return

    // Dispatch change event when user interacts with the map
    const handleUserInteractionThrottled = throttle(() => {
      const boundingRect = map.getBounds()
      if (!boundingRect) return

      const googleSouthWest = boundingRect.getSouthWest().toJSON()
      const googleNorthEast = boundingRect.getNorthEast().toJSON()

      dispatch({
        type: 'SET_INITIAL_SEARCH_BOUNDING_BOX',
        payload: {
          southWest: {
            latitude: googleSouthWest.lat,
            longitude: googleSouthWest.lng,
          },
          northEast: {
            latitude: googleNorthEast.lat,
            longitude: googleNorthEast.lng,
          },
        },
      })
    }, BOUNDING_BOX_UPDATE_THROTTLING_MILLISECONDS)

    // Add idle event listener only after the tiles are loaded to avoid re-renders before
    google.maps.event.addListenerOnce(map, 'tilesloaded', function () {
      map.addListener('dragend', handleUserInteractionThrottled)
      map.addListener('zoom_changed', handleUserInteractionThrottled)
    })

    // Make sure we close the info window and reset active/clicked markers if the user clicks in the map
    google.maps.event.addListener(map, 'click', function () {
      setActiveMarker(undefined)
      setClickedVenueId(undefined)
    })

    // ...or if they start to drag the map. Helps to see markers hidden under the info window.
    google.maps.event.addListener(map, 'dragstart', function () {
      setActiveMarker(undefined)
      setClickedVenueId(undefined)
    })
  }, [map])

  // If user closes the Map Listing Result tile, then set activeMarker to undefined
  useEffect(() => {
    if (activeMarker && !clickedVenueId) {
      setActiveMarker(undefined)
    }
  }, [clickedVenueId])

  useLayoutEffect(() => {
    if (!markers) return

    markers.forEach((marker) => {
      const isDisabled = isMarkerDisabled(marker)
      if (isMarkerFocused(marker)) {
        marker.setOptions({
          icon: markerIcon({ isDisabled }),
        })
      }
    })

    if (hoveredVenue) {
      const activeMarker = markers.get(hoveredVenue)
      if (activeMarker && !isMarkerFocused(activeMarker)) {
        activeMarker.setOptions({
          icon: focusedMarkerIcon({ isDisabled: isMarkerDisabled(activeMarker) }),
        })
      }
    }
  }, [hoveredVenue])

  // Redraw all markers when the venue list changes
  useEffect(() => {
    if (map && venues) {
      // Reset active markers whenever there's a new set of venues displayed and the active venue is not there anymore
      if (clickedVenueId && !venues.some((venue) => venue.id === clickedVenueId)) {
        setActiveMarker(undefined)
        setClickedVenueId(undefined)
      }

      createDiscoverMapMarkers({
        map,
        venues,
        markers,
        setMarkers,
        activeMarker,
        setActiveMarker,
        setClickedVenueId,
        category: sport?.category,
      })
    }
  }, [map, venues])

  return (
    <>
      <Box sx={{ width: '100%', height: '100%' }} forwardedRef={mapRef} id="map" />
      {map && <MapControls map={map} onGeoLocationControlClick={handleGeoLocationControlClick} />}
    </>
  )
}

export default DiscoverMap
