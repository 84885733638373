import * as React from 'react'
import { AnimatePresence } from '@eversports/klimt-utilities/framer-motion'

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import TechnicalNotes from './TechnicalNotes'

const TechnicalNotesScreen = () => {
  return (
    <>
      <Header />
      <AnimatePresence exitBeforeEnter>
        <TechnicalNotes />
      </AnimatePresence>
      <Footer />
    </>
  )
}

export default TechnicalNotesScreen
