import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

const LayoutContainer = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        p: [0, 4],
      }}
      component="main"
    >
      <Stack alignItems="center" sx={{ width: '100%', maxWidth: '806px', gap: [1, 4] }}>
        {children}
      </Stack>
    </Stack>
  )
}

export default LayoutContainer
