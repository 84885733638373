import Text from '@eversports/klimt-primitives/Text'
import Link from '@eversports/klimt-primitives/Link'
import * as React from 'react'
import { assertNever } from '@eversports/klimt-utilities/assert-never'

import { Localized } from '../../localization/react'
import { UserProfileTabsPrefix } from '../../App.types'
import useUrlParams from '../../hooks/use-url-params'

import { PageType } from './Match.types'

interface Props {
  pageType: Exclude<PageType, PageType.THANK_YOU>
  citySlug: string
  sportSlug: string
  venueName: string
}

const PageNavigationBreadcrumbLink = ({ pageType, sportSlug, citySlug, venueName }: Props) => {
  const { from } = useUrlParams()

  switch (pageType) {
    case PageType.CALENDAR:
      return (
        <Link to={from!} external>
          <Text sx={{ color: 'darkgray' }}>
            <Localized id="breadcrumbs-calendar" params={{ venueName }} />
          </Text>
        </Link>
      )
    case PageType.DISCOVER:
      return (
        <Link to={from!}>
          <Text sx={{ color: 'darkgray', textTransform: 'capitalize' }}>
            <Localized id="breadcrumbs-discover-sport-matches-in-city" params={{ citySlug, sportSlug }} />
          </Text>
        </Link>
      )
    case PageType.VENUE_PROFILE:
      return (
        <Link to={from!}>
          <Text sx={{ color: 'darkgray' }}>
            <Localized id="breadcrumbs-venue-profile-matches" params={{ venueName }} />
          </Text>
        </Link>
      )
    case PageType.OVERVIEW:
      return (
        <Link to={UserProfileTabsPrefix.OVERVIEW} external>
          <Text sx={{ color: 'darkgray' }}>
            <Localized id="breadcrumbs-my-bookings" />
          </Text>
        </Link>
      )
    default:
      return assertNever(pageType)
  }
}

export default PageNavigationBreadcrumbLink
