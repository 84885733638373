import * as React from 'react'
import Box, { BoxProps } from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

type SxProps = Pick<BoxProps, 'sx'>

interface Props extends SxProps {
  imgSrc: string
}

const StockImageCard = ({ imgSrc, sx }: Props) => (
  <Box
    sx={{
      width: { xs: '60%', sm: 'fit-content' },
      maxWidth: 'fit-content',
      height: 'auto',
      backgroundColor: 'white',
      padding: 2,
      boxShadow: boxShadows.default,
      borderRadius: 1,
      ...sx,
    }}
  >
    <img
      src={imgSrc}
      loading="lazy"
      alt=""
      width={240}
      height={120}
      style={{ objectFit: 'cover', borderRadius: '8px' }}
    />
  </Box>
)

export default StockImageCard
