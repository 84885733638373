import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

interface Props {
  variant?: 'default' | 'dense'
  onClick?: () => void
}

const SectionCard = ({ children, onClick, variant = 'default' }: React.PropsWithChildren<Props>) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'mediumgray',
        boxShadow: boxShadows.default,
        paddingY: variant === 'dense' ? 4 : 6,
        borderRadius: 1,
        paddingX: 4,
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}

export default SectionCard
