import * as React from 'react'
import { useState, useEffect } from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'

import { Localized, useIntl } from '../../../localization/react'
import {
  useCitySportOfferingsCitySearchLazyQuery,
  useCitySportOfferingsDiscoverContextByCityLazyQuery,
} from '../../../graphql'
import { DiscoverySport } from '../../Discover/Discover.types'
import SectionWrapper from '../components/SectionWrapper'

import StockPhoto1 from './assets/women-stock-photo.webp'
import StockPhoto2 from './assets/men-stock-photo.webp'
import Offerings from './components/Offerings'
import { CitySearchCity } from './CitySportOfferings.types'
import StockImageCard from './StockImageCard'

const MAX_DROPDOWN_OPTIONS = 12

const CitySportOfferings = () => {
  const [citySlug, setCitySlug] = useState('')
  const [sport, setSport] = useState<DiscoverySport | undefined>()

  const intl = useIntl()

  const [getCities, { data: citySearchData }] = useCitySportOfferingsCitySearchLazyQuery()
  const cityOptions: Array<CitySearchCity> = citySearchData?.citySearch ?? []

  const [getPopularSports, { data: cityData }] = useCitySportOfferingsDiscoverContextByCityLazyQuery()
  const bookableSportsForCity = cityData?.discoverContextByCity?.bookableSports
  const sportOptions: Array<DiscoverySport> = (bookableSportsForCity ?? []).slice(0, MAX_DROPDOWN_OPTIONS)

  useEffect(() => {
    void getCities({ fetchPolicy: 'network-only' }).then((response) => {
      const initialCitySlug = response?.data?.citySearch?.[0]?.slug
      if (initialCitySlug) {
        setCitySlug(initialCitySlug)
      }
    })
  }, [])

  useEffect(() => {
    if (!citySlug) return
    void getPopularSports({ variables: { citySlug } }).then((response) => {
      const initialSport = response?.data?.discoverContextByCity?.bookableSports?.[0]
      if (initialSport) {
        setSport(initialSport)
      }
    })
  }, [citySlug])

  if (!citySlug || !sport) return null

  return (
    <Stack
      sx={{
        width: '100%',
        alignItems: 'center',
        backgroundColor: { sm: 'rgb(247, 251, 251)' },
        paddingX: 4,
        paddingY: { sm: 10 },
        marginBottom: 15,
        contentVisibility: 'auto',
      }}
    >
      <SectionWrapper
        sx={{
          display: { sm: 'grid' },
          gap: { xs: 3, sm: 6 },
          gridTemplateColumns: { sm: '1fr 1fr' },
          gridTemplateRows: { sm: '1fr 140px 110px' },
        }}
      >
        <Stack sx={{ gap: 2, paddingBottom: { xs: 4, md: 0 } }}>
          <Heading
            is="h2"
            variant="xlarge"
            color="midnight"
            sx={{ fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'", fontWeight: 500 }}
          >
            <Localized id="home-rebrand-sport-offerings-header" />
          </Heading>
          <Text variant="large">
            <Localized id="home-rebrand-sport-offerings-description" />
          </Text>
        </Stack>
        <Box
          sx={{
            position: 'relative',
            gridRow: 'span 2',
            paddingBottom: 15,
          }}
        >
          <StockImageCard
            imgSrc={StockPhoto1}
            sx={{
              position: { xs: 'relative', sm: 'absolute' },
              left: { sm: '10%', xl: '20%' },
              transform: { xs: 'rotate(-3deg) rotateY(180deg)', xl: 'rotate(-3deg)' },
            }}
          />
          <StockImageCard
            imgSrc={StockPhoto2}
            sx={{ position: 'absolute', top: 0, right: 0, transform: 'translateY(25%) rotate(-3deg)' }}
          />
        </Box>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
            paddingY: 4,
            flexWrap: { xs: 'wrap', md: 'nowrap' },
          }}
        >
          <Text variant="large" sx={{ fontWeight: 600, color: 'midnight' }}>
            <Localized id="home-rebrand-check-out-offerings-for" />
          </Text>
          {/* TODO - in a follow up, convert this to a styled dropdown and bottom sheet */}
          <select
            name="cities"
            id="city-select"
            aria-label={intl.homeRebrandSelectACity()}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setCitySlug(e.target.value)}
            style={{
              padding: '8px 12px',
              borderRadius: '8px',
              color: '#141414',
              border: '1px solid #E0E0E0',
              width: '200px',
            }}
          >
            {cityOptions.map((city) => (
              <option key={city.id} value={city.slug}>
                {city.name}, {city.country}
              </option>
            ))}
          </select>
          {/* TODO - in a follow up, convert this to a styled dropdown and bottom sheet */}
          <select
            name="sports"
            id="sport-select"
            aria-label={intl.homeRebrandSelectASport()}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const sportIdValue = e.target.value
              const selectedSport = sportOptions.find((sport) => sport.id === sportIdValue)
              setSport(selectedSport)
            }}
            style={{
              padding: '8px 12px',
              borderRadius: '8px',
              color: '#141414',
              border: '1px solid #E0E0E0',
              width: '150px',
            }}
          >
            {sportOptions.map((sport) => (
              <option key={sport.id} value={sport.id}>
                {sport.name}
              </option>
            ))}
          </select>
        </Stack>
        <Offerings citySlug={citySlug} sport={sport} />
      </SectionWrapper>
    </Stack>
  )
}

export default CitySportOfferings
