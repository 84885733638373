import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import Heading from '@eversports/klimt-primitives/Heading/Heading'
import Processing from '@eversports/klimt-primitives/Processing'
import Text from '@eversports/klimt-primitives/Text'

import useGetTopContentHeight from '../../../../../hooks/useGetTopContentHeight'
import MetaHelmet from '../../../../../components/MetaHelmet'
import getStaticPageMetadata from '../../../../../helpers/get-static-page-metadata'
import { Localized, useIntl } from '../../../../../localization/react'
import SectionCard from '../components/SectionCard'
import PrivateInformation from '../../../components/PrivateInformation'
import { From, PrivacySettingField } from '../../../../../App.types'
import PublicUser from '../../../components/PublicUser'

import useFriendsOfUser from './hooks/useFriendsOfUser'
import { PublicUserWithFriends } from './FriendsOfUser.types'
import Navigation from './Navigation'

interface Props {
  user: PublicUserWithFriends
}

const FriendsOfUserComponent = ({ user }: Props) => {
  const intl = useIntl()
  const { origin } = useSettingsContext()
  const { DESKTOP_TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])
  const { friends, loading, fetchMore, hasMoreResults } = useFriendsOfUser({ initiallyFetchedFriends: user.friends })

  const meta = getStaticPageMetadata({
    title: intl.userFriendsPageMetaTitle({ userFirstName: user.firstName }),
    description: intl.userFriendsPageMetaDescription({ userFirstName: user.firstName }),
    url: `${origin}/friends`,
  })

  return (
    <>
      <MetaHelmet meta={meta} noIndex />
      <Box
        sx={{
          backgroundColor: 'limelite',
          minHeight: `calc(100vh - ${DESKTOP_TOP_CONTENT_HEIGHT}px)`,
          height: '100%',
        }}
        component="main"
      >
        <Stack
          margin="auto"
          maxWidth={{ xs: '100%', md: '600px' }}
          boxSizing="content-box"
          padding={{ xs: 0, md: 6 }}
          gap={{ xs: 0, md: 6 }}
        >
          <Stack gap={{ xs: 0, md: 6 }}>
            <Navigation userFirstName={user.firstName} userHandle={user.handle} />
          </Stack>
          <SectionCard>
            {user.friends ? (
              <>
                <Heading is="h3" color="midnight">
                  <Localized id="friends-of-user-title" params={{ userFirstName: user.firstName }} />
                </Heading>
                {friends.map((friend) => (
                  <PublicUser key={friend.id} user={friend} from={From.USER_FRIENDS_LIST} />
                ))}
                {hasMoreResults && (
                  <Processing isLoading={loading}>
                    <Box
                      sx={{
                        backgroundColor: 'limelite',
                        borderRadius: 1,
                        padding: 2,
                        width: '100%',
                        cursor: 'pointer',
                      }}
                      onClick={fetchMore}
                    >
                      <Text variant="small" sx={{ color: 'primary.main', fontWeight: 'bold', textAlign: 'center' }}>
                        <Localized id="load-more-friends-button" />
                      </Text>
                    </Box>
                  </Processing>
                )}
              </>
            ) : (
              <PrivateInformation field={PrivacySettingField.FRIENDS} />
            )}
          </SectionCard>
        </Stack>
      </Box>
    </>
  )
}

export default FriendsOfUserComponent
