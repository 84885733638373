import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../localization/react'
import { OnlineVenue } from '../../../App.types'
import { DiscoverySport } from '../Discover.types'

import ListingResult from './ListingResult'

interface Props {
  onlineVenues: Array<OnlineVenue>
  sport?: DiscoverySport
}

const OnlineVenuesSection = ({ onlineVenues, sport }: React.PropsWithChildren<Props>) => {
  return (
    <Stack gap={4} sx={{ margin: '12px 0' }}>
      <Stack gap={1}>
        <Heading is="h3">
          <Localized id="online-only-venue-heading" />
        </Heading>
        <Text sx={{ color: 'darkgray' }} variant="small">
          <Localized id="online-only-venue-description" />
        </Text>
      </Stack>
      <Stack gap={4}>
        {onlineVenues.map((venue) => (
          <ListingResult venue={venue} sport={sport} key={venue.id} imageLoadingStrategy="lazy" />
        ))}
      </Stack>
    </Stack>
  )
}

export default OnlineVenuesSection
