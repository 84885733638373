function setSearchParams<T extends object>(params: T) {
  const { search, pathname } = window.location
  const searchParams = new URLSearchParams(search)

  for (const [key, value] of Object.entries(params)) {
    const isEmptyArray = Array.isArray(value) && value.length === 0
    if (value && !isEmptyArray) {
      searchParams.set(key, encodeURIComponent(JSON.stringify(value)))
    } else {
      searchParams.delete(key)
    }
  }
  window.history.replaceState(
    {},
    '',
    searchParams.toString().length ? pathname + '?' + searchParams.toString() : pathname,
  )
}

export default setSearchParams
