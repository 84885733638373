import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import * as React from 'react'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import Drawer from '../../../../components/Drawer'

import useSharingMessage from './hooks/use-sharing-message'
import VenueShareButtonActionsContent from './VenueShareButtonActionsContent'

interface Props {
  isOpen: boolean
  toggleDialog: () => void
  venueId: string
}

const VenueButtonSharingActions = ({ isOpen, toggleDialog, venueId }: Props) => {
  const theme = useTheme()
  const sharingMessage = useSharingMessage()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return isMobile ? (
    <Drawer open={isOpen} onClose={toggleDialog}>
      <VenueShareButtonActionsContent sharingMessage={sharingMessage} venueId={venueId} />
    </Drawer>
  ) : (
    <Dialog open={isOpen} onClose={toggleDialog}>
      <DialogContent>
        <VenueShareButtonActionsContent sharingMessage={sharingMessage} venueId={venueId} />
      </DialogContent>
    </Dialog>
  )
}

export default VenueButtonSharingActions
