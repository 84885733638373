import * as React from 'react'
import { AnimatePresence } from '@eversports/klimt-utilities/framer-motion'

import Match from './Match'
import Header from './Header'
import Footer from './Footer'

const MatchPage = () => {
  return (
    <>
      <Header />
      <AnimatePresence exitBeforeEnter>
        <Match />
      </AnimatePresence>
      <Footer />
    </>
  )
}

export default MatchPage
