import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Link from '@eversports/klimt-primitives/Link'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import ManagerPromo from '../../../../static/manager-promo.webp'
import { Localized } from '../../../localization/react'
import { ManagerLinksMapping } from '../../../App.constants'

const ManagerPromoSection = () => {
  const { tld } = useSettingsContext()
  const managerLinks = ManagerLinksMapping[tld]

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent="center"
      alignItems="center"
      padding={[4, 4, 2]}
      gap={[2, 2, 8]}
      zIndex={2}
      sx={{
        backgroundColor: 'rgb(241,248,247)', // Need a solid bg rather than one with opacity because the "Show Map" button needs to hide behind it
        border: '2px solid white',
        position: 'relative',
      }}
    >
      <img src={ManagerPromo} alt="Manager Promo" loading="lazy" width="106px" height="64px" />
      <Stack gap={2} marginRight={{ xs: 'none', md: 4 }} marginBottom={{ xs: 1, md: 'none' }}>
        <Heading is="h3" variant="small" color="primary" sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
          <Localized id="manager-promo-title" />
        </Heading>
        <Text>
          <Localized id="manager-promo-subtitle" />
        </Text>
      </Stack>
      <Link to={managerLinks.business} external blank>
        <Button variant="primary" sx={{ px: 5, py: 2 }}>
          <Text variant="large" color="white" sx={{ fontWeight: 'bold', textTransform: 'none' }}>
            <Localized id="manager-promo-button-text" />
          </Text>
        </Button>
      </Link>
    </Stack>
  )
}

export default ManagerPromoSection
