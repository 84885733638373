import { FakeNotification } from './NotificationPromotion.types'
import FakeNotificationIcon1 from './assets/fake-notification-icon-1.svg'
import FakeNotificationIcon2 from './assets/fake-notification-icon-2.svg'

export const FAKE_NOTIFICATIONS: Array<FakeNotification> = [
  {
    icon: FakeNotificationIcon1,
    headerTextKey: 'fake-notification-header-1',
    created: '2023-11-09T12:15:00.000Z',
  },
  {
    icon: FakeNotificationIcon2,
    headerTextKey: 'fake-notification-header-2',
    created: '2023-11-09T10:30:00.000Z',
  },
]
