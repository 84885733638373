import * as React from 'react'
import xss, { getDefaultWhiteList } from 'xss'

interface Props {
  htmlContent: string
  stripIgnoreTag?: boolean
  whiteList?: XSS.IWhiteList
  WrapperComponent?: React.FC<React.PropsWithChildren<{ dangerouslySetInnerHTML?: { __html: string | TrustedHTML } }>>
}

export const defaultWhiteList = getDefaultWhiteList()

const DefaultWrapperComponent = ({
  dangerouslySetInnerHTML,
}: React.PropsWithChildren<{ dangerouslySetInnerHTML?: { __html: string | TrustedHTML } }>) => (
  <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
)

const FormattedContent = ({
  htmlContent,
  stripIgnoreTag,
  whiteList,
  WrapperComponent = DefaultWrapperComponent,
}: React.PropsWithChildren<Props>) => {
  const options: XSS.IFilterXSSOptions = {}

  if (whiteList) {
    options.whiteList = whiteList
  }
  if (stripIgnoreTag) {
    options.stripIgnoreTag = stripIgnoreTag
  }

  const __html = xss(htmlContent, options)
  return <WrapperComponent dangerouslySetInnerHTML={{ __html }} />
}

export default FormattedContent
