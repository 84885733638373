import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../localization/react'
import { VENUE_PROFILE_MAP_ID } from '../../VenueProfile.constants'
import { ScrollAnchor } from '../../../../components/ScrollAnchor'

import VenueMap from './VenueMap'

interface Props {
  address: string
  headerOffset: number
  latitude?: number | null
  longitude?: number | null
}

const Location = ({ address, latitude, longitude, headerOffset }: Props) => {
  if (!latitude || !longitude) {
    return null
  }

  return (
    <Stack gap={2} position="relative">
      <ScrollAnchor hash={VENUE_PROFILE_MAP_ID} topOffset={-headerOffset} />
      <Text color="midnight" sx={{ fontWeight: 'bold' }}>
        <Localized id="venue-profile-location-title" />
      </Text>
      <Text variant="small" sx={{ color: 'darkgray' }}>
        {address}
      </Text>
      <VenueMap latitude={latitude} longitude={longitude} />
    </Stack>
  )
}

export default Location
