import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

interface Props {
  venueName: string
  street: string
  zip: string
  city: string
}

const FriendUpcomingParticipationAddress = ({ venueName, street, zip, city }: Props) => {
  return (
    <Stack gap={1}>
      <Text sx={{ fontWeight: 'bold', color: 'midnight' }}>{venueName}</Text>
      <Text>
        {street}, {zip} {city}
      </Text>
    </Stack>
  )
}

export default FriendUpcomingParticipationAddress
