import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../localization/react'

import TeacherPlaceholderImage from './assets/teacher-placeholder.svg'

const TeacherPlaceholder = () => {
  return (
    <Box
      sx={{
        background: 'white',
        border: '0.5px solid',
        borderColor: 'lightgray',
        boxShadow: '0px 1px 1px rgba(20, 20, 20, 0.2)',
        backgroundColor: 'eggshell',
        borderRadius: 1,
        padding: 4,
      }}
    >
      <Box
        sx={{
          border: '1px dashed',
          borderColor: 'mediumgray',
          borderRadius: 1,
          height: '100%',
          px: 12,
          py: 8,
        }}
      >
        <Stack gap={3} justifyContent="center" alignItems="center" textAlign="center" height="100%">
          <img
            src={TeacherPlaceholderImage}
            alt="Teacher Placeholder Image"
            loading="lazy"
            width="44px"
            height="44px"
          />
          <Text sx={{ fontWeight: 'bold' }}>
            <Localized id="recent-teachers-placeholder" />
          </Text>
        </Stack>
      </Box>
    </Box>
  )
}

export default TeacherPlaceholder
