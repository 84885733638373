import * as React from 'react'

import DiscoverScreen from '../../DiscoverScreen'

import DiscoverSportMatches from './DiscoverSportMatches'

const DiscoverSportMatchesPage = () => {
  return (
    <DiscoverScreen>
      <DiscoverSportMatches />
    </DiscoverScreen>
  )
}

export default DiscoverSportMatchesPage
