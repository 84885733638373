import * as React from 'react'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import Drawer from '../../../components/Drawer'

import useSharingMessage from './hooks/use-sharing-message'
import SharingActionsContent from './SharingActionsContent'

interface Props {
  isOpen: boolean
  toggleDialog: () => void
  userFirstName: string
  recapUrl: string
}

const SharingActions = ({ isOpen, toggleDialog, recapUrl, userFirstName }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const sharingMessage = useSharingMessage({ userFirstName, recapUrl })

  return isMobile ? (
    <Drawer open={isOpen} onClose={toggleDialog}>
      <SharingActionsContent sharingMessage={sharingMessage} />
    </Drawer>
  ) : (
    <Dialog open={isOpen} onClose={toggleDialog}>
      <DialogContent>
        <SharingActionsContent sharingMessage={sharingMessage} />
      </DialogContent>
    </Dialog>
  )
}

export default SharingActions
