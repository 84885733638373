import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import PayPalIcon from '@eversports/design-tokens/assets/icons/paypal.svg'
import MastercardIcon from '@eversports/design-tokens/assets/icons/mastercard.svg'
import VisaIcon from '@eversports/design-tokens/assets/icons/visa.svg'
import KlarnaSofortIcon from '@eversports/design-tokens/assets/icons/klarna-sofort.svg'
import HorizontalLogoWhite from '@eversports/design-tokens/assets/logo/horizontal-white.svg'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../localization/react'

const shownPaymentMethodsIcons = [
  { name: 'Paypal', src: PayPalIcon },
  { name: 'Mastercard', src: MastercardIcon },
  { name: 'Visa', src: VisaIcon },
  { name: 'Klarna', src: KlarnaSofortIcon },
]
const currentYear = new Date().getFullYear().toString()

const CopyrightAndPaymentMethods = () => {
  return (
    <Stack alignItems="center" marginTop={10} marginBottom={10} gap={4}>
      <Stack gap={2} textAlign="center">
        <img src={HorizontalLogoWhite} alt="Eversports Logo" width="250px" height="32px" color="white" loading="lazy" />
        <Text variant="label" sx={{ color: 'gray' }}>
          <Localized id="footer-copyright-disclaimer" params={{ currentYear }} />
        </Text>
      </Stack>
      <Stack direction="row" flexWrap="wrap" justifyContent="center">
        {shownPaymentMethodsIcons.map((icon, index) => (
          <Box sx={{ padding: '4px 12px', borderRadius: '8px', backgroundColor: 'white' }} margin={2} key={index}>
            <img
              src={icon.src}
              alt={`${icon.name} is an accepted form of payment`}
              height="32px"
              width="32px"
              loading="lazy"
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  )
}

export default CopyrightAndPaymentMethods
