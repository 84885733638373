import * as React from 'react'
import Icon from '@eversports/klimt-primitives/Icon'
import Stack from '@eversports/klimt-primitives/Stack'
import ChevronLeft from '@eversports/design-tokens/assets/icons/chevron-left.svg'
import ChevronRight from '@eversports/design-tokens/assets/icons/chevron-right.svg'

import IconBadge from '../../../components/Pagination/IconBadge'

interface Props {
  isFirstPage: boolean
  handleBack: () => void
  handleForward: () => void
}

const Pagination = ({ isFirstPage, handleBack, handleForward }: Props) => {
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={4} sx={{ margin: 1 }}>
      {!isFirstPage && (
        <IconBadge onClick={handleBack}>
          <Icon src={ChevronLeft} size="default" color="darkgray" />
        </IconBadge>
      )}
      <IconBadge onClick={handleForward}>
        <Icon src={ChevronRight} size="default" color="darkgray" />
      </IconBadge>
    </Stack>
  )
}

export default Pagination
