import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import { List } from '@eversports/klimt-primitives/List'

import RecapSectionListItem from './RecapSectionListItem'
import { RecapSport, RecapVenue } from './YearlyRecap.types'
import RecapVenueLogo from './RecapVenueLogo'

interface Props {
  title: React.ReactNode
  subtitle: React.ReactNode
  image: React.ReactNode
  listItems?: Array<RecapSport | RecapVenue>
}

const RecapSection = ({ title, subtitle, image, listItems }: Props) => {
  return (
    <Stack
      gap={6}
      textAlign="center"
      justifyContent="center"
      alignItems="center"
      width="100%"
      minHeight="100svh"
      sx={{ scrollSnapAlign: 'start', scrollSnapStop: 'always' }}
    >
      {image}
      <Stack gap={4} paddingX={{ xs: 4, md: 0 }}>
        <Heading is="h3" sx={{ fontSize: '36px', lineHeight: '44px', color: 'midnight' }}>
          {title}
        </Heading>
        <Text variant="large" sx={{ color: 'mdinight' }}>
          {subtitle}
        </Text>
      </Stack>
      {listItems && (
        <List sx={{ width: '100%', gap: 6 }}>
          {listItems.map((item, index) => {
            const name = item.__typename === 'RecapSport' ? item.sport.name : item.venue.name
            const image =
              item.__typename === 'RecapSport' ? undefined : (
                <RecapVenueLogo src={item.venue.logo?.large} variant="small" />
              )
            return (
              <RecapSectionListItem
                key={`${index + 1}-${name}`}
                index={index + 1}
                name={name}
                count={item.bookingCount}
                image={image}
              />
            )
          })}
        </List>
      )}
    </Stack>
  )
}

export default RecapSection
