import * as React from 'react'
import { Tabs, TabsProps } from '@eversports/klimt-primitives/Tabs'

const FriendsTabs = (props: TabsProps) => {
  return (
    <Tabs
      {...props}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto friends tabs"
      sx={{
        backgroundColor: 'white',
        paddingX: { xs: 4, sm: 0 },
        '& .MuiTabs-flexContainer': {
          gap: 4,
        },
      }}
    />
  )
}

export default FriendsTabs
