import { BallsportMatchState } from '../Match.types'

import SuccessfulBookingIcon from './assets/successful-booking-icon.svg'
import PendingBookingIcon from './assets/pending-booking-icon.svg'
import RejectedBookingIcon from './assets/rejected-booking-icon.svg'

export const ballsportMatchStateTranslation = {
  [BallsportMatchState.ACCEPTED]: 'match-thank-you-banner-title-booking-confirmed',
  [BallsportMatchState.MOVED]: 'match-thank-you-banner-title-booking-confirmed',
  [BallsportMatchState.REQUESTED]: 'match-thank-you-banner-title-booking-requested',
  [BallsportMatchState.REJECTED]: 'match-thank-you-banner-title-booking-rejected',
  [BallsportMatchState.CANCELLED]: 'match-thank-you-banner-title-booking-cancelled',
  [BallsportMatchState.PLANNED]: 'match-thank-you-banner-title-booking-planned',
} as const

export const ballsportMatchStateIcon = {
  [BallsportMatchState.ACCEPTED]: SuccessfulBookingIcon,
  [BallsportMatchState.MOVED]: SuccessfulBookingIcon,
  [BallsportMatchState.REQUESTED]: PendingBookingIcon,
  [BallsportMatchState.REJECTED]: RejectedBookingIcon,
  [BallsportMatchState.CANCELLED]: RejectedBookingIcon,
  [BallsportMatchState.PLANNED]: PendingBookingIcon,
} as const
