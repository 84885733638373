import { EventType, Image } from '../../../../../../App.types'

interface PageInfo {
  hasNextPage: boolean
}

export interface PublicParticipationConnection {
  edges: Array<PublicParticipationEdge>
  pageInfo: PageInfo
}

export interface PublicParticipationEdge {
  cursor: string
  node: PublicParticipation
}

export type PublicParticipation = PublicCourtBooking | PublicEventSessionParticipation | BallsportMatch

interface FriendConnection {
  edges: Array<FriendEdge>
}

export interface FriendEdge {
  cursor: string
  node: Friend
}

export interface Friend {
  id: string
  publicUser: PublicUser
}

export interface PublicUser {
  id: string
  firstName: string
  lastName: string
  handle: string
  profilePicture: Image | null
}
export enum ParticipationState {
  PARTICIPATION_STATE_BOOKED = 'PARTICIPATION_STATE_BOOKED',
  PARTICIPATION_STATE_JOINED = 'PARTICIPATION_STATE_JOINED',
}

interface PublicCourtBooking {
  id: string
  timeRange: TimeRange
  participationState: ParticipationState | null
  bookableItem: CourtBookableItem
  attendingFriends: FriendConnection | null
  __typename: 'PublicCourtBooking'
}

interface PublicEventSessionParticipation {
  id: string
  timeRange: TimeRange
  bookableItem: EventBookableItem
  participationState: ParticipationState | null
  attendingFriends: FriendConnection | null
  __typename: 'PublicEventSessionParticipation'
}

interface BallsportMatch {
  id: string
  start: string
  end: string
  sport: Sport
  venue: Venue
  participationState: ParticipationState | null
  attendingFriends: FriendConnection | null
  __typename: 'BallsportMatch'
}

interface TimeRange {
  start: string
  end: string
}

interface CourtBookableItem {
  sport: Sport
  venue: Venue
}

export interface EventBookableItem {
  shortId: string
  eventType: EventType
  activityName: string
  activityImage: Image | null
  sport: Sport
  venue: Venue
}

interface Sport {
  name: string
}

export interface Venue {
  name: string
  location: Location
  images: Array<Image>
}

interface Location {
  street: string
  zip: string
  city: string
}

export interface PublicParticipationBlock {
  date: string
  upcomingParticipations: Array<PublicParticipation>
}
