import * as React from 'react'
import { useEffect, useState } from 'react'
import CalendarIcon from '@eversports/design-tokens/assets/icons/calendar.svg'
import Stack, { StackProps } from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Icon from '@eversports/klimt-primitives/Icon'
import { add, isToday, isTomorrow } from 'date-fns'

import { Localized } from '../localization/react'
import PickerBox from '../components/PickerBox'
import PickerMenu from '../components/PickerMenu'
import PickerDrawer from '../components/PickerDrawer'

import FormatDate from './components/FormatDate'
import DatePickerContent from './DatePickerContent'
import RedPulsatingBlob from './components/RedPulsatingBlob'

type MinWidth = Pick<StackProps, 'minWidth'>

export interface Props extends MinWidth {
  value: Date | null
  onChange: (value: Date | null) => void
  variant: 'simple' | 'pulsating'
  isMobile?: boolean
  maxDate?: Date
}

const DatePicker = ({
  value,
  onChange,
  isMobile,
  maxDate,
  minWidth,
  variant,
  children,
}: React.PropsWithChildren<Props>) => {
  const isSelectedDateToday = value ? isToday(value) : false
  const isSelectedDateTomorrow = value ? isTomorrow(value) : false
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showRedPulsatingBlob, setShowRedPulsatingBlob] = useState(true)

  const openDatePicker = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    if (showRedPulsatingBlob) {
      setShowRedPulsatingBlob(false)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (newValue: Date | null) => {
    onChange(newValue)
  }

  const selectToday = () => {
    onChange(new Date())
  }

  const selectTomorrow = () => {
    const tomorrow = add(new Date(), { days: 1 })
    onChange(tomorrow)
  }

  useEffect(() => {
    setAnchorEl(null)
  }, [value])

  return (
    <>
      <PickerBox onClick={openDatePicker} isActive={Boolean(value)} minWidth={minWidth}>
        <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
          {variant === 'pulsating' && showRedPulsatingBlob && <RedPulsatingBlob />}
          <Text sx={{ display: { xs: value ? 'none' : 'initial', sm: 'initial' } }}>
            <Localized id="date" />
          </Text>
          {value && (
            <Text sx={{ color: 'primary.main', fontWeight: 'bold' }}>
              <FormatDate value={value} />
            </Text>
          )}
          <Icon src={CalendarIcon} size="small" color={value ? 'primary' : 'gray'} />
        </Stack>
      </PickerBox>
      {!isMobile ? (
        <PickerMenu anchorEl={anchorEl} isOpen={Boolean(anchorEl)} onClose={handleClose}>
          <DatePickerContent
            isSelectedDateToday={isSelectedDateToday}
            isSelectedDateTomorrow={isSelectedDateTomorrow}
            selectToday={selectToday}
            selectTomorrow={selectTomorrow}
            handleChange={handleChange}
            value={value}
            maxDate={maxDate}
          />
          {children}
        </PickerMenu>
      ) : (
        <PickerDrawer isOpen={Boolean(anchorEl)} onClose={handleClose}>
          <DatePickerContent
            isSelectedDateToday={isSelectedDateToday}
            isSelectedDateTomorrow={isSelectedDateTomorrow}
            selectToday={selectToday}
            selectTomorrow={selectTomorrow}
            handleChange={handleChange}
            value={value}
            maxDate={maxDate}
            isMobile={true}
          />
          {children}
        </PickerDrawer>
      )}
    </>
  )
}

export default DatePicker
