import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Link from '@eversports/klimt-primitives/Link'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import { useHttpStatus } from '@eversports/react-app-base/http-status/use-http-status'

import NotFoundImage from '../../../static/not-found.svg'
import useGetTopContentHeight from '../../hooks/useGetTopContentHeight'
import { Localized, useIntl } from '../../localization/react'
import getStaticPageMetadata from '../../helpers/get-static-page-metadata'
import MetaHelmet from '../../components/MetaHelmet'

const NotFound = () => {
  const intl = useIntl()
  const { origin } = useSettingsContext()
  const { setStatus } = useHttpStatus()
  const { DESKTOP_TOP_CONTENT_HEIGHT: TOP_CONTENT_HEIGHT } = useGetTopContentHeight(['NAVIGATION_BAR'])

  const meta = getStaticPageMetadata({
    title: intl.notFoundPageTitle(),
    description: intl.notFoundPageDescription(),
    url: origin,
  })

  setStatus(404)

  return (
    <>
      <MetaHelmet meta={meta} noIndex />
      <Stack
        sx={{
          backgroundColor: 'eggshell',
          height: `calc(100vh - ${TOP_CONTENT_HEIGHT}px)`,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        component="main"
      >
        <img src={NotFoundImage} alt="Not Found Image" height="50%" width="100%" />
        <Link to="/">
          <Button variant="primary" sx={{ px: 2, py: 1 }}>
            <Text variant="badge" sx={{ color: 'white' }}>
              <Localized id="back-to-home" />
            </Text>
          </Button>
        </Link>
      </Stack>
    </>
  )
}

export default NotFound
