import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

const AccordionList: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Stack spacing={2} flex={1}>
      {children}
    </Stack>
  )
}

export default AccordionList
