import * as React from 'react'
import KlimtRadioButton, { RadioButtonProps } from '@eversports/klimt-primitives/RadioButton'

export { RadioButtonProps }

const RadioButton = ({ ...props }: RadioButtonProps) => {
  return <KlimtRadioButton {...props} />
}

export default RadioButton
