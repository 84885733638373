import * as React from 'react'
import { useEffect } from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'

import { Venue } from '../VenueProfile.types'
import { Localized } from '../../../localization/react'
import SectionWrapper from '../components/SectionWrapper'
import CallToActionButton from '../Sidebar/CallToAction/CallToActionButton'
import BottomFixedSection from '../Sidebar/BottomFixedSection'
import { Meta } from '../../../App.types'
import MetaHelmet from '../../../components/MetaHelmet'

import TrainerCard from './Trainers/TrainerCard'
import useVenueTrainers, { NUMBER_OF_TRAINERS_TO_FETCH } from './Trainers/hooks/useVenueTrainers'
import TrainerSkeleton from './Trainers/TrainerSkeleton'

const INITIAL_NUMBER_OF_TRAINER_RESULTS = 27

interface Props {
  venue: Venue
  meta: Meta
}

const VenueTeam = ({ venue, meta }: Props) => {
  const { id: venueId, name, trainers: initialTrainers } = venue

  const { loading, fetchMoreTrainers, trainers, hasMoreResults } = useVenueTrainers({
    venueId,
    initialTrainers: initialTrainers.slice(0, INITIAL_NUMBER_OF_TRAINER_RESULTS),
    initialHasMoreResults: initialTrainers.length > INITIAL_NUMBER_OF_TRAINER_RESULTS,
  })

  const { amplitude } = useAmplitude()

  useEffect(() => {
    if (!amplitude) return
    amplitude.logEvent('Viewed Venue Profile Team', {
      venue: {
        numberOfTrainers: venue.trainers.length,
      },
    })
  }, [amplitude])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <MetaHelmet noIndex meta={meta} />
      <Stack gap={4} sx={{ width: '100%' }}>
        <SectionWrapper sx={{ gap: 5 }}>
          <Heading is="h3">
            <Localized id="venue-profile-team-page-title" params={{ venueName: name }} />
          </Heading>
          <Box>
            <Box display="grid" gap={4} gridTemplateColumns={{ xs: '1fr', md: 'repeat(3, 1fr)' }}>
              {trainers.map((trainer) => (
                <TrainerCard key={trainer.id} trainer={trainer} venue={venue} />
              ))}
              {loading && <TrainerSkeleton numberOfCopies={NUMBER_OF_TRAINERS_TO_FETCH} />}
            </Box>
          </Box>
          {hasMoreResults && (
            <Button
              variant="tertiary"
              onClick={() => void fetchMoreTrainers()}
              sx={{ padding: 3, margin: 'auto', width: { xs: '100%', sm: '50%' }, maxWidth: '448px' }}
            >
              <Text sx={{ fontWeight: 'bold', textTransform: 'none' }}>
                <Localized id="load-more" />
              </Text>
            </Button>
          )}
        </SectionWrapper>
        <BottomFixedSection>
          <CallToActionButton offerings={venue.offerings} slug={venue.slug} />
        </BottomFixedSection>
      </Stack>
    </>
  )
}

export default VenueTeam
