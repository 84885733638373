import * as React from 'react'
import ClientLogger from '@eversports/klimt-utilities/client-logger'
import ErrorIcon from '@eversports/design-tokens/assets/icons/error.svg'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from './localization/react'
import NotFound from './pages/NotFound'
import Forbidden from './pages/Forbidden'
import { CustomError } from './App.types'

interface State {
  hasForbiddenError: boolean
  hasUnexpectedError: boolean
  hasNotFoundError: boolean
}

class ErrorBoundary extends React.Component<React.PropsWithChildren<unknown>> {
  public static getDerivedStateFromError(error: CustomError) {
    switch (error.type) {
      case 'NotFoundError':
        return { hasNotFoundError: true }
      case 'ForbiddenError':
        return { hasForbiddenError: true }
      default:
        return { hasUnexpectedError: true }
    }
  }

  public state: State = {
    hasForbiddenError: false,
    hasNotFoundError: false,
    hasUnexpectedError: false,
  }

  public componentDidCatch(error: CustomError, errorInfo: React.ErrorInfo) {
    switch (error.type) {
      case 'NotFoundError':
        ClientLogger.debug(error.name, error, errorInfo)
        break
      case 'ForbiddenError':
        ClientLogger.error(error.message, error, errorInfo)
        break
      default:
        ClientLogger.error(error.message, error, errorInfo)
    }
  }

  public render() {
    if (this.state.hasNotFoundError) {
      return <NotFound />
    }

    if (this.state.hasUnexpectedError) {
      return (
        <Stack gap={4} alignItems="center" justifyContent="center" data-testid="error" sx={{ height: '100vh' }}>
          <Icon src={ErrorIcon} size="x-large" color="red" />
          <Text variant="large" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            <Localized id="unexpected-error-message" />
          </Text>
          <Text variant="large" sx={{ textAlign: 'center' }}>
            <Localized id="unexpected-error-description" />
          </Text>
        </Stack>
      )
    }

    if (this.state.hasForbiddenError) {
      return <Forbidden />
    }

    return this.props.children
  }
}

export default ErrorBoundary
