import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { useEffect, useReducer } from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import Heading from '@eversports/klimt-primitives/Heading'
import Box from '@eversports/klimt-primitives/Box'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import { Venue } from '../VenueProfile.types'
import { Localized } from '../../../localization/react'
import VenueProfileContentContainer from '../VenueProfileContentContainer'
import { SportCategory, useVenueMatchesVenueQuery } from '../../../graphql'
import getTimeRangeForDate from '../../../utils/get-time-range-for-date'
import useMatches from '../../../hooks/useMatches'
import MatchCard, { MatchSkeleton } from '../../../components/MatchCard'
import { Meta } from '../../../App.types'
import MetaHelmet from '../../../components/MetaHelmet'

import { MatchFilters, NoMatchesFound } from './Matches'
import { matchesFiltersInitialState, NUMBER_OF_INITIAL_MATCHES_FETCHED } from './Matches/Matches.constants'
import matchesFiltersReducer from './Matches/helpers/matches-filters-reducer'
import CreateYourMatchCard from './Matches/CreateYourMatchCard'

interface Props {
  venue: Venue
  meta: Meta
}

const VenueMatches = ({ venue, meta }: Props) => {
  const { id: venueId } = venue
  const { amplitude, logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const { matches, filterMatches, loadMoreMatches, loading, hasMoreResults } = useMatches({
    initialFilters: { venueId },
  })
  const { data: venueData } = useVenueMatchesVenueQuery({ variables: { venueId } })
  const [appliedFilters, dispatch] = useReducer(matchesFiltersReducer, matchesFiltersInitialState)
  const sports = venueData?.venue.sports.filter((sport) => sport.category !== SportCategory.FITNESS)

  useEffect(() => {
    if (appliedFilters.date === null && appliedFilters.sports === undefined) return
    void filterMatches({
      sportIds: appliedFilters.sports?.length === 0 ? undefined : appliedFilters.sports,
      timeRange: getTimeRangeForDate(appliedFilters.date),
      venueId,
    })
  }, [appliedFilters])

  useEffect(() => {
    if (!matches || !amplitude) return
    logAmplitudeEventOnce({
      eventName: 'Viewed Venue Profile Matches',
      eventProperties: {
        venue: {
          numberOfMatches: matches.length,
        },
      },
    })
  }, [amplitude, matches])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleFetchMoreMatches = () => {
    void loadMoreMatches({
      sportIds: appliedFilters.sports?.length === 0 ? undefined : appliedFilters.sports,
      timeRange: getTimeRangeForDate(appliedFilters.date),
      venueId,
    })
  }

  return (
    <>
      <MetaHelmet noIndex meta={meta} />
      <Stack sx={{ backgroundColor: 'limelite' }}>
        <MatchFilters dispatch={dispatch} sports={sports} appliedFilters={appliedFilters} />
        <VenueProfileContentContainer variant="fullwidth">
          <Stack gap={[2, 4]} paddingX={{ xs: 5, md: 0 }} paddingY={{ xs: 2, md: 0 }}>
            {venueData?.venue && (
              <Heading is="h1">
                <Localized id="public-matches-section-title" params={{ venueName: venueData.venue.name }} />
              </Heading>
            )}
            {loading || matches.length > 0 ? (
              <Box display="grid" gap={{ xs: 3, md: 4 }} gridTemplateColumns={{ xs: '1fr', md: 'repeat(4, 1fr)' }}>
                {loading ? (
                  <MatchSkeleton numberOfCopies={NUMBER_OF_INITIAL_MATCHES_FETCHED} />
                ) : (
                  <>
                    <CreateYourMatchCard
                      venueSlug={venue.slug}
                      sports={sports}
                      selectedDate={appliedFilters.date}
                      selectedSports={appliedFilters.sports}
                    />
                    {matches.map((match) => (
                      <MatchCard key={match.id} match={match} />
                    ))}
                  </>
                )}
              </Box>
            ) : (
              <NoMatchesFound venueSlug={venue.slug} />
            )}
          </Stack>
          {hasMoreResults && (
            <Button
              variant="tertiary"
              onClick={handleFetchMoreMatches}
              sx={{ padding: 3, margin: 'auto', width: { xs: '100%', sm: '50%' }, maxWidth: '448px' }}
            >
              <Text sx={{ fontWeight: 'bold', textTransform: 'none' }}>
                <Localized id="load-more" />
              </Text>
            </Button>
          )}
        </VenueProfileContentContainer>
      </Stack>
    </>
  )
}

export default VenueMatches
