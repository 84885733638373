import { endOfDay, startOfDay } from 'date-fns'

const getTimeRangeForDate = (date: Date | null) => {
  if (!date) return

  const start = startOfDay(date).toISOString()
  const end = endOfDay(date).toISOString()

  return { start, end }
}

export default getTimeRangeForDate
