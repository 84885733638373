import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import EversportsLogoIcon from '@eversports/design-tokens/assets/logo/icon.svg'
import Avatar from '@eversports/klimt-primitives/Avatar'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Link from '@eversports/klimt-primitives/Link'

import { VenueProfileTabsPrefix, Branch as BranchType } from '../../../../../App.types'

interface Props {
  branch: BranchType
}

export const BranchBadge = ({ branch }: Props) => {
  return (
    <Link to={`${VenueProfileTabsPrefix.OVERVIEW}${branch.slug}`} blank>
      <Box
        pl={3}
        pr={5}
        py={2}
        sx={{
          border: '1px solid',
          borderColor: 'lightgray',
          boxShadow: boxShadows.default,
          backgroundColor: 'white',
          width: 'fit-content',
          cursor: 'pointer',
          borderRadius: 10,
          '&:hover': {
            boxShadow: boxShadows.hover,
          },
        }}
      >
        <Branch branch={branch} />
      </Box>
    </Link>
  )
}

const Branch = ({ branch }: Props) => {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Avatar
        src={branch.logo?.xSmall || EversportsLogoIcon}
        alt={`${branch.name} logo`}
        sx={{
          height: '42px',
          width: '42px',
          border: '1px solid',
          borderColor: 'lightgray',
          borderRadius: 10,
        }}
      />
      <Stack gap={1}>
        <Text variant="small" sx={{ fontWeight: 'bold' }}>
          {branch.name}
        </Text>
        <Text variant="small" sx={{ color: 'darkgray' }}>
          {branch.location.zip}, {branch.location.city}
        </Text>
      </Stack>
    </Stack>
  )
}

export default Branch
