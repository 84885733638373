import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import styled from '@eversports/klimt-design-components/styled'
import { palette } from '@eversports/design-tokens/palette'

import { GAP_PER_IMAGE, IMAGE_WIDTH } from './Thumbnails'

const ThumbnailWrapper = styled(Box)`
  @media (hover: hover) {
    :hover {
      transform: scale(0.95);
    }
  }
`

interface Props {
  imgSrc: string
  isSelected: boolean
  onClick: (value: number) => void
}

const Thumbnail = ({ imgSrc, isSelected, onClick: handleClick }: Props) => (
  <ThumbnailWrapper width={IMAGE_WIDTH} height="100%" mx={`${GAP_PER_IMAGE}px`} onClick={handleClick}>
    <img
      src={imgSrc}
      alt="The Venue's space and team"
      loading="lazy"
      draggable="false"
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        border: isSelected ? `2px solid ${palette.primary}` : 'none',
        padding: '2px',
      }}
    />
  </ThumbnailWrapper>
)

export default Thumbnail
