import * as React from 'react'
import Dialog, { DialogContent, DialogTitle } from '@eversports/klimt-primitives/Dialog'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import Divider from '@eversports/klimt-primitives/Divider'
import { useState } from 'react'
import Link from '@eversports/klimt-primitives/Link'

import { Localized } from '../../../../../localization/react'
import { VenueProfileTabsPrefix, Branch as BranchType } from '../../../../../App.types'

import Branch from './Branch'

interface Props {
  branches: Array<BranchType>
}

const BranchModalItem = ({ branch }: { branch: BranchType }) => {
  return (
    <Link to={`${VenueProfileTabsPrefix.OVERVIEW}${branch.slug}`}>
      <Box
        sx={{
          backgroundColor: 'white',
          cursor: 'pointer',
          py: 3,
          '&:hover': {
            backgroundColor: 'eggshell',
          },
        }}
      >
        <Branch branch={branch} />
      </Box>
    </Link>
  )
}

const BranchesModal = ({ branches }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDialog = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Text
        sx={{ color: 'primary.main', textDecoration: 'underline', cursor: 'pointer', width: 'fit-content' }}
        onClick={toggleDialog}
      >
        <Localized id="branches-view-other-locations" />
      </Text>
      <Dialog
        open={isOpen}
        onClose={toggleDialog}
        sx={{
          '& .MuiDialog-paper': {
            background: '#ffffff',
            border: '1px solid #F2F2F2',
            boxSizing: 'border-box',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.12)',
            borderRadius: '16px',
            minWidth: { lg: '400px' },
          },
        }}
      >
        <DialogTitle>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Text variant="small" sx={{ color: 'darkgray', cursor: 'pointer', flex: 1 }} onClick={toggleDialog}>
              <Localized id="branches-modal-cancel" />
            </Text>
            <Text variant="large" sx={{ fontWeight: 'bold', textAlign: 'center', flex: 4 }}>
              <Localized id="branches-modal-other-locations" />
            </Text>
            <Box sx={{ flex: 1 }} />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack divider={<Divider sx={{ border: '1px solid', borderColor: 'lightgray' }} flexItem />}>
            {branches.map((branch) => (
              <BranchModalItem branch={branch} key={branch.id} />
            ))}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default BranchesModal
