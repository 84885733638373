import * as React from 'react'
import Checkbox, { CheckboxProps } from '@eversports/klimt-forms/Checkbox'
import Box from '@eversports/klimt-primitives/Box'
import { assertNever } from '@eversports/klimt-utilities/assert-never'

export type CheckboxVariant = 'default' | 'drawer'

interface Props extends Pick<CheckboxProps, 'value' | 'label'> {
  name: string
  variant?: CheckboxVariant
}

const FilterCheckbox: React.FC<React.PropsWithChildren<Props>> = ({ value, name, label, variant = 'default' }) => {
  switch (variant) {
    case 'drawer':
      return (
        <Box
          sx={{
            py: 5,
            px: 3,
            borderBottom: '1px solid',
            borderColor: 'mediumgray',
            '& .MuiFormControlLabel-root': {
              width: '100%',
            },
          }}
        >
          <Checkbox
            value={value}
            name={name}
            label={label}
            sx={{
              padding: '0 10px 0 0',
              '&.MuiCheckbox-root': { color: '#DDDDDD' },
              '&.Mui-checked': { color: 'primary.main' },
            }}
          />
        </Box>
      )

    case 'default':
      return (
        <Checkbox
          value={value}
          name={name}
          label={label}
          sx={{
            padding: '0 10px 0 0',
            '&.MuiCheckbox-root': { color: '#DDDDDD' },
            '&.Mui-checked': { color: 'primary.main' },
          }}
        />
      )
    default:
      return assertNever(variant)
  }
}

export default FilterCheckbox
