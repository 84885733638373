import * as React from 'react'
import { default as MuiRadio } from '@mui/material/Radio'
import { default as FormControlLabel, FormControlLabelProps } from '@mui/material/FormControlLabel'

export interface Props extends Omit<FormControlLabelProps, 'control'> {
  label: React.ReactNode
}

const RadioButton: React.FC<React.PropsWithChildren<Props>> = ({ label, ...props }) => {
  return (
    <FormControlLabel label={label} control={<MuiRadio inputProps={{ 'aria-label': label as string }} />} {...props} />
  )
}

export default RadioButton
