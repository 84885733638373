import MarkerIcon from '../../../../static/marker.webp'
import DisabledMarkerIcon from '../../../../static/disabled-marker.webp'
import FocusedMarkerIcon from '../../../../static/focused-marker.webp'
import DisabledFocusedMarkerIcon from '../../../../static/disabled-focused-marker.webp'
import { Marker } from '../Map.types'

interface Props {
  map: google.maps.Map
  type: 'default' | 'active'
  marker: Marker
  clickable?: boolean
  isDisabled?: boolean
}

type Disableable = Pick<Props, 'isDisabled'>

export const markerIcon = ({ isDisabled }: Disableable) => ({
  url: isDisabled ? DisabledMarkerIcon : MarkerIcon,
  scaledSize: new google.maps.Size(36, 45),
})

export const focusedMarkerIcon = ({ isDisabled }: Disableable) => ({
  url: isDisabled ? DisabledFocusedMarkerIcon : FocusedMarkerIcon,
  scaledSize: new google.maps.Size(63, 78),
})

const createMapMarker = ({ map, marker, type, clickable = true, isDisabled = false }: Props) => {
  const mapMarker = new google.maps.Marker({
    position: { lat: marker.latitude, lng: marker.longitude },
    icon: type === 'default' ? markerIcon({ isDisabled }) : focusedMarkerIcon({ isDisabled }),
    optimized: true,
    clickable,
    map,
  })

  return mapMarker
}

export default createMapMarker
