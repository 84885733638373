import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import { Localized } from '../../localization/react'
import { BallsportMatchState, useNonValidBookingStateDisclaimerMatchQuery } from '../../graphql'

import useLoggedInUserType from './hooks/use-logged-in-user-type'
import { UserType } from './Match.types'

const NonValidBookingStateDisclaimer = () => {
  const { id } = useParams<{ id: string }>()
  const { userType } = useLoggedInUserType()
  const { data } = useNonValidBookingStateDisclaimerMatchQuery({ variables: { matchId: id } })

  if (!data) {
    return null
  }

  if (userType === UserType.ORGANIZER && data.match.state === BallsportMatchState.REJECTED) {
    return (
      <Stack gap={4} py={[4, 0]} px={4} alignItems="center" width="100%">
        <Text variant="small" color="red" style={{ textAlign: 'center' }}>
          <Localized id="match-rejected-message" />
        </Text>
      </Stack>
    )
  }

  if (userType === UserType.ORGANIZER && data.match.state === BallsportMatchState.CANCELLED) {
    return (
      <Stack gap={4} py={[4, 0]} px={4} alignItems="center" width="100%">
        <Text variant="small" color="red" style={{ textAlign: 'center' }}>
          <Localized id="match-refund-message" />
        </Text>
      </Stack>
    )
  }

  return null
}

export default NonValidBookingStateDisclaimer
