import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Stack from '@eversports/klimt-primitives/Stack'

interface Props {
  onClick: () => void
}

const SubSectionCard = ({ children, onClick }: React.PropsWithChildren<Props>) => {
  return (
    <Stack
      gap={3}
      onClick={onClick}
      sx={{
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'mediumgray',
        borderRadius: 1,
        boxShadow: boxShadows.default,
        cursor: 'pointer',
        padding: 3,
      }}
    >
      {children}
    </Stack>
  )
}

export default SubSectionCard
