import { default as React } from 'react'
import { Global, css } from '@eversports/klimt-design-components/emotion'

// TODO: Eventually we should not use this anymore, as the map should be easily customizable
// This exists to override the default google map styles that are applied on the info window component that gets shown
// with every marker click in the map.
const GoogleMapsGlobalStyles = () => (
  <Global
    styles={css`
      .gm-style .gm-style-iw {
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        min-height: 150px !important;
        max-width: 500px !important;
        padding-top: 0 !important;
        display: block !important;
      }
      .gm-style-iw.gm-style-iw-c {
        padding: 0 !important;
        border-radius: 16px !important;
        max-height: inherit !important;
        height: fit-content !important;
      }
      .gm-style-iw-d {
        overflow: hidden !important;
        height: fit-content !important;
        max-height: inherit !important;
      }
      button.gm-ui-hover-effect {
        visibility: hidden;
      }
      /* When the browser is at least 600px and bellow */
      @media screen and (max-width: 600px) {
        .gm-style .gm-style-iw {
          max-width: 300px !important;
        }
        div[title='blue-halo'] {
          animation: haloScaleMobile 1.5s ease-in-out infinite alternate;
          -webkit-animation: haloScaleMobile 1.5s ease-in-out infinite alternate;
        }
      }
      @keyframes haloScaleDesktop {
        0% {
          transform: scale(1.5);
        }
        100% {
          transform: scale(2.5);
        }
      }
      @keyframes haloScaleMobile {
        0% {
          transform: scale(1.05);
        }
        100% {
          transform: scale(1.5);
        }
      }
      div[title='blue-halo'] {
        background-color: #1b84ff;
        opacity: 0.2;
        border-radius: 50%;
      }
      /* When the browser is at least 600px and above */
      @media screen and (min-width: 600px) {
        div[title='blue-halo'] {
          animation: haloScaleDesktop 1.5s ease-in-out infinite alternate;
          -webkit-animation: haloScaleDesktop 1.5s ease-in-out infinite alternate;
        }
      }
    `}
  />
)

export default GoogleMapsGlobalStyles
