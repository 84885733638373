import * as React from 'react'
import { useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { useParams } from 'react-router-dom'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { useMatchSettingsOrganizerMatchQuery } from '../../../../graphql'
import useMatchStatus from '../../hooks/use-match-status'
import { MatchStatus } from '../../Match.types'

import { MatchSettingOrganizer } from './MatchSetting'
import { MatchSetting } from './MatchSetting/MatchSetting.types'
import MatchSettingsModal from './MatchSettingsModal'

const MatchSettingsOrganizer = () => {
  const matchStatus = useMatchStatus()
  const { amplitude } = useAmplitude()
  const { id } = useParams<{ id: string }>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data } = useMatchSettingsOrganizerMatchQuery({ variables: { matchId: id } })

  if (!data) {
    return null
  }

  const isDisabled = matchStatus !== MatchStatus.UPCOMING

  const handleOpenModal = () => {
    if (isDisabled) return
    setIsModalOpen(true)
    if (amplitude) {
      amplitude.logEvent('Viewed Match Settings', {
        sport: data?.match.sport.slug,
      })
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Stack direction="row" gap={4}>
      <MatchSettingOrganizer
        type={MatchSetting.LEVEL}
        level={data.match.level}
        onClick={handleOpenModal}
        isDisabled={isDisabled}
      />
      <MatchSettingOrganizer
        type={MatchSetting.COMPETITION_TYPE}
        competitionType={data.match.competitionType}
        onClick={handleOpenModal}
        isDisabled={isDisabled}
      />
      <MatchSettingOrganizer
        type={MatchSetting.GENDER}
        gender={data.match.gender}
        onClick={handleOpenModal}
        isDisabled={isDisabled}
      />
      <MatchSettingsModal isOpen={isModalOpen} onClose={handleCloseModal} desiredVisibility={data.match.visibility} />
    </Stack>
  )
}

export default MatchSettingsOrganizer
