import * as React from 'react'
import { useEffect, useState, useRef } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import Collapse from '@eversports/klimt-primitives/Collapse'

import { Localized } from '../localization/react'

interface Props {
  clampedTextHeight: number
  isDisabled?: boolean
  backgroundColor?: string
}

const ExpandableContent = ({
  children,
  clampedTextHeight,
  isDisabled = false,
  backgroundColor = 'white',
}: React.PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isTextClamped, setIsTextClamped] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(true)

  const toggleCollapse = () => {
    if (isDisabled) return
    setIsCollapsed(!isCollapsed)
  }

  useEffect(() => {
    if (!ref.current) return
    if (ref.current.clientHeight > clampedTextHeight) {
      setIsTextClamped(true)
    }
  }, [])

  return isTextClamped ? (
    <Stack sx={{ position: 'relative' }}>
      <Collapse in={!isCollapsed} collapsedSize={clampedTextHeight}>
        <Box forwardedRef={ref}>{children}</Box>
      </Collapse>
      {isCollapsed ? (
        <Box
          onClick={toggleCollapse}
          sx={{
            paddingLeft: 2,
            boxShadow: `0 0 4px 4px ${backgroundColor}`,
            backgroundColor,
            cursor: 'pointer',
            position: 'absolute',
            bottom: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <Text variant="label" color="midnight">
            <Localized id="see-more-content" />
          </Text>
        </Box>
      ) : (
        <Box onClick={toggleCollapse} sx={{ textAlign: 'right', cursor: 'pointer' }}>
          <Text variant="label" color="midnight">
            <Localized id="see-less-content" />
          </Text>
        </Box>
      )}
    </Stack>
  ) : (
    <Box sx={{ maxHeight: clampedTextHeight, overflow: 'hidden' }}>
      <Box forwardedRef={ref}>{children}</Box>
    </Box>
  )
}

export default ExpandableContent
