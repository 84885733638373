import { PageType } from '../Match.types'
import useUrlParams from '../../../hooks/use-url-params'

const urlCharset = '([-a-zA-Z0-9@:%._+~#=]){1,256}'

const usePageType = () => {
  const { from, returnTo } = useUrlParams()

  const fromCalendar = from?.match(new RegExp(`^/sb/${urlCharset}/?$`))
  const fromMatchesDiscoverPage = from?.match(new RegExp(`^/matches/(${urlCharset})/?(${urlCharset}$)?$`))
  const fromMatchesVenueProfile = from?.match(new RegExp(`^/s/${urlCharset}/matches?$`))

  if (returnTo) {
    return PageType.THANK_YOU
  }

  // /sb/{venueSlug}
  if (fromCalendar) {
    return PageType.CALENDAR
  }

  // /matches/{citySlug}/{sportSlug}
  if (fromMatchesDiscoverPage) {
    return PageType.DISCOVER
  }

  // /s/{venueSlug}/matches
  if (fromMatchesVenueProfile) {
    return PageType.VENUE_PROFILE
  }

  return PageType.OVERVIEW
}

export default usePageType
