import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useMatchUrlQuery } from '../../../graphql'

const useMatchUrl = () => {
  const { id } = useParams<{ id: string }>()
  const [matchUrl, setMatchUrl] = useState<string>('')
  const { data } = useMatchUrlQuery({ variables: { matchId: id } })

  useEffect(() => {
    if (!data || matchUrl) return
    setMatchUrl(`${window.location.origin}${data.match.relativeLink}`)
  }, [data])

  return matchUrl
}

export default useMatchUrl
