import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import TeacherAvatar from '../../../../components/TeacherCard/TeacherAvatar'
import SportTags from '../../../../components/SportTags'
import { Trainer } from '../../VenueProfile.types'

const MAX_NUMBER_OF_SPORT_TAGS = 2

interface Props {
  trainer: Trainer
}

const TrainerCardInfo = ({ trainer }: Props) => {
  return (
    <Stack px={4} gap={{ xs: 2, md: 4 }}>
      <Stack direction="row" alignItems="center" gap={2}>
        <TeacherAvatar imgSrc={trainer.image?.xSmall} />
        <Text variant="label">{trainer?.nickname}</Text>
      </Stack>
      <SportTags sports={trainer.sports} maxNumberOfVisibleSports={MAX_NUMBER_OF_SPORT_TAGS} />
    </Stack>
  )
}

export default TrainerCardInfo
