import * as React from 'react'
import { useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Collapse from '@eversports/klimt-primitives/Collapse'
import Box from '@eversports/klimt-primitives/Box'
import Icon from '@eversports/klimt-primitives/Icon'
import AddIcon from '@eversports/design-tokens/assets/icons/add.svg'
import MinusIcon from '@eversports/design-tokens/assets/icons/minus.svg'

interface Props {
  header: JSX.Element
}

const CollapsibleFilter: React.FC<React.PropsWithChildren<Props>> = ({ children, header }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const handleClick = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <Stack
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '16px 12px',
        border: '0.5px solid #F2F2F2',
        boxShadow: '0px 1px 0px #F2F2F2',
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text variant="badge" sx={{ color: 'darkgray', fontWeight: 'bold', textTransform: 'uppercase' }}>
          {header}
        </Text>
        <Icon src={isCollapsed ? AddIcon : MinusIcon} size="default" color="gray" />
      </Stack>
      <Collapse in={!isCollapsed}>
        <Box marginTop="20px" onClick={(e: Event) => e.stopPropagation()}>
          {children}
        </Box>
      </Collapse>
    </Stack>
  )
}

export default CollapsibleFilter
