import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import Link from '@eversports/klimt-primitives/Link'

import ContentWrapper from '../../components/ContentWrapper'
import { Localized } from '../../../../localization/react'
import { EventBookableItem, EventSession } from '../../ActivityDetails.types'
import WrappedHeader from '../../components/WrappedHeader'
import DateTimeAvailability from '../../Sidebar/DateTimeAvailability'
import { EventTypeToNameMapping } from '../Overview.constants'
import { VenueProfileTabsPrefix } from '../../../../App.types'
import checkHasOneSession from '../../helpers/check-has-one-session'
import FriendsAttending from '../../FriendAttending'

import ShareButton from './ShareButton'
import Description from './Description'
import Difficulty from './Difficulty'
import CalendarButton from './CalendarButton'
import FocusImageWithBackdrop from './FocusImageWithBackdrop'
import Location from './Location'
import Room from './Room'
import SessionComment from './SessionComment'

interface Props {
  activity: EventBookableItem
  eventSessions: Array<EventSession>
}

const InfoSection = ({ activity, eventSessions }: Props) => {
  const {
    activityName,
    description,
    eventType,
    activityImage,
    venue,
    level,
    sport,
    availableSpots,
    registrationState,
    locations,
    sessionComment,
  } = activity
  const hasOneSession = checkHasOneSession(eventSessions)
  return (
    <Stack>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <FocusImageWithBackdrop activityImage={activityImage} activityName={activityName} venueName={venue.name} />
      </Box>
      <ContentWrapper>
        <Stack gap={2}>
          <Stack direction="row" justifyContent="space-between" gap={{ xs: 6, md: 8 }}>
            <WrappedHeader headerText={activityName} />
            <Stack direction="row" gap={3}>
              <ShareButton eventId={activity.id} />
              {hasOneSession && (
                <CalendarButton
                  activityName={activityName}
                  eventType={eventType}
                  sportName={sport.name}
                  eventSession={eventSessions[0]}
                />
              )}
            </Stack>
          </Stack>
          <Stack direction={{ sm: 'row' }} gap={1} flexWrap="wrap">
            <Stack direction="row" gap={1}>
              <Text color="darkgray">{sport.name}</Text>
              <Text color="darkgray" sx={{ textTransform: 'lowercase' }}>
                <Localized id={EventTypeToNameMapping[eventType]} />
              </Text>
            </Stack>
            <Link to={`${VenueProfileTabsPrefix.OVERVIEW}${venue.slug}`} blank ariaLabel={venue.name}>
              <Text color="midnight" sx={{ textDecoration: 'underline' }}>{`@ ${venue.name}`}</Text>
            </Link>
          </Stack>
        </Stack>
        {level && <Difficulty difficulty={level} />}
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <FocusImageWithBackdrop activityImage={activityImage} activityName={activityName} venueName={venue.name} />
        </Box>
        <Stack gap={6}>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <FriendsAttending activityId={activity.id} />
          </Box>
          {description && <Description description={description.html} />}
          {sessionComment && <SessionComment sessionComment={sessionComment} />}
          <Box sx={{ display: { md: 'none' } }}>
            <DateTimeAvailability
              eventSessions={eventSessions}
              availableSpots={availableSpots}
              registrationState={registrationState}
            />
          </Box>
        </Stack>
        <Room activity={activity} />
        <Location locations={locations} eventSessions={eventSessions} />
      </ContentWrapper>
    </Stack>
  )
}

export default InfoSection
