import * as React from 'react'

import FilterMenu from '../../../../../../components/FilterMenu'
import { Localized } from '../../../../../../localization/react'
import { Action } from '../../helpers/matches-filters-reducer'
import { MatchSport } from '../../../../../../App.types'

import SportsFilterForm from './SportsFilterForm'

interface Props {
  dispatch: React.Dispatch<Action>
  sports: Array<MatchSport>
  selectedSports?: Array<string>
}

const DesktopSportsFilter = ({ dispatch, sports, selectedSports }: Props) => {
  const selectedBallsportsNames = sports
    .filter((sport) => selectedSports?.includes(sport.id))
    .map((sport) => sport.name)

  return (
    <FilterMenu
      header={<Localized id="filters-matches-sports-heading" />}
      activeFilters={selectedBallsportsNames}
      minWidth="162px"
    >
      <SportsFilterForm dispatch={dispatch} sports={sports} selectedSports={selectedSports} />
    </FilterMenu>
  )
}

export default DesktopSportsFilter
