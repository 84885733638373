import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import SeoLink from '@eversports/klimt-primitives/SeoLink'
import Box from '@eversports/klimt-primitives/Box'
import Heading from '@eversports/klimt-primitives/Heading'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Skeleton from '@eversports/klimt-primitives/Skeleton'

import { Localized } from '../../../../localization/react'
import { DiscoverPrefix } from '../../../../App.types'
import { useCitySportsDiscoverContextByCityQuery } from '../../../../graphql'

const SPORTS_TO_SHOW = 8
const CARD_WIDTH = 252

const CardWrapper = ({ children }: React.PropsWithChildren) => (
  <Stack
    component="li"
    sx={{
      gap: 3,
      backgroundColor: '#EDF8F6',
      borderRadius: 1,
      padding: 4,
      scrollSnapAlign: 'start',
      flex: { md: 1 },
      flexShrink: 0,
      width: `${CARD_WIDTH}px`,
    }}
  >
    {children}
  </Stack>
)

interface Props {
  citySlug: string
}

const CitySportsCard = ({ citySlug }: Props) => {
  const { data, loading } = useCitySportsDiscoverContextByCityQuery({ variables: { citySlug } })

  if (!data) {
    return null
  }

  const { bookableSports, city } = data.discoverContextByCity

  const shownSports = bookableSports.slice(0, SPORTS_TO_SHOW)

  if (!shownSports?.length) {
    return null
  }

  if (loading) {
    return (
      <CardWrapper>
        <Skeleton variant="text" height="32px" width="150px" />
        <Stack sx={{ padding: 2, flexDirection: 'row', flexWrap: 'wrap', rowGap: 4, columnGap: 2 }}>
          {Array.from(Array(SPORTS_TO_SHOW)).map((_, idx) => (
            <Skeleton key={`skeleton-city-sport-seo-link-${idx}`} variant="text" height="32px" width="60px" />
          ))}
        </Stack>
      </CardWrapper>
    )
  }

  return (
    <CardWrapper>
      <Heading is="h4" color="midnight" sx={{ fontSize: '14px' }}>
        <Localized id="home-city-sport-seo-links-card-header" params={{ city: city.name }} />
      </Heading>
      <Stack
        sx={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          rowGap: 4,
          columnGap: 2,
        }}
      >
        {shownSports.map((sport) => (
          <SeoLink
            key={sport.id}
            type="crawler"
            crawlerLink={`${DiscoverPrefix.SEO_SPORT_CITY}/${sport.slug}/${citySlug}`}
            encodedTo={btoa(`${DiscoverPrefix.APP_SPORT_CITY}/${sport.slug}/${citySlug}`)}
          >
            <Box
              sx={{
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'mediumgray',
                backgroundColor: 'white',
                boxShadow: boxShadows.default,
                paddingX: 2,
                paddingY: 1,
                color: 'midnight',
                width: 'fit-content',
                '&:hover': { boxShadow: boxShadows.hover },
              }}
            >
              {sport.name}
            </Box>
          </SeoLink>
        ))}
      </Stack>
    </CardWrapper>
  )
}

export default CitySportsCard
