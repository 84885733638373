import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Link from '@eversports/klimt-primitives/Link'
import Box from '@eversports/klimt-primitives/Box'
import Svg from '@eversports/klimt-primitives/Svg'

import Routes from '../../App.routes'
import NotificationsIcon from '../../../static/notifications.svg'
import UnreadNotificationCount from '../UnreadNotificationsCount'

import UserMenuLink from './UserMenu/UserMenuLink'
import { ColorContrast } from './Header.types'
import { NotificationsUserItem } from './UserMenu/UserMenuItems'

interface Props {
  colorContrast?: ColorContrast
}

export const NotificationsButton = ({ colorContrast = ColorContrast.NORMAL }: Props) => {
  return (
    <Link to={Routes.NOTIFICATIONS}>
      <Stack direction="row" alignItems="center" position="relative" sx={{ flexShrink: 0 }} gap={2}>
        <Svg
          src={NotificationsIcon}
          width={20}
          height={20}
          color={colorContrast === ColorContrast.NORMAL ? 'midnight' : 'white'}
        />
        <UnreadNotificationCount colorContrast={colorContrast} variant="dot" />
      </Stack>
    </Link>
  )
}

export const NotificationsUserMenuLink = () => {
  return (
    <Box sx={{ '&:hover': { backgroundColor: 'lightgray' } }}>
      <UserMenuLink userItem={NotificationsUserItem} />
    </Box>
  )
}
