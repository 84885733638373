import { VenueOfferingType } from '../../App.types'

export const VENUE_PROFILE_MAP_ID = 'location_map'
export const VENUE_PROFILE_RATING_ID = 'ratings'
export const VENUE_PROFILE_BRANCHES_ID = 'branches'

export const OfferingToTabNameMapping = {
  [VenueOfferingType.VENUE_OFFERING_CLASS_ACTIVITY]: 'venue-tab-classes',
  [VenueOfferingType.VENUE_OFFERING_COURSE_ACTIVITY]: 'venue-tab-courses',
  [VenueOfferingType.VENUE_OFFERING_CAMP_ACTIVITY]: 'venue-tab-camps',
  [VenueOfferingType.VENUE_OFFERING_EVENT_ACTIVITY]: 'venue-tab-events',
  [VenueOfferingType.VENUE_OFFERING_EDUCATION_ACTIVITY]: 'venue-tab-educations',
  [VenueOfferingType.VENUE_OFFERING_WORKSHOP_ACTIVITY]: 'venue-tab-workshops',
  [VenueOfferingType.VENUE_OFFERING_RETREAT_ACTIVITY]: 'venue-tab-retreats',
  [VenueOfferingType.VENUE_OFFERING_TRAINING_ACTIVITY]: 'venue-tab-trainings',
  [VenueOfferingType.VENUE_OFFERING_OPENTRAINING_ACTIVITY]: 'venue-tab-open-trainings',
  [VenueOfferingType.VENUE_OFFERING_VIDEO]: 'venue-tab-videos',
  [VenueOfferingType.VENUE_OFFERING_SHOP]: 'venue-tab-shop',
  [VenueOfferingType.VENUE_OFFERING_SPORTS_FIELDS]: 'venue-tab-sports-fields',
  [VenueOfferingType.VENUE_OFFERING_TEAM]: 'venue-tab-team',
  [VenueOfferingType.VENUE_OFFERING_PUBLIC_MATCHES]: 'venue-tab-public-matches',
  // They don't have a tab representation in the venue profile, so we map them to null
  [VenueOfferingType.VENUE_OFFERING_LIVESTREAM]: null,
  [VenueOfferingType.VENUE_OFFERING_INDOOR_ACTIVITY]: null,
  [VenueOfferingType.VENUE_OFFERING_OUTDOOR_ACTIVITY]: null,
}
