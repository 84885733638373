import { useCallback, useEffect } from 'react'

const GO_FORWARD_KEY = 'ArrowRight'
const GO_BACK_KEY = 'ArrowLeft'

interface Props {
  handleForward: () => void
  handleBack: () => void
  shouldUseShortcuts: boolean
}

const useGalleryKeyboardShortcuts = ({ handleForward, handleBack, shouldUseShortcuts }: Props) => {
  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    if (e.key === GO_BACK_KEY) {
      handleBack()
    } else if (e.key === GO_FORWARD_KEY) {
      handleForward()
    }
  }, [])

  useEffect(() => {
    if (shouldUseShortcuts) {
      document.addEventListener('keydown', handleKeyPress)
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])
}

export default useGalleryKeyboardShortcuts
