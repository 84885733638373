import { default as React, useEffect, useState } from 'react'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import getUserTrackingPreferences from '../../../helpers/get-user-tracking-preferences'
import setUserTrackingPreferences from '../../../helpers/set-user-tracking-preferences'
import { TrackingType } from '../../../App.types'

import CookieBannerComponent from './CookieBannerComponent'

function setAmplitudeCookies({ deviceId, sessionId }: { deviceId?: string; sessionId?: number }) {
  if (deviceId && sessionId) {
    document.cookie = `amplitudeDeviceId=${deviceId}; path=/;`
    document.cookie = `amplitudeSessionId=${sessionId}; path=/;`
  }
}

// Suboptimal solution, but since we currently inject the google tag manager snippet via `createHtmlSkeleton`, reloading the page is necessary at the moment
function handleLocationReload() {
  window.location.reload()
}

const CookieBanner = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [userCookieTrackingPreferences, setUserCookieTrackingPreferences] = useState<{
    [key in TrackingType]: boolean
  }>({
    [TrackingType.ANALYTICS]: false,
    [TrackingType.ADVERTISING]: false,
  })

  const { amplitude } = useAmplitude()
  const userTrackingPreferences = getUserTrackingPreferences()

  const openCookieBanner = () => setIsOpen(!isOpen)

  const handleOnChange = (type: TrackingType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserCookieTrackingPreferences({ ...userCookieTrackingPreferences, [type]: event.target.checked })
  }

  const handleClickAccept = () => {
    setUserTrackingPreferences({ [TrackingType.ANALYTICS]: true, [TrackingType.ADVERTISING]: true })
    setIsOpen(false)
    handleLocationReload()
  }

  const handleClickConfirm = () => {
    setUserTrackingPreferences({
      [TrackingType.ANALYTICS]: userCookieTrackingPreferences.ANALYTICS,
      [TrackingType.ADVERTISING]: userCookieTrackingPreferences.ADVERTISING,
    })
    setIsOpen(false)
    handleLocationReload()
  }

  useEffect(() => {
    const trackingPreferences = getUserTrackingPreferences()
    if (!trackingPreferences) return

    setUserCookieTrackingPreferences({
      [TrackingType.ANALYTICS]: Boolean(trackingPreferences.ANALYTICS),
      [TrackingType.ADVERTISING]: Boolean(trackingPreferences.ADVERTISING),
    })
  }, [])

  useEffect(() => {
    // only check for analytics - we will check for advertising in the auth app
    const userHasSetTrackingPreferences = userTrackingPreferences.ANALYTICS !== null

    if (userHasSetTrackingPreferences) return

    const handleFirstInteraction = () => setIsOpen(true)

    document.addEventListener('touchstart', handleFirstInteraction, { once: true })
    document.addEventListener('mousedown', handleFirstInteraction, { once: true })
    document.addEventListener('onfocus', handleFirstInteraction, { once: true })
    document.addEventListener('scroll', handleFirstInteraction, { once: true })
    document.addEventListener('mousemove', handleFirstInteraction, { once: true })

    return () => {
      document.removeEventListener('touchstart', handleFirstInteraction)
      document.removeEventListener('mousedown', handleFirstInteraction)
      document.removeEventListener('onfocus', handleFirstInteraction)
      document.removeEventListener('scroll', handleFirstInteraction)
      document.removeEventListener('mousemove', handleFirstInteraction)
    }
  }, [])

  useEffect(() => {
    if (amplitude) {
      const deviceId = amplitude.getDeviceId()
      const sessionId = amplitude.getSessionId()
      setAmplitudeCookies({ deviceId, sessionId })
    }
  }, [amplitude])

  return (
    <CookieBannerComponent
      isOpen={isOpen}
      isAnalyticsChecked={userCookieTrackingPreferences.ANALYTICS}
      isAdvertisingChecked={userCookieTrackingPreferences.ADVERTISING}
      openCookieBanner={openCookieBanner}
      handleOnChange={handleOnChange}
      handleClickAccept={handleClickAccept}
      handleClickConfirm={handleClickConfirm}
    />
  )
}

export default CookieBanner
