import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'

import ContainedXLWidthContainer from '../../components/ContainedXLWidthContainer'

interface Props {
  variant: 'fullwidth' | 'with-sidebar'
}

const VenueProfileContentContainer = ({ children, variant }: React.PropsWithChildren<Props>) => {
  return (
    <Box
      sx={{
        py: { xs: 2, lg: 4 },
        px: { xs: 0, lg: 8 },
        width: '100%',
      }}
    >
      <ContainedXLWidthContainer>
        <Box
          sx={{
            backgroundColor: 'eggshell',
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', lg: variant === 'with-sidebar' ? '1fr minmax(278px, 332px)' : '1fr' },
            gap: { xs: 1, lg: 4 },
          }}
        >
          {children}
        </Box>
      </ContainedXLWidthContainer>
    </Box>
  )
}

export default VenueProfileContentContainer
