import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import { motion } from '@eversports/klimt-utilities/framer-motion'
import CloseIcon from '@eversports/design-tokens/assets/icons/close.svg'

import { NPSBannerStep } from './NPSBanner.constants'
import NPSBannerComponentContent from './NPSBannerComponentContent'

const bannerVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.2,
    },
  },
  exit: { opacity: 0, y: 200, transition: { ease: 'easeInOut', duration: 0.3 } },
}

interface Props {
  sliderValue: number | null
  comment: string
  setSliderValue: (value: number) => void
  setComment: (value: string) => void
  step: NPSBannerStep
  handleNextStep: () => void
  handlePreviousStep: () => void
  handleDismiss: () => void
  forwardedRef?: React.Ref<HTMLDivElement>
}

const NPSBannerComponent = ({
  sliderValue,
  comment,
  setSliderValue,
  setComment,
  step,
  handleNextStep,
  handlePreviousStep,
  handleDismiss,
  forwardedRef,
}: Props) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        zIndex: 999,
        width: '100%',
      }}
      forwardedRef={forwardedRef}
    >
      <motion.div variants={bannerVariants} initial="hidden" animate="visible" exit="exit">
        <Box
          sx={{
            margin: 'auto',
            height: { xs: '292px', md: '250px' },
            paddingX: 4,
            paddingY: 6,
            backgroundColor: 'white',
            boxShadow: '0px -8px 8px rgba(20, 20, 20, 0.2)',
            borderRadius: '16px 16px 0px 0px',
          }}
        >
          <Box sx={{ position: 'absolute', left: 16, top: 16, cursor: 'pointer' }} onClick={handleDismiss}>
            <Icon src={CloseIcon} size="default" color="gray" />
          </Box>
          <Stack
            gap={4}
            alignItems="center"
            justifyContent="center"
            sx={{ maxWidth: '575px', margin: '0 auto', height: '100%', textAlign: 'center' }}
          >
            <NPSBannerComponentContent
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
              comment={comment}
              setComment={setComment}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
              step={step}
            />
          </Stack>
        </Box>
      </motion.div>
    </Box>
  )
}

export default NPSBannerComponent
