import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import LocationIcon from '@eversports/design-tokens/assets/icons/location.svg'
import Icon from '@eversports/klimt-primitives/Icon'
import Link from '@eversports/klimt-primitives/Link'

import { Localized } from '../../../../localization/react'
import { VENUE_PROFILE_MAP_ID } from '../../VenueProfile.constants'

interface Props {
  address?: string
}

const Address = ({ address }: Props) => {
  return (
    <Stack direction="row" gap={2} sx={{ cursor: address ? 'pointer' : 'default' }}>
      <Link to={`#${VENUE_PROFILE_MAP_ID}`} external>
        <Icon src={LocationIcon} size="small" color="darkgray" />
        <Text
          variant="small"
          sx={{
            fontWeight: 'bold',
            color: 'text.primary',
            textDecoration: address ? 'underline' : 'default',
          }}
        >
          {address ?? <Localized id="listing-result-online-only-venue" />}
        </Text>
      </Link>
    </Stack>
  )
}

export default Address
