import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'

import { Localized } from '../../../../../localization/react'
import { PublicParticipation } from '../../../PublicUserProfile.types'

import UpcomingBookingsIcon from './assets/upcoming-bookings-icon.webp'
import UpcomingParticipation from './UpcomingParticipation'

interface Props {
  upcomingParticipations: Array<PublicParticipation>
  isMobile?: boolean
}

const AllUpcomingParticipationsContent = ({ upcomingParticipations, isMobile = false }: Props) => {
  return (
    <Stack padding={6} gap={3} minWidth={{ xs: '100%', md: '450px' }}>
      {!isMobile && (
        <Stack direction="row" alignItems="center" gap={2}>
          <img src={UpcomingBookingsIcon} width="17px" height="16px" />
          <Heading is="h3" sx={{ color: 'midnight' }}>
            <Localized id="user-profile-upcoming-bookings-title" />
          </Heading>
        </Stack>
      )}
      <Stack gap={3}>
        {upcomingParticipations.map((participation) => (
          <UpcomingParticipation key={participation.id} participation={participation} />
        ))}
      </Stack>
    </Stack>
  )
}

export default AllUpcomingParticipationsContent
