import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Avatar from '@eversports/klimt-primitives/Avatar'
import Text from '@eversports/klimt-primitives/Text'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Box from '@eversports/klimt-primitives/Box'
import Link from '@eversports/klimt-primitives/Link'
import EversportsLogoIcon from '@eversports/design-tokens/assets/logo/icon.svg'

import { Localized } from '../../../../../../../localization/react'
import { From } from '../../../../../../../App.types'

import { PublicUser } from './SuggestedFriends.types'
import AddSuggestedFriendButton from './AddSuggestedFriendButton'

interface Props {
  friend: PublicUser
}

const SuggestedFriend = ({ friend }: Props) => {
  const from = friend.commonActivitiesCount
    ? From.SUGGESTED_FRIENDS_MUTUAL_ACTIVITY
    : From.SUGGESTED_FRIENDS_MUTUAL_FRIEND

  const to = `/user/${friend.handle}?from=${from}`

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'mediumgray',
        boxShadow: boxShadows.default,
        minWidth: '190px',
        borderRadius: 1,
        padding: 4,
        flex: 1,
      }}
    >
      <Stack gap={2} height="100%">
        <Link to={to}>
          <Stack gap={2}>
            <Avatar
              src={friend.profilePicture?.xSmall || EversportsLogoIcon}
              sx={{
                height: '42px',
                width: '42px',
                border: '1px solid',
                borderColor: 'lightgray',
                backgroundColor: 'white',
                borderRadius: 10,
              }}
            />
            <Stack gap={1}>
              <Text sx={{ color: 'midnight' }}>
                {friend.firstName} {friend.lastName}
              </Text>
              <Text variant="small" sx={{ color: 'carbon' }}>
                @{friend.handle}
              </Text>
            </Stack>
            {friend.commonActivitiesCount ? (
              <Text variant="small" sx={{ color: 'carbon' }}>
                <Localized id="suggested-friend-joined-activities" />
              </Text>
            ) : (
              <Text variant="small" sx={{ color: 'carbon' }}>
                <Localized id="suggested-friend-mutual-friends" params={{ count: friend.commonFriendsCount }} />
              </Text>
            )}
          </Stack>
        </Link>
        <AddSuggestedFriendButton friend={friend} />
      </Stack>
    </Box>
  )
}

export default SuggestedFriend
