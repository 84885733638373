import * as React from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@eversports/klimt-primitives/Box'

import { VenueOfferingType, VenueProfileTabsPrefix } from '../../../../App.types'
import { VenueOffering } from '../../VenueProfile.types'
import NavigationTabs from '../../../../components/NavigationTabs'
import { Tab } from '../../../../components/NavigationTabs/NavigationTabs.types'

import getActiveTab from './helpers/get-active-tab'
import { INTERNAL_LINKED_TABS, NOT_SHOWN_VENUE_OFFERINGS } from './NavigationTabs.constants'
import getTabFromVenueOffering from './helpers/get-tab-from-venue-offering'

interface Props {
  venueOfferings: Array<VenueOfferingType>
  venueSlug: string
}

const VenueProfileNavigationTabs = ({ venueOfferings, venueSlug }: Props) => {
  const location = useLocation()
  const whitelistedVenueOfferings: Array<VenueOffering> = venueOfferings.filter(
    (venueOffering) => !NOT_SHOWN_VENUE_OFFERINGS.includes(venueOffering),
  )

  const venueOfferingTabs = whitelistedVenueOfferings
    .map((venueOffering) => {
      const { to, localizedId } = getTabFromVenueOffering({ venueOffering, venueSlug })
      const isOfferingInternal = INTERNAL_LINKED_TABS.includes(venueOffering)

      return {
        to,
        localizedId,
        isExternal: !isOfferingInternal,
      }
    })
    .filter((tab) => tab.localizedId !== null) as Array<Tab>

  venueOfferingTabs.unshift({
    to: `${VenueProfileTabsPrefix.OVERVIEW}${venueSlug}`,
    localizedId: 'venue-tab-overview',
    isExternal: false,
  })

  const activeTab = getActiveTab(location.pathname)
  const activeTabIndex = whitelistedVenueOfferings.findIndex((tab) => tab === activeTab) + 1

  return (
    <Box
      sx={{
        borderRadius: 1,
        border: { xs: 0, sm: '1px solid' },
        borderColor: { xs: 'none', sm: 'lightgray' },
      }}
    >
      <NavigationTabs tabs={venueOfferingTabs} activeTabIndex={activeTabIndex} tabVariant="venueProfile" />
    </Box>
  )
}

export default VenueProfileNavigationTabs
