import { EventType } from '../../App.types'

export const TRAINER_EVENT_TYPES = [EventType.TRAINING, EventType.OPENTRAINING]

export const paramToEventTypeMapping: { [key in string]: EventType } = {
  camp: EventType.CAMP,
  class: EventType.CLASS,
  course: EventType.COURSE,
  education: EventType.EDUCATION,
  event: EventType.EVENT,
  openTraining: EventType.OPENTRAINING,
  retreat: EventType.RETREAT,
  training: EventType.TRAINING,
  workshop: EventType.WORKSHOP,
}
