import modernHydrateClient from '@eversports/react-app-base/modern-hydrate-client'

import App from '../../App'
import getLocalizationProvider from '../LocalizationProvider'
import fragmentTypes from '../../graphql-checkout-possible-types'

modernHydrateClient({
  App,
  getLocalizationProvider,
  apolloOptions: {
    possibleTypes: fragmentTypes.possibleTypes,
    uri: '/api/checkout',
    typePolicies: {
      Query: {
        fields: {
          venue: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Venue',
                id: args?.venueId,
              })
            },
          },
          match: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'BallsportMatch',
                id: args?.matchId,
              })
            },
          },
        },
      },
    },
  },
}).catch((error) => console.error(error))
