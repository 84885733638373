import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import { assertNever } from '@eversports/klimt-utilities/assert-never'

import { BallsportMatchState, PageType } from '../Match.types'
import { Localized } from '../../../localization/react'
import usePageType from '../hooks/use-page-type'

import OverviewBannerSectionMessage from './OverviewBannerSectionMessage'
import BookingStatus from './components/BookingStatus'

interface Props {
  bookingState: BallsportMatchState
  sportName: string
  isMobile: boolean
}

const OrganizerBannerSectionMessage = ({ bookingState, sportName, isMobile }: Props) => {
  const pageType = usePageType()

  switch (bookingState) {
    case BallsportMatchState.ACCEPTED:
    case BallsportMatchState.MOVED:
      if (pageType === PageType.THANK_YOU) {
        return <BookingStatus type={bookingState} />
      }
      return <OverviewBannerSectionMessage sportName={sportName} isMobile={isMobile} />
    case BallsportMatchState.CANCELLED:
    case BallsportMatchState.REJECTED:
    case BallsportMatchState.PLANNED:
      return <BookingStatus type={bookingState} />
    case BallsportMatchState.REQUESTED:
      return (
        <Stack alignItems="center" gap={1}>
          <BookingStatus type={bookingState} />
          <Text variant={isMobile ? 'regular' : 'large'} sx={{ color: 'white', textShadow: boxShadows.default }}>
            <Localized id="match-thank-you-banner-subtitle-booking-requested" />
          </Text>
        </Stack>
      )
    default:
      return assertNever(bookingState)
  }
}

export default OrganizerBannerSectionMessage
