import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SharingMessage } from '@eversports/klimt-patterns/SharingActions'

import { useIntl } from '../../../../../localization/react'
import { useUseSharingMessageVenueContextQuery } from '../../../../../graphql'

const useSharingMessage = (): SharingMessage => {
  const intl = useIntl()
  const { venueSlug } = useParams<{ venueSlug: string }>()
  const [url, setUrl] = useState<string | undefined>()
  const { data } = useUseSharingMessageVenueContextQuery({ variables: { slug: venueSlug } })

  useEffect(() => {
    setUrl(window.location.href)
  }, [])

  if (!data || !url) return { encodedMessage: '', translatedMessage: '', linkToCopy: '' }

  const params = {
    venueName: data.venueContext.venue.name,
    url,
  }

  //   Message that is shared:
  //
  //   I just found ${venueName} on EverSports!
  //   ➡️ Check out their schedule: ${url}
  const translatedMessage = intl.venueProfileSharingMessage(params)

  return {
    encodedMessage: encodeURIComponent(translatedMessage),
    translatedMessage: translatedMessage,
    linkToCopy: url,
  }
}

export default useSharingMessage
