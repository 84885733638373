import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'

interface Props {
  forwardedRef?: React.MutableRefObject<null>
  onClick?: () => void
}

const MatchSettingParticipantBox = ({ children, forwardedRef, onClick }: React.PropsWithChildren<Props>) => {
  return (
    <Box
      width="fit-content"
      flex={[1, 'initial']}
      forwardedRef={forwardedRef}
      onClick={onClick}
      sx={{
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'mediumgray',
        borderRadius: 1,
        cursor: 'pointer',
        position: 'relative',
        textAlign: 'center',
        minWidth: 100,
        maxWidth: 150,
        py: 1,
        px: 2,
      }}
    >
      {children}
    </Box>
  )
}

export default MatchSettingParticipantBox
