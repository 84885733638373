import * as React from 'react'

import { Action } from '../../helpers/matches-filters-reducer'
import { MatchSport } from '../../../../../../App.types'
import FilterDrawer from '../../../../../../components/FilterDrawer'
import { Localized } from '../../../../../../localization/react'

import SportsFilterForm from './SportsFilterForm'

interface Props {
  dispatch: React.Dispatch<Action>
  sports: Array<MatchSport>
  selectedSports?: Array<string>
}

export const MobileSportsFilter = ({ dispatch, selectedSports, sports }: Props) => {
  return (
    <FilterDrawer header={<Localized id="filters-matches-sports-heading" />} selectedFilterValues={selectedSports}>
      <SportsFilterForm dispatch={dispatch} variant="drawer" sports={sports} selectedSports={selectedSports} />
    </FilterDrawer>
  )
}

export default MobileSportsFilter
