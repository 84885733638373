import * as React from 'react'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import Box from '@eversports/klimt-primitives/Box'

import { useIntl } from '../../../localization/react'
import { SearchComponentProps, InventorySearchResult, CitySearchResult } from '../Search.types'
import SearchSvg from '../SearchInput/SearchSvg'
import SearchComponent from '../SearchComponent'
import { STACKED_POSITIONS } from '../Search.constants'

interface Props extends SearchComponentProps<InventorySearchResult> {
  location: CitySearchResult
  isDefaultOpen?: boolean
}

const SearchInventoryComponent = ({
  searchValue,
  setSearchValue,
  results,
  handleResultClick,
  handleResultEnter,
  isLoading,
  hasInputLabels,
  searchPosition,
  isDefaultOpen = false,
}: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const intl = useIntl()

  const inputLabel = intl.searchForSportOrVenueLabel()

  const searchPlaceholder =
    isMobile && !hasInputLabels ? intl.searchInventoryPlaceholderMobile() : intl.searchInventoryPlaceholderDesktop()

  return (
    <Box flex={{ xs: STACKED_POSITIONS.includes(searchPosition) ? 1 : 3, sm: 3 }} position="relative">
      <SearchComponent
        inputLabel={inputLabel}
        hasInputLabels={hasInputLabels}
        searchPosition={searchPosition}
        iconAdornment={<SearchSvg size={12} />}
        placeholder={searchPlaceholder}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        results={results}
        handleResultClick={handleResultClick}
        handleResultEnter={handleResultEnter}
        isLoading={isLoading}
        isDefaultOpen={isDefaultOpen}
      />
    </Box>
  )
}

export default SearchInventoryComponent
