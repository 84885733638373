import * as React from 'react'
import { useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import SearchIcon from '@eversports/design-tokens/assets/icons/search.svg'
import Text from '@eversports/klimt-primitives/Text'
import Icon from '@eversports/klimt-primitives/Icon'
import Box from '@eversports/klimt-primitives/Box'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../../../../localization/react'
import SubSectionCard from '../../components/SubSectionCard'

import Search from './Search'

const SearchUsers = () => {
  const { amplitude } = useAmplitude()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleDialog = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleClick = () => {
    toggleDialog()
    if (amplitude) {
      amplitude.logEvent('Clicked User Search Bar')
    }
  }

  return (
    <>
      <SubSectionCard onClick={handleClick}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>
              <Localized id="search-friends-title" />
            </Text>
            <Text variant="small">
              <Localized id="search-friends-sub-title" />
            </Text>
          </Stack>
          <Box sx={{ padding: 2, backgroundColor: 'lightgray', borderRadius: 1 }}>
            <Icon src={SearchIcon} size="default" color="carbon" />
          </Box>
        </Stack>
      </SubSectionCard>
      <Search isOpen={isModalOpen} toggleDialog={toggleDialog} />
    </>
  )
}

export default SearchUsers
