import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Heading from '@eversports/klimt-primitives/Heading'

import { Localized } from '../../../../../../../localization/react'
import StoreBadge from '../../../../../../../components/StoreBadge'

import AppScreenshot1 from './assets/app-screenshot-1.webp'
import AppScreenshot2 from './assets/app-screenshot-2.webp'

const AppPromoContent = () => {
  return (
    <Stack gap={{ xs: 3, md: 6 }} alignItems="center" sx={{ textAlign: 'center' }}>
      <Heading is="h3">
        <Localized id="download-app-title" />
      </Heading>
      <Text>
        <Localized id="download-app-description" />
      </Text>
      <Stack direction="row" gap={2}>
        <img src={AppScreenshot1} width="125px" height="auto" />
        <img src={AppScreenshot2} width="125px" height="auto" />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
        <StoreBadge type="app-store" colorVariant="black" height="44px" width="164px" />
        <StoreBadge type="play-store" colorVariant="black" height="44px" width="164px" />
      </Stack>
    </Stack>
  )
}

export default AppPromoContent
