import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import CircularProgress from '@eversports/klimt-primitives/CircularProgress'

const MapLoadingWrapper = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      backgroundColor: 'lightgray',
    }}
  >
    <CircularProgress sx={{ margin: '0 auto' }} />
  </Box>
)

export default MapLoadingWrapper
