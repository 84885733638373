import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import { assertNever } from '@eversports/klimt-utilities/assert-never'
import InfoIcon from '@eversports/design-tokens/assets/icons/info.svg'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import Box from '@eversports/klimt-primitives/Box'

import { Localized } from '../../../../../../localization/react'
import { matchSettingIcon } from '../MatchSetting.constants'
import { MatchSettingType, MatchSetting as MatchSettingEnum } from '../MatchSetting.types'
import { competitionTypeTranslation, genderTranslation, levelTranslation } from '../../MatchSettings.constants'

import MatchSettingParticipantBox from './MatchSettingParticipantBox'

const MatchSettingParticipant = (matchSetting: MatchSettingType & { forwardedRef?: React.MutableRefObject<null> }) => {
  switch (matchSetting.type) {
    case MatchSettingEnum.LEVEL:
      if (!matchSetting.level) return null
      return (
        <MatchSettingParticipantBox forwardedRef={matchSetting.forwardedRef} onClick={matchSetting.onClick}>
          <Stack alignItems="center" gap={1}>
            <img src={matchSettingIcon[matchSetting.type]} width="14px" height="14px" />
            <Text>
              <Localized id={levelTranslation[matchSetting.level]} />
            </Text>
          </Stack>
          <Box sx={{ position: 'absolute', top: 4, right: 4 }}>
            <Icon src={InfoIcon} size="small" color="mediumgray" />
          </Box>
        </MatchSettingParticipantBox>
      )
    case MatchSettingEnum.COMPETITION_TYPE:
      if (!matchSetting.competitionType) return null
      return (
        <MatchSettingParticipantBox forwardedRef={matchSetting.forwardedRef} onClick={matchSetting.onClick}>
          <Stack alignItems="center" gap={1}>
            <img src={matchSettingIcon[matchSetting.type]} width="14px" height="14px" />
            <Text>
              <Localized id={competitionTypeTranslation[matchSetting.competitionType]} />
            </Text>
          </Stack>
          <Box sx={{ position: 'absolute', top: 4, right: 4 }}>
            <Icon src={InfoIcon} size="small" color="mediumgray" />
          </Box>
        </MatchSettingParticipantBox>
      )
    case MatchSettingEnum.GENDER:
      if (!matchSetting.gender) return null
      return (
        <Box width="fit-content" flex={[1, 'initial']} sx={{ border: '1px solid transparent', py: 1, px: 2 }}>
          <Stack alignItems="center" gap={1}>
            <img src={matchSettingIcon[matchSetting.type]} width="14px" height="14px" />
            <Text>
              <Localized id={genderTranslation[matchSetting.gender]} />
            </Text>
          </Stack>
        </Box>
      )
    default:
      return assertNever(matchSetting)
  }
}

export default MatchSettingParticipant
