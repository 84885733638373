import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import Text from '@eversports/klimt-primitives/Text'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Button from '@eversports/klimt-primitives/Button'
import AddFriendsIcon from '@eversports/design-tokens/assets/icons/add-friends.svg'
import * as React from 'react'

interface Props {
  onClick: () => void
  isDisabled?: boolean
}

const ParticipationButton = ({ onClick, isDisabled = false, children }: React.PropsWithChildren<Props>) => {
  return (
    <Button
      variant="primary"
      sx={{
        p: 3,
        border: '0.5px solid',
        borderColor: 'lightgray',
        boxShadow: '0px 2px 2px rgba(33, 166, 150, 0.2), 0px 4px 12px rgba(33, 166, 150, 0.32)',
      }}
      onClick={onClick}
      disabled={isDisabled}
      fullWidth
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Icon src={AddFriendsIcon} color="white" size="default" />
        <Text sx={{ color: 'white', fontWeight: 'bold', textShadow: boxShadows.default, textTransform: 'none' }}>
          {children}
        </Text>
      </Stack>
    </Button>
  )
}

export default ParticipationButton
