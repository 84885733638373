import { DO_NOT_TRACK_PROPERTY_KEY, DO_NOT_TRACK_ADVERTISING_PROPERTY_KEY } from '../App.constants'
import { TrackingType } from '../App.types'

function getUserTrackingPreferences(): { [key in TrackingType]: boolean | null } {
  const doNotTrackValue = localStorage.getItem(DO_NOT_TRACK_PROPERTY_KEY)
  const doNotTrackAdvertisingValue = localStorage.getItem(DO_NOT_TRACK_ADVERTISING_PROPERTY_KEY)

  return {
    [TrackingType.ANALYTICS]: doNotTrackValue ? doNotTrackValue === '0' : null,
    [TrackingType.ADVERTISING]: doNotTrackAdvertisingValue ? doNotTrackAdvertisingValue === '0' : null,
  }
}

export default getUserTrackingPreferences
