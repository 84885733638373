import * as React from 'react'
import { useState } from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import { Localized } from '../../../../../localization/react'
import { MatchStatus, UserType } from '../../../Match.types'
import useLoggedInUserType from '../../../hooks/use-logged-in-user-type'
import useMatchStatus from '../../../hooks/use-match-status'
import Drawer from '../../../../../components/Drawer'

import RemoveParticipants from './RemoveParticipants'

const RemoveParticipantsContainer = () => {
  const theme = useTheme()
  const matchStatus = useMatchStatus()
  const { userType } = useLoggedInUserType()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  if (userType !== UserType.ORGANIZER || matchStatus !== MatchStatus.UPCOMING) {
    return null
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  const handleClick = () => {
    setIsDialogOpen(true)
  }

  return (
    <>
      <Text
        variant="xsmall"
        sx={{ color: 'primary.main', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Localized id="remove-players-action" />
      </Text>
      {isMobile ? (
        <Drawer open={isDialogOpen} onClose={closeDialog}>
          <RemoveParticipants onClose={closeDialog} />
        </Drawer>
      ) : (
        <Dialog
          open={isDialogOpen}
          onClose={closeDialog}
          sx={{ '.MuiDialog-paper': { minWidth: '320px' } }}
          maxWidth="sm"
        >
          <DialogContent>
            <RemoveParticipants onClose={closeDialog} />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default RemoveParticipantsContainer
