import * as React from 'react'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import Text from '@eversports/klimt-primitives/Text'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'

import { Trainer } from '../../ActivityDetails.types'
import { Localized } from '../../../../localization/react'
import { TrainerTranslationKey } from '../../helpers/get-trainer-translation-key'
import Drawer from '../../components/Drawer'

import TrainerContent from './TrainerContent'

interface Props {
  trainer?: Trainer
  isOpen: boolean
  togglePopup: () => void
  trainerHeadingKey: TrainerTranslationKey
}

const TrainerPopup = ({ trainer, isOpen, togglePopup, trainerHeadingKey }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (!trainer) return null

  return (
    <>
      {isMobile ? (
        <Drawer isOpen={isOpen} handleClose={togglePopup} height="60vh">
          <Box sx={{ paddingY: 5, background: 'white' }}>
            <Text variant="large" color="midnight" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              <Localized id={trainerHeadingKey} params={{ trainerCount: 1 }} />
            </Text>
          </Box>
          <Stack gap={3}>
            <TrainerContent trainer={trainer} />
          </Stack>
        </Drawer>
      ) : (
        <Dialog
          open={isOpen}
          onClose={togglePopup}
          sx={{
            '& .MuiDialog-paper': {
              background: 'white',
              border: '1px solid',
              borderColor: 'lightgray',
              boxSizing: 'border-box',
              boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.12)',
              borderRadius: '16px',
              width: { xs: '90vw', md: '500px' },
            },
          }}
        >
          <DialogContent>
            <Stack gap={3}>
              <TrainerContent trainer={trainer} />
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default TrainerPopup
