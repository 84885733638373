import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Popover from '@eversports/klimt-primitives/Popover'
import Text from '@eversports/klimt-primitives/Text'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import Svg from '@eversports/klimt-primitives/Svg'
import Box from '@eversports/klimt-primitives/Box'

import Drawer from '../../../../../components/Drawer'
import { Localized } from '../../../../../localization/react'
import { EventDifficultyLevel } from '../../../ActivityDetails.types'

import { DifficultyToDescriptionMapping, DifficultyIconMapping, DifficultyToNameMapping } from './Difficulty.constants'

const TRIANGLE_OFFSET = 12
const TRIANGLE_WIDTH = 24
const TRIANGLE_HEIGHT = 12

const TriangleForTooltip = () => (
  <Box
    sx={{
      width: `${TRIANGLE_WIDTH}px`,
      height: `${TRIANGLE_HEIGHT}px`,
      position: 'relative',
      overflow: 'hidden',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '16px',
        height: '16px',
        background: 'white',
        transform: 'rotate(45deg)',
        top: '5px',
        left: '5px',
        border: '1px solid',
        borderColor: 'mediumgray',
      },
    }}
  />
)

interface ContentProps {
  difficulty: EventDifficultyLevel
}

const DescriptionsContent = ({ difficulty }: ContentProps) => {
  const showIcon = difficulty !== EventDifficultyLevel.ALL
  return (
    <Stack gap={3} paddingX={4} paddingY={6} sx={{ position: 'relative', width: '350px' }}>
      <Stack direction="row" gap={2}>
        <Text color="midnight" sx={{ fontWeight: 'bold' }}>
          <Localized id="activity-details-difficulty-header" />
        </Text>
        <Stack gap={2} direction="row" alignItems="center">
          {showIcon && <Svg src={DifficultyIconMapping[difficulty]} width={TRIANGLE_WIDTH} height={TRIANGLE_HEIGHT} />}
          <Text color="darkgray">
            <Localized id={DifficultyToNameMapping[difficulty] as any} />
          </Text>
        </Stack>
      </Stack>
      <Text color="darkgray">
        <Localized id={DifficultyToDescriptionMapping[difficulty] as any} />
      </Text>
    </Stack>
  )
}

interface Props {
  isOpen: boolean
  handleClose: () => void
  difficulty: EventDifficultyLevel
  anchorEl?: Element | (() => Element) | null
}

const DifficultyDescriptionsPopup = ({ isOpen, handleClose, difficulty, anchorEl }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return isMobile ? (
    <Drawer open={isOpen} onClose={handleClose}>
      <DescriptionsContent difficulty={difficulty} />
    </Drawer>
  ) : (
    <Popover
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        '&.MuiPopover-root .MuiPopover-paper': { overflow: 'visible', border: '1px solid', borderColor: 'mediumgray' },
        top: `${TRIANGLE_OFFSET}px`,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: `-${TRIANGLE_OFFSET}px`,
          left: { sm: '10%', md: '25%', lg: '50%' },
          transform: { lg: 'translateX(-50%)' },
        }}
      >
        <TriangleForTooltip />
      </Box>
      <DescriptionsContent difficulty={difficulty} />
    </Popover>
  )
}

export default DifficultyDescriptionsPopup
