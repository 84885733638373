import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Heading from '@eversports/klimt-primitives/Heading'
import Link from '@eversports/klimt-primitives/Link'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../localization/react'
import { MarketplaceListingPagesPrefix } from '../../../../App.types'

const OnlineOfferings = () => (
  <Stack gap={3} width={{ md: '50vw' }}>
    <Heading is="h2" variant="small" color="midnight">
      <Localized id="discover-no-results-online-offerings-label" />
    </Heading>
    <Stack direction="row" gap={3}>
      <Link to={MarketplaceListingPagesPrefix.VIDEOS} external>
        <Button variant="tertiary" sx={{ border: '1px solid', borderColor: 'lightgray', textTransform: 'none' }}>
          <Text>
            <Localized id="discover-no-results-videos" />
          </Text>
        </Button>
      </Link>
      <Link to={MarketplaceListingPagesPrefix.LIVESTREAMS} external>
        <Button variant="tertiary" sx={{ border: '1px solid', borderColor: 'lightgray', textTransform: 'none' }}>
          <Text>
            <Localized id="discover-no-results-livestreams" />
          </Text>
        </Button>
      </Link>
    </Stack>
  </Stack>
)

export default OnlineOfferings
