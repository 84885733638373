import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Icon from '@eversports/klimt-primitives/Icon'

interface Props {
  iconSrc: string
  onClick: () => void
}

const Arrow = ({ iconSrc, onClick: handleClick }: Props) => (
  <Box
    onClick={(e: MouseEvent) => {
      e.stopPropagation()
      handleClick()
    }}
    justifyContent="center"
    alignItems="center"
    sx={{
      cursor: 'pointer',
      width: '100%',
      display: { xs: 'none', sm: 'flex' },
    }}
  >
    <Icon src={iconSrc} color="white" size="x-large" />
  </Box>
)

export default Arrow
