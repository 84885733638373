import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Stack, { StackProps } from '@eversports/klimt-primitives/Stack'

type BoxProps = Pick<StackProps, 'sx' | 'id'>

interface Props extends BoxProps {
  top?: number
}

const SectionWrapper = ({ children, sx, ...rest }: React.PropsWithChildren<Props>) => (
  <Stack
    sx={{
      paddingX: 4,
      paddingY: 5,
      gap: 8,
      backgroundColor: 'white',
      border: '1px solid',
      borderColor: 'lightgray',
      boxShadow: boxShadows.default,
      borderRadius: 1,
      width: '100%',
      contentVisibility: 'auto',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Stack>
)

export default SectionWrapper
