import * as React from 'react'
import { useParams } from 'react-router-dom'
import Drawer from '@eversports/klimt-primitives/Drawer'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import { useAllFavouritesPublicUserQuery } from '../../../../../graphql'
import { Localized } from '../../../../../localization/react'
import FullscreenDialog from '../../../../../components/FullscreenDialog'

import AllFavouritesContent from './AllFavouritesContent'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const AllFavourites = ({ isOpen, onClose }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { userHandle } = useParams<{ userHandle: string }>()
  const { data, loading } = useAllFavouritesPublicUserQuery({ variables: { handle: userHandle } })

  if (!data || loading || !data.publicUser.favouriteVenues?.edges) {
    return null
  }

  const venuesToShow = data?.publicUser.favouriteVenues?.edges.map((edge) => edge.node.venue)

  return isMobile ? (
    <FullscreenDialog title={<Localized id="user-profile-favourite-venues-title" />} isOpen={isOpen} onClose={onClose}>
      <AllFavouritesContent favouriteVenues={venuesToShow} isMobile />
    </FullscreenDialog>
  ) : (
    <Drawer anchor="right" open={isOpen} onClose={onClose} sx={{ padding: 6 }}>
      <AllFavouritesContent favouriteVenues={venuesToShow} />
    </Drawer>
  )
}

export default AllFavourites
