import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import MetaHelmet from '../../components/MetaHelmet'
import { useIntl } from '../../localization/react'
import getStaticPageMetadata from '../../helpers/get-static-page-metadata'

import Sidebar from './Sidebar'
import Overview from './Overview'
import { EventBookableItem, EventSession } from './ActivityDetails.types'

interface Props {
  activity: EventBookableItem
  eventSessions: Array<EventSession>
}

const ActivityDetailsComponent = ({ activity, eventSessions }: Props) => {
  const { origin } = useSettingsContext()
  const intl = useIntl()

  const meta = getStaticPageMetadata({
    title: activity.activityName,
    image: activity.activityImage,
    description: intl.activityDetailsMetaDescription({ sportName: activity.sport.name }),
    url: origin,
  })

  return (
    <>
      <MetaHelmet meta={meta} noIndex />
      <Stack
        component="main"
        direction={{ md: 'row' }}
        gap={4}
        sx={{
          paddingX: { md: 8 },
          paddingY: { md: 4 },
          backgroundColor: 'eggshell',
          justifyContent: 'center',
        }}
      >
        <Overview activity={activity} eventSessions={eventSessions} />
        <Sidebar activity={activity} eventSessions={eventSessions} />
      </Stack>
    </>
  )
}

export default ActivityDetailsComponent
