import * as React from 'react'
import TextField from '@eversports/klimt-primitives/TextField'
import Box from '@eversports/klimt-primitives/Box'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import Stack from '@eversports/klimt-primitives/Stack'

import { SearchPosition } from '../Search.types'
import { borderRadiusMapping, borderWidthMapping, STACKED_POSITIONS } from '../Search.constants'
import InputLabel from '../SearchInput/InputLabel'
import { useIntl } from '../../../localization/react'
import SearchSvg from '../SearchInput/SearchSvg'

interface Props {
  searchPosition: SearchPosition
  hasInputLabels?: boolean
}

const InventorySearchPlaceholder = ({ searchPosition, hasInputLabels }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const intl = useIntl()

  const placeholder =
    isMobile && !hasInputLabels ? intl.searchInventoryPlaceholderMobile() : intl.searchInventoryPlaceholderDesktop()

  const inputLabel = hasInputLabels ? (
    <Stack direction="row" gap={4} alignItems="center">
      <InputLabel label={intl.searchForSportOrVenueLabel()} iconAdornment={<SearchSvg size={12} />} />
    </Stack>
  ) : undefined

  const inputIcon = (
    <Box flexShrink={0} marginRight={2}>
      <SearchSvg size={12} />
    </Box>
  )

  return (
    <Box flex={{ xs: STACKED_POSITIONS.includes(searchPosition) ? 1 : 3, sm: 3 }} position="relative">
      <TextField
        id={`search-placeholder-input-${searchPosition}`}
        disabled
        label={inputLabel}
        placeholder={placeholder}
        InputProps={{
          readOnly: isMobile ? true : false,
          startAdornment: !inputLabel ? inputIcon : undefined,
          style: { fontSize: 16 },
        }}
        InputLabelProps={{ shrink: Boolean(inputLabel) }}
        fullWidth
        sx={{
          '& .MuiInputBase-root': {
            height: inputLabel ? '72px' : 'initial',
            paddingLeft: { sm: 4 },
            backgroundColor: 'white',
            borderRadius: borderRadiusMapping[searchPosition],
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: borderWidthMapping[searchPosition],
            },
          },
          '& .MuiInputBase-input': {
            marginTop: inputLabel ? '24px' : '0px',
          },
          '& .MuiInputBase-input::placeholder': {
            color: 'darkgray',
            WebkitTextFillColor: '#4d4d4d',
            opacity: 1,
          },
          '& .MuiFormLabel-root': {
            top: '24px',
            paddingLeft: { sm: 5 },
          },
          '& legend': {
            display: 'none',
          },
          '& fieldset': {
            top: 0,
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderWidth: '1px',
            },
          },
        }}
      />
    </Box>
  )
}

export default InventorySearchPlaceholder
