import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Avatar from '@eversports/klimt-primitives/Avatar'

import { Localized, useIntl } from '../../../localization/react'
import { Trainer } from '../ActivityDetails.types'
import getTrainerTranslationKey from '../helpers/get-trainer-translation-key'
import { EventType } from '../../../App.types'

interface Props {
  trainer?: Trainer
  eventType: EventType
}

const TrainerInfo = ({ trainer, eventType }: Props) => {
  const intl = useIntl()

  // If there's descriptive info, hide this component, because we'll show one in the Overview section with more content.
  const hideTrainerWithContent = !trainer || (trainer.description && trainer.education)

  if (hideTrainerWithContent) {
    return null
  }

  const trainerLabelKey = getTrainerTranslationKey(eventType)

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Avatar
        src={trainer?.image?.xSmall}
        alt={intl.activityDetailsTeacherLabel()}
        sx={{
          borderRadius: '80px',
          border: '1px solid',
          borderColor: 'mediumgray',
          width: '32px',
          height: '32px',
        }}
      />
      <Stack>
        <Text color="midnight">{trainer.nickname}</Text>
        <Text>
          <Localized id={trainerLabelKey} params={{ trainerCount: 1 }} />
        </Text>
      </Stack>
    </Stack>
  )
}

export default TrainerInfo
