import * as React from 'react'
import Box, { BoxProps } from '@eversports/klimt-primitives/Box'

import getNavigationTabVariantStyles from './helpers/get-navigation-tav-variant-styles'
import { NavigationTabVariant } from './NavigationTabs.types'

type PickedBoxProps = Pick<BoxProps, 'sx' | 'onClick'>

interface Props extends PickedBoxProps {
  variant: NavigationTabVariant
}

const NavigationTabBox = ({ sx, variant, children, ...rest }: React.PropsWithChildren<Props>) => {
  return (
    <Box
      sx={{
        px: 6,
        textAlign: 'center',
        flex: { xs: 1, lg: 'initial' },
        position: 'relative',
        ...sx,
        ...getNavigationTabVariantStyles(variant),
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default NavigationTabBox
