import * as React from 'react'
import FormattedContent from '@eversports/klimt-primitives/FormattedContent'
import styled from '@eversports/klimt-design-components/styled'
import ExpandableContent from '@eversports/klimt-patterns/ExpandableContent'

const SmallTextContent = styled('div')`
  font-size: 14px;
  line-height: 21px;
`

const MAX_DESCRIPTION_HEIGHT = 84

interface Props {
  description: string
}

const Description = ({ description }: Props) => (
  <ExpandableContent clampedTextHeight={MAX_DESCRIPTION_HEIGHT}>
    <FormattedContent htmlContent={description} WrapperComponent={SmallTextContent} />
  </ExpandableContent>
)

export default Description
