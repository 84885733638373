import { useState, useEffect } from 'react'

import { Review } from '../../../VenueProfile.types'
import { useUseVenueReviewsVenueQuery } from '../../../../../graphql'

interface ReviewChunk {
  reviews: Array<Review>
  hasNextPage: boolean
}

interface Props {
  id: string
  initialReviews: Array<Review>
  initialHasNextPage: boolean
  reviewChunkNumber: number
}

interface Result {
  reviews: Array<Review>
  hasNextPage: boolean
  isLoading: boolean
}

export const NUMBER_OF_REVIEWS_FETCHED = 4

const useVenueReviews = ({ id, initialReviews, initialHasNextPage, reviewChunkNumber }: Props): Result => {
  const [isLoading, setIsLoading] = useState(false)
  const [allReviewChunks, setAllReviewChunks] = useState<Array<ReviewChunk>>([
    {
      reviews: initialReviews,
      hasNextPage: initialHasNextPage,
    },
  ])

  const { refetch } = useUseVenueReviewsVenueQuery({
    variables: { id },
  })

  const reviewChunk = allReviewChunks[reviewChunkNumber]

  const paginateReviews = async () => {
    if (reviewChunk) return

    setIsLoading(true)

    const currentChunkNumber = reviewChunkNumber - 1
    const currentReviews = allReviewChunks[currentChunkNumber].reviews
    const lastReview = currentReviews[currentReviews.length - 1]

    const { data } = await refetch({
      id,
      first: NUMBER_OF_REVIEWS_FETCHED,
      after: lastReview.cursor,
    })

    if (data) {
      const fetchedReviews = data.venue.reviews.edges.map(({ node, cursor }) => ({ ...node, cursor }))
      const hasNextPage = data.venue.reviews.pageInfo.hasNextPage
      const newChunk: ReviewChunk = { reviews: fetchedReviews, hasNextPage }
      setAllReviewChunks(allReviewChunks.concat(newChunk))
      setIsLoading(false)
    }
  }

  useEffect(() => {
    void paginateReviews()
  }, [reviewChunkNumber])

  if (reviewChunk) {
    return {
      reviews: reviewChunk.reviews,
      hasNextPage: reviewChunk.hasNextPage,
      isLoading: false,
    }
  } else {
    const currentReviewChunk = allReviewChunks[reviewChunkNumber - 1]
    return {
      reviews: currentReviewChunk.reviews,
      hasNextPage: currentReviewChunk.hasNextPage,
      isLoading,
    }
  }
}

export default useVenueReviews
