import * as React from 'react'
import { Table, TableBody } from '@eversports/klimt-primitives/Table'

import { PurchaseOption } from '../../ActivityDetails.types'

import PriceTableRow from './PriceTableRow'

interface Props {
  purchaseOptions: Array<PurchaseOption>
  isCorporateUser: boolean
}

const PriceTable = ({ purchaseOptions, isCorporateUser }: Props) => (
  <Table sx={{ minWidth: 340 }} size="small" aria-label="pricing-table">
    <TableBody>
      {purchaseOptions.map((purchaseOption) => (
        <PriceTableRow key={purchaseOption.id} purchaseOption={purchaseOption} isCorporateUser={isCorporateUser} />
      ))}
    </TableBody>
  </Table>
)

export default PriceTable
