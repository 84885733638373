import * as React from 'react'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Search from '../../components/Search'
import { useIntl } from '../../localization/react'
import MetaHelmet from '../../components/MetaHelmet'
import getStaticPageMetadata from '../../helpers/get-static-page-metadata'

import Forbidden from './Forbidden'

const ForbiddenScreen = () => {
  const intl = useIntl()
  const { origin } = useSettingsContext()

  const meta = getStaticPageMetadata({
    title: intl.forbiddenPageTitle(),
    description: intl.forbiddenPageDescription(),
    url: origin,
  })

  return (
    <>
      <MetaHelmet meta={meta} noIndex />
      <Header position={{ xs: 'relative', lg: 'sticky' }}>
        <Search />
      </Header>
      <Forbidden />
      <Footer />
    </>
  )
}

export default ForbiddenScreen
