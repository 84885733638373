import { Locales } from '../../../../../localization'

interface Props {
  dateString: string
  language: Locales
}

export const formatDate = ({ dateString, language }: Props) => {
  const date = new Date(dateString)
  return date.toLocaleDateString(language, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}
