import Stack from '@eversports/klimt-primitives/Stack'
import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'

import { Invoice } from '../InvoiceHistory.types'
import { Localized } from '../../../localization/react'

import MobileInvoiceCard from './MobileInvoiceCard'

interface Props {
  invoices: Array<Invoice>
}

const MobileInvoiceCards = ({ invoices }: Props) => {
  return (
    <Stack gap={2} display={{ xs: 'flex', md: 'none' }}>
      {invoices.length ? (
        invoices.map((invoice) => <MobileInvoiceCard key={invoice.number} invoice={invoice} />)
      ) : (
        <Text textAlign="center">
          <Localized id="invoice-history-table-text-no-invoices" />
        </Text>
      )}
    </Stack>
  )
}

export default MobileInvoiceCards
