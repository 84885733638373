import * as React from 'react'
import { useMemo } from 'react'
import { Table, TableBody } from '@eversports/klimt-primitives/Table'
import Text from '@eversports/klimt-primitives/Text/Text'
import Box from '@eversports/klimt-primitives/Box/Box'
import Stack from '@eversports/klimt-primitives/Stack/Stack'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import Dialog from '@eversports/klimt-primitives/Dialog/Dialog'
import { DialogContent, DialogTitle } from '@eversports/klimt-primitives/Dialog'

import { Localized } from '../../../../localization/react'
import { ScheduleSession, Ticket } from '../../ActivityDetails.types'
import ScheduleTableRow from '../Schedule/ScheduleTableRow'
import ScheduleCardContent from '../Schedule/ScheduleCardContent'
import Drawer from '../../components/Drawer'

import PriceLabel from './PriceLabel'
import TicketPriceText from './TicketPriceText'

const currentDate = new Date()

interface Props {
  ticket: Ticket
  sessions: Array<ScheduleSession>
  isOpen: boolean
  togglePopup: () => void
  isCorporateUser: boolean
}

const SessionsForTicketPopup = ({ ticket, sessions, isOpen, togglePopup, isCorporateUser }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const upcomingSessions = useMemo(
    () => sessions.filter((session) => new Date(session.start) > currentDate),
    [sessions],
  )
  const pastSessions = useMemo(() => sessions.filter((session) => new Date(session.start) < currentDate), [sessions])

  const hasUpcomingSessions = upcomingSessions.length > 0
  const hasPastSessions = pastSessions.length > 0

  if (isMobile) {
    return (
      <Drawer isOpen={isOpen} handleClose={togglePopup}>
        <Box sx={{ paddingY: 5, background: 'white' }}>
          <Text variant="large" color="midnight" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            <Localized id="activity-detail-ticket-schedule-heading" />
          </Text>
        </Box>
        <Stack gap={5} sx={{ overflow: 'scroll' }}>
          <Stack
            gap={2}
            padding={3}
            sx={{ border: '1px solid', borderColor: 'mediumgray', backgroundColor: 'lightgray', borderRadius: 0.5 }}
          >
            <TicketPriceText ticket={ticket} />
            <Text>{ticket.name}</Text>
            <PriceLabel purchaseOption={ticket} isCorporateUser={isCorporateUser} />
          </Stack>
          {hasUpcomingSessions && (
            <Stack gap={1}>
              <Text color="midnight" sx={{ fontWeight: 'bold' }}>
                <Localized id="activity-detail-ticket-upcoming-sessions-label" />
              </Text>
              <Stack sx={{ 'div:last-child': { borderBottom: 'none' } }}>
                {upcomingSessions.map((session) => (
                  <ScheduleCardContent key={session.id} session={session} />
                ))}
              </Stack>
            </Stack>
          )}
          {hasPastSessions && (
            <Stack gap={1}>
              <Text color="midnight" sx={{ fontWeight: 'bold' }}>
                <Localized id="activity-detail-ticket-past-sessions-label" />
              </Text>
              <Stack sx={{ 'div:last-child': { borderBottom: 'none' } }}>
                {pastSessions.map((session) => (
                  <ScheduleCardContent key={session.id} session={session} />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Drawer>
    )
  }

  return (
    <Dialog
      open={isOpen}
      onClose={togglePopup}
      sx={{
        '& .MuiDialog-paper': {
          background: 'white',
          border: '1px solid',
          borderColor: 'lightgray',
          boxSizing: 'border-box',
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.12)',
          borderRadius: '16px',
          minWidth: { xs: '90vw', lg: '800px' },
          maxHeight: '60vh',
        },
      }}
    >
      <DialogTitle>
        <Text variant="large" color="midnight" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          <Localized id="activity-detail-ticket-schedule-heading" />
        </Text>
      </DialogTitle>
      <DialogContent>
        <Stack gap={8}>
          {hasUpcomingSessions && (
            <Stack gap={3}>
              <Text color="midnight" sx={{ fontWeight: 'bold' }}>
                <Localized id="activity-detail-ticket-upcoming-sessions-label" />
              </Text>
              <Table sx={{ width: '100%' }} aria-label="simple table">
                <TableBody>
                  {upcomingSessions.map((session) => (
                    <ScheduleTableRow key={session.id} session={session} />
                  ))}
                </TableBody>
              </Table>
            </Stack>
          )}
          {hasPastSessions && (
            <Stack gap={3}>
              <Text color="midnight" sx={{ fontWeight: 'bold' }}>
                <Localized id="activity-detail-ticket-past-sessions-label" />
              </Text>
              <Table sx={{ width: '100%' }} aria-label="schedule table">
                <TableBody>
                  {pastSessions.map((session) => (
                    <ScheduleTableRow key={session.id} session={session} />
                  ))}
                </TableBody>
              </Table>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default SessionsForTicketPopup
