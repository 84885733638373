import * as React from 'react'
import { useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Divider from '@eversports/klimt-primitives/Divider'
import Avatar from '@eversports/klimt-primitives/Avatar'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import Box from '@eversports/klimt-primitives/Box'
import ExpandableContent from '@eversports/klimt-patterns/ExpandableContent'

import DefaultUserImage from '../../../../assets/default-user-image.webp'
import { Review } from '../../VenueProfile.types'
import { Localized } from '../../../../localization/react'
import { getRelativeTime } from '../../../../helpers/get-relative-time'

import ReviewPagination from './ReviewPagination'
import useVenueReviews from './hooks/useVenueReviews'

const NUMBER_OF_RESULTS_PER_PAGE = 4
const CLAMPED_TEXT_HEIGHT = 44

interface Props {
  reviews: Array<Review>
  id: string
}

const Comments = ({ reviews: initialReviews, id }: Props) => {
  const [reviewChunkNumber, setReviewChunkNumber] = useState(0)
  const { amplitude } = useAmplitude()

  // We query an additional review in the initial venue profile query.
  const initialHasNextPage = initialReviews.length > NUMBER_OF_RESULTS_PER_PAGE

  const { reviews, isLoading, hasNextPage } = useVenueReviews({
    id,
    initialReviews: initialReviews.slice(0, NUMBER_OF_RESULTS_PER_PAGE),
    initialHasNextPage,
    reviewChunkNumber,
  })

  const handleClick = () => {
    if (amplitude) {
      amplitude.logEvent('Triggered More Venue Reviews')
    }
  }

  return (
    <Stack spacing={3} flex={{ xs: 2, md: 1, lg: 3 }}>
      {!reviews.length ? (
        <Text>
          <Localized id="venue-profile-no-review-comments" />
        </Text>
      ) : (
        reviews.map((review) => {
          const reviewDate = getRelativeTime(review.createdAt)
          return (
            <Stack key={review.id} spacing={4} mt={4}>
              <Stack spacing={3}>
                <Stack direction="row" spacing={3}>
                  <Avatar
                    src={review.user.profilePicture?.xSmall || DefaultUserImage}
                    imgProps={{
                      loading: 'lazy',
                      alt: 'User headshot',
                    }}
                    sx={{
                      height: '44px',
                      width: '44px',
                      borderRadius: 10,
                    }}
                  />
                  <Stack>
                    <Text variant="small" sx={{ fontWeight: 'bold' }}>
                      {review.user.firstName}
                    </Text>
                    <Text variant="small" color="darkgray">
                      <Localized
                        id="date-time-relative-to-now"
                        params={{ timeFrame: reviewDate.timeFrame, timeValue: reviewDate.timeValue }}
                      />
                    </Text>
                  </Stack>
                </Stack>
                <Box>
                  {review.comment && (
                    <ExpandableContent clampedTextHeight={CLAMPED_TEXT_HEIGHT}>
                      <Text>{review.comment}</Text>
                    </ExpandableContent>
                  )}
                </Box>
              </Stack>
              <Divider sx={{ borderColor: 'lightgray' }} />
            </Stack>
          )
        })
      )}
      <ReviewPagination
        isFirstPage={reviewChunkNumber === 0}
        isLastPage={!hasNextPage}
        setPage={setReviewChunkNumber}
        isLoading={isLoading}
        onClick={handleClick}
      />
    </Stack>
  )
}

export default Comments
