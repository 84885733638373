import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import FilledHorizontalLogo from '@eversports/design-tokens/assets/logo/filled-horizontal.svg'

import { Localized } from '../../localization/react'

import RecapOverviewItem from './RecapOverviewItem'
import { Recap } from './YearlyRecap.types'
import TotalMinutesImage from './assets/total-minutes-image.webp'
import ActiveDaysImage from './assets/active-days-image.webp'
import DifferentSportsImage from './assets/different-sports-image.webp'
import TopSportImage from './assets/top-sport-image.webp'
import RecapOverviewImage from './RecapOverviewImage'
import RecapVenueLogo from './RecapVenueLogo'
import RecapOverviewSharingButton from './RecapOverviewSharingButton'

interface Props {
  recap: Recap
  recapUrl: string
}

const RecapOverview = ({ recap, recapUrl }: Props) => {
  return (
    <Stack
      gap={6}
      textAlign="start"
      justifyContent="center"
      alignItems="center"
      width="100%"
      paddingX={4}
      minHeight="100svh"
      sx={{ scrollSnapAlign: 'start', scrollSnapStop: 'always' }}
    >
      <img width="185px" height="38px" src={FilledHorizontalLogo} loading="lazy" />
      <Stack gap={6} paddingTop={4}>
        <RecapOverviewItem
          title={recap.sports[0].sport.name}
          subtitle={<Localized id="yearly-recap-overview-top-sport-description" />}
          image={<RecapOverviewImage src={TopSportImage} variant="medium" />}
        />
        <RecapOverviewItem
          title={recap.venues[0].venue.name}
          subtitle={<Localized id="yearly-recap-overview-top-venue-description" />}
          image={<RecapVenueLogo src={recap.venues[0].venue.logo?.large} variant="medium" />}
        />
        <RecapOverviewItem
          title={
            <Localized id="yearly-recap-overview-total-sports-title" params={{ totalSportsCount: recap.sportsCount }} />
          }
          subtitle={<Localized id="yearly-recap-overview-total-sports-description" />}
          image={<RecapOverviewImage src={DifferentSportsImage} variant="medium" />}
        />
        <RecapOverviewItem
          title={
            <Localized id="yearly-recap-overview-total-days-title" params={{ totalDaysCount: recap.activeDays }} />
          }
          subtitle={<Localized id="yearly-recap-overview-total-days-description" />}
          image={<RecapOverviewImage src={ActiveDaysImage} variant="medium" />}
        />
        <RecapOverviewItem
          title={
            <Localized id="yearly-recap-overview-total-minutes-title" params={{ totalMinutes: recap.minutesActive }} />
          }
          subtitle={<Localized id="yearly-recap-overview-total-minutes-description" />}
          image={<RecapOverviewImage src={TotalMinutesImage} variant="medium" />}
        />
      </Stack>
      <RecapOverviewSharingButton recapUrl={recapUrl} userFirstName={recap.user.firstName} />
    </Stack>
  )
}

export default RecapOverview
