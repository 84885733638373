import * as React from 'react'
import { isToday, isTomorrow } from 'date-fns'
import Text from '@eversports/klimt-primitives/Text'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import { Localized } from '../../localization/react'
import { formatDate } from '../../helpers/format-date'

interface Props {
  date: string
}

const MatchDate = ({ date }: Props) => {
  const dateObj = new Date(date)
  const { language } = useSettingsContext()

  if (isToday(dateObj)) {
    return (
      <Text variant="small" sx={{ color: 'red' }}>
        <Localized id="today" />
      </Text>
    )
  }

  if (isTomorrow(dateObj)) {
    return (
      <Text variant="small" sx={{ color: 'tertiary' }}>
        <Localized id="tomorrow" />
      </Text>
    )
  }

  return <Text variant="small">{formatDate(language, date)}</Text>
}

export default MatchDate
