import * as React from 'react'
import { getDay, isToday, isTomorrow } from 'date-fns'
import Heading from '@eversports/klimt-primitives/Heading'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../../../../../localization/react'

interface Props {
  date: string
}

const FriendsUpcomingParticipationsBlockDate = ({ date }: Props) => {
  const { language } = useSettingsContext()
  const dateObj = new Date(date)

  const dateToShow = dateObj.toLocaleDateString(language, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })

  if (isToday(dateObj)) {
    return (
      <Stack direction="row" alignItems="baseline" paddingX={{ xs: 4, md: 0 }} gap={2}>
        <Heading variant="large" is="h4" sx={{ color: 'midnight' }}>
          <Localized id="today" />
        </Heading>
        <Text sx={{ color: 'carbon' }}>{dateToShow}</Text>
      </Stack>
    )
  }

  if (isTomorrow(dateObj)) {
    return (
      <Stack direction="row" alignItems="baseline" paddingX={{ xs: 4, md: 0 }} gap={2}>
        <Heading variant="large" is="h4" sx={{ color: 'midnight' }}>
          <Localized id="tomorrow" />
        </Heading>
        <Text sx={{ color: 'carbon' }}>{dateToShow}</Text>
      </Stack>
    )
  }

  const dayOfTheWeekIndex = getDay(dateObj)

  return (
    <Stack direction="row" alignItems="baseline" paddingX={{ xs: 4, md: 0 }} gap={2}>
      <Heading variant="large" is="h4" sx={{ color: 'midnight' }}>
        <Localized id="day-of-the-week" params={{ dayOfTheWeekIndex }} />
      </Heading>
      <Text sx={{ color: 'carbon' }}>{dateToShow}</Text>
    </Stack>
  )
}

export default FriendsUpcomingParticipationsBlockDate
