import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../../../../../localization/react'
import { FriendRequestConnection } from '../../Friends.types'
import SectionCard from '../../components/SectionCard'
import NoFriendsOrRequests from '../../components/NoFriendsOrRequests'

import ReceivedFriendRequest from './ReceivedFriendRequest'
import SentFriendRequest from './SentFriendRequest'

interface Props {
  sentRequests: FriendRequestConnection | null
  sentRequestsCount: number
  receivedRequests: FriendRequestConnection | null
  receivedRequestsCount: number
}

const RequestsList = ({ sentRequests, sentRequestsCount, receivedRequests, receivedRequestsCount }: Props) => {
  const sentRequestsToShow = sentRequests?.edges?.map((edge) => edge?.node.to)
  const receivedRequestsToShow = receivedRequests?.edges?.map((edge) => edge?.node.from)
  const hasNoRequests = !sentRequestsToShow?.length && !receivedRequestsToShow?.length

  if (hasNoRequests) {
    return <NoFriendsOrRequests />
  }

  return (
    <Stack gap={{ xs: 3, md: 6 }}>
      {receivedRequestsToShow && receivedRequestsToShow.length > 0 && (
        <SectionCard>
          <Heading is="h3" sx={{ color: 'midnight' }}>
            <Localized id="received-friend-requests-title" params={{ count: receivedRequestsCount }} />
          </Heading>
          {receivedRequestsToShow.map((request) => (
            <ReceivedFriendRequest key={request.id} friend={request} />
          ))}
        </SectionCard>
      )}
      {sentRequestsToShow && sentRequestsToShow.length > 0 && (
        <SectionCard>
          <Heading is="h3" sx={{ color: 'midnight' }}>
            <Localized id="sent-friend-requests-title" params={{ count: sentRequestsCount }} />
          </Heading>
          {sentRequestsToShow.map((request) => (
            <SentFriendRequest key={request.id} friend={request} />
          ))}
        </SectionCard>
      )}
    </Stack>
  )
}

export default RequestsList
