import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../localization/react'

import NoNotificationsImage from './assets/no-notifications.svg'

const NoNotifications = () => {
  return (
    <Stack justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }} gap={4}>
      <img src={NoNotificationsImage} width="240px" height="240px" />
      <div>
        <Heading is="h4" sx={{ color: 'darkgray' }}>
          <Localized id="no-notifications-title" />
        </Heading>
        <Text sx={{ color: 'carbon' }}>
          <Localized id="no-notifications-description" />
        </Text>
      </div>
    </Stack>
  )
}

export default NoNotifications
