export interface SharingMessage {
  encodedMessage: string
  translatedMessage: string
  linkToCopy: string
}

export enum ShareType {
  CopyText = 'copyText',
  Email = 'email',
  Messages = 'messages',
  Whatsapp = 'whatsapp',
  CopyLink = 'copyLink',
}
