import * as React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useInvoiceHistoryAssignedProductQuery } from '../../graphql'

import InvoiceHistoryComponent from './InvoiceHistoryComponent'

const InvoiceHistory = () => {
  const { assignedProductId } = useParams<{ assignedProductId: string }>()

  const { data, loading, error } = useInvoiceHistoryAssignedProductQuery({
    variables: {
      id: assignedProductId,
    },
  })

  useEffect(() => {
    if (!error) return
    throw new Error(error.graphQLErrors[0].message)
  }, [error])

  if (!data || loading) return null

  const { assignedProduct } = data
  if (assignedProduct.__typename !== 'AssignedMembership') {
    throw new Error('Only AssignedMembership is supported for now')
  }

  return <InvoiceHistoryComponent assignedProduct={assignedProduct} />
}

export default InvoiceHistory
