import { CheckboxVariant } from '../../../components/FilterCheckbox'

export enum DiscoverFilterTypes {
  surface = 'surface',
  area = 'area',
  amenity = 'amenity',
  date = 'date',
  time = 'time',
  offering = 'offering',
  venue = 'venue',
  sport = 'sport',
  subSport = 'sub sport',
  specialDeal = 'special deal',
  activityAvailability = 'activity availability',
  competitionType = 'competition type',
  level = 'level',
}

export interface FilterFormProps {
  variant?: CheckboxVariant
}
