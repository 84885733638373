import * as React from 'react'
import { useEffect, useState } from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Stack, { StackProps } from '@eversports/klimt-primitives/Stack'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Avatar from '@eversports/klimt-primitives/Avatar'
import Text from '@eversports/klimt-primitives/Text'

import useLoggedInUser, { LoggedInUser } from '../../../hooks/useLoggedInUser'
import MobileUserMenuDialogComponent from '../../../components/Header/UserMenu/MobileUserMenuDialogComponent'
import DesktopUserMenuDropdownComponent from '../../../components/Header/UserMenu/DesktopUserMenuDropdownComponent'
import CorporateBenefits from '../../../components/Header/CorporateBenefits'
import EversportsLogoLink from '../../../components/Header/EversportsLogoLink'
import Routes from '../../../App.routes'
import { From } from '../../../App.types'
import FriendsConnectionButton from '../../../components/FriendsConnectionButton'

interface RedirectProps {
  redirectLink?: string
}

interface HeaderProps extends RedirectProps {
  user: LoggedInUser
  authUrlParams: string
}

type PositionProp = Pick<StackProps, 'position'>

const Header = ({
  children,
  position = 'sticky',
  redirectLink,
}: React.PropsWithChildren<PositionProp & RedirectProps>) => {
  const { getLoggedInUser, user } = useLoggedInUser()
  const [authUrlParams, setAuthUrlParams] = useState('')

  useEffect(() => {
    getLoggedInUser()
    setAuthUrlParams(
      `?origin=eversport&redirectPath=${encodeURIComponent(window.location.pathname + window.location.search)}`,
    )
  }, [])

  return (
    <Stack
      sx={{
        boxShadow: boxShadows.default,
        top: 0,
        zIndex: 2,
        width: '100%',
        position,
      }}
      component="header"
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          px: { xs: 3, lg: 8 },
          pt: { xs: 3, lg: 2 },
          pb: { xs: 3, lg: 2 },
          width: '100%',
          backgroundColor: 'white',
          zIndex: 1,
        }}
      >
        <MobileHeader user={user} authUrlParams={authUrlParams} redirectLink={redirectLink}>
          {children}
        </MobileHeader>
        <DesktopHeader user={user} authUrlParams={authUrlParams} redirectLink={redirectLink} />
      </Stack>
    </Stack>
  )
}

const MobileHeader = ({ children, user, authUrlParams, redirectLink }: React.PropsWithChildren<HeaderProps>) => {
  return (
    <Stack direction="column" spacing={4} sx={{ width: '100%', display: { xs: 'flex', lg: 'none' } }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={4}>
        <EversportsLogoLink to={redirectLink ?? Routes.HOME} external={Boolean(redirectLink)} />
        {children}
        {redirectLink ? (
          <Link to={redirectLink} external>
            <Stack direction="row" alignItems="center" gap={4}>
              <Avatar src={user?.data?.profilePicture?.xSmall} alt={user?.data?.firstName} />
            </Stack>
          </Link>
        ) : (
          <MobileUserMenuDialogComponent user={user} authUrlParams={authUrlParams} />
        )}
      </Stack>
    </Stack>
  )
}

const DesktopHeader = ({ user, authUrlParams, redirectLink }: HeaderProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: '100%', display: { xs: 'none', lg: 'flex' }, height: '40px' }}
    >
      <Stack direction="row" alignItems="center" sx={{ width: 'calc(55vw - 32px)' }} gap={18}>
        <EversportsLogoLink to={redirectLink ?? Routes.HOME} external={Boolean(redirectLink)} />
      </Stack>
      {redirectLink ? (
        <Link to={redirectLink} external>
          <Stack direction="row" alignItems="center" gap={4}>
            <Avatar src={user?.data?.profilePicture?.xSmall} />
            <Text variant="label" sx={{ fontWeight: 'bold' }}>
              {user?.data?.firstName}
            </Text>
          </Stack>
        </Link>
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={7} flex="1">
          {user?.data && <FriendsConnectionButton to={`/user/${user.data.handle}/friends?from=${From.HEADER}`} />}
          <CorporateBenefits corporateBenefits={user?.data?.corporateBenefits} />
          <DesktopUserMenuDropdownComponent user={user} authUrlParams={authUrlParams} />
        </Stack>
      )}
    </Stack>
  )
}

export default Header
