import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import * as React from 'react'
import Avatar from '@eversports/klimt-primitives/Avatar'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Link from '@eversports/klimt-primitives/Link'

import OrganizerCrown from '../../assets/organizer-crown.svg'
import { From } from '../../../../App.types'

interface Props {
  handle: string
  firstName: string
  lastName: string
  isActiveUser: boolean
  profilePicture?: string
  isOrganizer?: boolean
}

const Participant = ({ handle, firstName, lastName, isActiveUser, profilePicture, isOrganizer }: Props) => {
  return (
    <Link to={`/user/${handle}?from=${From.MATCH_DETAIL}`}>
      <Box
        px={2}
        py={1}
        sx={{
          width: 'fit-content',
          border: '1px solid',
          borderRadius: 1,
          boxShadow: boxShadows.default,
          backgroundColor: isActiveUser ? 'limelite' : 'white',
          borderColor: isActiveUser ? 'rgba(33, 166, 150, 0.3)' : 'mediumgray',
          cursor: 'pointer',
          '&:hover': {
            boxShadow: '0px 4px 4px rgb(20 20 20 / 20%)',
          },
        }}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Avatar src={profilePicture} alt="User Picture" sx={{ width: '24px', height: '24px' }} />
          <Text variant="small" sx={{ color: 'midnight' }}>
            {firstName} {lastName}
          </Text>
          {isOrganizer && <img src={OrganizerCrown} alt="Organizer Crown" width="12px" height="12px" />}
        </Stack>
      </Box>
    </Link>
  )
}

export default Participant
