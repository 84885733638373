import { DiscoverMatchesFiltersState } from './DiscoverSportMatches.types'

export const discoverMatchesFiltersInitialState: DiscoverMatchesFiltersState = {
  sports: undefined,
  venues: undefined,
  date: null,
}

export const DEFAULT_NUMBER_OF_MATCHES_FETCHED = 12
export const DEFAULT_RADIUS_OF_GEO_SEARCH_IN_METER = 15000
