import { Image, Sport, MonetaryValue, EventType } from '../../App.types'

export interface EventBookableItem {
  id: string
  activityImage: Image | null
  activityName: string
  availableSpots: SpotLimit | null
  cancellationCondition: string
  description: {
    html: string
  } | null
  eventSessions: Array<EventSession>
  eventType: EventType
  locations: Array<LocationType>
  level: EventDifficultyLevel | null
  purchaseOptions: Array<PurchaseOption>
  registrationState: RegistrationState
  registrationType: EventRegistrationType
  registrationWindow: OpenTimeRange
  registrationWindowState: EventRegistrationWindowState
  sessionComment: SessionComment | null
  sport: Sport
  trainers: Array<Trainer>
  venue: Venue
}

export interface SessionComment {
  title: string
  comment: {
    html: string
  }
}

export interface Trainer {
  id: string
  nickname: string
  image: Image | null
  description: string | null
  education: string | null
}

export interface Venue {
  id: string
  address: string
  city: {
    id: string
    name: string
  }
  logo: Image | null
  name: string
  rating: number | null
  reviewCount: number
  reviews: Array<Review>
  slug: string
}

export enum EventRegistrationType {
  ALL_SESSIONS = 'ALL_SESSIONS',
  SINGLE_SESSION = 'SINGLE_SESSION',
  TICKETS = 'TICKETS',
}

export interface OpenTimeRange {
  start: string | null
  end: string | null
}

export enum EventRegistrationWindowState {
  CLOSED = 'CLOSED',
  NOT_YET_OPEN = 'NOT_YET_OPEN',
  OPEN = 'OPEN',
}

export interface Review {
  id: string
  comment: string | null
  user: User
  createdAt: string
  cursor: string | null
}

interface User {
  id: string
  firstName: string
  profilePicture: Image | null
}

export interface LocationType {
  detail: string
  street: string
  city: string
  zip: string
  latitude: number | null
  longitude: number | null
  indoor: boolean | null
}

export interface Court {
  id: string
  name: string
}

export interface EventSession {
  id: string
  name: string
  start: string
  end: string
  location: LocationType | null
  trainer: Pick<Trainer, 'id' | 'nickname' | 'image'> | null
  hasOnlineStream: boolean
  court: Court | null
}

export type ScheduleSession = Pick<EventSession, 'id' | 'start' | 'end' | 'location' | 'trainer' | 'hasOnlineStream'>

interface SinglePurchase {
  id: string
  __typename: 'SinglePurchase'
  name: string
  price: MonetaryValue
}

export enum SpecialPriceType {
  CORPORATE_EMPLOYEES = 'CORPORATE_EMPLOYEES',
}

export interface SpecialPrice {
  price: MonetaryValue | null
  type: SpecialPriceType
}

export interface Card {
  id: string
  __typename: 'Card'
  isTrialCard: boolean
  name: string
  price: MonetaryValue
  specialPrices: Array<SpecialPrice>
}
interface Membership {
  id: string
  __typename: 'Membership'
  name: string
  paymentPlan: {
    promotion: {
      price: MonetaryValue
      period: Interval
    } | null
    recurring: MonetaryValue
    interval: Interval
  }
}

enum IntervalUnit {
  INTERVAL_UNIT_DAYS = 'INTERVAL_UNIT_DAYS',
  INTERVAL_UNIT_HOURS = 'INTERVAL_UNIT_HOURS',
  INTERVAL_UNIT_MONTHS = 'INTERVAL_UNIT_MONTHS',
  INTERVAL_UNIT_WEEKS = 'INTERVAL_UNIT_WEEKS',
  INTERVAL_UNIT_YEARS = 'INTERVAL_UNIT_YEARS',
}

interface Interval {
  type: IntervalUnit
  value: number
}

export interface Ticket {
  id: string
  __typename: 'Ticket'
  name: string
  standardPrice: MonetaryValue
  validForSessions: Array<ScheduleSession>
  earlyBirdPrice: MonetaryValue | null
  currentPriceType: TicketPriceType
  lastMinutePrice: MonetaryValue | null
}

export enum TicketPriceType {
  TICKET_PRICE_TYPE_EARLY_BIRD = 'TICKET_PRICE_TYPE_EARLY_BIRD',
  TICKET_PRICE_TYPE_LAST_MINUTE = 'TICKET_PRICE_TYPE_LAST_MINUTE',
  TICKET_PRICE_TYPE_STANDARD = 'TICKET_PRICE_TYPE_STANDARD',
}

export type PurchaseOption = Card | Membership | SinglePurchase | Ticket
interface LimitedSpots {
  displayCapped: boolean
  limit: number
  __typename: 'LimitedSpots'
}

interface UnlimitedSpots {
  __typename: 'UnlimitedSpots'
}

export type SpotLimit = LimitedSpots | UnlimitedSpots

export enum EventDifficultyLevel {
  ALL = 'ALL',
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  PROFESSIONAL = 'PROFESSIONAL',
}

export enum EventRegistrationValidationProblem {
  ALREADY_REGISTERED = 'ALREADY_REGISTERED',
  CANCELLED = 'CANCELLED',
  CUSTOMERGROUP_RESTRICTED = 'CUSTOMERGROUP_RESTRICTED',
  FULLY_BOOKED = 'FULLY_BOOKED',
  INVALID_EVENT = 'INVALID_EVENT',
  IN_PAST = 'IN_PAST',
  MAX_FUTURE_BOOKINGS = 'MAX_FUTURE_BOOKINGS',
  NOT_ACTIVE = 'NOT_ACTIVE',
  REGISTRATION_WINDOW = 'REGISTRATION_WINDOW',
  NOT_BOOKABLE = 'NOT_BOOKABLE',
}

export interface RegistrationState {
  bookable: boolean
  validationProblems: Array<EventRegistrationValidationProblem>
}

export interface ParticipatingFriends {
  nodes: Array<Friend>
}

export interface Friend {
  publicUser: PublicUser
}

export interface PublicUser {
  id: string
  firstName: string
  lastName: string
  handle: string
  profilePicture: Image | null
}
