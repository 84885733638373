import * as React from 'react'
import { useMemo } from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import { Localized } from '../../../../localization/react'
import ContentWrapper from '../../components/ContentWrapper'
import { ScheduleSession } from '../../ActivityDetails.types'

import ScheduleCards from './ScheduleCards'
import ScheduleTable from './ScheduleTable'
import { sortSessions } from './helpers/sort-sessions'

interface Props {
  eventSessions: Array<ScheduleSession>
}

const Schedule = ({ eventSessions }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const sortedSessions = useMemo(() => sortSessions(eventSessions), [eventSessions])

  return (
    <ContentWrapper>
      <Stack gap={1}>
        <Heading is="h2" variant="small" color="midnight">
          <Localized id="activity-detail-schedule-heading" />
        </Heading>
        <Text color="carbon" variant="small">
          <Localized id="activity-detail-schedule-subheading" />
        </Text>
      </Stack>
      {isMobile ? <ScheduleCards sessions={sortedSessions} /> : <ScheduleTable sessions={sortedSessions} />}
    </ContentWrapper>
  )
}

export default Schedule
