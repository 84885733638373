import { isOfferingASingleSessionActivity, VenueOfferingType, VenueProfileTabsPrefix } from '../App.types'

import getVenueFirstOfferingTab from './get-link-to-venue-first-offering'

interface Props {
  venueOfferings: Array<VenueOfferingType>
  venueSlug: string
  teacherId: string
}

export const getLinkToTeacherOffering = ({
  venueOfferings,
  venueSlug,
  teacherId,
}: Props): { to: string; isOverviewPage: boolean } => {
  const path = {
    to: `${VenueProfileTabsPrefix.OVERVIEW}${venueSlug}`,
    isOverviewPage: true,
  }

  if (venueOfferings.length) {
    const [firstVenueOffering, secondVenueOffering] = venueOfferings

    const isFirstOfferingSportsFields = firstVenueOffering === VenueOfferingType.VENUE_OFFERING_SPORTS_FIELDS

    const offeringForTeacherLink =
      isFirstOfferingSportsFields && secondVenueOffering ? secondVenueOffering : firstVenueOffering

    if (offeringForTeacherLink) {
      let linkToTab = getVenueFirstOfferingTab({ firstVenueOffering: offeringForTeacherLink, venueSlug })

      const isSingleSessionActivity = isOfferingASingleSessionActivity(offeringForTeacherLink)

      if (teacherId && isSingleSessionActivity) {
        linkToTab = `${linkToTab}?trainer=${teacherId}`
      }

      path.isOverviewPage = false
      path.to = linkToTab
    }
  }

  return path
}
