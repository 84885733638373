import * as React from 'react'
import { useEffect } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import FilledHorizontalLogo from '@eversports/design-tokens/assets/logo/filled-horizontal.svg'
import Link from '@eversports/klimt-primitives/Link'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import { Localized, useIntl } from '../../localization/react'
import MetaHelmet from '../../components/MetaHelmet'
import getStaticPageMetadata from '../../helpers/get-static-page-metadata'

import BackgroundImage from './assets/background-image.webp'
import { ENGLISH_LINK, GERMAN_LINK } from './UserInterviewRegistration.constants'

const UserInterviewRegistration = () => {
  const intl = useIntl()
  const { amplitude } = useAmplitude()
  const { origin } = useSettingsContext()

  useEffect(() => {
    if (amplitude) {
      amplitude.logEvent('Viewed User Interview Registration Page')
    }
  }, [amplitude])

  const meta = getStaticPageMetadata({
    title: intl.userInterviewRegistrationMetaTitle(),
    description: intl.userInterviewRegistrationMetaDescription(),
    url: origin,
  })

  return (
    <>
      <MetaHelmet meta={meta} noIndex />
      <Stack
        sx={{
          height: `100vh`,
          background: `url(${BackgroundImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
        component="main"
      >
        <Stack gap={30} padding={4} alignItems="center" justifyContent="center" margin="auto" maxWidth="345px">
          <img src={FilledHorizontalLogo} width="300px" height="70px" />
          <Stack gap={6}>
            <Heading is="h3" sx={{ color: 'midnight', textAlign: 'center' }}>
              <Localized id="user-interview-registration-title" />
            </Heading>
            <Link to={GERMAN_LINK} external>
              <Button variant="primary" sx={{ padding: 4 }}>
                <Text variant="large" sx={{ color: 'white', textTransform: 'none', fontWeight: 'bold' }}>
                  <Localized id="user-interview-registration-german" />
                </Text>
              </Button>
            </Link>
            <Link to={ENGLISH_LINK} external>
              <Button variant="primary" sx={{ padding: 4 }}>
                <Text variant="large" sx={{ color: 'white', textTransform: 'none', fontWeight: 'bold' }}>
                  <Localized id="user-interview-registration-english" />
                </Text>
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default UserInterviewRegistration
