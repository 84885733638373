import * as React from 'react'
import Avatar from '@eversports/klimt-primitives/Avatar'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import TeacherDefaultImage from '../../assets/teacher-default-image.webp'

interface Props {
  imgSrc?: string
}

const TeacherAvatar = ({ imgSrc }: Props) => (
  <Avatar
    src={imgSrc || TeacherDefaultImage}
    imgProps={{
      loading: 'lazy',
      alt: 'Teacher headshot',
    }}
    sx={{
      height: '80px',
      width: '80px',
      border: '1px solid white',
      filter: `drop-shadow(${boxShadows.default})`,
      borderRadius: 10,
    }}
  />
)

export default TeacherAvatar
