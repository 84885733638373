import * as React from 'react'
import Heading from '@eversports/klimt-primitives/Heading'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Stack from '@eversports/klimt-primitives/Stack'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

import { Localized } from '../../../../localization/react'
import { BallsportMatchState } from '../../Match.types'
import { ballsportMatchStateIcon, ballsportMatchStateTranslation } from '../BannerSection.constants'

interface Props {
  type: BallsportMatchState
}

const BookingStatus = ({ type }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Stack direction="row" alignItems="center" gap={3}>
      <Heading is="h1" variant={isMobile ? 'regular' : 'large'} sx={{ color: 'white', textShadow: boxShadows.default }}>
        <Localized id={ballsportMatchStateTranslation[type] as any} />
      </Heading>
      <img src={ballsportMatchStateIcon[type]} width="20px" height="20px" />
    </Stack>
  )
}

export default BookingStatus
