import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Button from '@eversports/klimt-primitives/Button'
import * as React from 'react'
import { useState } from 'react'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../localization/react'

import ShareIconSVG from './assets/share-icon.svg'
import SharingActions from './SharingActions'

interface Props {
  userFirstName: string
  recapUrl: string
}

const RecapOverviewSharingButton = ({ userFirstName, recapUrl }: Props) => {
  const { amplitude, logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen)
    if (amplitude) {
      logAmplitudeEventOnce({
        eventName: 'Clicked 2023 Yearly Recap Share CTA Button',
        eventProperties: {},
      })
    }
  }

  const sharingRecapUrl = `${recapUrl}?view=sharing`

  return (
    <Stack width="100%" gap={5}>
      <Button variant="primary" sx={{ paddingY: 2.5, paddingX: 8, marginTop: 4, width: '100%' }} onClick={toggleDialog}>
        <Stack direction="row" alignItems="center" sx={{ textTransform: 'none' }} gap={2}>
          <img src={ShareIconSVG} width="20px" height="20px" />
          <Heading is="h6" sx={{ color: 'white' }}>
            <Localized id="yearly-recap-share-button" />
          </Heading>
        </Stack>
      </Button>
      <Text variant="xsmall" textAlign="center">
        <Localized id="yearly-recap-online-bookings-only-disclaimer" />
      </Text>
      <SharingActions
        isOpen={isDialogOpen}
        toggleDialog={toggleDialog}
        recapUrl={sharingRecapUrl}
        userFirstName={userFirstName}
      />
    </Stack>
  )
}

export default RecapOverviewSharingButton
