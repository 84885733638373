import * as React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import { useFriendsOfUserPublicUserQuery } from '../../../../../graphql'
import LoadingPage from '../../../../../components/LoadingPage'
import { NUMBER_OF_INITIAL_FRIENDS_FETCHED } from '../Friends.constants'

import FriendsOfUserComponent from './FriendsOfUserComponent'

const FriendsOfUser = () => {
  const { amplitude, logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const { userHandle } = useParams<{ userHandle: string }>()
  const { data, loading } = useFriendsOfUserPublicUserQuery({
    variables: { handle: userHandle, firstFriendsNumber: NUMBER_OF_INITIAL_FRIENDS_FETCHED },
  })

  useEffect(() => {
    if (!amplitude) return
    logAmplitudeEventOnce({
      eventName: 'Viewed User Friends Page',
      eventProperties: {},
    })
  }, [amplitude])

  if (!data || loading) {
    return <LoadingPage />
  }

  return <FriendsOfUserComponent user={data.publicUser} />
}

export default FriendsOfUser
