import * as React from 'react'
import { useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Icon from '@eversports/klimt-primitives/Icon'
import Text from '@eversports/klimt-primitives/Text'
import Tooltip from '@eversports/klimt-primitives/Tooltip'
import CopyIcon from '@eversports/design-tokens/assets/icons/copy.svg'

import { Localized } from '../localization/react'

import SharingActionBox from './SharingActionBox'

import { ShareType } from '@eversports/klimt-patterns/SharingActions/SharingActions.types'

interface Props {
  trackClick: (type: ShareType.CopyText) => void
  translatedMessage: string
}

const SharingActionCopyText = ({ trackClick, translatedMessage }: Props) => {
  const [showToolTip, setToolTip] = useState(false)

  const handleCopyLink = () => {
    trackClick(ShareType.CopyText)
    void navigator.clipboard.writeText(translatedMessage).then(() => {
      setToolTip(true)
      setTimeout(() => setToolTip(false), 1500)
    })
  }

  return (
    <Stack alignItems="center" justifyContent="center" gap={2} flexGrow={1} flexShrink={0}>
      <Tooltip title={<Localized id="tooltip-copied-text" />} arrow placement="bottom" open={showToolTip}>
        <SharingActionBox onClick={handleCopyLink}>
          <Icon src={CopyIcon} color="midnight" size="default" />
        </SharingActionBox>
      </Tooltip>
      <Text variant="small" textAlign="center" sx={{ color: 'midnight' }}>
        <Localized id="sharing-actions-copy-text" />
      </Text>
    </Stack>
  )
}

export default SharingActionCopyText
