import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'
import * as React from 'react'
import CircularProgress from '@eversports/klimt-primitives/CircularProgress'

import { Localized } from '../../../localization/react'

interface Props {
  handleClick: () => void
  isLoadingMoreComments: boolean
}

const ViewMoreComments = ({ isLoadingMoreComments, handleClick }: Props) => {
  return isLoadingMoreComments ? (
    <Box sx={{ margin: 'auto' }}>
      <CircularProgress size="30px" />
    </Box>
  ) : (
    <Box
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        border: '1px solid',
        borderColor: 'primary.main',
        boxShadow: boxShadows.default,
        cursor: 'pointer',
        borderRadius: 1,
        padding: 2,
      }}
      onClick={handleClick}
    >
      <Text variant="xsmall" sx={{ color: 'primary.main', textAlign: 'center', textShadow: '0px 1px 0px #FFFFFF' }}>
        <Localized id="comments-section-view-more-comments" />
      </Text>
    </Box>
  )
}

export default ViewMoreComments
