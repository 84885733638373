import * as React from 'react'
import { AnimatePresence } from '@eversports/klimt-utilities/framer-motion'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Search from '../../components/Search'
import useUrlParams from '../../hooks/use-url-params'
import { ShowType } from '../../App.types'

import GoogleMapsGlobalStyles from './GoogleMapsGlobalStyles'
import { DiscoverStateProvider } from './DiscoverContext'
import getDiscoverScreenInitialFilters from './helpers/get-discover-screen-initial-filters'

const DiscoverScreen = ({ children }: React.PropsWithChildren<unknown>) => {
  const initialFilters = getDiscoverScreenInitialFilters()

  const { show } = useUrlParams()
  const showSearchOpen = show === ShowType.SEARCH

  return (
    <>
      <GoogleMapsGlobalStyles />
      <DiscoverStateProvider initialFilters={initialFilters}>
        <Header position={{ xs: 'relative', lg: 'sticky' }}>
          <Search isDefaultOpen={showSearchOpen} />
        </Header>
        <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>
        <Footer />
      </DiscoverStateProvider>
    </>
  )
}

export default DiscoverScreen
