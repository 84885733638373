import * as React from 'react'
import FormattedContent from '@eversports/klimt-primitives/FormattedContent'
import styled from '@eversports/klimt-design-components/styled'
import Stack from '@eversports/klimt-primitives/Stack/Stack'
import Heading from '@eversports/klimt-primitives/Heading/Heading'

import { Localized } from '../../../../localization/react'

const SmallCarbonTextContent = styled('div')`
  font-size: 13px;
  line-height: 19.5px;
  color: ${({ theme }) => theme.palette.carbon};
`

interface Props {
  cancellationCondition: string
}

const CancellationConditions = ({ cancellationCondition }: Props) => (
  <Stack gap={1} px={{ xs: 4, md: 0 }} pb={{ xs: 4, md: 0 }}>
    <Heading is="h2" variant="small" color="carbon">
      <Localized id="activity-detail-cancellation-policy-header" />
    </Heading>
    <FormattedContent htmlContent={cancellationCondition} WrapperComponent={SmallCarbonTextContent} />
  </Stack>
)

export default CancellationConditions
