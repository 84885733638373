import { BoundingBox } from '../../../graphql'
import { LatLng } from '../../../App.types'

interface Result {
  northEast: LatLng
  southWest: LatLng
}

export const getBoundingBoxFromQueryResult = (boundingBoxQueryResult: BoundingBox): Result => {
  const { northEast: northEastWithTypename, southWest: southWestWithTypename } = boundingBoxQueryResult
  const { __typename: neTypename, ...northEast } = northEastWithTypename
  const { __typename: swTypename, ...southWest } = southWestWithTypename

  return {
    northEast,
    southWest,
  }
}
