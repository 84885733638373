import CrossFit from './assets/crossfit.svg'
import Fitness from './assets/fitness.svg'
import Football from './assets/football.svg'
import Padel from './assets/padel.svg'
import Pilates from './assets/pilates.svg'
import Squash from './assets/squash.svg'
import Tennis from './assets/tennis.svg'
import Yoga from './assets/yoga.svg'
import Badminton from './assets/badminton.svg'
import Ballet from './assets/ballet.svg'
import Bootcamp from './assets/bootcamp.svg'
import Bowling from './assets/bowling.svg'
import Boxing from './assets/boxing.svg'
import Dance from './assets/dance.svg'
import Swimming from './assets/swimming.svg'
import PoleDance from './assets/poleDance.svg'
import Volleyball from './assets/volleyball.svg'
import Backfit from './assets/backfit.svg'
import TableTennis from './assets/tableTennis.svg'
import Meditation from './assets/meditation.svg'
import BellyButtThighs from './assets/bbt.svg'
import CircutTraining from './assets/circutTraining.svg'
import Golf from './assets/golf.svg'
import Gymnastics from './assets/gymnastics.svg'
import MartialArts from './assets/martialArts.svg'
import QiGong from './assets/qigong.svg'
import SelfDefense from './assets/selfDefense.svg'
import Stretching from './assets/stretching.svg'
import StripTease from './assets/stripTease.svg'
import FunctionalTraining from './assets/functionalTraining.svg'
import Barre from './assets/barre.svg'

export const SportSlugIconMapping: { [key in string]: string } = {
  crossfit: CrossFit,
  fitness: Fitness,
  fussball: Football,
  padel: Padel,
  pilates: Pilates,
  squash: Squash,
  tennis: Tennis,
  yoga: Yoga,
  badminton: Badminton,
  ballett: Ballet,
  bootcamp: Bootcamp,
  bowling: Bowling,
  boxen: Boxing,
  tanzen: Dance,
  schwimmen: Swimming,
  beachvolleyball: Volleyball,
  volleyball: Volleyball,
  meditation: Meditation,
  rueckenfit: Backfit,
  tischtennis: TableTennis,
  poledance: PoleDance,
  'bauch-beine-po': BellyButtThighs,
  zirkeltraining: CircutTraining,
  golf: Golf,
  gymnastik: Gymnastics,
  kampfsport: MartialArts,
  'qi-gong': QiGong,
  'self-defense': SelfDefense,
  stretching: Stretching,
  striptease: StripTease,
  'funktionelles-training': FunctionalTraining,
  aerobic: Dance,
  zumba: Dance,
  barre: Barre,
}
