import { default as React, useEffect, useState } from 'react'

import init, { AmplitudeClient } from './core/amplitude'
import { AmplitudeContext } from './core/amplitude-context'

interface Props {
  amplitudeProjectApiKey?: string
  trackingStrategy?: TrackingStrategy
}

export type TrackingStrategy = 'dnt-local-storage' | 'always'

function isTrackingEnabled(strategy: 'dnt-local-storage' | 'always') {
  if (strategy === 'always') {
    return true
  }

  return localStorage.getItem('dnt') === '0'
}

const AmplitudeProvider = ({
  amplitudeProjectApiKey,
  children,
  trackingStrategy = 'dnt-local-storage',
}: React.PropsWithChildren<Props>) => {
  const [amplitudeInstance, setAmplitudeInstance] = useState<AmplitudeClient | null>(null)

  useEffect(() => {
    const trackingEnabled = isTrackingEnabled(trackingStrategy)

    if (!amplitudeProjectApiKey) {
      console.debug('Amplitude project API key was not defined. Not initializing Amplitude.')
    }

    if (trackingEnabled && amplitudeProjectApiKey && !amplitudeInstance) {
      const amplitude = init({ amplitudeProjectApiKey })
      setAmplitudeInstance(amplitude)
    }
  }, [])

  return <AmplitudeContext.Provider value={{ amplitude: amplitudeInstance }}>{children}</AmplitudeContext.Provider>
}

export default AmplitudeProvider
