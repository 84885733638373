import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import Link from '@eversports/klimt-primitives/Link'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../../../../localization/react'
import { From } from '../../../../../../App.types'

import FriendsUpcomingParticipationsBlockDate from './FriendsUpcomingParticipationsBlockDate'
import FriendUpcomingParticipationImage from './FriendUpcomingParticipationImage'
import FriendUpcomingParticipationName from './FriendUpcomingParticipationName'
import FriendUpcomingParticipationTimeRange from './FriendUpcomingParticipationTimeRange'
import FriendUpcomingParticipationSport from './FriendUpcomingParticipationSport'
import FriendUpcomingParticipationAddress from './FriendUpcomingParticipationAddress'
import FriendUpcomingParticipationCard from './FriendUpcomingParticipationCard'
import InviteFriendsToBookingsIcon from './assets/invite-friends-to-bookings.svg'
import AddFriendsIcon from './assets/add-friends.svg'
import SampleActivityImage from './assets/sample-activity-image.webp'
import SampleFriendsImage from './assets/sample-friends-image.webp'

interface Props {
  userHandle: string
}

const NoFriendsUpcomingParticipations = ({ userHandle }: Props) => {
  const { amplitude } = useAmplitude()
  const startDate = new Date()
  const endDate = new Date()

  startDate.setHours(15)
  startDate.setMinutes(0)
  endDate.setHours(16)
  endDate.setMinutes(0)

  const handleCTAButtonClick = () => {
    if (!amplitude) return
    amplitude.logEvent('Clicked Friends Upcoming Activities Placeholder CTA Button')
  }

  return (
    <Stack gap={8} padding={4} paddingTop={0}>
      <Stack gap={3} sx={{ transform: 'rotate(-2deg)' }}>
        <FriendsUpcomingParticipationsBlockDate date={startDate.toISOString()} />
        <FriendUpcomingParticipationCard>
          <Stack gap={3}>
            <Stack direction="row" gap={2}>
              <FriendUpcomingParticipationImage src={SampleActivityImage} />
              <Stack gap={2}>
                <FriendUpcomingParticipationName name="Yoga Vinyasa" />
                <FriendUpcomingParticipationTimeRange start={startDate.toISOString()} end={endDate.toISOString()} />
                <FriendUpcomingParticipationSport name="Yoga" />
              </Stack>
            </Stack>
            <FriendUpcomingParticipationAddress
              venueName="Eversports Studio"
              street="Beatifulstraße 123"
              zip="1020"
              city="Wien"
            />
          </Stack>
          <Stack gap={1}>
            <Text variant="badge" sx={{ color: 'gray' }}>
              <Localized id="friends-attending-section-title" />
            </Text>
            <img src={SampleFriendsImage} width="110px" height="40px" />
          </Stack>
        </FriendUpcomingParticipationCard>
      </Stack>
      <Stack textAlign="center">
        <Heading is="h1" variant="large" sx={{ color: 'black' }}>
          <Localized id="no-friends-upcoming-participations-title" />
        </Heading>
        <Text>
          <Localized id="no-friends-upcoming-participations-description" />
        </Text>
      </Stack>
      <Stack gap={4}>
        <Link to="/u" external>
          <Button variant="tertiary" sx={{ padding: 2 }}>
            <Stack direction="row" gap={2} alignItems="center">
              <img src={InviteFriendsToBookingsIcon} width="12px" height="12px" />
              <Text sx={{ color: 'primary.main', fontWeight: 'bold', textTransform: 'none' }}>
                <Localized id="no-friends-upcoming-participations-invite-to-bookings-button" />
              </Text>
            </Stack>
          </Button>
        </Link>
        <Link to={`/user/${userHandle}/friends?from=${From.FRIENDS_UPCOMING_PARTICIPATIONS_PLACEHOLDER}`}>
          <Button variant="primary" sx={{ padding: 2 }} onClick={handleCTAButtonClick}>
            <Stack direction="row" gap={2} alignItems="center">
              <img src={AddFriendsIcon} width="12px" height="12px" />
              <Text sx={{ color: 'white', fontWeight: 'bold', textTransform: 'none' }}>
                <Localized id="no-friends-upcoming-participations-add-friends-button" />
              </Text>
            </Stack>
          </Button>
        </Link>
      </Stack>
    </Stack>
  )
}

export default NoFriendsUpcomingParticipations
