import * as React from 'react'
import { Table, TableRow, TableCell, TableBody, TableHead } from '@eversports/klimt-primitives/Table'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../../localization/react'
import { ScheduleSession } from '../../ActivityDetails.types'

import ScheduleTableRow from './ScheduleTableRow'

interface Props {
  sessions: Array<ScheduleSession>
}

const ScheduleTable = ({ sessions }: Props) => {
  return (
    <Table sx={{ minWidth: 340 }} aria-label="event-schedule-table">
      <TableHead sx={{ '> .MuiTableRow-root': { backgroundColor: 'white' } }}>
        <TableRow>
          <TableCell align="center">
            <Text variant="label" color="carbon">
              <Localized id="activity-detail-schedule-table-date-header" />
            </Text>
          </TableCell>
          <TableCell align="center">
            <Text variant="label" color="carbon">
              <Localized id="activity-detail-schedule-table-time-header" />
            </Text>
          </TableCell>
          <TableCell align="center">
            <Text variant="label" color="carbon">
              <Localized id="activity-detail-schedule-table-location-header" />
            </Text>
          </TableCell>
          <TableCell align="center">
            <Text variant="label" color="carbon">
              <Localized id="activity-detail-schedule-table-teacher-header" />
            </Text>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sessions.map((session) => (
          <ScheduleTableRow key={session.id} session={session} />
        ))}
      </TableBody>
    </Table>
  )
}

export default ScheduleTable
