import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../../../localization/react'
import { PrivacySettingVisibility } from '../../../../App.types'

import { visibilityTypeDescriptionTranslation, visibilityTypeTranslation } from './PrivacySettingButton.constants'

interface Props {
  visibility: PrivacySettingVisibility
}

const VisibilityTypeContent = ({ visibility }: Props) => {
  return (
    <Stack gap={0.5} p={3} width="100%">
      <Text variant="badge">
        <Localized id={visibilityTypeTranslation[visibility]} />
      </Text>
      <Text variant="xsmall">
        <Localized id={visibilityTypeDescriptionTranslation[visibility]} />
      </Text>
    </Stack>
  )
}

export default VisibilityTypeContent
