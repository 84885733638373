import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Animation from '@eversports/klimt-animations/Animation'

import { Localized } from '../localization/react'

import SuccessAnimation from './assets/success-animation.lottie'

const SuccessfullyInvitedFriends = () => {
  return (
    <Stack margin="auto" maxWidth="350px">
      <Animation src={SuccessAnimation} style={{ width: '30%', margin: 'auto' }} autoplay />
      <Text sx={{ color: 'darkgray', textAlign: 'center', fontWeight: 'bold' }}>
        <Localized id="successfully-invited-friends" />
      </Text>
    </Stack>
  )
}

export default SuccessfullyInvitedFriends
