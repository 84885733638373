import * as React from 'react'
import { useEffect } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import Divider from '@eversports/klimt-primitives/Divider'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import { useLocation, matchPath } from 'react-router-dom'

import { DiscoverPrefix, Meta, OnlineVenue, Sport, Venue, ShowType } from '../../../../App.types'
import Listing from '../../Listing'
import Map from '../../Map'
import Filters from '../../Filters'
import { City, DiscoverOfferingType, DiscoverySport, SeoContent, AvailableFiltersType } from '../../Discover.types'
import SeoSection from '../../SeoSection'
import ListingWrapperWithAnimation from '../../Listing/ListingWrapperWithAnimation'
import MapWrapperWithAnimation from '../../Map/MapWrapperWithAnimation'
import { useDiscoverReducer, useDiscoverState } from '../../DiscoverContext'
import DiscoverViewToggle from '../../DiscoverViewToggle'
import Offerings from '../../Offerings'
import MetaHelmet from '../../../../components/MetaHelmet'
import useUrlParams from '../../../../hooks/use-url-params'
import Routes from '../../../../App.routes'

interface Props {
  sport: DiscoverySport
  city: City
  meta: Meta
  availableFilters: AvailableFiltersType
  subSports?: Array<Sport>
  onlineVenues?: Array<OnlineVenue>
  listingResults?: Array<Venue>
  offerings: Array<DiscoverOfferingType> | null
  content: SeoContent
}

const DiscoverVenuesComponent = ({
  meta,
  content,
  sport,
  subSports,
  city,
  onlineVenues,
  availableFilters,
  listingResults,
  offerings,
}: Props) => {
  const { origin } = useSettingsContext()
  const { pathname } = useLocation()
  const { showDesktopListing } = useDiscoverState()
  const dispatch = useDiscoverReducer()

  const { show } = useUrlParams()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const hasOfferings = Boolean(offerings?.length)

  const canonicalHref = city
    ? new URL(`${DiscoverPrefix.SEO_SPORT_CITY}/${sport.slug}/${city.slug}`, origin).href
    : undefined

  const noIndex = Boolean(matchPath(pathname, { path: Routes.APP_DISCOVER_SPORT_CITY, exact: true }))

  useEffect(() => {
    switch (show) {
      case ShowType.FILTERS:
        dispatch({ type: 'TOGGLE_FILTER_VIEW' })
        break
      case ShowType.MAP:
        dispatch({ type: 'TOGGLE_MAP_VIEW' })
        break
      default:
        break
    }
  }, [])

  return (
    <>
      <MetaHelmet meta={meta} canonicalHref={canonicalHref} noIndex={noIndex} />
      <Stack
        sx={{
          backgroundColor: 'white',
          height: '100%',
        }}
        component="main"
      >
        <Offerings sport={sport} offerings={offerings} />
        <Filters
          sport={sport}
          availableSubSports={subSports}
          hasOfferings={hasOfferings}
          availableFilters={availableFilters}
        />
        <Stack direction="row" position="relative">
          <ListingWrapperWithAnimation isListingOpen={showDesktopListing} hasOfferings={hasOfferings}>
            <Listing
              listingResults={listingResults}
              onlineVenues={onlineVenues}
              heading={content?.heading}
              sport={sport}
            />
          </ListingWrapperWithAnimation>
          <MapWrapperWithAnimation isListingOpen={showDesktopListing} hasOfferings={hasOfferings}>
            <Map venues={listingResults} />
          </MapWrapperWithAnimation>
        </Stack>
        {isMobile && <DiscoverViewToggle />}
      </Stack>
      <Divider sx={{ border: '1px solid', borderColor: 'mediumgray' }} />
      <SeoSection content={content} sport={sport} city={city} />
    </>
  )
}

export default React.memo(DiscoverVenuesComponent)
