import * as React from 'react'
import { useFormContext, Controller, ControllerProps } from 'react-hook-form'
import RadioGroupPrimitive, { RadioGroupProps } from '@eversports/klimt-primitives/RadioGroup'

type Props = RadioGroupProps & Omit<ControllerProps, 'render'>

const RadioGroup = ({ children, rules, name, shouldUnregister, defaultValue, ...rest }: Props) => {
  const { control } = useFormContext()
  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <RadioGroupPrimitive {...rest} value={value} onChange={onChange}>
          {children}
        </RadioGroupPrimitive>
      )}
    />
  )
}

export default RadioGroup
