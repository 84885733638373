import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'

interface ZoomIconProps {
  isTop?: boolean
  isBottom?: boolean
  onClick?: () => void
}

const MapIconBadge: React.FC<React.PropsWithChildren<ZoomIconProps>> = ({
  children,
  onClick,
  isTop = false,
  isBottom = false,
}) => (
  <Box
    sx={{
      background: '#FFFFFF',
      borderRadius: isTop ? '8px 8px 0px 0px' : isBottom ? '0px 0px 8px 8px' : '8px',
      borderBottom: isTop ? '1px solid #ddd' : 'none',
      cursor: 'pointer',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
      width: '44px',
      height: '44px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    onClick={onClick}
  >
    {children}
  </Box>
)

export default MapIconBadge
