import * as React from 'react'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import RadioGroup from '@eversports/klimt-forms/RadioGroup'
import { useEffect } from 'react'

import { MatchLevel, MatchMissingField } from '../../../Match.types'
import { Localized } from '../../../../../localization/react'
import { Action } from '../helpers/match-settings-reducer'
import RequiredLevelContent from '../components/RequiredLevelContent'
import ModalOption from '../../../../../components/ModalOption'
import ModalRadioButton from '../../../../../components/ModalRadioButton'

import { RequiredLevelForm } from './MatchSettingsModal.types'
import ResetField from './components/ResetField'

interface Props {
  dispatch: React.Dispatch<Action>
  initialLevel?: MatchLevel | null
  isRequired?: boolean
}

const RequiredLevel = ({ dispatch, initialLevel, isRequired = false }: Props) => {
  const methods = useForm<RequiredLevelForm>({
    defaultValues: { LEVEL: initialLevel },
  })

  const requiredLevel = methods.watch(MatchMissingField.LEVEL)

  useEffect(() => {
    if (!requiredLevel) return
    dispatch({ type: 'UPDATE_MATCH_LEVEL', payload: requiredLevel })
  }, [requiredLevel])

  const resetField = () => {
    methods.reset({ LEVEL: null })
    dispatch({ type: 'UPDATE_MATCH_LEVEL', payload: null })
  }

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={0.5}>
        <Text variant="small" sx={{ fontWeight: 'bold' }}>
          <Localized id="match-rule-level-title" />
        </Text>
        {isRequired && <Text sx={{ color: 'red', fontWeight: 'bold', verticalAlign: 'text-top' }}>*</Text>}
        {requiredLevel && <ResetField onClick={resetField} />}
      </Stack>
      <Form methods={methods}>
        <RadioGroup name={MatchMissingField.LEVEL}>
          <Stack gap={2}>
            {Object.keys(MatchLevel).map((level) => (
              <ModalOption key={level} isSelected={level === (requiredLevel as string | null)}>
                <ModalRadioButton label={<RequiredLevelContent level={level as MatchLevel} />} value={level} />
              </ModalOption>
            ))}
          </Stack>
        </RadioGroup>
      </Form>
    </Stack>
  )
}

export default RequiredLevel
