import * as React from 'react'
import Dialog from '@eversports/klimt-primitives/Dialog'
import { boxShadows } from '@eversports/design-tokens/box-shadows'

import Drawer from '../Drawer'

import PublicProfilePrivacyTermsDisclaimerComponentContent from './PublicProfilePrivacyTermsDisclaimerComponentContent'

interface Props {
  isMobile: boolean
  isLoading: boolean
  onClick: () => void
}

const PublicProfilePrivacyTermsDisclaimerComponent = ({ isMobile, isLoading, onClick }: Props) => {
  return isMobile ? (
    <Drawer open={true}>
      <PublicProfilePrivacyTermsDisclaimerComponentContent onClick={onClick} isLoading={isLoading} />
    </Drawer>
  ) : (
    <Dialog
      open={true}
      sx={{
        '& .MuiDialog-paper': {
          background: 'white',
          border: '1px solid',
          borderColor: 'lightgray',
          borderRadius: 4,
          boxShadow: boxShadows.default,
          minWidth: { lg: '400px' },
          maxWidth: { lg: '680px' },
          padding: 4,
        },
      }}
    >
      <PublicProfilePrivacyTermsDisclaimerComponentContent onClick={onClick} isLoading={isLoading} />
    </Dialog>
  )
}

export default PublicProfilePrivacyTermsDisclaimerComponent
