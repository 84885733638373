import * as React from 'react'

interface Props {
  hash: string
  topOffset: number
}

export const ScrollAnchor = ({ hash, topOffset }: Props) => (
  <span id={hash} style={{ position: 'absolute', top: topOffset }}></span>
)
