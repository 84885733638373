import * as React from 'react'
import Avatar from '@eversports/klimt-primitives/Avatar'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Link from '@eversports/klimt-primitives/Link'
import EversportsLogoIcon from '@eversports/design-tokens/assets/logo/icon.svg'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { From } from '../App.types'
import { fromMapping } from '../App.constants'
import { getFromParam } from '../hooks/use-from-param'

interface Props {
  profilePicture?: string
  firstName: string
  lastName: string
  handle: string
  from: From
}

const UserInfo = ({ profilePicture, firstName, from, lastName, handle }: Props) => {
  const { amplitude } = useAmplitude()
  const mappedFrom = getFromParam(from)

  const handleClick = () => {
    if (!amplitude) return
    amplitude.logEvent('Clicked User Tile', { from: mappedFrom ? fromMapping[mappedFrom] : null })
  }

  return (
    <Link to={`/user/${handle}?from=${from}`}>
      <Stack direction="row" alignItems="center" width="100%" gap={3} onClick={handleClick}>
        <Avatar
          src={profilePicture || EversportsLogoIcon}
          sx={{
            height: '42px',
            width: '42px',
            border: '1px solid',
            borderColor: 'lightgray',
            backgroundColor: 'white',
            borderRadius: 10,
          }}
        />
        <Stack gap={1} sx={{ maxWidth: { xs: '125px', md: 'fit-content' } }}>
          <Text
            sx={{
              color: 'midnight',
              fontWeight: 'bold',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {firstName} {lastName}
          </Text>
          <Text variant="small" sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            @{handle}
          </Text>
        </Stack>
      </Stack>
    </Link>
  )
}

export default UserInfo
