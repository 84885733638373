import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { List, ListItem } from '@eversports/klimt-primitives/List'

import { Localized } from '../../../../localization/react'

const OnlineStreamInfo = () => {
  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center" flexWrap="wrap" columnGap={2} rowGap={1}>
        <Text color="midnight" sx={{ fontWeight: 'bold' }}>
          <Localized id="activity-detail-online-stream-header" />
        </Text>
      </Stack>
      <Text color="midnight">
        <Localized id="activity-detail-online-stream-subtext" />
      </Text>
      <List dense sx={{ listStyleType: 'disc', paddingX: 2, paddingLeft: 10 }}>
        <ListItem sx={{ display: 'list-item' }}>
          <Text color="midnight">
            <Localized id="activity-detail-online-stream-instructions-step-1" />
          </Text>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          <Text color="midnight">
            <Localized id="activity-detail-online-stream-instructions-step-2" />
          </Text>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          <Text color="midnight">
            <Localized id="activity-detail-online-stream-instructions-step-3" />
          </Text>
        </ListItem>
      </List>
    </Stack>
  )
}

export default OnlineStreamInfo
