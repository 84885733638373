import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useEffect, useMemo, useReducer } from 'react'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import getTimeRangeForDate from '../../../../utils/get-time-range-for-date'
import { useDiscoverSportMatchesContextQuery } from '../../../../graphql'
import { toISOStringWithoutSeconds } from '../../../../utils/format-date-time'
import useMatches from '../../../../hooks/useMatches'
import LoadingPage from '../../../../components/LoadingPage'
import { DISCOVER_OFFERING_TYPE_TO_AMPLITUDE_NAME_MAPPING } from '../../Discover.constants'

import DiscoverSportMatchesComponent from './DiscoverSportMatchesComponent'
import discoverMatchesFiltersReducer from './helpers/discover-sport-matches-reducer'
import {
  DEFAULT_NUMBER_OF_MATCHES_FETCHED,
  DEFAULT_RADIUS_OF_GEO_SEARCH_IN_METER,
} from './DiscoverSportMatches.constants'
import getDiscoverySportInitialFilters from './helpers/get-discovery-sport-initial-filters'

const DiscoverSportMatches = () => {
  const { amplitude } = useAmplitude()
  const { sportSlug, citySlug } = useParams<{ sportSlug: string; citySlug: string }>()
  const currentDate = useMemo(() => toISOStringWithoutSeconds(new Date()), [])
  const initialFilters = getDiscoverySportInitialFilters()
  const [appliedFilters, dispatch] = useReducer(discoverMatchesFiltersReducer, initialFilters)

  const { data, loading: contextLoading } = useDiscoverSportMatchesContextQuery({
    variables: {
      sportSlug,
      citySlug,
      first: DEFAULT_NUMBER_OF_MATCHES_FETCHED,
      filters: {
        timeRange: initialFilters.date ? getTimeRangeForDate(new Date(initialFilters.date)) : { start: currentDate },
        venueIds: initialFilters.venues,
        sportIds: initialFilters.sports,
        levels: initialFilters.levels,
        competitionTypes: initialFilters.competitionTypes,
      },
      withSports: !sportSlug,
    },
    nextFetchPolicy: 'standby',
  })

  const initialData = useMemo(
    () => ({
      matches: data?.discoverMatchContext.matches.edges.map(({ node, cursor }) => ({ ...node, cursor })) || [],
      hasMoreResults: data?.discoverMatchContext.matches.pageInfo.hasNextPage,
    }),
    [data],
  )

  const discoverSport = useMemo(() => data?.discoverMatchContext.sport || null, [data])
  const { matches, filterMatches, loadMoreMatches, loadingFilterMatches, loading, loadingMoreMatches, hasMoreResults } =
    useMatches({
      initialData,
    })

  const handleFetchMoreMatches = () => {
    if (!hasMoreResults || !matches || !discoverCoordinate) return

    const sportIds = [...new Set([...(discoverSport?.id ? [discoverSport.id] : []), ...(appliedFilters?.sports || [])])]
    void loadMoreMatches({
      sportIds: sportIds.length ? sportIds : undefined,
      venueIds: appliedFilters.venues?.length === 0 ? undefined : appliedFilters.venues,
      levels: appliedFilters.levels?.length === 0 ? undefined : appliedFilters.levels,
      competitionTypes: appliedFilters.competitionTypes?.length === 0 ? undefined : appliedFilters.competitionTypes,
      timeRange: getTimeRangeForDate(appliedFilters.date) || { start: currentDate },
      geoSearch: {
        coordinate: { latitude: discoverCoordinate.latitude, longitude: discoverCoordinate.longitude },
        radius: DEFAULT_RADIUS_OF_GEO_SEARCH_IN_METER,
      },
    })
  }

  useEffect(() => {
    if (!amplitude || !data) return

    const totalNumberOfResults = data.discoverMatchContext?.matches.edges.length
    const numberOfInitialResults = Math.min(totalNumberOfResults, DEFAULT_NUMBER_OF_MATCHES_FETCHED)
    const tabsAvailable = data.discoverMatchContext.offerings.map(
      (offering) => DISCOVER_OFFERING_TYPE_TO_AMPLITUDE_NAME_MAPPING[offering],
    )

    amplitude.logEvent('Viewed Discovery Sport City', {
      city: citySlug,
      sport: sportSlug,
      tab: 'match',
      hasFilters: true,
      tabsAvailable: ['venue', ...tabsAvailable],
      hasTimeFilter: true,
      numberOfInitialResults,
      category: discoverSport ? discoverSport.category.toLowerCase() : undefined,
    })
  }, [amplitude, data])

  useEffect(() => {
    if (
      (appliedFilters.date === null &&
        appliedFilters.sports === undefined &&
        appliedFilters.venues === undefined &&
        appliedFilters.levels === undefined &&
        appliedFilters.competitionTypes === undefined) ||
      JSON.stringify(appliedFilters) === JSON.stringify(initialFilters) ||
      !discoverCoordinate
    ) {
      return
    }

    const sportIds = [...new Set([...(discoverSport?.id ? [discoverSport.id] : []), ...(appliedFilters?.sports || [])])]
    void filterMatches({
      sportIds: sportIds.length ? sportIds : undefined,
      venueIds: appliedFilters.venues?.length === 0 ? undefined : appliedFilters.venues,
      competitionTypes: appliedFilters.competitionTypes?.length === 0 ? undefined : appliedFilters.competitionTypes,
      levels: appliedFilters.levels?.length === 0 ? undefined : appliedFilters.levels,
      timeRange: getTimeRangeForDate(appliedFilters.date) || { start: currentDate },
      geoSearch: {
        coordinate: { latitude: discoverCoordinate.latitude, longitude: discoverCoordinate.longitude },
        radius: DEFAULT_RADIUS_OF_GEO_SEARCH_IN_METER,
      },
    })
  }, [appliedFilters])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!data || contextLoading || loading) {
    return <LoadingPage />
  }

  const { center: discoverCoordinate, offerings, filters, meta, content, city } = data.discoverMatchContext
  const { sports: sportsForFilter, venues: venuesForFilter } = filters

  if (!meta || !content) {
    throw new Error(
      'Missing the meta or content information for the Match Discover page. Please use a city slug instead of a coordinate to fetch the context',
    )
  }

  return (
    <DiscoverSportMatchesComponent
      content={content}
      meta={meta}
      sportSlug={sportSlug}
      cityName={city?.name}
      citySlug={citySlug}
      sports={sportsForFilter}
      venues={venuesForFilter}
      discoverSport={discoverSport}
      offerings={offerings}
      matches={matches}
      appliedFilters={appliedFilters}
      dispatch={dispatch}
      loadingFilterMatches={loadingFilterMatches}
      loadingMoreMatches={loadingMoreMatches}
      hasMoreResults={hasMoreResults}
      onFetchMoreMatches={handleFetchMoreMatches}
    />
  )
}

export default DiscoverSportMatches
