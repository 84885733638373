type ScreenType = 'desktop' | 'mobile'
type ScreenContentHeight = { [key in ScreenType]: number }

export type ContentType = 'NAVIGATION_BAR' | 'FILTERS' | 'PAGE_TITLE' | 'OFFERINGS' | 'SPORT_PILLS'

const contentHeightsPerScreen: { [key in ContentType]: ScreenContentHeight } = {
  NAVIGATION_BAR: { desktop: 56, mobile: 64 },
  FILTERS: { desktop: 55, mobile: 63 },
  PAGE_TITLE: { desktop: 0, mobile: 37 },
  OFFERINGS: { desktop: 47, mobile: 45 },
  SPORT_PILLS: { desktop: 56, mobile: 56 },
}

interface Result {
  DESKTOP_TOP_CONTENT_HEIGHT: number
  MOBILE_TOP_CONTENT_HEIGHT: number
}

const sumContentHeightsForScreen = (screen: ScreenType, contentTypesArr: Array<ContentType>, defaultHeight: number) => {
  const totalHeight = contentTypesArr.reduce((prevHeight, currContent) => {
    const currContentHeight = contentHeightsPerScreen[currContent]?.[screen] ?? 0
    return prevHeight + currContentHeight
  }, defaultHeight)
  return totalHeight
}

const useGetTopContentHeight = (contentArr: Array<ContentType>): Result => {
  const DESKTOP_TOP_CONTENT_HEIGHT = sumContentHeightsForScreen('desktop', contentArr, 0)
  const MOBILE_TOP_CONTENT_HEIGHT = sumContentHeightsForScreen('mobile', contentArr, 0)

  return { DESKTOP_TOP_CONTENT_HEIGHT, MOBILE_TOP_CONTENT_HEIGHT }
}

export default useGetTopContentHeight
