import * as React from 'react'

import { Trainer, Venue } from '../../VenueProfile.types'

import ActiveTrainerCard from './ActiveTrainerCard'
import DisabledTrainerCard from './DisabledTrainerCard'

export interface Props {
  trainer: Trainer
  venue: Venue | null
}

const TrainerCard = ({ trainer, venue }: Props) => {
  return !venue || !venue.offerings.length ? (
    <DisabledTrainerCard trainer={trainer} />
  ) : (
    <ActiveTrainerCard trainer={trainer} venue={venue} />
  )
}

export default TrainerCard
