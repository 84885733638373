import * as React from 'react'
import KlimtPromotionalTooltip, {
  Props as PromotionalTooltipProps,
} from '@eversports/klimt-patterns/PromotionalTooltip'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'

type Props = Pick<PromotionalTooltipProps, 'content' | 'children' | 'open' | 'onOpen' | 'onClose'>

const PromotionalTooltip = ({ children, content, open, onOpen, onClose }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <KlimtPromotionalTooltip
      placement={isMobile ? 'bottom-end' : 'bottom'}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      componentsProps={{
        tooltip: {
          sx: {
            paddingY: 2,
            paddingX: 4,
            background: 'rgba(20, 20, 20, 0.80)',
            backdropFilter: 'blur(2px)',
            '& .MuiTooltip-arrow': {
              color: 'rgba(20, 20, 20, 0.80)',
            },
          },
        },
      }}
      content={content}
    >
      {children}
    </KlimtPromotionalTooltip>
  )
}

export default PromotionalTooltip
