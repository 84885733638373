import * as React from 'react'
import { useContext } from 'react'

import { AmplitudeClient } from './amplitude'

interface Context {
  amplitude: AmplitudeClient | null
}

export const AmplitudeContext = React.createContext<undefined | Context>(undefined)

export const useAmplitudeContext = () => {
  const context = useContext(AmplitudeContext)

  if (context === undefined) {
    throw new Error('Amplitude context was not correctly defined')
  }

  return context
}
