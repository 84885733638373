import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import StaticDatePicker from '@eversports/klimt-primitives/StaticDatePicker'
import * as React from 'react'

import { Localized } from '../localization/react'

interface Props {
  isSelectedDateToday: boolean
  isSelectedDateTomorrow: boolean
  selectToday: () => void
  selectTomorrow: () => void
  value: Date | null
  maxDate?: Date
  handleChange: (value: Date | null) => void
  isMobile?: boolean
}

const DatePickerContent = ({
  isSelectedDateToday,
  isSelectedDateTomorrow,
  selectToday,
  selectTomorrow,
  value,
  handleChange,
  isMobile,
  maxDate,
}: Props) => {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        gap={4}
        px={6}
        sx={isMobile ? { width: '320px', margin: 'auto', paddingTop: 4 } : {}}
      >
        <Button variant={isSelectedDateToday ? 'secondary' : 'tertiary'} sx={{ flex: 1 }} onClick={selectToday}>
          <Text
            sx={{ textTransform: 'none', fontWeight: 'bold', color: isSelectedDateToday ? 'primary.main' : 'text' }}
          >
            <Localized id="today" />
          </Text>
        </Button>
        <Button variant={isSelectedDateTomorrow ? 'secondary' : 'tertiary'} sx={{ flex: 1 }} onClick={selectTomorrow}>
          <Text
            sx={{ textTransform: 'none', fontWeight: 'bold', color: isSelectedDateTomorrow ? 'primary.main' : 'text' }}
          >
            <Localized id="tomorrow" />
          </Text>
        </Button>
      </Stack>
      <Box
        sx={{
          height: 294,
          '> .MuiPickerStaticWrapper-root': {
            height: '100%',
          },
        }}
      >
        <StaticDatePicker
          value={value}
          onChange={handleChange}
          maxDate={maxDate}
          showDaysOutsideCurrentMonth
          views={['day']}
          disablePast
        />
      </Box>
    </>
  )
}

export default DatePickerContent
