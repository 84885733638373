import * as React from 'react'
import { useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Svg from '@eversports/klimt-primitives/Svg'
import useLogAmplitudeEventOnce from '@eversports/amplitude-react/useLogAmplitudeEventOnce'

import { ParticipatingFriends as ParticipatingFriendsType } from '../../ActivityDetails.types'
import { Localized } from '../../../../localization/react'
import FriendsAttendingIcon from '../assets/friends-attending.svg'

import ParticipatingFriend from './ParticipatingFriend'
import ShowMoreButton from './ShowMoreButton'
import AllParticipatingFriends from './AllParticipatingFriends'
import { NUMBER_OF_PARTICIPATING_FRIENDS_TO_SHOW } from './ParticipatingFriends.constants'

interface Props {
  friends: ParticipatingFriendsType
}

const ParticipatingFriends = ({ friends }: Props) => {
  const { logAmplitudeEventOnce } = useLogAmplitudeEventOnce()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const users = friends.nodes.map((friend) => friend.publicUser)
  const usersToShow = users.slice(0, NUMBER_OF_PARTICIPATING_FRIENDS_TO_SHOW)
  const showMoreButton = users.length > NUMBER_OF_PARTICIPATING_FRIENDS_TO_SHOW

  if (usersToShow.length === 0) {
    return null
  }

  const toggleDialog = () => {
    logAmplitudeEventOnce({
      eventName: 'Viewed Friends Attending',
      eventProperties: { numberOfFriendsAttending: users.length },
    })
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Svg src={FriendsAttendingIcon} width={12} height={10} color="midnight" />
          <Text sx={{ fontWeight: 'bold', color: 'midnight' }}>
            <Localized id="friends-attending-section-title" />
          </Text>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ cursor: 'pointer', width: 'fit-content' }}
          onClick={toggleDialog}
        >
          {usersToShow.map((user, index) => (
            <ParticipatingFriend key={user.id} index={index} friend={user} />
          ))}
          {showMoreButton && <ShowMoreButton />}
        </Stack>
      </Stack>
      <AllParticipatingFriends isOpen={isModalOpen} toggleDialog={toggleDialog} friends={users} />
    </>
  )
}

export default ParticipatingFriends
