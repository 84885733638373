import { MatchCompetitionType, MatchGender, MatchLevel } from '../../../Match.types'

export enum MatchSetting {
  LEVEL = 'LEVEL',
  COMPETITION_TYPE = 'COMPETITION_TYPE',
  GENDER = 'GENDER',
}

interface ClickableMatchSetting {
  onClick?: () => void
  isDisabled?: boolean
}

interface MatchSettingLevel extends ClickableMatchSetting {
  type: MatchSetting.LEVEL
  level?: MatchLevel | null
}

interface MatchSettingCompetitionType extends ClickableMatchSetting {
  type: MatchSetting.COMPETITION_TYPE
  competitionType?: MatchCompetitionType | null
}

interface MatchSettingGender extends ClickableMatchSetting {
  type: MatchSetting.GENDER
  gender?: MatchGender | null
}

export type MatchSettingType = MatchSettingLevel | MatchSettingCompetitionType | MatchSettingGender
