import Stack from '@eversports/klimt-primitives/Stack'
import Link from '@eversports/klimt-primitives/Link'
import * as React from 'react'
import FriendsIcon from '@eversports/design-tokens/assets/icons/friends.svg'
import Svg from '@eversports/klimt-primitives/Svg'

import { ColorContrast } from './Header/Header.types'
import NumberOfPendingFriendRequests from './NumberOfPendingFriendRequests'

interface Props {
  colorContrast?: ColorContrast
  to: string
}

const FriendsConnectionButton = ({ colorContrast = ColorContrast.NORMAL, to }: Props) => {
  return (
    <Link to={to}>
      <Stack
        direction="row"
        alignItems="center"
        position="relative"
        sx={{ display: { xs: 'none', lg: 'flex' }, cursor: 'pointer', flexShrink: 0 }}
        gap={2}
      >
        <Svg
          src={FriendsIcon}
          color={colorContrast === ColorContrast.NORMAL ? 'black' : 'white'}
          width={24}
          height={24}
        />
        <NumberOfPendingFriendRequests colorContrast={colorContrast} variant="dot" />
      </Stack>
    </Link>
  )
}

export default FriendsConnectionButton
