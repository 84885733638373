export const typography = {
  body: {
    xsmall: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
    },
    small: {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '19.5px',
    },
    regular: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '21px',
    },
    large: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
    },
  },
  header: {
    small: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '24px',
    },
    regular: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '27px',
    },
    large: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '36px',
    },
    xlarge: {
      fontSize: 26,
      fontWeight: 700,
      lineHeight: '39px',
    },
  },
  label: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: '19.5px',
  },
  badge: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '18px',
  },
  button: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '21px',
  },
}

export type Typography = typeof typography

export const textVariants = Object.freeze({ badge: typography.badge, label: typography.label, ...typography.body })
export type TextVariants = typeof textVariants
export const headingVariants = Object.freeze({ ...typography.header })
export type HeadingVariants = typeof headingVariants
