import * as React from 'react'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import Processing from '@eversports/klimt-primitives/Processing'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../../../../../localization/react'
import {
  useAddToFriendsButtonRequestFriendshipMutation,
  useAddFriendButtonPublicUserQuery,
  AddFriendButtonPublicUserDocument,
} from '../../../../../../../graphql'
import { fromMapping } from '../../../../../../../App.constants'
import { FromType } from '../../../../../../../App.types'

import { PublicUser } from './SuggestedFriends.types'

interface Props {
  friend: PublicUser
}

const AddSuggestedFriendButton = ({ friend }: Props) => {
  const { amplitude } = useAmplitude()
  const { data } = useAddFriendButtonPublicUserQuery({ variables: { handle: friend.handle } })
  const [addToFriends, { loading }] = useAddToFriendsButtonRequestFriendshipMutation({
    variables: { userId: friend.id },
    refetchQueries: [
      {
        query: AddFriendButtonPublicUserDocument,
        variables: { handle: friend.handle },
      },
    ],
  })

  const from = friend.commonActivitiesCount
    ? FromType.SUGGESTED_FRIENDS_MUTUAL_ACTIVITY
    : FromType.SUGGESTED_FRIENDS_MUTUAL_FRIEND

  const handleClick = () => {
    void addToFriends()
    if (amplitude) {
      amplitude.logEvent('Clicked Add Friend Button', { from: fromMapping[from] })
    }
  }

  const hasRequestedFriendship = Boolean(data?.publicUser?.pendingFriendRequest)

  return (
    <div style={{ marginTop: 'auto' }}>
      <Processing isLoading={loading}>
        <Button
          variant="tertiary"
          sx={{ textTransform: 'none', paddingY: 1, paddingX: 4, width: '100%', borderRadius: 2 }}
          onClick={handleClick}
          disabled={hasRequestedFriendship}
        >
          <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>
            {hasRequestedFriendship ? <Localized id="friendship-requested" /> : <Localized id="add-friend" />}
          </Text>
        </Button>
      </Processing>
    </div>
  )
}

export default AddSuggestedFriendButton
