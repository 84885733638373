import * as React from 'react'
import Dialog, { DialogContent, DialogTitle } from '@eversports/klimt-primitives/Dialog'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Divider from '@eversports/klimt-primitives/Divider'
import Heading from '@eversports/klimt-primitives/Heading'
import Link from '@eversports/klimt-primitives/Link'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'

import { Localized } from '../../localization/react'
import { ManagerLinksMapping } from '../../App.constants'

import EversportsCorporateLogo from './assets/eversportsCorporateLogo.svg'
import CorporateBadgeComponent from './CorporateBadgeComponent'

interface Props {
  isOpen: boolean
  toggleModal: () => void
  citySlug: string
}

const CorporateBadgeModal = ({ isOpen, toggleModal }: Props) => {
  const { tld } = useSettingsContext()
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={toggleModal}
        sx={{
          '& .MuiDialog-paper': {
            background: '#ffffff',
            border: '1px solid #F2F2F2',
            boxSizing: 'border-box',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.12)',
            borderRadius: 4,
            minWidth: { lg: '800px' },
            p: 4,
            pb: 5,
          },
        }}
      >
        <DialogTitle sx={{ p: { md: 6 } }}>
          <Stack spacing={3}>
            <img
              src={EversportsCorporateLogo}
              loading="lazy"
              width="250px"
              height="35px"
              alt="Eversports Corporate logo"
            />
            <CorporateBadgeComponent />
            <Text>
              <Localized id="corporate-deal-modal-subheading" />
            </Text>
          </Stack>
        </DialogTitle>
        <Divider sx={{ mx: 6 }} />
        <DialogContent>
          <Stack gap={7}>
            <Stack gap={2}>
              <Heading is="h3" variant="small">
                <Localized id="what-is-the-corporate-program-question" />
              </Heading>
              <Text>
                <Localized id="what-is-the-corporate-program-answer" />
              </Text>
            </Stack>
            <Stack gap={2}>
              <Heading is="h3" variant="small">
                <Localized id="which-venues-offer-corporate-discounts-question" />
              </Heading>
              <Stack>
                <Text>
                  <Localized id="which-venues-offer-corporate-discounts-answer" />
                </Text>
                <Text>
                  <Localized id="find-corporate-deals-on-venue-profile" />
                </Text>
              </Stack>
            </Stack>
            <Link
              to={ManagerLinksMapping[tld].corporate}
              external
              blank
              ariaLabel="Learn about Eversports' special corporate deals"
            >
              <Text component="span" sx={{ color: 'primary.main', textDecoration: 'underline', fontWeight: 'initial' }}>
                <Localized id="learn-more-about-eversports-corporate" />
              </Text>
            </Link>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CorporateBadgeModal
