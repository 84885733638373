import * as React from 'react'
import Link from '@eversports/klimt-primitives/Link'
import Breadcrumbs from '@eversports/klimt-primitives/Breadcrumbs'
import Box from '@eversports/klimt-primitives/Box'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Icon from '@eversports/klimt-primitives/Icon'
import ChevronLeft from '@eversports/design-tokens/assets/icons/chevron-left.svg'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack'

import { Localized } from '../../../../../localization/react'
import { UserProfileTabsPrefix } from '../../../../../App.types'

const Navigation = () => {
  return (
    <>
      <Breadcrumbs sx={{ display: { xs: 'none', md: 'initial' } }} aria-label="breadcrumb">
        <Link external to={UserProfileTabsPrefix.OVERVIEW}>
          <Text sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            <Localized id="breadcrumb-navigation-item-user-profile" />
          </Text>
        </Link>
        <Text>
          <Localized id="breadcrumb-navigation-item-user-friends" />
        </Text>
      </Breadcrumbs>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        sx={{ display: { xs: 'flex', md: 'none' }, backgroundColor: 'white', paddingX: 4 }}
      >
        <Link to={UserProfileTabsPrefix.OVERVIEW} external>
          <Box
            sx={{
              padding: 2,
              width: 'fit-content',
              height: 'fit-content',
              border: '1px solid',
              borderColor: 'mediumgray',
              borderRadius: 10,
              cursor: 'pointer',
              backgroundColor: 'white',
              boxShadow: boxShadows.default,
              zIndex: 3,
            }}
          >
            <Icon src={ChevronLeft} size="small" color="midnight" />
          </Box>
        </Link>
        <Text sx={{ color: 'midnight' }}>
          <Localized id="back-to-profile" />
        </Text>
      </Stack>
    </>
  )
}

export default Navigation
