import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

import { From } from '../../../../../../../App.types'
import { PublicUser } from '../../../../../PublicUserProfile.types'
import UserInfo from '../../../../../../../components/UserInfo'

import ApproveFriendRequest from './ApproveFriendRequest'
import DeclineFriendRequest from './DeclineFriendRequest'

interface Props {
  friend: PublicUser
}

const ReceivedFriendRequest = ({ friend }: Props) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <UserInfo
        profilePicture={friend.profilePicture?.small}
        firstName={friend.firstName}
        lastName={friend.lastName}
        handle={friend.handle}
        from={From.MY_RECEIVED_FRIEND_REQUESTS}
      />
      <Stack direction="row" alignItems="center" flexShrink={0} gap={2}>
        <ApproveFriendRequest friendId={friend.id} />
        <DeclineFriendRequest friendId={friend.id} />
      </Stack>
    </Stack>
  )
}

export default ReceivedFriendRequest
