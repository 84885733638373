import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { useState } from 'react'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'

import { Localized } from '../../../../../../../localization/react'
import SubSectionCard from '../../../components/SubSectionCard'

import AddressBookIcon from './assets/address-book.svg'
import AppPromo from './AppPromo'

const SyncContacts = () => {
  const { amplitude } = useAmplitude()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen)
  }

  const handleClick = () => {
    toggleDialog()
    if (amplitude) {
      amplitude.logEvent('Clicked Find Contacts Button')
    }
  }

  return (
    <>
      <SubSectionCard onClick={handleClick}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Text sx={{ color: 'midnight', fontWeight: 'bold' }}>
              <Localized id="sync-contacts-title" />
            </Text>
            <Text variant="small">
              <Localized id="sync-contacts-sub-title" />
            </Text>
          </Stack>
          <img src={AddressBookIcon} width="32px" height="32px" />
        </Stack>
      </SubSectionCard>
      <AppPromo isOpen={isDialogOpen} toggleDialog={toggleDialog} />
    </>
  )
}

export default SyncContacts
