import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Animation from '@eversports/klimt-animations/Animation'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'
import { useEffect, useState } from 'react'
import Link from '@eversports/klimt-primitives/Link'

import { Localized } from '../../../../localization/react'
import SuccessActionAnimation from '../../assets/success-action-animation.lottie'
import Drawer from '../../../../components/Drawer'

interface Props {
  isMobile: boolean
  userJoinedMatch: boolean
}

const JoinedMatchDisclaimer = ({ isMobile, userJoinedMatch }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  useEffect(() => {
    if (userJoinedMatch) {
      setIsDialogOpen(true)
    }
  }, [userJoinedMatch])

  return isMobile ? (
    <Drawer open={isDialogOpen} onClose={closeDialog}>
      <JoinedMatchDisclaimerContent onClick={closeDialog} />
    </Drawer>
  ) : (
    <Dialog open={isDialogOpen} onClose={closeDialog} maxWidth="xs">
      <DialogContent>
        <JoinedMatchDisclaimerContent onClick={closeDialog} />
      </DialogContent>
    </Dialog>
  )
}

const JoinedMatchDisclaimerContent = ({ onClick }: { onClick: () => void }) => {
  return (
    <Stack gap={3} alignItems="center">
      <Animation src={SuccessActionAnimation} style={{ width: '160px', height: '160px' }} loop autoplay />
      <Stack gap={1} alignItems="center">
        <Text sx={{ fontWeight: 'bold' }}>
          <Localized id="joined-match-disclaimer-title" />
        </Text>
        <Text sx={{ color: 'darkgray', textAlign: 'center' }}>
          <Localized
            id="joined-match-disclaimer-description"
            params={{
              bookingsLink: (
                <Link to="/u" external>
                  <Text component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
                    <Localized id="joined-match-disclaimer-bookings-link-text" />
                  </Text>
                </Link>
              ),
            }}
          />
        </Text>
      </Stack>
      <Button variant="primary" sx={{ p: 3 }} onClick={onClick} fullWidth>
        <Text sx={{ color: 'white', fontWeight: 'bold', textShadow: boxShadows.default, textTransform: 'none' }}>
          <Localized id="joined-match-disclaimer-button" />
        </Text>
      </Button>
    </Stack>
  )
}

export default JoinedMatchDisclaimer
