import * as React from 'react'
import { useMemo, useEffect } from 'react'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'
import Heading from '@eversports/klimt-primitives/Heading'

import ContentWrapper from '../../components/ContentWrapper'
import { Localized } from '../../../../localization/react'
import { EventRegistrationType, PurchaseOption } from '../../ActivityDetails.types'
import useLoggedInUser from '../../../../hooks/useLoggedInUser'

import PriceCards from './PriceCards'
import PriceTable from './PriceTable'
import groupAndSortPurchaseOptions from './helpers/group-and-sort-purchase-options'

interface Props {
  purchaseOptions: Array<PurchaseOption>
  registrationType: EventRegistrationType
}

const Products = ({ purchaseOptions, registrationType }: Props) => {
  const { getLoggedInUser, user } = useLoggedInUser()
  const isCorporateUser = Boolean(user.data?.corporateBenefits)

  useEffect(() => {
    getLoggedInUser()
  }, [])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (!purchaseOptions.length) return null

  const isTicketedEvent = registrationType === EventRegistrationType.TICKETS
  const sortedProducts = useMemo(() => groupAndSortPurchaseOptions(purchaseOptions), [purchaseOptions])

  return (
    <ContentWrapper>
      <Heading is="h2" variant="small" color="midnight">
        {isTicketedEvent ? (
          <Localized id="activity-detail-ticket-options-heading" />
        ) : (
          <Localized id="activity-detail-pricing-options-heading" />
        )}
      </Heading>
      {isMobile ? (
        <PriceCards purchaseOptions={sortedProducts} isCorporateUser={isCorporateUser} />
      ) : (
        <PriceTable purchaseOptions={sortedProducts} isCorporateUser={isCorporateUser} />
      )}
    </ContentWrapper>
  )
}

export default Products
