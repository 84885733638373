import * as React from 'react'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'

import Drawer from '../../../../../../../components/Drawer'

import AppPromoContent from './AppPromoContent'

interface Props {
  isOpen: boolean
  toggleDialog: () => void
}

const AppPromo = ({ isOpen, toggleDialog }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return isMobile ? (
    <Drawer open={isOpen} onClose={toggleDialog}>
      <AppPromoContent />
    </Drawer>
  ) : (
    <Dialog open={isOpen} onClose={toggleDialog} maxWidth="xs">
      <DialogContent>
        <AppPromoContent />
      </DialogContent>
    </Dialog>
  )
}

export default AppPromo
