import * as React from 'react'
import { useParams } from 'react-router-dom'

import { useDiscoverReducer, useDiscoverState } from '../../DiscoverContext'
import { OnlineVenue, Venue, VenueOfferingType } from '../../../../App.types'
import getLinkToVenueFirstOffering from '../../../../helpers/get-link-to-venue-first-offering'
import { DiscoverySport, SportCategory } from '../../Discover.types'
import { formatDateAsQueryVariable } from '../../../../utils/format-date-time'
import getLinkToSportsFieldsCalendar from '../../../../helpers/get-link-to-sports-fields-calendar'

import ListingResultComponent from './ListingResultComponent'

interface ListingResultProps {
  venue: Venue | OnlineVenue
  sport?: DiscoverySport
  imageLoadingStrategy: 'lazy' | 'eager'
}

const ListingResult = ({ venue, sport, imageLoadingStrategy }: ListingResultProps) => {
  const dispatch = useDiscoverReducer()
  const { sportSlug } = useParams<{ sportSlug: string }>()
  const { venueCourtSlotDateActiveFilter } = useDiscoverState()
  const courtSlotDateAsString = formatDateAsQueryVariable(venueCourtSlotDateActiveFilter)
  const hasSportsFieldCalendar =
    sport?.category !== SportCategory.FITNESS && venue.offerings[0] === VenueOfferingType.VENUE_OFFERING_SPORTS_FIELDS

  const to = hasSportsFieldCalendar
    ? getLinkToSportsFieldsCalendar({
        sportSlug,
        venueSlug: venue.slug,
        selectedDate: courtSlotDateAsString,
        hasAvailableSlots: Boolean(venue?.slotsToDiscover?.length),
      })
    : getLinkToVenueFirstOffering({
        venueSlug: venue.slug,
        firstVenueOffering: venue.offerings[0],
      })

  const handleMouseEnter = () => {
    dispatch({ type: 'SET_VENUE_HOVERED', payload: venue.id })
    dispatch({ type: 'SET_CLICKED_VENUE_ID', payload: undefined })
  }

  const handleMouseLeave = () => {
    dispatch({ type: 'SET_VENUE_HOVERED', payload: undefined })
  }

  return (
    <ListingResultComponent
      venue={venue}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      imageLoadingStrategy={imageLoadingStrategy}
      sport={sport}
      to={to}
    />
  )
}

export default React.memo(ListingResult)
