import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../../localization/react'

import RedHeartImage from './assets/red-heart.webp'

const NPSBannerThankYou = () => {
  return (
    <>
      <img src={RedHeartImage} width="36px" height="36px" />
      <Text variant="large" sx={{ fontWeight: 'bold' }}>
        <Localized id="nps-score-thank-you-message" />
      </Text>
    </>
  )
}

export default NPSBannerThankYou
