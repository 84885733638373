import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import { useParams } from 'react-router-dom'
import Box from '@eversports/klimt-primitives/Box'

import Card from '../components/Card'
import { useParticipationSectionMatchQuery } from '../../../graphql'
import { VALID_BALLSPORT_MATCH_STATES } from '../Match.constants'

import OrganizerNotes from './OrganizerNotes'
import Disclaimer from './Disclaimer'
import Participants from './Participants'
import CallToAction from './CallToAction'

const ParticipationSection = () => {
  const theme = useTheme()
  const { id } = useParams<{ id: string }>()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { data } = useParticipationSectionMatchQuery({ variables: { matchId: id } })

  if (!data) {
    return null
  }

  if (VALID_BALLSPORT_MATCH_STATES.includes(data.match.state)) {
    return (
      <Card sx={{ p: 4 }}>
        <Stack gap={5}>
          {!isMobile ? (
            <Stack direction="row">
              <Stack gap={5} flex={3}>
                <Disclaimer />
                <OrganizerNotes />
              </Stack>
              <Box sx={{ alignSelf: 'self-start', marginLeft: 3, flex: 2, display: { xs: 'none', sm: 'initial' } }}>
                <CallToAction />
              </Box>
            </Stack>
          ) : (
            <OrganizerNotes />
          )}
          <Participants />
        </Stack>
      </Card>
    )
  }

  return null
}

export default ParticipationSection
