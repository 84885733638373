import { useState, useEffect } from 'react'

import { CityLocalStorage, CitySearchResult } from '../Search.types'

const formatParsedLocation = (parsedValue: CityLocalStorage): CitySearchResult => {
  const { id, name, country, slug, lat, lng } = parsedValue
  return {
    id,
    name,
    country,
    slug,
    coordinate: {
      latitude: lat,
      longitude: lng,
    },
    matchedName: '',
    secondaryMatch: null,
    isRecent: true,
    __typename: 'CitySearchResult',
  }
}

const formatForLocalStorage = (location: CitySearchResult): CityLocalStorage => {
  const { id, country, name, slug, coordinate } = location
  return {
    id,
    country,
    lat: coordinate.latitude,
    lng: coordinate.longitude,
    name,
    slug,
  }
}

const LAST_CITY_SEARCH_KEY = 'lastCitySearch'

function useLastCitySearch() {
  const [location, setLocation] = useState<CitySearchResult>()

  const getLastCitySearch = () => {
    const storedValue = window.localStorage.getItem(LAST_CITY_SEARCH_KEY)
    if (!storedValue) return
    const parsedValue = JSON.parse(storedValue) as CityLocalStorage
    return formatParsedLocation(parsedValue)
  }

  const setLastCitySearch = (location: CitySearchResult) => {
    const cityForLocalStorage = formatForLocalStorage(location)
    window.localStorage.setItem(LAST_CITY_SEARCH_KEY, JSON.stringify(cityForLocalStorage))
    setLocation(location)
  }

  // Check local storage for last city searched
  useEffect(() => {
    const storedLocation = getLastCitySearch()
    if (storedLocation) {
      setLocation(storedLocation)
    }
  }, [])

  // Update local storage when user changes their city
  useEffect(() => {
    if (location) {
      setLastCitySearch(location)
    }
  }, [location])

  return {
    location,
    setLocation,
  }
}

export default useLastCitySearch
