import { MatchesFiltersState } from '../Matches.types'

interface UpdateMatchesSportsFilter {
  type: 'UPDATE_MATCHES_SPORTS_FILTER'
  payload?: Array<string>
}

interface UpdateMatchesDateFilter {
  type: 'UPDATE_MATCHES_DATE_FILTER'
  payload: Date
}

interface ResetMatchesFilters {
  type: 'RESET_MATCHES_FILTERS'
}

export type Action = UpdateMatchesSportsFilter | UpdateMatchesDateFilter | ResetMatchesFilters

const matchesFiltersReducer = (state: MatchesFiltersState, action: Action): MatchesFiltersState => {
  switch (action.type) {
    case 'UPDATE_MATCHES_SPORTS_FILTER':
      return { ...state, sports: action.payload }
    case 'UPDATE_MATCHES_DATE_FILTER':
      return { ...state, date: action.payload }
    case 'RESET_MATCHES_FILTERS':
      return { sports: [], date: null }
    default:
      return state
  }
}

export default matchesFiltersReducer
