import { AvatarProps, default as MuiAvatar } from '@mui/material/Avatar'
import * as React from 'react'
import AvatarIcon from '@eversports/design-tokens/assets/icons/avatar.svg'

export const Avatar: React.FC<React.PropsWithChildren<AvatarProps>> = ({ src, sx, ...props }) => {
  if (props.children && !src) {
    return (
      <MuiAvatar
        {...props}
        sx={{
          backgroundColor: 'mediumgray',
          width: 24,
          height: 24,
          textTransform: 'uppercase',
          padding: src ? 0 : 1,
          '> img': {
            objectFit: src ? 'cover' : 'contain',
          },
          ...sx,
        }}
      >
        {props.children}
      </MuiAvatar>
    )
  }

  return (
    <MuiAvatar
      {...props}
      src={src || AvatarIcon}
      sx={{
        backgroundColor: 'mediumgray',
        width: 24,
        height: 24,
        textTransform: 'uppercase',
        padding: src ? 0 : 1,
        '> img': {
          objectFit: src ? 'cover' : 'contain',
        },
        ...sx,
      }}
    />
  )
}

export default Avatar
