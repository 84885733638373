import { default as React, useEffect, useState } from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import Divider from '@eversports/klimt-primitives/Divider'
import Button from '@eversports/klimt-primitives/Button'
import Text from '@eversports/klimt-primitives/Text'
import Heading from '@eversports/klimt-primitives/Heading'

import { useDiscoverState } from '../DiscoverContext'
import { Venue, OnlineVenue } from '../../../App.types'
import { Localized } from '../../../localization/react'
import { NUMBER_OF_LISTING_RESULTS_TO_LOAD } from '../Discover.constants'
import { DiscoverySport } from '../Discover.types'
import LoadingDots from '../../../components/LoadingDots'

import ListingResult from './ListingResult'
import EmptyListingResult from './EmptyListingResult'
import ListingResultsCount from './ListingResultsCount'
import SkeletonListingResult from './ListingResult/SkeletonListingResult'
import OnlineVenuesSection from './OnlineVenuesSection'

interface Props {
  listingResults?: Array<Venue>
  onlineVenues?: Array<OnlineVenue>
  heading?: string
  sport?: DiscoverySport
}

const Listing = ({ listingResults, onlineVenues, heading, sport }: Props) => {
  const { showMobileMapView } = useDiscoverState()

  const [isFakeLoading, setIsFakeLoading] = useState(false)
  const [numberOfResultsToShow, setNumberOfResultsShown] = useState(NUMBER_OF_LISTING_RESULTS_TO_LOAD)

  const resultsToShow = listingResults?.slice(0, numberOfResultsToShow) ?? []

  const hasNoResults = resultsToShow.length === 0
  const hasMoreResults = listingResults && listingResults.length > resultsToShow.length

  const loadMoreResults = () => {
    if (!hasMoreResults || isFakeLoading) return
    setIsFakeLoading(true)
  }

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined = undefined

    if (isFakeLoading) {
      timeoutId = setTimeout(() => {
        setNumberOfResultsShown((curr) => curr + NUMBER_OF_LISTING_RESULTS_TO_LOAD)
        setIsFakeLoading(false)
      }, 1000)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isFakeLoading])

  // Reset the count if the results change
  useEffect(() => {
    setNumberOfResultsShown(NUMBER_OF_LISTING_RESULTS_TO_LOAD)
  }, [listingResults])

  return (
    <Box
      sx={{
        flex: 1,
        overflowY: 'scroll',
        height: 'inherit',
        display: { xs: showMobileMapView ? 'none' : 'initial', lg: 'inherit' },
        backgroundColor: { xs: 'eggshell', lg: 'limelite' },
      }}
    >
      <Stack spacing={4} sx={{ padding: { xs: 3, lg: '16px 16px 8px 32px' } }}>
        {heading && (
          <Heading is="h1" variant="large">
            {heading}
          </Heading>
        )}
        <ListingResultsCount count={listingResults?.length} />
        {!listingResults ? (
          <SkeletonListingResult numberOfCopies={5} />
        ) : (
          <>
            <Stack spacing={4} marginBottom={8}>
              <Stack component="ul" paddingLeft={0} gap={[2, 4]}>
                {resultsToShow.map((venue, index) => (
                  <ListingResult
                    venue={venue}
                    sport={sport}
                    key={venue.id}
                    imageLoadingStrategy={index < 3 ? 'eager' : 'lazy'}
                  />
                ))}
              </Stack>
              {hasMoreResults && (
                <Box textAlign="center">
                  <Button
                    variant="primary"
                    onClick={loadMoreResults}
                    sx={{
                      p: 3,
                      width: { xs: '100%', sm: '70%' },
                      maxWidth: { sm: '500px' },
                      alignSelf: 'center',
                    }}
                  >
                    {isFakeLoading ? (
                      <LoadingDots />
                    ) : (
                      <Text variant="large" color="white" sx={{ fontWeight: 'bold', textTransform: 'none' }}>
                        <Localized id="load-more" />
                      </Text>
                    )}
                  </Button>
                </Box>
              )}
            </Stack>
          </>
        )}
        {hasNoResults && <EmptyListingResult />}
        {(hasNoResults || !hasMoreResults) && onlineVenues && onlineVenues.length > 0 && (
          <>
            <Divider />
            <OnlineVenuesSection onlineVenues={onlineVenues} sport={sport} />
          </>
        )}
      </Stack>
    </Box>
  )
}

export default React.memo(Listing)
