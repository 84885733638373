import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import Link from '@eversports/klimt-primitives/Link'

import { Localized } from '../../localization/react'

interface Props {
  linkUrl: string
}

const RecommendationsViewAllButton = ({ linkUrl }: Props) => (
  <Link to={linkUrl}>
    <Button variant="tertiary" sx={{ px: 6, py: 1.5, width: { xs: '100%', md: 'fit-content' } }}>
      <Text sx={{ fontWeight: 'bold', textTransform: 'none' }}>
        <Localized id="view-all" />
      </Text>
    </Button>
  </Link>
)

export default RecommendationsViewAllButton
