import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import NoSsr from '@eversports/klimt-design-components/NoSsr'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Link from '@eversports/klimt-primitives/Link'
import Text from '@eversports/klimt-primitives/Text'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import FacebookIcon from '@eversports/design-tokens/assets/icons/facebook.svg'
import YoutubeIcon from '@eversports/design-tokens/assets/icons/youtube.svg'
import InstagramIcon from '@eversports/design-tokens/assets/icons/instagram.svg'
import Icon from '@eversports/klimt-primitives/Icon'

import StoreBadge from '../StoreBadge'
import { Localized } from '../../localization/react'

import { BLOG_LINK, FooterLinksMapping } from './footer-links-mapping'
import CookieBanner from './CookieBanner/CookieBanner'

const StaticFooterComponent = () => {
  const { tld } = useSettingsContext()
  const footerLinks = FooterLinksMapping[tld]

  return (
    <>
      <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
        <Stack direction="row" flex="1">
          <Stack gap={2} flex="1" sx={{ margin: '24px 16px' }}>
            <Heading is="h2" variant="large" sx={{ color: 'white' }}>
              <Localized id="footer-header-more-of-eversports" />
            </Heading>
            <Link to={footerLinks.about} external blank>
              <Text sx={{ color: 'lightgray' }}>
                <Localized id="footer-about-us" />
              </Text>
            </Link>
            <Link to={BLOG_LINK} external blank>
              <Text sx={{ color: 'lightgray' }}>
                <Localized id="footer-blog" />
              </Text>
            </Link>
            <Link to={footerLinks.career} external blank>
              <Text sx={{ color: 'lightgray' }}>
                <Localized id="footer-career" />
              </Text>
            </Link>
            <Link to="/h/security" external blank>
              <Text sx={{ color: 'lightgray' }}>
                <Localized id="footer-security" />
              </Text>
            </Link>
            <Link to={footerLinks.corporate} external blank>
              <Text sx={{ color: 'lightgray' }}>
                <Localized id="footer-for-corporates" />
              </Text>
            </Link>
          </Stack>
          <Stack gap={2} flex="1" sx={{ margin: '24px 16px' }}>
            <Heading is="h2" variant="large" sx={{ color: 'white' }}>
              <Localized id="footer-header-eversports-manager" />
            </Heading>
            <Link to={footerLinks.website} external blank>
              <Text sx={{ color: 'lightgray' }}>
                <Localized id="footer-visit-the-website" />
              </Text>
            </Link>
            <Link to={footerLinks.pricing} external blank>
              <Text sx={{ color: 'lightgray' }}>
                <Localized id="footer-pricing" />
              </Text>
            </Link>
            <Link to={footerLinks.demo} external blank>
              <Text sx={{ color: 'lightgray' }}>
                <Localized id="footer-demo" />
              </Text>
            </Link>
          </Stack>
        </Stack>
        <Stack direction="row" flex="1">
          <Stack gap={2} flex="1" sx={{ margin: '24px 16px' }}>
            <Heading is="h2" variant="large" sx={{ color: 'white' }}>
              <Localized id="footer-header-contact" />
            </Heading>
            <Link to="/h/contact" external blank>
              <Text sx={{ color: 'lightgray' }}>
                <Localized id="footer-contact-us" />
              </Text>
            </Link>
            <Link to="/h/about" external blank>
              <Text sx={{ color: 'lightgray' }}>
                <Localized id="footer-impressum" />
              </Text>
            </Link>
            <Link to="/h/privacy" external blank>
              <Text sx={{ color: 'lightgray' }}>
                <Localized id="footer-data-protection" />
              </Text>
            </Link>
            <Link to="/h/terms" external blank>
              <Text sx={{ color: 'lightgray' }}>
                <Localized id="footer-terms-and-conditions" />
              </Text>
            </Link>
            <NoSsr defer>
              <CookieBanner />
            </NoSsr>
            <Link to="/h/cancel-membership" external blank>
              <Text sx={{ color: 'lightgray' }}>
                <Localized id="footer-cancel-membership" />
              </Text>
            </Link>
          </Stack>
          <Stack gap={4} flex="1" sx={{ margin: '24px 16px' }}>
            <Stack gap={2}>
              <Heading is="h2" variant="large" sx={{ color: 'white' }}>
                <Localized id="footer-header-social-media" />
              </Heading>
              <Stack direction="row" gap={4}>
                <Link to={footerLinks.facebook} external blank ariaLabel="Eversports Facebook">
                  <Box sx={{ padding: '10px', borderRadius: '8px', backgroundColor: '#333333' }}>
                    <Icon src={FacebookIcon} color="mediumgray" size="default" />
                  </Box>
                </Link>
                <Link to={footerLinks.youtube} external blank ariaLabel="Eversports Manager Youtube">
                  <Box sx={{ padding: '10px', borderRadius: '8px', backgroundColor: '#333333' }}>
                    <Icon src={YoutubeIcon} color="mediumgray" size="default" />
                  </Box>
                </Link>
                <Link to={footerLinks.instagram} external blank ariaLabel="Eversports Instagram">
                  <Box sx={{ padding: '10px', borderRadius: '8px', backgroundColor: '#333333' }}>
                    <Icon src={InstagramIcon} color="mediumgray" size="default" />
                  </Box>
                </Link>
              </Stack>
            </Stack>
            <Stack gap={2} marginTop={2}>
              <StoreBadge type="app-store" height="44px" width="164px" />
              <StoreBadge type="play-store" height="44px" width="164px" />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default StaticFooterComponent
