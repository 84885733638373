import styled from '@eversports/klimt-design-components/styled'

const HighlightedResult = styled('div')`
  text-align: left;
  font-size: 16px;
  em {
    background: rgba(33, 166, 150, 0.1);
    color: #141414;
    font-style: normal;
    font-size: 16px;
  }
`

export default HighlightedResult
