import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Button from '@eversports/klimt-primitives/Button'
import Checkbox from '@eversports/klimt-forms/Checkbox'
import Form, { useForm } from '@eversports/klimt-forms/Form'
import Processing from '@eversports/klimt-primitives/Processing'

import {
  PrivacySettingField,
  ProfileVisibilityContentMeDocument,
  useProfileVisibilityContentMeQuery,
  useProfileVisibilityContentUpdatePrivacySettingsMutation,
} from '../../../../../../graphql'
import { Localized } from '../../../../../../localization/react'
import { PrivacySettingVisibility } from '../../../../../../App.types'

interface Props {
  onClose: () => void
}

interface InitialValues {
  visibility: Array<string>
}

const ProfileVisibilityContent = ({ onClose }: Props) => {
  const { data } = useProfileVisibilityContentMeQuery()
  const [updateProfileVisibility, { loading }] = useProfileVisibilityContentUpdatePrivacySettingsMutation({
    onCompleted: onClose,
    refetchQueries: [ProfileVisibilityContentMeDocument],
  })

  const profileSetting = data?.me?.privacySettings.find((setting) => setting.field === PrivacySettingField.PROFILE)

  const methods = useForm<InitialValues>({
    defaultValues: { visibility: profileSetting ? [profileSetting.visibility] : [] },
  })

  const visibility = methods.watch('visibility')

  const handleClick = () => {
    const visibilityToUpdate = visibility.includes(PrivacySettingVisibility.PRIVATE)
      ? PrivacySettingVisibility.PRIVATE
      : PrivacySettingVisibility.PUBLIC

    void updateProfileVisibility({
      variables: { args: { fieldInputs: [{ field: PrivacySettingField.PROFILE, visibility: visibilityToUpdate }] } },
    })
  }

  return (
    <Processing isLoading={loading}>
      <Stack gap={4}>
        <Stack gap={2} sx={{ textAlign: 'center' }}>
          <Text variant="large" sx={{ fontWeight: 'bold', color: 'black' }}>
            <Localized id="profile-privacy-settings-title" />
          </Text>
          <Text variant="small" sx={{ color: 'darkgray' }}>
            <Localized id="profile-privacy-settings-description" />
          </Text>
        </Stack>
        <Stack gap={1}>
          <Form methods={methods}>
            <Checkbox
              name="visibility"
              value={PrivacySettingVisibility.PRIVATE}
              label={
                <Text sx={{ fontWeight: 'bold', color: 'midnight' }} component="span">
                  <Localized id="profile-privacy-hide-profile-text" />
                </Text>
              }
            />
          </Form>
          <Text variant="small" sx={{ color: 'darkgray' }}>
            <Localized id="profile-privacy-hide-profile-description" />
          </Text>
        </Stack>
        <Stack direction="row" gap={4} width="100%">
          <Button variant="tertiary" sx={{ textTransform: 'none', flex: 1, padding: 3 }} onClick={onClose}>
            <Text sx={{ fontWeight: 'bold', color: 'midnight' }}>
              <Localized id="profile-privacy-dismiss-button" />
            </Text>
          </Button>
          <Button variant="primary" sx={{ textTransform: 'none', flex: 2, padding: 3 }} onClick={handleClick}>
            <Text sx={{ fontWeight: 'bold', color: 'white' }}>
              <Localized id="profile-privacy-apply-button" />
            </Text>
          </Button>
        </Stack>
      </Stack>
    </Processing>
  )
}

export default ProfileVisibilityContent
