import { AmplitudeClient } from '@eversports/amplitude-react/core/amplitude'

import { CitySearchResult, LocationSearchResult } from '../../Search.types'

const AMPLITUDE_LOCATION_SEARCH_EVENT_NAME = 'Used City Search'

type ResultType = 'city' | 'currentLocation' | null

interface Fields {
  resultTypeClicked: ResultType
  searchTerm: string
  where: string
}

interface LocationFields extends Fields {
  isRecent?: boolean
  city: {
    name: string
    slug: string
  }
}

interface ResultProps {
  searchTerm: string
  amplitude: AmplitudeClient
}

const trackCityResult = ({ result, searchTerm, amplitude }: ResultProps & { result: CitySearchResult }) => {
  const fields: LocationFields = {
    resultTypeClicked: 'city',
    searchTerm,
    isRecent: result?.isRecent,
    city: {
      name: result.name,
      slug: result.slug,
    },
    where: window.location.pathname,
  }
  amplitude.logEvent(AMPLITUDE_LOCATION_SEARCH_EVENT_NAME, fields)
}

const trackCurrentLocationResult = ({ searchTerm, amplitude }: ResultProps) => {
  const fields: Fields = {
    resultTypeClicked: 'currentLocation',
    searchTerm,
    where: window.location.pathname,
  }
  amplitude.logEvent(AMPLITUDE_LOCATION_SEARCH_EVENT_NAME, fields)
}

interface Props<T> {
  result: T
  searchTerm: string
  amplitude: AmplitudeClient | null
}

export const trackLocationSearch = ({ result, searchTerm, amplitude }: Props<LocationSearchResult>) => {
  if (!amplitude) return

  switch (result.__typename) {
    case 'CitySearchResult':
      return trackCityResult({ result, searchTerm, amplitude })
    case 'currentLocation':
      return trackCurrentLocationResult({ searchTerm, amplitude })
  }
}
