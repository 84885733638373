import { isSupportedTLD } from './tld-configs'
import { SettingsContext } from './SettingsContext'

export const createOrigin = ({ protocol, host }: { protocol: string; host: string }) => `${protocol}//${host}`

export const getOriginForCountry = ({ host, origin, protocol }: SettingsContext, country: string) => {
  const countryCode = country.toLowerCase()
  const tld = isSupportedTLD(countryCode) ? countryCode : 'at'

  if (host.startsWith('www.')) {
    return createOrigin({
      host: `www.eversports.${tld}`,
      protocol,
    })
  }
  if (host.startsWith('www-test')) {
    return createOrigin({
      host: `www-test-${tld}.eversports.dev`,
      protocol,
    })
  }
  if (host.startsWith('www-dev')) {
    return createOrigin({
      host: `www-dev-${tld}.eversports.local`,
      protocol,
    })
  }
  return origin
}

interface HostConfig {
  hostname: string
  countryCode: string
}

const hostConfigMap: Array<HostConfig> = [
  { hostname: 'www-dev-at.eversports.local', countryCode: 'AT' },
  { hostname: 'www-dev-de.eversports.local', countryCode: 'DE' },
  { hostname: 'www-dev-ch.eversports.local', countryCode: 'CH' },
  { hostname: 'www-dev-nl.eversports.local', countryCode: 'NL' },
  { hostname: 'www-dev-fr.eversports.local', countryCode: 'FR' },
  { hostname: 'www-dev-es.eversports.local', countryCode: 'ES' },
  { hostname: 'www-dev-it.eversports.local', countryCode: 'IT' },
  { hostname: 'www-dev-be.eversports.local', countryCode: 'BE' },
  { hostname: 'www-test-at.eversports.dev', countryCode: 'AT' },
  { hostname: 'www-test-de.eversports.dev', countryCode: 'DE' },
  { hostname: 'www-test-ch.eversports.dev', countryCode: 'CH' },
  { hostname: 'www-test-nl.eversports.dev', countryCode: 'NL' },
  { hostname: 'www-test-fr.eversports.dev', countryCode: 'FR' },
  { hostname: 'www-test-es.eversports.dev', countryCode: 'ES' },
  { hostname: 'www-test-it.eversports.dev', countryCode: 'IT' },
  { hostname: 'www-test-be.eversports.dev', countryCode: 'BE' },
  { hostname: 'www.eversports.at', countryCode: 'AT' },
  { hostname: 'www.eversports.de', countryCode: 'DE' },
  { hostname: 'www.eversports.ch', countryCode: 'CH' },
  { hostname: 'www.eversports.nl', countryCode: 'NL' },
  { hostname: 'www.eversports.fr', countryCode: 'FR' },
  { hostname: 'www.eversports.es', countryCode: 'ES' },
  { hostname: 'www.eversports.be', countryCode: 'BE' },
  { hostname: 'www.eversports.it', countryCode: 'IT' },
]

class HostConfigs extends Map<string, HostConfig> {
  public get(hostname: string) {
    // Get the correct TLD config for the current environment or return the first one from the current environment
    const tldConfig = super.get(hostname) || (super.values().next().value as HostConfig)
    if (!tldConfig) {
      throw new Error(`tld config for ${hostname} not found`)
    }

    return tldConfig
  }
}

const tldConfigs = new HostConfigs()
hostConfigMap.forEach((c) => {
  tldConfigs.set(c.hostname, c)
})

export default tldConfigs
