import { useEffect, useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'

import { Venue } from '../TrialCards.types'
import { UseRecommendationsTrialCardsMeQuery, useUseRecommendationsTrialCardsMeQuery } from '../../../../graphql'

interface Result {
  venues: Array<Venue>
  loading: boolean
  fetchMore: () => Promise<ApolloQueryResult<UseRecommendationsTrialCardsMeQuery>>
  hasMoreResults: boolean
}

export const NUMBER_OF_INITIAL_TRIAL_CARDS_FETCHED = 12

const useRecommendationsTrialCards = (): Result => {
  const [venues, setVenues] = useState<Array<Venue> | null>(null)
  const { data, loading, refetch } = useUseRecommendationsTrialCardsMeQuery({
    variables: { first: NUMBER_OF_INITIAL_TRIAL_CARDS_FETCHED },
  })

  useEffect(() => {
    const fetchedVenues =
      data?.me?.recommendation?.venuesWithTrialCards.edges.map((edge) => {
        const { trialCards: trialCardsWithEdges, ...venueProperties } = edge.node
        const trialCards = trialCardsWithEdges.edges.map((edge) => ({ ...edge.node }))
        return {
          ...venueProperties,
          trialCards,
        }
      }) || []
    const newVenues = venues ? [...venues, ...fetchedVenues] : fetchedVenues
    setVenues(newVenues)
  }, [data])

  const initiallyFetchedVenues =
    data?.me?.recommendation?.venuesWithTrialCards.edges.map((edge) => {
      const { trialCards: trialCardsWithEdges, ...venueProperties } = edge.node
      const trialCards = trialCardsWithEdges.edges.map((edge) => ({ ...edge.node }))
      return {
        ...venueProperties,
        trialCards,
      }
    }) || []
  const hasMoreResults = Boolean(data?.me?.recommendation?.venuesWithTrialCards.pageInfo.hasNextPage)
  const indexOfLastVenue = data?.me?.recommendation?.venuesWithTrialCards
    ? data.me.recommendation.venuesWithTrialCards.edges.length - 1
    : 0
  const cursor = data?.me?.recommendation?.venuesWithTrialCards.edges[indexOfLastVenue]?.cursor

  const fetchMore = async () =>
    refetch({
      after: cursor,
    })

  return { loading, fetchMore, venues: !venues ? initiallyFetchedVenues : venues, hasMoreResults }
}

export default useRecommendationsTrialCards
