import * as React from 'react'

import CollapsibleFilter from '../../../../Filters/components/CollapsibleFilter'
import { Localized } from '../../../../../../localization/react'
import { Action } from '../../helpers/discover-sport-matches-reducer'
import { MatchCompetitionType } from '../../../../../../App.types'

import CompetitionTypeFilterForm from './CompetitionTypeFilterForm'

interface Props {
  dispatch: React.Dispatch<Action>
  selectedCompetitionTypes?: Array<MatchCompetitionType>
}

export const CollapsibleCompetitionTypeFilter = ({ dispatch, selectedCompetitionTypes }: Props) => {
  return (
    <CollapsibleFilter header={<Localized id="filters-matches-competition-type-heading" />}>
      <CompetitionTypeFilterForm dispatch={dispatch} selectedCompetitionTypes={selectedCompetitionTypes} />
    </CollapsibleFilter>
  )
}

export default CollapsibleCompetitionTypeFilter
