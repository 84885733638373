import { useParams } from 'react-router-dom'

import { UserType } from '../Match.types'
import { useLoggedInUserMeQuery, useLoggedInUserMatchOrganizerQuery } from '../../../graphql'

const useLoggedInUserType = () => {
  const { id } = useParams<{ id: string }>()
  const { data: userData } = useLoggedInUserMeQuery()
  const { data: matchData } = useLoggedInUserMatchOrganizerQuery({ variables: { matchId: id } })

  if (userData?.me?.id === matchData?.match?.organizerV2.id) {
    return { userType: UserType.ORGANIZER, userId: userData?.me?.id }
  }

  if (matchData?.match?.participants.some((participant) => participant.user.id === userData?.me?.id)) {
    return { userType: UserType.PARTICIPANT, userId: userData?.me?.id }
  }

  if (userData?.me) {
    return { userType: UserType.EVERSPORTS_GUEST, userId: userData.me.id }
  }

  return { userType: UserType.EXTERNAL_GUEST, userId: undefined }
}

export default useLoggedInUserType
