/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

// DO NOT MODIFY
// FILE GENERATED BY `intl-codegen@2.2.1`
// https://github.com/eversport/intl-codegen
// DO NOT MODIFY

import { createReactAPI } from "intl-codegen/runtime-react";
import { loadLanguage } from "./index.js";

const { Context, Provider, Consumer, Localized, useIntl } = createReactAPI();

export { loadLanguage, Context, Provider, Consumer, Localized, useIntl }
