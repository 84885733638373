import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import FormatTime from '@eversports/klimt-localization/Time'

import NewNotificationImage from '../assets/new-notification.svg'

interface Props {
  createdAt: string
  isNew?: boolean
}

const NotificationTimeStamp = ({ createdAt, isNew }: Props) => {
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
      {isNew && <img src={NewNotificationImage} width="7px" height="7px" />}
      <Text variant="xsmall" sx={{ color: 'carbon' }}>
        <FormatTime variant="short" value={createdAt} />
      </Text>
    </Stack>
  )
}

export default NotificationTimeStamp
