import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'

import { useUnreadNotificationCountMeQuery } from '../../graphql'
import { ColorContrast } from '../Header/Header.types'

interface Props {
  variant?: 'numerical' | 'dot'
  colorContrast?: ColorContrast
}

const UnreadNotificationCount = ({ colorContrast = ColorContrast.NORMAL, variant = 'numerical' }: Props) => {
  const { data } = useUnreadNotificationCountMeQuery()

  if (!data || !(data && data.me?.unreadNotificationsCount)) {
    return null
  }

  const numberOfUnreadNotifications = data.me.unreadNotificationsCount > 99 ? '99' : data.me.unreadNotificationsCount

  if (variant === 'dot') {
    return (
      <Text
        variant="xsmall"
        position="absolute"
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius={10}
        width={16}
        height={16}
        top={-12}
        right={-12}
        sx={{
          color: colorContrast === ColorContrast.NORMAL ? 'white' : 'red',
          backgroundColor: colorContrast === ColorContrast.NORMAL ? 'red' : 'white',
          fontWeight: 'bold',
        }}
      >
        {numberOfUnreadNotifications}
      </Text>
    )
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingX={1.5}
      borderRadius={10}
      backgroundColor={colorContrast === ColorContrast.NORMAL ? 'red' : 'white'}
    >
      <Text
        variant="xsmall"
        sx={{ color: colorContrast === ColorContrast.NORMAL ? 'white' : 'red', fontWeight: 'bold' }}
      >
        {numberOfUnreadNotifications}
      </Text>
    </Box>
  )
}

export default UnreadNotificationCount
