import { useEffect, useState } from 'react'

import { FriendConnection, FriendEdge } from '../../../Friends.types'
import { useUseFriendsMeLazyQuery } from '../../../../../../../graphql'
import { NUMBER_OF_INITIAL_FRIENDS_FETCHED } from '../../../Friends.constants'
import { PublicUser } from '../../../../../PublicUserProfile.types'

interface Result {
  friends: Array<PublicUser>
  loading: boolean
  fetchMore: () => void
  hasMoreResults: boolean
  onFriendRemoval: (friendId: string) => void
}

interface Args {
  initiallyFetchedFriends: FriendConnection | null
}

const useFriends = ({ initiallyFetchedFriends }: Args): Result => {
  const initialFetchedFriendsEdges = initiallyFetchedFriends?.edges || []
  const [friends, setFriends] = useState<Array<FriendEdge>>(initialFetchedFriendsEdges)
  const [loadMoreFriends, { data, loading }] = useUseFriendsMeLazyQuery({ fetchPolicy: 'no-cache' })

  useEffect(() => {
    if (!data) return
    const fetchedFriends = data.me?.friends.edges || []
    const newFriends = friends ? [...friends, ...fetchedFriends] : fetchedFriends
    setFriends(newFriends)
  }, [data])

  const fetchMore = () => {
    const cursor = friends[friends.length - 1].cursor
    void loadMoreFriends({
      variables: {
        first: NUMBER_OF_INITIAL_FRIENDS_FETCHED,
        after: cursor,
      },
    })
  }

  const onFriendRemoval = (friendId: string) => {
    const newFriends = friends.filter((friend) => friend.node.publicUser.id !== friendId)
    setFriends(newFriends)
  }

  const hasMoreResults = Boolean(
    data?.me?.friends.pageInfo.hasNextPage ?? initiallyFetchedFriends?.pageInfo.hasNextPage,
  )
  const friendsWithoutCursor = friends
    ? friends.map((edge) => edge.node.publicUser)
    : initialFetchedFriendsEdges.map((edge) => edge.node.publicUser)

  return {
    loading,
    friends: friendsWithoutCursor,
    fetchMore,
    hasMoreResults,
    onFriendRemoval,
  }
}

export default useFriends
