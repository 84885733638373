import * as React from 'react'

import SectionWrapper from '../../components/SectionWrapper'
import { MonetaryValue, VenueOfferingType } from '../../../../App.types'

import CallToActionButton from './CallToActionButton'
import CallToActionPrices from './CallToActionPrices'

interface Props {
  cheapestPrice?: MonetaryValue | null
  cheapestTrialProductPrice?: MonetaryValue | null
  offerings: Array<VenueOfferingType>
  slug: string
}

const CallToAction = ({ cheapestPrice, offerings, slug, cheapestTrialProductPrice }: Props) => {
  const hasPublicMatches = offerings.some((offering) => offering === VenueOfferingType.VENUE_OFFERING_PUBLIC_MATCHES)

  return (
    <SectionWrapper sx={{ gap: 5, padding: 4 }}>
      <CallToActionButton offerings={offerings} slug={slug} />
      {!hasPublicMatches && (
        <CallToActionPrices
          cheapestPrice={cheapestPrice}
          cheapestTrialProductPrice={cheapestTrialProductPrice}
          slug={slug}
        />
      )}
    </SectionWrapper>
  )
}

export default CallToAction
