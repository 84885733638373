import FormattedContent from '@eversports/klimt-primitives/FormattedContent'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import ChevronDownIcon from '@eversports/design-tokens/assets/icons/chevron-down.svg'
import Icon from '@eversports/klimt-primitives/Icon'
import Box from '@eversports/klimt-primitives/Box'
import Collapse from '@eversports/klimt-primitives/Collapse'
import { useState } from 'react'

import { Localized } from '../../localization/react'
import { useCancellationConditionsMatchQuery } from '../../graphql'

import { VALID_BALLSPORT_MATCH_STATES } from './Match.constants'

const CancellationsConditions = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useCancellationConditionsMatchQuery({ variables: { matchId: id } })
  const [isCollapsed, setIsCollapsed] = useState(false)

  if (!data?.match?.cancellationCondition) {
    return null
  }

  const isValidState = VALID_BALLSPORT_MATCH_STATES.includes(data.match.state)

  if (!isValidState) return null

  const cancellationConditions = data.match.cancellationCondition

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <Stack gap={1} py={[1, 0]} px={2}>
      <Stack
        gap={2}
        direction="row"
        alignItems="center"
        width="fit-content"
        sx={{ cursor: 'pointer' }}
        onClick={toggleCollapse}
      >
        <Text color="midnight" style={{ textDecoration: 'underline' }}>
          <Localized id="cancellation-conditions-header-for-organizers" />
        </Text>
        <Box sx={{ transform: isCollapsed ? 'rotate(180deg)' : 'initial' }}>
          <Icon src={ChevronDownIcon} size="x-small" color="darkgray" />
        </Box>
      </Stack>
      <Collapse in={isCollapsed}>
        <FormattedContent htmlContent={cancellationConditions} />
      </Collapse>
    </Stack>
  )
}

export default CancellationsConditions
