import * as React from 'react'
import Text from '@eversports/klimt-primitives/Text'
import { assertNever } from '@eversports/klimt-utilities/assert-never'

import { Localized } from '../../../../../../localization/react'
import { matchSettingIcon, matchSettingIconTranslation } from '../MatchSetting.constants'
import { MatchSettingType, MatchSetting as MatchSettingEnum } from '../MatchSetting.types'
import { competitionTypeTranslation, genderTranslation, levelTranslation } from '../../MatchSettings.constants'

import MatchSettingOrganizerBox from './MatchSettingOrganizerBox'

const MatchSettingOrganizer = (matchSetting: MatchSettingType) => {
  switch (matchSetting.type) {
    case MatchSettingEnum.LEVEL:
      return (
        <MatchSettingOrganizerBox
          onClick={matchSetting.onClick}
          hasContent={Boolean(matchSetting.level)}
          isDisabled={matchSetting.isDisabled}
        >
          <img src={matchSettingIcon[matchSetting.type]} width="14px" height="14px" />
          <Text>
            {matchSetting.level ? (
              <Localized id={levelTranslation[matchSetting.level]} />
            ) : (
              <Localized id={matchSettingIconTranslation[matchSetting.type]} />
            )}
          </Text>
        </MatchSettingOrganizerBox>
      )
    case MatchSettingEnum.COMPETITION_TYPE:
      return (
        <MatchSettingOrganizerBox
          onClick={matchSetting.onClick}
          hasContent={Boolean(matchSetting.competitionType)}
          isDisabled={matchSetting.isDisabled}
        >
          <img src={matchSettingIcon[matchSetting.type]} width="14px" height="14px" />
          <Text>
            {matchSetting.competitionType ? (
              <Localized id={competitionTypeTranslation[matchSetting.competitionType]} />
            ) : (
              <Localized id={matchSettingIconTranslation[matchSetting.type]} />
            )}
          </Text>
        </MatchSettingOrganizerBox>
      )
    case MatchSettingEnum.GENDER:
      return (
        <MatchSettingOrganizerBox
          onClick={matchSetting.onClick}
          hasContent={Boolean(matchSetting.gender)}
          isDisabled={matchSetting.isDisabled}
        >
          <img src={matchSettingIcon[matchSetting.type]} width="14px" height="14px" />
          <Text>
            {matchSetting.gender ? (
              <Localized id={genderTranslation[matchSetting.gender]} />
            ) : (
              <Localized id={matchSettingIconTranslation[matchSetting.type]} />
            )}
          </Text>
        </MatchSettingOrganizerBox>
      )
    default:
      return assertNever(matchSetting)
  }
}

export default MatchSettingOrganizer
