import * as React from 'react'
import { boxShadows } from '@eversports/design-tokens/box-shadows'
import Stack from '@eversports/klimt-primitives/Stack'

import ContainedXLWidthContainer from '../../components/ContainedXLWidthContainer'

const VenueProfileHeaderContainer: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Stack
      gap={4}
      sx={{
        py: { xs: 0, lg: 4 },
        pt: { xs: 0, sm: 5 },
        px: { xs: 0, lg: 8 },
        backgroundColor: 'white',
        boxShadow: boxShadows.default,
        width: '100%',
        zIndex: 1,
      }}
    >
      <ContainedXLWidthContainer>{children}</ContainedXLWidthContainer>
    </Stack>
  )
}

export default VenueProfileHeaderContainer
