import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Text from '@eversports/klimt-primitives/Text'
import Stack from '@eversports/klimt-primitives/Stack/Stack'

import { Localized } from '../../../../localization/react'
import { PurchaseOption, TicketPriceType } from '../../ActivityDetails.types'

import { getCorporateSpecial } from './helpers/get-corporate-special'

interface LabelProps {
  backgroundColor: string
}

const Label = ({ children, backgroundColor }: React.PropsWithChildren<LabelProps>) => (
  <Box
    sx={{
      px: 2,
      py: 1,
      backgroundColor,
      borderRadius: 1,
      width: 'max-content',
      height: 'fit-content',
      display: 'inline-block',
    }}
  >
    {children}
  </Box>
)

interface Props {
  purchaseOption: PurchaseOption
  isCorporateUser: boolean
}
const PriceLabel = ({ purchaseOption, isCorporateUser }: Props) => {
  switch (purchaseOption.__typename) {
    case 'Membership': {
      const {
        paymentPlan: { promotion },
      } = purchaseOption

      return (
        <Stack direction="row" gap={2}>
          {promotion && (
            <Label backgroundColor="rgba(243,236, 236)">
              <Text variant="badge" color="tomato">
                <Localized id="activity-detail-membership-promotion-label" />
              </Text>
            </Label>
          )}
          <Label backgroundColor="rgba(119,0, 238, 0.06) ">
            <Text variant="badge" color="secondary">
              <Localized id="activity-detail-membership-price-label" />
            </Text>
          </Label>
        </Stack>
      )
    }
    case 'Card': {
      const { isTrialCard, specialPrices } = purchaseOption
      if (!isTrialCard && !specialPrices.length) {
        return null
      }
      const corporateSpecial = getCorporateSpecial(purchaseOption)
      return (
        <Stack direction="row" gap={2}>
          {purchaseOption.isTrialCard && (
            <Label backgroundColor="rgba(33, 166, 150, 0.06)">
              <Text variant="badge" color="primary.main">
                <Localized id="activity-detail-trial-price-label" />
              </Text>
            </Label>
          )}
          {isCorporateUser && corporateSpecial && corporateSpecial.price && (
            <Label backgroundColor="rgba(242, 153, 74, 0.06)">
              <Text variant="badge" color="tertiary">
                <Localized id="activity-detail-corporate-price-label" />
              </Text>
            </Label>
          )}
        </Stack>
      )
    }
    case 'Ticket': {
      const { currentPriceType, earlyBirdPrice, lastMinutePrice } = purchaseOption
      if (currentPriceType === TicketPriceType.TICKET_PRICE_TYPE_EARLY_BIRD && earlyBirdPrice) {
        return (
          <Label backgroundColor="rgba(33, 166, 150, 0.06)">
            <Text variant="badge" color="primary.main">
              <Localized id="activity-detail-early-bird-price-label" />
            </Text>
          </Label>
        )
      }
      if (currentPriceType === TicketPriceType.TICKET_PRICE_TYPE_LAST_MINUTE && lastMinutePrice) {
        return (
          <Label backgroundColor="rgba(255, 26, 26, 0.06)">
            <Text variant="badge" color="red">
              <Localized id="activity-detail-last-minute-price-label" />
            </Text>
          </Label>
        )
      }
      return null
    }
    case 'SinglePurchase':
    default:
      return null
  }
}

export default PriceLabel
