import * as React from 'react'
import FormattedContent from '@eversports/klimt-primitives/FormattedContent'
import styled from '@eversports/klimt-design-components/styled'
import ExpandableContent from '@eversports/klimt-patterns/ExpandableContent'
import Text from '@eversports/klimt-primitives/Text'
import Box from '@eversports/klimt-primitives/Box'

import { SessionComment } from '../../ActivityDetails.types'

const SmallTextContent = styled('div')`
  font-size: 14px;
  line-height: 24px;
`

const MAX_DESCRIPTION_HEIGHT = 70

interface Props {
  sessionComment: SessionComment
}

const SessionComment = ({ sessionComment }: Props) => {
  const { title, comment } = sessionComment

  if (!comment) {
    return null
  }

  return (
    <Box sx={{ padding: 4, backgroundColor: 'lightgray', borderRadius: 1, color: 'darkgray' }}>
      <Text color="midnight" sx={{ fontWeight: 'bold' }}>
        {title} {/*Translated by the backend*/}
      </Text>
      <ExpandableContent clampedTextHeight={MAX_DESCRIPTION_HEIGHT} backgroundColor="#F2F2F2">
        <FormattedContent htmlContent={comment.html} WrapperComponent={SmallTextContent} />
      </ExpandableContent>
    </Box>
  )
}

export default SessionComment
