import { ContentType } from '../../hooks/useGetTopContentHeight'

import { DiscoverOfferingType } from './Discover.types'

export const NUMBER_OF_LISTING_RESULTS_TO_LOAD = 20

export const TOP_CONTENT_DISCOVER_SPORT_CITY: Array<ContentType> = ['NAVIGATION_BAR', 'FILTERS']

export const TOP_CONTENT_DISCOVER_CITY: Array<ContentType> = ['NAVIGATION_BAR', 'SPORT_PILLS', 'FILTERS']

export const DISCOVER_OFFERING_TYPE_TO_AMPLITUDE_NAME_MAPPING = {
  [DiscoverOfferingType.DISCOVER_OFFERING_CAMP_ACTIVITY]: 'camp',
  [DiscoverOfferingType.DISCOVER_OFFERING_CLASS_ACTIVITY]: 'class',
  [DiscoverOfferingType.DISCOVER_OFFERING_COURSE_ACTIVITY]: 'course',
  [DiscoverOfferingType.DISCOVER_OFFERING_EDUCATION_ACTIVITY]: 'education',
  [DiscoverOfferingType.DISCOVER_OFFERING_EVENT_ACTIVITY]: 'event',
  [DiscoverOfferingType.DISCOVER_OFFERING_LIVESTREAM]: 'livestream',
  [DiscoverOfferingType.DISCOVER_OFFERING_PUBLIC_MATCH]: 'match',
  [DiscoverOfferingType.DISCOVER_OFFERING_OPENTRAINING_ACTIVITY]: 'open training',
  [DiscoverOfferingType.DISCOVER_OFFERING_RETREAT_ACTIVITY]: 'retreat',
  [DiscoverOfferingType.DISCOVER_OFFERING_TRAINING_ACTIVITY]: 'training',
  [DiscoverOfferingType.DISCOVER_OFFERING_VIDEO]: 'video',
  [DiscoverOfferingType.DISCOVER_OFFERING_WORKSHOP_ACTIVITY]: 'workshop',
}

export const AMPLITUDE_DISCOVER_CITY_FILTER_EVENT = 'Used Discovery City Filter'
export const AMPLITUDE_DISCOVER_SPORT_CITY_FILTER_EVENT = 'Used Discovery Sport City Filter'
