import * as React from 'react'

import CollapsibleFilter from '../../../../Filters/components/CollapsibleFilter'
import { Localized } from '../../../../../../localization/react'
import { Action } from '../../helpers/discover-sport-matches-reducer'
import { Sport } from '../../../../../../App.types'

import SportsFilterForm from './SportsFilterForm'

interface Props {
  dispatch: React.Dispatch<Action>
  sports: Array<Sport>
  selectedSports?: Array<string>
}

export const CollapsibleSportsFilter = ({ dispatch, sports, selectedSports }: Props) => {
  return (
    <CollapsibleFilter header={<Localized id="filters-matches-sports-heading" />}>
      <SportsFilterForm dispatch={dispatch} sports={sports} selectedSports={selectedSports} />
    </CollapsibleFilter>
  )
}

export default CollapsibleSportsFilter
