import * as React from 'react'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import Stack from '@eversports/klimt-primitives/Stack/Stack'

import ContentWrapper from '../components/ContentWrapper'
import BottomFixedSection from '../components/BottomFixedSection'
import { EventSession, EventBookableItem } from '../ActivityDetails.types'
import FriendsAttending from '../FriendAttending'

import CallToAction from './CallToAction'
import DateTimeAvailability from './DateTimeAvailability'
import TrainerInfo from './TrainerInfo'
import RevealedActivityName from './RevealedActivityName'

interface Props {
  activity: EventBookableItem
  eventSessions: Array<EventSession>
}

const Sidebar = ({ activity, eventSessions }: Props) => {
  const {
    id,
    activityName,
    venue,
    eventType,
    availableSpots,
    registrationWindow,
    registrationWindowState,
    trainers,
    registrationState,
  } = activity
  const hasOneTrainer = trainers.length === 1

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <ContentWrapper
        sx={{
          display: { xs: 'none', md: 'flex' },
          position: 'sticky',
          top: '72px',
          height: 'fit-content',
          minWidth: { md: 250 },
          maxWidth: { md: 300 },
          gap: 0,
        }}
      >
        {!isMobile && <RevealedActivityName activityName={activityName} />}
        <Stack gap={4}>
          <DateTimeAvailability
            eventSessions={eventSessions}
            availableSpots={availableSpots}
            registrationState={registrationState}
          />
          {hasOneTrainer && <TrainerInfo trainer={trainers[0]} eventType={eventType} />}
          <FriendsAttending activityId={activity.id} />
          <CallToAction
            bookableItemId={id}
            venueId={venue.id}
            availableSpots={availableSpots}
            registrationState={registrationState}
            registrationWindowState={registrationWindowState}
            registrationWindow={registrationWindow}
          />
        </Stack>
      </ContentWrapper>
      <BottomFixedSection>
        <CallToAction
          bookableItemId={id}
          venueId={venue.id}
          availableSpots={availableSpots}
          registrationState={registrationState}
          registrationWindowState={registrationWindowState}
          registrationWindow={registrationWindow}
        />
      </BottomFixedSection>
    </>
  )
}

export default Sidebar
