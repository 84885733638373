import Box from '@eversports/klimt-primitives/Box'
import * as React from 'react'
import { motion } from '@eversports/klimt-utilities/framer-motion'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import useTheme from '@eversports/klimt-design-components/use-theme'

import { useDiscoverState } from '../DiscoverContext'
import { TRANSITION_FOR_MAP_ANIMATION } from '../../../App.constants'
import useGetTopContentHeight from '../../../hooks/useGetTopContentHeight'
import { getTopContentElements } from '../helpers/get-top-content-elements'

interface MobileWrapperProps {
  showMobileMapView: boolean
}

interface DesktopWrapperProps {
  topOffset: number
}

const MapWrapperMobile = ({ children, showMobileMapView }: React.PropsWithChildren<MobileWrapperProps>) => {
  return (
    <Box
      sx={{
        flex: 'auto',
        display: showMobileMapView ? 'flex' : 'none',
        transform: showMobileMapView ? 'unset' : 'translateX(-100%)',
        userSelect: 'none',
      }}
    >
      {children}
    </Box>
  )
}

const MapWrapperDesktop = ({ children, topOffset }: React.PropsWithChildren<DesktopWrapperProps>) => (
  <Box
    sx={{
      display: { xs: 'none', lg: 'flex' },
      height: `calc(100vh - ${topOffset}px)`,
      position: 'sticky',
      top: topOffset,
      userSelect: 'none',
    }}
  >
    {children}
  </Box>
)

const mapVariants = {
  halfMap: {
    width: '45vw',
  },
  expandMap: {
    width: '100vw',
  },
}

interface Props {
  isListingOpen: boolean
  hasOfferings?: boolean
}

const MapWrapperWithAnimation = ({ children, isListingOpen, hasOfferings = false }: React.PropsWithChildren<Props>) => {
  const theme = useTheme()
  const { showMobileMapView } = useDiscoverState()
  const isInMobileView = useMediaQuery(theme.breakpoints.down('lg'))
  let topContentElements = getTopContentElements()

  if (!topContentElements.some((element) => element === 'OFFERINGS') && hasOfferings) {
    topContentElements = [...topContentElements, 'OFFERINGS']
  }

  const { DESKTOP_TOP_CONTENT_HEIGHT } = useGetTopContentHeight(topContentElements)

  return isInMobileView ? (
    <MapWrapperMobile showMobileMapView={showMobileMapView}>{children}</MapWrapperMobile>
  ) : (
    <MapWrapperDesktop topOffset={DESKTOP_TOP_CONTENT_HEIGHT}>
      <motion.div
        initial={false}
        variants={mapVariants}
        animate={isListingOpen ? 'halfMap' : 'expandMap'}
        transition={TRANSITION_FOR_MAP_ANIMATION}
      >
        {children}
      </motion.div>
    </MapWrapperDesktop>
  )
}

export default MapWrapperWithAnimation
