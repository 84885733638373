import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'

import SharingAction from './SharingAction'
import { ShareType, SharingMessage } from './SharingActions.types'

interface Props {
  sharingMessage: SharingMessage
}

const SharingActions = ({ sharingMessage }: Props) => {
  return (
    <Stack
      direction="row"
      alignItems="baseline"
      gap={4}
      sx={{
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <SharingAction type={ShareType.CopyLink} link={sharingMessage.linkToCopy} />
      <SharingAction type={ShareType.Whatsapp} encodedMessage={sharingMessage.encodedMessage} />
      <SharingAction type={ShareType.Email} encodedMessage={sharingMessage.encodedMessage} />
      <SharingAction type={ShareType.Messages} encodedMessage={sharingMessage.encodedMessage} />
      <SharingAction type={ShareType.CopyText} translatedMessage={sharingMessage.translatedMessage} />
    </Stack>
  )
}

export default SharingActions
