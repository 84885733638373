import * as React from 'react'
import Box from '@eversports/klimt-primitives/Box'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import Checkbox from '@eversports/klimt-forms/Checkbox'
import IconLogo from '@eversports/design-tokens/assets/logo/icon.svg'

import { REMOVE_PARTICIPANTS_FORM_KEY } from './RemoveParticipants.constants'
import { UserWithParticipantId } from './RemoveParticipants.types'

interface Props {
  participant: UserWithParticipantId
  isSelected: boolean
}

const ParticipantToBeRemoved = ({ participant, isSelected }: Props) => {
  return (
    <Box
      px={2}
      py={1}
      sx={{
        width: 'fit-content',
        border: '1px solid',
        borderRadius: 1,
        backgroundColor: isSelected ? 'rgba(255, 26, 26, 0.08)' : 'white',
        borderColor: isSelected ? 'red' : 'lightgray',
      }}
    >
      <Checkbox
        label={
          <Stack direction="row" alignItems="center" gap={2}>
            <img
              src={participant.profilePicture?.xSmall || IconLogo}
              style={{ borderRadius: '80px' }}
              alt="User Picture"
              height="24px"
              width="24px"
            />
            <Text variant="small" sx={{ color: 'midnight' }}>
              {participant.firstName} {participant.lastName}
            </Text>
          </Stack>
        }
        value={participant.participantId}
        name={REMOVE_PARTICIPANTS_FORM_KEY}
        sx={{
          padding: '0 10px 0 0',
          '&.MuiCheckbox-root': { color: '#DDDDDD' },
          '&.Mui-checked': { color: 'red' },
        }}
      />
    </Box>
  )
}

export default ParticipantToBeRemoved
