import { useParams } from 'react-router-dom'

import { useUseLoggedInUserTypeMeQuery, useUseLoggedInUserTypePublicUserQuery } from '../../../graphql'
import { UserType } from '../PublicUserProfile.types'

interface Result {
  userType: UserType
  publicUserId?: string
}

const useLoggedInUserType = (): Result => {
  const { userHandle } = useParams<{ userHandle: string }>()
  const { data: loggedInUserData } = useUseLoggedInUserTypeMeQuery()
  const { data: publicUserData } = useUseLoggedInUserTypePublicUserQuery({ variables: { handle: userHandle } })

  if (loggedInUserData?.me?.id === publicUserData?.publicUser?.id) {
    return { userType: UserType.PROFILE_OWNER }
  }

  if (!loggedInUserData?.me) {
    return { userType: UserType.LOGGED_OUT_USER }
  }

  if (publicUserData?.publicUser.isYourFriend) {
    return { userType: UserType.FRIENDS, publicUserId: publicUserData?.publicUser?.id }
  }

  if (!publicUserData?.publicUser.pendingFriendRequest) {
    return { userType: UserType.EVERSPORTS_USER, publicUserId: publicUserData?.publicUser?.id }
  }

  if (publicUserData?.publicUser.pendingFriendRequest.from.id === loggedInUserData?.me.id) {
    return { userType: UserType.REQUESTED_FRIENDSHIP, publicUserId: publicUserData?.publicUser?.id }
  }

  if (publicUserData?.publicUser.pendingFriendRequest.to.id === loggedInUserData?.me.id) {
    return { userType: UserType.FRIENDSHIP_REQUEST_PENDING, publicUserId: publicUserData?.publicUser?.id }
  }

  return { userType: UserType.LOGGED_OUT_USER }
}

export default useLoggedInUserType
