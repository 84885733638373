import * as React from 'react'
import { default as MuiSwitch, SwitchProps } from '@mui/material/Switch'
import { default as FormControlLabel } from '@mui/material/FormControlLabel'

export interface Props extends SwitchProps {
  label: React.ReactNode
}

const Switch: React.FC<React.PropsWithChildren<Props>> = ({ label, ...props }) => {
  return (
    <FormControlLabel
      label={label}
      disabled={props.disabled}
      control={<MuiSwitch inputProps={{ 'aria-label': label as string }} {...props} />}
    />
  )
}

export default Switch
