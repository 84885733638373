import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Text from '@eversports/klimt-primitives/Text'
import { motion } from '@eversports/klimt-utilities/framer-motion'
import Box from '@eversports/klimt-primitives/Box'
import Close from '@eversports/design-tokens/assets/icons/close.svg'
import Icon from '@eversports/klimt-primitives/Icon'
import OverlayLink from '@eversports/klimt-patterns/OverlayLink'

import { useDiscoverState } from '../../DiscoverContext'
import { OnlineVenue, Slot, Venue } from '../../../../App.types'
import ListingCard from '../ListingCard'
import SportTags from '../../../../components/SportTags'
import { venueHasCorporatePrice } from '../../../../helpers/venue-has-corporate-price'
import CorporateBadge from '../../../../components/CorporateBadge'
import { MapNoProfileImage, MapVenueImage } from '../ListingResult/VenueImage'
import Rating from '../ListingResult/Rating'
import CourtSlots from '../ListingResult/CourtSlots'
import VenueTags from '../ListingResult/VenueTags'
import MultipleLocationsTag from '../../../../components/MultipleLocationsTag'

const mapCardVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.2,
    },
  },
  exit: { opacity: 0, y: 200, transition: { ease: 'easeInOut', duration: 0.3 } },
}

interface MapListingResultComponentProps {
  venue: Venue | OnlineVenue
  to: string
  handleClick: (e: MouseEvent) => void
  slots?: Array<Slot>
}

const MapListingResultComponent = ({ venue, to, slots, handleClick }: MapListingResultComponentProps) => {
  const hasSpecialCorporatePrice = venueHasCorporatePrice(venue.specialPriceTypes)
  const isOnline = !('address' in venue)
  return (
    <Box
      sx={{
        position: 'absolute',
        left: [8, 16, 32],
        right: [8, 16, 32],
        maxWidth: '600px',
        margin: 'auto',
        bottom: 16,
        zIndex: 3,
      }}
    >
      <OverlayLink to={to} external blank />
      <motion.div variants={mapCardVariants} initial="hidden" animate="visible" exit="exit" style={{ zIndex: 2 }}>
        <ListingCard>
          <Stack direction="row" gap={3} p={[2, 3]}>
            {venue.profileImage ? (
              <MapVenueImage venueProfileImage={venue.profileImage} venueName={venue.name} loading="lazy" />
            ) : (
              <MapNoProfileImage />
            )}
            <Stack gap={[2, 3]} flex={1} sx={{ overflow: 'auto' }}>
              <Stack gap={[1, 2]}>
                <Text
                  variant="large"
                  sx={{
                    fontWeight: 'bold',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {venue.name}
                </Text>
                <Rating rating={venue.rating} reviewCount={venue.reviewCount} />
              </Stack>
              {slots ? (
                <>
                  <MapCourtSlots venueId={venue.id} to={to} slots={slots} />
                  {hasSpecialCorporatePrice && <CorporateBadge />}
                  <MultipleLocationsTag company={venue.company} isOnline={isOnline} />
                </>
              ) : (
                <>
                  <SportTags sports={venue.sports} />
                  <Stack gap={2} direction="row" flexWrap="wrap">
                    <MultipleLocationsTag company={venue.company} isOnline={isOnline} />
                    <VenueTags tags={venue.tags} />
                    {hasSpecialCorporatePrice && <CorporateBadge />}
                  </Stack>
                </>
              )}
            </Stack>
            <Box
              onClick={handleClick}
              sx={{
                height: 'fit-content',
                zIndex: 1,
              }}
            >
              <Icon src={Close} color="gray" size="default" />
            </Box>
          </Stack>
        </ListingCard>
      </motion.div>
    </Box>
  )
}

interface MapCourtSlotsProps {
  venueId: string
  to: string
  slots: Array<Slot>
}

const MapCourtSlots = ({ venueId, to, slots }: MapCourtSlotsProps) => {
  const { venueCourtSlotTimeActiveFilter } = useDiscoverState()

  // If there's a time slot selected, show slots from the time selected
  const selectedTimeSlotIndex = venueCourtSlotTimeActiveFilter
    ? slots.findIndex((slot: Slot) => {
        const slotDate = new Date(slot.start)
        return slotDate.getHours() === venueCourtSlotTimeActiveFilter.getHours()
      })
    : -1

  const initialTimeSlotIndex = selectedTimeSlotIndex !== -1 ? selectedTimeSlotIndex : 0
  const slotsToShow = venueCourtSlotTimeActiveFilter ? slots.slice(initialTimeSlotIndex) : slots

  return <CourtSlots venueId={venueId} slots={slotsToShow} numberOfSlotsToShow={3} to={to} />
}

export default MapListingResultComponent
