export enum PageType {
  THANK_YOU = 'THANK_YOU',
  OVERVIEW = 'OVERVIEW',

  CALENDAR = 'CALENDAR',
  DISCOVER = 'DISCOVER',
  VENUE_PROFILE = 'VENUE_PROFILE',
}

export enum UserType {
  ORGANIZER = 'ORGANIZER',
  PARTICIPANT = 'PARTICIPANT',
  EVERSPORTS_GUEST = 'EVERSPORTS_GUEST',
  EXTERNAL_GUEST = 'EXTERNAL_GUEST',
}

export enum MatchStatus {
  UPCOMING = 'UPCOMING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum BallsportMatchState {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  MOVED = 'MOVED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
  PLANNED = 'PLANNED',
}

export enum PaymentState {
  PAYMENT_STATE_CANCELLED = 'PAYMENT_STATE_CANCELLED',
  PAYMENT_STATE_FAILED = 'PAYMENT_STATE_FAILED',
  PAYMENT_STATE_IN_PROGRESS = 'PAYMENT_STATE_IN_PROGRESS',
  PAYMENT_STATE_ON_HOLD = 'PAYMENT_STATE_ON_HOLD',
  PAYMENT_STATE_OPEN = 'PAYMENT_STATE_OPEN',
  PAYMENT_STATE_SUCCEEDED = 'PAYMENT_STATE_SUCCEEDED',
}

export interface Venue {
  name: string
  address: string
  slug: string
  location: {
    latitude: number | null
    longitude: number | null
  }
}

export interface Court {
  name: string
  area: CourtAreaType | null
  surface: CourtSurfaceType | null
}

export enum CourtAreaType {
  INDOOR = 'INDOOR',
  OUTDOOR = 'OUTDOOR',
}

export enum CourtSurfaceType {
  ARTIFICIAL_GRASS = 'ARTIFICIAL_GRASS',
  ARTIFICIAL_ICE = 'ARTIFICIAL_ICE',
  ASH = 'ASH',
  ASPHALT = 'ASPHALT',
  CARPET = 'CARPET',
  CARPET_GRANULE = 'CARPET_GRANULE',
  CONCRETE = 'CONCRETE',
  FELT = 'FELT',
  GRANULE = 'GRANULE',
  GRASS = 'GRASS',
  HARD_COURT = 'HARD_COURT',
  ICE = 'ICE',
  OPTICOURT = 'OPTICOURT',
  PARQUET = 'PARQUET',
  PVC = 'PVC',
  RED_COURT = 'RED_COURT',
  SAND = 'SAND',
  SYNTHETIC = 'SYNTHETIC',
  TARTAN = 'TARTAN',
  WATER = 'WATER',
}

export enum MatchCompetitionType {
  COMPETITIVE = 'COMPETITIVE',
  FRIENDLY = 'FRIENDLY',
}

export enum MatchGender {
  UNISEX = 'UNISEX',
  MALE_ONLY = 'MALE_ONLY',
  FEMALE_ONLY = 'FEMALE_ONLY',
}

export enum MatchLevel {
  ANY = 'ANY',
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
}

export enum MatchVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum MatchMissingField {
  COMPETITION_TYPE = 'COMPETITION_TYPE',
  GENDER = 'GENDER',
  LEVEL = 'LEVEL',
  MAX_PARTICIPANTS = 'MAX_PARTICIPANTS',
}
