import * as React from 'react'
import useTheme from '@eversports/klimt-design-components/use-theme'
import useMediaQuery from '@eversports/klimt-design-components/use-media-query'
import Dialog, { DialogContent } from '@eversports/klimt-primitives/Dialog'

import Drawer from '../../../components/Drawer'

import FriendsAttendingPromotionContent from './FriendsAttendingPromotionContent'

interface Props {
  isOpen: boolean
  toggleDialog: () => void
  userHandle: string
}

const FriendsAttendingPromotion = ({ isOpen, toggleDialog, userHandle }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return isMobile ? (
    <Drawer open={isOpen} onClose={toggleDialog}>
      <FriendsAttendingPromotionContent userHandle={userHandle} />
    </Drawer>
  ) : (
    <Dialog open={isOpen} onClose={toggleDialog} maxWidth="xs">
      <DialogContent>
        <FriendsAttendingPromotionContent userHandle={userHandle} />
      </DialogContent>
    </Dialog>
  )
}

export default FriendsAttendingPromotion
