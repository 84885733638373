import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FriendConnection, FriendEdge } from '../../Friends.types'
import { useUseFriendsOfUserPublicUserLazyQuery } from '../../../../../../graphql'
import { NUMBER_OF_INITIAL_FRIENDS_FETCHED } from '../../Friends.constants'
import { PublicUser } from '../../../../PublicUserProfile.types'

interface Result {
  friends: Array<PublicUser>
  loading: boolean
  fetchMore: () => void
  hasMoreResults: boolean
}

interface Args {
  initiallyFetchedFriends: FriendConnection | null
}

const useFriendsOfUser = ({ initiallyFetchedFriends }: Args): Result => {
  const { userHandle } = useParams<{ userHandle: string }>()
  const initialFetchedFriendsEdges = initiallyFetchedFriends?.edges || []
  const [friends, setFriends] = useState<Array<FriendEdge>>(initialFetchedFriendsEdges)
  const [loadMoreFriends, { data, loading }] = useUseFriendsOfUserPublicUserLazyQuery({ fetchPolicy: 'no-cache' })

  useEffect(() => {
    if (!data) return
    const fetchedFriends = data.publicUser?.friends?.edges || []
    const newFriends = friends ? [...friends, ...fetchedFriends] : fetchedFriends
    setFriends(newFriends)
  }, [data])

  const fetchMore = () => {
    const cursor = friends[friends.length - 1].cursor
    void loadMoreFriends({
      variables: {
        handle: userHandle,
        first: NUMBER_OF_INITIAL_FRIENDS_FETCHED,
        after: cursor,
      },
    })
  }

  const hasMoreResults = Boolean(
    data?.publicUser?.friends?.pageInfo.hasNextPage ?? initiallyFetchedFriends?.pageInfo.hasNextPage,
  )
  const friendsWithoutCursor = friends
    ? friends.map((edge) => edge.node.publicUser)
    : initialFetchedFriendsEdges.map((edge) => edge.node.publicUser)

  return {
    loading,
    friends: friendsWithoutCursor,
    fetchMore,
    hasMoreResults,
  }
}

export default useFriendsOfUser
