import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import IconLogo from '@eversports/design-tokens/assets/logo/icon.svg'

import { Localized } from '../../../localization/react'
import SectionWrapper from '../components/SectionWrapper'

import BrandPromiseCard from './components/BrandPromiseCard'
import Running from './assets/running.svg'
import Flexibility from './assets/flexibility.svg'
import Friends from './assets/friends.svg'

const WhatIsEversports = () => (
  <SectionWrapper sx={{ marginBottom: 20, contentVisibility: 'auto' }}>
    <Stack
      sx={{
        flexDirection: { sm: 'row' },
        gap: 4,
        justifyContent: 'center',
        alignItems: { sm: 'center' },
        marginBottom: 4,
      }}
    >
      <img src={IconLogo} alt="" width="32" height="32" loading="lazy" />
      <Heading
        is="h2"
        variant="xlarge"
        color="midnight"
        sx={{ fontFamily: "'Bricolage Grotesque', 'Adjusted Bricolage Grotesque Fallback'", fontWeight: 500 }}
      >
        <Localized id="home-what-is-eversports-header" />
      </Heading>
    </Stack>
    <Stack
      component="ul"
      sx={{
        flexDirection: 'row',
        gap: 4,
        overflowX: 'auto',
        scrollSnapType: 'x',
        scrollBehavior: 'smooth',
        padding: 0,
        listStyle: 'none',
      }}
    >
      <BrandPromiseCard
        headerLocalizedId="home-brand-promise-1-header"
        descriptionLocalizedId="home-brand-promise-1-description"
        icon={Running}
      />
      <BrandPromiseCard
        headerLocalizedId="home-brand-promise-2-header"
        descriptionLocalizedId="home-brand-promise-2-description"
        icon={Flexibility}
      />
      <BrandPromiseCard
        headerLocalizedId="home-brand-promise-3-header"
        descriptionLocalizedId="home-brand-promise-3-description"
        icon={Friends}
      />
    </Stack>
  </SectionWrapper>
)

export default WhatIsEversports
