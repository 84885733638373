export interface CustomWindow {
  __APOLLO_STATE__?: any
  __APPSTATE__: any
  sharedConfig: any
  env: WindowOptions
}

const windowOptions = {
  GRAPHQL_URL: process.env.GRAPHQL_URL || '',
  MANAGER_GRAPHQL_URL: process.env.MANAGER_GRAPHQL_URL || '',
  FACEBOOK_CLIENT_ID: process.env.FACEBOOK_CLIENT_ID || '',
  OAUTH_GOOGLE_CLIENT_ID: process.env.OAUTH_GOOGLE_CLIENT_ID || '',
  CHECKOUT_GRAPHQL_URL: process.env.CHECKOUT_GRAPHQL_URL || '',
  TOURNAMENTS_GRAPHQL_URL: process.env.TOURNAMENTS_GRAPHQL_URL || '',
  AMPLITUDE_PROJECT_API_KEY: process.env.AMPLITUDE_PROJECT_API_KEY || '',
} as const

export type WindowOptions = typeof windowOptions
export default windowOptions

export interface WindowType extends Omit<Window, 'env'>, CustomWindow {}

export function getWindow<T extends WindowType>(): T {
  return window as unknown as T
}
