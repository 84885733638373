import * as React from 'react'

import FilterDrawer from '../../../../../../components/FilterDrawer'
import { Localized } from '../../../../../../localization/react'
import { Action } from '../../helpers/discover-sport-matches-reducer'
import { Sport } from '../../../../../../App.types'

import SportsFilterForm from './SportsFilterForm'

interface Props {
  dispatch: React.Dispatch<Action>
  sports: Array<Sport>
  selectedSports?: Array<string>
}

export const DrawerSportsFilter = ({ dispatch, selectedSports, sports }: Props) => {
  return (
    <FilterDrawer header={<Localized id="filters-matches-sports-heading" />} selectedFilterValues={selectedSports}>
      <SportsFilterForm dispatch={dispatch} variant="drawer" sports={sports} selectedSports={selectedSports} />
    </FilterDrawer>
  )
}

export default DrawerSportsFilter
