import * as React from 'react'
import { useParams } from 'react-router-dom'

import { useFriendsMeQuery } from '../../../../graphql'
import LoadingPage from '../../../../components/LoadingPage'

import MyFriends from './MyFriends'
import FriendsOfUser from './FriendsOfUser'

const Friends = () => {
  const { userHandle } = useParams<{ userHandle: string }>()
  const { data, loading } = useFriendsMeQuery()

  if (!data || loading) {
    return <LoadingPage />
  }

  if (userHandle !== data.me?.handle) {
    return <FriendsOfUser />
  }

  return <MyFriends />
}

export default Friends
